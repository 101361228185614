import WindowFrame from "../../components/WindowFrame";

import V1App from '../../$v1/App2';
import { ConfigProvider, theme } from "antd";

export default function V1() {
    
    const { darkAlgorithm, compactAlgorithm } = theme;

    return (
        <ConfigProvider 
            theme={{
                algorithm: [ darkAlgorithm, compactAlgorithm ]
            }}
        >
            <V1App />
        </ConfigProvider>
    )


}