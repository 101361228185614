import { Circle } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY } from "../../util/graph";
import { corner5 } from "./corner5";

export const corner6 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {

    const size = {width: 0, height: 0};
    let paths = corner5(dx, dy, startX, startY, true, size);
    paths = paths.slice(11, paths.length);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, size.width);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = size.width;
            sizeRes.height = 33.998293//33.988293;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);

}