export const enUS = {
    "大圣设备": "Dasheng Equipment",
    "我的订单": "My Orders",
    "已创建订单": "Order created",
    "还未创建订单": "Empty",
    "创建订单": "Create a new order",
    "添加": "Add",
    "收货地址": "Address",
    "板材设置": "Material settings",
    "请输入收货地址": "Please enter address",
    "板材种数": " Material types",
    "请输入材料种数": "Please enter the number of panel material types",
    "最少1种, 最多4种": "1 <= quantity <= 4",
    "材料名字": "Material name",
    "请输入材料名字":　"Please enter material name",
    "板件材料": "Panel and material",
    "结构设置": "Structure settings",
    "结构": "Structure",
    "请选择板件结构": "Please choose structure",
    "材料": " Material",
    "删除当前订单": "Delete current order",
    "删除后不可恢复，请确认是否删除当前订单": "Cannot be restored after deletion, please confirm whether to delete the current order",
    "确认删除": "Delete",
    "删除成功，已为您切换到最新订单": "Deleted successfully, has been switched to the latest order for you",
    "删除订单": "Delete order",
    "导出资料": "Export data",
    "当前订单": "Current order",
    "订单号": "Order No",
    "创建日期": "Create date",

    "排版配置": "Nest config",
    "开始排版": "Start nesting",
    "侧包背": "Side wrapped back",
    "背包侧": "Back wrapped side",
    "启动排版": "Start nesting",

    
    "确认": "OK",
    "取消": "Cancel",
    "默认": "Default",


    "地柜": "Floor cabinet",
    "吊柜": "Wall cabinet",
    "A右三门柜": "Right three-door cabinet",
    "A左三门柜": "Left three-door cabinet",
    "左切转角柜": "Left cut-corner cabinet",
    "右切转角柜": "Right cut-corner cabinet",
    "左转角柜": "Left corner cabinet",
    "右转角柜": "Right corner cabinet",
    "水槽柜": "Sink cabinet",
    "拉篮柜": "Pull-basket cabinet",
    "阳台柜": "Balcony cabinet",
    "阳台柜2": "Balcony cabinet II",
    "吊柜三门柜": "Wall three-door cabinet",

    "不锈钢1": "Material 1",
    "不锈钢2": "Material 2",
    "不锈钢3": "Material 3",
    "柜内": "Cabinet inner",
    "柜外": "Cabinet outer",
    "背内": "Back inner",
    "背外": "Back outer",
    "门内": "Door inner",
    "门外": "Door outer",

    //配置相关
    "长": "Length",
    "深": "Depth",
    "高": "Height",
    "切角X": "Cut-corner X",
    "切角Y": "Cut-corner Y",
    "门板宽度": "Door width",
    "柜腿边距X": "Feet margin X",
    "柜腿边距Y": "Feet margin Y",
    "吊孔边距": "Lifting hole margin",
    "连接孔": "Connect hole",
    "有连接孔": "yes",
    "无连接孔": "no",
    "连接孔边距": "Connect hole margin",
    "铰链孔": "Hinge hole",
    "有铰链孔": "yes",
    "无铰链孔": "no",
    "铰链边距": "Hinge hole margin",
    "拉手": "Door handle",
    "门拉手": "Door handle",
    "有拉手": "yes",
    "无拉手": "no",
    "门下高出": "Door bottom higher",
    "门明拉手": "Handle type - pull",
    "G型拉手": "Handle type - G",
    "普通拉手": "Handle type - normal",
    "拉手间距": "Handle hole distance",
    "拉手X": "Handle position X",
    "拉手Y": "Handle position Y",
    "拉手方向": "Handle layout",
    "横向": "Horizontal",
    "纵向": "Vertical",
    "G型拉手类型": "G position",
    "G型拉手高度": "G height",
    "上边G型拉手": "top",
    "侧边G型拉手": "side",
    "柜门数量": "Number of doors",
    "单门": "one",
    "双门": "two",
    "三门": "three",
    "外板角类型": "Outer panel corner type",
    "有护角": "with protection",
    "无护角": "without protection",
    "无角": "no corner",
    "门外板角": "Door outer panel corner",
    "有角": "yes",
    "门头外板角": "Door header outer panel corner",
    "前挡外板角": "Front bezel outer panel corner",
    "门高": "Door height",
    "门宽": "Door width",
    "是否有隔层": "Interlayer board",
    "有隔层": "yes",
    "无隔层": "no",
    "隔层X": "Interlayer hole position X",
    "隔层Y": "Interlayer hole position Y",
    "隔层孔直径": "Interlayer hole diameter",
    "有无背内板": "Back inner plate",
    "有背内板": "yes",
    "无背内板": "no",
    "有无背板": "Back board",
    "有背板": "yes",
    "无背板": "no",
    "底板深": "Bottom board depth",
    "左侧深": "Left board depth",
    "左侧高": "Left board height",
    "中侧深": "Middle board depth",
    "中侧高": "Middle board height",
    "右侧深": "Right board depth",
    "右侧高": "Right board height",
    "前挡横放": "Front bezel horizontal",
    "横放": "horizontal",
    "竖放": "vertical",
    "门内排孔": "Rail hole at door inner panel",
    "有排孔": "yes",
    "无排孔": "no",
    "排孔X": "Rail hole margin X",
    "排孔Y": "Rail hole margin Y",
    "排孔宽": "Rail hole width",
    "排孔高": "Rail hole height",
    "侧板宽": "Side board width",
    "侧板高": "Side board height",
    "有无大孔": "Big hole",
    "有大孔": "yes",
    "无大孔": "no",
    "有无柜脚": "Feet",
    "有柜脚": "yes",
    "无柜脚": "no",
    "合页": "Hinge",
    "有合页": "yes",
    "无合页": "no",
    "数量": "Number",
    "开门方向": "Door open side",
    "左开": "Left",
    "右开": "Right",
    "A": "A",
    "门板宽": "Door board width",   
    "门板高": "Door board height",
    "添加侧板": "Side board",
    "添加门板": "Door board",
    //排版 文件相关
    "标签": "Tags",
    "写入文件出现错误，检查是否文件被占用": "An error occurred while writing the file, check whether the file is occupied",
    "DXF文件已导出到": "DXF file has been exported to ",
    "已排版": "Nested",
    "排版结果已导出到桌面": "The nesting result has been exported to the desktop",
    "排版程序启动中，请耐心等待": "The nesting program is starting, please wait patiently",
    "排版包括启动、整理和计算，时间约3-5分钟，之后点击导出得到DXF文件": "Nesting includes starting, sorting and calculating, it takes about 3-5 minutes, and then click \"Export\" to get the DXF file",
    "完成排版后再点确认": "Click \"OK\" after finishing the nesting",
    "切割板材宽": "Sheet width",
    "切割板材长": "Sheet length",
    "切割间距": "Spacing",
    "是否旋转": "Panel rotate",
    "旋转": "yes",
    "不旋转": "no",

    "查看": "View",
    "删除": "Delete",
    "已删除": "Deleted",
    "输入数据": "Config",
    "X": "X",
    "Y1": "Y1",
    "Y2": "X2",
    "Y3": "Y3",

    "上免拉手": "Side hidden handle(up)",
    "上免拉手X": "Side hidden handle(up) X",
    "上免拉手Y": "Side hidden handle(up) Y",
    "中免拉手": "Side hidden handle(middle)",
    "中免拉手X": "Side hidden handle(middle) X",
    "中免拉手Y": "Side hidden handle(middle) Y",
    "中免拉手Z": "Side hidden handle(middle) Z",
    "有": "yes",
    "无": "no",

    "使用吊码": "",
    "不使用吊码": "",
}

export const zhCN: any = {};
for (const _key in enUS) {
    zhCN[_key] = _key;
}
export const isEn = /* window.location.search.split('?')[1]?.split('=')[1] !== "zh-CN" */false;
export const locale = /* isEn ? enUS : */ zhCN;    