import { DirectX, DirectY } from "../../../constants/enum"
import { cewaiBoardLeft } from "../地柜/01-cewai-left";

export const _05cewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    corner: boolean,
    mengao: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return cewaiBoardLeft(cornerType, dx, dy, startX, startY, width, height, true, -1, lianjiekongbianju, corner, undefined, undefined, undefined, undefined, mengao, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
    // return cewaiBoardLeft(cornerType, dx, dy, startX, startY, width, height, extraConfig.doorNumber !== 2,/* 实际无铰链孔 */ extraConfig.doorNumber === 2 ? extraConfig?.jiaolianbianju : -1, lianjiekongbianju, corner, undefined, undefined, undefined, undefined, mengao, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, {});
}
