import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum"
import { pathMoveY } from "../../../util/graph";
import { 无护角有卡槽 } from "./config";
import { corner2WaiWuhujiao } from "./corner2-wai-wuhujiao";

export const  corner5WaiWuhujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }, special?: number): Path[] => {

    const size = { width: 0, height: 0 };
    let paths = corner2WaiWuhujiao(dx, -dy, startX, startY, true, size);
    paths = pathMoveY(paths, DirectY.UP, size.height);
    //倒数第二个才是
    // const nowX = paths[paths.length - 2].params[2];
    // const nowY = paths[paths.length - 2].params[3];
    const nowX = paths[paths.length - 1].params[2]; //去除corner2的多余小边（解决rotate之后出现重边的问题）
    const nowY = paths[paths.length - 1].params[3] as number;
    let _special = special;
    if (!_special) {
        _special = 10.07;
    }
    paths.push({
        type: Circle,
        params: [
            nowX + dx * _special + dx * 1.92 / 2, nowY + dy * 1.995 + dy * 13.39,
            2.61
        ]
    },
        {
            type: Circle,
            params: [
                nowX + dx * _special + dx * 1.92 + dx * 29.58 + dx * 1.92 / 2, nowY + dy * 1.995 + dy * 13.39,
                2.61
            ]
        }, {

        type: Circle,
        params: [
            nowX + dx * _special + dx * 1.92 + dx * 29.58 + dx * 1.92 / 2 - dx * 15.75, nowY + dy * 1.995 + dy * 13.39,
            3.11
        ]
    });
    if (无护角有卡槽) {
        paths.push({
            type: Line,
            params: [
                nowX, nowY,
                nowX + dx * _special, nowY,
            ]
        }, {
            type: Line,
            params: [
                nowX + dx * _special, nowY,
                nowX + dx * _special, nowY + dy * 1.995,
            ]
        }, {
            type: Line,
            params: [
                nowX + dx * _special, nowY + dy * 1.995,
                nowX + dx * _special + dx * 1.92, nowY + dy * 1.995
            ]
        }, {
            type: Line,
            params: [
                nowX + dx * _special + dx * 1.92, nowY + dy * 1.995,
                nowX + dx * _special + dx * 1.92, nowY,
            ]
        }, {
            type: Line,
            params: [
                nowX + dx * _special + dx * 1.92, nowY,
                nowX + dx * _special + dx * 1.92 + dx * 29.58, nowY,
            ]
        }, {
            type: Line,
            params: [
                nowX + dx * _special + dx * 1.92 + dx * 29.58, nowY,
                nowX + dx * _special + dx * 1.92 + dx * 29.58, nowY + dy * 1.995,

            ]
        },
            // {
            //     type: Line,
            //     params: [
            //         nowX + dx * _special + dx * 1.92, nowY,
            //         nowX + dx * _special, nowY
            //     ]
            // },
        {
            type: Line,
            params: [
                nowX + dx * _special + dx * 1.92 + dx * 29.58, nowY + dy * 1.995,
                nowX + dx * _special + dx * 1.92 + dx * 29.58 + dx * 1.92, nowY + dy * 1.995,
            ]
        }, {
        type: Line,
        params: [
            nowX + dx * _special + dx * 1.92 + dx * 29.58 + dx * 1.92, nowY + dy * 1.995,
            nowX + dx * _special + dx * 1.92 + dx * 29.58 + dx * 1.92, nowY,
        ]})
    }else {
        
        paths.push({
            type: Line,
            params: [
                nowX, nowY,
                nowX + dx * _special + dx * 43.49, nowY,
            ]
        });
    }

    if (typeof sizeRes === 'object') {
        sizeRes.width = 77.74022 + (_special - 10.07);
        sizeRes.height = 77.620141;
    }
    return paths.filter((item) => corner ? true : item.type === Circle); 
}