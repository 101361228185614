import { Circle, Group, Layer, Line, Rect } from "react-konva";
import { AreaData, CANVAS_SIZE, DrawWallType, DrawedAreaMeta, DrawedWallMeta, WallData, m2px } from "../../../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Vector2d } from "konva/lib/types";
import { computeCurrentPosApproachOtherWallAnchors, computeIsCurrentPosApproachStartAnchorPos, computeIsWallCrossOtherWall, computeWallAnchors, computeWallByEndAnchor, computeWallDirectSign, computeWallEndAnchorPos } from "../../../util";
import { calculateWallContours } from "../../../gpt/calculateWallContours";
import { KonvaEventObject } from "konva/lib/Node";
import { message } from "antd";
import { ShapeWall, ShapeWallAnchorIndicator } from "../$shapes/shape-wall";
import { useWallBasicData, useNextAnchorPos } from "./hooks";
import { ShapeDrawWallHelpers } from "../$shapes/shape-helpers";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { generateUUID } from "three/src/math/MathUtils";
import { useWallDrawing } from "./store";

export const LayerDrawingStraightWall = () => {


    const {
        drawWallType, brushPos, stageScale, wallThickness, roomName, floorHeight, floorThickness,
        //开始绘画
        drawedWallList: drawedWalls, pushDrawedArea, pushDrawedWall, cancelDraw
    } = useWallDrawing();

    const [isDrawStraightWall, setIsDrawStraightWall] = useState(false);

    useEffect(() => {
        if (drawWallType === "wall-straight") {
            setIsDrawStraightWall(true);
        } else {
            setIsDrawStraightWall(false);
            setWallStartAnchor(null);
            setIsWallCross(false);
        }
    }, [drawWallType]);

    
    const [wallStartAnchor, setWallStartAnchor] = useState<Vector2d>(null);
    //画墙时的一些属性
    let wallLength = 0;
    let wallDirect: "x" | "y" = "x";
    const wallBasicData = useWallBasicData(brushPos, wallStartAnchor, wallThickness);
    const nextAnchorPos = useNextAnchorPos(brushPos, wallStartAnchor, wallThickness);
    if (wallBasicData) {
        wallLength = wallBasicData.wallLength;
        wallDirect = wallBasicData.wallDirect;
    }

    const [wallAnchors, setWallAnchors] = useState<any[]>([]);

    useEffect(() => {
        const anchors = computeWallAnchors(drawedWalls.map(w => w.data), false);
        setWallAnchors(anchors);
    }, [drawedWalls]);



    //墙是否相交
    const [isWallCross, setIsWallCross] = useState(false);
    //是否已经闭合
    const [closeWallAnchors, setCloseWallAnchors] = useState<Vector2d[]>([]);
    const [innerContour, setInnerContour] = useState<Vector2d[]>([]);
    const [outerContour, setOuterContour] = useState<Vector2d[]>([]);

    const [drawing, setDrawing] = useState(false);



    //辅助线相关
    const [isShowAnchor, setIsShowAnchor] = useState(false);
    const [isShowHelperLine, setIsShowHelperLine] = useState(false);
    const [isShowingCloseLine, setIsShowingCloseLine] = useState(false);

    const [showingHelperLines, setShowingHelperLines] = useState<{
        targetAnchor: Vector2d,
        approachEndAnchor: Vector2d
    }[]>([]);


    /**
     * 重置辅助点
     */
    const resetHelpers = (range = "all" as ("all" | "anchor" | "helper-line" | "close-line")) => {
        let resetAnchor = range === "all" || range === "anchor";
        let resetHelperLine = range === "all" || range === "helper-line";
        let resetCloseLine = range === "all" || range === "close-line";
        if (resetAnchor) {
            if (isShowAnchor) {
                setIsShowAnchor(false);
            }

        }
        if (resetHelperLine) {
            if (isShowHelperLine) {
                setIsShowHelperLine(false);
            }
            if (showingHelperLines.length > 0) {
                setShowingHelperLines([]);
            }

        }

        if (resetCloseLine) {
            if (isShowingCloseLine) {
                setIsShowingCloseLine(false);
            }
        }
    }


    /**
     * 开始该图层的绘画
     * @param pos 
     */
    const startDrawing = (pos: Vector2d) => {
        setWallStartAnchor({
            x: pos.x,
            y: pos.y,
        });
    }

    /**
     * 取消该图层的绘画
     * @param pos 
     */
    const cancelDrawing = () => {
        setWallStartAnchor(null);
        resetHelpers();
        cancelDraw();
    }


    /**
     * anchor显示逻辑
     */
    useEffect(() => {
        if (drawWallType && !wallStartAnchor) {
            setIsShowAnchor(true);
        } else {
            setIsShowAnchor(false);
        }
    }, [drawWallType, wallStartAnchor])


    /**
     * helper line和close line显示逻辑
     */
    useEffect(() => {

        if (drawedWalls.length === 0) {
            resetHelpers();
            return;
        }
        //墙体交叉碰撞检测

        if (!wallStartAnchor) {
            //showAnchor
            resetHelpers("helper-line");
        } else {
            //计算是否相交
            const isCross = computeIsWallCrossOtherWall(drawedWalls, wallStartAnchor, wallLength, wallDirect, wallThickness);
            const willClosed = computeIsCurrentPosApproachStartAnchorPos(drawedWalls[0].data, nextAnchorPos, wallStartAnchor, wallDirect, wallThickness);
            if (willClosed) {
                if (!isShowingCloseLine) {
                    const fakeWall = computeWallByEndAnchor(willClosed.approachEndAnchor, drawWallType, wallStartAnchor, wallLength, wallDirect, wallThickness, floorHeight, floorThickness);
                    const anchors = computeWallAnchors(drawedWalls.map(w => w.data).concat(fakeWall), true);
                    const contour = calculateWallContours(anchors, wallThickness);
                    setInnerContour(contour.innerContour);
                    setOuterContour(contour.outerContour);
                    setCloseWallAnchors(anchors);
                    setIsShowingCloseLine(true);

                }
                if (isCross) {
                    setIsWallCross(false);
                }
            } else {
                setIsShowingCloseLine(false);
                if (closeWallAnchors.length > 0) {
                    setCloseWallAnchors([]);
                }
                setIsWallCross(isCross);
            }
            //计算是否接近点
            const approachAnchorHelperLine = computeCurrentPosApproachOtherWallAnchors(drawedWalls, nextAnchorPos, wallStartAnchor, wallDirect, wallThickness);
            const showingHelperLines = [];
            if (approachAnchorHelperLine) {
                showingHelperLines.push(approachAnchorHelperLine);
            }
            if (!isCross && !willClosed && showingHelperLines.length > 0) {
                if (!isShowHelperLine) {
                    console.log(showingHelperLines);
                    
                    setShowingHelperLines(showingHelperLines);
                    setIsShowHelperLine(true);
                }
            } else {
                if (isShowHelperLine) {
                    setIsShowHelperLine(false);
                }
                if (showingHelperLines.length > 0) {
                    setShowingHelperLines([]);
                }
            }
        }

    }, [nextAnchorPos, drawedWalls, wallStartAnchor, wallBasicData]);
    
    useEffect(() => {
        if(wallStartAnchor && !drawing) {
            setDrawing(true);
        }
    }, [wallStartAnchor, brushPos]);


    const onAddWallWhenLengthSet = (inputLength: number) => {
        const sign = computeWallDirectSign(wallLength);
        const endAnchor = computeWallEndAnchorPos(wallStartAnchor, sign * inputLength, wallDirect, wallThickness);
        const wall = computeWallByEndAnchor(endAnchor, drawWallType, wallStartAnchor, sign * inputLength, wallDirect, wallThickness, floorHeight, floorThickness);
        pushDrawedWall({
            type: drawWallType,
            data: wall
        });
        setWallStartAnchor(wall.nextAnchorPos);
        setDrawing(false);
    }
    /**
     * 当墙添加的时候
     */
    const onWallAdd = (willClosed = false, approachEndAnchor?: Vector2d) => {
        if (drawWallType.indexOf("wall") === 0) {
            if (willClosed && !approachEndAnchor) {
                throw new Error("endAnchorPos required when wall will closed.");
            }
            if (willClosed) {
                const lastWall = computeWallByEndAnchor(approachEndAnchor, drawWallType, wallStartAnchor, wallLength, wallDirect, wallThickness, floorHeight, floorThickness);
                const anchors = computeWallAnchors(drawedWalls.map(w => w.data).concat(lastWall), true);
                const contour = calculateWallContours(anchors, wallThickness);
                const areaData: AreaData = {
                    id: generateUUID(),
                    areaName: roomName,
                    anchors,
                    ...contour,
                    wallThickness,
                    floorThickness,
                    walls: [
                        ...drawedWalls.map(w => w.data),
                        lastWall
                    ]
                }
                pushDrawedArea({
                    type:  "area",
                    data: areaData
                } as DrawedAreaMeta)
            } else {
                const approachAnchor = computeCurrentPosApproachOtherWallAnchors(drawedWalls, brushPos, wallStartAnchor, wallDirect, wallThickness);
                const wall = computeWallByEndAnchor(approachAnchor?.approachEndAnchor, drawWallType, wallStartAnchor, wallLength, wallDirect, wallThickness, floorHeight, floorThickness)
                pushDrawedWall({
                    type: drawWallType,
                    data: wall
                });
                setWallStartAnchor(wall.nextAnchorPos);
            }
            setDrawing(false);
        }
    }

    const onClick = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (e.evt.button === 0) {
                if (!wallStartAnchor) {
                    startDrawing({
                        x: brushPos.x,
                        y: brushPos.y,
                    });
                } else {
                    //画墙完成
                    const willClosed = computeIsCurrentPosApproachStartAnchorPos(drawedWalls[0]?.data, nextAnchorPos, wallStartAnchor, wallDirect, wallThickness);
                    if (willClosed) {
                        onWallAdd(true, willClosed.approachEndAnchor);
                        cancelDrawing();
                    } else {
                        if (wallLength === 0) {
                            return ;
                        } else if (Math.abs(wallLength) < wallThickness) {
                            message.warning("墙的长度不能小于墙的厚度");
                        } else {
                            if (willClosed) {
                                onWallAdd(true);
                            } else {
                                const isCross = computeIsWallCrossOtherWall(drawedWalls, wallStartAnchor, wallLength, wallDirect, wallThickness);
                                if (isCross) {
                                    message.warning("不能与其他墙相交");
                                } else {
                                    onWallAdd();
                                }
                            }
                        }
                    }
                }

            } else if (e.evt.button === 2) {
                cancelDrawing();
            }
        },
        [wallStartAnchor, brushPos, drawedWalls]
    )

    

    return (
        <Layer
            visible={isDrawStraightWall}
        >

            {
                useMemo(() => {
                    return drawing && (
                        <ShapeWallAnchorIndicator
                            x={nextAnchorPos.x}
                            y={nextAnchorPos.y}
                            wallThickness={wallThickness}
                            danger={isWallCross}
                        />
                    )
                }, [nextAnchorPos, isWallCross])
            }

            {
                useMemo(() => {
                    const isDanger = isWallCross;
                    const sign = computeWallDirectSign(wallLength)
                    return wallBasicData && drawing && (
                        <>
                            <ShapeWall
                                type={"fake"}
                                wallStartAnchor={wallStartAnchor}
                                wallDirect={wallDirect}
                                wallLength={wallLength}
                                wallThickness={wallThickness}
                                danger={isDanger}
                            />
                            {/* 向右偏移墙厚度 */}
                            <ShapeDimension 
                                from={wallDirect === 'x' ? wallStartAnchor.x + sign * wallThickness / 2 : wallStartAnchor.y + sign * wallThickness / 2}
                                to={wallDirect === 'x' ? wallStartAnchor.x  - sign * wallThickness / 2 + wallLength : wallStartAnchor.y - sign * wallThickness / 2 + wallLength} 
                                direct={wallDirect} 
                                base={wallDirect === 'x' ? wallStartAnchor.y : wallStartAnchor.x}
                                offset={wallThickness * 2}       
                                canInput
                                autoSelect
                                onLengthSet={(length) => {
                                    //下面长度的定义是startAnchorPos到endAnchorPos的长度，要补一个thickness，前面特殊处理减去了thickness，总共补两个thickness
                                    onAddWallWhenLengthSet(length + wallThickness * 2);
                                }}
                            />

                        </>
                    )
                }, [wallBasicData, isWallCross])
            }
            {/* 操作提示 */}
            {
                drawWallType && (
                    <ShapeDrawWallHelpers
                        isShowAnchor={isShowAnchor}
                        anchors={wallAnchors}
                        wallThickness={wallThickness}
                        isShowHelperLine={isShowHelperLine}
                        showingHelperLines={showingHelperLines}
                        isShowingCloseLine={isShowingCloseLine}
                        closeWallAnchors={closeWallAnchors}
                        showingInnerContour={innerContour}
                        showingOuterContour={outerContour}
                    />
                )
            }

            {/* 用于接住onClick事件 */}
            {
                isDrawStraightWall && (
                    <Rect
                        x={0}
                        y={0}
                        width={CANVAS_SIZE * m2px}
                        height={CANVAS_SIZE * m2px}
                        fill="#ffff0011"
                        opacity={0}
                        onClick={onClick}
                    />
                )
            }
        </Layer>
    )

}