import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { sanheyiWaijiaoPart2, sanheyiWaijiaoPartYuan } from "./@parts";
import { corner2Sanheyi } from "./corner2-sanheyi";


export const corner6Sanheyi = (
    _dx: DirectX, 
    _dy: DirectY, 
    startX: number, 
    startY: number, 
    corner: boolean, 
    sizeRes?: { 
        width: number, 
        height: number 
    }
): Path[] => {
    const dx = 1;
    const dy = 1;
    const corner2SanheyiSize = {
        width: 0,
        height: 0
    }
    let paths: Path[] = corner2Sanheyi(-dx, dy, startX, startY, corner, corner2SanheyiSize);
    paths = pathRotate2(paths, startX, startY, -90);
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === "Circle") {
            if(numberEqual(path.params[1], startY + dy * 32.8)) {
                path.params[1] = startY + dy * 15.35;
            }
        }
    }
    const width = corner2SanheyiSize.height;
    const height = corner2SanheyiSize.width;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
    }
    
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);

}