import { DirectX, DirectY } from "../../../constants/enum"
import { _02AcewaiBoardLeft } from "./02-a-cewai-left"

export const _12AcewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    menxiagaochu?: number,
    extraConfig?: any
): [Path[], Path[]] => {
    return _02AcewaiBoardLeft(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, lianjiekongbianju, corner, geceng, gecengX, gecengY, gecengkongjing, gxinglashou, gxinglashouType, gxinglashouGaodu, menxiagaochu, extraConfig);
}