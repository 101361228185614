import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { _02cewaiBoardRight } from "./02-cewai-right";

export const _12AcewaiBoardMiddle = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
): [Path[], Path[]] => {
    const [boardPaths, cornerPaths] = _02cewaiBoardRight(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju - 18, -1, corner, false, gecengX, gecengY, gecengkongjing, gxinglashou, gxinglashouType, gxinglashouGaodu, undefined, {
        noMiddleware: true
    });
    for (let i = 0; i < cornerPaths.length; i++) {
        const temp = cornerPaths[i];
        if(temp.type === Circle) {
            if(numberEqual(temp.params[1], startY + dy * 32.385)) {
                temp.params[1] =  startY + dy * 15.385;
            }else if(numberEqual(temp.params[1], startY + dy * height -  dy * 32.385)) {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }

            else if(numberEqual(temp.params[0], startX + dx * 32.385)) {
                temp.params[0] =  startX + dx * 15.385;
            }else if(temp.params[0], startX + dx * width - dx * 32.385) {
                temp.params[0] = startX + dx * width - dx * 15.385;
            }
        }
    }
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6) - dy * 18,
                (gecengkongjing || 3) / 2,
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6) - dy * 18,
                (gecengkongjing || 3) / 2,
            ]
        });
    }
    return [boardPaths, cornerPaths];
}