import { _09ceneiBoardLeft } from "../../boards/水槽柜/09-cenei-left";
import { _09ceneiBoardRight } from "../../boards/水槽柜/09-cenei-right";
import { _09cewaiBoardLeft } from "../../boards/水槽柜/09-cewai-left";
import { _09cewaiBoardRight } from "../../boards/水槽柜/09-cewai-right";
import { _09dineiBoard } from "../../boards/水槽柜/09-dinei";
import { _09diwaiBoard } from "../../boards/水槽柜/09-diwai";
import { _09gecengneiBoard } from "../../boards/水槽柜/09-gecengnei";
import { _09gecengwaiBoard } from "../../boards/水槽柜/09-gecengwai";
import { _09menneiBoard } from "../../boards/水槽柜/09-mennei";
import { _09menwaiBoard } from "../../boards/水槽柜/09-menwai";
import { _09qiandangneiBoard } from "../../boards/水槽柜/09-qiandangnei";
import { _09qiandangwaiBoard } from "../../boards/水槽柜/09-qiandangwai";
import { BaseCupboard } from "../@base/BaseCupboard";

export class ShuicaoguiCebaobei extends BaseCupboard {
    key: string = "ShuicaoguiCebaobei";
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            ceneiBoardLeft: _09ceneiBoardLeft,
            ceneiBoardRight: _09ceneiBoardRight,
            cewaiBoardLeft: _09cewaiBoardLeft,
            cewaiBoardRight: _09cewaiBoardRight,
            dineiBoard: _09dineiBoard,
            diwaiBoard: _09diwaiBoard,
            menneiBoard: _09menneiBoard,
            menwaiBoard: _09menwaiBoard,
            "menwaiBoard-left": _09menwaiBoard,
            "menneiBoard-left": _09menneiBoard,
            "menwaiBoard-right": _09menwaiBoard,
            "menneiBoard-right": _09menneiBoard,
            qiandangneiBoard1: _09qiandangneiBoard,
            qiandangwaiBoard1: _09qiandangwaiBoard,
            qiandangneiBoard2: _09qiandangneiBoard,
            qiandangwaiBoard2: _09qiandangwaiBoard,
            gecengneiBoard: _09gecengneiBoard,
            gecengwaiBoard: _09gecengwaiBoard,
        }
    }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [deep + 47, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep + 21, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21, height + 3],
            },
            // "beiwaiBoard": {
            //     size: [long + 11, height + 29]
            // },
            // "beineiBoard": {
            //     size: [long - 15, height + 3]
            // },
            "qiandangwaiBoard1": {
                size: [long + 11, 74 + 47],
                //rotate90: true,
                // quantity: 2
            },
            "qiandangneiBoard1": {
                size: [long - 44, 74 + 21],
                //rotate90: true,
                // quantity: 2
            },
            "qiandangwaiBoard2": {
                size: [long + 11, 74 + 47],
                //rotate90: true,
                // quantity: 2
            },
            "qiandangneiBoard2": {
                size: [long - 44, 74 + 21],
                //rotate90: true,
                // quantity: 2
            }
        };
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
         /* 2021 12 27 - 5 G型拉手门内高度+13*/
         let menneiHeightIncre = 0;
        let menneiWidthIncre = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                menneiHeightIncre = 13;
            }else {
                menneiWidthIncre = 13;
            }
        }
        if(extraConfig.doorNumber === 2) {
            list["menneiBoard-left"] = {
                size: [long / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-left"] = {
                size: [long / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],   
                quantity: 1,
            }
            list["menneiBoard-right"] = {
                size: [long / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-right"] = {
                size: [long / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }else {
            list["menneiBoard"] = {
                size: [long - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard"] = {
                size: [long - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }
        
        if(extraConfig.geceng) {
            list['gecengwaiBoard'] = {
                size: [long + 11, deep - extraConfig.gecengX + 29],
            };
            list['gecengneiBoard'] = {
                size: [long - 15, deep - extraConfig.gecengX + 3]
            };
        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
        // if(extraConfig.nobeinei) {
        //     delete list["beineiBoard"];
        // }
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        if(extraConfig.上免拉手) {
            delete list['qiandangneiBoard1'];
            delete list['qiandangwaiBoard1'];

        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.guituiX, extraConfig.guituiY, extraConfig);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            if(extraConfig.doorNumber === 2) {
                temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                    //from 2022!!! 都在一个对象里
                    //可选， 上、中免拉手X、Y、Z
                    上免拉手: Boolean(extraConfig.上免拉手),
                    上免拉手X: Number(extraConfig.上免拉手X),
                    上免拉手Y: Number(extraConfig.上免拉手Y),
                    中免拉手: Boolean(extraConfig.中免拉手),
                    中免拉手X: Number(extraConfig.中免拉手X),
                    中免拉手Y: Number(extraConfig.中免拉手Y),
                    中免拉手Z: Number(extraConfig.中免拉手Z),
                    mengao: extraConfig.mengao,
                });                    
            }else {
                if(board.name.toLowerCase().includes(extraConfig.doorSide)) {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                        //from 2022!!! 都在一个对象里
                        //可选， 上、中免拉手X、Y、Z
                        上免拉手: Boolean(extraConfig.上免拉手),
                        上免拉手X: Number(extraConfig.上免拉手X),
                        上免拉手Y: Number(extraConfig.上免拉手Y),
                        中免拉手: Boolean(extraConfig.中免拉手),
                        中免拉手X: Number(extraConfig.中免拉手X),
                        中免拉手Y: Number(extraConfig.中免拉手Y),
                        中免拉手Z: Number(extraConfig.中免拉手Z),
                        mengao: extraConfig.mengao,
                    });
                }else {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, -1, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                        //from 2022!!! 都在一个对象里
                        //可选， 上、中免拉手X、Y、Z
                        上免拉手: Boolean(extraConfig.上免拉手),
                        上免拉手X: Number(extraConfig.上免拉手X),
                        上免拉手Y: Number(extraConfig.上免拉手Y),
                        中免拉手: Boolean(extraConfig.中免拉手),
                        中免拉手X: Number(extraConfig.中免拉手X),
                        中免拉手Y: Number(extraConfig.中免拉手Y),
                        中免拉手Z: Number(extraConfig.中免拉手Z),
                        mengao: extraConfig.mengao,
                    });
                }
            }
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang,  doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }

}