import { Circle, Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";

export const corner19 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const paths: Path[] = [
        {
            type: Line,
            params: [
                startX, startY,
                startX + dx * 29, startY
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 29, startY,
                startX + dx * 29, startY + dy * 86
            ]
        }
    ]
    if (typeof sizeRes === 'object') {
        // if (!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // } else {
            sizeRes.width = 29;
            sizeRes.height = 86;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}