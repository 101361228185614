import { BaseCupboard } from "../@base/BaseCupboard";

import { _07zhuanjiaoneiXBoard } from "../../boards/转角柜/07-zhuanjiaonei-x";
import { _07zhuanjiaowaiXBoard } from "../../boards/转角柜/07-zhuanjiaowai-x";
import { _07zhuanjiaoneiYBoard } from "../../boards/转角柜/07-zhuanjiaonei-y";
import { _07zhuanjiaowaiYBoard } from "../../boards/转角柜/07-zhuanjiaowai-y";
import { _07dineiBoard } from "../../boards/转角柜/07-dinei";
import { _07diwaiBoard } from "../../boards/转角柜/07-diwai";
import { _07ceneiBoardLeft } from "../../boards/转角柜/07-cenei-left";
import { _07ceneiBoardRight } from "../../boards/转角柜/07-cenei-right";
import { _07lineiBoard } from "../../boards/转角柜/07-linei";
import { _07liwaiBoard } from "../../boards/转角柜/07-liwai";
import { _07fengneiBoard } from "../../boards/转角柜/07-fengnei";
import { _07fengwaiBoard } from "../../boards/转角柜/07-fengwai";
import { _07cewaiBoardLeft } from "../../boards/转角柜/07-cewai-left";
import { _07cewaiBoardRight } from "../../boards/转角柜/07-cewai-right";
import { _07beiwaiBoard } from "../../boards/转角柜/07-beiwai";
import { _07beineiBoard } from "../../boards/转角柜/07-beinei";
import { _07menwaiBoard } from "../../boards/转角柜/07-menwai";
import { _07menneiBoard } from "../../boards/转角柜/07-mennei";
import { _07qiandangneiBoard } from "../../boards/转角柜/07-qiandangnei";
import { _07qiandangwaiBoard } from "../../boards/转角柜/07-qiandangwai";


export class LeftZhuanjiaoguiCebaobei extends BaseCupboard {
    key: string = "LeftZhuanjiaoguiCebaobei";

    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: _07dineiBoard,
            diwaiBoard: _07diwaiBoard,
            ceneiBoardLeft: _07ceneiBoardLeft,
            ceneiBoardRight: _07ceneiBoardRight,
            cewaiBoardLeft: _07cewaiBoardLeft,
            cewaiBoardRight: _07cewaiBoardRight,
            beiwaiBoard: _07beiwaiBoard,
            beineiBoard: _07beineiBoard,
            menwaiBoard: _07menwaiBoard,
            menneiBoard: _07menneiBoard,
            "menwaiBoard-left": _07menwaiBoard,
            "menneiBoard-left": _07menneiBoard,
            "menwaiBoard-right": _07menwaiBoard,
            "menneiBoard-right": _07menneiBoard,
            qiandangneiBoard: _07qiandangneiBoard,
            qiandangwaiBoard: _07qiandangwaiBoard,
            zhuanjiaoneiXBoard: _07zhuanjiaoneiXBoard,
            zhuanjiaowaiXBoard: _07zhuanjiaowaiXBoard,
            zhuanjiaoneiYBoard: _07zhuanjiaoneiYBoard,
            zhuanjiaowaiYBoard: _07zhuanjiaowaiYBoard,
            lineiBoard: _07lineiBoard,
            liwaiBoard: _07liwaiBoard,
            fengneiBoard: _07fengneiBoard,
            fengwaiBoard: _07fengwaiBoard
        }
    }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
         /* 2021 12 27 - 5 G型拉手门内高度+13*/
         let menneiHeightIncre = 0;
         let menneiWidthIncre = 0;
         if(extraConfig.gxinglashou) {
             if(extraConfig.gxinglashouType === "up") {
                 menneiHeightIncre = 13;
             }else {
                 menneiWidthIncre = 13;
             }
         }
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [deep + 47, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep + 21, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21, height + 3],
            },
            "beiwaiBoard": {
                size: [long + 11, height + 29]
            },
            "beineiBoard": {
                size: [long- 15, height + 3]
            },
            "qiandangwaiBoard": {
                size: [long + 11, 74 + 47],
                //rotate90: true,
            },
            "qiandangneiBoard": {
                size: [long - 44, 74 + 21],
                //rotate90: true,
            },
            // "menneiBoard": {
            //     size: [extraConfig.doorWidth + 19 - wReduce + menneiWidthIncre, /* height - 19 */extraConfig.mengao + 19 - hReduce + menneiHeightIncre],
            // },
            // "menwaiBoard": {
            //     size: [extraConfig.doorWidth + 45 - wReduce, /* height + 7 */extraConfig.mengao + 45 - hReduce],
            // },
            "lineiBoard": {
                size: [96, height + 3 - 14.6]
            },
            "liwaiBoard": {
                size: [122, height + 29]
            },
            "fengneiBoard": {
                size: [121, height - 19]
            },
            "fengwaiBoard": {
                size: [147, height + 7]
            }
        }
        if(extraConfig.doorNumber === 2) {
            list["menneiBoard-left"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-left"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],   
                quantity: 1,
            }
            list["menneiBoard-right"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-right"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }else {
            list["menneiBoard"] = {
                size: [extraConfig.doorWidth + 19 - wReduce + menneiWidthIncre, /* height - 19 */extraConfig.mengao + 19 - hReduce + menneiHeightIncre],
            };
            list["menwaiBoard"] = {
                size: [extraConfig.doorWidth + 45 - wReduce, /* height + 7 */ extraConfig.mengao + 45 - hReduce],
            };
        }
        if(extraConfig.nobeinei) {
            delete list["beineiBoard"];
        }
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        
        if(extraConfig.上免拉手) {
            delete list['qiandangneiBoard'];
            delete list['qiandangwaiBoard'];
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.x, extraConfig.y, extraConfig.guituiX, extraConfig.guituiY, extraConfig.doorWidth, extraConfig);
        }else if(board.name === "diwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.x, extraConfig.y, extraConfig.doorWidth, extraConfig.waibanjiao);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
                doorNumber: Number(extraConfig.doorNumber),
                jiaolianbianju: Number(extraConfig.jiaolianbianju),
            });                    
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name === "liwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.waibanjiao, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        }else if(board.name === "lineiBoard") {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }

    
    
}