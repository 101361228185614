import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner3 } from "../../coners/corner3";
import { corner4 } from "../../coners/corner4";
import { corner7 } from "../../coners/corner7";
import { rectBoard } from "../general-board";

// export const 
export const ceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any,
 ): [Path[], Path[]]  => {
    const {
        上免拉手  ,
        上免拉手X ,
        上免拉手Y ,
        中免拉手  ,
        中免拉手X ,
        中免拉手Y ,
        中免拉手Z
    } = extraConfig;
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];
    let cornerBLW: number = 0;
    let cornerBLH: number = 0;
    if(!extraConfig.isMeiqiguangui) {
        cornerBL = corner3(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }else {
        cornerBL = corner7(dx, -dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }

    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner4(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    if(extraConfig.isMeiqiguangui) {
        cornerBR = corner1(-dx, -dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    
    //右上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(!上免拉手) {
        cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        if(qiandanghengfang) {
            cornerTL = pathMoveY(cornerTL, -DirectY.UP, height);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTL = pathRotate2(cornerTL, startX, startY, 90);
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            const widthTemp = cornerTLSize.width;
            cornerTLW = cornerTLSize.height;
            cornerTLH = widthTemp;
        }
    }else {
        cornerTL.push({
            type: "Line",
            params: [
                startX, startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
            ] 
        }, {
            type: "Line",
            params: [
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height,
            ]
        });
        cornerTLH = 上免拉手Y + 10.5;
        cornerTLW = 上免拉手X + 10.5;
    }
   

    const boardConfig: any = {
        middleware: {
            top: false,
            left: false,
            right: height > 500 && true,
            bottom: extraConfig?.isMeiqiguangui ? false : width > 500 && "close",
        }
    }
    let paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);

    if(中免拉手) {
        paths = paths.filter((_, index) => index !== 3);
        paths.push({
            type: Line,
            params: [
                startX, startY + dy * cornerBLH,
                startX, startY + dy * (中免拉手Z - 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z - 7.5),
                startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5),
                startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
            ]
        }, {                                                                                                                                                     
            type: Line,
            params: [
                startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                startX,  startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                startX, startY + dy * height - dy * cornerTLH
            ]
        })
    }


    if(lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }
   
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];
}