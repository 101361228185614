import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _02AdineiBoard } from "./02-a-dinei";

export const _12AdineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number
): [Path[], Path[]] => {
    const [boardsPath, cornerPaths] = _02AdineiBoard(dx, dy, startX, startY, width, height);
    
    cornerPaths.push(
        {
            type: Circle,
            params: [
                startX + dx * ( a - 16.5), startY + dy * 32.03,
                3.65
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * ( a - 16.5), startY + dy * (31.5 + 32.03),
                3.65
            ]
        },
        
        {
            type: Circle,
            params: [
                startX + dx * ( a - 16.5), startY + dy * height - dy * (31.5 + 32.03),
                3.65
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * ( a - 16.5), startY + dy * height - dy * 32.03,
                3.65
            ]
        }
        
    )
    
    
    return [boardsPath, cornerPaths];
}