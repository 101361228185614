import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { sanheyiWaijiaoPart2, sanheyiWaijiaoPartJin, sanheyiWaijiaoPartYuan } from "./@parts";
import { corner2Sanheyi } from "./corner2-sanheyi";


export const corner5Sanheyi = (
    _dx: DirectX, 
    _dy: DirectY, 
    startX: number, 
    startY: number, 
    corner: boolean, 
    sizeRes?: { 
        width: number, 
        height: number 
    }
): Path[] => {
    const dx = 1;
    const dy = 1;
    const corner2SanheyiSize = {
        height: 0,
        width: 0
    }
    let paths: Path[] = corner2Sanheyi(dx, -dy, startX, startY, corner, corner2SanheyiSize);
    paths = pathMoveY(paths, DirectY.UP, corner2SanheyiSize.height);
    const width = 69.6;
    const height = 69.6;
    paths.push({
        type: Line,
        params: [
            startX + dx * corner2SanheyiSize.width, startY,
            startX + dx * corner2SanheyiSize.width + dx * 10.399665, startY
        ]
    });
    const sx = startX + dx * corner2SanheyiSize.width + dx * 10.399665;
    const sy = startY;
    let partPaths = sanheyiWaijiaoPartJin(sx, sy, dx, dy);
    partPaths = pathRotate2(partPaths, sx, sy, 90);
    partPaths = pathMoveX(partPaths, DirectX.RIGHT, dx * 25.200000);
    paths.push(...partPaths);
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
    }
    
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);

}