import { DirectX, DirectY } from "../../../constants/enum";
import { _02gecengwaiBoard } from "./02-gecengwai";

export const _12AgecengwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
): [Path[], Path[]] => {
    return _02gecengwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
}