import { useContext, useEffect, useMemo, useState } from "react";
import { FloorPlannerContext } from "./context-reducer";
import { Vector2d } from "konva/lib/types";
import { Stage } from "konva/lib/Stage";
import { Vector2dLine } from "./types";

//画布大小 (m)
export const CANVAS_SIZE = 200;
//窗口画布大小（m)
const CANVAS_WINDOW_SIZE = 12;
//网格间隙（m)
const GRID_SPAN = 0.4;



//单位转换
export const m2px  = 1000 / CANVAS_WINDOW_SIZE; //1000像素对30米
export const px2m = 1 / m2px;
// export const defaultWallThickness = 0.24 * m2px;
export const postDefaultSize = 0.4 * m2px;
export const onwallDefaultSize = 1 * m2px;
export const defaultWallHeight = 2.8 * m2px;
export const defaultFloorThickness = 0.12 * m2px;
export const defaultWallThickness = 0.24 * m2px;
export const defaultDoorHeight = 2 * m2px;
export const defaultWindowHeight = 1.4 * m2px;
export const defaultWindowGroundClearance = 0.9 * m2px;
export const defaultRoomName = "厨房";


export const konva = {
    stage: null as Stage
}

export type DrawWallType = "wall-straight" | "wall-round" | "wall-rectangle";
export type DrawStructType ="struct-rect-post" |  "struct-round-post" | "struct-beam-x" | "struct-beam-y";
export type DrawAreaType = "area" | "area-rect";
export type DrawOnWallType = "onwall-single-door" | "onwall-double-door" |"onwall-window" | "onwall-sliding-door";

type DrawType = DrawWallType | DrawOnWallType | DrawStructType | DrawAreaType;
type DrawData = WallData | AreaData | StructData | OnWallData;

export type Drawed<T extends DrawType, W extends DrawData> = {
    type: T,
    data: W;
}

export interface BaseShapeData {
    id: string;
}
/**
 * 墙数据
 */
export interface WallData extends BaseShapeData {
    wallType: DrawWallType,
    startAnchorPos: Vector2d,
    endAnchorPos: Vector2d,
    nextAnchorPos: Vector2d,
    wallLength: number,
    wallDirect: "x" | "y",
    wallThickness: number,
    floorHeight: number,
    floorThickness: number,
}
/**
 * 区域数据
 */
export interface AreaData extends BaseShapeData {
    areaName: string;
    anchors: Vector2d[]
    outerContour: Vector2d[],
    innerContour: Vector2d[],
    innerBound: ShapeBound;
    outerArea: number;
    innerArea: number;
    outerBound: ShapeBound;
    wallThickness: number;
    floorThickness: number;    //地面厚度
    walls: WallData[]
}

/**
 * 结构数据
 */
export interface StructData extends BaseShapeData {


    structType: DrawStructType,
    pos: Vector2d,
    direct?: "x" | "y", //type为beam时，得考虑贯穿
    size: { //圆形的x===y
        x: number,
        y: number,
        z: number
    },
    offset: {
        top: number,
        bottom: number,
        left: number,
        right: number,
        topLine: Vector2dLine,
        leftLine: Vector2dLine,
        rightLine: Vector2dLine,
        bottomLine:Vector2dLine
        
    },
    groundClearance: number;
    floorThickness: number,
    floorHeight: number,

}

/**
 * 在墙上的数据
 */
export interface OnWallData extends BaseShapeData {
    onWallType: DrawOnWallType,
    openDirect: "top" | "left" | "right" | "bottom";    //开扇方向
    openClockwise?: boolean;                            //单开扇门是否顺时针
    onWallWidth: number;                                //在墙上的宽度
    onWallHeight: number;                               //在墙上的高度
    anchor: number;                                     //锚点，根据openDirect来确定是anchorX还是anchorY
    wall: { from: Vector2d, to: Vector2d };             //门从哪开始到哪结束
    wallThickness: number;
    onWallThickness: number;                            //在墙上的厚度, 主要用于2d区分门和窗，3D场景下开洞开始整墙厚度
    groundClearance: number;
    floorThickness: number;
}

export interface ShapeBound{
    left: number, top: number, right: number, bottom: number
}

export type DrawedWallMeta = Drawed<DrawWallType, WallData>;
export type DrawedAreaMeta = Drawed<DrawAreaType, AreaData>;
export type DrawedStructMeta = Drawed<DrawStructType, StructData>;
export type DrawedOnWallMeta = Drawed<DrawOnWallType, OnWallData>;

export type DrawedMeta = ( 
    DrawedWallMeta | 
    DrawedAreaMeta |
    DrawedStructMeta |
    DrawedOnWallMeta
);

export const initialFloorPlannerData = {
    stageScale: 1,
    roomName: defaultRoomName,
    stageCursor: null as ("move"),
    floorHeight: defaultWallHeight,
    floorThickness: defaultFloorThickness,
    wallThickness: defaultWallThickness,
    drawType: null as DrawType,
    brushPos: { x: 0, y: 0 },
    // drawingStartPos: null as Vector2d
    drawedList: [] as DrawedMeta[],
    redoList: [] as DrawedMeta[],
    isEditing: false,
    editTarget: null as DrawedMeta,
    selectedTargets: [] as DrawedMeta[],
}

export const actionMap = {
    "setStage": (state, payload) => {
        konva.stage = payload;
        return state;
    },
    "setFloorHeight": (state, payload: number) => {
        return {
            ...state,
            floorHeight: payload
        }
    },
    "setFloorThickness": (state, payload: number) => {
        return {
            ...state,
            floorThickness: payload
        }
    },
    "stageScaleUpdated": (state, payload /* stageScale */) => {
        return { 
            ...state,
            stageScale: payload
        }
    },
    "setStageScale": (state, payload: number) => {
        konva.stage.setAttrs({
            scaleX: payload,
            scaleY: payload,
        });
        return {
            ...state,
            stageScale: payload
        }
    },
    setStageScaleAndToCenter: (state, payload: number) => {
        konva.stage.setAttrs({
            scaleX: payload,
            scaleY: payload,
            x: -((CANVAS_SIZE * m2px * payload - window.innerWidth) / 2),
            y: -((CANVAS_SIZE * m2px * payload - window.innerHeight) / 2),
        });
        return {
            ...state,
            stageScale: payload
        }
    },

    "toStageCenter": (state) => {
        konva.stage.setAttrs({
            x: -((CANVAS_SIZE * m2px - window.innerWidth) / 2),
            y: -((CANVAS_SIZE * m2px - window.innerHeight) / 2),
        });
        return state;
    },
    "startDraw": (state, payload: DrawType) => {
        return {
            ...state,
            drawType: payload
        }
    },
    // "startDrawing": (state, payload: Vector2d) => {
    //     return {
    //         ...state,
    //         drawingStartPos: payload
    //     }
    // },
    "cancelDraw": (state: State, payload) => {
        return {
            ...state,
            drawType: null,
            brushPos: {x: 0, y: 0},
            drawingStartPos: null,
            isEditing: false,
            editTarget: null,
            stageCursor: null,
            selectedTargets: []
        }  
    },
    "setBrushPos": (state, payload: Vector2d) => {
        return {
            ...state,
            brushPos: payload
        }
    },
    "pushDrawed": (state:State, payload: Drawed<DrawWallType, WallData>): State => {
        return {
            ...state,
            redoList: [],
            drawedList: state.drawedList.concat(payload)
        }
    },
    "updateDrawedList": (state: State, payload: DrawedMeta[]): State => {
        return {
            ...state,
            drawedList: payload
        }
    },
    "updateRedoList": (state: State, payload: DrawedMeta[]): State => {
        return {
            ...state,
            redoList: payload
        }
    },
    "setStageCursor": (state: State, payload: "move" | null) => {
        return {
            ...state,
            stageCursor: payload
        }
    },
    "updateDrawed": (state: State, payload: DrawedStructMeta): State => {
        return {
            ...state,
            redoList: [],
            drawedList: state.drawedList.map((d) => {
                if(d.data.id === payload.data.id) {
                    return payload;
                }
                return d;
            }),
            editTarget: state.editTarget && state.editTarget.data.id === payload.data.id ? 
                            payload : state.editTarget
        }
    },
    "updateRoomName": (state: State, payload: string): State => {
        return {
            ...state,
            roomName: payload
        }
    },
    "updateWallThickness": (state: State, payload: number): State => {
        return {
            ...state,
            wallThickness: payload
        }
    },
    "updateFloorHeight": (state: State, payload: number): State => {
        return {
            ...state,
            floorHeight: payload
        }
    },
    "updateFloorThickness": (state: State, payload: number): State => {
        return {
            ...state,
            floorThickness: payload
        }
    },
    //选择逻辑和编辑逻辑
    //选择与编辑逻辑，如果选择多个，则取消编辑状态，如果选择一个，则进入当前的编辑状态
    "pushSelectTarget": (state: State, payload: DrawedMeta): State => {
        const selectedTargets = state.selectedTargets.concat(payload);
        if(selectedTargets.length > 1) {
            return {
                ...state,
                isEditing: false,
                editTarget: null,
                selectedTargets
            }
        }else if(selectedTargets.length === 1) {
            return {
                ...state,
                isEditing: true,
                editTarget: selectedTargets[0],
                selectedTargets
            }
        }
    },
    //startEdit和setSelectedTarget逻辑一致
    "startEdit": (state: State, payload: DrawedMeta): State => {
        return {
            ...state,
            isEditing: true,
            editTarget: payload,
            selectedTargets: [payload]
        }
    },
    "setSelectedTarget": (state: State, payload: DrawedMeta): State => {
        return {
            ...state,
            isEditing: true,
            editTarget: payload,
            selectedTargets: [payload]
        }
    },
    "clearSelectTarget": (state: State): State => {
        return {
            ...state,
            isEditing: false,
            editTarget: null,
            selectedTargets: []
        }
    },
    "removeSelectedTargets": (state: State) => {
        console.log("removeSelectedTargets", state.selectedTargets);
        
        const drawedList = state.drawedList.filter((d) => {
            return state.selectedTargets.indexOf(d) === -1;
        });
        return {
            ...state,
            drawedList,
            selectedTargets: [],
            isEditing: false,
            editTarget: null
        }
    }
}


export const useWidgets = () => {
    // console.debug("[hook]useStageScale");
    const { state, dispatch } = useContext(FloorPlannerContext);

    // 第一阶段，仅限制一个闭合区域
    const [areaDrawed, setAreaDrawed] = useState(false);

    useEffect(() => {
        const area = state.drawedList.find((item) => item.type === 'area');
        if(!area) {
            setAreaDrawed(false);
        }else {
            setAreaDrawed(true);
        }
    }, [state.drawedList])
    
    const setStageScale = (scale: number)=>{
        dispatch({
            type: "setStageScale",
            payload: scale
        });
    }

    const toStageCenter = () => {
        dispatch({
            type:"toStageCenter"
        });
    }

    const setStageScaleAndToCenter = (scale: number) => {
        dispatch({
            type: "setStageScaleAndToCenter",
            payload: scale
        });
    }

    const startDraw = (type: DrawType) =>{
        dispatch({
            type: "startDraw",
            payload: type
        })
    }

    return {
        areaDrawed,
        stageScale: state.stageScale,
        setStageScale,
        toStageCenter,
        setStageScaleAndToCenter,
        startDraw,
        drawType: state.drawType,
        editTarget: state.editTarget
    };
}


export const useGridMeasure = () => {
    // console.debug("[hook]useGridMeasure");
    
    const {  state, dispatch } = useContext(FloorPlannerContext);

    const setStageRef = (r: Stage) => {
        if(r) {
            konva.stage = r;
        }
    }

    
    const updateStageScale = (scale: number) => {
        dispatch({
            type: "stageScaleUpdated",
            payload: scale
        });
    }

    /**
     * 汇报
     * @param x 
     * @param y 
     */
    const reportMouseInStagePos = (pos: Vector2d) => {
        dispatch({
            type: "setBrushPos",
            payload: pos
        })
    }

    const onCancelDrawing = () => {
        dispatch({
            type: "cancelDraw"
        })
    }


    return {
        stageCursor: state.stageCursor,
        initX: -((CANVAS_SIZE * m2px - window.innerWidth) / 2),
        initY: -((CANVAS_SIZE * m2px - window.innerHeight) / 2),
        updateStageScale,
        setStageRef,
        
        grid: {
            gridSpan: GRID_SPAN * m2px,
            gridLines: CANVAS_SIZE / GRID_SPAN,
            gridLineLength: CANVAS_SIZE * m2px,
    
        },
        isEditing: state.isEditing,
        drawType: state.drawType,
        reportMouseInStagePos,
        onCancelDrawing,
    }

}


export const useDrawedArea = () => {
    const { state, dispatch } = useContext(FloorPlannerContext);
    const [ drawedArea, setDrawedArea ] = useState<AreaData>(null);

    useEffect(() => {
        const area = state.drawedList.find((d) => d.type === 'area');
        if(area) {
            setDrawedArea(area.data as AreaData);
        }else {
            setDrawedArea(null);
        }
    }, [state.drawedList]);
    return drawedArea;
}


export const useIsDrawing = () => {
    const { state, dispatch } = useContext(FloorPlannerContext);
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        if(state.drawType) {
            setIsDrawing(true);
        }else{
            setIsDrawing(false);
        }
    },[state.drawType]);

    return isDrawing;

}

export const useDrawingAndEditing = () => {
    const {  state, dispatch } = useContext(FloorPlannerContext);
    const isDrawing = useIsDrawing();
    return {
        isDrawing,
        drawType: state.drawType,
        isEditing: state.isEditing,
        editTarget: state.editTarget
    }
}


export const useDrawed = () => {

    const {  state, dispatch } = useContext(FloorPlannerContext);

    const isDrawing = useIsDrawing();

    const startEdit = (drawed: DrawedMeta) => {
        
        dispatch({
            type: "startEdit",
            payload: drawed
        })
    }
    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        })
    }

    return {
        stage: konva.stage,
        isEditing: state.isEditing,
        isDrawing,
        drawedList: state.drawedList,
        startEdit,
        cancelDraw
    }

}



export const useBrushPos = () => {
    const { state, dispatch } = useContext(FloorPlannerContext);
    const [brushPos, setBrushPos] = useState<Vector2d>({x: -Infinity, y: -Infinity});
    useEffect(() => {
        if(state.brushPos.x !== brushPos.x || state.brushPos.y !== brushPos.y) {
            setBrushPos(state.brushPos);
        }
    }, [state.brushPos]);
    return brushPos;
}

export const useDrawType = () => {
    const { state, dispatch } = useContext(FloorPlannerContext);
    // const [drawTarget, setDrawTarget] = useState<DrawedMeta>(null);
    const [drawType, setDrawType] = useState(state.drawType);
    useEffect(() => {
        setDrawType(state.drawType);
    }, [state.drawType]);

    return drawType;
}


export const useSpace = () => {
    const { state, dispatch } = useContext(FloorPlannerContext);

    const updateRoomName = (roomName: string) => {
        dispatch({
            type: "updateRoomName",
            payload: roomName
        });
    }
    const updateFloorHeight = (floorHeight: number) => {
        dispatch({
            type: "updateFloorHeight",
            payload: floorHeight
        });
    }
    const updateFloorThickness = (floorThickness: number) => {
        dispatch({
            type: "updateFloorThickness",
            payload: floorThickness
        });
    }
    const updateWallThickness = (wallThickness: number) => {
        dispatch({
            type: "updateWallThickness",
            payload: wallThickness
        });
    }
    
    const [areaDrawed, setAreaDrawed] = useState(false);
    const [wallDrawed, setWallDrawed] = useState(false);
    useEffect(() => {
        const area = state.drawedList.find((item) => item.type === 'area');
        if(!area) {
            setAreaDrawed(false);
        }else {
            setAreaDrawed(true);
        }
    }, [state.drawedList]);

    useEffect(() => {
        const wall = state.drawedList.find((item) => item.type.indexOf("wall") === 0);
        if(!wall) {
            setWallDrawed(false);
        }else {
            setWallDrawed(true);
        }
    }, [state.drawedList]);

    const isDrawing = useIsDrawing();

    return useMemo(() => {
        return {
            isDrawing,
            areaDrawed,
            wallDrawed,
            roomName: state.roomName,
            floorHeight: state.floorHeight,
            floorThickness: state.floorThickness,
            wallThickness: state.wallThickness,
            updateRoomName,
            updateFloorHeight,
            updateFloorThickness,
            updateWallThickness,
        }
    }, [state.roomName, state.floorHeight, state.floorThickness, state.wallThickness, areaDrawed, wallDrawed, isDrawing]);

}

export type State = typeof initialFloorPlannerData;
export type Action =  { type: keyof (typeof actionMap), payload?: any };