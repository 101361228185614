import { Arc, Circle, Line } from "../../../constants";

const sanheyiWaiKacao = (sx: number, sy: number, dx: number, dy: number) => {
    const paths: Path[] = [
        {
            type: Line,
            params: [
                sx, sy,
                sx + dx * 2.359608, sy + dy * 0.090754
            ]
        },
        {
            type: Arc,
            params: [
                sx + dx * 2.359608 + dx * 0.240392 - dx * 0.25, sy + dy * 0.090754 + dy * 0.249815,
                0.25,
                (360 - 87.797320594), 360
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2.359608 + dx * 0.240392, sy + dy * 0.090754 + dy * 0.249815,
                sx + dx * 2.359608 + dx * 0.240392, sy + dy * 0.090754 + dy * 0.249815 + dy * 1.518861,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2.359608 + dx * 0.240392 - dx * 0.25, sy + dy * 0.090754 + dy * 0.249815 + dy * 1.518861,
                0.25,
                0, 87.797320594
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2.359608, sy + dy * 2.2 - dy * 0.090754,
                sx, sy + dy * 2.2,
            ]
        }
    ];
    return paths;
}

const sanheyiWaijiaoPart = (sx: number, sy: number, dx: number, dy: number, circleType: "none" | "yuan" | "jin", size?: {width: number, height: number}) => {
    let paths: Path[] = [];

    if (circleType === "yuan") {
        paths.push({
            type: Circle,
            params: [
                sx + dx * 32.8, sy + dy * 12.6 - dy * 11.5,
                3
            ]
        });
        paths.push({
            type: Circle,
            params: [
                sx + dx * 32.8, sy + dy * 12.6,
                3.5
            ]
        })
        paths.push({
            type: Circle,
            params: [
                sx + dx * 32.8, sy + dy * 12.6 + dy * 11.5,
                3
            ]
        });
    } else if (circleType === "jin") {

        paths.push({
            type: Circle,
            params: [
                sx + dx * 15.35, sy + dy * 12.6 - dy * 11.5,
                3
            ]
        });
        paths.push({
            type: Circle,
            params: [
                sx + dx * 15.35, sy + dy * 12.6,
                3.5
            ]
        })
        paths.push({
            type: Circle,
            params: [
                sx + dx * 15.35, sy + dy * 12.6 + dy * 11.5,
                3
            ]
        });
    }
    paths.push(...sanheyiWaiKacao(sx, sy, dx, dy));
    let sx2 = sx;
    let sy2 = sy + dy * 2.2 + dy * 20.8;
    paths.push({
        type: Line,
        params: [
            sx, sy + dy * 2.2,
            sx, sy + dy * 2.2 + dy * 20.8
        ]
    });
    paths.push(...sanheyiWaiKacao(sx2, sy2, dx, dy));
    if(size) {
        size.height = 26.265727;
    }
    return paths;
}

export const sanheyiWaijiaoPartYuan = (sx: number, sy: number, dx: number, dy: number) => {
    return sanheyiWaijiaoPart(sx, sy, dx, dy, "yuan");
}

export const sanheyiWaijiaoPartJin = (sx: number, sy: number, dx: number, dy: number) => {
    return sanheyiWaijiaoPart(sx, sy, dx, dy, "jin");
}

export const sanheyiWaijiaoPart2 = (sx: number, sy: number, dx: number, dy: number) => {
    let paths: Path[] = [
        {
            type: Line,
            params: [
                sx, sy,
                sx + dx * 1.8, sy,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 1.8, sy + dy * 0.2,
                0.2,
                270,
                360,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 1.8 + dx * 0.2, sy + dy * 0.2,
                sx + dx * 1.8 + dx * 0.2, sy + dy * 0.2 + dy * 1.75,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 1.8, sy + dy * 0.2 + dy * 1.75,
                0.2,
                0,
                90,
                // ...degreeTransform(0, 90, dx, dy),
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 1.8, sy + dy * 0.2 + dy * 1.75 + dy * 0.2,
                sx, sy + dy * 0.2 + dy * 1.75 + dy * 0.2,
            ]
        },
        // {
        //     type: Line,
        //     params: [
        //         sx, sy + dy * 0.2 + dy * 1.75 + dy * 0.2,
        //         sx, sy,
        //     ]
        // },
        {
            type: Line,
            params: [
                sx, sy + dy * 0.2 + dy * 1.75 + dy * 0.2,
                sx, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45,

            ]
        }, {
            type: Line,
            params: [
                sx, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45,
                sx + dx * 4.84055, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 4.84055 + dx * 0.141421, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421,
                0.2,
                90,
                135
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 0.141421, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2,
                sx + dx * 4.84055 + dx * 0.141421 + dx * 20.318026, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 4.84055 + dx * 0.141421 + dx * 20.318026, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2,
                0.2,
                270,
                360
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 0.141421 + dx * 20.318026 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2,
                sx + dx * 4.84055 + dx * 0.141421 + dx * 20.318026 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2 + dy * 20.317193,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 4.84055 + dx * 0.141421 + dx * 20.318026 + dx * 0.2 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2 + dy * 20.317193,
                0.2,
                135,
                180,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193,
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
                0.2,
                180,
                270,
                // ...degreeTransform(180, 270, dx, dy),
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8 - dy * 0.2,
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8 - dy * 0.2,

            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
                0.2,
                270,
                360,
                // ...degreeTransform(270, 360, dx, dy),
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
                sx + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75 + dx * 0.2, sy + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
            ]
        }
    ];
    return paths;
}

const sanheyiNeijiaoKacao = (sx: number, sy: number, dx: number, dy: number): Path[] => {
    return [{
        type: Arc,
        params: [
            sx + dx * 0.25, sy,
            0.25,
            (180 - 85.9349781894), 180
        ]
    }, {
        type: Line,
        params: [
            sx + dx * 0.232278, sy + dy * 0.249371,
            sx + dx * 0.232278 + dx * 1.1819, sy + dy * 0.249371 + dy * 0.083996
        ]
    },

    {
        type: Arc,
        params: [
            sx + dx * 0.232278 + dx * 1.1819 + dx * 0.185922 - dx * 0.2, sy + dy * 0.249371 + dy * 0.083996 + dy * 0.199497,
            0.2,
            (360 - 85.9347926250), 360
        ]
    },
    {
        type: Line,
        params: [
            sx + dx * 0.232278 + dx * 1.1819 + dx * 0.185922, sy + dy * 0.249371 + dy * 0.083996 + dy * 0.199497,
            sx + dx * 0.232278 + dx * 1.1819 + dx * 0.185922, sy + dy * 0.249371 + dy * 0.083996 + dy * 0.199497 + dy * 2.2,
        ]
    }, {
        type: Arc,
        params: [
            sx + dx * 0.232278 + dx * 1.1819 + dx * 0.185922 - dx * 0.2, sy + dy * 0.249371 + dy * 0.083996 + dy * 0.199497 + dy * 2.2,
            0.2,
            0, 85.9347926250
            // (360 - 85.9347926250), 360
        ]
    }, {
        type: Line,
        params: [
            sx + dx * 0.232278 + dx * 1.1819 + dx * 0.185922 - dx * 0.185822, sy + dy * 0.249371 + dy * 0.083996 + dy * 0.199497 + dy * 2.2 + dy * 0.199497,
            sx + dx * 0.232278, sy + dy * 0.249371 + dy * 2.766985,

        ]
    }, {
        type: Arc,
        params: [
            sx + dx * 0.232278 + dx * 0.017722, sy + dy * 0.249371 + dy * 2.766985 + dy * 0.249371,
            0.25,
            180, 180 + 85.9349781894,
        ]
    }
    ]
}

/**
 * 与二合一不一样的
 */
export const sanheyiNeijiaoPart = (sx: number, sy: number, dx: number, dy: number, circleType: "none" | "circle") => {
    const paths: Path[] = [];
   
    paths.push(...sanheyiNeijiaoKacao(sx, sy, dx, dy));
    const sx2 = sx;
    const sy2 = sy + dy * 3.265713;
    paths.push({
        type: Line,
        params: [
            sx2, sy2,
            sx2, sy2 + dy * 19.734287
        ]
    });
    if(circleType === "circle") {
        paths.push({
            type: Circle,
            params: [
                sx + dx * 31.1, (sy + (sy2 + dy * 19.734287)) / 2,
                4
            ]
        })
    }
    const sx3 = sx2;
    const sy3 = sy2 + dy * 19.734287;
    paths.push(...sanheyiNeijiaoKacao(sx3, sy3, dx, dy));
    return paths;
}

/**
 * 与二合一一样的
 */
export const sanheyiNeijiaoPart2 = (sx: number, sy: number, dx: number, dy: number) => {
    const paths: Path[] = [
        {
            type: Arc,
            params: [
                sx + dx * 0.2, sy,
                0.2,
                90,
                180,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 0.2, sy + dy * 0.2,
                sx + dx * 0.2 + dx * 1.2, sy + dy * 0.2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 0.2 + dx * 1.2, sy + dy * 0.2 + dy * 0.2,
                0.2,
                270,
                360,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 0.2 + dx * 1.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2,
                sx + dx * 0.2 + dx * 1.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 0.2 + dx * 1.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2,
                0.2,
                0,
                90
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 0.2 + dx * 1.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2,
                sx + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2,
                0.2,
                180,
                270
            ]
        }, {
            type: Line,
            params: [
                sx, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2,
                sx, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71,
                2,
                90,
                180
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2,
                sx + dx * 2 + dx * 1.01, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2 + dx * 1.01, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2,
                sx + dx * 2 + dx * 1.01, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01,
                2,
                90,
                180
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01 + dy * 2,
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01 + dy * 2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
                0.2,
                0,
                90
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
                0.2,
                180,
                270

            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2 - dy * 0.2,
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2 - dy * 0.2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
                0.2,
                270,
                360,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
                0.2,
                90,
                180,
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2,
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2 + dx * 6.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2 + dx * 6.2, sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2 + dy * 0.51,
                0.51,
                270,
                270 + 68.962446917137058017291095061078,
            ]
        }
    ];
    let nowX = sx + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2 + dx * 6.2 + dx * 0.51 * Math.sin(68.962446917137058017291095061078 / 360 * 2 * Math.PI);
    let nowY = sy + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2 + dy * 0.51 * (1 - Math.cos(68.962446917137058017291095061078 / 360 * 2 * Math.PI));
    paths.push({
        type: Line,
        params: [
            nowX, nowY,
            nowX + dx * 4.469648, nowY + dy * 11.65308/* 11.67308 */
        ]
    });
    return paths;
}

