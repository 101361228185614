// import {  } from "dxf-writer";
import { Arc, Line, Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner7 } from "../../coners/corner7";
import { rectBoard } from "../general-board";
import { menneiBoard } from "../地柜/01-mennei";

export const _10menneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    nolashou: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    paikong?: boolean,
    paikongX?: number,
    paikongY?: number,
    paikongWidth?: number,
    paikongHeight?: number

): [Path[], Path[]] => {

    //加孔处理
    const [cornerPaths, boardPaths] = menneiBoard(dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, nolashou, menminglashou, menlashouX, menlashouY, menlashoubianju, menlashoufangxiang, doorSide, gxinglashou, gxinglashouType, gxinglashouGaodu);
    //console.log(paikong, paikongX, paikongHeight);
    
    if(paikong) {

        const generateRect = (sx: number, sy: number, dx: number, dy: number,  width: number, height: number) => {
            let paths: Path[] = [];
            paths.push({
                type: Line,
                params: [
                    sx, sy,
                    sx + dx * width, sy
                ]
            }, {
                type: Line,
                params: [
                    sx + dx * width, sy,
                    sx + dx * width, sy + dy * height,
                ]
            }, {
                type: Line,
                params: [
                    sx + dx * width, sy + dy * height,
                    sx, sy + dy * height,
                ]
            },  {
                type: Line,
                params: [
                    sx, sy + dy * height,
                    sx, sy
                ]
            });
            return paths;
        }
        paikongY = paikongY as number;
        paikongX = paikongX as number;
        paikongWidth = paikongWidth as number;
        paikongHeight = paikongHeight as number;
        const sx1 = startX + dx * paikongX - dx * paikongWidth / 2;
        const sy1  =startY + dy * paikongY;
        const rect1  =generateRect(sx1, sy1, dx, dy, paikongWidth, paikongHeight);
        const sx2 = startX + dx * width - dx * paikongX + dx * paikongWidth / 2;
        const sy2 = sy1;
        const rect2 = generateRect(sx2, sy2, dx, dy, paikongWidth, paikongHeight);
        cornerPaths.push(...rect1, ...rect2);
    }
    return [cornerPaths, boardPaths];
}