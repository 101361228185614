import { ChromeFilled, ChromeOutlined, DeleteFilled, DownOutlined, HomeFilled, HomeOutlined, MoneyCollectOutlined, OrderedListOutlined, PlusOutlined, SafetyCertificateFilled, SafetyCertificateOutlined, SaveFilled, SettingOutlined, TranslationOutlined, UserOutlined, WalletOutlined, WindowsFilled } from "@ant-design/icons"
import { Avatar, Button, ConfigProvider, Dropdown, Popover, Tag, message, theme } from "antd"
import { useEffect, useState } from "react";
import pkg from '../../../package.json'
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { localeOptions, useLocaleStore } from "../../locale";
import { useTranslation } from "react-i18next";
import { LocaleSwitch } from "../../locale/LocaleSwich";


export const Header = () => {

    const location = useLocation();

    const { t } = useTranslation();

    const [accountInfo, setAccountInfo] = useState<any>(null);
    const navigate = useNavigate();


    useEffect(() => {
        const localInfo = localStorage.getItem("SK_LOGIN_INFO");
        if (localInfo) {
            try {
                setAccountInfo(JSON.parse(localInfo)?.accountInfo);

            } catch (e) {
                navigate("/login", {
                    replace: true
                });
                message.warning(t("登录信息解析失败，请重新登录"));
            }
        }

    }, []);

    const [navList] = useState([
        {
            title: "首页",
            icon: HomeOutlined,
            key: "/",
            onClick: () => {
                navigate("/", {
                    replace: true
                });
            }
        },
        {
            title: "设置中心",
            key: "/settings",
            icon: SettingOutlined,
            onClick: () => {
                navigate("/settings", {
                    
                    replace: true
                });
            }
        }
    ]);

    const [activeKey, setActiveKey] = useState(location.pathname);

    useEffect(() => {
        //找到可以匹配的
        if(location.pathname === '/') {
            setActiveKey('/');
        }else {
            setActiveKey("/settings");
        }
    }, [location.pathname])

    return (
        <ConfigProvider theme={{ algorithm: [theme.defaultAlgorithm] }}>
            <div className="top-menu fixed flex center space-between" style={{ opacity: 1, height: 54, width: '100%', left: 0, top: 0, padding: '0 16px', background: "rgba(248, 249, 251, 1)", zIndex: 4, }}>
                <div className="flex center" style={{ justifyContent: 'flex-start', flex: 1, overflowX: "hidden" }}>
                    {/* <img src="http://bxgcgsb.com/favicon.ico" style={{height:18,width:18}}/> */}
                    <span className="bold" style={{ color: 'rgba(3, 9, 17, 0.8)' }}>{t("大圣设计")}</span>
                    <Tag color="rgba(3, 9, 17, .07)" style={{ letterSpacing: 1, color: "rgba(3, 9, 17, 0.6)", marginLeft: 4, }}>V{pkg.version}</Tag>
                    <div style={{ width: 1, height: 22, margin: '0 8px', background: 'rgba(3, 9, 17, 0.3)' }} />
                    {
                        navList.map((item, index) => {
                            return (
                                <span className={`${activeKey === item.key ? 'bold' : undefined}`} style={{ color: "rgba(3, 9, 17, 0.6)", fontSize: 14, cursor: "pointer", marginLeft: 16 }} key={index} onClick={item.onClick}>
                                    <item.icon style={{ position: "relative", top: 0 }} /> {t(item.title)}
                                </span>
                            )
                        })
                    }
                </div>
                <div className="flex" style={{ flexShrink: 0, color: "rgba(3, 9, 17, 0.7)", cursor: "pointer" }}>

                </div>
                <div className="flex center" style={{ justifyContent: "flex-end", flex: 1, height: '100%' }}>
                    <div>
                        <LocaleSwitch />
                    </div>
                    <div className="flex" style={{ margin: '0 10px', height: 24, borderRadius: 6, padding: '2px 8px', background: "rgba(25,64,255,.12)", color: "#1940ff", fontSize: 14 }}>
                        <SafetyCertificateOutlined style={{ marginRight: 2 }} /> {t('已授权')}
                    </div>
                    <div style={{}}>
                        <div className="flex center" style={{ margin: '0 0 0 10px', color: "gray" }}>
                            <Avatar size={"small"} style={{ background: "#aaa", marginRight: 4 }} icon={(<UserOutlined />)} />{accountInfo?.account || t("未登录")}
                        </div>
                    </div>
                </div>
            </div>

        </ConfigProvider>
    )
}