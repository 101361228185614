import { LeftQiezhuanjiaoguiCebaobei } from "../qiezhuanjiaogui/leftQiezhuanjiaoguiCebaobei";
import { _08zhuanjiaoneiXBoard } from "../../boards/转角柜/08-zhuanjiaonei-x";
import { _08zhuanjiaowaiXBoard } from "../../boards/转角柜/08-zhuanjiaowai-x";
import { _08zhuanjiaoneiYBoard } from "../../boards/转角柜/08-zhuanjiaonei-y";
import { _08zhuanjiaowaiYBoard } from "../../boards/转角柜/08-zhuanjiaowai-y";
import { _08dineiBoard } from "../../boards/转角柜/08-dinei";
import { _08diwaiBoard } from "../../boards/转角柜/08-diwai";
import { _08ceneiBoardLeft } from "../../boards/转角柜/08-cenei-left";
import { _08ceneiBoardRight } from "../../boards/转角柜/08-cenei-right";
import { _08lineiBoard } from "../../boards/转角柜/08-linei";
import { _08liwaiBoard } from "../../boards/转角柜/08-liwai";
import { _08fengneiBoard } from "../../boards/转角柜/08-fengnei";
import { _08fengwaiBoard } from "../../boards/转角柜/08-fengwai";
import { _08cewaiBoardLeft } from "../../boards/转角柜/08-cewai-left";
import { _08cewaiBoardRight } from "../../boards/转角柜/08-cewai-right";
import { _08beiwaiBoard } from "../../boards/转角柜/08-beiwai";
import { _08beineiBoard } from "../../boards/转角柜/08-beinei";
import { _08menwaiBoard } from "../../boards/转角柜/08-menwai";
import { _08menneiBoard } from "../../boards/转角柜/08-mennei";
import { _08qiandangneiBoard } from "../../boards/转角柜/08-qiandangnei";
import { _08qiandangwaiBoard } from "../../boards/转角柜/08-qiandangwai";
import { LeftZhuanjiaoguiCebaobei } from "./leftZhuanjiaoguiCebaobei";

export class RightZhuanjiaoguiCebaobei extends LeftZhuanjiaoguiCebaobei {
    key: string = "RightZhuanjiaoguiCebaobei";
    
    setNameMap() {
        return {
            dineiBoard: _08dineiBoard,
            diwaiBoard: _08diwaiBoard,
            ceneiBoardLeft: _08ceneiBoardLeft,
            ceneiBoardRight: _08ceneiBoardRight,
            cewaiBoardLeft: _08cewaiBoardLeft,
            cewaiBoardRight: _08cewaiBoardRight,
            beiwaiBoard: _08beiwaiBoard,
            beineiBoard: _08beineiBoard,
            menwaiBoard: _08menwaiBoard,
            menneiBoard: _08menneiBoard,
            "menwaiBoard-left": _08menwaiBoard,
            "menneiBoard-left": _08menneiBoard,
            "menwaiBoard-right": _08menwaiBoard,
            "menneiBoard-right": _08menneiBoard,
            qiandangneiBoard: _08qiandangneiBoard,
            qiandangwaiBoard: _08qiandangwaiBoard,
            zhuanjiaoneiXBoard: _08zhuanjiaoneiXBoard,
            zhuanjiaowaiXBoard: _08zhuanjiaowaiXBoard,
            zhuanjiaoneiYBoard: _08zhuanjiaoneiYBoard,
            zhuanjiaowaiYBoard: _08zhuanjiaowaiYBoard,
            lineiBoard: _08lineiBoard,
            liwaiBoard: _08liwaiBoard,
            fengneiBoard: _08fengneiBoard,
            fengwaiBoard: _08fengwaiBoard
        }
    }

}