import { Vector2d } from "konva/lib/types";

type Point = Vector2d;
type Edge = {from: Point, to: Point};

function distancePointToLineSegment(point: Point, edge: Edge): number {
    const {x, y} = point;
    const {from, to} = edge;
    let dx = to.x - from.x;
    let dy = to.y - from.y;

    // Calculate the t that minimizes the distance.
    const t = ((x - from.x) * dx + (y - from.y) * dy) / (dx * dx + dy * dy);

    if (t < 0) {
        // Point is nearest to the first endpoint of the line segment.
        dx = x - from.x;
        dy = y - from.y;
    } else if (t > 1) {
        // Point is nearest to the second endpoint of the line segment.
        dx = x - to.x;
        dy = y - to.y;
    } else {
        // Point is nearest to some point in the middle of the line segment.
        dx = x - (from.x + t * dx);
        dy = y - (from.y + t * dy);
    }

    // Return the distance.
    return Math.sqrt(dx * dx + dy * dy);
}

/**
 * 
 * @param point 
 * @param polygon 
 * @param min 
 * @returns 返回最接近的边，如果min<0，则不管点离边多远都返回，否则仅在min范围内返回
 */
export function computeClosestEdge(point: Point, polygon: Point[], min = -1): Edge {
    let minDistance = Infinity;
    let closestEdge: Edge;

    for (let i = 0; i < polygon.length; i++) {
        const edge: Edge = {from: polygon[i], to: polygon[(i + 1) % polygon.length]};
        const distance = distancePointToLineSegment(point, edge);

        if (distance < minDistance) {
            minDistance = distance;
            closestEdge = edge;
        }
    }
    if(min < 0) {
        return closestEdge;
    }else {
        return minDistance < min ? closestEdge : null;
    }
}