import { DirectX, DirectY } from "../../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../../util/graph";
import { sanheyi01CewaiBoardLeft } from "./sanheyi-01-cewai-left";

export const sanheyi01CewaiBoardRight = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    mengao?: number
): [Path[], Path[]] => {
    const [ boardPaths, cornerPaths ] = sanheyi01CewaiBoardLeft(cornerType, dx, dy, startX, startY, width, height, jiaolianbianju, lianjiekongbianju, corner, geceng, gecengX, gecengY, mengao);
    const combind = conbinePaths([boardPaths, cornerPaths]);
    let mirrorCombind = pathMirrorY(combind, startX);
    mirrorCombind = pathMoveX(mirrorCombind, DirectX.RIGHT, width);
    return [
        mirrorCombind.slice(0, boardPaths.length),
        mirrorCombind.slice(boardPaths.length, boardPaths.length + cornerPaths.length),
    ]

}