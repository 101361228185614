import { BoxGeometry, Color, ExtrudeGeometry, Shape, Vector2 } from "three";
import { OnWallData, WallData, px2m } from "../../../store";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Addition, Base, Geometry, Subtraction } from "@react-three/csg";
import { computeWallDirectSign } from "../../../util";


interface WallsAndOnWalls3DProps {
    wall3DList: Wall3DProps[];
    door3DList: Door3DProps[];
    window3DList: Window3DProps[];
}
export const WallsAndOnWalls3D = (props: WallsAndOnWalls3DProps) => {

    const box = useMemo(() => {
        return new BoxGeometry(0, 0, 0);
    }, [])
    return (

        <mesh receiveShadow castShadow >
            <Geometry computeVertexNormals >
                <Base geometry={box}/>
                {
                    props.wall3DList.map((wall3D, index) => {
                        // console.log("wall3D:", index);
                        
                        return <Wall3D isBase={index === 0} key={"wall-" + index} name={"wall-" + index} {...wall3D} />
                    })
                }
                {
                    props.door3DList.map((door3D, index) => {
                        // console.log("door3D:", index);
                        return <Door3D key={"door-" + index} {...door3D} />
                    })
                }
                {
                    props.window3DList.map((window3D, index) => {
                        // console.log("window3D:", index);
                        return <Window3D key={"window-" + index} {...window3D} />
                    })
                }
            </Geometry>
            <meshStandardMaterial envMapIntensity={1} color={new Color(0xf2f2f2)} />
        </mesh>
    )
}



interface Wall3DProps extends WallData {
    name?: string;
    globalOffset: { x: number, y: number };
    isBase?: boolean;
}

const Wall3D = (props: Wall3DProps) => {

    const { globalOffset, startAnchorPos, wallLength, wallDirect, wallThickness, floorHeight, floorThickness, endAnchorPos } = props;


    const getGeometry = () => {
        
        const sign = computeWallDirectSign(wallLength);
        const wallEffect = wallThickness / 2 * sign;
        let points: Vector2[] = [];
        if (wallDirect === 'y') {
            const v1 = new Vector2(startAnchorPos.x - wallEffect + globalOffset.x, startAnchorPos.y - wallEffect + globalOffset.y);
            const v2 = new Vector2(startAnchorPos.x + wallEffect + globalOffset.x, startAnchorPos.y - wallEffect + globalOffset.y);
            const v3 = new Vector2(endAnchorPos.x + wallEffect + globalOffset.x, endAnchorPos.y + wallEffect + globalOffset.y);
            const v4 = new Vector2(endAnchorPos.x - wallEffect + globalOffset.x, endAnchorPos.y + wallEffect + globalOffset.y);
            points = [v1, v2, v3, v4];
        } else {
            const v1 = new Vector2(startAnchorPos.x - wallEffect + globalOffset.x, startAnchorPos.y - wallEffect + globalOffset.y);
            const v2 = new Vector2(endAnchorPos.x + wallEffect + globalOffset.x, endAnchorPos.y - wallEffect + globalOffset.y);
            const v3 = new Vector2(endAnchorPos.x + wallEffect + globalOffset.x, endAnchorPos.y + wallEffect + globalOffset.y);
            const v4 = new Vector2(startAnchorPos.x - wallEffect + globalOffset.x, startAnchorPos.y + wallEffect + globalOffset.y);
            points = [v1, v2, v3, v4];
        }
        points.forEach(p => {
            p.setX(p.x * px2m);
            p.setY(p.y * px2m);
        });
        const shape = new Shape(points);
        const totalHeight = (floorHeight + floorThickness) * px2m;
        const geo = new ExtrudeGeometry(shape, {
            depth: totalHeight,
            bevelEnabled: false
        });
        geo.translate(0, 0, -totalHeight);
        geo.rotateX(Math.PI / 2);
        return geo;
    }
    const [geometry, setGeometry] = useState<ExtrudeGeometry>(getGeometry());

    useEffect(() => {
        setGeometry(getGeometry());
    }, [startAnchorPos, endAnchorPos, wallLength, wallDirect, wallThickness, floorHeight, floorThickness, globalOffset]);

    return (<Geometry>
        {
            geometry && (
                <Addition geometry={geometry}/>
            )
        }
        
        </Geometry>)

}

interface BaseOnWallProps extends OnWallData {
    globalOffset: { x: number, y: number };
    children?: ReactNode | ReactNode[]
}
const BaseOnWall3D = (props: BaseOnWallProps) => {

    const { 
        openDirect, onWallHeight, onWallWidth, groundClearance, anchor, wall, 
        wallThickness, floorThickness, globalOffset
    } = props;

    const getGeometry = () => {
        let points: Vector2[];
        if(openDirect === 'left' || openDirect === 'right') {
            const v1 = new Vector2(wall.from.x - wallThickness / 2 + globalOffset.x, anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.y);
            const v2 = new Vector2(wall.from.x + wallThickness / 2 + globalOffset.x, anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.y);
            const v3 = new Vector2(wall.from.x + wallThickness / 2 + globalOffset.x, anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.y);
            const v4 = new Vector2(wall.from.x - wallThickness / 2 + globalOffset.x, anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.y);
            points = [v1, v2, v3, v4];
        }else {
            const v1 = new Vector2(anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.x, wall.from.y - wallThickness / 2 + globalOffset.y);
            const v2 = new Vector2(anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.x, wall.from.y - wallThickness / 2 + globalOffset.y);
            const v3 = new Vector2(anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.x, wall.from.y + wallThickness / 2 + globalOffset.y);
            const v4 = new Vector2(anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.x, wall.from.y + wallThickness / 2 + globalOffset.y);
            points = [v1, v2, v3, v4];
        }
        points.forEach(p => {
            p.setX(p.x * px2m);
            p.setY(p.y * px2m);
        });
        const shape = new Shape(points);
        const height = onWallHeight * px2m;
        const geo = new ExtrudeGeometry(shape, {
            depth: height,
            bevelEnabled: false
        });
        const offsetZ = (groundClearance + floorThickness) * px2m;
        geo.translate(0, 0, (-height - offsetZ));
        geo.rotateX(Math.PI / 2);
        return geo;
    }
    const [geometry, setGeometry] = useState<ExtrudeGeometry>(getGeometry());


    useEffect(() => {
        
        setGeometry(getGeometry());
    }, [ onWallHeight, onWallWidth, wallThickness, floorThickness, groundClearance, anchor, wall, openDirect]);

    return geometry && (<Subtraction geometry={geometry}/>)
}


interface Window3DProps extends OnWallData {
    globalOffset: { x: number, y: number };
    
}
const Window3D = (props: Window3DProps) => {
    return (
        <BaseOnWall3D {...props}>
            {/* <Box args={[props.onWallWidth * px2m, props.onWallHeight * px2m, 0.1]}/> */}
        </BaseOnWall3D>
    )
}

interface Door3DProps extends OnWallData {
    globalOffset: { x: number, y: number };

}

const Door3D = (props: Window3DProps) => {
    return (
        <BaseOnWall3D {...props}>
            
        </BaseOnWall3D>
    )
}
