import { ConfigProvider, Layout, Menu, theme } from "antd"
import { useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

export const SettingsLayout = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [menuItems] = useState([
        // {
        //     name: "成本设置",
        //     path: "/settings/cost-settings",
        // },
        {
            name: "板材成本",
            path: "/settings/cost-settings/sheet-cost",
        },
        {
            name: "零配件件成本",
            path: "/settings/cost-settings/part-cost",
        }

    ]);

    return (
        <div className="relative" style={{ height: "calc(100vh - 54px - 10px)" }}>

            <div className="fixed no-scroll-bar" style={{ left: 0, top: 54, width: 222, paddingTop: 10, height: "calc(100vh - 54px)", overflowY: "scroll", background: "rgb(248, 249, 251)" }}>
                <ConfigProvider theme={{ algorithm: [theme.defaultAlgorithm] }}>
                    <Menu selectedKeys={[location.pathname]} style={{ background: "none" }}>
                        {
                            menuItems.map((item) => {
                                return (
                                    <Menu.Item key={item.path} onClick={() => {
                                        navigate(item.path, {
                                            replace: true
                                        });
                                    }}>
                                        {item.name}
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                </ConfigProvider>
            </div>
            <div style={{ paddingLeft: 222 + 10 }}>
                <ConfigProvider theme={{ algorithm: [theme.defaultAlgorithm] }}>
                    <Outlet />
                </ConfigProvider>
            </div>
        </div>
    )


}