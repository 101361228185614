import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05zhuanjiaoneiXBoard } from "./05-zhuanjiaonei-x";
import { _05zhuanjiaoneiYBoard } from "./05-zhuanjiaonei-y";

export const _07zhuanjiaoneiYBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return _05zhuanjiaoneiYBoard(dx, dy, startX, startY, width, height);
}