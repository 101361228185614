import { DiaoguiBeibaoce } from "../../utils/v1-engine/paths/cupboards/diaogui/diaoguiBeibaoce";
import { DiaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/diaogui/diaoguiCebaobei";
import { DiaoguiSanmenguiBeibaoce } from "../../utils/v1-engine/paths/cupboards/diaogui/diaoguiSanmenguiBeibaoce";
import { SanheyiDiaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/diaogui/sanheyi/sanheyiDiaoguiCebaobei";
import { DiguiBeibaoce } from "../../utils/v1-engine/paths/cupboards/digui/diguiBeibaoce";
import { DiguiCebaobei } from "../../utils/v1-engine/paths/cupboards/digui/diguiCebaobei";
import { SanheyiDiguiCebaobei } from "../../utils/v1-engine/paths/cupboards/digui/sanheyi/sanheyiDiguiCebaobei";
import { Hanjiegui } from "../../utils/v1-engine/paths/cupboards/hanjiegui/hanjiegui";
import { LalanguiCebaobei } from "../../utils/v1-engine/paths/cupboards/lalangui/lalanguiCebaobei";
import { LeftQiezhuanjiaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/qiezhuanjiaogui/leftQiezhuanjiaoguiCebaobei";
import { RightQiezhuanjiaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/qiezhuanjiaogui/rightQiezhuanjiaoguiCebaobei";
import { LeftSanmenguiCebaobei } from "../../utils/v1-engine/paths/cupboards/sanmengui/leftSanmenguiCebaobei";
import { RightSanmenguiCebaobei } from "../../utils/v1-engine/paths/cupboards/sanmengui/rightSanmenguiCebaobei";
import { ShuicaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/shuicaogui/shuicaoguiCebaobei";
import { IIYangtaiguiCebaobei } from "../../utils/v1-engine/paths/cupboards/yangtaigui/iiYangtaiguiCebaobei";
import { YangtaiguiCebaobei } from "../../utils/v1-engine/paths/cupboards/yangtaigui/yangtaiguiCebaobei";
import { LeftZhuanjiaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/zhuanjiaogui/leftZhuanjiaoguiCebaobei";
import { RightZhuanjiaoguiCebaobei } from "../../utils/v1-engine/paths/cupboards/zhuanjiaogui/rightZhuanjiaoguiCebaobei";

export const getCupboardBoards = (order: any, cupboard: any, method: any = "factory") => {
    let resultMap = {};
    let _cupboard: any;
    if(cupboard.key === "digui-cebaobei"/* 01 */) {
        _cupboard = new DiguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

           jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 60 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            mengao: Number(cupboard.mengao),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
            isMeiqiguangui: Boolean(cupboard.isMeiqiguangui),
            nocenei: Boolean(cupboard.nocenei),
        });
    }else if(cupboard.key === "sanheyi-digui-cebaobei"/* 01 */) {
        _cupboard = new SanheyiDiguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 60 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            mengao: Number(cupboard.mengao),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
        });
    } else if(cupboard.key === "digui-beibaoce"/* 01-a */) {
        _cupboard = new DiguiBeibaoce(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 60 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            mengao: Number(cupboard.mengao),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
        });
    } else if (cupboard.key === "diaogui-cebaobei"/* 02 */) {
        //console.log(order.boardTypes, order.materials)
        _cupboard = new DiaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            diaokongbianju: Number(cupboard.diaokongbianju),
            吊孔孔径: Number(cupboard.吊孔孔径) || 2.8,
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 0 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            menxiagaochu: Number(cupboard.menxiagaochu),
        });
    } else if (cupboard.key === "sanheyi-diaogui-cebaobei"/* 02 */) {
        //console.log(order.boardTypes, order.materials)
        _cupboard = new SanheyiDiaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            diaokongbianju: Number(cupboard.diaokongbianju),
            吊孔孔径: Number(cupboard.吊孔孔径) || 2.8,
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 0 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
        });
    } else if (cupboard.key === "diaogui-beibaoce"/* -02-a */) {
        _cupboard = new DiaoguiBeibaoce(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            diaokongbianju: Number(cupboard.diaokongbianju),
            吊孔孔径: Number(cupboard.吊孔孔径) || 2.8,
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 0 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            menxiagaochu: Number(cupboard.menxiagaochu),
            吊码: Boolean(cupboard.diaoma),
        });
    } else if (cupboard.key === "left-sanmengui-cebaobei"/* 03 */) {
        _cupboard = new LeftSanmenguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            diaokongbianju: Number(cupboard.diaokongbianju),
            吊孔孔径: Number(cupboard.吊孔孔径) || 2.8,
            menwaibanjiao: cupboard.menwaibanjiao,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            a: Number(cupboard.a),
            waibanjiao: !cupboard.nowaibanjiao,
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 0 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            doorSide: cupboard.doorSide,
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
        });
    }else if (cupboard.key === "right-sanmengui-cebaobei" /* 04 */) {
        _cupboard = new RightSanmenguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            diaokongbianju: Number(cupboard.diaokongbianju),
            吊孔孔径: Number(cupboard.吊孔孔径) || 2.8,
            menwaibanjiao: cupboard.menwaibanjiao,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            a: Number(cupboard.a),
            waibanjiao: !cupboard.nowaibanjiao,
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 0 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            doorSide: cupboard.doorSide,
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
        });
    }else if (cupboard.key === "left-qiezhuanjiaogui-cebaobei"/* 05 */) {
        _cupboard = new LeftQiezhuanjiaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            menwaibanjiao: cupboard.menwaibanjiao,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            x: Number(cupboard.x),
            y: Number(cupboard.y),
            doorWidth: Number(cupboard.doorWidth),
            waibanjiao: !cupboard.nowaibanjiao,
            nobeinei: Boolean(cupboard.nobeinei),
            nobeiban: Boolean(cupboard.nobeiban),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            doorSide: cupboard.doorSide,
            mengao: Number(cupboard.mengao),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
            doorNumber: Number(cupboard.doorNumber),
            
        });
    }else if (cupboard.key === "right-qiezhuanjiaogui-cebaobei"/* 06 */) {
        _cupboard = new RightQiezhuanjiaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            menwaibanjiao: cupboard.menwaibanjiao,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            x: Number(cupboard.x),
            y: Number(cupboard.y),
            doorWidth: Number(cupboard.doorWidth),
            waibanjiao: !cupboard.nowaibanjiao,
            nobeinei: Boolean(cupboard.nobeinei),
            nobeiban: Boolean(cupboard.nobeiban),
            
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            doorSide: cupboard.doorSide,
            mengao: Number(cupboard.mengao),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
            doorNumber: Number(cupboard.doorNumber),
        });
    }else if (cupboard.key === "left-zhuanjiaogui-cebaobei"/* 07 */) {
        _cupboard = new LeftZhuanjiaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            menwaibanjiao: cupboard.menwaibanjiao,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            x: Number(cupboard.x),
            y: Number(cupboard.y),
            doorWidth: Number(cupboard.doorWidth),
            waibanjiao: !cupboard.nowaibanjiao,
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            doorSide: cupboard.doorSide,
            mengao: Number(cupboard.mengao),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
            doorNumber: Number(cupboard.doorNumber),
        })
    
    }else if (cupboard.key === "right-zhuanjiaogui-cebaobei"/* 08 */) {
        _cupboard = new RightZhuanjiaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            menwaibanjiao: cupboard.menwaibanjiao,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            x: Number(cupboard.x),
            y: Number(cupboard.y),
            doorWidth: Number(cupboard.doorWidth),
            waibanjiao: !cupboard.nowaibanjiao,
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            doorSide: cupboard.doorSide,
            mengao: Number(cupboard.mengao),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),
            doorNumber: Number(cupboard.doorNumber),
        });
    }else if (cupboard.key === "shuicaogui-cebaobei"/* 09 */) { //水槽柜
        _cupboard = new ShuicaoguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 60 : Number(cupboard.gecengX),
            
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手: Boolean(cupboard.中免拉手),
            中免拉手X: Number(cupboard.中免拉手X),
            中免拉手Y: Number(cupboard.中免拉手Y),
            中免拉手Z: Number(cupboard.中免拉手Z),

            mengao: Number(cupboard.mengao),
            nocenei: Boolean(cupboard.nocenei)
        });
    }else if (cupboard.key === "lalangui-cebaobei"/* 10 */) { //拉篮柜
        _cupboard = new LalanguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,
            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            无柜腿孔: Boolean(cupboard.无柜腿孔),
            guituiX: Number(cupboard.guituiX),
            guituiY: Number(cupboard.guituiY),
            doorNumber: Number(cupboard.doorNumber),
            doorSide: cupboard.doorSide,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 60 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            y1: Number(cupboard.y1),
            y2: Number(cupboard.y2),
            y3: Number(cupboard.y3),
            x: Number(cupboard.x),
            a: Number(cupboard.a),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            mengao: Number(cupboard.mengao),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            qiandanghengfang: Boolean(cupboard.qiandanghengfang),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            //门内排孔
            paikong: Boolean(cupboard.paikong),
            paikongX: Number(cupboard.paikongX || 30),
            paikongY: Number(cupboard.paikongY || 90),
            paikongWidth: Number(cupboard.paikongWidth || 10),
            paikongHeight: Number(cupboard.paikongHeight || 120),
            //可选， 上、中免拉手X、Y、Z
            上免拉手: Boolean(cupboard.上免拉手),
            上免拉手X: Number(cupboard.上免拉手X),
            上免拉手Y: Number(cupboard.上免拉手Y),
            中免拉手1: Boolean(cupboard.中免拉手1),
            中免拉手1X: Number(cupboard.中免拉手1X),
            中免拉手1Y: Number(cupboard.中免拉手1Y),
            中免拉手1Z: Number(cupboard.中免拉手1Z),
            中免拉手2: Boolean(cupboard.中免拉手2),
            中免拉手2X: Number(cupboard.中免拉手2X),
            中免拉手2Y: Number(cupboard.中免拉手2Y),
            中免拉手2Z: Number(cupboard.中免拉手2Z),
            中免拉手3: Boolean(cupboard.中免拉手3),
            中免拉手3X: Number(cupboard.中免拉手3X),
            中免拉手3Y: Number(cupboard.中免拉手3Y),
            中免拉手3Z: Number(cupboard.中免拉手3Z),
            无侧板排孔: Boolean(cupboard.无侧板排孔),
        });  
    }else if(cupboard.key === "yangtaigui-cebaobei"/* 11 */) {
        _cupboard = new YangtaiguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        if(Number(cupboard.long) < 800){
            return ;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            mengao: Number(cupboard.mengao),
            menkuan: Number(cupboard.menkuan),
            dibanshen: Number(cupboard.dibanshen),
            zuocekuan: Number(cupboard.zuocekuan),
            zuocechang: Number(cupboard.zuocechang),
            zhongcekuan: Number(cupboard.zhongcekuan),
            zhongcechang: Number(cupboard.zhongcechang),
            youcekuan: Number(cupboard.youcekuan),
            youcechang: Number(cupboard.youcechang),
            doorNumber: Number(cupboard.doorNumber),
            nomentouwaibanjiao: Boolean(cupboard.nomentouwaibanjiao),
            noqiandangwaibanjiao: Boolean(cupboard.noqiandangwaibanjiao),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            doorSide: cupboard.doorSide,
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
        });  
    }else if(cupboard.key === "ii-yangtaigui-cebaobei"/* 11 */) {
        _cupboard = new IIYangtaiguiCebaobei(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        // if(Number(cupboard.long) < 800){
        //     return ;
        // }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            diyBoards: cupboard.diyBoards || [],
            waibanjiaoType: cupboard.waibanjiaoType,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
        });  
    }else if(cupboard.key === "diaogui-sanmengui-beibaoce" || cupboard.key === "diaogui-sanmengui-cebaobei") {
        _cupboard = new DiaoguiSanmenguiBeibaoce(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        if(Number(cupboard.long) < 800){
            return ;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            waibanjiaoType: cupboard.waibanjiaoType,

            jiaolianbianju: Number(cupboard.jiaolianbianju),
            lianjiekongbianju: cupboard.lianjiekong ? Number(cupboard.lianjiekongbianju) : -1,
            diaokongbianju: Number(cupboard.diaokongbianju),
            吊孔孔径: Number(cupboard.吊孔孔径) || 2.8,
            doorSide: cupboard.doorSide,
            a: Number(cupboard.a),
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
            geceng: Boolean(cupboard.geceng),
            gecengX: isNaN(cupboard.gecengX) ? 0 : Number(cupboard.gecengX),
            gecengY: isNaN(cupboard.gecengY) ? 340 : Number(cupboard.gecengY),
            gecengkongjing: Number(cupboard.gecengkongjing || 4.96),
            nobeinei: Boolean(cupboard.nobeinei),
            nocenei: Boolean(cupboard.nocenei),
            menminglashou: Boolean(cupboard.menminglashou),
            menlashoubianju: Number(cupboard.menlashoubianju),
            menlashoufangxiang: cupboard.menlashoufangxiang,
            menlashouX: Number(cupboard.menlashouX),
            menlashouY: Number(cupboard.menlashouY),
            
            gxinglashou: Boolean(cupboard.gxinglashou),
            gxinglashouType: cupboard.gxinglashouType,
            gxinglashouGaodu: Number(cupboard.gxinglashouGaodu),
            // menminglashou: Boolean(cupboard.menminglashou),
            // menlashoubianju: Number(cupboard.menlashoubianju),
            // menlashoufangxiang: cupboard.menlashoufangxiang,
            // menlashouX: Number(cupboard.menlashouX),
            // menlashouY: Number(cupboard.menlashouY),
            //可选有无铰链孔, 有无拉手
            nojiaoliankong: Boolean(cupboard.nojiaoliankong),
            nolashou: Boolean(cupboard.nolashou),
            吊码: Boolean(cupboard.diaoma),
        });  
    }else if(cupboard.key === "hanjiegui") {
        _cupboard = new Hanjiegui(order.boardTypes, order.materials);
        if(method === false) {
            return _cupboard;
        }
        resultMap = _cupboard[method](Number(cupboard.long), Number(cupboard.deep), Number(cupboard.height), {
            diyBoards: cupboard?.diyBoards || [],
            waibanjiaoType: cupboard.waibanjiaoType,
            menwaibanjiao: cupboard.menwaibanjiao,
            waibanjiao: !cupboard.nowaibanjiao,
        });  

    }
    return resultMap;
}

export const getCupboardSize = (order: any, cupboard: any) => {
    return getCupboardBoards(order, cupboard, "getSizeMap");
}

export const getCupboardSheets = (order: any, cupboard: any) => {
    return getCupboardBoards(order, cupboard, "getSheets");
}


export const getCupboardRects = (order: any, cupboard: any) => {
    return getCupboardBoards(order, cupboard, "getRects");
}

export const getCupboardConfigs = (order: any, cupboard: any) => {
    return getCupboardBoards(order, cupboard, "getConfigs");
}

export const getCupboard = (order: any, cupboard: any) => {
    return getCupboardBoards(order, cupboard, false);
}

export const getCupboardTypeMap = (key: string) => {
    if(key.indexOf("beinei") > -1) {
        return "beinei";
    }else if(key.indexOf("beiwai") > -1) {
        return "beiwai";
    }else if(key.indexOf("mennei") > -1) {
        return "mennei";
    }else if(key.indexOf("menwai") > -1) {
        return "menwai"; 
    }else if(key.indexOf("nei") > -1) {
        return "guinei";
    }else if(key.indexOf("wai") > -1) {
        return "guiwai";
    }
}