import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { rectBoard } from "../general-board";

export const _09dineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    guijiaoX: number,
    guijiaoY: number,
    extraConfig?: any
): [Path[], Path[]] => {
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL = corner1(dx, -dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR = corner1(-dx, -dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    
    const boardConfig: any = {
        middleware: {
            top: width > 500,
            left: height > 500,
            right: height > 500,
            bottom: false,
        }
    }
    //board
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);

    if (!extraConfig.无柜腿孔) {

        //板子四个角的四孔
        paths.push({
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50.216983, startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50.216983, startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        },
            //====================================
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50.216983, startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50.216983, startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        },
            //========================================
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50.216983, startY + dy * height - dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50.216983, startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        },
            //=====================================
            {
                type: Circle,
                params: [
                    startX + dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50.216983, startY + dy * height - dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50.216983, startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        });
    }
    if (!extraConfig?.禁止长度增加自动加孔) {

        if (width >= 500) {
            // paths.push({
            //     type: Circle,
            //     params: [
            //         startX + width / 2, startY + dy * height - dy * 32.025123,
            //         3.61
            //     ],
            // }, {
            //     type: Circle,
            //     params: [
            //         startX + width / 2, startY + dy * height - dy * 32.025123 - dy * 31.5,
            //         3.61
            //     ]
            // }, 
            // //======================
            // {
            //     type: Circle,
            //     params: [
            //         startX + width / 2, startY  + dy * 32.025123,
            //         3.61
            //     ]
            // }, {
            //     type: Circle,
            //     params: [
            //         startX + width / 2, startY  + dy * 32.025123 + dy * 31.5,
            //         3.61
            //     ]
            // })
        }
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}