import { Circle } from "../../../../constants";
import { DirectX, DirectY } from "../../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../../util/graph";
import { corner1Sanheyi } from "../../../coners/三合一/corner1-sanheyi";
import { corner3Sanheyi } from "../../../coners/三合一/corner3-sanheyi";
import { corner4Sanheyi } from "../../../coners/三合一/corner4-sanheyi";
import { rectBoard } from "../../general-board";

export const sanheyi01CeneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number
 ): [Path[], Path[]]  => {

    //左下角
    const cornerBLSize = {width: 0, height: 0};
    const cornerBL = corner3Sanheyi(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner4Sanheyi(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner1Sanheyi(-dx, dy, startX, startY,true,  cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner1Sanheyi(dx, dy, startX, startY,true,  cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    const paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);

    if(lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}