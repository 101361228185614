import { useEffect, useState } from "react"
import { Arc, Circle, Layer, Line, Shape, Stage } from "react-konva";
import { NestRequest } from "../../../../../@interface";
import { useParams } from "react-router-dom";

interface Props {

}
export const DxfCheck = (props: Props) => {

    const [data, setData] = useState<NestRequest>();
    const [computedData, setComputedData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [stageX, setStageX] = useState(200);
    const [stageY, setStageY] = useState(200);
    const [stageScale, setStageScale] = useState(1);

    const [lineWidth, setLineWidth] = useState(1);

    useEffect(() => {
        if (!stageScale) {
            return;
        }
        const scale = 1 / stageScale;
        let width = scale;
        if (width > 1) {
            width = 1;
        }
        setLineWidth(width);
    }, [stageScale]);
    
    const param = useParams();
    useEffect(() => {
        setData(JSON.parse((localStorage.getItem(param.storageKey || "SK_NEST_REQUEST_TEMP"))));
    }, [param.storageKey]);

    useEffect(() => {
        if(!data) {
            return ;
        }
        let offsetX = 0;    //同一个柜的板在同一个X轴， 多板的偏移通过此变量控制
        let offsetY = 0;    //不同柜通过不同的Y level区分，由此变量控制Y轴偏移
        let topLevel = Number.MIN_SAFE_INTEGER
        let bottomLevel = Number.MAX_SAFE_INTEGER;
        let leftLevel = Number.MAX_SAFE_INTEGER;
        let rightLevel = Number.MIN_SAFE_INTEGER;
        const _data = JSON.parse(JSON.stringify(data));
        _data.cupboards.forEach((cupboard, index) => {
            let thisBoardTop = Number.MIN_SAFE_INTEGER;
            let thisBoardBottom = Number.MAX_SAFE_INTEGER;
            let thisBoardLeft = Number.MAX_SAFE_INTEGER;
            let thisBoardRight = Number.MIN_SAFE_INTEGER;
            offsetX = 0;
            for (let i = 0; i < cupboard.boards.length; i++) {
                const board = cupboard.boards[i];
                board.components.forEach((component) => {
                    component.shapes.forEach((shape) => {
                        //偏移
                        if(shape.length === 4) {
                            shape[0] += offsetX;
                            shape[2] += offsetX;
                            shape[1] += offsetY;
                            shape[3] += offsetY;
                        }else if(shape.length === 3) {
                            shape[0] += offsetX;
                            shape[1] += offsetY;
                        }else if(shape.length === 6) {
                            shape[0] += offsetX;
                            shape[1] += offsetY;
                            shape[5][0] += offsetX;
                            shape[5][1] += offsetY;
                            shape[5][2] += offsetX;
                            shape[5][3] += offsetY;
                            shape[5][4] += offsetX;
                            shape[5][5] += offsetY;
                        }
                        if(shape.length === 4) {
                            //横轴扩边
                            if(shape[0] < thisBoardLeft || shape[2] < thisBoardLeft) {
                                thisBoardLeft = Math.min(shape[0], shape[2]);
                            }
                            if(shape[0] > thisBoardRight || shape[2] > thisBoardRight) {
                                thisBoardRight = Math.max(shape[0], shape[2]);
                            }
                            //纵轴扩边
                            if(shape[1] < thisBoardBottom || shape[3] < thisBoardBottom) {
                                thisBoardBottom = Math.min(shape[1], shape[3]);
                            }
                            if(shape[1] > thisBoardTop || shape[3] > thisBoardTop) {
                                thisBoardTop = Math.max(shape[1], shape[3]);
                            }
                        }else if(shape.length === 6) {
                            //横轴扩边
                            if(shape[5][0] < thisBoardLeft || shape[5][2] < thisBoardLeft) {
                                thisBoardLeft = Math.min(shape[5][0], shape[5][2]);
                            }
                            if(shape[5][0] > thisBoardRight || shape[5][2] > thisBoardRight) {
                                thisBoardRight = Math.max(shape[5][0], shape[5][2]);
                            }
                            //纵轴扩边
                            if(shape[5][1] < thisBoardBottom || shape[5][3] < thisBoardBottom) {
                                thisBoardBottom = Math.min(shape[5][1], shape[5][3]);
                            }
                            if(shape[5][1] > thisBoardTop || shape[5][3] > thisBoardTop) {
                                thisBoardTop = Math.max(shape[5][1], shape[5][3]);
                            }

                        }
                    });
                });
                offsetX = thisBoardRight + 100;
                // console.log("offsetX, offsetY", offsetX, offsetY);
                
            }
            if(thisBoardLeft < leftLevel) {
                leftLevel = thisBoardLeft;
            }
            if(thisBoardRight > rightLevel) {
                rightLevel = thisBoardRight;
            }
            if(thisBoardBottom < bottomLevel) {
                bottomLevel = thisBoardBottom;
            }
            if(thisBoardTop > topLevel) {
                topLevel = thisBoardTop;
            }
            offsetY = topLevel + 100;
            
        });
        setComputedData(_data);
        setLoading(false);
    }, [data])


    if (loading) {
        return (
            <div>加载中...</div>
        )
    }
    return (
        <div className="nest-checking">
            <Stage
                draggable
                x={stageX}
                y={stageY}
                
                scaleX={stageScale} scaleY={stageScale}
                width={innerWidth} height={innerHeight} style={{
                    width: innerWidth, height: innerHeight, background: "#000"
                }}
                
                onWheel={(e) => {

                    e.evt.preventDefault();
                    const stage = e.target.getStage();
                    const pointer = stage.getPointerPosition();
                    const oldScale = stage.scaleX();
                    const scaleBy = 1.2;

                    var mousePointTo = {
                        x: (pointer.x - stage.x()) / oldScale,
                        y: (pointer.y - stage.y()) / oldScale,
                    };
                    // how to scale? Zoom in? Or zoom out?
                    let direction = e.evt.deltaY > 0 ? -1 : 1;

                    // when we zoom on trackpad, e.evt.ctrlKey is true
                    // in that case lets revert direction
                    if (e.evt.ctrlKey) {
                        direction = -direction;
                    }

                    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                    // stage.scale({ x: newScale, y: newScale });

                    var newPos = {
                        x: pointer.x - mousePointTo.x * newScale,
                        y: pointer.y - mousePointTo.y * newScale,
                    };
                    setStageScale(newScale);
                    setStageX(newPos.x);
                    setStageY(newPos.y);
                }}
            >

                <Layer>
                    {
                        computedData.cupboards.map((cupboard) => {
                            return cupboard.boards.map((board) => {
                                return board.components.map((component) => {
                                    return component.shapes.map((shape) => {
                                        return shape.length === 4 ? (
                                            <Line 
                                                strokeWidth={1}
                                                stroke="#fff"
                                                points={shape}
                                                strokeScaleEnabled={false}
                                            />
                                        ) : shape.length === 6 ? (
                                            <Arc 
                                                x={shape[0]}
                                                y={shape[1]}
                                                angle={shape[4] - shape[3]}
                                                rotation={shape[3]}
                                                innerRadius={shape[2]}
                                                outerRadius={shape[2]}
                                                stroke="#fff"
                                                strokeWidth={1}
                                                strokeScaleEnabled={false}
                                            />

                                        ) : shape.length === 3 ? (
                                            <Circle 
                                                x={shape[0]}
                                                y={shape[1]}
                                                radius={shape[2]}
                                                stroke="#fff"
                                                strokeWidth={1}
                                                strokeScaleEnabled={false}
                                            />
                                        ): undefined
                                    })
                                })
                            })
                        })
                    }
                </Layer>
            </Stage>
        </div>
    )
}