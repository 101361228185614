import { innerBoard } from "../../boards/焊接柜/inner-board";
import { outerBoard } from "../../boards/焊接柜/outer-board";
import { BaseCupboard } from "../@base/BaseCupboard";

export class Hanjiegui extends BaseCupboard {
    key: string = "Hanjiegui";

    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        const result = {}
            // result["outerBoard"] = outerBoard;
            // result["innerBoard"] = innerBoard;
        // for (let i = 0; i < 100; i++) {
        //     result["cewaiBoard-" + i] = _11iiCewaiBoardMiddle;
        //     result["ceneiBoard-" + i] = _11iiCeneiBoardMiddle;
        //     result["menwaiBoard-" + i] = _11iiMenwaiBoard;
        //     result["menneiBoard-" + i] = _11iiMenneiBoard;
        // }
        return result;
    }
    setBoardNameMap() {
        return {};
    }

    // setTypeMap() {
    //     const typeMap: any = {};
    //     for (const name in this.nameMap) {
    //         if(name.indexOf("beinei") > -1) {
    //             typeMap[name] = "beinei";
    //         }else if(name.indexOf("beiwai") > -1) {
    //             typeMap[name] = "beiwai";
    //         }else if(name.indexOf("mennei") > -1) {
    //             typeMap[name] = "mennei";
    //         }else if(name.indexOf("menwai") > -1) {
    //             typeMap[name] = "menwai"; 
    //         }else if(name.indexOf("nei") > -1) {
    //             typeMap[name] = "guinei";
    //         }else if(name.indexOf("wai") > -1) {
    //             typeMap[name] = "guiwai";
    //         }
    //     }
    //     typeMap["qiandangwaiBoard"] = "menwai";
    //     typeMap["mentouwaiBoard"] = "menwai";

    //     return typeMap;
    // }
    // boardNameHasCircleMap: {[baordName: string]: boolean} = {};
    // ceBoardNameDetailMap: {
    //     [boardName: string]: {
    //         waibanjiaoType: string,
    //         hasCircle: boolean,
    //         hasGuijiao: boolean
    //     }
    // }
    // menBoardNameDetailMap: {
    //     [boardName: string]: {
    //         waibanjiaoType: string,
    //         jiaolianbianju: number,
    //         menminglashou: boolean,
    //         menlashoubianju: Number
    //         menlashoufangxiang: string;
    //         menlashouX: number;
    //         menlashouY: number;
    //         menlashou: boolean;
    //         heye: boolean,
    //         gxinglashou: boolean,
    //         gxinglashouType: "up" | "left-or-right",
    //         gxinglashouGaodu: number,
    //         doorSide: "right" | "left"
    //     }
    // }

    boardDetail: {
        [boardName: string]: {
            hasInnerBoard: boolean,
            hasOuterBoard: boolean,
            config: any
        }
    } = {}

    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {

        }
        this.boardDetail = {}
        //动态重设name和type
        for (let i = 0; i < extraConfig.diyBoards.length; i++) {
            const board = extraConfig.diyBoards[i];
            
            const detail: any = {
                hanInnerBoard: board.hasInnerBoard,
                hasOuterBoard: board.hasOuterBoard,
                config: board.config || {}
            }
            if(board.hasInnerBoard) {
                this.boardCNNameMap['innerBoard-' + i] = `${board.name}_内板`;
                this.nameMap['innerBoard-' + i] = innerBoard;
                if(detail.config.isDoor) {
                    this.typeMap['innerBoard-' + i] = 'mennei';
                }else {
                    this.typeMap['innerBoard-' + i] = 'guinei';
                }
                this.boardDetail['innerBoard-' + i] = detail;
                list["innerBoard-" + i] = {
                    size: [Number(board.width) + 21, Number(board.height) + 21]
                }
            }
            if(board.hasOuterBoard) {
                this.nameMap['outerBoard-' + i] = outerBoard
                this.boardCNNameMap['outerBoard-' + i] = `${board.name}_外板`;
                if(detail.config.isDoor) {
                    this.typeMap['outerBoard-' + i] = 'menwai';
                }else {
                    this.typeMap['outerBoard-' + i] = 'guiwai';
                }
                this.boardDetail['outerBoard-' + i] = detail;
                list["outerBoard-" + i] = {
                    size: [Number(board.width) + 47, Number(board.height) + 47]
                }
            }
        }
        //计算size
        // //console.log(this.typeMap);
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }, index: number): [Path[], Path[]] {
        let temp;
        const detail = this.boardDetail[board.name];
       if(board.name.indexOf("innerBoard") === 0) {
            temp = innerBoard(
                1,
                1,
                board.startX,
                board.startY,
                board.width,
                board.height,
                detail.config
            );
       }else {
            temp = outerBoard(
                1,
                1,
                board.startX,
                board.startY,
                board.width,
                board.height,
                detail.config
            );
       }
       return temp;
    }

    //排版
    sheetBoardHandler(boardsInSheet: Sheet[], extraConfig: { [key: string]: any }) {
        this.getSizeMap(0, 0, 0, extraConfig);
        return super.sheetBoardHandler(boardsInSheet, extraConfig);
    }

}