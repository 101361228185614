import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths } from "../../../util/graph";
import { dineiBoard } from "./01-dinei";
import { menneiBoard } from "./01-mennei";
import { _05beineiBoard } from "../转角柜/05-beinei";

export const gecengneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
 ): [Path[], Path[]]  => {

   //  return _05beineiBoard(dx, dy, startX, startY, width, height);
   return menneiBoard(dx, dy, startX, startY, width, height, true, -1);
 }