import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach, numberEqual } from "../../../util";
import { corner12WaiWuhujiao } from "./corner12-wai-wuhujiao";


export const corner16WaiWuhujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner12WaiWuhujiao(dx, dy, startX, startY, true, size);
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === Circle) {
            if(numberEqual(startY + dy * size.height - dy * 32.385, path.params[1])) {
                path.params[1] = startY + dy * size.height - dy * 15.385;
            }
        }
    }
    if (typeof sizeRes === 'object') {
        
        sizeRes.width = size.width;
        sizeRes.height = size.height;
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);;
}
