import { DirectX, DirectY } from "../../../constants/enum";
import { _02AdiwaiBoard } from "./02-a-diwai";
import { _02diwaiBoard } from "./02-diwai";

export const _02AdingwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return _02AdiwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
}