let _host = "https://api.dasheng.toskies.com:4430";
let _host2 = "https://api.dasheng.toskies.com:4431";
let _nestHost = "https://api.dasheng.toskies.com:4434";
let _wsHost = "wss://api.dasheng.toskies.com:4430";
let _wsHost2 = "wss://api.dasheng.toskies.com:4434";
if(process.env.NODE_ENV === 'development'){
    _host = "http://localhost:8080";
    _host2 = "http://localhost:8081";
    // _nestHost = "http://localhost:8081";
    _wsHost = "ws://localhost:8080";
    // _wsHost2 = "ws://localhost:8081";
}

export const host = _host;
export const host2 = _host2;
export const nestHost = _nestHost;
export const wsHost = _wsHost;
export const wsHost2 = _wsHost2;