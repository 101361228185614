import { ExpandOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { konva, useWidgets } from "../../store";

export const BottomWidget = (props: {
    mode: "2d" | "3d",
    onChangeTo2D: () => void;
    onChangeTo3D: () => void;
}) => {


    const {
        //缩放相关
        stageScale, setStageScale, toStageCenter, setStageScaleAndToCenter,
        //绘图相关
        drawType, startDraw,
    } = useWidgets();


    return (
        <>
            {/* 2d/3d切换 */}
            <div onClick={() => {
            }} className="flex center" style={{ cursor: "pointer", zIndex: 9, position: 'fixed', left: 'calc((100vw - 120px) / 2 - 16px - 80px)', bottom: 16, width: 80, borderRadius: 4, padding: '0 6px', boxShadow: '0px 2px 8px rgba(3,9,17,0.08)', height: 36, background: "#f8f9fb" }}>
                <div className="flex center" style={{flex: 1,background: props.mode === '2d' ? '#deeaf9': undefined, color: props.mode === '2d' ? "#2e85f7" : undefined}} onClick={props.onChangeTo2D}>2D</div>
                <div className="flex center" style={{flex: 1,background: props.mode === '3d' ? '#deeaf9': undefined, color: props.mode === '3d' ? "#2e85f7" : undefined}} onClick={props.onChangeTo3D}>3D</div>
            </div>
            {/* 放大缩小 */}
            <div className="flex center space-between" style={{ zIndex: 9, opacity: props.mode === '2d' ? 1 : 0.5,  position: 'fixed', left: 'calc((100vw - 120px) / 2)', bottom: 16, width: 120, borderRadius: 4, padding: '0 6px', boxShadow: '0px 2px 8px rgba(3,9,17,0.08)', height: 36, background: "#f8f9fb" }}>
                <MinusOutlined title="放大" style={{ cursor: "pointer" }} onClick={() => {
                    if(props.mode === '2d') {
                        setStageScaleAndToCenter(stageScale / 1.05);
                    }
                }} />
                <input disabled value={(stageScale * 100).toFixed(0) + "%"} style={{ width: 50, outline: 'none', borderRadius: 2, border: '1px solid  #efefef', textAlign: 'center', backgroundColor: 'rgba(0,0,0,0)', height: 28 }} />
                <PlusOutlined title="缩小" style={{ cursor: "pointer" }} onClick={() => {
                    if(props.mode === '2d') {
                        setStageScaleAndToCenter(stageScale * 1.05);
                    }
                }} />
            </div>
            {/* 回到中心 */}
            <div className="flex center" title="回到中心&重置缩放" onClick={() => {
                if(props.mode === '2d') {
                    setStageScale(1);
                    toStageCenter();
                }
            }} style={{ zIndex: 9, opacity: props.mode === '2d' ? 1 : 0.5, cursor: "pointer", position: "fixed", left: "calc((100vw - 120px) / 2 +  120px + 16px)", bottom: 16, width: 40, borderRadius: 4, padding: '0 6px', boxShadow: '0px 2px 8px rgba(3,9,17,0.08)', height: 36, background: "#f8f9fb" }}>
                <div className="relative">
                    <div className="absolute flex center" style={{ left: 0, top: 0, width: "100%", height: "100%" }}>
                        <span>·</span>
                    </div>
                    <ExpandOutlined />
                </div>
            </div>
        </>
    )

}