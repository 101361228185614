import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { conbinePaths, pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { _03gongyongcewaiBoard } from "./03-gongyongcewai";

export const _04gongyongcewaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _03gongyongcewaiBoard(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, corner, geceng, gecengX, gecengY, gecengkongjing, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);

    for (let i = 0; i < paths.length; i++) {
        const temp = paths[i];
        if(temp.type === Arc) {
            // //console.log(temp.params);
            if(numberEqual(temp.params[3] % 360, 90) && numberEqual(temp.params[4] % 360, -180)) {
                temp.params[3] = 270;
                temp.params[4] = 360;
            }else if(numberEqual(temp.params[3], -90) && numberEqual(temp.params[4], -360) && numberEqual(temp.params[2], 0.2)) {
                // //console.log(temp);
                temp.params[3] = 90;
                temp.params[4] = 180;
            }
        }
    }
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]

 }