import { ArrowUpOutlined, BorderOutlined, CaretDownOutlined, CloseOutlined, DownOutlined, EllipsisOutlined, MinusOutlined, MoreOutlined, RocketFilled, RocketOutlined, SettingOutlined, SyncOutlined } from "@ant-design/icons";
import { Badge, Dropdown, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ipcRenderer } from "../../../utils/electron";
import { SettingTab } from "./Tabs";

interface Props {
    tabsRef: any;
}
let maxmizeStateTemp: "maximize" | "unmaximize" = "unmaximize";
export default function Widgets(props: Props) {

    const onClose = () => {
        ipcRenderer.send("app-exit");
    }

    const onMinimize = () => {
        ipcRenderer.send("win-minimize");

    }

    const onMaximize = () => {
        ipcRenderer.send("win-maximize");
    }

    const onUnmaximize = () => {
        ipcRenderer.send("win-unmaximize");
    }

    const [maxmizeState, setMaxmizeState] = useState(maxmizeStateTemp);

    useEffect(() => {
        ipcRenderer?.on("win-maximize", () => {
            maxmizeStateTemp = "maximize";
            setMaxmizeState("maximize");
        });
        ipcRenderer?.on("win-unmaximize", () => {
            maxmizeStateTemp = "unmaximize";
            setMaxmizeState("unmaximize");
        });
        return () => {
            ipcRenderer?.removeAllListeners("win-maximize");
            ipcRenderer?.removeAllListeners("win-unmaximize");
        }
    }, []);

    const items: MenuProps['items'] = [
        {
            key: 'settings',
            icon: <SettingOutlined />,
            label: "设置",
            onClick: () => {
                props.tabsRef.current?.push(SettingTab)
            },
        },
        {
            key: 'update-checking',
            icon: <SyncOutlined />,
            label: (
                <span>
                    检查更新
                    <span style={{ color: "lightgreen", position: 'relative', top: -1, left: 0, }}>
                        <ArrowUpOutlined style={{ color: 'lightgreen', marginLeft: 4, fontSize: 12 }} />
                        <span style={{ fontSize: 12, display: "inline-block", transform: 'scale(0.9)', transformOrigin: "right" }}>新</span>
                    </span>
                </span>
            ),
            onClick: undefined,
        }
    ];


    return (
        <Wrapper className="window-widgets">
            {/* <Dropdown menu={{ items }} trigger={["click"]}>
                <Badge dot offset={[0, 5]} color="lightgreen">
                <div className="widget-item more">
                    <DownOutlined />
                </div>
                </Badge>
            </Dropdown> */}
            {
                ipcRenderer && (
                    <>

                        <div className='widget-item' onClick={onMinimize}>
                            <MinusOutlined />
                        </div>
                        {
                            maxmizeState === 'maximize' ? (
                                <div className='widget-item scale relative' onClick={onUnmaximize}>
                                    <BorderOutlined className="block-1 absolute" />
                                    <BorderOutlined className="block-2 absolute" />
                                </div>
                            ) : (
                                <div className='widget-item' onClick={onMaximize}>
                                    <BorderOutlined />
                                </div>
                            )
                        }
                        <div className='widget-item close' onClick={onClose}>
                            <CloseOutlined />
                        </div>
                    </>

                )
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
        display: flex;
        flex-direction: row;
        -webkit-app-region: no-drag;
        color: #fff;
        
        .widget-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 36px;
            .anticon {
                font-size: 14px;
            }
        }
        .widget-item.more {
            width: 24px;
            cursor: pointer;
            .anticon {
                font-size: 12px;
                font-weight: bold;
            }
        }
        .widget-item.scale {
            .anticon.block-1 {
                left: calc((42px - 14px) / 2 - 1px);
                top: calc((36px - 14px) / 2 + 1px);
                font-size: 12px;
                display: inline-block;
                background-color: #000;
                z-index: 2;
            }

            .anticon.block-2 {
                left: calc((42px - 14px) / 2 + 1px);
                top: calc((36px - 14px) / 2 - 1px);
                font-size: 12px;
            }
        }

        .widget-item.close {
            font-weight: bold;
        }

        .widget-item {
            &:hover {
                background-color: #666;
                .anticon.block-1 {
                    background-color: #666;
                }
            }
            
        }
        .widget-item.close {
            &:hover {
                background-color: rgba(255, 0, 0, 0.8);
                color: #fff;
            }
        }
    `;
