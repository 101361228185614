import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { corner12 } from "../../coners/corner12";
import { corner13 } from "../../coners/corner13";
import { corner12WaiWuhujiao } from "../../coners/无护角/corner12-wai-wuhujiao";
import { corner13WaiWuhujiao } from "../../coners/无护角/corner13-wai-wuhujiao";
import { corner12WaiWujiao } from "../../coners/无角/corner12-wai-wujiao";
import { corner13WaiWujiao } from "../../coners/无角/corner13-wai-wujiao";
import { rectBoard } from "../general-board";

export const _02AdiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner13(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }else if(cornerType === "wujiao") {
        cornerBL = corner13WaiWujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }else if(cornerType === "wuhujiao") { 
        cornerBL = corner13WaiWuhujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;

    }

    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner13(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    }else if(cornerType === "wujiao") {
        cornerBR = corner13WaiWujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    }else if(cornerType === "wuhujiao") { 
        cornerBR = corner13WaiWuhujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);

    }
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner12(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        for (let i = 0; i < cornerTR.length; i++) {
            const temp = cornerTR[i];
            if(temp.type === Circle && (numberEqual(temp.params[1], startY + dy * height - dy * 32.385, 0.01)))  {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }
        }
    }else if(cornerType === "wujiao") {
        cornerTR = corner12WaiWujiao(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        for (let i = 0; i < cornerTR.length; i++) {
            const temp = cornerTR[i];
            if(temp.type === Circle && (numberEqual(temp.params[1], startY + dy * height - dy * 32.385, 0.01)))  {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }
        }
    }else if(cornerType === "wuhujiao") {
        cornerTR = corner12WaiWuhujiao(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        for (let i = 0; i < cornerTR.length; i++) {
            const temp = cornerTR[i];
            if(temp.type === Circle && (numberEqual(temp.params[1], startY + dy * height - dy * 32.385, 0.01)))  {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }
        }

    }
    

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];// = corner12(dx, dy, startX, startY, corner, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner12(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        for (let i = 0; i < cornerTL.length; i++) {
            const temp = cornerTL[i];
            if(temp.type === Circle && (numberEqual(temp.params[1], startY + dy * height - dy * 32.385)))  {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }
        }
    }else if(cornerType === "wujiao") {
        cornerTL = corner12WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        for (let i = 0; i < cornerTL.length; i++) {
            const temp = cornerTL[i];
            if(temp.type === Circle && (numberEqual(temp.params[1], startY + dy * height - dy * 32.385)))  {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }
        }
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner12WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        for (let i = 0; i < cornerTL.length; i++) {
            const temp = cornerTL[i];
            if(temp.type === Circle && (numberEqual(temp.params[1], startY + dy * height - dy * 32.385)))  {
                temp.params[1] = startY + dy * height - dy * 15.385;
            }
        }
    }
    const boardConfig: any = {
        middleware: {
            top: width > 500 && "close",
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: false,
        }
    }
    let paths;
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}