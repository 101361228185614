import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { _02AceneiBoardLeft } from "./02-a-cenei-left";
import { _02AceneiBoardRight } from "./02-a-cenei-right";
import { _02ceneiBoardRight } from "./02-cenei-right";

export const _12AceneiBoardMiddle = (

    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    // lianjiekongbianju: number,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number
    
): [Path[], Path[]] => {
    let [boardPaths, cornerPaths] = _02AceneiBoardLeft(dx, dy, startX, startY, width, height, -1, { noMiddleware: true });
    cornerPaths = cornerPaths.filter((item) => item.type !== Circle);
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * (59 + gecengX), startY + dy * (gecengY + 6) - dy * 18 - dy * 13,
                (gecengkongjing || 3) / 2,
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * 63, startY + dy * (gecengY + 6) - dy * 18 - dy * 13,
                (gecengkongjing || 3) / 2,
            ]
        });
    }
    return [boardPaths, cornerPaths];
}