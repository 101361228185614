import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum"
import { _11cewaiBoardLeft } from "./11-cewai-left"

export const _11iiCewaiBoardMiddle = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean,
    hasCircle: boolean,
    hasGuijiao: boolean,
): [Path[], Path[]] => {
    const [boardPaths, cornerPaths] = _11cewaiBoardLeft(cornerType,dx, dy, startX, startY, width, height, corner);
    if(hasGuijiao) {
        boardPaths.shift();
    }
    cornerPaths.pop();
    cornerPaths.pop();
    if(hasCircle) {
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * 84, startY + dy * 144,
                30
            ]
        })
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * 84, startY + dy * 224,
                30
            ]
        });
    }
    if(hasGuijiao) {
        const _sx = cornerType === "youhujiao" ? 34 : cornerType  === "wuhujiao" ? 34.25022 : 0;
        const c1X = startX + dx * _sx + dx * 61.5 + dx * 9 / 2;
        const c1Y = startY + dy * 15;
        boardPaths.push({
            type: Circle,
            params: [
                c1X - dx * 20, c1Y,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                c1X, c1Y,
                4.5
            ]
        }, {
            type: Circle,
            params: [
                c1X + dx * 20, c1Y,
                2.5
            ]
        });
        boardPaths.push({
            type: Line,
            params: [
                startX + dx * _sx, startY,
                startX + dx * _sx + dx * 61.5, startY,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * _sx + dx * 61.5, startY,
                startX + dx * _sx + dx * 61.5, startY + dy * 2.3,
    
            ]
        }, {
            type: Line,
            params: [
                startX + dx * _sx + dx * 61.5, startY + dy * 2.3,
                startX + dx * _sx + dx * 61.5 + dx * 9, startY + dy * 2.3,
    
            ]
        }, {
            type: Line, 
            params: [
                startX + dx * _sx + dx * 61.5 + dx * 9, startY + dy * 2.3,
                startX + dx * _sx + dx * 61.5 + dx * 9, startY,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * _sx + dx * 61.5 + dx * 9, startY,
                startX + dx * width - (dx * _sx + dx * 61.5 + dx * 9), startY,
            ]
        });
        
        const c2X = startX + dx * width - (dx * _sx + dx * 61.5 + dx * 9 / 2);
        const c2Y = startY + dy * 15;
        boardPaths.push({
            type: Circle,
            params: [
                c2X - dx * 20, c2Y,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                c2X, c2Y,
                4.5
            ]
        }, {
            type: Circle,
            params: [
                c2X + dx * 20, c2Y,
                2.5
            ]
        });
        boardPaths.push({
            type: Line,
            params: [
                startX + dx * width - (dx * _sx + dx * 61.5 + dx * 9), startY,
                startX + dx * width - (dx * _sx + dx * 61.5 + dx * 9), startY + dy * 2.3, 
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * _sx + dx * 61.5 + dx * 9), startY + dy * 2.3, 
                startX + dx * width - (dx * _sx + dx * 61.5), startY + dy * 2.3, 
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * _sx + dx * 61.5), startY + dy * 2.3, 
                startX + dx * width - (dx * _sx + dx * 61.5), startY, 
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * _sx + dx * 61.5), startY, 
                startX + dx * width - (dx * _sx), startY, 
            ]
        })
    }
    return [boardPaths, cornerPaths];
}