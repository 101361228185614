import { DirectX, DirectY } from "../../../constants/enum";
import { _12AdiwaiBoard } from "./12-a-diwai";

export const _12AdingwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number,
    corner: boolean,
): [Path[], Path[]] => {
    return _12AdiwaiBoard(cornerType, dx, dy, startX, startY, width, height, a, corner);
}