import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { _05beiwaiBoard } from "./05-beiwai";

export const _05zhuanjiaowaiYBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    const [boards, corners] = _05beiwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner, false, {
        noMiddleware: true
    });
    for (let i = 0; i < corners.length; i++) {
        const temp = corners[i];
        if(temp.type === Circle) {
            
            if(numberEqual(temp.params[0], startX + dx * 15.385)) {
                temp.params[0] = startX + dx * 32.385;
            }else {
                // //console.log(temp.params[0], startX + dx * width - dx * 15.385);
                
                if(numberEqual(temp.params[0], startX + dx * width - dx * 15.385)) {
                    temp.params[0] = startX + dx * width - dx * 32.385;
                }
            }
        }
    }
    return [
        boards,
        corners
    ]
}