import React, { createRef, useEffect, useState } from 'react';
import './App.less';
// import { Map as IMap, List as IList } from 'immutable';
import { OrderCreateModal } from './components/OrderCreateModal';
import { CupboardCreateModal } from './components/CupboardCreateModal';
// import jsPDF from 'jspdf';
import { TagsPrintModal } from './components/TagsPrintModal';
import { ExportOutlined, MinusOutlined,  MinusCircleOutlined, FileSearchOutlined, PlusOutlined, LockOutlined, UserAddOutlined, UserOutlined, UsergroupAddOutlined, EllipsisOutlined, CheckOutlined, EditOutlined, SaveOutlined, SettingOutlined, DeleteOutlined, SyncOutlined, HistoryOutlined } from '@ant-design/icons';
// import html2canvas from 'html2canvas';
import { Dropdown, Menu, message, Form, Checkbox, Radio, Input, Modal, Empty, Button, Tag, Select, Drawer, Switch, Spin } from 'antd';
import { DxfCanvasPreview } from './components/DxfPreviewCanvas';
import { parse } from 'svg-parser';
import { getCupboard, getCupboardBoards, getCupboardConfigs, getCupboardRects, getCupboardSheets } from './utils/cupboard';
import { genRectsSvg } from './utils/nest';
import { InputNumber } from 'antd';
import { isEn, locale } from './utils/locale';
import { HanjieguiBoardAddModal } from './components/HanjieguiToolSide/HanjieguiBoardAddModal';
import { HanjieguiBoardUpdateModal } from './components/HanjieguiToolSide/HanjieguiBoardUpdateModal';
import { useNavigate, useParams } from 'react-router-dom';
import { DxfGenerator } from '../utils/v1-engine/dxf-generator';
import { setting } from '../utils/v1-engine/util/setting';
import { request } from '../utils2/request';
import { apis } from '../utils2/apis';



const App = (props) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [activeOrder, setActiveOrder] = useState({});
    const [syncing, setSyncing] = useState(false);
    const [nestPreparing, setNestPreparing] = useState(false);

    useEffect(() => {
        request.get(apis.order + `/${params.orderId}/detail`)
            .then((res) => {
                if(res.code === 1) {
                    setActiveOrder(res.data);
                    setLoading(false);
                }
            })
    }, [params.orderId]);

    const [activeCupboard, setActiveCupboard] = useState({});
    useEffect(() => {
        if(!activeCupboard.id) {
            return ;
        }
        // console.log("activeCupbaord", activeCupboard);
        setSyncing(true);
        request.put(apis.order + `/cupboard/${activeCupboard.id}`, activeCupboard)
            .then((res) => {
                if(res.code === 1) {
                    setSyncing(false)
                }
            })
    }, [activeCupboard]);

    //new
    const [availableMaterials, setAvailableMaterials] = useState([]);
    const [canvasRefs, setCanvasRefs] = useState([]);
    const [nestMaterialId, setNestMaterialId] = useState(0);

    const [hanjieguiBoardAddModalVisible, setHanjieguiAddModalVisible] = useState(false);
    const [hanjieguiBoardAddSelectedKey, setHanjieguiBoardAddSelectedKey] = useState("");
    const [hanjieguiBoardAddKey, setHanjieguiBoardAddKey] = useState("");
    const [hanjieguiBoardAddConfig, setHanjieguiBoardAddConfig] = useState({});
    const openHanjieguiBoardAddModal = () => {
        setHanjieguiAddModalVisible(true);
    }
    const closeHanjieguiBoardAddModal = () => {
        setHanjieguiAddModalVisible(false);
        setHanjieguiBoardAddSelectedKey("");
        setHanjieguiBoardAddKey("");
        setHanjieguiBoardAddConfig({});
    }

    const [hanjieguiBoardUpdateModalVisible, setHanjieguiUpdateModalVisible] = useState(false);
    const [hanjieguiBoardUpdateSelectedKey, setHanjieguiBoardUpdateSelectedKey] = useState("");
    const [hanjieguiBoardUpdateKey, setHanjieguiBoardUpdateKey] = useState("");
    const [hanjieguiBoardUpdateConfig, setHanjieguiBoardUpdateConfig] = useState({});
    const openHanjieguiBoardUpdateModal = (board) => {
        setHanjieguiUpdateModalVisible(true);
        setHanjieguiBoardUpdateConfig(board)
    }
    const closeHanjieguiBoardUpdateModal = () => {
        setHanjieguiUpdateModalVisible(false);
        setHanjieguiBoardUpdateSelectedKey("");
        setHanjieguiBoardUpdateKey("");
        setHanjieguiBoardUpdateConfig({});
    }


    const [hanjieguiTemplateSaveMode, setHanjieguiTemplateSaveMode] = useState(false);
    const [hanjieguiTemplateNameTemp, setHanjieguiTemplateNameTemp] = useState("");
    const openHanjieguiTemplateSaveMode = () => {
        setHanjieguiTemplateSaveMode(true);
    }
    const closeHanjieguiTemplateSaveMode = () => {
        setHanjieguiTemplateSaveMode(false);
        setHanjieguiTemplateNameTemp("");
    }



    const canvasResize = () => {
        if (!activeOrder.materials || !availableMaterials.length) {
            return;
        }
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 600) {
            return;
        }
        const canvasWidth = width - 84 - (isEn ? 320 : 232);
        const canvasHeight = (height - 30 * 2) / availableMaterials.length;

        const availableMaterialIds = availableMaterials.map((item) => item.id);
        for (let i = 0; i < activeOrder.materials.length; i++) {
            const material = activeOrder.materials[i];
            if (!canvasRefs[i].current) {
                return;
            }
            if (availableMaterialIds.includes(material.id)) {
                //可用
                canvasRefs[i].current.width = canvasWidth;
                canvasRefs[i].current.height = canvasHeight;
            } else {
                canvasRefs[i].current.width = 0;
                canvasRefs[i].current.height = 0;
            }
        }
        dxfCanvasPreview();
    }

    const [settings, setSettings] = useState(({}));

    useEffect(() => {
        if(Object.keys(settings).length > 0) {
            localStorage.setItem("settings", JSON.stringify(settings));
            setting.syncSettings(settings || {});
        }
    }, [settings])

    //==============================订单相关=========================//
    useEffect(() => {
        setActiveCupboard(activeOrder.boards ? activeOrder.boards[0] : {});
        // activeOrderTemp = activeOrder;
        if (activeOrder.materials) {
            setCanvasRefs(activeOrder.materials.map(() => createRef()));
            //console.log("first init canvas refs");
            setAvailableMaterials(activeOrder.materials);
        }
    }, [activeOrder]);

    //根据可选材料 设置几个canvas
    useEffect(() => {
        canvasResize();
        window.addEventListener("resize", canvasResize, false);
    }, [availableMaterials]);

    useEffect(() => {
        if (activeOrder.boards?.length > 0) {
            let already = false;
            for (let i = 0; i < activeOrder.boards.length; i++) {
                const board = activeOrder.boards[i];
                if (board.id === activeCupboard.id) {
                    already = true;
                    break;
                }
            }
            if (!already) {
                setActiveCupboard(activeOrder.boards[0]);
            }
        } else {
            setActiveCupboard({});
        }
    }, [activeOrder.boards]);


    useEffect(() => {
        dxfCanvasPreview();
    }, [availableMaterials, activeCupboard]);


    const dxfCanvasPreview = () => {
        if (!activeCupboard.id) {
            return;
        }
        let resultMap = getCupboardBoards(activeOrder, activeCupboard)
        let i = 0;
        for (const materialId in resultMap) {
            const sheet = resultMap[materialId];
            canvasRefs[i].current.drawCanvas(sheet.paths, sheet.width, sheet.height);
            i++;
        }
    }


    const [settingDrawerVisible, setSettingDrawerVisible] = useState(false);
    const openSettingsDrawer = () => {
        setSettingDrawerVisible(true)
    }
    const closeSettingDrawer = () => {
        setSettingDrawerVisible(false)
    }

    /**
     * 新函数
     */
    const navigate = useNavigate();
    const onGoNest = async () => {
        setNestPreparing(true);
        const order = await request.get(apis.order + `/${params.orderId}/detail`)
            .then((res) => {
                if(res.code === 1) {
                    return res.data;
                }
            })
        const nestRequest = {
            id: Date.now().toString(),
            order: {
                orderNo: order.orderNo,
                address: order.address,
            },
            materials: order.materials.filter((m) => !m.hide).map((m) => ({id: m.id, name: m.name, texture: { type: "COLOR", value: "lightgray" }})),
            cupboards: [],
            compatibleV1: true,
        }
        let boardId = 0;
        for (let i = 0; i < order.boards.length; i++) {
            const cupboard = order.boards[i];
            const _cupboard = {
                id: cupboard.id,
                name: cupboard.long + cupboard.name,
                length: cupboard.long,
                deep: cupboard.deep,
                height: cupboard.height,
                connectorType: "二合一",
                cornerType: cupboard.nowaibanjiao ? '无角' : cupboard.waibanjiaoType === 'youhujiao' ? '有护角' : '无护角',
                boards: []
            }
            nestRequest.cupboards.push(_cupboard);
            let boards = getCupboardBoards(order, cupboard, "getBoards");
            console.log("boards");
            for (let i = 0; i < boards.length; i++) {
                const board = boards[i];
                const _board = {
                    id: ++boardId,
                    // cupboardId: _cupboard.id,
                    // cupboardName: _cupboard.name,
                    name: board.friendlyName.replace("_内板", '').replace('_外板', ''),
                    width: board.width,
                    height: board.height,
                    type: board.friendlyName.indexOf("外") > -1 ? '外板' : '内板',
                    connectorType: _cupboard.connectorType,
                    cornerType: board.extraConfig?.nowaibanjiao ? '无角' : board.extraConfig?.waibanjiaoType === 'youhujiao' ? '有护角' : '无护角',
                    materialId: board.materialId,
                    components: [],
                    computedComponents: [{
                        flags: 0,
                        shapes: board.paths[0].concat(board.paths[1]).map((item) => {
                            return item.params;
                        })
                    }] 
                }
                _cupboard.boards.push(_board)
            }
        }
        console.log(nestRequest);
        localStorage.setItem("SK_NEST_REQUEST_TEMP", JSON.stringify(nestRequest));
        setNestPreparing(false);
        navigate("/nest")
    }

    return (
        <Spin spinning={loading} tip="加载中...">
            
        <div className="flex f-column flex1 app-wrapper" style={{height: '100vh', width: '100vw'}}>
            
        <Button type='link' style={{position: 'absolute', top: 4, right: 10, zIndex: 999999, fontSize: 20}}><HistoryOutlined /> </Button>
         
            <div className="app no-drag" style={{ display:  'flex' }}>
                <div className="cg-modes">

                    {
                        activeOrder.boards?.length > 0 && activeOrder.boards.map((board, index) => {
                            // //console.log(board);
                            return (
                                <Dropdown trigger={["contextMenu"]} overlay={(
                                    <Menu>
                                        <Menu.Item onClick={() => {
                                            setLoading(true);
                                            request.get(apis.order + `/cupboard/${board.id}`)
                                                .then((res) => {
                                                    if(res.code === 1) {
                                                        setActiveCupboard(res.data);
                                                        setLoading(false);
                                                    }
                                                })
                                        }}>
                                            <FileSearchOutlined />
                                            {locale['查看']}

                                        </Menu.Item>
                                        <Menu.Item danger onClick={() => {
                                            request.delete(apis.order + `/cupboard/${board.id}`)
                                                .then((res) => {
                                                    if(res.code === 1) {
                                                        if (activeCupboard.id === board.id) {
                                                            setActiveCupboard(index - 1 >= 0 ? activeOrder.boards[index - 1] : index + 1 <= activeOrder.boards.length - 2 ? activeOrder.boards[index + 1] : {});
                                                        } else {
                                                            setActiveCupboard({ ...activeCupboard });
                                                        }
                                                        activeOrder.boards = activeOrder.boards.filter((item) => item.id !== board.id);
                                                        setActiveOrder(activeOrder);
                                                        // setActiveCupboard(nu)
                                                        message.info(locale["已删除"]);
                                                    }
                                                })
                                        }}>
                                            <MinusCircleOutlined />
                                            {locale['删除']}
                                        </Menu.Item>
                                    </Menu>
                                )}>
                                    <div className={"item " + (activeCupboard.id === board.id ? 'active' : '')} key={"board-" + index} onClick={() => {
                                        // setActiveCupboard({...board});
                                        setLoading(true);
                                        request.get(apis.order + `/cupboard/${board.id}`)
                                            .then((res) => {
                                                if(res.code === 1) {
                                                    setActiveCupboard(res.data);
                                                    setLoading(false);
                                                }
                                            })

                                    }}>{index + 1}.{board.name}</div>
                                </Dropdown>
                            )
                        })
                    }

                    <CupboardCreateModal order={activeOrder} onSelected={(board) => {
                        request.post(apis.order + `/${activeOrder.id}/cupboard`, board)
                            .then((res) => {
                                if(res.code === 1) {
                                    activeOrder.boards.push(res.data);
                                    setActiveCupboard(res.data);
                                    // setActiveOrder(activeOrder);
                                    message.info("已添加" + board.name);
                                }
                            });
                    }} />


                    {/* hanjiegui add modal */}
                    <HanjieguiBoardAddModal
                        visible={hanjieguiBoardAddModalVisible}
                        onCancel={closeHanjieguiBoardAddModal}
                        hanjieguiBoardAddKey={hanjieguiBoardAddKey}
                        hanjieguiBoardAddConfig={hanjieguiBoardAddConfig}
                        setHanjieguiBoardAddConfig={setHanjieguiBoardAddConfig}
                        onSaveFromBoards={(boards) => {
                            boards.forEach((b, index) => {
                                b.id = Date.now() + "-" + index;
                                if (activeCupboard.diyBoards?.length > 0) {
                                    activeCupboard.diyBoards.push(b);
                                } else {
                                    activeCupboard.diyBoards = [b];
                                }
                                setActiveCupboard({ ...activeCupboard });
                                closeHanjieguiBoardAddModal();
                            });
                            message.info("已添加")
                        }}
                        onSave={(data) => {
                            // let id = (activeCupboard.diyBoards?.length || 0) + 1;
                            data.id = Date.now();
                            if (activeCupboard.diyBoards?.length > 0) {
                                activeCupboard.diyBoards.push(data);
                            } else {
                                activeCupboard.diyBoards = [data];
                            }
                            setActiveCupboard({ ...activeCupboard });
                            closeHanjieguiBoardAddModal();
                        }}
                    />
                    <HanjieguiBoardUpdateModal
                        visible={hanjieguiBoardUpdateModalVisible}
                        onCancel={closeHanjieguiBoardUpdateModal}
                        hanjieguiBoardUpdateKey={hanjieguiBoardUpdateKey}
                        hanjieguiBoardUpdateConfig={hanjieguiBoardUpdateConfig}
                        setHanjieguiBoardAddConfig={setHanjieguiBoardUpdateConfig}
                        onSave={(data) => {
                            const index = activeCupboard.diyBoards.findIndex((item) => item.id === data.id);
                            activeCupboard.diyBoards[index] = data;
                            setActiveCupboard({ ...activeCupboard });
                            closeHanjieguiBoardUpdateModal();
                        }}
                    />
                </div>
                {
                    activeCupboard.id && activeCupboard.key === "hanjiegui" && (
                        <div className="cg-control" style={{ width: isEn ? 320 : 232 }}>
                            <div style={{}}>
                                <Button icon={(<PlusOutlined />)} type="link" onClick={() => {
                                    openHanjieguiBoardAddModal();
                                }}>
                                    添加柜板
                                </Button>
                                {
                                    activeCupboard.diyBoards?.length > 0 && activeCupboard.diyBoards.map((db, index) => {
                                        return (
                                            <Dropdown
                                                trigger={["contextMenu"]}
                                                overlay={(
                                                    <Menu>
                                                        <Menu.Item danger onClick={() => {
                                                            Modal.confirm({
                                                                title: "请确认是否删除该板",
                                                                content: "删除后不可恢复，请进行确认",
                                                                okButtonProps: {
                                                                    danger: true,
                                                                },
                                                                onOk: () => {
                                                                    const diyBoards = activeCupboard.diyBoards.filter((b) => {
                                                                        return b.id !== db.id;
                                                                    });
                                                                    activeCupboard.diyBoards = diyBoards;
                                                                    setActiveCupboard({ ...activeCupboard });
                                                                }
                                                            });
                                                        }}>删除</Menu.Item>
                                                    </Menu>
                                                )}
                                            >
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 42, paddingRight: 6 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                                                            {/* <div style={{width: 30, textAlign: 'center', color: 'gray'}}></div> */}
                                                            <div style={{ fontSize: 14, color: "#fff" }}>{index + 1}. {db.name || ("板" + (index + 1))}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div
                                                                onClick={() => {
                                                                    db.hasOuterBoard = !db.hasOuterBoard;
                                                                    activeCupboard.diyBoards = [...activeCupboard.diyBoards];
                                                                    setActiveCupboard({ ...activeCupboard });
                                                                }}
                                                                style={{ border: `1px solid ${db.hasOuterBoard ? 'lightblue' : 'gray'}`, color: db.hasOuterBoard ? 'lightblue' : 'gray', padding: '0 8px', marginRight: 6, fontSize: 12, cursor: 'pointer' }}>外板</div>
                                                            <div
                                                                onClick={() => {
                                                                    db.hasInnerBoard = !db.hasInnerBoard;
                                                                    activeCupboard.diyBoards = [...activeCupboard.diyBoards];
                                                                    setActiveCupboard({ ...activeCupboard });
                                                                }}
                                                                style={{ border: `1px solid ${db.hasInnerBoard ? 'lightblue' : 'gray'}`, color: db.hasInnerBoard ? 'lightblue' : 'gray', padding: '0 8px', marginRight: 6, fontSize: 12, cursor: 'pointer' }}>内板</div>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, color: 'gray', padding: '0 10px' }}>
                                                            <div style={{ marginRight: 8 }}>宽: {db.width}mm</div>
                                                            <div>高: {db.height}mm</div>
                                                            <Button type="link" icon={(<EditOutlined />)} size="small" onClick={() => {
                                                                openHanjieguiBoardUpdateModal(db);
                                                            }}>编辑</Button>
                                                        </div>
                                                    </div>
                                                </div></Dropdown>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ textAlign: 'center', height: 50, lineHeight: '50px' }}>
                                <Modal
                                    visible={hanjieguiTemplateSaveMode}
                                    onCancel={closeHanjieguiTemplateSaveMode}
                                    centered
                                    title="保存模板"
                                    onOk={() => {
                                        if (!hanjieguiTemplateNameTemp) {
                                            message.info("请输入名称");
                                            return;
                                        }

                                        let local = localStorage.getItem("hanjiegui-templates");
                                        let store = [];
                                        if (local) {
                                            store = JSON.parse(local);
                                        }
                                        store.push({
                                            id: Date.now(),
                                            boards: activeCupboard.diyBoards || [],
                                            name: hanjieguiTemplateNameTemp
                                        });
                                        localStorage.setItem("hanjiegui-templates", JSON.stringify(store));
                                        message.success("已保存");
                                        closeHanjieguiTemplateSaveMode();
                                    }}

                                >
                                    <Form.Item label="模板名称">
                                        <Input placeholder="请输入模板名称" value={hanjieguiTemplateNameTemp} onChange={(e) => {
                                            setHanjieguiTemplateNameTemp(e.target.value)
                                        }} />
                                    </Form.Item>
                                </Modal>
                                <Button size="small" type="link" icon={(<SaveOutlined />)} onClick={() => {
                                    openHanjieguiTemplateSaveMode();

                                }}>将以上板保存为模板</Button>
                            </div>
                        </div>
                    )
                }

                {
                    activeCupboard.id && activeCupboard.key !== 'hanjiegui' && (

                        <div className={"cg-control " + (isEn ? "en" : "")} style={{ width: isEn ? 320 : 232}}>
                            {/* <div className="title">橱柜数据</div> */}
                            <Form labelCol={{ span: 10 }} labelAlign="right" wrapperCol={{ span: isEn ? 6 : 12 }}>
                                <Menu mode="inline" defaultOpenKeys={["input"]}>
                                    {/* <Menu.SubMenu key="input" title={locale["输入数据"]}> */}
                                        {
                                            !["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (

                                                <Menu.Item key="long" selectable={false}>
                                                    <Form.Item label={locale["长"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.long} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.long = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["left-sanmengui-cebaobei", "right-sanmengui-cebaobei", "lalangui-cebaobei", "diaogui-sanmengui-beibaoce", "diaogui-sanmengui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="a" selectable={false}>
                                                    <Form.Item label={locale["A"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.a} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.a = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>

                                            )
                                        }
                                        
                                        {
                                            ['lalangui-cebaobei'].includes(activeCupboard.key) && (
                                                <Menu.Item key="paikong">
                                                    <Form.Item label={locale["门内排孔"]}>
                                                        <Checkbox value={!activeCupboard.paikong} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.paikong = !target.paikong;
                                                            if (target.paikong) {
                                                                target.paikongX = 30;
                                                                target.paikongY = 90;
                                                                target.paikongWidth = 10;
                                                                target.paikongHeight = 120;
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.paikong}>{locale[`${activeCupboard.paikong ? '有排孔' : '无排孔'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["lalangui-cebaobei"].includes(activeCupboard.key) && (
                                                
                                                <Menu.Item key="paikong">
                                                    <Form.Item label={"侧板排孔"}>
                                                        <Checkbox value={!activeCupboard.无侧板排孔} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.无侧板排孔 = !target.无侧板排孔;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={!activeCupboard.无侧板排孔}>{locale[`${!activeCupboard.无侧板排孔 ? '有排孔' : '无排孔'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["lalangui-cebaobei"].includes(activeCupboard.key) && !activeCupboard.无侧板排孔 && (
                                                <>
                                                
                                                    <Menu.Item key="x" selectable={false}>
                                                        <Form.Item label={locale["X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.x} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.x = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="y1" selectable={false}>
                                                        <Form.Item label={locale["Y1"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.y1} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.y1 = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="y2" selectable={false}>
                                                        <Form.Item label={locale["Y2"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.y2} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.y2 = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="y3" selectable={false}>
                                                        <Form.Item label={locale["Y3"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.y3} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.y3 = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>

                                            )
                                        }
                                        {

                                            !["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="deep" selectable={false}>
                                                    <Form.Item label={locale["深"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.deep} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.deep = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="height" selectable={false}>
                                                    <Form.Item label={locale["高"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.height} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.height = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )

                                        }
                                        {
                                            ["left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei"].includes(activeCupboard.key) && (
                                                <>
                                                    <Menu.Item key="x" selectable={false}>
                                                        <Form.Item label={locale["切角X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.x} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.x = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>

                                                    <Menu.Item key="y" selectable={false}>
                                                        <Form.Item label={locale["切角Y"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.y} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.y = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>

                                                </>

                                            )
                                        }
                                        {
                                            ["left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei", "left-zhuanjiaogui-cebaobei", "right-zhuanjiaogui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="doorWidth" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["门板宽度"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.doorWidth} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.doorWidth = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }

                                        {
                                            ["digui-beibaoce", "digui-cebaobei", "sanheyi-digui-cebaobei", "left-sanmengui-cebaobei", "right-sanmengui-cebaobei", "left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei", "left-zhuanjiaogui-cebaobei", "right-zhuanjiaogui-cebaobei", "shuicaogui-cebaobei", "lalangui-cebaobei"].includes(activeCupboard.key) && (
                                                <>
                                                     <Menu.Item key="柜腿孔" selectable={false}>
                                                        <Form.Item label={"柜腿孔"}>
                                                            <Checkbox checked={!activeCupboard.无柜腿孔} onChange={() => {
                                                                const target = activeCupboard;
                                                                target.无柜腿孔 = !target.无柜腿孔;
                                                                setActiveCupboard({ ...target })
                                                            }}>{!activeCupboard.无柜腿孔 ? "有柜腿孔" : "无柜腿孔"}</Checkbox>
                                                        </Form.Item>
                                                    </Menu.Item>    
                                                    <Menu.Item key="guituiX" selectable={false}>
                                                        <Form.Item label={locale["柜腿边距X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.guituiX} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.guituiX = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="guituiY" selectable={false}>
                                                        <Form.Item label={locale["柜腿边距Y"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.guituiY} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.guituiY = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {
                                            (!activeCupboard.diaoma) && ["diaogui-cebaobei", "diaogui-beibaoce", "diaogui-sanmengui-beibaoce", "diaogui-sanmengui-cebaobei"].includes(activeCupboard.key) && (
                                                <>
                                                <Menu.Item key="diaokongbianju" selectable={false}>
                                                    <Form.Item label={locale["吊孔边距"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.diaokongbianju} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.diaokongbianju = e.target.value;
                                                            // //console.log(target.diaokongbianju);
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                                <Menu.Item key="吊孔孔径" selectable={false}>
                                                    <Form.Item label={"吊孔直徑"}>
                                                        <Input suffix={"mm"} value={activeCupboard.吊孔孔径 || "2.8"} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.吊孔孔径 = e.target.value;
                                                            // //console.log(target.diaokongbianju);
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                                </>
                                            )
                                        }
                                        {
                                            ["diaogui-beibaoce", "diaogui-sanmengui-beibaoce", "diaogui-sanmengui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="diaoma" selectable={false}>
                                                    <Form.Item label={locale["使用吊码"]}>
                                                        <Checkbox checked={activeCupboard.diaoma} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.diaoma = !target.diaoma;
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.diaoma ? "使用" : "不使用"}吊码`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !["yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="lianjiekong" selectable={false}>
                                                    <Form.Item label={locale["连接孔"]}>
                                                        <Checkbox checked={activeCupboard.lianjiekong} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.lianjiekong = !target.lianjiekong;
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.lianjiekong ? "有" : "无"}连接孔`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }

                                        {
                                            activeCupboard.lianjiekong && (
                                                <Menu.Item key="lianjiekongbianju" selectable={false}>
                                                    <Form.Item label={locale["连接孔边距"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.lianjiekongbianju} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.lianjiekongbianju = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !["lalangui-cebaobei", "yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="nojiaoliankong" selectable={false}>
                                                    <Form.Item label={locale["铰链孔"]}>
                                                        <Checkbox checked={!activeCupboard.nojiaoliankong} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.nojiaoliankong = !target.nojiaoliankong;
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.nojiaoliankong ? "无" : "有"}铰链孔`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !["lalangui-cebaobei", "yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && !activeCupboard.nojiaoliankong && (
                                                <Menu.Item key="jiaolianbianju" selectable={false}>
                                                    <Form.Item label={locale["铰链边距"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.jiaolianbianju} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.jiaolianbianju = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !["yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="nolashou" selectable={false}>
                                                    <Form.Item label={locale["拉手"]}>
                                                        <Checkbox checked={!activeCupboard.nolashou} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.nolashou = !target.nolashou;
                                                            if (target.nolashou) {
                                                                target.menminglashou = false;
                                                                target.gxinglashou = false;

                                                            } else {
                                                                target.上免拉手 = false;
                                                                target.中免拉手 = false;
                                                                target.中免拉手1 = false;
                                                                target.中免拉手2 = false;
                                                            }
                                                            if (['diaogui-cebaobei', 'diaogui-beibaoce'].includes(activeCupboard.key)) {
                                                                if (target.nolashou) {
                                                                    target.menxiagaochu = 30;
                                                                } else {
                                                                    target.menxiagaochu = 0;
                                                                }
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.nolashou ? "无" : "有"}拉手`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.nolashou &&
                                            ["digui-cebaobei", "lalangui-cebaobei", "left-sanmengui-cebaobei", "right-sanmengui-cebaobei", "shuicaogui-cebaobei", "left-zhuanjiaogui-cebaobei", "right-zhuanjiaogui-cebaobei", "left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="shangmianlashou">
                                                    <Form.Item label={locale["上免拉手"]}>
                                                        <Checkbox checked={activeCupboard.上免拉手} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.上免拉手 = !target.上免拉手;
                                                            if (target.上免拉手) {
                                                                target.上免拉手X = 27;
                                                                target.上免拉手Y = 51;
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.上免拉手 ? "有" : "无"}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.上免拉手 && (
                                                <>
                                                    <Menu.Item key="shangmianlashouX">
                                                        <Form.Item label={locale["上免拉手X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.上免拉手X} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.上免拉手X = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="shangmianlashouY">
                                                        <Form.Item label={locale["上免拉手Y"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.上免拉手Y} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.上免拉手Y = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {//非拉篮柜
                                            activeCupboard.nolashou &&
                                            ["digui-cebaobei", "left-sanmengui-cebaobei", "right-sanmengui-cebaobei", "shuicaogui-cebaobei", "left-zhuanjiaogui-cebaobei", "right-zhuanjiaogui-cebaobei", "left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zhongmianlashou">
                                                    <Form.Item label={locale["中免拉手"]}>
                                                        <Checkbox checked={activeCupboard.中免拉手} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.中免拉手 = !target.中免拉手;
                                                            if (target.中免拉手) {
                                                                target.中免拉手X = 27;
                                                                target.中免拉手Y = 71;
                                                                target.中免拉手Z = 289.5;
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.中免拉手 ? "有" : "无"}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }

                                        {//拉篮柜 中1  上免拉手  且y3  或者 无拉手
                                            activeCupboard.nolashou && ["lalangui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zhongmianlashou1">
                                                    <Form.Item label={locale["中免拉手"] + "1"}>
                                                        <Checkbox checked={activeCupboard.中免拉手1} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.中免拉手1 = !target.中免拉手1;
                                                            if (target.中免拉手1) {
                                                                target.中免拉手1X = 27;
                                                                target.中免拉手1Y = 71;
                                                                target.中免拉手1Z = 180.5;
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.中免拉手1 ? "有" : "无"}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.中免拉手1 && (
                                                <>
                                                    <Menu.Item key="zhongmianlashou1X">
                                                        <Form.Item label={locale["中免拉手"] + "1X"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手1X} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手1X = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashou1Y">
                                                        <Form.Item label={locale["中免拉手"] + "1Y"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手1Y} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手1Y = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashou1Z">
                                                        <Form.Item label={locale["中免拉手"] + "1Z"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手1Z} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手1Z = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {//拉篮柜 中2 上免拉手且y3  或无拉手
                                            activeCupboard.nolashou && ["lalangui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zhongmianlashou2">
                                                    <Form.Item label={locale["中免拉手"] + "2"}>
                                                        <Checkbox checked={activeCupboard.中免拉手2} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.中免拉手2 = !target.中免拉手2;
                                                            if (target.中免拉手2) {
                                                                target.中免拉手2X = 27;
                                                                target.中免拉手2Y = 71;
                                                                target.中免拉手2Z = 500.5;
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.中免拉手2 ? "有" : "无"}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.中免拉手2 && (
                                                <>
                                                    <Menu.Item key="zhongmianlashou2X">
                                                        <Form.Item label={locale["中免拉手"] + "2X"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手2X} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手2X = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashou2Y">
                                                        <Form.Item label={locale["中免拉手"] + "2Y"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手2Y} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手2Y = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashou2Z">
                                                        <Form.Item label={locale["中免拉手"] + "2Z"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手2Z} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手2Z = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {
                                            activeCupboard.nolashou && ["lalangui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zhongmianlashou3">
                                                    <Form.Item label={locale["中免拉手"] + "3"}>
                                                        <Checkbox checked={activeCupboard.中免拉手3} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.中免拉手3 = !target.中免拉手3;
                                                            if (target.中免拉手3) {
                                                                target.中免拉手3X = 27;
                                                                target.中免拉手3Y = 71;
                                                                target.中免拉手3Z = 320.5;
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.中免拉手3 ? "有" : "无"}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.中免拉手3 && (
                                                <>
                                                    <Menu.Item key="zhongmianlashou3X">
                                                        <Form.Item label={locale["中免拉手"] + "3X"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手3X} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手3X = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashou3Y">
                                                        <Form.Item label={locale["中免拉手"] + "3Y"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手3Y} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手3Y = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashou3Z">
                                                        <Form.Item label={locale["中免拉手"] + "3Z"}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手3Z} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手3Z = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {/* {
                                            ["lalangui-cebaobei"].includes(activeCupboard.key) && !activeCupboard.上免拉手 && (
                                                <Menu.Item key="zhongmianlashou">
                                                        <Form.Item label={locale["中免拉手"]}>
                                                            <Checkbox checked={activeCupboard.中免拉手} onChange={() => {
                                                                const target = activeCupboard;
                                                                target.中免拉手 = !target.中免拉手;
                                                                if(target.中免拉手) {
                                                                    target.中免拉手X =  27;
                                                                    target.中免拉手Y = 71;
                                                                    target.中免拉手Z = 289.5;
                                                                }
                                                                setActiveCupboard({ ...target })
                                                            }}>{locale[`${activeCupboard.中免拉手 ? "有" : "无"}`]}</Checkbox>
                                                        </Form.Item>
                                                    </Menu.Item>
                                            )
                                        } */}
                                        {
                                            activeCupboard.中免拉手 && (
                                                <>
                                                    <Menu.Item key="zhongmianlashouX">
                                                        <Form.Item label={locale["中免拉手X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手X} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手X = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashouY">
                                                        <Form.Item label={locale["中免拉手Y"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手Y} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手Y = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="zhongmianlashouZ">
                                                        <Form.Item label={locale["中免拉手Z"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.中免拉手Z} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.中免拉手Z = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }

                                        {
                                            ['diaogui-cebaobei', 'diaogui-beibaoce'].includes(activeCupboard.key) && activeCupboard.nolashou && (
                                                <Menu.Item key="menxiagaochu">
                                                    <Form.Item label={locale["门下高出"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.menxiagaochu} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menxiagaochu = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !activeCupboard.nolashou && !activeCupboard.nolashou && !["yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && !activeCupboard.gxinglashou && (
                                                <Menu.Item>
                                                    <Form.Item label={locale["门明拉手"]}>
                                                        <Checkbox checked={activeCupboard.menminglashou} onChange={() => {
                                                            const target = activeCupboard;
                                                            target.menminglashou = !target.menminglashou;
                                                            if (target.menminglashou) {
                                                                target.gxinglashou = false;
                                                            }
                                                            if (target.menminglashou) {
                                                                if (!target.menlashoubianju) {
                                                                    target.menlashoubianju = 200;
                                                                }
                                                                if (!target.menlashouX) {
                                                                    target.menlashouX = 100;
                                                                }
                                                                if (!target.menlashouY) {
                                                                    target.menlashouY = 150;
                                                                }
                                                                if (!target.menlashoufangxiang) {
                                                                    target.menlashoufangxiang = "vertical";
                                                                }
                                                            }
                                                            setActiveCupboard({ ...target })
                                                        }}>{locale[`${activeCupboard.menminglashou ? "门明拉手" : "普通拉手"}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !activeCupboard.nolashou && !["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && !activeCupboard.menminglashou && (
                                                <Menu.Item key="gxinglashou" selectable={false} >
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["G型拉手"]}>
                                                        <Checkbox value={activeCupboard.gxinglashou} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.gxinglashou = !target.gxinglashou;
                                                            if (target.gxinglashou) {
                                                                target.menminglashou = false;
                                                            }
                                                            target.gxinglashouType = "up";
                                                            target.gxinglashouGaodu = 32;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.gxinglashou}>{locale[`${activeCupboard.gxinglashou ? 'G型拉手' : '普通拉手'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.menminglashou && [(
                                                <Menu.Item key="menlashoubianju" selectable={false}>
                                                    <Form.Item label={locale["拉手间距"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.menlashoubianju} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menlashoubianju = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            ), (
                                                <Menu.Item key="menlashouX" selectable={false}>
                                                    <Form.Item label={locale["拉手X"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.menlashouX} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menlashouX = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            ), (
                                                <Menu.Item key="menlashouY" selectable={false}>
                                                    <Form.Item label={locale["拉手Y"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.menlashouY} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menlashouY = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            ), (
                                                <Menu.Item key="menlashoufangxiang" selectable={false}>
                                                    <Form.Item label={locale["拉手方向"]}>
                                                        <Radio.Group value={activeCupboard.menlashoufangxiang} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menlashoufangxiang = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }}>
                                                            <Radio value={"horizontal"}>{locale['横向']}</Radio>
                                                            <Radio value={"vertical"}>{locale['纵向']}</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )]
                                        }
                                        {
                                            activeCupboard.gxinglashou && (
                                                <>
                                                    <Menu.Item key="gxinglashoutype" selectable={false}>
                                                        <Form.Item label={locale["G型拉手类型"]}>
                                                            <Select style={{ width: 90 }} value={activeCupboard.gxinglashouType} onChange={(value) => {
                                                                const target = activeCupboard;
                                                                target.gxinglashouType = value;
                                                                setActiveCupboard({ ...target })
                                                            }}>
                                                                <Select.Option value={"up"}>{locale['上边G型拉手']}</Select.Option>
                                                                <Select.Option value={"left-or-right"}>{locale['侧边G型拉手']}</Select.Option>
                                                            </Select>
                                                        </Form.Item>

                                                    </Menu.Item>
                                                    <Menu.Item key="gxinglashougaodu" selectable={false}>
                                                        <Form.Item label={locale["G型拉手高度"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.gxinglashouGaodu} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.gxinglashouGaodu = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {
                                            ["digui-beibaoce",
                                                "digui-cebaobei",
                                                "left-zhuanjiaogui-cebaobei",
                                                "right-zhuanjiaogui-cebaobei",
                                                "left-qiezhuanjiaogui-cebaobei",
                                                "right-qiezhuanjiaogui-cebaobei",
                                                "sanheyi-digui-cebaobei", "diaogui-cebaobei", "diaogui-beibaoce", "shuicaogui-cebaobei", "lalangui-cebaobei", "yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="doorNumber" selectable={false}>
                                                    <Form.Item label={locale["柜门数量"]}>
                                                        <Radio.Group value={activeCupboard.doorNumber} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.doorNumber = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }}>
                                                            {
                                                                !["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                                    <Radio value={1}>{locale['单门']}</Radio>

                                                                )
                                                            }
                                                            <Radio value={2}>{locale['双门']}</Radio>
                                                            {
                                                                ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                                    <Radio value={3}>{locale['三门']}</Radio>
                                                                )
                                                            }
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Menu.Item>

                                            )
                                        }
                                        {/* {
                                            ["digui-beibaoce", "digui-cebaobei", "diaogui-cebaobei", "diaogui-beibaoce", "lalangui-cebaobei", "shuicaogui-cebaobei"].includes(activeCupboard.key) && activeCupboard.doorNumber === 1 && (
                                                <Menu.Item key="doorSide" selectable={false}>
                                                    <Form.Item label={locale["开门方向">
                                                        <Radio.Group value={activeCupboard.doorSide} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.doorSide = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }}>
                                                                <Radio value="left">左开</Radio>
                                                                <Radio value="right">右开</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Menu.Item>
                                                )
                                        }
                                        {
                                            ["left-sanmengui-cebaobei", "right-sanmengui-cebaobei"] && (
                                                
                                            )
                                        } */}
                                        {
                                            !["diaogui-sanmengui-cebaobei", "diaogui-sanmengui-beibaoce", "shuicaogui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="doorSide" selectable={false}>
                                                    <Form.Item label={locale["开门方向"]}>
                                                        <Radio.Group value={activeCupboard.doorSide} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.doorSide = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }}>
                                                            <Radio value="left">{locale['左开']}</Radio>
                                                            <Radio value="right">{locale['右开']}</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            !["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && !(activeCupboard.key?.indexOf("sanheyi") > -1) && (
                                                <Menu.Item key="waibanjiao">
                                                    <Form.Item label={locale["外板角类型"]}>
                                                        <Select style={{ width: 90 }} value={activeCupboard.waibanjiaoType} onChange={(value) => {
                                                            const target = activeCupboard;
                                                            target.waibanjiaoType = value;
                                                            setActiveCupboard({ ...target })
                                                        }}>
                                                            <Select.Option value={"youhujiao"}>{locale['有护角']}</Select.Option>
                                                            <Select.Option value={"wuhujiao"}>{locale['无护角']}</Select.Option>
                                                            <Select.Option value={"wujiao"}>{locale['无角']}</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {/* <Menu.Item key="nowaibanjiao" selectable={false}>
                                            <Form.Item labelCol={{ span: 16 }} label={locale["外板角">
                                                <Checkbox value={!activeCupboard.menwaibanjiao} onChange={(e) => {
                                                    const target = activeCupboard;
                                                    target.nowaibanjiao = !target.nowaibanjiao;
                                                    setActiveCupboard({ ...target })
                                                }} checked={!activeCupboard.nowaibanjiao}>{!activeCupboard.nowaibanjiao ? '有角' : '无角'}</Checkbox>
                                            </Form.Item>
                                        </Menu.Item> */}
                                        {
                                            !["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="menbanjiao" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["门外板角"]}>
                                                        <Checkbox value={activeCupboard.menwaibanjiao} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menwaibanjiao = !target.menwaibanjiao;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.menwaibanjiao}>{locale[`${activeCupboard.menwaibanjiao ? '有角' : '无角'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>

                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="nomentouwaibanjiao" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["门头外板角"]}>
                                                        <Checkbox value={activeCupboard.nomentouwaibanjiao} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.nomentouwaibanjiao = !target.nomentouwaibanjiao;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.nomentouwaibanjiao}>{locale[`${activeCupboard.nomentouwaibanjiao ? '有角' : '无角'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="noqiandangwaibanjiao" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["前挡外板角"]}>
                                                        <Checkbox value={activeCupboard.noqiandangwaibanjiao} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.noqiandangwaibanjiao = !target.noqiandangwaibanjiao;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.noqiandangwaibanjiao}>{locale[`${activeCupboard.noqiandangwaibanjiao ? '有角' : '无角'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["digui-cebaobei", "digui-beibaoce", "shuicaogui-cebaobei", "left-zhuanjiaogui-cebaobei", "right-zhuanjiaogui-cebaobei", "left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei", "sanheyi-digui-cebaobei", "yangtaigui-cebaobei", "lalangui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="mengao" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["门高"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.mengao} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.mengao = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ['yangtaigui-cebaobei'].includes(activeCupboard.key) && (
                                                <Menu.Item key="menkuan" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["门宽"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.menkuan} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.menkuan = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["digui-cebaobei", "sanheyi-digui-cebaobei", "digui-beibaoce", "diaogui-cebaobei", "diaogui-beibaoce", "shuicaogui-cebaobei", "lalangui-cebaobei", "diaogui-sanmengui-beibaoce", "diaogui-sanmengui-cebaobei", "left-sanmengui-cebaobei", "right-sanmengui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="geceng" selectable={false}>
                                                    <Form.Item labelCol={{ span: 16 }} label={locale["是否有隔层"]}>
                                                        <Checkbox value={!activeCupboard.geceng} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.geceng = !target.geceng;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.geceng}>{locale[`${activeCupboard.geceng ? '有隔层' : '无隔层'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.geceng && (
                                                <>
                                                    <Menu.Item key="gecengX" selectable={false}>
                                                        <Form.Item label={locale["隔层X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.gecengX} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.gecengX = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="gecengY" selectable={false}>
                                                        <Form.Item label={locale["隔层Y"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.gecengY} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.gecengY = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>

                                                    <Menu.Item key="gecengkongjing" selectable={false}>
                                                        <Form.Item label={locale["隔层孔直径"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.gecengkongjing} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.gecengkongjing = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }


                                        {
                                            !["shuicaogui-cebaobei", "yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="nobeinei" selectable={false}>
                                                    <Form.Item label={locale["有无背内板"]}>
                                                        <Checkbox value={!activeCupboard.nobeinei} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.nobeinei = !target.nobeinei;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={!activeCupboard.nobeinei}>{locale[`${!activeCupboard.nobeinei ? '有背内板' : '无背内板'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>

                                            )
                                        }
                                        {
                                            !["yangtaigui-cebaobei", "ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="nocenei" selectable={false}>
                                                    <Form.Item label={"有无侧内板"}>
                                                        <Checkbox value={!activeCupboard.nocenei} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.nocenei = !target.nocenei;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={!activeCupboard.nocenei}>{!activeCupboard.nocenei ? '有侧内板' : '无侧内板'}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>

                                            )
                                        }
                                        {
                                            ["left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="nobeiban" selectable={false}>
                                                    <Form.Item label={locale["有无背板"]}>
                                                        <Checkbox value={!activeCupboard.nobeiban} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.nobeiban = !target.nobeiban;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={!activeCupboard.nobeiban}>{locale[`${!activeCupboard.nobeiban ? '有背板' : '无背板'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>

                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="dibanshen" selectable={false}>
                                                    <Form.Item label={locale["底板深"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.dibanshen} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.dibanshen = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zuocekuan" selectable={false}>
                                                    <Form.Item label={locale["左侧深"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.zuocekuan} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.zuocekuan = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zuocechang" selectable={false}>
                                                    <Form.Item label={locale["左侧高"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.zuocechang} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.zuocechang = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zhongcegao" selectable={false}>
                                                    <Form.Item label={locale["中侧深"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.zhongcekuan} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.zhongcekuan = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }

                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="zhongcechang" selectable={false}>
                                                    <Form.Item label={locale["中侧高"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.zhongcechang} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.zhongcechang = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="youcekuan" selectable={false}>
                                                    <Form.Item label={locale["右侧深"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.youcekuan} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.youcekuan = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="youcechang" selectable={false}>
                                                    <Form.Item label={locale["右侧高"]}>
                                                        <Input suffix={"mm"} value={activeCupboard.youcechang} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.youcechang = e.target.value;
                                                            setActiveCupboard({ ...target })
                                                        }} />
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            ["digui-cebaobei", "digui-beibaoce", "diaogui-cebaobei", "left-sanmengui-cebaobei", "right-sanmengui-cebaobei", /* "left-qiezhuanjiaogui-cebaobei", "right-qiezhuanjiaogui-cebaobei", "left-zhuanjiaogui-cebaobei", "right-zhuanjiaogui-cebaobei",  */"lalangui-cebaobei", "shuicaogui-cebaobei"].includes(activeCupboard.key) && (
                                                <Menu.Item key="qiandanghengfang" selectable={false}>
                                                    <Form.Item label={locale["前挡横放"]}>
                                                        <Checkbox value={!activeCupboard.qiandanghengfang} onChange={(e) => {
                                                            const target = activeCupboard;
                                                            target.qiandanghengfang = !target.qiandanghengfang;
                                                            setActiveCupboard({ ...target })
                                                        }} checked={activeCupboard.qiandanghengfang}>{locale[`${activeCupboard.qiandanghengfang ? '横放' : '竖放'}`]}</Checkbox>
                                                    </Form.Item>
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            activeCupboard.paikong && (
                                                <>
                                                    <Menu.Item key="paikongX" selectable={false}>
                                                        <Form.Item label={locale["排孔X"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.paikongX} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.paikongX = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="paikongY" selectable={false}>
                                                        <Form.Item label={locale["排孔Y"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.paikongY} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.paikongY = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="paikongWidth" selectable={false}>
                                                        <Form.Item label={locale["排孔宽"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.paikongWidth} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.paikongWidth = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                    <Menu.Item key="paikongHeight" selectable={false}>
                                                        <Form.Item label={locale["排孔高"]}>
                                                            <Input suffix={"mm"} value={activeCupboard.paikongHeight} onChange={(e) => {
                                                                const target = activeCupboard;
                                                                target.paikongHeight = e.target.value;
                                                                setActiveCupboard({ ...target })
                                                            }} />
                                                        </Form.Item>
                                                    </Menu.Item>
                                                </>
                                            )
                                        }
                                        {
                                            ["ii-yangtaigui-cebaobei"].includes(activeCupboard.key) && (
                                                <div key="ii-yangtaigui-boards">
                                                    {
                                                        activeCupboard.diyBoards?.length > 0 && activeCupboard.diyBoards.map((board, index) => {
                                                            if (board.boardName.indexOf("ce") > -1) {

                                                                return (
                                                                    <div style={{ marginBottom: 10 }}>
                                                                        <Menu.Item key={"cebanwidth-" + index} selectable={false}>
                                                                            <Form.Item label={locale["侧板宽"]}>
                                                                                <Input suffix={"mm"} value={board.boardWidth} onChange={(e) => {
                                                                                    const target = activeCupboard;
                                                                                    board.boardWidth = e.target.value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"cebanheight-" + index} selectable={false}>
                                                                            <Form.Item label={locale["侧板高"]}>
                                                                                <Input suffix={"mm"} value={board.boardHeight} onChange={(e) => {
                                                                                    const target = activeCupboard;
                                                                                    board.boardHeight = e.target.value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"youkong-" + index} selectable={false}>
                                                                            <Form.Item label={locale["有无大孔"]} style={{ position: 'relative', left: 10 }}>
                                                                                <Checkbox suffix={"mm"} value={board.hasCircle} checked={board.hasCircle} onChange={() => {
                                                                                    const target = activeCupboard;
                                                                                    board.hasCircle = !board.hasCircle;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} >
                                                                                    {locale[`${board.hasCircle ? "有大孔" : "无大孔"}`]}
                                                                                </Checkbox>
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"guijiao-" + index} selectable={false}>
                                                                            <Form.Item label={locale["有无柜脚"]} style={{ position: 'relative', left: 10 }}>
                                                                                <Checkbox suffix={"mm"} value={board.hasGuijiao} checked={board.hasGuijiao} onChange={() => {
                                                                                    const target = activeCupboard;
                                                                                    board.hasGuijiao = !board.hasGuijiao;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} >
                                                                                    {locale[`${board.hasGuijiao ? "有柜脚" : "无柜脚"}`]}
                                                                                </Checkbox>
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key="waibanjiao">
                                                                            <Form.Item label={locale["外板角类型"]}>
                                                                                <Select style={{ width: 90 }} value={board.waibanjiaoType} onChange={(value) => {
                                                                                    const target = activeCupboard;
                                                                                    board.waibanjiaoType = value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target });
                                                                                }}>
                                                                                    <Select.Option value={"youhujiao"}>{locale['有护角']}</Select.Option>
                                                                                    <Select.Option value={"wuhujiao"}>{locale['无护角']}</Select.Option>
                                                                                    <Select.Option value={"wujiao"}>{locale['无角']}</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Menu.Item>


                                                                        <Menu.Item key={"quantity-" + index} selectable={false}>
                                                                            <Form.Item label={locale["数量"]}>
                                                                                <InputNumber max={1000} suffix={"mm"} value={board.quantity || 1} onChange={(value) => {
                                                                                    if (value > 999) {
                                                                                        return;
                                                                                    }
                                                                                    const target = activeCupboard;
                                                                                    board.quantity = value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"delete-" + index} selectable={false}>
                                                                            <Button type="link" danger onClick={() => {
                                                                                activeCupboard.diyBoards = activeCupboard.diyBoards.filter((item, i) => {
                                                                                    return i !== index;
                                                                                });
                                                                                setActiveCupboard({ ...activeCupboard })
                                                                            }}>{locale['删除']}</Button>
                                                                        </Menu.Item>
                                                                    </div>
                                                                )
                                                            } else if (board.boardName.indexOf("men") > -1) {
                                                                return (

                                                                    <div style={{ marginBottom: 10 }}>
                                                                        <Menu.Item key={"menbanwidth-" + index} selectable={false}>
                                                                            <Form.Item label={locale["门板宽"]}>
                                                                                <Input suffix={"mm"} value={board.boardWidth} onChange={(e) => {
                                                                                    const target = activeCupboard;
                                                                                    board.boardWidth = e.target.value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"menbanheight-" + index} selectable={false}>
                                                                            <Form.Item label={locale["门板高"]}>
                                                                                <Input suffix={"mm"} value={board.boardHeight} onChange={(e) => {
                                                                                    const target = activeCupboard;
                                                                                    board.boardHeight = e.target.value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>

                                                                        <Menu.Item key={"jiaolianbianju-" + index} selectable={false}>
                                                                            <Form.Item label={locale["铰链边距"]}>
                                                                                <Input suffix={"mm"} value={board.jiaolianbianju} onChange={(e) => {
                                                                                    const target = activeCupboard;
                                                                                    board.jiaolianbianju = e.target.value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>

                                                                        <Menu.Item key={"heye-" + index} selectable={false}>
                                                                            <Form.Item label={locale["合页"]}>
                                                                                <Checkbox checked={board.heye} onChange={() => {
                                                                                    const target = activeCupboard;
                                                                                    board.heye = !board.heye;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }}>{locale[`${board.heye ? "有合页" : "无合页"}`]}</Checkbox>
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"waibanjiao-" + index}>
                                                                            <Form.Item label={locale["外板角类型"]}>
                                                                                <Select style={{ width: 90 }} value={board.waibanjiaoType} onChange={(value) => {
                                                                                    const target = activeCupboard;
                                                                                    board.waibanjiaoType = value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }}>
                                                                                    <Select.Option value={"youhujiao"}>{locale['有护角']}</Select.Option>
                                                                                    <Select.Option value={"wuhujiao"}>{locale['无护角']}</Select.Option>
                                                                                    <Select.Option value={"wujiao"}>{locale['无角']}</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"menbashou-" + index}>
                                                                            <Form.Item label={locale["门拉手"]}>
                                                                                <Checkbox checked={board.menlashou} onChange={() => {
                                                                                    const target = activeCupboard;
                                                                                    board.menlashou = !board.menlashou;
                                                                                    if (!board.menlashou) {
                                                                                        board.menminglashou = false;
                                                                                        board.gxinglashou = false;
                                                                                    }
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }}>{locale[`${board.menlashou ? "有拉手" : "无拉手"}`]}</Checkbox>
                                                                            </Form.Item>
                                                                        </Menu.Item>

                                                                        {
                                                                            board.menlashou && !board.gxinglashou && (
                                                                                <Menu.Item key={"menminglashou-" + index}>
                                                                                    <Form.Item label={locale["门明拉手"]}>
                                                                                        <Checkbox checked={board.menminglashou} onChange={() => {
                                                                                            const target = activeCupboard;
                                                                                            board.menminglashou = !board.menminglashou;
                                                                                            if (board.menminglashou) {
                                                                                                board.gxinglashou = false;
                                                                                            }
                                                                                            if (board.menminglashou) {
                                                                                                if (!board.menlashoubianju) {
                                                                                                    board.menlashoubianju = 200;
                                                                                                }
                                                                                                if (!board.menlashouX) {
                                                                                                    board.menlashouX = 100;
                                                                                                }
                                                                                                if (!board.menlashouY) {
                                                                                                    board.menlashouY = 150;
                                                                                                }
                                                                                                if (!board.menlashoufangxiang) {
                                                                                                    board.menlashoufangxiang = "vertical";
                                                                                                }
                                                                                            }
                                                                                            target.diyBoards = [...target.diyBoards];
                                                                                            setActiveCupboard({ ...target })
                                                                                        }}>{locale[`${board.menminglashou ? "门明拉手" : "普通拉手"}`]}</Checkbox>
                                                                                    </Form.Item>
                                                                                </Menu.Item>
                                                                            )
                                                                        }
                                                                        {
                                                                            board.menlashou && !board.menminglashou && (
                                                                                <Menu.Item key={"gxinglashou" + index} selectable={false} >
                                                                                    <Form.Item labelCol={{ span: 16 }} label={locale["G型拉手"]}>
                                                                                        <Checkbox value={board.gxinglashou} onChange={(e) => {
                                                                                            const target = activeCupboard;
                                                                                            board.gxinglashou = !board.gxinglashou;
                                                                                            if (board.gxinglashou) {
                                                                                                board.menminglashou = false;
                                                                                            }
                                                                                            board.gxinglashouType = "up";
                                                                                            board.gxinglashouGaodu = 32;
                                                                                            board.doorSide = "left";
                                                                                            target.diyBoards = [...target.diyBoards];
                                                                                            setActiveCupboard({ ...target })
                                                                                        }} checked={board.gxinglashou}>{locale[`${board.gxinglashou ? 'G型拉手' : '普通拉手'}`]}</Checkbox>
                                                                                    </Form.Item>
                                                                                </Menu.Item>
                                                                            )
                                                                        }
                                                                        {
                                                                            board.menlashou && board.menminglashou && (
                                                                                <>
                                                                                    <Menu.Item key={"menlashoubianju-" + index} selectable={false}>
                                                                                        <Form.Item label={locale["拉手间距"]}>
                                                                                            <Input suffix={"mm"} value={board.menlashoubianju} onChange={(e) => {
                                                                                                const target = activeCupboard;
                                                                                                board.menlashoubianju = e.target.value;
                                                                                                target.diyBoards = [...target.diyBoards];
                                                                                                setActiveCupboard({ ...target })
                                                                                            }} />
                                                                                        </Form.Item>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item key={"menlashouX-" + index} selectable={false}>
                                                                                        <Form.Item label={locale["拉手X"]}>
                                                                                            <Input suffix={"mm"} value={board.menlashouX} onChange={(e) => {
                                                                                                const target = activeCupboard;
                                                                                                board.menlashouX = e.target.value;
                                                                                                target.diyBoards = [...target.diyBoards];
                                                                                                setActiveCupboard({ ...target })
                                                                                            }} />
                                                                                        </Form.Item>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item key={"menlashouY-" + index} selectable={false}>
                                                                                        <Form.Item label={locale["拉手Y"]}>
                                                                                            <Input suffix={"mm"} value={board.menlashouY} onChange={(e) => {
                                                                                                const target = activeCupboard;
                                                                                                board.menlashouY = e.target.value;
                                                                                                target.diyBoards = [...target.diyBoards];
                                                                                                setActiveCupboard({ ...target })
                                                                                            }} />
                                                                                        </Form.Item>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item key={"menlashoufangxiang" + index} selectable={false}>
                                                                                        <Form.Item label={locale["拉手方向"]}>
                                                                                            <Radio.Group value={board.menlashoufangxiang} onChange={(e) => {
                                                                                                const target = activeCupboard;
                                                                                                board.menlashoufangxiang = e.target.value;
                                                                                                target.diyBoards = [...target.diyBoards];
                                                                                                setActiveCupboard({ ...target })
                                                                                            }}>
                                                                                                <Radio value={"horizontal"}>{locale['横向']}</Radio>
                                                                                                <Radio value={"vertical"}>{locale['纵向']}</Radio>
                                                                                            </Radio.Group>
                                                                                        </Form.Item>
                                                                                    </Menu.Item>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            board.menlashou && board.gxinglashou && (
                                                                                <>
                                                                                    <Menu.Item key={"gxinglashoutype-" + index} selectable={false}>
                                                                                        <Form.Item label={locale["G型拉手类型"]}>
                                                                                            <Select style={{ width: 90 }} value={board.gxinglashouType} onChange={(value) => {
                                                                                                const target = activeCupboard;
                                                                                                board.gxinglashouType = value;
                                                                                                target.diyBoards = [...target.diyBoards];
                                                                                                setActiveCupboard({ ...target })
                                                                                            }}>
                                                                                                <Select.Option value={"up"}>{locale['上边G型拉手']}</Select.Option>
                                                                                                <Select.Option value={"left-or-right"}>{locale['侧边G型拉手']}</Select.Option>
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item key={"menlashouX-" + index} selectable={false}>
                                                                                        <Form.Item label={locale["G型拉手高度"]}>
                                                                                            <Input suffix={"mm"} value={board.gxinglashouGaodu} onChange={(e) => {
                                                                                                const target = activeCupboard;
                                                                                                board.gxinglashouGaodu = e.target.value;
                                                                                                target.diyBoards = [...target.diyBoards];
                                                                                                setActiveCupboard({ ...target })
                                                                                            }} />
                                                                                        </Form.Item>
                                                                                    </Menu.Item>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <Menu.Item key={"kaimenfangxiang" + index} selectable={false}>
                                                                            <Form.Item label={locale["开门方向"]}>
                                                                                <Radio.Group value={board.doorSide || "left"} onChange={(e) => {
                                                                                    const target = activeCupboard;
                                                                                    board.doorSide = e.target.value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }}>
                                                                                    <Radio value={"left"}>{locale['左开']}</Radio>
                                                                                    <Radio value={"right"}>{locale['右开']}</Radio>
                                                                                </Radio.Group>
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"quantity-" + index} selectable={false}>
                                                                            <Form.Item label={locale["数量"]}>
                                                                                <InputNumber max={1000} suffix={"mm"} value={board.quantity || 1} onChange={(value) => {
                                                                                    if (value > 999) {
                                                                                        return;
                                                                                    }
                                                                                    const target = activeCupboard;
                                                                                    board.quantity = value;
                                                                                    target.diyBoards = [...target.diyBoards];
                                                                                    setActiveCupboard({ ...target })
                                                                                }} />
                                                                            </Form.Item>
                                                                        </Menu.Item>
                                                                        <Menu.Item key={"delete-" + index} selectable={false}>
                                                                            <Button type="link" danger onClick={() => {
                                                                                activeCupboard.diyBoards = activeCupboard.diyBoards.filter((item, i) => {
                                                                                    return i !== index;
                                                                                });
                                                                                setActiveCupboard({ ...activeCupboard })
                                                                            }}>{locale['删除']}</Button>
                                                                        </Menu.Item>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <Menu.Item key="add" style={{ justifyContent: 'space-between' }}>
                                                        <Button type="link"
                                                            onClick={() => {
                                                                if (!(activeCupboard.diyBoards instanceof Array)) {
                                                                    activeCupboard.diyBoards = [];
                                                                }
                                                                activeCupboard.diyBoards.push({
                                                                    boardName: "ce",
                                                                    boardWidth: 570,
                                                                    boardHeight: 880,
                                                                    menlashou: true,
                                                                    hasCircle: true,
                                                                    hasGuijiao: true,
                                                                    waibanjiaoType: "youhujiao",
                                                                });
                                                                activeCupboard.diyBoards = [...activeCupboard.diyBoards];
                                                                setActiveCupboard({ ...activeCupboard })

                                                            }}
                                                        >
                                                            <PlusOutlined />
                                                            {locale['添加侧板']}
                                                        </Button>
                                                        <Button type="link"
                                                            onClick={() => {
                                                                activeCupboard.diyBoards.push({
                                                                    boardName: "men",
                                                                    boardWidth: 570,
                                                                    boardHeight: 880,
                                                                    waibanjiaoType: "youhujiao",
                                                                    jiaolianbianju: 100,
                                                                    menlashou: true,
                                                                    menminglashou: true,
                                                                    menlashoubianju: 200,
                                                                    menlashouX: 100,
                                                                    menlashouY: 150,
                                                                    menlashoufangxiang: "vertical",
                                                                    heye: true,
                                                                })
                                                                activeCupboard.diyBoards = [...activeCupboard.diyBoards];
                                                                setActiveCupboard({ ...activeCupboard })
                                                            }}
                                                        >
                                                            <PlusOutlined />
                                                            {locale['添加门板']}
                                                        </Button>
                                                    </Menu.Item>
                                                </div>
                                            )
                                        }


                                    {/* </Menu.SubMenu> */}
                                    {/* <Menu.SubMenu key="data" title="板材数据">
                                        TODO 板材数据
                                    </Menu.SubMenu> */}
                                </Menu>
                            </Form>
                        </div>
                    )
                }
                <div className="cg-canvas">
                    {
                        activeOrder.materials?.length > 0 && activeOrder.materials.map((item, index) => {
                            return (
                                <DxfCanvasPreview handleNest={() => { openNestModal(item.id) }} className="dxf-canvas" ref={canvasRefs[index]} title={item.name} />
                            )
                        })
                    }

                </div>
            </div>
            <div className="footer  no-drag" style={{ display: 'flex', fontSize: 14 }}>
                <div className="flex">
                    {/* <Dropdown trigger={["click", "contextMenu"]} overlay={(
                        <Menu>
                            <Menu.Item danger onClick={() => {
                                Modal.confirm({
                                    centered: true,
                                    title: locale["删除当前订单"],
                                    content: locale["删除后不可恢复，请确认是否删除当前订单"],
                                    okText: locale["确认删除"],
                                    okButtonProps: {
                                        danger: true,
                                    },
                                    onOk: () => {
                                        const newOrders = orders.filter((order) => order.id !== activeOrder.id);
                                        if (newOrders.length > 0) {
                                            setActiveOrder(newOrders[0]);
                                        } else {
                                            setActiveOrder({});
                                        }
                                        message.info(locale["删除成功，已为您切换到最新订单"]);
                                        setOrders(newOrders);
                                        if (newOrders.size === 0) {
                                            localStorage.setItem("orders", "[]")
                                        }
                                    },
                                    cancelText: locale["取消"],
                                    onCancel: () => {

                                    }
                                })
                            }}>
                                <DeleteOutlined  />
                                {locale['删除订单']}
                            </Menu.Item>
                            {
                                !isEn && (

                                    <Menu.Item onClick={() => {
                                        const target = { ...activeOrder };
                                        openTagPrintModal(target);
                                        orderTemp = target;
                                    }}>
                                        <ExportOutlined />
                                        {locale['导出资料']}
                                    </Menu.Item>
                                )
                            }
                        </Menu>
                    )}> */}
                    {
                        syncing ? (
                            <div className="title" style={{color: "#fff", fontSize: 14, background: "green"}}><SyncOutlined spin /> 同步中..</div>
                        ) : (
                            <div className="title" style={{color: "#fff", fontSize: 14}}>{locale['当前订单']}</div>
                        )
                    }
                    {/* </Dropdown> */}
                    <div className="order-info flex a-center ">
                        <div className="info-item">
                            {locale['订单号']}：{activeOrder.orderNo}
                        </div>
                        <div className="info-item">
                            {locale['结构']}：{activeOrder.struct === "beibaoce" ? locale["背包侧"] : locale['侧包背'] + `(${locale['默认']})`}
                        </div>
                        {/* <div className="info-item">
                            板材：{
                                (() => {
                                    if (activeOrder.materialType === 2) {
                                        return "柜板：" + activeOrder.waibanMaterial + ", " + "门板：" + activeOrder.menbanMaterial
                                    } else {

                                        return "外板：" + activeOrder.waibanMaterial + ", " + "内板：" + activeOrder.neibanMaterial + ", " + "门板：" + activeOrder.menbanMaterial
                                    }
                                })()
                            }
                        </div> */}
                        <div className="info-item">
                            {locale['创建日期']}：{activeOrder.createDate}
                        </div>
                        <div className="info-item">
                            {locale['收货地址']}：{activeOrder.address}
                        </div>
                    </div>
                </div>
                <div className="board-info flex a-center" style={{alignItems: 'center'}}>
                    {/* <div className="action" onClick={handleNest}>
                        全部排版
                    </div> */}
                    <Button onClick={openSettingsDrawer} type='link' icon={(<SettingOutlined />)} style={{marginTop: 0, marginBottom: 0}}>
                        设置    
                    </Button>
                    <Button onClick={onGoNest} type='primary' size='small' icon={(<ExportOutlined />)} style={{marginTop: 0, marginBottom: 0}}>
                        排板优化    
                    </Button>
                    <Drawer visible={settingDrawerVisible} style={{marginTop: 36}} onClose={closeSettingDrawer} title="设置">
                        <div style={{padding: "10px 0", width: '100%', display: 'flex', color: "#fff", justifyCotent: 'space-between', alignItems: 'center'}}>
                            自动增加中间连接件：
                            <Switch checked={settings.自动增加中间连接件} onChange={() => {
                                settings.自动增加中间连接件 = !Boolean(settings.自动增加中间连接件);
                                setting.syncSettings(settings);
                                setSettings({...settings});
                                dxfCanvasPreview();
                            }}/>
                        </div>
                    </Drawer>
                </div>
            </div>
        </div>
    </Spin>
    );
}

export default App;
