import { DirectX, DirectY } from "../../../../constants/enum";
import { sanheyi02DiwaiBoard } from "./sanheyi-02-diwai";

export const sanheyi02DingwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return sanheyi02DiwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
}