import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { corner5WaiWujiao } from "./corner5-wai-wujiao";

export const corner6WaiWujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {

    const size = {width: 0, height: 0};
    let paths = corner5WaiWujiao(dx, dy, startX, startY, true, size);
    paths = paths.slice(10, paths.length);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, size.width);
    if(typeof sizeRes === 'object') {
        sizeRes.width =  size.width;
        sizeRes.height = 44.068293;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);

}