import { Arc, Circle, Group, Layer, Line, Rect, Text } from "react-konva"
import { CANVAS_SIZE, defaultDoorHeight, defaultWindowGroundClearance, defaultWindowHeight, m2px, onwallDefaultSize, px2m } from "../../../store"
import { useEffect, useMemo, useState } from "react";
import { computeClosestEdge } from "../../../gpt/computeClosestEdge";
import { Vector2d } from "konva/lib/types";
import { KonvaEventObject } from "konva/lib/Node";
import { message } from "antd";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { ShapeOnWall } from "../$shapes/shape-onwall";
import { generateUUID } from "three/src/math/MathUtils";
import { useOnWallDrawing } from "./store";

export const LayerDrawingOnWall = () => {

    const { drawOnWallType, drawedArea, brushPos, wallThickness, floorThickness, pushDrawed, cancelDraw } = useOnWallDrawing();

    const [openDirect, setOpenDirect] = useState<"top" | "left" | "right" | "bottom">("left");
    const [openClockwise, setOpenClockwise] = useState(true);

    const [edgeHelper, setEdgeHelper] = useState<{from: Vector2d, to: Vector2d}>(null);
    
    const onWallWidth = drawOnWallType === 'onwall-single-door' ? onwallDefaultSize : onwallDefaultSize * 2;

    const onWallThickness = drawOnWallType?.indexOf("window") > -1 ? wallThickness - 0.1 * m2px : wallThickness;
    
    useEffect(() => {
        if (!drawOnWallType || !drawedArea) {
            return;
        }
        
        const edge = computeClosestEdge(brushPos, drawedArea.anchors, -1);
        
        let openDirect: "top" | "left" | "right" | "bottom";
        if(edge.from.x - edge.to.x === 0) {
            //说明是edge是y方向，则openDirect = left | right
            if(brushPos.x > edge.from.x) {
                openDirect = 'right';
            }else {
                openDirect = 'left';
            }

        }else{
            //否则openDirect = top | bottom
            if(brushPos.y > edge.from.y) {
                openDirect = 'bottom';
            }else {
                openDirect = 'top';
            }
        }
        setOpenDirect(openDirect);
        const threshold = 20;
        const isWithinOuterBound = (
            brushPos.x - drawedArea.outerBound.left   > -threshold &&
            brushPos.x - drawedArea.outerBound.right  < threshold  &&
            brushPos.y - drawedArea.outerBound.top    > -threshold &&
            brushPos.y - drawedArea.outerBound.bottom < threshold
        );
        if (isWithinOuterBound) {
            // setOk(true);
            const edge = computeClosestEdge(brushPos, drawedArea.anchors, onWallThickness);
            setEdgeHelper(edge || null);
         }else {
            // setOk(false);
            setEdgeHelper(null);
         }
    }, [drawOnWallType, drawedArea, brushPos]);


    const onClick = (e: KonvaEventObject<MouseEvent>) => {

        if (e.evt.button === 0) {
            if (!edgeHelper) {
                message.info("门窗不能放在这里");
                return;
            }else {
                pushDrawed({
                    type: drawOnWallType,
                    data: {
                        id: generateUUID(),
                        onWallType: drawOnWallType,
                        openDirect: openDirect,
                        openClockwise: openClockwise,
                        anchor: (openDirect === 'left' || openDirect === 'right') ? brushPos.y : brushPos.x,
                        wall: edgeHelper,
                        onWallThickness,
                        wallThickness,
                        onWallWidth: onWallWidth,
                        onWallHeight: drawOnWallType.indexOf("door") > -1 ? defaultDoorHeight : defaultWindowHeight,
                        groundClearance: drawOnWallType.indexOf("door") > -1 ? 0 : defaultWindowGroundClearance,
                        
                        floorThickness: floorThickness
                    }
                
                });
                cancelDraw();
            }
        }else if(e.evt.button === 1) {
            if(edgeHelper) {
                setOpenClockwise(!openClockwise);
            }
        }

    }


    return (
        <Layer
            visible={!!drawOnWallType}
        >
            {
                //区域外
                useMemo(() => {
                    if(edgeHelper) {
                        return ;
                    }

                    return (openDirect === 'left' || openDirect === 'right') ? (
                        <Group
                            x={brushPos.x}
                            y={brushPos.y}
                        >
                            <Rect
                                x={-onWallThickness / 2}
                                y={-onWallWidth / 2}
                                stroke={"pink"}
                                fill={"#ff000011"}
                                height={onWallWidth}
                                width={onWallThickness / 2}
                                strokeWidth={1}
                                strokeScaleEnabled={false}
                            />
                            <Rect
                                x={0}
                                y={-onWallWidth / 2}
                                height={onWallWidth}
                                width={onWallThickness / 2}
                                stroke={"pink"}
                                fill={"#ff000011"}
                                strokeWidth={1}
                                strokeScaleEnabled={false}
                            />
                        </Group>
                    ) : (
                        <Group
                            x={brushPos.x}
                            y={brushPos.y}
                        >
                            <Rect
                                x={-onWallWidth / 2}
                                y={-onWallThickness / 2}
                                stroke={"pink"}
                                fill={"#ff000011"}
                                width={onWallWidth}
                                height={onWallThickness / 2}
                                strokeWidth={1}
                                strokeScaleEnabled={false}
                            />
                            <Rect
                                x={-onWallWidth / 2}
                                y={0}
                                width={onWallWidth}
                                height={onWallThickness / 2}
                                stroke={"pink"}
                                fill={"#ff000011"}
                                strokeWidth={1}
                                strokeScaleEnabled={false}
                            />
                        </Group>
                    )
                }, [brushPos, openDirect, edgeHelper])
            }
            {
                //预览部分
                useMemo(() => {
                    if(!edgeHelper) {
                        return ;
                    }
                    let anchor = openDirect === 'left' || openDirect === 'right' ? brushPos.y : brushPos.x;
                    return (
                        <ShapeOnWall 
                            onWallType={drawOnWallType}
                            openDirect={openDirect}
                            openClockwise={openClockwise}
                            anchor={anchor}
                            wall={edgeHelper}
                            onWallThickness={onWallThickness}
                            onWallWidth={onWallWidth}
                            wallThickness={wallThickness}
                        />
                    )
                }, [edgeHelper, brushPos, openDirect, openClockwise])
            }
            {
                // helper 部分
                useMemo(() => {
                    if(!edgeHelper) {
                        return ;
                    }
                    let direct: "x" | "y";
                    let base: number;
                    let d1From: number;
                    let d1To: number;
                    let d2From: number;
                    let d2To: number;
                    if(openDirect === 'left' || openDirect === 'right') {
                        direct = 'y';
                        base = edgeHelper.from.x;
                        if(edgeHelper.from.y < edgeHelper.to.y) {
                            d1From = edgeHelper.from.y + onWallThickness / 2;
                            d1To = brushPos.y - onWallWidth / 2;
                            d2From = brushPos.y + onWallWidth / 2;
                            d2To = edgeHelper.to.y - onWallThickness / 2;
                        }else {
                            d1From = edgeHelper.to.y + onWallThickness / 2;
                            d1To = brushPos.y - onWallWidth / 2;
                            d2From = brushPos.y + onWallWidth / 2;
                            d2To = edgeHelper.from.y - onWallThickness / 2;
                        }
                    }else {
                        direct = 'x';
                        base = edgeHelper.from.y;
                        if(edgeHelper.from.x < edgeHelper.to.x) {
                            d1From = edgeHelper.from.x + onWallThickness / 2;
                            d1To = brushPos.x - onWallWidth / 2;
                            d2From = brushPos.x + onWallWidth / 2;
                            d2To = edgeHelper.to.x - onWallThickness / 2;
                        }else {
                            d1From = edgeHelper.to.x + onWallThickness / 2;
                            d1To = brushPos.x - onWallWidth / 2;
                            d2From = brushPos.x + onWallWidth / 2;
                            d2To = edgeHelper.from.x - onWallThickness / 2;
                        }
                    }
                    return (
                        <>
                            <ShapeDimension 
                                direct={direct}
                                base={base}
                                offset={0}
                                from={d1From}
                                to={d1To} 
                                color="yellow"                    
                            />
                            <ShapeDimension 
                                direct={direct}
                                base={base}
                                offset={0}
                                from={d2From}
                                to={d2To}
                                color="yellow"                       
                            />
                        </>
                    )
                }, [brushPos, edgeHelper, openDirect])
            }

            {
                drawOnWallType && (
                    <Rect
                        x={0}
                        y={0}
                        width={CANVAS_SIZE * m2px}
                        height={CANVAS_SIZE * m2px}
                        onClick={onClick}
                    />
                )
            }
        </Layer>
    )


}