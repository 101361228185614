import { _06beineiBoard } from "../../boards/转角柜/06-beinei";
import { _06beiwaiBoard } from "../../boards/转角柜/06-beiwai";
import { _06ceneiBoardLeft } from "../../boards/转角柜/06-cenei-left";
import { _06ceneiBoardRight } from "../../boards/转角柜/06-cenei-right";
import { _06cewaiBoardLeft } from "../../boards/转角柜/06-cewai-left";
import { _06cewaiBoardRight } from "../../boards/转角柜/06-cewai-right";
import { _06dineiBoard } from "../../boards/转角柜/06-dinei";
import { _06diwaiBoard } from "../../boards/转角柜/06-diwai";
import { _06fengneiBoard } from "../../boards/转角柜/06-fengnei";
import { _06fengwaiBoard } from "../../boards/转角柜/06-fengwai";
import { _06lineiBoard } from "../../boards/转角柜/06-linei";
import { _06liwaiBoard } from "../../boards/转角柜/06-liwai";
import { _06menneiBoard } from "../../boards/转角柜/06-mennei";
import { _06menwaiBoard } from "../../boards/转角柜/06-menwai";
import { _06qiandangneiBoard } from "../../boards/转角柜/06-qiandangnei";
import { _06qiandangwaiBoard } from "../../boards/转角柜/06-qiandangwai";
import { _06zhuanjiaoneiXBoard } from "../../boards/转角柜/06-zhuanjiaonei-x";
import { _06zhuanjiaoneiYBoard } from "../../boards/转角柜/06-zhuanjiaonei-y";
import { _06zhuanjiaowaiXBoard } from "../../boards/转角柜/06-zhuanjiaowai-x";
import { _06zhuanjiaowaiYBoard } from "../../boards/转角柜/06-zhuanjiaowai-y";
import { LeftQiezhuanjiaoguiCebaobei } from "./leftQiezhuanjiaoguiCebaobei";

export class RightQiezhuanjiaoguiCebaobei extends LeftQiezhuanjiaoguiCebaobei {
    key: string = "RightQiezhuanjiaoguiCebaobei";

    setNameMap() {
        return {
            dineiBoard: _06dineiBoard,
            diwaiBoard: _06diwaiBoard,
            ceneiBoardLeft: _06ceneiBoardLeft,
            ceneiBoardRight: _06ceneiBoardRight,
            cewaiBoardLeft: _06cewaiBoardLeft,
            cewaiBoardRight: _06cewaiBoardRight,
            beiwaiBoard: _06beiwaiBoard,
            beineiBoard: _06beineiBoard,
            menwaiBoard: _06menwaiBoard,
            menneiBoard: _06menneiBoard,
            "menwaiBoard-left": _06menwaiBoard,
            "menneiBoard-left": _06menneiBoard,
            "menwaiBoard-right": _06menwaiBoard,
            "menneiBoard-right": _06menneiBoard,
            qiandangneiBoard: _06qiandangneiBoard,
            qiandangwaiBoard: _06qiandangwaiBoard,
            zhuanjiaoneiXBoard: _06zhuanjiaoneiXBoard,
            zhuanjiaowaiXBoard: _06zhuanjiaowaiXBoard,
            zhuanjiaoneiYBoard: _06zhuanjiaoneiYBoard,
            zhuanjiaowaiYBoard: _06zhuanjiaowaiYBoard,
            lineiBoard: _06lineiBoard,
            liwaiBoard: _06liwaiBoard,
            fengneiBoard: _06fengneiBoard,
            fengwaiBoard: _06fengwaiBoard
        }
    }

}