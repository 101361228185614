import { DirectX, DirectY } from "../../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate, pathRotate2 } from "../../../../util/graph";
import { corner3Sanheyi } from "../../../coners/三合一/corner3-sanheyi";
import { corner4Sanheyi } from "../../../coners/三合一/corner4-sanheyi";
import { rectBoard } from "../../general-board";

export const sanheyi02DineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner3Sanheyi(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.height;
    let cornerBLH: number = cornerBLSize.width;
    cornerBL = pathRotate2(cornerBL, startX, startY, 90);
    cornerBL = pathMirrorY(cornerBL, startX);
    // cornerBL = pathMoveX(cornerBL, DirectX.LEFT, cornerBLSize.width);
    // cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.width);
    // cornerBL = pathMirrorY(cornerBL, startX);

    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner3Sanheyi(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;
    cornerBR = pathRotate2(cornerBR, startX, startY, 90);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    // cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.width);

    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner4Sanheyi(dx, -dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner4Sanheyi(-dx, -dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
    cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.width);
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];

}