import { CheckCircleFilled, CheckCircleOutlined, CloseCircleFilled, DeleteFilled, FieldStringOutlined, HomeOutlined, InfoCircleFilled, InfoOutlined, LoadingOutlined, RightOutlined, SaveFilled } from "@ant-design/icons"
import { Image, Input, Modal, Tag, Tooltip, message } from "antd"
import { ReactNode, useEffect, useMemo, useState } from "react";
import { AreaData, CANVAS_SIZE, Drawed, DrawedAreaMeta, DrawedMeta, OnWallData, StructData, WallData, m2px, useDrawed } from "../../store";
import { useDrawTool } from "./store";
import { Vector2 } from "three";
import { computeWallDirectSign } from "../../util";
import { px2m } from "../../store/base";
import { useLocation } from "react-router-dom";
import { request } from "../../../../utils2/request";
import { apis } from "../../../../utils2/apis";
import pkg from '../../../../../package.json';
import { getJSONByDrawedList } from "../../utils";

export const Top0Widget = (props: {
    onOrderChange: (order: any) => void;
    onSave: (order: any, drawedList: DrawedMeta[], auto?: boolean) => Promise<boolean>;
}) => {

    const {
        stage, isDrawing, drawedList, redoList, editTarget,
        cancelDraw, updateDrawedList, updateRedoList, removeSelectedTargets
    } = useDrawTool();
    const { state, search } = useLocation();
    const [order, setOrder] = useState<any>(null);
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [lastSaveTime, setLastSaveTime] = useState("");
    const [lastSaveState, setLastSaveState] = useState<0 | 1 | -1>(-1);

    useEffect(() => {
        if(state?.order?.id) {
            //说明有order
            setOrder(state.order);
            setInit(true);
        }else {
            //没order获取order detail
            const params = new URLSearchParams(search);
            // 使用get()方法获取参数的值
            const _orderId = params.get('orderId');
            if(!_orderId) {
                return ;
            }
            request.get(apis.order + `/${_orderId}/detail`)
                .then((res) => {
                    if (res.code === 1) {
                        setOrder(res.data);
                        setInit(true);
                    }
                });
        }
    }, [state, search]);   
    
    
    useEffect(() => {
        if(init && loading && order) {
            if(order.floorPlannerData) {
                try {
                    const drawedList = JSON.parse(order.floorPlannerData);
                    updateDrawedList(drawedList);
                }catch(e) {
                    Modal.error({
                        title: "云端数据解析失败",
                    });
                }
            }
            setLoading(false);
        }
    }, [init, loading, order]);

    useEffect(() => {
        props.onOrderChange(order);
    }, [order]);
    


    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [canClear, setCanClear] = useState(false);
    const [canSave, setCanSave] = useState(false);


    useEffect(() => {
        if (isDrawing) {
            // setCanUndo(true);
            // setCanRedo(true);
            setCanClear(false);
            setCanSave(false);
        }
        if (redoList.length > 0) {
            setCanRedo(true);
        } else {
            setCanRedo(false);
        }
        if (drawedList.length > 0) {
            setCanUndo(true);
            setCanClear(true);
            setCanSave(true);
        } else {
            setCanUndo(false);
            setCanClear(false);
            setCanSave(false);
        }


    }, [isDrawing, drawedList, redoList]);

    const onDelete = () => {
        removeSelectedTargets();
    }



    const onUndo = () => {
        //如果在编辑，则取消编辑
        if (isDrawing || editTarget) {
            cancelDraw();
            return;
        }
        if (!canUndo) {
            return;
        }
        if (drawedList.length === 0) {
            return;
        }
        const last = drawedList.pop();
        redoList.push(last);
        updateDrawedList([...drawedList]);
        updateRedoList([...redoList]);
    }
    const onRedo = () => {
        if (!canRedo) {
            return;
        }
        if (redoList.length === 0) {
            return;
        }
        const last = redoList.pop();
        drawedList.push(last);
        updateDrawedList([...drawedList]);
        updateRedoList([...redoList]);
    }
    const onClear = () => {
        Modal.confirm({
            title: "不可恢复操作",
            content: "清空后不可恢复，请确认操作是否无误",
            okButtonProps: {
                danger: true,
            },
            // centered: true,
            okText: "确认清空",
            onOk: () => {
                updateDrawedList([]);
                updateRedoList([]);
                cancelDraw();
            }
        });
    }
    const updateOrderFloorPlannerData = (json: string) => {
        if(!order || !state?.order) {
            return ;
        }
        if(state.order) {
            state.order.floorPlannerData = json;
            history.replaceState(state, "");
        }
        order.floorPlannerData = json;
        setOrder({...order});
    }
    const onSave = (auto = true) => {
        if(isSaving) {
            return ;
        }
        //如果没有变化，则不保存
        const json = JSON.stringify(drawedList);
        if(order.floorPlannerData === json) {
            if(!auto) {
                message.success("已保存");
            }
            return ;
        }
        updateOrderFloorPlannerData(json);
        setIsSaving(true);
        props.onSave(order, drawedList, auto)
            .then((res) => {
                const now = new Date();
                setLastSaveState(Number(res) as 0 | 1);
                setLastSaveTime(now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds());
                setIsSaving(false);
                //保存之后，重设订单
                setTimeout(() => {
                    setLastSaveState(-1);
                }, 500);
            });
    }

    const handleCache = useMemo(() => {
        return {
            onUndo,
            onRedo,
            onSave,
            onDelete
        }
    }, []);
    handleCache.onUndo = onUndo;
    handleCache.onRedo = onRedo;
    handleCache.onSave = onSave;
    handleCache.onDelete = onDelete;

    //监听键盘事件，实现热键
    useEffect(() => {
        console.log("listen keydown");
        let pressKey = "";
        let handle = (e: KeyboardEvent) => {
            console.log("onKeyDown", e.key.toLowerCase());
            if (e.key.toLowerCase() === "escape") {
                console.log("Esc");
                pressKey = 'escape';
                cancelDraw();
            } else if (e.key.toLowerCase() === 'delete') {
                console.log("Delete");
                pressKey = 'delete';
                handleCache.onDelete();
            } else if (e.key.toLowerCase() === "backspace") {
                console.log("Delete");
                pressKey = 'backspace';
                handleCache.onDelete();
            } else if (e.key.toLowerCase() === "z" && e.ctrlKey && !e.shiftKey) {
                console.log("Ctrl+Z");
                pressKey = 'z';
                handleCache.onUndo();
            } else if ((e.key.toLowerCase() === "y" && e.ctrlKey)) {
                console.log("Ctrl+Y");
                pressKey = 'y';
                handleCache.onRedo();
            } else if ((e.key.toLowerCase() === "z" && e.ctrlKey && e.shiftKey)) {
                console.log("Ctrl+Shift+Z");
                pressKey = 'z';
                handleCache.onRedo();
            } else if (e.key.toLowerCase() === "s" && e.ctrlKey) {
                console.log("Ctrl+S");
                pressKey = 's';
                handleCache.onSave();
            }
            //防止一直按
            if (pressKey) {
                window.addEventListener("keyup", onKeyUp);
                window.removeEventListener("keydown", handle);
            }
        }
        const onKeyUp = (e: KeyboardEvent) => {
            console.log("onKeyUp")
            if (e.key.toLowerCase() === pressKey) {
                window.addEventListener("keydown", handle);
                window.removeEventListener("keyup", onKeyUp);
                pressKey = "";
            }
        }
        window.addEventListener("keydown", handle);
        return () => {
            console.log("remove handle hot key");
            window.removeEventListener("keydown", handle);
            window.removeEventListener("keyup", onKeyUp);
            pressKey = "";
        }
    }, []);

    //10s自动保存一次
    

    useEffect(() => {
        //防抖保存
        let timer: any;
        if(!order) {
            return ;
        }
        //操作停止1s后保存
        timer = setTimeout(() => {
            handleCache.onSave();
        }, 100);
        return () => {
            clearTimeout(timer);
            timer = null;
        }
    }, [order, drawedList]);

    return (
        <div className="top-menu fixed flex center space-between" style={{ opacity: 1, height: 54, width: '100%', left: 0, top: 0, padding: '0 16px', background: "rgba(248, 249, 251, 1)", zIndex: 4, }}>
            
            <div className="flex center" style={{ justifyContent: 'flex-start', width: 400 }}>
                {/* <img src="http://bxgcgsb.com/favicon.ico" style={{height:18,width:18}}/> */}
                <span className="bold" style={{ color: 'rgba(3, 9, 17, 0.8)' }}>大圣设计</span>
                    <Tag color="rgba(3, 9, 17, .07)" style={{ letterSpacing: 1, color: "rgba(3, 9, 17, 0.6)", marginLeft: 4, }}>V{pkg.version}</Tag>
                <div style={{ width: 1, height: 22, margin: '0 8px', background: 'rgba(3, 9, 17, 0.3)' }} />
                <span style={{ color: "rgba(3, 9, 17, 0.4)", fontSize: 14, cursor: "pointer",}} onClick={() => {
                    window.location.href = '/';
                }}>
                    <HomeOutlined style={{position: 'relative', top: 0 }}/> 首页
                </span>
                <RightOutlined style={{fontSize: 12, margin: '0 4px', color: "rgba(3, 9, 17, 0.6)"}}/>
                
                <span style={{ color: "rgba(3, 9, 17, 0.4)", fontSize: 14 }}>
                {loading ? "正在获取订单数据..." : order ? (<span className="bold">
                        当前订单：{order.address}
                    <Tooltip title={order?.orderNo || (loading ? "正在获取订单" : "无订单信息")}>
                        <InfoCircleFilled style={{marginLeft: 4}} />
                    </Tooltip></span> ) : (<span style={{color: "orange"}}>无订单信息</span>)}

                </span>
            </div>
            <div className="flex" style={{ color: "rgba(3, 9, 17, 0.7)", cursor: "pointer" }}>
                {/* <div className="flex center column tool-item" style={{height: 54, padding:'0 14px'}}>
                    <FolderOpenFilled style={{fontSize: 20}}/>
                    <div className="name" style={{fontSize: 22,zoom:.5, marginTop: 8}}>我的订单</div>
                </div> */}
                <ToolItem
                    disable={!canUndo}
                    icon={(<svg viewBox="0 0 1024 1024" width={20} height={20} fill="currentColor"><path d="M457.5744 135.0144a10.24 10.24 0 0 0-6.4 2.304l-375.4496 303.616a25.6 25.6 0 0 0-0.1024 39.7824l375.5008 306.2272a10.24 10.24 0 0 0 16.6912-7.936v-225.9968l15.3088 0.6144c114.8416 5.7344 205.056 30.0544 270.5408 73.0624 80.3328 46.6432 131.584 132.7104 153.856 258.0992a10.24 10.24 0 0 0 10.0864 8.4992h33.5872a10.24 10.24 0 0 0 10.24-10.24v-0.6144c-13.1072-221.5424-82.3808-378.624-207.7696-471.2448-75.4176-52.224-170.7008-84.6336-285.7984-97.28V145.2544a10.24 10.24 0 0 0-10.24-10.24z"></path></svg>)}
                    name="撤销"
                    onClick={onUndo}
                />
                <ToolItem
                    disable={!canRedo}
                    icon={(<svg viewBox="0 0 1024 1024" width={20} height={20} fill="currentColor"><path d="M566.4256 135.0144a10.24 10.24 0 0 1 6.4 2.304l375.4496 303.616a25.6 25.6 0 0 1 0.1024 39.7824l-375.5008 306.2272a10.24 10.24 0 0 1-16.6912-7.936v-225.9968l-15.3088 0.6144c-114.8416 5.7344-205.056 30.0544-270.5408 73.0624-80.3328 46.6432-131.584 132.7104-153.856 258.0992a10.24 10.24 0 0 1-10.0864 8.4992h-33.5872a10.24 10.24 0 0 1-10.24-10.24v-0.6144C75.6736 660.8896 144.896 503.808 270.336 411.136c75.4176-52.224 170.7008-84.6336 285.7984-97.28V145.2544c0-5.632 4.608-10.24 10.24-10.24z"></path></svg>)}
                    name={"恢复"}
                    onClick={onRedo}
                />
                <ToolItem
                    disable={!canClear}
                    icon={(<DeleteFilled style={{ fontSize: 20 }} />)}
                    name={"清空"}
                    onClick={onClear}
                />
                <ToolItem
                    disable={!canSave}
                    icon={(isSaving ? <LoadingOutlined style={{fontSize: 20}} spin/> : lastSaveState === -1 ? <SaveFilled style={{ fontSize: 20 }} /> : lastSaveState === 1 ? <CheckCircleFilled style={{color: "green", fontSize: 20}} /> : <CloseCircleFilled style={{color: "orange", fontSize: 20}}/>)}
                    name={"保存"}
                    onClick={() => {
                        onSave(false)
                    }}
                />
                {/* <ToolItem
                    icon={(<FieldStringOutlined style={{ fontSize: 20 }} />)}
                    name="JSON"
                    onClick={() => {
                        const drawedListJSON = JSON.stringify(getJSONByDrawedList(drawedList));
                        function copyText(text: string) {
                            navigator.clipboard.writeText(text)
                            .then(function() {Modal.info({
                                title: '已复制以下内容',
                                content: (
                                    <Input.TextArea value={drawedListJSON} style={{height: window.innerHeight - 400}}/>
                                ),
                            });

                            })
                            .catch(function(error) {
                                message.info("复制失败")
                            });
                        }
                        copyText(drawedListJSON);
                        
                    }}
                /> */}
            </div>
            <div style={{ width: 400, textAlign: "right" }}>
            </div>
        </div>
    )

}



const ToolItem = (
    props: {
        disable?: boolean;
        icon: ReactNode;
        name: string;
        onClick: () => void;
    }
) => {
    return (
        <div onClick={() => {
            if (props.disable) return;
            props.onClick();
        }} className="relative flex center column tool-item" style={{ opacity: props.disable ? 0.5 : undefined, height: 54, padding: '0 14px', cursor: props.disable ? "not-allowed" : "pointer" }}>
            {props.icon}
            <div className="name" style={{ fontSize: 22, zoom: .5, marginTop: 8 }}>{props.name}</div>
        </div>
        
    )
}