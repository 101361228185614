// import {  } from "dxf-writer";
import { Circle, Line, Arc } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner2 } from "../../coners/corner2";
import { corner3 } from "../../coners/corner3";
import { corner4 } from "../../coners/corner4";
import { corner5 } from "../../coners/corner5";
import { corner6 } from "../../coners/corner6";
import { corner8 } from "../../coners/corner8";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner5WaiWuhujiao } from "../../coners/无护角/corner5-wai-wuhujiao";
import { corner6WaiWuhujiao } from "../../coners/无护角/corner6-wai-wuhujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { corner5WaiWujiao } from "../../coners/无角/corner5-wai-wujiao";
import { corner6WaiWujiao } from "../../coners/无角/corner6-wai-wujiao";
import { rectBoard } from "../general-board";

// export const 
export const cewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    mengao?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
): [Path[], Path[]] => {
    const {
        上免拉手  ,
        上免拉手X ,
        上免拉手Y ,
        中免拉手  ,
        中免拉手X ,
        中免拉手Y ,
        中免拉手Z,
    } = extraConfig;
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(!extraConfig.isMeiqiguangui) {
        if(cornerType === "youhujiao") {
            cornerBL = corner5(dx, dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
        }else if(cornerType === "wujiao") {
            cornerBL = corner5WaiWujiao(dx, dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
        }else if(cornerType === "wuhujiao") {
            cornerBL = corner5WaiWuhujiao(dx, dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
        }
    }else {
        if(cornerType === "youhujiao") {
            cornerBL = corner2(dx, dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMirrorY(cornerBL, startX);
            cornerBL = pathRotate2(cornerBL, startX, startY, 180);
            cornerBL = pathMoveY(cornerBL, 1, cornerBLH);
        }else if(cornerType === "wujiao") {
            cornerBL = corner2WaiWujiao(dx, dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMirrorY(cornerBL, startX);
            cornerBL = pathRotate2(cornerBL, startX, startY, 180);
            cornerBL = pathMoveY(cornerBL, 1, cornerBLH);
        }else if(cornerType === "wuhujiao") {
            cornerBL = corner2WaiWuhujiao(dx, dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMirrorY(cornerBL, startX);
            cornerBL = pathRotate2(cornerBL, startX, startY, 180);
            cornerBL = pathMoveY(cornerBL, 1, cornerBLH);
        }
    }
    
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(!extraConfig.isMeiqiguangui) {
        if(cornerType === "youhujiao") {
            cornerBR = corner6(dx, dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;        
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
        }else if(cornerType === "wujiao") {
            cornerBR = corner6WaiWujiao(dx, dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
        }else if(cornerType === "wuhujiao") {
            cornerBR = corner6WaiWuhujiao(dx, dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
        }
    }else {
        
        if(cornerType === "youhujiao") {
            
            cornerBR = corner8(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        }else if(cornerType === "wujiao") {
            cornerBR = corner8(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        }else if(cornerType === "wuhujiao") {
            cornerBR = corner8(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        }
    }
    
    // const cornerBRSize = { width: 0, height: 0 };
    // let cornerBR = corner6(dx, dy, startX, startY, corner, cornerBRSize);
    // let cornerBRW: number = cornerBRSize.width;
    // let cornerBRH: number = cornerBRSize.height;
    // cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(!上免拉手) {
        if(cornerType === "youhujiao") {
            cornerTR = corner2(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
        }else if(cornerType === "wujiao") {
            cornerTR = corner2WaiWujiao(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
        }else if(cornerType === "wuhujiao") {
            cornerTR = corner2WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
        }
        if(qiandanghengfang) {
            cornerTR = pathMoveX(cornerTR, -DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMirrorY(cornerTR, startX);
            cornerTR = pathRotate2(cornerTR, startX, startY, -90);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
            const widthTemp = cornerTRSize.width;
            cornerTRW = cornerTRSize.height;
            cornerTRH = widthTemp;
        }
    }else {
        cornerTR.push({
            type: "Line",
            params: [
                startX + dx * width, startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * width - dx * (上免拉手X + 23.5),  startY + dy * height - dy * (上免拉手Y + 23.5)
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5),  startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * width - dx * (上免拉手X + 23.5), startY + dy * height
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5) - dx * 10, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5) - dx * 10 - dx * 25.5, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        })
        cornerTRW = 上免拉手X + 23.5;
        cornerTRH = 上免拉手Y + 23.5;

    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner2(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }else if(cornerType === "wujiao") {
        cornerTL = corner2WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height)
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height)
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }
    // if(qiandanghengfang) {
    //     cornerTL = pathMoveY(cornerTL, -DirectY.UP, height - cornerTLSize.height);
    //     cornerTL = pathMirrorY(cornerTL, startX);
    //     cornerTL = pathRotate2(cornerTL, startX, startY, 90);
    //     cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
    //     cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.height);
    //     const widthTemp = cornerTLSize.width;
    //     cornerTLW = cornerTLSize.height;
    //     cornerTLH = widthTemp;
    // }
    
    const boardConfig: any = {
        middleware: {
            top: false,
            left: height > 500 && true,
            right: false,
            bottom: extraConfig?.isMeiqiguangui ? false : width > 500 && "close",
        }
    }
    let paths = [];
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }

    if(中免拉手) {
        paths = paths.filter((_, index) => index !== 1);
        paths.push({
            type: Line,
            params: [
                startX + dx * width, startY + dy * cornerBRH,
                startX + dx * width, startY + dy * (中免拉手Z + 5.5)

            ]
        }, {
            type: Line,
            params: [
                startX + dx * width, startY + dy * (中免拉手Z + 5.5),
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5),
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                startX + dx * width,  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                startX + dx * width, startY + dy * height - dy * cornerTRH
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5) - dx * 10, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                1.6
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5) - dx * 10 - dx * 25.5, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                1.6
            ]
        })
    }

    if (lianjiekongbianju >= 0) {
        let offset1Y = 0;
        if(extraConfig.isMeiqiguangui) {
            offset1Y = 18;
        }
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5) + dy * offset1Y,
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5) + dy * offset1Y,
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        });
    }
    // if(extraConfig.isMeiqiguangui) {
    //     jiaolianbianju = -1;
    // }
    if (jiaolianbianju > -1 && !nojiaoliankong) {
        mengao = mengao as number;
        let offsetGX = 0;
        let offsetGY = 0;
        if (gxinglashou) {
            if (gxinglashouType === "up") {
                offsetGY = -1.5;
            }else if(gxinglashouType === "left-or-right") {
                offsetGX = 0;
            }
        }

        let offset1Y/* 无底板 */ = 0;
        if(extraConfig.isMeiqiguangui) {
            offset1Y = 18;
        }
        const temp: Path[] = [{
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * offset1Y,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * offset1Y,
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * offset1Y,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * offset1Y,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * offset1Y,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * offset1Y
            ]
        },
        //**********************
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32 + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32 + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32 + dy * offset1Y,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32 + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32 + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32 + dy * offset1Y
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 32 + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32 + dy * offset1Y,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32 + dy * offset1Y,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32 + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32 + dy * offset1Y,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32 + dy * offset1Y
            ]
        },
        //==============================================
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX,   startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY + dy * offset1Y,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY + dy * offset1Y
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY + dy * offset1Y,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY + dy * offset1Y,  
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY + dy * offset1Y
            ]
        },
        //******************* 
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY + dy * offset1Y,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY + dy * offset1Y
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY + dy * offset1Y,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY + dy * offset1Y,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY + dy * offset1Y,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY + dy * offset1Y,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY + dy * offset1Y
            ]
        }
        ];
        
        paths.push(...temp);
    }
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        paths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                (gecengkongjing || 4.96) / 2
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                (gecengkongjing || 4.96) / 2
            ]
        });
    }

    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ];
}