import { corner1 } from "./corner1";
import { pathMoveX, pathRotate } from '../../util/graph';
import { Circle } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { degreeTransformForEach } from "../../util";

export const corner3 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const size: {width: number, height: number} = {width: 0, height: 0};
    let  paths = corner1(dx, dy, startX, startY, true, size);
    const height = size.width;
    const width = size.height;
    paths = paths.filter((path) => path.type !== Circle);
    paths = pathRotate(paths, dx * dy * 90);
    paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = width;
            sizeRes.height = height;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);
}