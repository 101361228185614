import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { _05beineiBoard } from "./05-beinei";
import { _05beiwaiBoard } from "./05-beiwai";
import { _05ceneiBoardLeft } from "./05-cenei-left";
import { _05ceneiBoardRight } from "./05-cenei-right";
import { _05cewaiBoardLeft } from "./05-cewai-left";
import { _05cewaiBoardRight } from "./05-cewai-right";

export const _06cewaiBoardRight = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    corner: boolean,
    mengao: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _05cewaiBoardRight(cornerType, dx, dy, startX, startY, width, height, lianjiekongbianju, corner, mengao, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]
 }