import { ConfigProvider, theme } from "antd";
import { createHashRouter, Navigate } from "react-router-dom";
import Home1 from "./pages/home[v1]";
import LoginPage from "./pages/login";
import New from "./pages/new-order";
import New3DOrder from "./pages/new-order/3d-order";
import Settings from "./pages/settings";
import { CornerTest } from "./pages/2d-tool/corner/CornerTest";
import { NestChecking } from "./pages/2d-tool/nest/NestChecking";
import V1 from "./pages/2d-design[v0]";
import { NestResult } from "./pages/nest-result";
import { DisplayAll } from "./pages/nest-result/blocks/display-all";
import { MaterialDxf } from "./pages/nest-result/blocks/material-dxf";
import { SheetEditor } from "./pages/nest-result/blocks/sheet-edit";
import { DxfCheck } from "./pages/2d-tool/nest/DxfCheck";
import { NestView } from "./pages/nest";
// import { FloorPlanner } from "./pages/floor-planner";
import FloorPlanner from "./pages/2d-design";
import { Home2 } from "./pages/home[next]";
import { _3DWrapper } from "./pages/3d-design[wrapper]";
import { _3DWrapperV1 } from "./pages/3d-design[wrapper][v1]";
import HomeDebug from "./pages/home[v1][debug]";
import { CostSettingsPage } from "./pages/cost-settings";
import { MainLayout } from "./layout/main-layout";
import { SettingsLayout } from "./layout/settings-layout";
import { PartCostSettingsPage } from "./pages/settings/part-cost-settings";
import { SheetCostSettingsPage } from "./pages/settings/sheet-cost-settings";

// const FloorPlanner = lazy(() => import("./pages/floor-planner"));

const Home = Home1;
// const Home = Home2;

const LoginWrapper = (props: {
  comp: any
}) => {
  let localLoginInfo = localStorage.getItem("SK_LOGIN_INFO");
  if(!localLoginInfo) {
      return <Navigate to={"/login"} />
  }else {
    const expireAt = localStorage.getItem("SK_TOKEN_EXPIRE_AT");
    // console.log(Number(expireAt), Date.now() - );
    
    if(!expireAt || Number(expireAt) < (Date.now() + 60 * 60 * 1000)) {
      return (<Navigate to={"/login"} />);
    }
  }
  //@ts-ignore
  window.loginInfo = JSON.parse(localLoginInfo);
  let client;
  const localClient = localStorage.getItem("SK_CLIENT");
  if(localClient) {
    client = JSON.parse(localClient);
  }
  //@ts-ignore
  window.client = client;
  return (
    <props.comp />
  )
}

export const router = createHashRouter([
    {
      path: "/",
      Component: MainLayout,
      children: [
        {
          path: "/",
          Component: () => {
            return (<LoginWrapper comp={Home2}/>)
          }
          
        },
        {
          path: "/home",
          Component: () => {
            return (<LoginWrapper comp={Home2}/>)
          }
        },
        {
          path: "/settings",
          Component: SettingsLayout,
          children: [
            {
              path: "/settings",
              Component: () => {
                return (<Navigate to={"/settings/cost-settings/sheet-cost"} />)
              }
            },
            {
              path: "/settings/cost-settings/part-cost",
              Component: () => {
                return (<LoginWrapper comp={PartCostSettingsPage}/>)
              }
            },
            {
              path: "/settings/cost-settings/sheet-cost",
              Component: () => {
                return (<LoginWrapper comp={SheetCostSettingsPage}/>)
              }
            }

          ]
        },
        
       
      ]
    },
    
    {
      path: "/home/index",
      Component: () => {
        return (<LoginWrapper comp={Home2}/>)
      }
    },
    {
      path: "/last",
      Component: () => {
        return (<LoginWrapper comp={Home1}/>)
      }
    },
    {
      path: "/home/debug",
      Component: () => {
        return (
          <LoginWrapper comp={HomeDebug}/>
        )
      }
    },
    {
      path: "/3d/:orderId",
      Component: () => {
        return (<_3DWrapperV1 />);
      }
    },
    {
      path: "/next",
      Component: () => {
        return (<LoginWrapper comp={Home2}/>)
      }
    },
    {
      path: "/home/index2",
      Component: () => {
        return (<LoginWrapper comp={Home}/>)
      }
    },
    {
      path: "/floor-planner",
      Component: () => {
        return (<LoginWrapper comp={FloorPlanner}/>)
      }
    },
    {
      path: "/cupboard-design",
      Component: () => {
        return (<LoginWrapper comp={_3DWrapper}/>)
      }
    },
    {
      path: "/nest/:taskId?",
      Component: () => {
        return (<LoginWrapper comp={NestView}/>)
      }
    },
    {
      path: "/nest/result",
      Component: () => {
        return (
          (<LoginWrapper comp={NestResult}/>)
        )
      },
      children: [
        {
          path: "",
          Component: DisplayAll
        },
        {
          path: ":materialId",
          Component: MaterialDxf
        },
        {
          path: ":materialId/:sheetId",
          Component: SheetEditor
        }
      ]
    },
    {
      path: "/tool/corner-test",
      Component: () => {
        return (<LoginWrapper comp={CornerTest}/>)
      }
    },
    
    {
      path: "/tool/nest-check",
      Component: () => {
        return (<LoginWrapper comp={NestChecking}/>)
      }
    },
    {
      path: "/tool/dxf-check/:storageKey?",
      Component: () => {
        return (<LoginWrapper comp={DxfCheck}/>)
      }
    },
    {                                                                                                                          
      path: "/settings",
      Component: () => {
        return (<LoginWrapper comp={Settings}/>)
      }
    },
    {
      path: "/new",
      Component: () => {
        return (<LoginWrapper comp={New}/>)
      }
    },
    // {
    //   path: "/new/3d",
    //   element: (
    //     <New3DOrder />
    //   )
    // },
    {
      path: "/login",
      element: (
        <ConfigProvider theme={{
          algorithm: [theme.defaultAlgorithm]
        }}>
          <LoginPage />
        </ConfigProvider>
      )
    },
    {
      path: "/v1/:orderId",
      Component: () => {
        return (<LoginWrapper comp={V1}/>)
      }
    },
]);