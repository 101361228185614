import { DirectX, DirectY } from "../../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../../util/graph";
import { sanheyi01CeneiBoardLeft } from "./sanheyi-01-cenei-left";


// export const 
export const sanheyi01CeneiBoardRight = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number
 ): [Path[], Path[]]  => {
    const [ boardPaths, cornerPaths ] = sanheyi01CeneiBoardLeft(dx, dy, startX, startY, width, height, lianjiekongbianju);
    const combind = conbinePaths([boardPaths, cornerPaths]);
    let mirrorCombind = pathMirrorY(combind, startX);
    mirrorCombind = pathMoveX(mirrorCombind, DirectX.RIGHT, width);
    return [
        mirrorCombind.slice(0, boardPaths.length),
        mirrorCombind.slice(boardPaths.length, boardPaths.length + cornerPaths.length),
    ]
}