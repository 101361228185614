import { Arc, Circle, Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { degreeTransformForEach } from "../../util";
import { pathReverse, pathRotate } from "../../util/graph";

const genPart = (dx: number, dy: number, startX: number, startY: number, circle = false): Path[] => {
    let temp: Path[] = [];
    if (circle) {
        temp = [
            {
                type: Line,
                params: [
                    startX, startY,
                    startX, startY
                ]
            },
            {
                type: Circle,
                params: [
                    startX + dx * 12 + dx * 2 + dx * 5.39, startY + dy * 4.95 + dy * 1.61,
                    3.61
                ]
            },
            {
                type: Circle,
                params: [
                    startX + dx * 12 + dx * 2 + dx * 5.39, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 1.61,
                    3.61,
                ]
            }, 
            
        ];
    }

    return temp.concat(degreeTransformForEach([{
        type: Line,
        params: [
            startX, startY,
            startX + dx * 12, startY
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY,
            startX + dx * 12, startY + dy * 4.95
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95,
            startX + dx * 12 + dx * 2, startY + dy * 4.95
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95,
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123,
            0.2,
            90,
            180,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2,
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2,
            0.2,
            270,
            360,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2,
            startX + dx * 12 + dx * 0.2 + dx * 1.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2,
            0.2,
            0,
            90
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2,
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2,
            0.2,
            180,
            270
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 1.99, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71,
            1.99,
            90,
            180
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 1.99, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 1.99,
            startX + dx * 12 + dx * 1.99 + dx * 1.01, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 1.99,
        ]
    }], dx,dy));
};
export const corner4 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?:{width: number, height: number}): Path[] => {
    let odx = dx;
    dx = -dx;
    let paths = pathRotate(genPart(dx, dy, startX, startY), -90);
    dx = odx;
    let part2 = genPart(-dx, -dy, startX + dx * 55.085123 + dx * 15, startY  + dy * 15 + dy * 55.085123, true);
    //!!!!!!!!path得按顺序来, 所以要进行翻转，且应当用pathReverse进行翻转！！！!!!
    part2 = pathReverse(part2);
    paths = paths.concat(part2);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = 70.09;
            sizeRes.height = 70.09;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}