import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05beiwaiBoard } from "./05-beiwai";
import { _05cewaiBoardRight } from "./05-cewai-right";

export const _07cewaiBoardRight = (
   cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number,
    corner: boolean,
    mengao: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return _05cewaiBoardRight(cornerType, dx, dy, startX, startY, width, height,lianjiekongbianju, corner, mengao, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
 }