import { Base, Geometry } from "@react-three/csg"
import { StructData, px2m } from "../../../store"
import { BufferGeometry, Color, CylinderGeometry, ExtrudeGeometry, Shape, Vector2 } from "three";
import { useEffect, useState } from "react";

interface Props extends StructData {
    globalOffset: { x: number, y: number };

}

export const Struct3D = (props: Props) => {

    const { globalOffset, size, pos, groundClearance, structType, floorHeight, floorThickness } = props;


    const getGeometry = () => {
        let shape: Shape;
        if(structType === 'struct-round-post') {
            shape = new Shape();
            shape.moveTo((pos.x + globalOffset.x) * px2m, (pos.y + globalOffset.y) * px2m);
            shape.arc(0 , 0, size.x / 2 * px2m, 0, Math.PI * 2);
        }else {
            const v1 = new Vector2(pos.x - size.x / 2 + globalOffset.x, pos.y - size.y / 2 + globalOffset.y);
            const v2 = new Vector2(pos.x + size.x / 2 + globalOffset.x, pos.y - size.y / 2 + globalOffset.y);
            const v3 = new Vector2(pos.x + size.x / 2 + globalOffset.x, pos.y + size.y / 2 + globalOffset.y);
            const v4 = new Vector2(pos.x - size.x / 2 + globalOffset.x, pos.y + size.y / 2 + globalOffset.y);
            const points = [v1, v2, v3, v4];
            points.forEach((p) => {
                p.setX(p.x * px2m);
                p.setY(p.y * px2m);
            });
            shape = new Shape([v1, v2, v3, v4]);
        }
        // console.log(zHeight);
        
        const zHeight = (size.z < 0 ? floorHeight : size.z) * px2m;
        const zOffset = (floorThickness + (groundClearance === -1 ? floorHeight - size.z : groundClearance)) * px2m;
        const geo = new ExtrudeGeometry(shape, {
            depth: zHeight,
            bevelEnabled: false,
        });
        geo.translate(0, 0, -zHeight -zOffset);
        geo.rotateX(Math.PI / 2);
        return geo;
        
    }

    const [geometry, setGeometry] = useState<BufferGeometry>(getGeometry());

    useEffect(() => {
        setGeometry(getGeometry());
    }, [globalOffset, size, pos, groundClearance, structType, floorHeight, floorThickness]);

    return (
        <mesh>
            <Geometry>
                <Base geometry={geometry} />
            </Geometry>
            <meshStandardMaterial envMapIntensity={1} color={new Color(0xd3d3d3)} />
        </mesh>
    )
}