import { numberEqual } from ".";
import { Arc, Circle, Line } from "../constants";
import { DirectX, DirectY } from "../constants/enum";

/**
 * 圆弧角度  xy轴变换转换
 * @param fromD 
 * @param toD 
 * @param dx 
 * @param dy 
 */
export const degreeTransform = (fromD: number, toD: number, dx: DirectX, dy: DirectY): [number, number] => {
    while(fromD < 0) {
        fromD += 360;
    }
    while(toD < 0) {
        toD += 360;
    }
    while(fromD >= 360) {
        fromD %= 360;
    }
    while(toD > 360) {
        toD %= 360;
    }     
    const delta = toD - fromD;
    if(fromD < 0) {
        fromD = 360 + fromD;
        toD = fromD + delta;
    }
    if (dx === 1 && dy === 1) {
        return [fromD, toD];
    } else if (dx === 1 && dy === -1) {
        if (fromD === 0 || fromD === 180) {
            return [2 * fromD - toD, fromD];
        } else if (fromD === 90 || fromD === 270) {
            return [2 * fromD + 180 - toD, fromD + 180];
        } else if (fromD === 135 || fromD === 225) {
            return [180, 180 + toD - fromD];
        } else if(fromD === 45 || fromD === 315) {
            return [360 - toD, 360 - fromD]
        } else {
            return [0, 0]
        }
    } else if (dx === -1 && dy === 1) {
        if (fromD === 90 || fromD === 270) {
            return [2 * fromD - toD, fromD];
        } else if (fromD === 0 || fromD === 180) {
            return [2 * fromD + 180 - toD, fromD + 180];
        } else if (fromD === 135 || fromD === 45) {
            return [0, toD - fromD];
        } else if(fromD === 225 || fromD === 315) {
            return [270 * 2 - fromD, 270 * 2 - fromD + toD - fromD]
        }else if(fromD > 90 && fromD < 180) {  //sanheyi
            return [0, toD - fromD];
        }else {
            return [0, 0];
        }
        // //console.log(fromD);
    } else {
        if (fromD === 135) {
            return [fromD - toD, 0]
        }
        return [fromD + 180, toD + 180];
    }
}

/**
 * 坐标（startX, startY）逆时针旋转angle度后的坐标
 * @param startX 
 * @param startY 
 * @param angle 
 */
export const pointRotate = (startX: number, startY: number, angle: number): [number, number] => {
    return [startX * Math.cos(angle) - startY * Math.sin(angle), startX * Math.sin(angle) + startY * Math.cos(angle)];
}


/**
 * 旋转板子
 * @param paths 
 * @param angle 
//  */
export const boardRotate90 = (boards: Path[], corners: Path[], startX: number, startY: number,width: number, height: number): [Path[], Path[]]  => {
    const paths = boards.concat(corners);
    let result: Path[] = [];
    const centerX = (2 * startX + width) / 2;
    const centerY = (2 * startY + height) / 2;
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === Line) {
            const [x1, y1] = pointRotate(path.params[0] - centerX, path.params[1] - centerY, Math.PI / 2);
            const [x2, y2] = pointRotate(path.params[2] - centerX, path.params[3] - centerY, Math.PI / 2);
            result.push({
                type: Line,
                params: [x1 + centerX, y1 + centerY, x2 + centerX, y2 + centerY],
            })
        }else if(path.type === Arc) {
            const [x1, y1] = pointRotate(path.params[0] - centerX, path.params[1] - centerY,Math.PI / 2);
            result.push({
                type: Arc,
                params: [
                    x1 + centerX, y1 + centerY,
                    path.params[2],
                    path.params[3] + 90,
                    path.params[4] + 90
                ]
            })
        }else if(path.type === Circle) {
            const [x1, y1] = pointRotate(path.params[0] - centerX, path.params[1] - centerY, Math.PI / 2);
            result.push({
                type: Circle,
                params: [
                    x1 + centerX, y1 + centerY,
                    path.params[2]
                ]
            })
        }
    }
    result = pathMoveX(result, DirectX.LEFT, width / 2 - height / 2);
    result = pathMoveY(result, DirectY.UP, width / 2 - height / 2);
    return [
        result.slice(0, boards.length),
        result.slice(boards.length, result.length),
    ];
}

export const pathRotate2 = (paths: Path[], centerX: number, centerY: number, angle: number) => {
    const result: Path[] = [];
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === Line) {
            const [x1, y1] = pointRotate(path.params[0] - centerX, path.params[1] - centerY, angle / 360 * 2 * Math.PI);
            const [x2, y2] = pointRotate(path.params[2] - centerX, path.params[3] - centerY, angle / 360 * 2 * Math.PI);
            result.push({
                type: Line,
                params: [x1 + centerX, y1 + centerY, x2 + centerX, y2 + centerY],
            })
        }else if(path.type === Arc) {
            const [x1, y1] = pointRotate(path.params[0] - centerX, path.params[1] - centerY,angle / 360 * 2 * Math.PI);
            result.push({
                type: Arc,
                params: [
                    x1 + centerX, y1 + centerY,
                    path.params[2],
                    path.params[3] + angle,
                    path.params[4] + angle
                ]
            })
        }else if(path.type === Circle) {
            const [x1, y1] = pointRotate(path.params[0] - centerX, path.params[1] - centerY, angle / 360 * 2 * Math.PI);
            result.push({
                type: Circle,
                params: [
                    x1 + centerX, y1 + centerY,
                    path.params[2]
                ]
            })
        }
    }
    return result;
}
/**
 * path以起始点为轴，逆时针旋转angle度
 * @param paths 
 * @param angle 
 */
export const pathRotate = (paths: Path[], angle: number): Path[] => {
    if (paths.length === 0) {
        return [];
    }
    // //console.log(paths.slice(paths.length - 3, paths.length).map((item) => `${item.type}-${item.params.join(',')}\n`));
    // //console.log("===============")
    const result: Path[] = [];
    const a = angle / 360 * 2 * Math.PI;
    let centerX = paths[0].params[0];
    let centerY = paths[0].params[1];
    let tStartX: number | null = paths[0].params[0];
    let tStartY: number | null = paths[0].params[1];

    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if (path.type === Line) {
            if (tStartX === null) {  //针对
                tStartX = path.params[0];
                tStartY = path.params[1];
                
                [tStartX, tStartY] = pointRotate(tStartX - centerX, tStartY - centerY, a);
                tStartX += centerX;
                tStartY += centerY;
            }
            let _endX = path.params[2];
            let _endY = path.params[3];
            
            const [tEndX, tEndY] = pointRotate(_endX - centerX, _endY - centerY, a);
            result.push({
                type: Line,
                params: [
                    tStartX, tStartY as number,
                    centerX + tEndX, centerY + tEndY,
                ]
            });
            tStartX = centerX + tEndX;
            tStartY = centerY + tEndY;
        } else if (path.type === Arc) {
            const _endX = path.params[0];
            const _endY = path.params[1];
            const [tEndX, tEndY] = pointRotate(_endX - centerX, _endY - centerY, a);
            result.push({
                type: Arc,
                params: [
                    centerX + tEndX, centerY + tEndY,
                    path.params[2],
                    path.params[3] + angle,
                    path.params[4] + angle
                ]
            })
            tStartX = null;
            tStartY = null;
        } else if (path.type === Circle) {
            const _endX = path.params[0];
            const _endY = path.params[1];
            const [tEndX, tEndY] = pointRotate(_endX - centerX, _endY - centerY, a);
            result.push({
                type: Circle,
                params: [
                    centerX + tEndX, centerY + tEndY,
                    path.params[2],
                ]
            })
        }
    }
    return result;
}

/**
 * X轴方向平移distance
 * @param paths 
 * @param dx 
 * @param distance 
 */
export const pathMoveX = (paths: Path[], dx: DirectX, distance: number) => {
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        path.params[0] += dx * distance;
        if(path.type === Line) {
            path.params[2] += dx * distance;
        }
    }
    return paths;
}


/**
 * Y轴方向平移distance
 * @param paths 
 * @param dy 
 * @param distance 
 */
export const pathMoveY = (paths: Path[], dy: DirectY, distance: number) => {
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        path.params[1] += dy * distance;
        if(path.type === Line) {
            path.params[3] += dy * distance;
        }
    }
    return paths;
}

/**
 * 镜像X
 */
export const pathMirrorX = (paths: Path[], yAias: number) => {
    // let temp = paths = 


    // if(paths.length === 0) {
    //     return [];
    // }
    // // const centerX = paths[0].params[0];
    // for (let i = 0; i < paths.length; i++) {
    //     const path = paths[i];
    //     path.params[0] = yAias - (path.params[0] - yAias);
    //     if(path.type === Line) {
    //         path.params[2] = yAias - (path.params[2] - yAias);
    //     } 
    //     if(path.type === Arc) {
    //         throw new Error("arc not implemented");
    //         // const delta = path.params[4] - path.params[3];
    //         // const cnt180 = Math.floor((path.params[4] % 360) / 180);
    //         // const angle = path.params[4] % 180;
    //         // path.params[3] = 180 -  angle + cnt180 * 180;
    //         // path.params[4] = path.params[3] + delta;
    //     }
    // }
    // return paths;
}

export const cornerMirrorX = (cornerPaths: Path[], startX: number, size: {width: number, height: number}) => {
    // return cornerPaths;
    let temp = pathRotate(cornerPaths, 90);
    temp = pathMoveX(temp, DirectX.LEFT, size.width);
    temp = pathMirrorY(temp, startX);
    temp = pathRotate(temp, -90);
    temp = pathMoveY(temp, DirectY.DOWN, size.height);
    temp = pathMoveX(temp, DirectX.RIGHT, size.width);
    return temp;
}

export const pathMirrorY = (paths: Path[], xAias: number) => {
    if(paths.length === 0) {
        return [];
    }
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        path.params[0] = xAias - (path.params[0] - xAias);
        if(path.type === Line) {
            path.params[2] = xAias - (path.params[2] - xAias);
        } 
        if(path.type === Arc) {
            while(path.params[3] < 0) {
                path.params[3] += 360;
            }
            while(path.params[4] < 0) {
                path.params[4] += 360;
            }
            while(path.params[3] >= 360) {
                path.params[3] %= 360;
            }
            while(path.params[4] > 360) {
                path.params[4] %= 360;
            }   
            //算法一开始就是没问题的！！！  360 / 270 !== 1  要用Math.floor!!!
            // if(path.params[4] < 0) {
            //     path.params[4] = path.params[4] + 360;
            // }
            // if(path.params[3] < 0) {
            //     path.params[3] = path.params[3] + 360;
            // }
            // if(path.params[4] < 0) {
            //     path.params[4] = path.params[4] + 360;
            // }
            // if(path.params[3] < 0) {
            //     path.params[3] = path.params[3] + 360;
            // }
            const delta = path.params[4] - path.params[3];
            const cnt180 = Math.floor((path.params[4] % 360) / 180);
            const angle = path.params[4] % 180;
            path.params[3] = 180 -  angle + cnt180 * 180;
            path.params[4] = path.params[3] + delta;
        }
    }
    return paths;
}


export const conbinePaths = (paths: (Path[])[]): Path[] => {
    const result = [];
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        result.push(...path);
    }
    return result;
}

//!!!important for corner4!!!  
export const pathReverse = (paths: Path[]) => {
    let _paths = paths.reverse();
    for (let i = 0; i < _paths.length; i++) {
        const path = _paths[i];
        if(path.type === Line) {
            let t1 = path.params[0];
            path.params[0] = path.params[2];
            path.params[2] = t1;
            let t2 = path.params[1];
            path.params[1] = path.params[3];
            path.params[3] = t2;
        }
    }
    return _paths;
}


export const clearCorner = (paths: Path[]) => {
    return paths.filter((item) => item.type === Circle);
}