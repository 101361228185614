import { Form, Input, Modal, message } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { request } from "../utils2/request";
import { apis } from "../utils2/apis";


export const SheetCreateModal = forwardRef((props: {
    onSheetsAdd: (sheets: any[]) => void;
}, ref: any) => {

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            open: () => {
                setVisible(true);
            }
        }
    });

    const [form] = Form.useForm();

    const close = () => {
        setVisible(false);
        form.resetFields();
    }

    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (data: any) => {
        const width = Number(data.width);
        if (isNaN(width) || width <= 0) {
            message.error("宽度必须为正整数");
            return;
        }
        const length = Number(data.length);
        if (isNaN(length) || length <= 0) {
            message.error("长度必须为正整数");
            return;
        }
        if (submitting) {
            return;
        }
        setSubmitting(true);
        try {
            const res = await request.post(apis.sheet + '/add/multiple', {
                baseOn: "size",
                size: {
                    width,
                    length
                }
            });
            if (res.code === 1) {
                props.onSheetsAdd(res.data)
                close();
            }
        } catch (e) {
            message.error("添加失败");
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Modal
            title="按尺寸添加板材"
            open={visible}
            centered
            onCancel={close}
            onOk={() => {
                form.submit();
            }}
            okButtonProps={{
                loading: submitting
            }}
        >
            <Form form={form} onFinish={onSubmit}>
                <Form.Item label="长" name="length" rules={[
                    {
                        required: true,
                        message: "请输入长"
                    },
                ]}>
                    <Input type="number" placeholder="请输入长度" />
                </Form.Item>
                <Form.Item label="宽" name="width" rules={[{
                    required: true,
                    message: "请输入宽"

                }]}>
                    <Input type="number" placeholder="请输入宽度" />
                </Form.Item>
            </Form>

        </Modal>
    )

})