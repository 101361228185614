import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner10 } from "../../coners/corner10";
import { rectBoard } from "../general-board";

export const _07dineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    x: number,  //省的改代码
    y: number,  //省的改代码
    guijiaoX: number,
    guijiaoY: number,
    doorWidth: number,
    extraConfig: any
): [Path[], Path[]] => {
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL = corner10(dx, dy, startX, startY, true, cornerBLSize);
    cornerBL = pathRotate2(cornerBL, startX, startY, 90);
    cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
    let cornerBLW = cornerBLSize.height;
    let cornerBLH = cornerBLSize.width;
    //右下角
    const cornerBRSize = cornerBLSize;
    let cornerBR = JSON.parse(JSON.stringify(cornerBL))
    cornerBR = pathMirrorY(cornerBR, startX);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    // cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height );

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);


    
    const boardConfig: any = {
        middleware: {
            top: (width > 500),
            left: (height > 500),
            right: (height > 500),
            bottom: false,
        }
    }

    const paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    if (!extraConfig.无柜腿孔) {

        //板子四个角的四孔
        paths.push({
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        },
            //====================================
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50, startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50, startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        },
            //========================================
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50, startY + dy * height - dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50, startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        },
            //=====================================
            {
                type: Circle,
                params: [
                    startX + dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * height - dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        });
    }
    //其他孔，逆时针来
    const corners: Path[] = [];
    corners.push(
        {
            type: Circle,
            params: [
                startX + dx * (doorWidth + 2), startY + dy * height - dy * 32.03,
                2.1
            ]
        }, {
        type: Circle,
        params: [
            startX + dx * (doorWidth + 2), startY + dy * height - dy * 32.03 - dy * 31.5,
            2.1
        ]
    })
    return [
        paths,
        corners.concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}