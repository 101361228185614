import { Layer, Stage } from "react-konva"
import { BoardGraph, RectWithAngles } from "../../../components/graph/board"
import { CupboardBroad, Material, NestBoard, NestSheet, NestSheetSettings, Texture } from "../../../../../@interface"
import { DetailedHTMLProps, ForwardedRef, HTMLAttributes, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDndContext, useDraggable } from "@dnd-kit/core";
import { CSSProperties } from "styled-components";
import { BoardActionMap, DraggableBoardContextMap, DraggableBoardRef, DraggableData } from "./draggable-data";
import { sheetBoardDragStore } from "../store";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    index: number;
    actionMap: BoardActionMap; //为draggable提供上下文数据
    actionMode: "free" | "safe";
    autoApproachLine: boolean;
    sheet: NestSheet;
    sheetSettings: NestSheetSettings;//本应在sheet里
    moveTargetSheet: NestSheet | null;
    layout: "vertical" | "horizontal";
    board: NestBoard;
    scale: number;
    horizontalScale: number;
    material: Material;
    // x?: number;
    // y?: number;
    compatibleV1?: boolean;
    onBoardMoved: (deltaX: number, deltaY: number) => void;
    onBoardMoveToSheet: (toSheet: NestSheet) => void;

    //该rotate对stage进行操作，所以特殊
    rotate: boolean;
    //右键操作情况，所以单拎出来
    onSetRotate: (rotate: boolean) => void;
    onSetHelperLines: (lines: ({x?: number, y?: number})[]) => void;
    onShowMoveTargetSheet: (sheet: NestSheet) => void;
}

const refsMap: { [i: number]: HTMLDivElement } = {};

const contextMap: DraggableBoardContextMap = {};

export const DraggableBoard = memo(forwardRef((props: Props, ref: DraggableBoardRef) => {

    const scale = props.scale;
    const horizontalScale = props.horizontalScale;
    const board = props.board;
    const sheet = props.sheet;
    const material = props.material;
    const [layout, setLayout] = useState(props.layout)

    const boardRef = useRef<any>();
    const boardHorizontalRef = useRef<any>();
    const [rotate, setRotate] = useState(props.rotate);

    const onBoardDanger = () =>{
        if (layout === 'horizontal') {
            boardHorizontalRef.current?.danger();
        } else {
            boardRef.current?.danger();
        }
    }
    
    const onBoardDangerCancel = () => {
        if (layout === 'horizontal') {
            boardHorizontalRef.current?.cancelDanger();
        } else {
            boardRef.current?.cancelDanger();
        }
    }

    const onBoardMoveStart = () => {
        
        if (layout === 'horizontal') {
            boardHorizontalRef.current?.moveReady();
        } else {
            boardRef.current?.moveReady();

        }
    }
    const onBoardMoveEnd = () => {
        if (layout === 'horizontal') {
            boardHorizontalRef.current?.moveCancel();
        } else {
            boardRef.current?.moveCancel();

        }

    }
    contextMap[board.board.id] = { 
        onBoardDanger, 
        onBoardDangerCancel,
        onBoardMoveStart,
        onBoardMoveEnd,
        onBoardMoved: props.onBoardMoved,
        onSetHelperLines: props.onSetHelperLines,
        onShowMoveTargetSheet: props.onShowMoveTargetSheet,
        onBoardMoveToSheet: props.onBoardMoveToSheet
    }

    const divRef = useRef<any>();
    const { attributes, listeners, setNodeRef, isDragging, transform } = useDraggable({
        id: props.board.board.id.toString(),
        data: {
            layout,
            board,
            sheet: props.moveTargetSheet || sheet,
            scale,
            horizontalScale,
            contextMap: contextMap,
            actionMap: props.actionMap,
            actionMode: props.actionMode,
            autoApproachLine: props.autoApproachLine,
            sheetSettings: props.sheetSettings
        } as DraggableData
    });
    setNodeRef(divRef.current)

    // const context = useDndContext();

    useEffect(() => {
        boardRef.current?.setScale(props.scale);
    }, [props.scale]);

    useEffect(() => {
        boardHorizontalRef.current?.setScale(props.horizontalScale);
    }, [props.horizontalScale]);

    useEffect(() => {
        setLayout(props.layout)
    }, [props.layout]);

    useEffect(() => {
        setRotate(props.rotate);
    }, [props.rotate]);


    useImperativeHandle(ref, () => {
        return {
        }
    });
    let style: CSSProperties = {};
    if (transform) {
        style.transform = `translate3d(${transform.x}px, ${transform.y}px, 0)`;
    }

    const onMouseOver = () => {
        if(sheetBoardDragStore.draggingLock) {
            return ;
        }
        if (divRef.current) {
            divRef.current.style.zIndex = '9';
            divRef.current.style.cursor = 'grab';
        }
        if (layout === 'horizontal') {
            boardHorizontalRef.current?.hover();
        } else {
            boardRef.current?.hover();
        }
    }

    const onMouseLeave = () => {
        if(sheetBoardDragStore.draggingLock) {
            return ;
        }
        if (divRef.current) {
            divRef.current.style.zIndex = '2';
            divRef.current.style.cursor = 'default';
        }
        if (layout === 'horizontal') {
            boardHorizontalRef.current?.leave();
        } else {
            boardRef.current?.leave();
        }
    }

    const onMouseDown = () => {
        if (divRef.current) {
            divRef.current.style.cursor = 'grabbing';
        }

    }

    const onMouseUp = () => {
        if (divRef.current) {
            divRef.current.style.cursor = 'grab';
        }
    }

    const onRotate = () => {
        props.onSetRotate(!rotate)

    }

    return (
        // <Dropdown className="no-motion" trigger={["contextMenu"]} menu={{
        //     items: [
        //         {
        //             icon: <ScissorOutlined />,
        //             label: '剪切到粘贴板',
        //             key: "delte"
        //         },
        //         {
        //             icon: (rotate ? <RotateLeftOutlined /> : <RotateRightOutlined />),
        //             label: '旋转',
        //             key: "delte",
        //             onClick: onRotate
        //         },
        //         {
        //             icon: (<DeleteOutlined />),
        //             label: '删除',
        //             key: "delte",
        //             danger: true
        //         }
        //     ]
        // }}>
            <div
                className="draggable-board"
                ref={divRef}
                style={Object.assign(style, props.style)}
                {...listeners}
                {...attributes}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onMouseDown={onMouseDown}
                onDoubleClick={onRotate}
            >
                <Stage
                    visible={layout === 'vertical'}
                    height={rotate ? Math.ceil(board.width * scale) : Math.ceil(board.height * scale)}
                    width={rotate ? Math.ceil(board.height * scale) : Math.ceil(board.width * scale)}
                    x={rotate ? board.height * scale : 0}
                    rotation={rotate ? 90 : 0}
                >
                    <OneTimesRender
                        ref={boardRef}
                        scale={scale}
                        board={board}
                        material={material}
                        compatibleV1={props.compatibleV1}
                    />
                </Stage>
                <Stage
                    visible={layout === 'horizontal'}
                    height={rotate ? Math.ceil(board.height * horizontalScale) : Math.ceil(board.width * horizontalScale)}
                    width={rotate ? Math.ceil(board.width * horizontalScale) : Math.ceil(board.height * horizontalScale)}
                    // x={!rotate ? board.height * horizontalScale : 0}
                    y={!rotate ? board.width * horizontalScale : 0}
                    rotation={!rotate ? 270 : 0}

                >
                    <OneTimesRenderHorizontal
                        ref={boardHorizontalRef}
                        scale={horizontalScale}
                        board={board}
                        material={material}
                        compatibleV1={props.compatibleV1}
                    />
                </Stage>
                <div className="text absolute flex column center" style={{ padding: '0 12px', fontSize: 12, height: '100%', width: '100%', /* [rotate ? 'height' : 'width']: board.width * scale, [rotate ? 'width' : 'height']: board.height * scale, */ left: 0, top: 0 }}>
                    <div>{props.board.board.cupboardIndex + 1}. {props.board.board.cupboardName}</div>
                    <div style={{ marginTop: 2 }}>{props.board.board.name}{props.board.board.type}</div>
                </div>
            </div>
        // </Dropdown>
    )

}));


interface MoveTargetBoardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    layout: "horizontal" | "vertical",
    board: NestBoard;
    scale: number;
    horizontalScale: number;
    material: Material
    compatibleV1?: boolean;
    rotate: boolean;
}
export const MoveTargetBoard = memo((props: MoveTargetBoardProps)=>{
    const rotate = props.rotate || false;
    const {board, layout, scale, horizontalScale, material} = props;
    return (
        <div
                className="move-target-board"
                // ref={thisSetNodeRef}
                style={props.style}
            >
                <Stage
                    visible={layout === 'vertical'}
                    height={rotate ? Math.ceil(board.width * scale) : Math.ceil(board.height * scale)}
                    width={rotate ? Math.ceil(board.height * scale) : Math.ceil(board.width * scale)}
                    x={rotate ? board.height * scale : 0}
                    rotation={rotate ? 90 : 0}
                >
                    <OneTimesRender
                        // ref={boardRef}
                        scale={scale}
                        board={board}
                        material={material}
                        compatibleV1={props.compatibleV1}
                    />
                </Stage>
                <Stage
                    visible={layout === 'horizontal'}
                    height={rotate ? Math.ceil(board.height * horizontalScale) : Math.ceil(board.width * horizontalScale)}
                    width={rotate ? Math.ceil(board.width * horizontalScale) : Math.ceil(board.height * horizontalScale)}
                    // x={!rotate ? board.height * horizontalScale : 0}
                    y={!rotate ? board.width * horizontalScale : 0}
                    rotation={!rotate ? 270 : 0}

                >
                    <OneTimesRenderHorizontal
                        // ref={boardHorizontalRef}
                        scale={horizontalScale}
                        board={board}
                        material={material}
                        compatibleV1={props.compatibleV1}
                    />
                </Stage>
                <div className="text absolute flex column  center" style={{ color: "gray", padding: '0 12px', fontSize: 12, height: '100%', width: '100%', /* [rotate ? 'height' : 'width']: board.width * scale, [rotate ? 'width' : 'height']: board.height * scale, */ left: 0, top: 0 }}>
                    <div>{props.board.board.cupboardIndex + 1}. {props.board.board.cupboardName}</div>
                    <div style={{ marginTop: 2 }}>{props.board.board.name}{props.board.board.type}</div>
                </div>
            </div>
    )
})


const OneTimesRender = memo(forwardRef((props: {
    scale,
    board,
    material,
    compatibleV1
}, ref: any) => {
    // console.log("OneTimesRender");
    const {
        scale,
        board,
        material,
        compatibleV1
    } = props;

    const maskRef = useRef<any>();
    const mainRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            hover: () => {
                maskRef.current?.show();
                mainRef.current?.hide();
            },
            leave: () => {
                mainRef.current?.show();
                maskRef.current?.hide();

            },

            moveReady: () => {
                maskRef.current?.moveReady()
            },
            moveCancel: () => {
                maskRef.current?.resetStroke();
            },
            setScale: (scale) => {
                mainRef.current?.setScale(scale);
                maskRef.current?.setScale(scale);
            },
            danger: () => {
                maskRef.current?.danger();
            },
            cancelDanger: () => {
                maskRef.current?.moveReady()
            },
        }
    })

    return (
        <Layer rotationDeg={board.rotate ? 90 : 0} x={board.rotate ? board.width * scale : 0}>
            <RectWithAngles
                ref={mainRef}
                scale={scale}
                board={board.board}
                material={material}
                fill
                compatibleV1={compatibleV1}
                background="#000"
            />

            <RectWithAngles
                ref={maskRef}
                defaultHide
                scale={scale}
                board={board.board}
                material={{ id: "hover", name: "hover", texture: { type: "COLOR", id: "hover", value: "#257793" } }}
                fill
                compatibleV1={compatibleV1}
                background="#000"
            />
        </Layer>
    )
}))

const OneTimesRenderHorizontal = memo(forwardRef((props: {
    scale,
    board: NestBoard,
    material,
    compatibleV1
}, ref: any) => {
    const {
        scale,
        board,
        material,
        compatibleV1
    } = props;

    const maskRef = useRef<any>();
    const mainRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            hover: () => {
                maskRef.current?.show();
                mainRef.current?.hide();
            },
            leave: () => {
                mainRef.current?.show();
                maskRef.current?.hide();

            },

            moveReady: () => {
                maskRef.current?.moveReady()
            },
            moveCancel: () => {
                maskRef.current?.resetStroke();
            },
            setScale: (scale) => {
                mainRef.current?.setScale(scale);
                maskRef.current?.setScale(scale);
            },
            danger: () => {
                maskRef.current?.danger();
            },
            cancelDanger: () => {
                maskRef.current?.moveReady()
            },

        }
    });

    return (
        <Layer rotationDeg={board.rotate ? 90 : 0} x={board.rotate ? board.width * scale : 0}>
            <RectWithAngles
                ref={mainRef}
                scale={scale}
                board={board.board}
                material={material}
                fill
                compatibleV1={compatibleV1}
                background="#000"
            />

            <RectWithAngles
                ref={maskRef}
                defaultHide
                scale={scale}
                board={board.board}
                material={{ id: "hover", name: "hover", texture: { type: "COLOR", id: "hover", value: "#257793" } }}
                fill
                compatibleV1={compatibleV1}
                background="#000"
            />

        </Layer>
    )
}))