import { Circle } from "../../../../constants";
import { DirectX, DirectY } from "../../../../constants/enum";
import { conbinePaths } from "../../../../util/graph";
import { dineiBoard } from "../01-dinei";
import { sanheyi01DineiBoard } from "./sanheyi-01-dinei";

export const sanheyi01BeineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number
): [Path[], Path[]] => {

    const [dineiBoardPaths, dineiBoardCorners] = sanheyi01DineiBoard(dx, dy, startX, startY, width, height, 0, 0);
    //四个角孔
    dineiBoardCorners.push({
        type: Circle,
        params: [
            startX + dx * 43.1, startY + dy * 43.1,
            4
        ]

    }, {
        type: Circle,
        params: [
            startX + dx * width - dx * 43.1, startY + dy * 43.1,
            4
        ]

    }, {
        type: Circle,
        params: [
            startX + dx * width - dx * 43.1, startY + dy * height - dy * 43.1,
            4
        ]

    }, {

        type: Circle,
        params: [
            startX + dx * 43.1, startY + dy * height - dy * 43.1,
            4
        ]
    })

    return [
        dineiBoardPaths,
        dineiBoardCorners
    ]

}