import { Vector2 } from "three";
import { AreaData, DrawedAreaMeta, DrawedMeta, OnWallData, StructData, WallData } from "../store";
import { computeWallDirectSign } from "../util";
import { px2m } from "../store/base";
import { reorderRectPoints } from "../gpt/reorderRectPoints";

export const getJSONByDrawedList = (drawedList: DrawedMeta[]) => {

    type Point = {
        x: number;
        y: number;
    }
    /**
     * 参数顺序如下：
     * 0----1
     * |    |
     * 3----2
     */
    type ShapeRect =  {
        type: "rect",
        data: [Point, Point, Point, Point]

    }
    /**
     * 0: 圆心x,y;
     * 1: 半径
     */
    type ShapeCircle = {
        type: "circle",
        data: [Point, number]
    }
    /**
     * 边数大于等于3的多边形
     * 顺序可能为逆时针或者顺时针
     */
    type ShapePolygon = {
        type: "polygon",
        data: Point[]
    }
    
    type Shape2D = ShapeRect | ShapeCircle | ShapePolygon;

    type Shape3D = {
        type: "wall" | "door" | "window" | "area" | "struct"; //墙体、门、窗、区域（墙内范围，可渲染地面）、结构
        data: {                 //输出单位：米
            shape2D: Shape2D;   //xy轴的形状
            zHeight: number;    //z轴高度
            zOffset: number;    //z轴偏移 >0表示向上偏移
        },
        csgType: "add" | "sub";
    }
    const shape3DList: Shape3D[] = [];


    //计算部分
    //globalOffset计算
    let left = Infinity;
    let top = Infinity;
    let right = -Infinity;
    let bottom = -Infinity;
    let globalOffset: Point;
    const areas = drawedList.filter((d) => d.type === 'area') as DrawedAreaMeta[];
    let displayWalls: WallData[] = [];
    if (areas.length > 0) {
        areas.forEach((a) => {
            displayWalls.push(...a.data.walls);
        });
    } else {
        displayWalls = drawedList.filter((d) => d.type.indexOf("wall-") === 0).map((d) => d.data) as WallData[];
    }
    displayWalls.forEach((w) => {
        left = Math.min(left, w.startAnchorPos.x, w.endAnchorPos.x);
        top = Math.min(top, w.startAnchorPos.y, w.endAnchorPos.y);
        right = Math.max(right, w.startAnchorPos.x, w.endAnchorPos.x);
        bottom = Math.max(bottom, w.startAnchorPos.y, w.endAnchorPos.y);
    });
    globalOffset = {
        x: -(left + right) / 2,
        y: -(top + bottom) / 2
    };

    displayWalls.forEach(wall => {
        const { startAnchorPos, wallLength, wallDirect, wallThickness, floorHeight, floorThickness, endAnchorPos } = wall;
        //墙的计算
        const sign = computeWallDirectSign(wallLength);
        const wallEffect = wallThickness / 2 * sign;
        let points: Vector2[] = [];
        if (wallDirect === 'y') {
            const v1 = new Vector2(startAnchorPos.x - wallEffect + globalOffset.x, startAnchorPos.y - wallEffect + globalOffset.y);
            const v2 = new Vector2(startAnchorPos.x + wallEffect + globalOffset.x, startAnchorPos.y - wallEffect + globalOffset.y);
            const v3 = new Vector2(endAnchorPos.x + wallEffect + globalOffset.x, endAnchorPos.y + wallEffect + globalOffset.y);
            const v4 = new Vector2(endAnchorPos.x - wallEffect + globalOffset.x, endAnchorPos.y + wallEffect + globalOffset.y);
            points = [v1, v2, v3, v4];
        } else {
            const v1 = new Vector2(startAnchorPos.x - wallEffect + globalOffset.x, startAnchorPos.y - wallEffect + globalOffset.y);
            const v2 = new Vector2(endAnchorPos.x + wallEffect + globalOffset.x, endAnchorPos.y - wallEffect + globalOffset.y);
            const v3 = new Vector2(endAnchorPos.x + wallEffect + globalOffset.x, endAnchorPos.y + wallEffect + globalOffset.y);
            const v4 = new Vector2(startAnchorPos.x - wallEffect + globalOffset.x, startAnchorPos.y + wallEffect + globalOffset.y);
            points = [v1, v2, v3, v4];
        }

        let zOffset = 0;
        let zHeight = floorHeight + floorThickness;
        const shape3D: Shape3D = {
            type: "wall",
            data: {
                shape2D: {
                    type: "rect",
                    data: points.map((p) => ({ x: p.x, y: p.y })) as [Point, Point, Point, Point]
                },
                zHeight,
                zOffset
            },
            csgType: "add"
        }
        shape3DList.push(shape3D);
    });

    const onWallList = drawedList.filter((d) => d.type.indexOf("onwall-") === 0).map((d) => d.data) as OnWallData[];

    onWallList.forEach((onwall) => {
        const {
            openDirect, onWallHeight, onWallWidth, groundClearance, anchor, wall,
            wallThickness, floorThickness
        } = onwall;
        let points: Vector2[];
        if (openDirect === 'left' || openDirect === 'right') {
            const v1 = new Vector2(wall.from.x - wallThickness / 2 + globalOffset.x, anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.y);
            const v2 = new Vector2(wall.from.x + wallThickness / 2 + globalOffset.x, anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.y);
            const v3 = new Vector2(wall.from.x + wallThickness / 2 + globalOffset.x, anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.y);
            const v4 = new Vector2(wall.from.x - wallThickness / 2 + globalOffset.x, anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.y);
            points = [v1, v2, v3, v4];
        } else {
            const v1 = new Vector2(anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.x, wall.from.y - wallThickness / 2 + globalOffset.y);
            const v2 = new Vector2(anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.x, wall.from.y - wallThickness / 2 + globalOffset.y);
            const v3 = new Vector2(anchor + onWallWidth / 2 + wallThickness / 2 + globalOffset.x, wall.from.y + wallThickness / 2 + globalOffset.y);
            const v4 = new Vector2(anchor - onWallWidth / 2 - wallThickness / 2 + globalOffset.x, wall.from.y + wallThickness / 2 + globalOffset.y);
            points = [v1, v2, v3, v4];
        }
        let type: ("door" | "window") = onwall.onWallType.indexOf("door") > -1 ? "door" : "window";
        const zHeight = onWallHeight;
        const zOffset = floorThickness + groundClearance;
        const shape3D: Shape3D = {
            type,
            data: {
                shape2D: {
                    type: "rect",
                    data: points.map((p) => ({ x: p.x, y: p.y })) as [Point, Point, Point, Point]
                },
                zHeight,
                zOffset
            },
            csgType: "sub"
        };
        shape3DList.push(shape3D);


    });


    const structList = drawedList.filter((d) => d.type.indexOf("struct") === 0).map((s) => s.data) as StructData[];
    structList.forEach((s) => {
        const {
            size, pos, groundClearance, structType, floorHeight, floorThickness
        } = s;
        let shape2D: Shape2D;
        let type: "struct" = "struct";
        if (s.structType.indexOf("round") > -1) {
            shape2D = {
                type: "circle",
                data: [
                    { x: pos.x + globalOffset.x, y: pos.y + globalOffset.y },
                    size.x / 2
                ]
            }
        } else {
            const v1 = new Vector2(pos.x - size.x / 2 + globalOffset.x, pos.y - size.y / 2 + globalOffset.y);
            const v2 = new Vector2(pos.x + size.x / 2 + globalOffset.x, pos.y - size.y / 2 + globalOffset.y);
            const v3 = new Vector2(pos.x + size.x / 2 + globalOffset.x, pos.y + size.y / 2 + globalOffset.y);
            const v4 = new Vector2(pos.x - size.x / 2 + globalOffset.x, pos.y + size.y / 2 + globalOffset.y);
            const points = [v1, v2, v3, v4];
            shape2D = {
                type: "rect",
                data: points.map((p) => ({ x: p.x, y: p.y })) as [Point, Point, Point, Point]
            }
        }
        const zHeight = size.z < 0 ? floorHeight : size.z;
        const zOffset = floorThickness + (groundClearance < 0 ? floorHeight - size.z : groundClearance);
        const shape3D: Shape3D = {
            type,
            data: {
                shape2D,
                zHeight,
                zOffset
            },
            csgType: "add"
        };
        shape3DList.push(shape3D);
    });
    const areaList = areas.map((d) => d.data) as AreaData[];
    areaList.forEach((a) => {
        const { innerContour, outerContour, anchors, floorThickness } = a;
        const type: "area" = "area";
        const zHeight = floorThickness;
        const zOffset = 0;
        const points: Point[] = innerContour.map((p) => ({
            x: p.x + globalOffset.x,
            y: p.y + globalOffset.y
        }));
        const shape3D: Shape3D = {
            type,
            data: {
                shape2D: {
                    type: "rect",
                    data: points as [Point, Point, Point, Point]
                },
                zHeight,
                zOffset
            },
            csgType: "add"
        };
        shape3DList.push(shape3D);
    });
    shape3DList.forEach((s3d, index) => {
        s3d.data.shape2D.data.forEach((p, i) => {
            if (typeof s3d.data.shape2D.data[i] === 'number') {
                (s3d.data.shape2D.data[i] as number) *= px2m;
            } else {
                (s3d.data.shape2D.data[i] as Point).x *= px2m;
                (s3d.data.shape2D.data[i] as Point).y *= px2m;
            }
        });
        if(s3d.data.shape2D.type === 'rect') {
            //reorder
            const reorderData = reorderRectPoints(s3d.data.shape2D.data);
            s3d.data.shape2D.data = reorderData;
        }
        s3d.data.zHeight *= px2m;
        s3d.data.zOffset *= px2m;
    });
    return shape3DList;
}
