import { Button, Dropdown } from "antd";
import { localeOptions, useLocaleStore } from ".";
import { TranslationOutlined } from "@ant-design/icons";

export const LocaleSwitch = () => {

    const localeLabel = useLocaleStore(state => state.localeLabel);
    const setLocale = useLocaleStore(state => state.setLocale);
    
    return (

        <Dropdown
            trigger={["click"]}

            menu={{
                items: localeOptions.map((item) => {
                    return {
                        key: item.value,
                        label: item.label,
                        onClick: () => {
                            setLocale(item.value);
                        }
                    }
                })
            }}
        >
            <Button type="text">
                <TranslationOutlined />
                {localeLabel}
            </Button>

        </Dropdown>
    )

}