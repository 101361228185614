import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { _05beineiBoard } from "./05-beinei";
import { _05beiwaiBoard } from "./05-beiwai";
import { _05ceneiBoardLeft } from "./05-cenei-left";
import { _05ceneiBoardRight } from "./05-cenei-right";
import { _05cewaiBoardLeft } from "./05-cewai-left";
import { _05cewaiBoardRight } from "./05-cewai-right";
import { _05dineiBoard } from "./05-dinei";
import { _05diwaiBoard } from "./05-diwai";
import { _05fengwaiBoard } from "./05-fengwai";

export const _06fengwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _05fengwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]
 }