import { Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { pathMoveY, pathRotate2 } from "../../util/graph";
import { setting } from "../../util/setting";
export const middleware = {
    inner: {
        paths: (startX: number, startY: number, type: boolean | "far" | "close", dx = 1, dy = 1) => {
            if(typeof type === "boolean") {
                type = "far";
            }
            dy *= -1;
            let paths: Path[] = [];
            paths.push({
                type: "Line",
                params: [
                    startX, startY,
                    startX, startY + dy * 12
                ]
            }, {
                type: 'Line',
                params: [
                    startX, startY + dy * 12,
                    startX + dx * 4.95, startY + dy * 12
                ]
            }, {
                type: 'Line',
                params: [
                    startX + dx * 4.95, startY + dy * 12,
                    startX + dx * 4.95, startY + dy * 12 + dy * 2
                ]

            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95, startY + dy * 12 + dy * 2,
                    startX + dx * 4.95 + dx * 3.62, startY + dy * 12 + dy * 2
                ]
            }, {
                type: "Circle",
                params: [
                    startX + dx * 6.56, startY + dy * (12 + 2 + 5.39),
                    3.61
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62, startY + dy * 12 + dy * 2,
                    startX + dx * 4.95 + dx * 3.62, startY + dy * 12
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62, startY + dy * 12,
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88, startY + dy * 12
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88, startY + dy * 12,
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88, startY + dy * 12 + dy * 2,
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88, startY + dy * 12 + dy * 2,
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62, startY + dy * 12 + dy * 2,
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62, startY + dy * 12 + dy * 2,
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62, startY + dy * 12
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62, startY + dy * 12,
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62 + dx * 4.95, startY + dy * 12
                ]
            }, {
                type: "Circle",
                params: [
                    startX + dx * 6.56 + dx * 31.5, startY + dy * (12 + 2 + 5.39),
                    3.61
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62 + dx * 4.95, startY + dy * 12,
                    startX + dx * 4.95 + dx * 3.62 + dx * 27.88 + dx * 3.62 + dx * 4.95, startY
                ]
            });
            if(type === 'close') {
                paths = paths.filter((p) => p.type !== 'Circle');
            }
            return paths;
        },

        width: 4.95 + 3.62 + 27.88 + 3.62 + 4.95,
        height: 12 + 2,
    },
    outer: {
        paths: (startX: number, startY: number, type: boolean | "far" | "close", dx = 1, dy = 1) => {
            dy *= -1;
            if(typeof type === "boolean") {
                type = "far";
            }
            let paths: Path[] = [
                {
                    type: "Line",
                    params: [
                        startX, startY,
                        startX, startY + dy * 1.995
                    ]
                }, {
                    type: "Line",
                    params: [
                        startX, startY + dy * 1.995,
                        startX + dx * 1.92, startY + dy * 1.995

                    ]
                }, {
                    type: "Circle",
                    params: [
                        startX + dx * 1.92 / 2, startY + 32.385 * dy,
                        2.61
                    ]
                }, {
                    type: "Circle",
                    params: [
                        startX + dx * 1.92 / 2 + dx * 15.75, startY + 32.385 * dy,
                        3.11
                    ]
                }, {
                    type: "Circle",
                    params: [
                        startX + dx * 1.92 / 2 + dx * 15.75 + dx * 15.75, startY + 32.385 * dy,
                        2.61
                    ]
                }, {
                    type: "Line",
                    params: [
                        startX + dx * 1.92, startY + dy * 1.995,
                        startX + dx * 1.92, startY,
                    ]
                }, {
                    type: "Line",
                    params: [
                        startX + dx * 1.92, startY,
                        startX + dx * 1.92 + dx * 29.58, startY
                    ]
                }, {
                    type: "Line",
                    params: [
                        startX + dx * 1.92 + dx * 29.58, startY,
                        startX + dx * 1.92 + dx * 29.58, startY + dy * 1.995,
                    ]
                }, {
                    type: "Line",
                    params: [
                        startX + dx * 1.92 + dx * 29.58, startY + dy * 1.995,
                        startX + dx * 1.92 + dx * 29.58 + dx * 1.92, startY + dy * 1.995,
                    ]
                }, {
                    type: "Line",
                    params: [
                        startX + dx * 1.92 + dx * 29.58 + dx * 1.92, startY + dy * 1.995,
                        startX + dx * 1.92 + dx * 29.58 + dx * 1.92, startY,
                    ]
                }
            ];
            if(type === "close") {
                for (let i = 0; i < paths.length; i++) {
                    const path = paths[i];
                    if(path.type === 'Circle') {
                        //console.log("close Circle");
                        
                        path.params[1] -= dy * 17;
                    }
                }
            }
            return paths;
        },
        width: 1.92 + 29.58 + 1.92,
        height: 1.995,
    },
}

interface ExtraConfig {
    middleware?: {
        top?: boolean | "far" | "close",
        left?: boolean | "far" | "close",
        right?: boolean | "far" | "close",
        bottom?: boolean | "far" | "close"
    }
}

export const getBottomLine = (
    type: "inner" | "outer",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    cornerBLW: number,
    cornerBRW: number,
    extraConfig?: ExtraConfig
) => {
    let bottomLine: Path[] = [];
    if (setting.setting.自动增加中间连接件 && extraConfig?.middleware?.bottom) {

        let linePaths: Path[] = [];
        let sx: number = 0;
        let sy: number = 0;
        let ex: number = 0;
        // let ey: number;
        if (type === 'inner') {
            sx = (startX + startX + dx * width) / 2 - middleware.inner.width / 2;
            sy = startY;
            linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.bottom, 1, -1);
            ex = sx + middleware.inner.width;
        } else if (type === "outer") {
            sx = (startX + startX + dx * width) / 2 - middleware.outer.width / 2;
            sy = startY;
            linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.bottom, 1, -1);
            ex = sx + middleware.outer.width;
        }
        bottomLine.push(
            {
                type: Line,
                params: [
                    startX + dx * cornerBLW, startY,
                    sx, startY
                ]
            },
            ...linePaths,
            {
                type: Line,
                params: [
                    ex, startY,
                    startX + dx * width - dx * cornerBRW, startY
                ]
            }
        )
    } else {
        bottomLine.push({
            type: Line,
            params: [
                startX + dx * cornerBLW, startY,
                startX + dx * width - dx * cornerBRW, startY
            ]
        });
    }
    return bottomLine;
}





export const getRightLine = (
    type: "inner" | "outer",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    cornerBRH: number,
    cornerTRH: number,
    extraConfig?: ExtraConfig
) => {

    
    let rightLines: Path[] = [];
    if (setting.setting.自动增加中间连接件 && extraConfig?.middleware?.right) {
        let linePaths: Path[] = [];
        let sx: number = 0;
        let sy: number = 0;
        let ex: number = 0;
        let ey: number = 0;
        if (type === 'inner') {
            sx = startX + dx * width;
            sy = (startY + startY + dy * height) / 2 - middleware.inner.width / 2;;
            linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.right);
            linePaths = pathRotate2(linePaths, sx, sy, -90);
            linePaths = pathMoveY(linePaths, DirectY.UP, middleware.inner.width);
            ey = sy + middleware.inner.width;
        } else if (type === "outer") {
            sx = startX + dx * width;
            sy = (startY + startY + dy * height) / 2 - middleware.outer.width / 2;;
            linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.right);
            linePaths = pathRotate2(linePaths, sx, sy, -90);
            linePaths = pathMoveY(linePaths, DirectY.UP, middleware.outer.width);
            ey = sy + middleware.outer.width;
        }
        rightLines.push(
            {
                type: Line,
                params: [
                    startX + dx * width, startY + dy * cornerBRH,
                    startX + dx * width, sy,
                ]
            },
            ...linePaths,
            {
                type: Line,
                params: [
                    startX + dx * width, ey,
                    startX + dx * width, startY + dy * height - dy * cornerTRH,
                ]
            }
        );
    } else {
        rightLines.push({
            type: Line,
            params: [
                startX + dx * width, startY + dy * cornerBRH,
                startX + dx * width, startY + dy * height - dy * cornerTRH,
            ]
        })
    }
    return rightLines;
}


export const getTopLine = (
    type: "inner" | "outer",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    cornerTRW: number,
    cornerTLW: number,
    extraConfig?: ExtraConfig
) => {

    
    let topLine: Path[] = [];
    if (setting.setting.自动增加中间连接件 && extraConfig?.middleware?.top) {
        let linePaths: Path[] = [];
        let sx: number = 0;
        let sy: number = 0;
        let ex: number = 0;
        // let ey: number;
        if (type === 'inner') {
            sx = (startX + startX + dx * width) / 2 - middleware.inner.width / 2;
            sy = startY + dy * height;
            linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.top);
            ex = sx + middleware.inner.width;
        } else if (type === "outer") {
            sx = (startX + startX + dx * width) / 2 - middleware.outer.width / 2;
            sy = startY + dy * height;
            linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.top);
            ex = sx + middleware.outer.width;
        }
        topLine.push(
            {
                type: Line,
                params: [
                    startX + dx * width - dx * cornerTRW, startY + dy * height,
                    ex, startY + dy * height,

                ]
            },
            ...linePaths,
            {
                type: Line,
                params: [
                    sx, startY + dy * height,
                    startX + dx * cornerTLW, startY + dy * height,

                ]
            }
        );
    } else {
        topLine.push({
            type: Line,
            params: [
                startX + dx * width - dx * cornerTRW, startY + dy * height,
                startX + dx * cornerTLW, startY + dy * height,

            ]
        })
    }
    return topLine;

}
export const getLeftLine = (
    type: "inner" | "outer",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    cornerTLH: number,
    cornerBLH: number,
    extraConfig?: ExtraConfig
) => {

    let leftLine: Path[] = [];
    if (setting.setting.自动增加中间连接件 && extraConfig?.middleware?.left) {
        let linePaths: Path[] = [];
        let sx: number = 0;
        let sy: number = 0;
        // let ex: number;
        let ey: number = 0;
        if (type === 'inner') {
            sx = startX;
            sy = (startY + startY + dy * height) / 2 - middleware.inner.width / 2;;
            linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.left);
            linePaths = pathRotate2(linePaths, sx, sy, 90);
            // linePaths = pathMoveY(linePaths, DirectY.UP, middleware.inner.width);
            ey = sy + middleware.inner.width;
        } else if (type === "outer") {
            sx = startX;
            sy = (startY + startY + dy * height) / 2 - middleware.outer.width / 2;;
            linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.left);
            linePaths = pathRotate2(linePaths, sx, sy, 90);
            // linePaths = pathMoveY(linePaths, DirectY.UP, middleware.outer.width);
            ey = sy + middleware.outer.width;
        }
        leftLine.push(
            {
                type: Line,
                params: [
                    startX, startY + dy * height - dy * cornerTLH,
                    startX, ey
                ]
            },
            ...linePaths,
            {
                type: Line,
                params: [
                    startX, sy,
                    startX, startY + dy * cornerBLH
                ]
            }
        )
    } else {
        leftLine.push({
            type: Line,
            params: [
                startX, startY + dy * height - dy * cornerTLH,
                startX, startY + dy * cornerBLH
            ]
        })
    }
    return leftLine;

}










export const rectBoard = (
    type: "inner" | "outer",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    cornerBLW: number,
    cornerBLH: number,
    cornerBRW: number,
    cornerBRH: number,
    cornerTRW: number,
    cornerTRH: number,
    cornerTLW: number,
    cornerTLH: number,
    extraConfig?: ExtraConfig
) => {
    let bottomLine = getBottomLine(type, dx, dy, startX, startY, width, height, cornerBLW, cornerBRW, extraConfig);
    let rightLie = getRightLine(type, dx, dy, startX, startY, width, height, cornerBRH, cornerTRH, extraConfig);
    let topLine = getTopLine(type, dx, dy, startX, startY, width, height, cornerTRW, cornerTLW, extraConfig);
    let leftLine = getLeftLine(type, dx, dy, startX, startY, width, height, cornerTLH, cornerBLH, extraConfig);
    
    const paths: Path[] = [
        ...bottomLine,
        ...rightLie,
        ...topLine,
        ...leftLine
    ];
    // let bottomLine: Path[] = [];
    // if (extraConfig?.middleware?.bottom) {

    //     let linePaths: Path[] = [];
    //     let sx: number;
    //     let sy: number;
    //     let ex: number;
    //     // let ey: number;
    //     if (type === 'inner') {
    //         sx = (startX + startX + dx * width) / 2 - middleware.inner.width / 2;
    //         sy = startY;
    //         linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.bottom, 1, -1);
    //         ex = sx + middleware.inner.width;
    //     } else if (type === "outer") {
    //         sx = (startX + startX + dx * width) / 2 - middleware.outer.width / 2;
    //         sy = startY;
    //         linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.bottom, 1, -1);
    //         ex = sx + middleware.outer.width;
    //     }
    //     bottomLine.push(
    //         {
    //             type: Line,
    //             params: [
    //                 startX + dx * cornerBLW, startY,
    //                 sx, startY
    //             ]
    //         },
    //         ...linePaths,
    //         {
    //             type: Line,
    //             params: [
    //                 ex, startY,
    //                 startX + dx * width - dx * cornerBRW, startY
    //             ]
    //         }
    //     )
    // } else {
    //     bottomLine.push({
    //         type: Line,
    //         params: [
    //             startX + dx * cornerBLW, startY,
    //             startX + dx * width - dx * cornerBRW, startY
    //         ]
    //     });
    // }
    // let rightLie: Path[] = [];
    // if (extraConfig?.middleware?.right) {
    //     let linePaths: Path[] = [];
    //     let sx: number;
    //     let sy: number;
    //     let ex: number;
    //     let ey: number;
    //     if (type === 'inner') {
    //         sx = startX + dx * width;
    //         sy = (startY + startY + dy * height) / 2 - middleware.inner.width / 2;;
    //         linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.right);
    //         linePaths = pathRotate2(linePaths, sx, sy, -90);
    //         linePaths = pathMoveY(linePaths, DirectY.UP, middleware.inner.width);
    //         ey = sy + middleware.inner.width;
    //     } else if (type === "outer") {
    //         sx = startX + dx * width;
    //         sy = (startY + startY + dy * height) / 2 - middleware.outer.width / 2;;
    //         linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.right);
    //         linePaths = pathRotate2(linePaths, sx, sy, -90);
    //         linePaths = pathMoveY(linePaths, DirectY.UP, middleware.outer.width);
    //         ey = sy + middleware.outer.width;
    //     }
    //     rightLie.push(
    //         {
    //             type: Line,
    //             params: [
    //                 startX + dx * width, startY + dy * cornerBRH,
    //                 startX + dx * width, sy,
    //             ]
    //         },
    //         ...linePaths,
    //         {
    //             type: Line,
    //             params: [
    //                 startX + dx * width, ey,
    //                 startX + dx * width, startY + dy * height - dy * cornerTRH,
    //             ]
    //         }
    //     );
    // } else {
    //     rightLie.push({
    //         type: Line,
    //         params: [
    //             startX + dx * width, startY + dy * cornerBRH,
    //             startX + dx * width, startY + dy * height - dy * cornerTRH,
    //         ]
    //     })
    // }
    // let topLine: Path[] = [];
    // if (extraConfig?.middleware?.top) {
    //     let linePaths: Path[] = [];
    //     let sx: number;
    //     let sy: number;
    //     let ex: number;
    //     // let ey: number;
    //     if (type === 'inner') {
    //         sx = (startX + startX + dx * width) / 2 - middleware.inner.width / 2;
    //         sy = startY + dy * height;
    //         linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.top);
    //         ex = sx + middleware.inner.width;
    //     } else if (type === "outer") {
    //         sx = (startX + startX + dx * width) / 2 - middleware.outer.width / 2;
    //         sy = startY + dy * height;
    //         linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.top);
    //         ex = sx + middleware.outer.width;
    //     }
    //     topLine.push(
    //         {
    //             type: Line,
    //             params: [
    //                 startX + dx * width - dx * cornerTRW, startY + dy * height,
    //                 ex, startY + dy * height,

    //             ]
    //         },
    //         ...linePaths,
    //         {
    //             type: Line,
    //             params: [
    //                 sx, startY + dy * height,
    //                 startX + dx * cornerTLW, startY + dy * height,

    //             ]
    //         }
    //     );
    // } else {
    //     topLine.push({
    //         type: Line,
    //         params: [
    //             startX + dx * width - dx * cornerTRW, startY + dy * height,
    //             startX + dx * cornerTLW, startY + dy * height,

    //         ]
    //     })
    // }

    // let leftLine: Path[] = [];
    // if (extraConfig?.middleware?.left) {
    //     let linePaths: Path[] = [];
    //     let sx: number;
    //     let sy: number;
    //     // let ex: number;
    //     let ey: number;
    //     if (type === 'inner') {
    //         sx = startX;
    //         sy = (startY + startY + dy * height) / 2 - middleware.inner.width / 2;;
    //         linePaths = middleware.inner.paths(sx, sy, extraConfig.middleware.left);
    //         linePaths = pathRotate2(linePaths, sx, sy, 90);
    //         // linePaths = pathMoveY(linePaths, DirectY.UP, middleware.inner.width);
    //         ey = sy + middleware.inner.width;
    //     } else if (type === "outer") {
    //         sx = startX;
    //         sy = (startY + startY + dy * height) / 2 - middleware.outer.width / 2;;
    //         linePaths = middleware.outer.paths(sx, sy, extraConfig.middleware.left);
    //         linePaths = pathRotate2(linePaths, sx, sy, 90);
    //         // linePaths = pathMoveY(linePaths, DirectY.UP, middleware.outer.width);
    //         ey = sy + middleware.outer.width;
    //     }
    //     leftLine.push(
    //         {
    //             type: Line,
    //             params: [
    //                 startX, startY + dy * height - dy * cornerTLH,
    //                 startX, ey
    //             ]
    //         },
    //         ...linePaths,
    //         {
    //             type: Line,
    //             params: [
    //                 startX, sy,
    //                 startX, startY + dy * cornerBLH
    //             ]
    //         }
    //     )
    // } else {
    //     leftLine.push({
    //         type: Line,
    //         params: [
    //             startX, startY + dy * height - dy * cornerTLH,
    //             startX, startY + dy * cornerBLH
    //         ]
    //     })
    // }


    // const paths: Path[] = [
    //     ...bottomLine,
    //     ...rightLie,
    //     ...topLine,
    //     ...leftLine
    // ];
    return paths;
}