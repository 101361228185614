import { Arc, Circle, Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { degreeTransformForEach } from "../../util";

export const corner2 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {

    let paths: Path[] = [{
        type: Line,
        params: [
            startX, startY,
            startX + dx * 1.995, startY,
        ],
    }, {
        type: Line,
        params: [
            startX + dx * 1.995, startY,
            startX + dx * 1.995, startY + dy * 1.92,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2,
            2.61,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2 + dy * 15.75,
            3.11,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2 + dy * 15.75 + dy * 15.75,
            2.61,
        ]
    }, {

        type: Line,
        params: [
            startX + dx * 1.995, startY + dy * 1.92,
            startX, startY + dy * 1.92,
        ]
    },
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92,
            startX, startY + dy * 1.92 + dy * 29.58,
        ]
    }, {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58,
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58,
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
        ]
    }, 
    // {
    //     type: Line,
    //     params: [
    //         startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
    //         startX, startY + dy * 1.92 + dy * 29.58,
    //     ]
    // },
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
        ]
    }, {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2,
            0.2,
            270,
            360,
            // ...degreeTransform(270, 360, dx, dy),
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.8 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2,
            startX + dx * 1.8 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75,
            0.2,
            0,
            90,
            // ...degreeTransform(0, 90, dx, dy),
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
        ]
    }, 
    // {
    //     type: Line,
    //     params: [
    //         startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
    //         startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
    //     ]
    // },
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45,

        ]
    }, {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45,
            startX + dx * 4.84055, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 4.84055 + dx * 0.141421, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421,
            0.2,
            90,
            135
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 0.141421, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2,
            startX + dx * 4.84055 + dx * 0.141421 + dx * 20.318026, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 4.84055 + dx * 0.141421 + dx * 20.318026, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2,
            0.2,
            270,
            360
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 0.141421 + dx * 20.318026 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2,
            startX + dx * 4.84055 + dx * 0.141421 + dx * 20.318026 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2 + dy * 20.317193,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 4.84055 + dx * 0.141421 + dx * 20.318026 + dx * 0.2 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 - dy * 0.141421 + dy * 0.2 + dy * 0.2 + dy * 20.317193,
            0.2,
            135,
            180,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193,
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
            0.2,
            180,
            270,
            // ...degreeTransform(180, 270, dx, dy),
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8 - dy * 0.2,
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8 - dy * 0.2,

        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
            0.2,
            270,
            360,
            // ...degreeTransform(270, 360, dx, dy),
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055 - dy * 1.8,
            startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
        ]
    }
    // , {

    //     type: Line,
    //     params: [
    //         startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,

    //         startX + dx * 4.84055 + dx * 20.318026 + dx * 0.2 + dx * 0.2 + dx * 4.84055 + dx * 1.451209 + dx * 0.2 + dx * 1.75 + dx * 0.2 - dx * 2.15, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.45 + dy * 4.84055 + dy * 0.2 + dy * 0.2 + dy * 20.317193 + dy * 4.84055,
    //     ]
    // }
    ];
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = 34.00035;
            sizeRes.height = 77.488293;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);

}