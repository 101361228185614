import { DirectX, DirectY } from "../../../constants/enum";
import { _05beiwaiBoard } from "./05-beiwai";

export const _05zhuanjiaowaiXBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return _05beiwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner, false, {
        noMiddleware: true
    });
}