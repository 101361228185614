import { DirectX, DirectY } from "../../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../../util/graph";
import { corner12Sanheyi } from "../../../coners/三合一/corner12-sanheyi";
import { corner13Sanheyi } from "../../../coners/三合一/corner13-sanheyi";
import { rectBoard } from "../../general-board";

export const sanheyi02DiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];//= corner13(dx, dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner13Sanheyi(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }
    
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    let cornerBR: Path[] = [];// = corner13(dx, dy, startX, startY, corner, cornerBRSize);
    if(cornerType === "youhujiao") {
        cornerBR = corner13Sanheyi(-dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        // cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    }
    // cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = []; // = corner12(dx, dy, startX, startY, corner, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner12Sanheyi(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        // cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
    }

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];// = corner12(dx, dy, startX, startY, corner, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner12Sanheyi(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
    }
    
    let paths;
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0);
    }
    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}