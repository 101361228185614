import WindowFrame from "../../components/WindowFrame";

export default function Settings() {

    return (
        <WindowFrame
        
        >
            设置页
        </WindowFrame>
    )

}