import { Layer, Rect, Transformer } from "react-konva"
import { useOnWallEdit } from "./store";
import { useEffect, useMemo, useRef, useState } from "react";
import { ShapeOnWall } from "../$shapes/shape-onwall";
import { CANVAS_SIZE, m2px } from "../../../store";
import { Group } from "konva/lib/Group";
import { Vector2d } from "konva/lib/types";
import { KonvaEventObject } from "konva/lib/Node";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { onWallX, onWallY, singleDoorOnX, singleDoorOnY } from "../../../store/onwall-store";
import { Html } from "react-konva-utils";

export const LayerEditOnWall = (props: {

}) => {

    const { targetData: editData, updateDrawed, cancelDraw } = useOnWallEdit();

    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        cancelDraw();
    }
    const transformerRef = useRef<any>();

    const onSetShapeRef = (shapeRef: any) => {
        if (shapeRef) {
            transformerRef.current?.nodes([shapeRef]);
            // transformerRef.current.getLayer().batchDraw();
        }

    }

    const [isDanger, setIsDanger] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const onWallRef = useRef<any>();

    const [curPos, setCurPos] = useState<Vector2d>(null);
    const [curOnWallWidth, setCurOnWallWidth] = useState<number>(null);

    useEffect(() => {
        if (!editData) {
            setDimension(null);
            setCurPos(null);
            setCurOnWallWidth(null);
            return;
        }
        if (editData.openDirect === 'left' || editData.openDirect === 'right') {
            setCurPos({
                x: editData.wall.from.x,
                y: editData.anchor,
            });

        } else {
            setCurPos({
                x: editData.anchor,
                y: editData.wall.from.y,
            });
        }
        setCurOnWallWidth(editData.onWallWidth)
    }, [editData]);


    type Dimension = {
        direct: "x" | "y",
        d1From: number,
        d1To: number,
        d2From: number,
        d2To: number,
        base: number
    }
    const [dimension, setDimension] = useState<Dimension>(null);

    useEffect(() => {
        if (!editData || !curPos) {
            return;
        }

        let direct: "x" | "y";
        let d1From: number;
        let d1To: number;
        let d2From: number;
        let d2To: number;
        let base: number;

        const onWallThickness = editData.onWallThickness;
        const onWallWidth = editData.onWallWidth;

        if (editData.openDirect === 'left' || editData.openDirect === 'right') {
            direct = 'y';
            base = editData.wall.from.x;
            if (editData.wall.from.y < editData.wall.to.y) {
                d1From = editData.wall.from.y + onWallThickness / 2;
                d1To = curPos.y - onWallWidth / 2;
                d2From = curPos.y + onWallWidth / 2;
                d2To = editData.wall.to.y - onWallThickness / 2;
            } else {
                d1From = editData.wall.to.y + onWallThickness / 2;
                d1To = curPos.y - onWallWidth / 2;
                d2From = curPos.y + onWallWidth / 2;
                d2To = editData.wall.from.y - onWallThickness / 2;
            }
        } else {
            direct = 'x';
            base = editData.wall.from.y;
            if (editData.wall.from.x < editData.wall.to.x) {
                d1From = editData.wall.from.x + onWallThickness / 2;
                d1To = curPos.x - onWallWidth / 2;
                d2From = curPos.x + onWallWidth / 2;
                d2To = editData.wall.to.x - onWallThickness / 2;
            } else {
                d1From = editData.wall.to.x + onWallThickness / 2;
                d1To = curPos.x - onWallWidth / 2;
                d2From = curPos.x + onWallWidth / 2;
                d2To = editData.wall.from.x - onWallThickness / 2;
            }
        }
        setDimension({
            direct,
            d1From,
            d1To,
            d2From,
            d2To,
            base
        });
    }, [editData, curPos])

    const onUpdateD1Offset = (offset: number) => {
        if (!editData || !dimension) {
            return;
        }
        const { d1From } = dimension;
        let newAnchor: number;
        const length = offset;
        const onWallWidth = editData.onWallWidth;

        newAnchor = d1From + length + onWallWidth / 2;
        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                anchor: newAnchor
            }
        });


    }

    const onUpdateD2Offset = (offset: number) => {
        if (!editData || !dimension) {
            return;
        }
        const { d2To } = dimension;
        let newAnchor: number;
        const length = offset;
        const onWallWidth = editData.onWallWidth;

        newAnchor = d2To - length - onWallWidth / 2;
        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                anchor: newAnchor
            }
        });
    }


    const onUpdateOnWallWidth = (width: number) => {

        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                onWallWidth: width,
            }
        });
    }

    const onUpdateOnWallHeight = (height: number) => {

        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                onWallHeight: height
            }
        })
    }

    const onUpdateGroundClearance = (clearance: number) => {
        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                groundClearance: clearance
            }
        })
    }

    const onUpdateOpenDirect = (direct: "top" | "bottom" | "left" | "right") => {
        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                openDirect: direct
            }
        })
    }

    const onUpdateOpenClockwise = (clockwise: boolean) => {
        updateDrawed({
            type: editData.onWallType,
            data: {
                ...editData,
                openClockwise: clockwise
            }
        })
    }

    const [boundSize, setBoundSize] = useState(0);

    useEffect(() => {
        if (boundSize) {
            setCurOnWallWidth(boundSize);
        }
    }, [boundSize])

    return (
        <>
            <Layer
                visible={Boolean(editData)}
            >
                <Rect
                    x={0}
                    y={0}
                    width={CANVAS_SIZE * m2px}
                    height={CANVAS_SIZE * m2px}
                    onClick={onClick}
                    listening={false}
                />

                {
                    useMemo(() => {
                        if (!editData || !curPos) {
                            return;
                        }
                        return (
                            <ShapeOnWall
                                ref={onWallRef}
                                setShapeRef={onSetShapeRef}
                                draggable
                                {...editData}

                                anchor={editData.openDirect === 'left' || editData.openDirect === 'right' ? curPos.y : curPos.x}
                                onDragStart={(e) => {
                                    setIsDragging(true);
                                }}
                                onDragMove={(e) => {
                                    if (editData.openDirect === 'left' || editData.openDirect === 'right') {
                                        e.currentTarget.x(editData.wall.from.x);
                                        setCurPos({
                                            x: editData.wall.from.x,
                                            y: e.target.y(),
                                        });
                                    } else {
                                        e.currentTarget.y(editData.wall.from.y);
                                        setCurPos({
                                            x: e.target.x(),
                                            y: editData.wall.from.y,
                                        });
                                    }
                                }}
                                onDragEnd={(e) => {
                                    setIsDragging(false);
                                    updateDrawed({
                                        type: editData.onWallType,
                                        data: {
                                            ...editData,
                                            anchor: editData.openDirect === 'left' || editData.openDirect === 'right' ? curPos.y : curPos.x,
                                        }
                                    })
                                }}
                                doorStyle={{ stroke: isDanger ? "pink" : "cyan" }}
                                frameStyle={{ stroke: isDanger ? "pink" : "cyan" }}
                            />
                        )
                    }, [editData, curPos, isDanger])
                }

                <Transformer
                    ref={transformerRef}
                    rotateEnabled={false}
                    borderEnabled={false}
                    flipEnabled={false}
                    ignoreStroke
                    anchorSize={6}
                    anchorCornerRadius={6}
                    anchorStroke={isDanger ? 'pink' : 'cyan'}
                    anchorStrokeWidth={1}
                    anchorFill="#0000ff11"
                    enabledAnchors={
                        editData?.openDirect === 'left' || editData?.openDirect === 'right' ?
                            ["top-center", "bottom-center"] :
                            ["middle-left", "middle-right"]
                    }
                    centeredScaling
                    onTransformStart={(e) => {
                        setIsDragging(true);
                    }}
                    onTransform={(e) => {
                        const group: Group = transformerRef.current?.nodes()[0];
                        if (!group) {
                            return;
                        }
                        const door: Group = onWallRef.current?.getDoorRef();
                        if (!door) {
                            return;
                        }

                        if (editData?.openDirect === 'left' || editData?.openDirect === 'right') {
                            group.setAttrs({
                                scaleX: 1,
                                scaleY: group.getAttr("scaleY")
                            });
                            door.setAttrs({
                                scaleX: group.getAttr("scaleY"),
                                scaleY: group.getAttr("scaleY"),

                            });
                            setBoundSize(e.currentTarget.height());
                        } else {
                            group.setAttrs({
                                scaleX: group.getAttr("scaleX"),
                                scaleY: 1
                            });
                            door.setAttrs({
                                scaleX: group.getAttr("scaleX"),
                                scaleY: group.getAttr("scaleX"),
                            });
                            setBoundSize(e.currentTarget.width());
                        }
                    }}
                    onTransformEnd={(e) => {

                        if (!isDanger) {
                            let onWallWidth: number;
                            if (editData.openDirect === 'left' || editData.openDirect === 'right') {
                                onWallWidth = e.currentTarget.height();
                            } else {
                                onWallWidth = e.currentTarget.width();
                            }
                            onUpdateOnWallWidth(onWallWidth);
                        }

                        const group: Group = transformerRef.current?.nodes()[0];
                        if (group) {
                            group.setAttrs({
                                scaleX: 1,
                                scaleY: 1,
                            })
                        }
                        const door: Group = onWallRef.current?.getDoorRef();
                        if (door) {
                            door.setAttrs({
                                scaleX: 1,
                                scaleY: 1,
                            });
                        }
                        transformerRef.current?.nodes()[0]?.setAttrs({
                            scaleX: 1,
                            scaleY: 1,
                        })
                        setBoundSize(0)
                        setIsDragging(false);
                    }}

                />


                {
                    // helper 部分
                    useMemo(() => {
                        if (!editData || !dimension) {
                            return;
                        }
                        const { direct, base, d1From, d1To, d2From, d2To } = dimension;
                        return (
                            <>
                                <ShapeDimension
                                    direct={direct}
                                    base={base}
                                    offset={0}
                                    from={d1From}
                                    to={d1To}
                                    color="yellow"
                                    canInput
                                    onLengthSet={(length) => {
                                        onUpdateD1Offset(length);
                                    }}

                                />
                                <ShapeDimension
                                    direct={direct}
                                    base={base}
                                    offset={0}
                                    from={d2From}
                                    to={d2To}
                                    canInput
                                    color="yellow"
                                    onLengthSet={(length) => {
                                        onUpdateD2Offset(length)
                                    }}
                                />
                            </>
                        )
                    }, [dimension, editData])
                }
            </Layer>
            <Layer>
                {
                    editData && dimension && (
                        <Html transform={false}>
                            {
                                editData.onWallType === 'onwall-single-door' ? (
                                    (editData.openDirect === 'top' || editData.openDirect === 'bottom') ? (
                                        <SingleDoorOnX
                                            d1={Math.abs(dimension.d1To - dimension.d1From)}
                                            d2={Math.abs(dimension.d2To - dimension.d2From)}
                                            onWallWidth={curOnWallWidth}
                                            onWallHeight={editData.onWallHeight}
                                            groundClearance={editData.groundClearance}
                                            openDirect={editData.openDirect}
                                            openClockwise={editData.openClockwise || false}
                                            onD1OffsetChange={(offset) => {
                                                onUpdateD1Offset(offset);
                                            }}
                                            onD2OffsetChange={(offset) => {
                                                onUpdateD2Offset(offset)
                                            }}
                                            onOnWallWidthChange={(width) => {
                                                onUpdateOnWallWidth(width);
                                            }}
                                            onOnWallHeightChange={(height) => {
                                                onUpdateOnWallHeight(height);
                                            }}
                                            onGroundClearanceChange={(clearance) => {
                                                onUpdateGroundClearance(clearance);
                                            }}
                                            onOpenDirectChange={(direct) => {
                                                onUpdateOpenDirect(direct);
                                            }}
                                            onOpenClockwiseChange={(clockwise) => {
                                                onUpdateOpenClockwise(clockwise)
                                            }}

                                        />

                                    ) : (
                                        <SingleDoorOnY
                                            d1={Math.abs(dimension.d1To - dimension.d1From)}
                                            d2={Math.abs(dimension.d2To - dimension.d2From)}
                                            onWallWidth={curOnWallWidth}
                                            onWallHeight={editData.onWallHeight}
                                            groundClearance={editData.groundClearance}
                                            openDirect={editData.openDirect}
                                            openClockwise={editData.openClockwise || false}
                                            onD1OffsetChange={(offset) => {
                                                onUpdateD1Offset(offset);
                                            }}
                                            onD2OffsetChange={(offset) => {
                                                onUpdateD2Offset(offset)
                                            }}
                                            onOnWallWidthChange={(width) => {
                                                onUpdateOnWallWidth(width);
                                            }}
                                            onOnWallHeightChange={(height) => {
                                                onUpdateOnWallHeight(height);
                                            }}
                                            onGroundClearanceChange={(clearance) => {
                                                onUpdateGroundClearance(clearance);
                                            }}
                                            onOpenDirectChange={(direct) => {
                                                onUpdateOpenDirect(direct);
                                            }}
                                            onOpenClockwiseChange={(clockwise) => {
                                                onUpdateOpenClockwise(clockwise)
                                            }}


                                        />
                                    )
                                ) : (
                                    (editData.openDirect === 'top' || editData.openDirect === 'bottom') ? (
                                        <OnWallOnX
                                            d1={Math.abs(dimension.d1To - dimension.d1From)}
                                            d2={Math.abs(dimension.d2To - dimension.d2From)}
                                            onWallWidth={curOnWallWidth}
                                            onWallHeight={editData.onWallHeight}
                                            groundClearance={editData.groundClearance}
                                            onD1OffsetChange={(offset) => {
                                                onUpdateD1Offset(offset);
                                            }}
                                            onD2OffsetChange={(offset) => {
                                                onUpdateD2Offset(offset)
                                            }}
                                            onOnWallWidthChange={(width) => {
                                                onUpdateOnWallWidth(width);
                                            }}
                                            onOnWallHeightChange={(height) => {
                                                onUpdateOnWallHeight(height);
                                            }}
                                            onGroundClearanceChange={(clearance) => {
                                                onUpdateGroundClearance(clearance);
                                            }}

                                        />
                                    ) : (
                                        <OnWallOnY
                                            d1={Math.abs(dimension.d1To - dimension.d1From)}
                                            d2={Math.abs(dimension.d2To - dimension.d2From)}
                                            onWallWidth={curOnWallWidth}
                                            onWallHeight={editData.onWallHeight}
                                            groundClearance={editData.groundClearance}
                                            onD1OffsetChange={(offset) => {
                                                onUpdateD1Offset(offset);
                                            }}
                                            onD2OffsetChange={(offset) => {
                                                onUpdateD2Offset(offset)
                                            }}
                                            onOnWallWidthChange={(width) => {
                                                onUpdateOnWallWidth(width);
                                            }}
                                            onOnWallHeightChange={(height) => {
                                                onUpdateOnWallHeight(height);
                                            }}
                                            onGroundClearanceChange={(clearance) => {
                                                onUpdateGroundClearance(clearance);
                                            }}

                                        />
                                    )

                                )

                            }

                        </Html>

                    )
                }
            </Layer>
        </>
    )

}


const SingleDoorOnX = (props: {
    d1: number,
    d2: number,
    onWallWidth: number,
    onWallHeight: number,
    groundClearance: number,
    openDirect: "top" | "bottom",
    openClockwise: boolean,

    onD1OffsetChange: (offset: number) => void;
    onD2OffsetChange: (offset: number) => void;
    onOnWallWidthChange: (width: number) => void;
    onOnWallHeightChange: (height: number) => void;
    onGroundClearanceChange: (clearance: number) => void;
    onOpenDirectChange: (direct: "top" | "bottom") => void;
    onOpenClockwiseChange: (clockwise: boolean) => void;
}) => {


    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(singleDoorOnX.updateStoreBatch({
        d1: props.d1,
        d2: props.d2,
        onWallWidth: props.onWallWidth,
        onWallHeight: props.onWallHeight,
        groundClearance: props.groundClearance,
        openDirect: props.openDirect,
        openClockwise: props.openClockwise,
    }));

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== singleDoorOnX.$getStore()) {
            singleDoorOnX.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        setStore({
            d1: props.d1,
            d2: props.d2,
            onWallWidth: props.onWallWidth,
            onWallHeight: props.onWallHeight,
            groundClearance: props.groundClearance,
            openDirect: props.openDirect,
            openClockwise: props.openClockwise,
        });
    }, [init, props.d1, props.d2, props.onWallWidth, props.onWallHeight, props.groundClearance, props.openDirect, props.openClockwise]);

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{singleDoorOnX.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    singleDoorOnX.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {

                                if (typeof newStore[key] === 'number' ? Math.abs(newStore[key] - store[key]) > 0.1 : newStore[key] !== store[key]) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'd1') {
                                        props.onD1OffsetChange(newStore[key]);
                                    } else if (key === 'd2') {
                                        props.onD2OffsetChange(newStore[key]);
                                    } else if (key === 'onWallWidth') {
                                        props.onOnWallWidthChange(newStore[key]);
                                    } else if (key === 'onWallHeight') {
                                        props.onOnWallHeightChange(newStore[key]);
                                    } else if (key === 'groundClearance') {
                                        props.onGroundClearanceChange(newStore[key]);
                                    } else if (key === 'openDirect') {
                                        props.onOpenDirectChange(newStore[key]);
                                    } else if (key === 'openClockwise') {
                                        props.onOpenClockwiseChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}



const SingleDoorOnY = (props: {
    d1: number,
    d2: number,
    onWallWidth: number,
    onWallHeight: number,
    groundClearance: number,
    openDirect: "left" | "right",
    openClockwise: boolean,

    onD1OffsetChange: (offset: number) => void;
    onD2OffsetChange: (offset: number) => void;
    onOnWallWidthChange: (width: number) => void;
    onOnWallHeightChange: (height: number) => void;
    onGroundClearanceChange: (clearance: number) => void;
    onOpenDirectChange: (direct: "left" | "right") => void;
    onOpenClockwiseChange: (clockwise: boolean) => void;
}) => {


    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(singleDoorOnY.updateStoreBatch({
        d1: props.d1,
        d2: props.d2,
        onWallWidth: props.onWallWidth,
        onWallHeight: props.onWallHeight,
        groundClearance: props.groundClearance,
        openDirect: props.openDirect,
        openClockwise: props.openClockwise,
    }));

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== singleDoorOnY.$getStore()) {
            singleDoorOnY.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        setStore({
            d1: props.d1,
            d2: props.d2,
            onWallWidth: props.onWallWidth,
            onWallHeight: props.onWallHeight,
            groundClearance: props.groundClearance,
            openDirect: props.openDirect,
            openClockwise: props.openClockwise,
        });
    }, [init, props.d1, props.d2, props.onWallWidth, props.onWallHeight, props.groundClearance, props.openDirect, props.openClockwise]);

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{singleDoorOnY.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    singleDoorOnY.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {

                                if (typeof newStore[key] === 'number' ? Math.abs(newStore[key] - store[key]) > 0.1 : newStore[key] !== store[key]) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'd1') {
                                        props.onD1OffsetChange(newStore[key]);
                                    } else if (key === 'd2') {
                                        props.onD2OffsetChange(newStore[key]);
                                    } else if (key === 'onWallWidth') {
                                        props.onOnWallWidthChange(newStore[key]);
                                    } else if (key === 'onWallHeight') {
                                        props.onOnWallHeightChange(newStore[key]);
                                    } else if (key === 'groundClearance') {
                                        props.onGroundClearanceChange(newStore[key]);
                                    } else if (key === 'openDirect') {
                                        props.onOpenDirectChange(newStore[key]);
                                    } else if (key === 'openClockwise') {
                                        props.onOpenClockwiseChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}


const OnWallOnY = (props: {
    d1: number,
    d2: number,
    onWallWidth: number,
    onWallHeight: number,
    groundClearance: number,
    onD1OffsetChange: (offset: number) => void;
    onD2OffsetChange: (offset: number) => void;
    onOnWallWidthChange: (width: number) => void;
    onOnWallHeightChange: (height: number) => void;
    onGroundClearanceChange: (clearance: number) => void;
}) => {


    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(onWallY.updateStoreBatch({
        d1: props.d1,
        d2: props.d2,
        onWallWidth: props.onWallWidth,
        onWallHeight: props.onWallHeight,
        groundClearance: props.groundClearance,
    }));

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== onWallY.$getStore()) {
            onWallY.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        setStore({
            d1: props.d1,
            d2: props.d2,
            onWallWidth: props.onWallWidth,
            onWallHeight: props.onWallHeight,
            groundClearance: props.groundClearance,
        });
    }, [init, props.d1, props.d2, props.onWallWidth, props.onWallHeight, props.groundClearance]);

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{onWallY.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    onWallY.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {

                                if (typeof newStore[key] === 'number' ? Math.abs(newStore[key] - store[key]) > 0.1 : newStore[key] !== store[key]) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'd1') {
                                        props.onD1OffsetChange(newStore[key]);
                                    } else if (key === 'd2') {
                                        props.onD2OffsetChange(newStore[key]);
                                    } else if (key === 'onWallWidth') {
                                        props.onOnWallWidthChange(newStore[key]);
                                    } else if (key === 'onWallHeight') {
                                        props.onOnWallHeightChange(newStore[key]);
                                    } else if (key === 'groundClearance') {
                                        props.onGroundClearanceChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}


const OnWallOnX = (props: {
    d1: number,
    d2: number,
    onWallWidth: number,
    onWallHeight: number,
    groundClearance: number,
    onD1OffsetChange: (offset: number) => void;
    onD2OffsetChange: (offset: number) => void;
    onOnWallWidthChange: (width: number) => void;
    onOnWallHeightChange: (height: number) => void;
    onGroundClearanceChange: (clearance: number) => void;
}) => {


    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(onWallX.updateStoreBatch({
        d1: props.d1,
        d2: props.d2,
        onWallWidth: props.onWallWidth,
        onWallHeight: props.onWallHeight,
        groundClearance: props.groundClearance,
    }));

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== onWallX.$getStore()) {
            onWallX.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        setStore({
            d1: props.d1,
            d2: props.d2,
            onWallWidth: props.onWallWidth,
            onWallHeight: props.onWallHeight,
            groundClearance: props.groundClearance,
        });
    }, [init, props.d1, props.d2, props.onWallWidth, props.onWallHeight, props.groundClearance]);

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{onWallX.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    onWallX.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {

                                if (typeof newStore[key] === 'number' ? Math.abs(newStore[key] - store[key]) > 0.1 : newStore[key] !== store[key]) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'd1') {
                                        props.onD1OffsetChange(newStore[key]);
                                    } else if (key === 'd2') {
                                        props.onD2OffsetChange(newStore[key]);
                                    } else if (key === 'onWallWidth') {
                                        props.onOnWallWidthChange(newStore[key]);
                                    } else if (key === 'onWallHeight') {
                                        props.onOnWallHeightChange(newStore[key]);
                                    } else if (key === 'groundClearance') {
                                        props.onGroundClearanceChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}