import { Circle, Group, Rect, Transformer } from "react-konva";
import { DrawStructType, StructData } from "../../../store";
import { useEffect, useRef, useState } from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";



const defaultStyle = {
    stroke: "#000",
    fill: "#ffffff",
    strokeWidth: 1,
    strokeScaleEnabled: false,
    opacity: 1,
};

interface Props extends StructData {
    draggable?: boolean;
    selectable?: boolean;
    selected?: boolean;

    onSelectChange?: (selected: boolean) => void;
    style?: Partial<typeof defaultStyle>;
    setShapeRef?: (ref: any) => void;

    onDragStart?: (e: KonvaEventObject<DragEvent>) => void;
    onDragMove?: (e: KonvaEventObject<DragEvent>) => void;
    onDragEnd?: (e: KonvaEventObject<DragEvent>) => void;

    onMouseOver?: (e: KonvaEventObject<MouseEvent>) => void;
    onMouseLeave?: (e: KonvaEventObject<MouseEvent>) => void;
    onMouseUp?: (e: KonvaEventObject<MouseEvent>) => void;
    onMouseDown?: (e: KonvaEventObject<MouseEvent>) => void;
    // dragBoundFunc?: (pos: Vector2d) => Vector2d
}

export const ShapeStruct = (props: Props) => {

    const [isOver, setIsOver] = useState(false);
    const [isSelected, setIsSelected] = useState(props.selected || false);
    const [style, setStyle] = useState(Object.assign({}, defaultStyle, props.style || {}));

    useEffect(() => {
        if (isSelected) {
            setStyle({
                stroke: "#ffffff",
                fill: "#ffffffaa",
                strokeWidth: 1,
                opacity: 0.4,
                strokeScaleEnabled: false
            });
        } else if (isOver) {
            setStyle({
                stroke: "cyan",
                fill: "#ffffff",
                strokeWidth: 1,
                strokeScaleEnabled: false,
                opacity: 1
            });
        } else {
            setStyle(Object.assign({}, defaultStyle, props.style || {}));
        }
    }, [isSelected, isOver, props.style]);

    useEffect(() => {
        setIsSelected(props.selected || false);
    }, [props.selected]);

    const onSelectChange = (targetSelected: boolean) => {
        setIsSelected(targetSelected);
        props.onSelectChange && props.onSelectChange(targetSelected);
    }

    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        if (!props.selectable) {
            return;
        }
        if (e.evt.button !== 0) {
            return;
        }
        const targetSelected = !isSelected;
        onSelectChange(targetSelected);
    }

    return (
        <Group
            x={props.pos.x}
            y={props.pos.y}
            draggable={props.draggable}
            onClick={onClick}
            onMouseOver={(e) => {
                props.onMouseOver && props.onMouseOver(e);
                props.selectable && !isSelected && setIsOver(true)
            }}
            onMouseLeave={(e) => {
                props.onMouseLeave && props.onMouseLeave(e);
                setIsOver(false)
            }}
            onMouseUp={props.onMouseUp}
            onMouseDown={props.onMouseDown}
            onDragStart={props.onDragStart}
            onDragMove={props.onDragMove}
            onDragEnd={props.onDragEnd}
            ref={props.setShapeRef}


        >
            {
                props.structType === "struct-round-post" && (
                    <Circle
                        radius={props.size.x / 2}
                        width={props.size.x}
                        height={props.size.y}
                        {...style}
                    />
                )
            }
            {
                props.structType === "struct-rect-post" && (
                    <Rect
                        // ref={props.setShapeRef}
                        x={-props.size.x / 2}
                        y={-props.size.y / 2}
                        width={props.size.x}
                        height={props.size.y}
                        {...style}
                    />
                )
            }
            {
                props.structType.indexOf("struct-beam") === 0 && (
                    <Rect
                        // ref={props.setShapeRef}
                        width={props.size.x}
                        height={props.size.y}
                        x={-props.size.x / 2}
                        y={-props.size.y / 2}
                        {...style}
                        opacity={0.6}
                    />
                )
            }

        </Group>
    )
}