import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1Sanheyi } from "./corner1-sanheyi";

export const corner9Sanheyi = (_dx: DirectX, _dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const dx = 1;
    const dy = 1;
    const corner1PathsSize = {
        height: 0,
        width: 0
    };
    const corner1Paths = corner1Sanheyi(dx, -dy, startX, startY, true, corner1PathsSize);
    let paths = corner1Paths.slice(corner1Paths.length - 13, corner1Paths.length);
    // paths = pathMirrorY(paths, startX);
    // paths = pathMoveX(paths, DirectX.LEFT, dx * 15.01);
    let width = 17.855654;
    let height = 14.99;
    paths = pathMoveY(paths, DirectY.UP, dy * (corner1PathsSize.height - height + height));
    paths = pathMoveX(paths, DirectX.LEFT, dx * (corner1PathsSize.width - width));
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
        paths = pathMoveY(paths, DirectY.UP, dy * height);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);
        paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
        paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
        paths = pathMoveY(paths, DirectY.UP, dy * height);
    }
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}


//const corner1Paths = corner1(dx, dy, startX, startY);
//    let paths1 = corner1Paths.slice(corner1Paths.length - 13, corner1Paths.length);