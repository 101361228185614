import { Stage } from "konva/lib/Stage";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Action, State, actionMap, initialFloorPlannerData } from "./store";



type Dispatch = (action: Action) => void;

export const floorPlannerReducer = (
    state: State, 
    action: Action
): State => {
    return actionMap[action.type](state, action.payload);
}

export const FloorPlannerContext = createContext<{ state: State; dispatch: Dispatch }>({
    state: initialFloorPlannerData,
    dispatch: () => {}
});

