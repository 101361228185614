import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach } from "../../../util";
import {  pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { 无护角有卡槽 } from "./config";
import { corner12WaiWuhujiao } from "./corner12-wai-wuhujiao";


export const corner15WaiWuhujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner12WaiWuhujiao(dx, dy, startX, startY, true, size);
    if(无护角有卡槽) {
        paths = paths.slice(0, paths.length - 11);
    }else {
        paths =  paths.slice(0, paths.length - 1);
    }
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, size.width);
    paths = pathMoveY(paths, DirectY.DOWN, 43.49);
    if(typeof sizeRes === 'object') {
        sizeRes.width = 77.620141;
        sizeRes.height = 34.25022;
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);
}