import { Circle, Line } from "../../../../constants";
import { DirectX, DirectY } from "../../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../../util/graph";
import { corner1Sanheyi } from "../../../coners/三合一/corner1-sanheyi";
import { corner10Sanheyi } from "../../../coners/三合一/corner10-sanheyi";
import { rectBoard } from "../../general-board";

// export const 
export const sanheyi02CeneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner10Sanheyi(dx, dy, startX, startY, true, cornerBLSize);
    cornerBL = pathRotate2(cornerBL, startX, startY, 90)
    cornerBL = pathMirrorY(cornerBL, startX);
    cornerBL = pathRotate2(cornerBL, startX, startY, -90);
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    // cornerBL.push({
    //     type: Line,
    //     params: [0, 689.000002, 10000, 689.000002]
    // }, {
    //     type: Line,
    //     params: [3243.995, 0, 3243.995, 1000]
    // }, {
    //     type: Line,
    //     params: [3232, 0, 3232, 1000]
    // }, {
    //     type: Line,
    
    //     params: [0, 701, 10000, 701]
    // })
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner1Sanheyi(-dx, dy, startX, startY,true,  cornerBRSize);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;
    // cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathRotate2(cornerBR, startX, startY, 90);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRSize.height);
    cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.width);
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner1Sanheyi(dx, -dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.height;
    let cornerTRH: number = cornerTRSize.width;
    cornerTR = pathRotate2(cornerTR, startX, startY, 90);
    // cornerTR = pathMirrorY(cornerTR, startX);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height)

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner10Sanheyi(dx, dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    
    const paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);

    if(lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];
}