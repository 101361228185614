import { menneiBoard } from "../../boards/地柜/01-mennei";
import { menwaiBoard } from "../../boards/地柜/01-menwai";
import { _12AbeineiBoard } from "../../boards/吊柜/12-a-beinei";
import { _12AbeiwaiBoard } from "../../boards/吊柜/12-a-beiwai";
import { _12AceneiBoardLeft } from "../../boards/吊柜/12-a-cenei-left";
import { _12AceneiBoardMiddle } from "../../boards/吊柜/12-a-cenei-middle";
import { _12AceneiBoardRight } from "../../boards/吊柜/12-a-cenei-right";
import { _12AcewaiBoardLeft } from "../../boards/吊柜/12-a-cewai-left";
import { _12AcewaiBoardMiddle } from "../../boards/吊柜/12-a-cewai-middle";
import { _12AcewaiBoardRight } from "../../boards/吊柜/12-a-cewai-right";
import { _12AdineiBoard } from "../../boards/吊柜/12-a-dinei";
import { _12AdingneiBoard } from "../../boards/吊柜/12-a-dingnei";
import { _12AdingwaiBoard } from "../../boards/吊柜/12-a-dingwai";
import { _12AdiwaiBoard } from "../../boards/吊柜/12-a-diwai";
import { _12AgecengneiBoard } from "../../boards/吊柜/12-a-gecengnei";
import { _12AgecengwaiBoard } from "../../boards/吊柜/12-a-gecengwai";
import { BaseCupboard } from "../@base/BaseCupboard";

export class DiaoguiSanmenguiBeibaoce extends BaseCupboard {
    key: string = "DiaoguiSanmenguiBeibaoce";
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: _12AdineiBoard,
            diwaiBoard: _12AdiwaiBoard,
            dingneiBoard: _12AdingneiBoard,
            dingwaiBoard: _12AdingwaiBoard,
            ceneiBoardLeft: _12AceneiBoardLeft,
            ceneiBoardRight: _12AceneiBoardRight,
            ceneiBoardMiddle: _12AceneiBoardMiddle,
            cewaiBoardLeft: _12AcewaiBoardLeft,
            cewaiBoardMiddle: _12AcewaiBoardMiddle,
            cewaiBoardRight: _12AcewaiBoardRight,
            beiwaiBoard: _12AbeiwaiBoard,
            beineiBoard: _12AbeineiBoard,
            menwaiBoard: menwaiBoard,
            menneiBoard: menneiBoard,
            "menwaiBoard-left": menwaiBoard,
            "menneiBoard-left": menneiBoard,
            "menwaiBoard-right": menwaiBoard,
            "menneiBoard-right": menneiBoard,
            gecengneiBoard: _12AgecengneiBoard,
            gecengwaiBoard: _12AgecengwaiBoard,
            AGecengneiBoard: _12AgecengneiBoard,
            AGecengwaiBoard: _12AgecengwaiBoard,
        }
    }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
        /* 2021 12 27 - 5 G型拉手门内高度+13*/
        let menneiHeightIncre = 0;
        let menneiWidthIncre = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                menneiHeightIncre = 13;
            }else {
                menneiWidthIncre = 13;
            }
        }
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 11, deep + 47 - 18],
            },
            "dineiBoard": {
                size: [long - 15, deep + 21 - 18],
            },
            "dingwaiBoard": {
                size: [long + 11, deep + 47 - 18],
            },
            "dingneiBoard": {
                size: [long - 15, deep + 21 - 18],
            },
            "cewaiBoardLeft": {
                size: [ deep + 47 - 18, height + 47],
            },
            "cewaiBoardMiddle": {
                size: [ deep + 47 - 18 , height + 47 - 36],
            },
            "cewaiBoardRight": {
                size: [ deep + 47 - 18, height + 47],
            },
            "ceneiBoardLeft": {
                size: [ deep + 21 - 18, height + 21],
            },
            "ceneiBoardMiddle": {
                size: [ deep + 21 - 18, height + 21 - 36],
            },
            "ceneiBoardRight": {
                size: [ deep + 21 - 18, height + 21],
            },
            "beiwaiBoard": {
                size: [long + 11 + 36, height + 11 + 36]
            },
            "beineiBoard": {
                size: [long - 15 + 36, height - 15 + 36]
            },
            "menwaiBoard-left": {
                size: [(long - 6) / 3 + 47 - wReduce, height + 45 - hReduce],
                quantity: 1,
            },
            "menneiBoard-left": {
                size: [(long - 6) / 3 + 21 - wReduce + menneiWidthIncre, height + 19 - hReduce + menneiHeightIncre],
                quantity: 1
            },
            "menwaiBoard-right": {
                size: [(long - 6) / 3 + 47 - wReduce, height + 45 - hReduce],
                quantity: 1,
            },
            "menneiBoard-right": {
                size: [(long - 6) / 3 + 21 - wReduce + menneiWidthIncre, height + 19 - hReduce + menneiHeightIncre],
                quantity: 1
            },
            "menwaiBoard": {
                size: [(long - 6) / 3 + 47 - wReduce, height + 45 - hReduce],
                quantity: 1,
            },
            "menneiBoard": {
                size: [(long - 6) / 3 + 21 - wReduce + menneiWidthIncre, height + 19 - hReduce + menneiHeightIncre],
                quantity: 1
            },
            

        };
        
        if(extraConfig.geceng) {
            list["AGecengwaiBoard"] = {
                size: [extraConfig.a + 11 , deep - extraConfig.gecengX + 29]
            }
            list["AGecengneiBoard"] = {
                size: [extraConfig.a - 15, deep - extraConfig.gecengX + 3]
            }
            list['gecengwaiBoard'] = {
                // size: [long - extraConfig.a + 11, deep - extraConfig.gecengX + 29],
                size: [long - extraConfig.a + 11 + 18, deep - extraConfig.gecengX + 29 ],
            };
            list['gecengneiBoard'] = {
                // size: [long - extraConfig.a - 15, deep - extraConfig.gecengX + 3],
                size: [long - extraConfig.a - 15 + 18, deep - extraConfig.gecengX + 3],
            };

        }
        if(extraConfig.nobeinei) {
            delete list["beineiBoard"];
        }
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard' || board.name === "dingneiBoard") {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.a);
        } else if(board.name === "diwaiBoard"|| board.name === "dingwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.a, extraConfig.waibanjiao);
        } else if(board.name === "beineiBoard"){
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.diaokongbianju, extraConfig.a, extraConfig);
        } else if(board.name === "beiwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.diaokongbianju, extraConfig.a, extraConfig.waibanjiao, extraConfig);
        } else if(["cewaiBoardLeft", "cewaiBoardRight"].includes(board.name)) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.menxiagaochu, extraConfig);
        } else if(["ceneiBoardLeft", "ceneiBoardRight"].includes(board.name)) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, /* extraConfig.nojiaoliankong, extraConfig.jiaolianbianju,  */extraConfig.lianjiekongbianju, /* extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu */);
        } else if("cewaiBoardMiddle" === board.name) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height,  extraConfig.nojiaoliankong, extraConfig.jiaolianbianju,  extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if("ceneiBoardMiddle" === board.name) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height,  extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing);
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao)
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }

}