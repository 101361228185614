import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { clearCorner, pathMirrorY, pathMoveX, pathMoveY, pathRotate, pathRotate2 } from "../../../util/graph";
import { corner16 } from "../../coners/corner16";
import { corner18 } from "../../coners/corner18";
import { corner6 } from "../../coners/corner6";
import { corner16WaiWuhujiao } from "../../coners/无护角/corner16-wai-wuhujiao";
import { corner6WaiWuhujiao } from "../../coners/无护角/corner6-wai-wuhujiao";
import { corner16WaiWujiao } from "../../coners/无角/corner16-wai-wujiao";
import { corner6WaiWujiao } from "../../coners/无角/corner6-wai-wujiao";
import { rectBoard } from "../general-board";

export const _03gongyongcewaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
): [Path[], Path[]] => {
    const {
        上免拉手  ,
        上免拉手X ,
        上免拉手Y ,
        中免拉手  ,
        中免拉手X ,
        中免拉手Y ,
        中免拉手Z
    } = extraConfig;
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];// = corner6(dx, dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if (cornerType === "youhujiao") {
        cornerBL = corner6(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMirrorY(cornerBL, startX);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.width);
    } else if (cornerType === "wujiao") {
        cornerBL = corner6WaiWujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMirrorY(cornerBL, startX);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.width);
    } else if (cornerType === "wuhujiao") {
        cornerBL = corner6WaiWuhujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMirrorY(cornerBL, startX);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.width);

    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];// = corner16(-dx, -dy, startX, startY, corner, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if (cornerType === "youhujiao") {
        cornerBR = corner16(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBRSize.height - 2 * (cornerBRSize.height - cornerBRSize.width));

        for (let i = 0; i < cornerBR.length; i++) {
            const temp = cornerBR[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    } else if (cornerType === "wujiao") {
        cornerBR = corner16WaiWujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBRSize.height - 2 * (cornerBRSize.height - cornerBRSize.width));
        for (let i = 0; i < cornerBR.length; i++) {
            const temp = cornerBR[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    } else if (cornerType === "wuhujiao") {
        cornerBR = corner16WaiWuhujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBRSize.height - 2 * (cornerBRSize.height - cornerBRSize.width));
        for (let i = 0; i < cornerBR.length; i++) {
            const temp = cornerBR[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    }




    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];// = corner6(dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.height;
    let cornerTRH: number = cornerTRSize.width;
    if (cornerType === "youhujiao") {
        cornerTR = corner6(dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathRotate(cornerTR, -90);
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width - cornerTRSize.height);
        if (!corner) {   //特殊处理
            cornerTR = clearCorner(cornerTR);
        }
    } else if (cornerType === "wujiao") {
        cornerTR = corner6WaiWujiao(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathRotate(cornerTR, -90);
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width - cornerTRSize.height);
        if (!corner) {   //特殊处理
            cornerTR = clearCorner(cornerTR);
        }
    } else if (cornerType === "wuhujiao") {
        cornerTR = corner6WaiWuhujiao(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.width);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        if (!corner) {   //特殊处理
            cornerTR = clearCorner(cornerTR);
        }
    }



    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(!上免拉手) {
        cornerTL = corner18(dx, dy, startX, startY, true, cornerTLSize);
        cornerTL = cornerTL.filter((temp) => temp.type !== Circle);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        if(qiandanghengfang) {
            cornerTL = pathMoveY(cornerTL, -DirectY.UP, height - cornerTLSize.height);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTL = pathRotate2(cornerTL, startX, startY, 90);
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.height);
            const widthTemp = cornerTLSize.width;
            cornerTLW = cornerTLSize.height;
            cornerTLH = widthTemp;
        }
    }else {
        cornerTL.push({
            type: "Line",
            params: [
                startX, startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * (上免拉手X + 23.5), startY + dy * height - dy * (上免拉手Y + 23.5),
            ] 
        }, {
            type: "Line",
            params: [
                startX + dx * (上免拉手X + 23.5), startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * (上免拉手X + 23.5), startY + dy * height,
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * (上免拉手X + 23.5) + dx * 10, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * (上免拉手X + 23.5) + dx * 10 + dx * 25.5, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        });
        cornerTLH = 上免拉手Y + 23.5;
        cornerTLW = 上免拉手X + 23.5;
    }

    let paths;
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, cornerTLW, cornerTLH);
    }
    
    if(中免拉手) {
        paths = paths.filter((_, index) => index !== 3);
        paths.push({
            type: Line,
            params: [
                startX, startY + dy * cornerBLH,
                startX, startY + dy * (中免拉手Z + 5.5)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z + 5.5),
                startX + dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5),
                startX + dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                startX,  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                startX, startY + dy * height - dy * cornerTLH
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (中免拉手X + 23.5) + dx * 10, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                1.6
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (中免拉手X + 23.5) + dx * 10 + dx * 25.5, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                1.6
            ]
        })
    }

    if (jiaolianbianju > -1 && !nojiaoliankong) {
        gxinglashouGaodu = gxinglashouGaodu as number;
        let offsetGX = 0;
        let offsetGY = 0;
        if (gxinglashou) {
            if (gxinglashouType === "up") {
                offsetGY = gxinglashouGaodu;
            } else if (gxinglashouType === "left-or-right") {
                offsetGX = 0;
            }
        }
        const temp: Path[] = [{
            type: Arc,
            params: [
                startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14),
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14)
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12),
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12),
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4,
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12)
            ]
        },
            //**********************
            {
                type: Arc,
                params: [
                    startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32,
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 32,
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 32
            ]
        },
            //==============================================
            {
                type: Arc,
                params: [
                    startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) - dy * offsetGY,
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) - dy * offsetGY
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) - dy * offsetGY,
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) - dy * offsetGY
            ]
        },
            //******************* 
            {
                type: Arc,
                params: [
                    startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
                startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
                startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
                startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
                startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY
            ]
        }
        ];
        paths.push(...temp);
    }
    const cornerPaths: Path[] = [];
    if (geceng) {
        gecengX  = gecengX as number;
        gecengY  = gecengY as number;
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                (gecengkongjing || 3) / 2
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                (gecengkongjing || 3) / 2
            ]
        });
    }
    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        cornerPaths.concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]

}