import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _02AbeiwaiBoard } from "./02-a-beiwai";

export const _12AbeiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    diaokongbianju: number,
    a: number,
    corner: boolean,
    extraConfig: any
): [Path[], Path[]] => {
    const [boardPaths, cornerPaths] = _02AbeiwaiBoard(cornerType,dx, dy, startX, startY, width, height, diaokongbianju, corner, extraConfig);
    cornerPaths.push(
        {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a + 14.5), startY + dy * (60.78 - 15.75 + 18),
                2.61
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a + 14.5), startY + dy * (60.78 + 18),
                3.11
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a + 14.5), startY + dy * (60.78 + 15.75 + 18),
                2.61
            ]
        },
        
        {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a + 14.5), startY + dy * height - dy * (60.78 - 15.75 + 18),
                2.61
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a + 14.5), startY + dy * height - dy * (60.78 + 18),
                3.11
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a + 14.5), startY + dy * height - dy * (60.78 + 15.75 + 18),
                2.61
            ]
        })

    return [boardPaths, cornerPaths];
}