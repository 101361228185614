import { Circle, Line } from "../../constants"
import { DirectX, DirectY } from "../../constants/enum"
import { degreeTransformForEach } from "../../util";
import { pathMoveX, pathMoveY, pathReverse, pathRotate } from "../../util/graph";
import { corner1 } from "./corner1";

export const corner10 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {

    const paths: Path[] = degreeTransformForEach([
        {
            type: Line,
            params: [
                startX, startY,
                startX + dx * 11.995, startY
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995, startY,
                startX + dx * 11.995, startY + dy * 4.95
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995, startY + dy * 4.95,
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * 11.995 + dx * 2 + dx * 5.39, startY + dy * 4.95 + dy * 1.61,
                3.61
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95,
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95 + dy * 3.62
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95 + dy * 3.62,
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62,
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * 11.995 + dx * 2 + dx * 5.39, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 1.61,
                3.61,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 4.95,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 11.995, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 4.95,
                startX,               startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 4.95,
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 4.95,
                startX, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 4.95 + dy * 10.199471,
            ]
        }
    ], dx, dy);
    const size = {height: 0, width: 0};
    let part2 = corner1(dx, -dy, startX, startY + dy * 55.219471, true, size);
    part2 = pathRotate(part2, -90);
    part2 = pathMoveX(part2, DirectX.RIGHT, dx * size.height);
    part2 = pathMoveY(part2, DirectY.UP, dy * size.width);
    part2 = pathReverse(part2);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = 70.085123;
            sizeRes.height = 88.085123;
        // }
    }
    return degreeTransformForEach(paths.concat(part2), dx, dy).filter((item) => corner ? true : item.type === Circle);
}