import { DirectX, DirectY } from "../../../constants/enum";
import { _05beiwaiBoard } from "./05-beiwai";
import { _07zhuanjiaowaiXBoard } from "./07-zhuanjiaowai-x";

export const _08zhuanjiaowaiXBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return _07zhuanjiaowaiXBoard(cornerType, dx, dy, startX, startY, width, height, corner);
}