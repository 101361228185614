import { Input, message } from "antd"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Group, Line, Text } from "react-konva"
import { Html } from "react-konva-utils"
import { m2px, px2m } from "../../../store";

export const ShapeDimension = forwardRef((props: {
    //基础属性
    direct: "x" | "y";
    from: number;   //if direct = x, from is fromX, otherwise from is fromY
    to: number;     //if direct = x, to is toX, otherwise from is toY
    base: number;   //if direct = x, base is baseY, otherwise base is baseX
    offset: number; //if direct = x, offset is offsetY, otherwise offset is offsetX
    //输入相关
    canInput?: boolean;
    autoSelect?: boolean;
    lengthSetTriggers?: ("enter"| "blur")[];
    onCanSelect?: () => void;//能够选择时触发，用于通知父组件，autoSelect为false时，可以选择
    //输入确认相关
    onLengthSet?: (length: number) => void;
    onLengthEnter?: (length: number) => void;
    //其他
    color?: string;
    show?: boolean; //是否显示
}, ref: any) => {

    const { direct, from, to, base, offset } = props;
    let fromX: number, fromY: number;
    let toX: number, toY: number;
    if (direct === 'x') {
        fromX = from;
        fromY = base + offset;
        toX = to;
        toY = base + offset
    } else {
        fromX = base + offset;
        fromY = from;
        toX = base + offset;
        toY = to;
    }
    const length = (from && to) ? Math.abs(from - to) : 0;
    const show = props.show !== false;

    const inputRef = useRef<any>();

    useEffect(() => {
        let timer: any;
        if (show && props.canInput) {
            timer = setTimeout(() => {
                if(props.autoSelect) {
                    inputRef.current?.focus();
                    inputRef.current?.select();
                }else {
                    props.onCanSelect && props.onCanSelect();
                }
            }, 240)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
                timer = undefined;
            }
            if (!props.autoSelect) {
                return;
            }
        }
    }, [show, props.canInput, length, props.autoSelect]);

    const [value, setValue] = useState((length * px2m * 1000).toFixed(0));

    useEffect(() => {
        if (show) {
            setValue((length * px2m * 1000).toFixed(0));
        }
    }, [show, length]);

    useImperativeHandle(ref, () => {
        return {

            select: () => {
                inputRef.current?.focus();
                inputRef.current?.select();
            }
        }
    })

    return (
        <Group visible={show}>
            {
                value !== '0' && (props.canInput ? (
                    <Html
                        groupProps={{
                            x: direct === 'x' ? (from + to) / 2 - (/* value.length */4) * 8 / 2 : base + offset - (/* value.length */4) * 8 / 2,
                            y: direct === 'x' ? base + offset - 22 / 2 : (from + to) / 2 - 22 / 2
                        }}
                    >

                        <Input 
                            onFocus={() => {
                                inputRef.current?.select();
                            }} 
                            onClick={() => {
                                inputRef.current?.select();
                            }} 
                            onBlur={() => {
                                if(props.lengthSetTriggers && props.lengthSetTriggers.includes("blur")) {
                                    if (Number(value)) {
                                        props.onLengthSet && props.onLengthSet(Number(value) / 1000 / px2m);
                                    }
                                }
                            }}
                            autoComplete="off" 
                            autoCorrect="off" 
                            ref={inputRef} 
                            value={value} 
                            onChange={(e) => {
                                if (e.target.value && !/^\d+(\.\d{1,})$|(^[1-9]\d*$)/.test(e.target.value)) {
                                    return;
                                }
                                setValue(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if(e.key !== 'Enter') {
                                    return ;
                                }
                                if (!props.onLengthEnter) {
                                    //默认走onLengthSet
                                    if(!props.lengthSetTriggers || props.lengthSetTriggers.includes("enter")) {
                                        if (Number(value)) {
                                            props.onLengthSet && props.onLengthSet(Number(value) / 1000 / px2m);
                                        }else {
                                            message.info("请输入有效的长度");
                                        }
                                    }
                                }else {
                                    if(Number(value)) {
                                        props.onLengthEnter && props.onLengthEnter(Number(value) / 1000 / px2m);
                                    }else {
                                        message.info("请输入有效的长度");
                                    }
                                }

                            }} 
                            size="small" 
                            style={{ minWidth: 30, width: (/* value.length */4) * 8, textAlign: 'center', fontSize: 12, padding: 0 }} 
                        />
                    </Html>
                ) : (
                    <Text
                        ref={(r) => {
                            if(r) {
                                r.offset({ x: r.width() / 2, y: r.height() / 2 });
                            }
                        }}
                        text={value}
                        x={direct === 'x' ? (from + to) / 2 : base + offset}
                        y={direct === 'x' ? base + offset : (from + to) / 2}
                        fontSize={16}
                        fill="#000"
                        stroke={"#fff"}
                        strokeWidth={0.6}
                        strokeScaleEnabled={false}
                        fontStyle="600"
                    />
                    // <Html
                    //     groupProps={{
                    //         x: direct === 'x' ? (from + to) / 2 - value.length * 8 / 2 : base + offset - value.length * 8 / 2,
                    //         y: direct === 'x' ? base + offset - 12 / 2 : (from + to) / 2 - 12 / 2,
                    //         // rotation: 90
                    //         visible: direct === 'x' ? length > value.length * 6 / 2 : length > value.length * 6 / 2
                    //     }}
                    // >
                    //     <div style={{ width: value.length * 8, lineHeight: "10px", padding: 0, overflow: 'hidden', textAlign: 'center' }}>
                    //         <span style={{ fontSize: 12, color: props.color || "#000", textShadow: props.color ? undefined : "#fff .5px 0 0, #fff 0 .5px 0, #fff -.5px 0 0, #fff 0 -.5px 0" }}></span>
                    //     </div>
                    // </Html>
                ))
            }
            <Line
                points={
                    direct === 'x' ? [
                        from, base + offset - 4,
                        from, base + offset + 4
                    ] : [
                        base + offset + 4, from,
                        base + offset - 4, from
                    ]
                }
                strokeWidth={0.5}
                strokeScaleEnabled={false}
                stroke={props.color || "#000"}
            />
            {/* 主线 */}
            <Line
                points={[
                    fromX, fromY,
                    toX, toY
                ]}
                strokeWidth={0.5}
                strokeScaleEnabled={false}
                stroke={props.color || "#000"}
            />
            <Line
                points={
                    direct === 'x' ? [
                        to, base + offset - 4,
                        to, base + offset + 4
                    ] :
                        [
                            base + offset + 4, to,
                            base + offset - 4, to
                        ]}
                strokeWidth={0.5}
                strokeScaleEnabled={false}
                stroke={props.color || "#000"}
            />
        </Group>
    )
})