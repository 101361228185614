import { ReactNode, Suspense, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useRef, useState } from "react"
import { Circle, Group, Layer, Line, Rect, Stage } from "react-konva"
import { FloorPlannerContext, floorPlannerReducer } from "./context-reducer"
import { DrawedMeta, initialFloorPlannerData, useGridMeasure, useWidgets } from "./store";
import './style.less';
import { KonvaEventObject } from "konva/lib/Node";
import { ConfigProvider, Image, Input, Modal, Tag, message, theme } from "antd";
import { LayerDrawingWall } from "./components/layer/layer-drawing-wall/index";
import { LayerDrawedList, LayerDrawedListRef } from "./components/layer/layer-drawed-list";
import { Widgets } from "./components/widgets";
import { LayerDrawingStruct } from "./components/layer/layer-drawing-struct";
import { LayerDrawingOnWall } from "./components/layer/layer-drawing-onwall";
import { LayerEditStruct } from "./components/layer/layer-drawing-struct/edit";
import { LayerEditOnWall } from "./components/layer/layer-drawing-onwall/edit";
import { LayerDrawingArea } from "./components/layer/layer-drawing-area";
import { _3DWrapper } from "../3d-design[wrapper]";
import { request } from "../../utils2/request";
import { apis } from "../../utils2/apis";

const FloorPlanner = () => {

    const [state, dispatch] = useReducer(floorPlannerReducer, initialFloorPlannerData);

    const drawedListRef = useRef<LayerDrawedListRef>();

    const [mode, setMode] = useState<"2d" | "3d">("2d");

    const [order, setOrder] = useState<any>(null);

    const [cupboard3DLoad, setCupboard3DLoad] = useState(false);

    useEffect(() => {

    }, []);

    const on2DSave = async (order: any, drawedList: DrawedMeta[], auto = true) => {
        if (!order || !order.id) {
            return false;
        }
        const drawedGroup = drawedListRef.current.getGroupRef();
        const hasArea = drawedList.some((item) => item.type === "area");
        let previewImage2D: Blob;
        if (hasArea) {
            try {
                previewImage2D = await drawedGroup.toBlob({
                    mimeType: "image/jpeg",
                    quality: 1,
                    pixelRatio: 2,

                }) as Blob;
            } catch (e) {
                console.error(e);
            }
        }
        const options = previewImage2D ? {
            files: [{ name: "previewImage", file: new File([previewImage2D], "previewImage_2D.jpg") }],
        } : undefined;
        return request.put(apis.order + `/${order.id}`, {
            floorPlannerData: JSON.stringify(drawedList),
        }, options).then((res) => {
            if (res.code === 1) {
                // if (!auto) {
                //     closeLoading();
                //     message.success("保存成功");
                // }
                return true;
            } else {
                return false;
            }
        }).catch((e) => { console.error(e); return false; });
    }

    const on3DSave = () => {

    }

    return (
        <ConfigProvider theme={{
            algorithm: [theme.defaultAlgorithm]
        }}>
            <FloorPlannerContext.Provider value={{ state, dispatch }}>
                <ScalableGridCanvas>
                    <LayerDrawedList ref={drawedListRef} />
                    <LayerDrawingArea />
                    <LayerDrawingWall />
                    <LayerDrawingStruct />
                    <LayerDrawingOnWall />
                    <LayerEditStruct />
                    <LayerEditOnWall />
                </ScalableGridCanvas>
                <Widgets
                    mode={mode}
                    onSave={on2DSave}
                    onModeChange={(mode) => {
                        setMode(mode);
                    }}
                    onOrderChange={(order) => {
                        setOrder(order);
                    }}
                />
                {
                    <_3DWrapper
                        mode={mode}
                        visible={mode === "3d"}
                        order={order}
                    />
                }
            </FloorPlannerContext.Provider>
        </ConfigProvider>
    )

}

export default FloorPlanner;

/**
 * 可滚动的画布
 * @param props 
 * @returns 
 */
const ScalableGridCanvas = (props: {
    children?: ReactNode | ReactNode[]
}) => {

    const {
        //初始化相关
        grid, initX, initY, updateStageScale, setStageRef,
        //绘图相关
        stageCursor, drawType, isEditing, reportMouseInStagePos, onCancelDrawing

    } = useGridMeasure();

    const [cursor, setCursor] = useState<"move" | "grab" | "crosshair" | "none" | "default">("grab");

    useEffect(() => {
        if (!drawType) {
            setCursor("default");
        } else {
            if (drawType.indexOf("wall") === 0 || drawType.indexOf("struct") === 0 || drawType.indexOf("onwall") === 0) {
                setCursor("crosshair");
            }

        }
    }, [drawType, isEditing]);



    const onWheel = useCallback(
        (e: KonvaEventObject<WheelEvent>) => {
            console.debug("[event]onWheel");
            e.evt.preventDefault();
            const stage = e.target.getStage();
            const pointer = stage.getPointerPosition();
            const oldScale = stage.scaleX();
            const scaleBy = 1.05;
            const mousePointTo = {
                x: (pointer.x - stage.x()) / oldScale,
                y: (pointer.y - stage.y()) / oldScale,
            };
            let direction = e.evt.deltaY > 0 ? -1 : 1;
            if (e.evt.ctrlKey) {
                direction = -direction;
            }
            const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
            const newPos = {
                x: pointer.x - mousePointTo.x * newScale,
                y: pointer.y - mousePointTo.y * newScale,
                scaleX: newScale,
                scaleY: newScale
            };
            stage.setAttrs(newPos);
            updateStageScale(newScale);
        },
        []
    )

    const onMouseMove = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (!drawType) {
                return;
            }
            // console.debug("[event]onMouseOver");
            e.evt.preventDefault();
            const stage = e.target.getStage();
            const pointer = stage.getRelativePointerPosition();
            reportMouseInStagePos(pointer);
        },
        [drawType]
    )

    return useMemo(() => {
        return (
            <Stage
                x={initX}
                y={initY}
                width={window.innerWidth}
                height={window.innerHeight}
                style={{ cursor: stageCursor || cursor, background: "#f8f8f8" }}
                draggable={!drawType}
                ref={setStageRef}
                onWheel={onWheel}
                onMouseMove={onMouseMove}
                onContextMenu={(e) => {
                    e.evt.preventDefault();
                    onCancelDrawing();
                }}
            >
                <GridLines {...grid} />
                {props.children}
            </Stage>
        )
    }, [cursor, drawType, stageCursor])

}

/**
 * 画布网格线
 */
const GridLines = memo((props: { gridLines: number, gridSpan: number, gridLineLength: number }) => {
    const { gridLines, gridSpan, gridLineLength } = props;
    return (
        <Layer>
            {
                new Array(gridLines).fill(null).map((_, i) => {
                    const darkLine = i === Math.floor(gridLines / 2) || i == 0;
                    return (
                        <>
                            <Line key={"row-" + i} points={[0, i * gridSpan, gridLineLength, i * gridSpan]} strokeWidth={0.3} strokeScaleEnabled={false} stroke={darkLine ? '#a0a0a0' : "#d3d3d3"} />
                            <Line key={"col" + i} points={[i * gridSpan, 0, i * gridSpan, gridLineLength]} strokeWidth={0.3} strokeScaleEnabled={false} stroke={darkLine ? '#a0a0a0' : "#d3d3d3"} />
                        </>
                    )
                })
            }
            <Line key={"row-e"} points={[0, gridLines * gridSpan, gridLineLength, gridLines * gridSpan]} strokeWidth={0.3} strokeScaleEnabled={false} stroke={"#a0a0a0"} />
            <Line key={"col-e"} points={[gridLines * gridSpan, 0, gridLines * gridSpan, gridLineLength]} strokeWidth={0.3} strokeScaleEnabled={false} stroke={"#a0a0a0"} />
        </Layer>

    )
})

