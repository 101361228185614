import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths } from "../../../util/graph";
import { dineiBoard } from "../地柜/01-dinei";
import { gecengneiBoard } from "../地柜/01-gecengnei";
import { gecengwaiBoard } from "../地柜/01-gecengwai";
import { _05beineiBoard } from "../转角柜/05-beinei";

export const _02gecengwaiBoard = (
   cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return gecengwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
 }