import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";

export const _05zhuanjiaoneiYBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    const [ boards, corners ] = _05beineiBoard(dx, dy, startX, startY, width, height, false, {
        noMiddleware: true
    });

    corners.push({
        type: Circle,
        params: [
            startX + dx * 19.385, startY + dy * (32.025129 - 0.000006),
            3.61
        ]
    },
    {
        type: Circle,
        params: [
            startX + dx * 19.385, startY + dy * (32.025129 - 0.000006) + dy * 31.5,
            3.61
        ]
    },
    //===================
    {
        type: Circle,
        params: [
            startX + dx * width - dx * 19.385, startY + dy * (32.025129 - 0.000006),
            3.61
        ]
    },
    {
        type: Circle,
        params: [
            startX + dx * width - dx * 19.385, startY + dy * (32.025129 - 0.000006) + dy * 31.5,
            3.61
        ]
    },
    //=================
    {
        type: Circle,
        params: [
            startX + dx * width - dx * 19.385, startY + dy * height - dy * (32.025129 - 0.000006),
            3.61
        ]
    },
    {
        type: Circle,
        params: [
            startX + dx * width - dx * 19.385, startY + dy * height - dy * (32.025129 - 0.000006) - dy * 31.5,
            3.61
        ]
    },
    //==================
    {
        type: Circle,
        params: [
            startX + dx * 19.385, startY + dy * height - dy * (32.025129 - 0.000006),
            3.61
        ]
    },
    {
        type: Circle,
        params: [
            startX + dx * 19.385, startY + dy * height - dy * (32.025129 - 0.000006) - dy * 31.5,
            3.61
        ]
    }
    )
    return [
        boards,
        corners
    ]


}