import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveY } from "../../../util/graph";
import { 无护角有卡槽 } from "./config";
import { corner2WaiWuhujiao } from "./corner2-wai-wuhujiao";

export const corner8WaiWuhujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const corner2Paths = corner2WaiWuhujiao(dx, dy, startX, startY, true);
    let paths = corner2Paths.filter((item) => item.type !== Circle);
    if(无护角有卡槽) {
        paths =  paths.slice(8, paths.length);
    }else {
        paths =  paths.slice(1, paths.length);
    }
    paths = pathMoveY(paths, DirectY.DOWN, dy * 43.49);
    if(typeof sizeRes === 'object') {
        sizeRes.width = 34.25022;
        sizeRes.height = 34.130141;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}