import { ReactNode } from "react"
import styled from "styled-components"

interface Props {
    children?: ReactNode
}

export default function Body(props: Props) {

    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    )

}

const Wrapper = styled.div`
    flex: 1;
    overflow-y: auto;
    /* overflow-y: scroll; */
    /*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
        width:12px;
        height:12px;
        background-color:#fff;
    }
    /*定义滚动条轨道
    内阴影+圆角*/
    ::-webkit-scrollbar-track {
        /* box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
        border-radius:10px;
        background-color:#fff;
    }
    /*定义滑块
    内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow:inset 0 0 6px rgba(0,0,0,.5);
        background-color:#fff;
    }
`