// import {  } from "dxf-writer";
import { Circle, Line, Arc } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate, pathRotate2 } from "../../../util/graph";
import { corner16 } from "../../coners/corner16";
import { corner2 } from "../../coners/corner2";
import { corner6 } from "../../coners/corner6";
import { corner16WaiWuhujiao } from "../../coners/无护角/corner16-wai-wuhujiao";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner6WaiWuhujiao } from "../../coners/无护角/corner6-wai-wuhujiao";
import { corner16WaiWujiao } from "../../coners/无角/corner16-wai-wujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { corner6WaiWujiao } from "../../coners/无角/corner6-wai-wujiao";
import { rectBoard } from "../general-board";

// export const 
export const _10cewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    a?: number,
    x?: number,
    y1?: number,
    y2?: number,
    y3?: number,
    // gxinglashou?: boolean,
    // gxinglashouType?: "up" | "left-or-right",
    // gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
): [Path[], Path[]] => {
    
    const {
        上免拉手  ,
        上免拉手X ,
        上免拉手Y ,
        中免拉手1  ,
        中免拉手1X ,
        中免拉手1Y ,
        中免拉手1Z ,
        中免拉手2  ,
        中免拉手2X ,
        中免拉手2Y ,
        中免拉手2Z ,
        中免拉手3  ,
        中免拉手3X ,
        中免拉手3Y ,
        中免拉手3Z ,
        
        无侧板排孔
    } = extraConfig;
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];// = corner16(dx, dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner16(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;
        cornerBL = pathRotate2(cornerBL, startX, startY, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
        for (let i = 0; i < cornerBL.length; i++) {
            const temp = cornerBL[i];
            if(temp.type === "Circle" && numberEqual(temp.params[0], startX + dx * 15.385)) {
                temp.params[0] = startX + dx * 32.385
            }
        }
    }else if(cornerType === "wujiao") {
        cornerBL = corner16WaiWujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;
        cornerBL = pathRotate2(cornerBL, startX, startY, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
        for (let i = 0; i < cornerBL.length; i++) {
            const temp = cornerBL[i];
            if(temp.type === "Circle" && numberEqual(temp.params[0], startX + dx * 15.385)) {
                temp.params[0] = startX + dx * 32.385
            }
        }
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner16WaiWuhujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;
        cornerBL = pathRotate2(cornerBL, startX, startY, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
        for (let i = 0; i < cornerBL.length; i++) {
            const temp = cornerBL[i];
            if(temp.type === "Circle" && numberEqual(temp.params[0], startX + dx * 15.385)) {
                temp.params[0] = startX + dx * 32.385
            }
        }
    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = []; // = corner6(dx, dy, startX, startY, corner, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner6(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    }else if(cornerType === "wujiao") {
        cornerBR = corner6WaiWujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner6WaiWuhujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);

    }
    
    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(!上免拉手) {
        if(cornerType === "youhujiao") {
            cornerTR = corner2(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
        }else if(cornerType === "wujiao") {
            cornerTR = corner2WaiWujiao(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
        }else if(cornerType === "wuhujiao") {
            cornerTR = corner2WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
        }
        if(qiandanghengfang) {
            cornerTR = pathMoveX(cornerTR, -DirectX.RIGHT, width);
            cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMirrorY(cornerTR, startX);
            cornerTR = pathRotate2(cornerTR, startX, startY, -90);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
            const widthTemp = cornerTRSize.width;
            cornerTRW = cornerTRSize.height;
            cornerTRH = widthTemp;
        }
    }else {
        cornerTR.push({
            type: "Line",
            params: [
                startX + dx * width, startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * width - dx * (上免拉手X + 23.5),  startY + dy * height - dy * (上免拉手Y + 23.5)
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5),  startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * width - dx * (上免拉手X + 23.5), startY + dy * height
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5) - dx * 10, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5) - dx * 10 - dx * 25.5, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        })
        cornerTRW = 上免拉手X + 23.5;
        cornerTRH = 上免拉手Y + 23.5;

    }


    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner2(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }else if(cornerType === "wujiao") {
        cornerTL = corner2WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height)
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height)
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }
    // //左上角
    // const cornerTLSize = { width: 0, height: 0 };
    // let cornerTL: Path[] = [];// = corner2(-dx, dy, startX, startY, corner, cornerTLSize);
    // let cornerTLW: number = cornerTLSize.width;
    // let cornerTLH: number = cornerTLSize.height;
    // if(cornerType === "youhujiao") {
    //     cornerTL = corner2(-dx, dy, startX, startY, corner, cornerTLSize);
    //     cornerTLW = cornerTLSize.width;
    //     cornerTLH = cornerTLSize.height;
    //     cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    //     cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    // }else if(cornerType === "wujiao") {
    //     cornerTL = corner2WaiWujiao(-dx, dy, startX, startY, corner, cornerTLSize);
    //     cornerTLW = cornerTLSize.width;
    //     cornerTLH = cornerTLSize.height;
    //     cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    //     cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    // }else if(cornerType === "wuhujiao") {
    //     cornerTL = corner2WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTLSize);
    //     cornerTLW = cornerTLSize.width;
    //     cornerTLH = cornerTLSize.height;
    //     cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    //     cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    // }

    // if(qiandanghengfang) {
    //     cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
    //     cornerTR = pathMirrorY(cornerTR, startX);
    //     cornerTR = pathRotate2(cornerTR, startX, startY, -90);
    //     cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
    //     const widthTemp = cornerTRSize.width;
    //     cornerTRW = cornerTRSize.height;
    //     cornerTRH = widthTemp;
    // }
    // for (let i = 0; i < cornerTR.length; i++) {
    //     const temp = cornerTR[i];
    //     if(temp.type ===  Circle) {
    //         temp.params[0] = startX + dx * 15.385;
    //     }
    // }

    

    const boardConfig: any = {
        middleware: {
            top: false,
            left: height > 500 && "far",
            right: false,
            bottom: width > 500 && "close",
        }
    }

    let paths = [];
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }

    const mianlashouTemp = [
        {
            中免拉手: 中免拉手1,
            中免拉手X: 中免拉手1X,
            中免拉手Y: 中免拉手1Y,
            中免拉手Z: 中免拉手1Z
        },
        {
            中免拉手: 中免拉手2,
            中免拉手X: 中免拉手2X,
            中免拉手Y: 中免拉手2Y,
            中免拉手Z: 中免拉手2Z
        },
        {
            中免拉手: 中免拉手3,
            中免拉手X: 中免拉手3X,
            中免拉手Y: 中免拉手3Y,
            中免拉手Z: 中免拉手3Z
        },
    ].filter((item) => item.中免拉手).sort((a, b) => {
        return a.中免拉手Z > b.中免拉手Z ? 1 : -1;
    });
    if(mianlashouTemp.length > 0) {
        paths = paths.filter((_, index) => index !== 1);
        let lastZFlag = 0;

        for (let i = 0; i < mianlashouTemp.length; i++) {
            const temp = mianlashouTemp[i];
            const { 中免拉手, 中免拉手X, 中免拉手Z, 中免拉手Y } = temp;
            let yTemp = i === 0 ? cornerBRH :  lastZFlag;
            paths.push({
                type: Line,
                params: [
                    startX + dx * width, startY + yTemp,
                    startX + dx * width, startY + dy * (中免拉手Z + 5.5)
    
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width, startY + dy * (中免拉手Z + 5.5),
                    startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5)
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5),
                    startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                    startX + dx * width,  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * width - dx * (中免拉手X + 23.5) - dx * 10, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                    1.6
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * width - dx * (中免拉手X + 23.5) - dx * 10 - dx * 25.5, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                    1.6
                ]
            });
            lastZFlag = (中免拉手Z + 5.5) + 中免拉手Y;
        }
        paths.push({
            type: "Line",
            params: [
                startX + dx * width, startY + dy * lastZFlag,
                startX + dx * width, startY + dy * height - dy * cornerTRH
            ]
        })
    }

    
    if (lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        });
    }
   
    // //console.log(geceng, gecengX, gecengY);
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        paths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                (gecengkongjing || 4.96) / 2
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                (gecengkongjing || 4.96) / 2

            ]
        });
    }

    a = a as number;
    x = x as number;
    y1 = y1 as number;
    
    let sx = startX + dx * width - dx * (x + 19) - dx * 58 - dx * a;
    let sy = startY + dy * (y1 + 9);
    function putRect(sx: number, sy: number) {
        paths.push({
            type: Arc,
            params: [
                sx + dx * 1, sy + dx * 1,
                1,
                180, 270 
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 1, sy,
                sx + dx * 1 + dx * 56, sy
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 1 + dx * 56, sy + dy * 1,
                1,
                270, 360
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 1 + dx * 56 + dx * 1, sy + dy * 1,
                sx + dx * 1 + dx * 56 + dx * 1, sy + dy * 1 + dy * 18
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 1 + dx * 56, sy + dy * 1 +dy * 18,
                1,
                0, 90
            ]
        }, {
            type: Line,
            params: [
                sx + dx * 1 + dx * 56, sy + dy * 1 +dy * 18 + dy * 1,
                sx + dx * 1, sy + dy * 1 +dy * 18 + dy * 1,
            ]
        }, {
            type: Arc,
            params: [
                sx + dx * 1, sy + dy * 1 + dy * 18,
                1,
                90, 180
            ]
        }, {
            type: Line,
            params: [
                sx, sy + dy * 1 + dy * 18,
                sx, sy + dy * 1
            ]
        });
    }
    if(!无侧板排孔) {

        if(y1) {
            putRect(sx, sy);
            putRect(sx + a, sy);
        }
        if(y2) {
            sy = startY + dy * (y2 + 9);
            putRect(sx, sy);
            putRect(sx + a, sy);
        
        }
        if(y3) {
            sy = startY + dy * (y3 + 9);
            putRect(sx, sy);
            putRect(sx + a, sy);
        }
    }
    
    
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ];
}