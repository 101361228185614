// import {  } from "dxf-writer";
import { Circle, Line, Arc } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner2 } from "../../coners/corner2";
import { corner5 } from "../../coners/corner5";
import { corner6 } from "../../coners/corner6";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner5WaiWuhujiao } from "../../coners/无护角/corner5-wai-wuhujiao";
import { corner6WaiWuhujiao } from "../../coners/无护角/corner6-wai-wuhujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { corner5WaiWujiao } from "../../coners/无角/corner5-wai-wujiao";
import { corner6WaiWujiao } from "../../coners/无角/corner6-wai-wujiao";
import { rectBoard } from ".././general-board";

// export const 
export const _01AcewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    mengao?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean
): [Path[], Path[]] => {
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner5(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }else if(cornerType === "wujiao") {
        cornerBL = corner5WaiWujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner5WaiWuhujiao(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;

    }
    for (let i = 0; i < cornerBL.length; i++) {
        const temp = cornerBL[i];
        if(temp.type === Circle) {
            if(numberEqual(temp.params[0], startX + dx * 32.385, 0.01)) {
                temp.params[0] = startX + dx * 15.385;
            }
            if(numberEqual(temp.params[1], startY + dy * 32.385, 0.01))  {
                temp.params[1] = startY + dy * 15.385;
            }
        }
    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner6(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    }else if(cornerType === "wujiao") {
        cornerBR = corner6WaiWujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner6WaiWuhujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);

    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner2(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height)
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else if(cornerType === "wujiao") {
        cornerTR = corner2WaiWujiao(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height)
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else if(cornerType === "wuhujiao") {
        cornerTR = corner2WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height)
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    }
    if(qiandanghengfang) {
        cornerTR = pathMoveX(cornerTR, -DirectX.RIGHT, width);
        cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        const widthTemp = cornerTRSize.width;
        cornerTRW = cornerTRSize.height;
        cornerTRH = widthTemp;
    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner2(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wujiao") {
        cornerTL = corner2WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    }
    for (let i = 0; i < cornerTL.length; i++) {
        const temp = cornerTL[i];
        if(temp.type === Circle) {
            temp.params[0] = startX + dx * 15.385;
        }
    }
    
    const boardConfig: any = {
        middleware: {
            top: false,
            left: height > 500 && "close",
            right: false,
            bottom: width > 500 && "close",
        }
    }
    let paths = [];
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    
    if (lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        });
    }
    if (jiaolianbianju > -1 && !nojiaoliankong) {
        let offsetGX = 0;
        let offsetGY = 0;
        if (gxinglashou) {
            if (gxinglashouType === "up") {
                offsetGY = -1.5;
            }else if(gxinglashouType === "left-or-right") {
                offsetGX = 0;
            }
        }
        mengao = mengao as number;
        const temp: Path[] = [{
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14),
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14)
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12),
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12)
            ]
        },
        //**********************
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 32,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32
            ]
        },
        //==============================================
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX,   startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - offsetGY,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,  
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY
            ]
        },
        //******************* 
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY
            ]
        }
        ];
        paths.push(...temp);
    }
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        paths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                (gecengkongjing || 3) / 2,
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                (gecengkongjing || 3) / 2,
            ]
        });
    }
    
    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ];
}