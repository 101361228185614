import { Vector2d } from "konva/lib/types";
import { DrawWallType, Drawed, WallData } from "./store";
import { areaLinesIntersecting } from "./gpt/areaLinesIntersecting";
import { generateUUID } from "three/src/math/MathUtils";

/**
 * 根据wallLength（可为负数）计算方向符号
 * @param wallLength 
 * @returns 
 */
export const computeWallDirectSign = (wallLength: number): (1 | -1) => {
    return wallLength / Math.abs(wallLength) as (1 | -1);
}


/**
 * 根据墙起始锚计算终止锚点
 * @param wallStartAnchorPos 
 * @param wallLength 
 * @param wallDirect 
 */
export const computeWallEndAnchorPos = (wallStartAnchorPos: Vector2d, wallLength: number, wallDirect: "x" | "y", wallThickness: number) => {
    const sign = computeWallDirectSign(wallLength);
    return {
        x: wallStartAnchorPos.x + (wallDirect === 'x' ? (wallLength - sign * wallThickness) : 0),
        y: wallStartAnchorPos.y + (wallDirect === 'y' ? (wallLength - sign * wallThickness) : 0)
    }
}

/**
 * 计算墙各连接处的anchors
 * @param walls 
 * @param closed 
 * @returns 
 */
export const computeWallAnchors = (walls: WallData[], closed: boolean) => {
    
    let anchors: Vector2d[] = [];
    const anchorSet = new Set<string>();
    walls.forEach((w, i) => {
        const { startAnchorPos, endAnchorPos, nextAnchorPos } = w;
        if (i === 0) {
            const p1 = `${startAnchorPos.x},${startAnchorPos.y}}`;
            if (!anchorSet.has(p1)) {
                anchors.push(startAnchorPos);
                anchorSet.add(p1);
            }
        }

        if (i !== walls.length - 1) {
            const p2 = `${nextAnchorPos.x},${nextAnchorPos.y}}`;
            if (!anchorSet.has(p2)) {
                anchors.push(nextAnchorPos);
                anchorSet.add(p2);
            }
        }
        if (i === walls.length - 1) {
            if(!closed) {
                const p3 = `${endAnchorPos.x},${endAnchorPos.y}}`;
                if (!anchorSet.has(p3)) {
                    anchors.push(endAnchorPos);
                    anchorSet.add(p3);
                }
            }else{
                //闭合时，
                const firstWallDirect = walls[0].wallDirect;
                const lastWall = walls[walls.length - 1];
                const lastWallDirect = lastWall.wallDirect;
                if(firstWallDirect === lastWallDirect) {
                    anchors[0] = anchors.pop();
                }
            }
        }
    });
    return anchors;
}

/**
 * 根据终点锚点返回墙的参数
 * @param approachEndAnchor 
 * @param wallStartAnchorPos 
 * @param wallLength 
 * @param wallDirect 
 * @param wallThickness 
 * @returns 
 */
export const computeWallByEndAnchor = (
    approachEndAnchor: Vector2d | null, 
    wallType: DrawWallType,
    wallStartAnchorPos: Vector2d, 
    wallLength: number, 
    wallDirect: "x" | "y", 
    wallThickness: number,
    floorHeight: number,
    floorThickness: number
): WallData => {
    // const   
    let startPosX = wallStartAnchorPos.x;
    let startPosY = wallStartAnchorPos.y;
    //计算当前的中心点位置，不能用brushPos，因为会移动，得用drawingStartPos
    let nextPosX = startPosX;
    let nextPosY = startPosY;
    let _wallLength = wallLength;
    let _approachEndAnchor = approachEndAnchor;
    if (_approachEndAnchor) {
        if (wallDirect === 'x') {
            _wallLength = approachEndAnchor.x - startPosX;
        } else {

            _wallLength = approachEndAnchor.y - startPosY;
        }
    }


    if (wallDirect === 'x') {
        nextPosX += _wallLength;
    } else {
        nextPosY += _wallLength;
    }
    if(!_approachEndAnchor) {
        _approachEndAnchor = computeWallEndAnchorPos(wallStartAnchorPos, _wallLength, wallDirect, wallThickness);

    }
    const nextStartPos = {
        x: nextPosX,
        y: nextPosY
    }
    return {
        id: generateUUID(),
        wallType,
        startAnchorPos: wallStartAnchorPos,
        endAnchorPos: _approachEndAnchor,
        nextAnchorPos: nextStartPos,
        wallLength: _wallLength,
        wallDirect,
        wallThickness,
        floorHeight,
        floorThickness
    }
}


/**
 * 计算与当前墙终点接近的其他锚点
 * @param otherWalls 其他墙
 * @param endAnchor 当前的endAnchor
 * @param wallStartAnchor 
 * @param wallDirect 
 * @param wallThickness 
 */
export const computeCurrentPosApproachOtherWallAnchors = (
    otherWalls: Drawed<DrawWallType, WallData>[], 
    endAnchor: Vector2d, 
    wallStartAnchor: Vector2d, 
    wallDirect: "x" | "y", 
    wallThickness: number
): {
    targetAnchor: Vector2d,
    approachEndAnchor: Vector2d
} => {
    const parallelWalls = otherWalls.filter((d) => d.data.wallDirect === wallDirect);
    // const 
    for (let i = 0; i < parallelWalls.length; i++) {
        const w = parallelWalls[i];
        const data = w.data as WallData;
        // const wEndAnchorPos = computeWallEndAnchorPos(data.startAnchorPos, data.wallLength, data.wallDirect, data.wallThickness);
        if (Math.abs(endAnchor[wallDirect] - data.startAnchorPos[wallDirect]) <= wallThickness) {

            return {
                targetAnchor: data.startAnchorPos,
                approachEndAnchor: wallDirect === 'x' ? {
                    x: data.startAnchorPos.x,
                    y: wallStartAnchor.y,
                } : {
                    x: wallStartAnchor.x,
                    y: data.startAnchorPos.y
                }


            }
        } /* else if (Math.abs(endAnchor[wallDirect] - wEndAnchorPos[wallDirect]) <= wallThickness / 3) {

            return {
                targetAnchor: wEndAnchorPos,
                approachEndAnchor: wallDirect === 'x' ? {
                    x: wEndAnchorPos.x,
                    y: wallStartAnchor.y,
                } : {
                    x: wallStartAnchor.x,
                    y: wEndAnchorPos.y
                }
            }

        } */
    }
    return null;
}


/**
 * 计算当前点是否接近终点
 * @param firstWall 
 * @param currentPos 
 * @param wallThickness 
 * @returns 
 */
export const computeIsCurrentPosApproachStartAnchorPos = (
    firstWall: WallData, 
    currentPos: Vector2d,
    wallStartAnchor: Vector2d,
    wallDirect: "x" | "y",
    wallThickness: number
) => {
    if(!firstWall) {
        return null;
    }
    // const endAnchorPos = computeWallEndAnchorPos(wallStartAnchor, wallLength, wallDirect, wallThickness);
    const firstStartAnchor = firstWall.startAnchorPos;
    // console.log(Math.abs(endAnchorPos.x - firstStartAnchor.x));
    
    if(Math.abs(currentPos.x - firstStartAnchor.x) <= wallThickness/*  / 2 */ &&
       Math.abs(currentPos.y - firstStartAnchor.y) <= wallThickness/*  / 2 */) {
        const sign = computeWallDirectSign(firstWall.wallLength);
        return {
            targetAnchor: firstStartAnchor,
            approachEndAnchor: wallDirect === 'x' ? {
                x: firstStartAnchor.x + sign * wallThickness / 2,
                y: wallStartAnchor.y,
            } : {
                x: wallStartAnchor.x,
                y: firstStartAnchor.y + sign * wallThickness / 2
            }
        }
    }else {
        return null;
    }
}


export const computeIsWallCrossOtherWall = (
    otherWalls: Drawed<DrawWallType, WallData>[],
    wallStartAnchorPos: Vector2d,
    wallLength: number,
    wallDirect: "x"| "y",
    wallThickness: number
) => {
    const endAnchorPos = computeWallEndAnchorPos(wallStartAnchorPos, wallLength, wallDirect, wallThickness);
    const sign = computeWallDirectSign(wallLength);
    const line = {
        start: {
            x: wallStartAnchorPos.x - (wallDirect === 'x' ? sign * wallThickness / 2 : 0),
            y: wallStartAnchorPos.y - (wallDirect === 'y' ? sign * wallThickness / 2 : 0),
        },
        end: {
            x: endAnchorPos.x + (wallDirect === 'x' ? sign * wallThickness / 2 : 0),
            y: endAnchorPos.y + (wallDirect === 'y' ? sign * wallThickness / 2 : 0),
        }
    }
    for (let i = 0; i < otherWalls.length; i++) {
        const w = otherWalls[i];
        if(w.type === 'wall-straight') {
            const { startAnchorPos, endAnchorPos } = w.data;
            const line2 = {
                start: {
                    x: startAnchorPos.x - (wallDirect === 'x' ? sign * wallThickness / 2 : 0),
                    y: startAnchorPos.y - (wallDirect === 'y' ? sign * wallThickness / 2 : 0),
                },
                end: {
                    x: endAnchorPos.x + (wallDirect === 'x' ? sign * wallThickness / 2 : 0),
                    y: endAnchorPos.y + (wallDirect === 'y' ? sign * wallThickness / 2 : 0),
                }
            }
            if(areaLinesIntersecting(line, line2)) {
                return true;
            }
        }else {
            
        }
    }
    return false;
}

export const computeWallContours = (points: Vector2d[], wallThickness: number) => {

    let line1: Vector2d[] =[];
    let line2: Vector2d[] = [];

    for (let i = 1; i < points.length; i++) {
        const lastPoint = points[i - 1];
        const point = points[i];
        
    }


}