import { ReactNode, useEffect, useState } from "react";
import { Layer, Stage } from "react-konva"

interface Props {
    ruleScale?: number;
    draggable?: boolean;
    zoomable?: boolean;
    height?: number;
    width?: number;
    children?: ReactNode | ReactNode[];
    onStageScaleChange?: (stageScale: number) => void;
}

export const RuleStage = (props: Props) => {

    const [ruleSize, setRuleSize] = useState(24)
    const [ruleScale, setRuleScale] = useState(props.ruleScale || 1);  //1px = 2mm

    const [height, setHeight] = useState((props.height || window.innerHeight));
    const [width, setWidth] = useState((props.width || window.innerWidth));

    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [stageScale, setStageScale] = useState(1);
    const [grabing, setGrabing] = useState(false);

    useEffect(() => {
        props.onStageScaleChange && props.onStageScaleChange(stageScale)
    }, [stageScale]);

    useEffect(() => {
        setRuleScale(props.ruleScale || 1);
    }, [props.ruleScale]);

    return (
        <div style={{ background: "#000" }}>
            <Rule height={ruleSize} width={width - ruleSize} scale={ruleScale * stageScale} offsetX={ruleSize} />
            <div style={{ display: 'flex' }}>
                <Rule width={ruleSize} height={height - ruleSize} scale={ruleScale * stageScale} />
                <Stage 
                    draggable={props.draggable}
                    height={height - ruleSize} 
                    width={width - ruleSize} 
                    x={stageX}
                    y={stageY}
                    scaleX={stageScale} 
                    scaleY={stageScale}     
                    style={{ cursor: 'pointer', background: "#000" }}
                    onDblClick={props.zoomable ? (e) => {
                        e.evt.preventDefault();
                        const stage = e.target.getStage();
                        stage.setAttr("x", 0);
                        stage.setAttr("y", 0);
                        setStageScale(1);
        
                    } : undefined}
                    onWheel={props.zoomable ? (e) => {
                        e.evt.preventDefault();
                        const stage = e.target.getStage();
                        const pointer = stage.getPointerPosition();
                        const oldScale = stage.scaleX();
                        const scaleBy = 1.2;
        
                        var mousePointTo = {
                            x: (pointer.x - stage.x()) / oldScale,
                            y: (pointer.y - stage.y()) / oldScale,
                        };
                        // how to scale? Zoom in? Or zoom out?
                        let direction = e.evt.deltaY > 0 ? -1 : 1;
        
                        // when we zoom on trackpad, e.evt.ctrlKey is true
                        // in that case lets revert direction
                        if (e.evt.ctrlKey) {
                            direction = -direction;
                        }
        
                        var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        
                        // stage.scale({ x: newScale, y: newScale });
        
                        var newPos = {
                            x: pointer.x - mousePointTo.x * newScale,
                            y: pointer.y - mousePointTo.y * newScale,
                        };
                        setStageScale(newScale);
                        setStageX(newPos.x);
                        setStageY(newPos.y);
                    } : undefined}
                    onMouseDown={() => {
                        if (props.draggable) {
                            setGrabing(true);
                        }
                    }}
                    onMouseUp={() => {
                        if (props.draggable) {
                            setGrabing(false);
                        }
                    }}
                >
                    {props.children}
                </Stage>
            </div>
        </div>
    )

}

const Rule = (props: {
    height: number, //相对浏览器
    width: number,  //相对浏览器
    scale: number,   //1：1px = 1mm, 2:1px = 2mm   scale = mm / px
    offsetY?: number,
    offsetX?: number,
    color?: string;
}) => {
    const [direction] = useState<"row" | "column">(() => {
        if (props.width > props.height) {
            return "row";
        } else {
            return "column";
        }
    });

    const [borderType] = useState<"border-top" | "border-left">(() => {
        if (props.width > props.height) {
            return "border-left";
        } else {
            return "border-top";
        }
    });



    const [a] = useState(() => {
        if(props.width > props.height) {
            return "width";
        }else {
            return "height";
        }
    });
    const [b] = useState(() => {
        if(props.width > props.height) {
            return "height";
        }else {
            return "width";
        }
    });
    const [c] = useState(() => {
        if(props.width > props.height) {
            return "margin-left";
        }else {
            return "margin-top";
        }

    });
    const [ruleWidth] = useState(() => {
        return Math.min(props.width, props.height)// 100mm / scale = ?px
    });
    const [writingMode] = useState<'horizontal-tb' | 'vertical-rl'>(() => {
        return props.width > props.height ? 'horizontal-tb' : 'vertical-rl';
    });
    const [color] = useState(props.color || "gray");

    //可能会动态变化
    const [unit] = useState(100);
    const [scale, setScale] = useState(props.scale || 1)
    const [count, setCount] = useState(() => {
        const length = Math.max(props.width, props.height);
        return Math.floor(length / (unit * (props.scale || 1) )) + 1;
    });
    useEffect(() => {
        const length = Math.max(props.width, props.height);
        const count = Math.floor(length / (unit * (props.scale || 1) )) + 1;
        setScale(props.scale || 1);
        setCount(count);
        // console.log("props.scale", props.scale);
        
    }, [props.scale])

    return (
        <div style={{
            background: "#000",
            position: 'relative', left: props.offsetX || undefined, top: props.offsetY || undefined,
            display: 'flex', flexDirection: direction, justifyContent: 'flex-start', flexWrap: 'nowrap', overflow: 'hidden',
            width: props.width, height: props.height,
            [props.width > props.height ? 'border-bottom' : 'border-right']: `1px solid ${color}`
        }}>
            {
                new Array(count).fill(null).map(((_, index) => {
                    return (

                        <div style={{ 
                            display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: direction === 'column' ? 'row' : 'column',
                            [borderType]: `1px solid ${color}`,  
                            [a]: unit * scale, 
                            [b]: ruleWidth, 
                        }}>
                            <span style={{color: "yellow", writingMode: writingMode, fontSize: 12, transform: `scale(${scale > 1 ? 1 : scale * 1.5})`, [c]: 4}}>{(index) * unit}</span>
                            <div style={{
                                display: 'flex', flexDirection: direction, justifyContent: 'space-around', [a]: unit * scale}}>
                                {
                                    new Array(10).fill(null).map((_, index) => {
                                        return (
                                            <div style={{
                                                [b]: ruleWidth * 0.4, 
                                                [a]: unit * scale, 
                                                [borderType === 'border-left' ? 'border-right' : 'border-bottom']: index === 9 ? 0 : `1px solid ${color}`}}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }))
            }
        </div>
    )
}