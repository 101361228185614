
import { DirectX, DirectY } from "../../../constants/enum";
import { menneiBoard } from "../地柜/01-mennei";
import { _05beineiBoard } from "../转角柜/05-beinei";

export const _10gecengneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
 ): [Path[], Path[]]  => {

   return menneiBoard(dx, dy, startX, startY, width, height, true, -1);
 }