import { _11ceneiBoardLeft } from "./11-cenei-left"

export const _11ceneiBoardRight = (
    dx: number, 
    dy: number, 
    startX: number, 
    startY: number, 
    width: number, 
    height: number
): [Path[], Path[]] => {
    return _11ceneiBoardLeft(dx, dy, startX, startY, width, height);
}