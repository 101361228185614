import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach } from "../../../util";
import { pathMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner5WaiWujiao } from "./corner5-wai-wujiao";


export const corner12WaiWujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }, special?: number): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner5WaiWujiao(dx, dy, startX, startY, true, size, special);
    paths = pathRotate(paths, - dx * dy * 90);
    paths = pathMoveX(paths, DirectX.RIGHT, dx * size.height);
    paths = pathMoveY(paths, DirectY.DOWN, dy * (size.height - size.width));
    if (typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = size.height;
            sizeRes.height = size.width;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);
}
