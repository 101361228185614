import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach } from "../../../util";

export const corner2WaiWujiao = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    corner: boolean,
    sizeRes?: { width: number, height: number}
    
): Path[] => {
    let paths: Path[] = [{
        type: Line,
        params: [
            startX, startY,
            startX + dx * 1.995, startY,
        ],
    }, 
    {
        type: Line,
        params: [
            startX + dx * 1.995, startY,
            startX + dx * 1.995, startY + dy * 1.92,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2,
            2.61,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2 + dy * 15.75,
            3.11,
        ]
    }, {
        type: Circle,
        params: [
                startX + dx * 32.385, startY + dy * 1.92 / 2 + dy * 15.75 + dy * 15.75,
                2.61,
        ]
    }, {

        type: Line,
        params: [
            startX + dx * 1.995, startY + dy * 1.92,
            startX, startY + dy * 1.92,
        ]
    },
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92,
            startX, startY + dy * 1.92 + dy * 29.58,
        ]
    },
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58,
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58,
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
        ]
    }, 
    //====================================
    {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
            startX, startY + dy * 77.488293
        ]
    },
    {
        type: Line,
        params: [
            startX, startY + dy * 77.488293,
            startX + dx * 34.00035, startY + dy * 77.488293
        ]
    }
    ];
    if(typeof sizeRes === 'object') {
        sizeRes.width = 34.00035;
        sizeRes.height = 77.488293;
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);

}