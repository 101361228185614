import { DndContext, DragMoveEvent, DragStartEvent, MeasuringStrategy, rectIntersection } from "@dnd-kit/core"
import { PropsWithChildren } from "react"
import { BoardActionMap, BoardMoveAction, BoardRotateAction, DraggableData } from "./draggable-data";
import { NestBoard, NestSheet } from "../../../../../@interface";
import { sheetBoardDragStore } from "../store";
import { message } from "antd";



let intersect = false;
let moving = false;


interface Props extends PropsWithChildren {

}

let linesTemp: ({x?: number, y?: number})[] = [];
let targetSheetTemp:NestSheet | null = null;
let approchLines: ({x?: number, y?: number})[] = [];

/**
 * 计算辅助线
 * @param e 
 * @param data 
 */
const easy_computeHelperLines = (e: DragStartEvent, data: DraggableData, _sheet = data.sheet) => {
    const { board, scale, horizontalScale, layout, actionMap, sheetSettings, contextMap  } = data;
    const sheet = _sheet;
    const space = sheetSettings?.clipSpace || 10;
    linesTemp = [];
    let _scale = scale;
    if(layout === 'horizontal'){
        _scale = horizontalScale;
    }
    let sheetWidth = sheet.width;
    let sheetHeight = sheet.height;
    if(layout === 'horizontal') {
        sheetWidth = sheet.height;
        sheetHeight = sheet.width;
    }
    linesTemp.push({x: space / 2 * _scale});
    linesTemp.push({y: (sheetHeight - space / 2) * _scale});
    linesTemp.push({x: (sheetWidth - space / 2) * _scale});
    linesTemp.push({y: (sheetHeight - (sheetHeight - space / 2)) * _scale});
    
    for (let i = 0; i < sheet.boards.length; i++) {
        const sheetBoard = sheet.boards[i];
        if(sheetBoard.board.id === board.board.id) {
            continue ;
        }
        const sheetBoardActions = actionMap[sheetBoard.board.id] || [];
        const rotateActions = sheetBoardActions.filter((a) => a.type === 'rotate') as BoardRotateAction[];
        let sheetBoardRotate = false;
        if (rotateActions.length > 0) {
            sheetBoardRotate = rotateActions[rotateActions.length - 1].rotate;
        }
        const moveActions = sheetBoardActions.filter((a) => a.type === 'move') as BoardMoveAction[];
        let sheetBoardDeltaX = 0;
        let sheetBoardDeltaY = 0;
        moveActions.forEach((a) => {
            sheetBoardDeltaX += a.deltaX;
            sheetBoardDeltaY += a.deltaY;
        });
        const rect = boardToRect(sheetBoard, layout, sheetBoardRotate, sheetBoardDeltaX, sheetBoardDeltaY);
        
        if((rect.x - space ) > (0 + space / 2)) {
            const x = rect.x - space;
            linesTemp.push({x: x * _scale});
        }
        if((rect.x + rect.width + space) < (0 + sheetWidth - space / 2)) {
            const x =  rect.x + rect.width + space;
            linesTemp.push({x: x * _scale});
        }
        if(layout === 'horizontal') {
            if((sheetHeight - (rect.y - space)) > (0 + space / 2)) {
                const y = sheetHeight - (rect.y - space);
                linesTemp.push({y: y * _scale});
            }
            if((sheetHeight - (rect.y + rect.height + space)) < (0 + sheetHeight - space / 2)) {
                const y = sheetHeight - (rect.y + rect.height + space);
                linesTemp.push({y: y * _scale});
            }

        }else {
            if((rect.y - space) > (0 + space / 2)) {
                const y = (rect.y - space);
                linesTemp.push({y: y * _scale});
            }
            if((rect.y + rect.height + space) < (0 + sheetHeight - space / 2)) {
                const y =  (rect.y + rect.height + space);
                linesTemp.push({y: y * _scale});
            }

        }
    }
    // contextMap[board.board.id].onSetHelperLines(linesTemp); //重置approchLines    
}
/**
 * easy 自动处理scale
 * @param x scale之后的
 * @param y scale之后的
 * @param lines 
 */
const easy_getApprochLines = (rect: Rectangle, layout: "horizontal" | "vertical", sheetHeight: number, space: number, scale: number, lines: ({x?: number, y?: number})[] = linesTemp) => {
    const approchLines: {x?: number, y?: number}[] = [];
    const xLines = lines.filter((l) => l.x);
    const yLines = lines.filter((l) => l.y);

    let topBorder
    if(layout === 'horizontal') {
        topBorder = yLines.find((l) =>{
            if(Math.abs(l.y - (sheetHeight - rect.y) * scale) < space * 1 * scale)  {
                return true;
            }
            return false;
        });

    }else {
        topBorder = yLines.find((l) =>{
            if(Math.abs(l.y - rect.y * scale) < space * 1 * scale)  {
                return true;
            }
            return false;
        });

    }
    if(topBorder){
        approchLines.push(topBorder);
    }
    let bottomBorder;
    if(layout === 'horizontal') {
        bottomBorder = yLines.find((l) =>{
            if(Math.abs(l.y - (sheetHeight - (rect.y + rect.height)) * scale) < space * 1 * scale)  {
                return true;
            }
            return false;
        });

    }else {
        bottomBorder = yLines.find((l) =>{
            if(Math.abs(l.y - (rect.y + rect.height) * scale) < space * 1 * scale)  {
                return true;
            }
            return false;
        });
    }
    if(bottomBorder){
        approchLines.push(bottomBorder);
    }
    const leftBorder = xLines.find((l) => {
        if(Math.abs(l.x - rect.x * scale) < space * 1 * scale) {
            return true;
        }
        return false;
    });
    if(leftBorder){
        approchLines.push(leftBorder);
    }
    const rightBorder = xLines.find((l) => {
        if(Math.abs(l.x - (rect.x + rect.width)  * scale) < space * 1 * scale) {
            return true;
        }
        return false;
    });
    if(rightBorder){
        approchLines.push(rightBorder);
    }
    return approchLines;
}

const easy_showApprochLines = (e: DragMoveEvent, data: DraggableData) => {
    const { board, sheet, contextMap, layout, actionMap, sheetSettings }  = data;
    //显示辅助线
    let scale = data.scale;
    if(data.layout === 'horizontal'){
        scale = data.horizontalScale;
    }
    const space = sheetSettings?.clipSpace || 10;

    const boardActions = actionMap[board.board.id] || [];
    const rotateActions = boardActions.filter((a) => a.type === 'rotate') as BoardRotateAction[];
    let boardRotate = false;
    if (rotateActions.length > 0) {
        boardRotate = rotateActions[rotateActions.length - 1].rotate;
    }
    const moveActions = boardActions.filter((a) => a.type === 'move') as BoardMoveAction[];
    let boardDeltaX = 0;
    let boardDeltaY = 0;
    moveActions.forEach((a) => {
        boardDeltaX += a.deltaX;
        boardDeltaY += a.deltaY;
    });

    const offsetX = (data.layout === 'horizontal' ? -e.delta.y : e.delta.x) / scale;
    const offsetY = (data.layout === 'horizontal' ? e.delta.x : e.delta.y) / scale;
    const boardRect = boardToRect(board, layout, boardRotate, (boardDeltaX + offsetX), (boardDeltaY + offsetY));
    approchLines = easy_getApprochLines(
        boardRect,
        layout,
        layout === 'horizontal'? sheet.width : sheet.height,
        space,
        scale
    )
    //TODO 过滤和其他板贴边的辅助线
    contextMap[data.board.board.id].onSetHelperLines(approchLines);
}

export const SheetDnDContext = (props: PropsWithChildren) => {

    return (
        <DndContext
            autoScroll={{activator: 0}}
            
            onDragStart={(e) => {
                sheetBoardDragStore.draggingLock = true;
                easy_computeHelperLines(e, e.active.data.current as DraggableData);

            }}
            onDragMove={(e) => {
                const data = e.active.data.current as DraggableData;
                const { board, contextMap }  = data;
                if (!moving) {
                    moving = true;
                    contextMap[data.board.board.id].onBoardMoveStart();
                }
                if (easyIsDanger(e, data)) {
                    if (!intersect) {
                        intersect = true;
                        contextMap[board.board.id].onBoardDanger(); 
                        contextMap[board.board.id].onSetHelperLines([]); //重置approchLines
                        // linesTemp = [];
                        approchLines = []
                    }
                } else {
                    //取消danger
                    if (intersect) {
                        intersect = false;
                        contextMap[board.board.id].onBoardDangerCancel();
                    }
                    easy_showApprochLines(e, data);
                }
            }}
            onDragOver={(e) => {
                const data = e.active.data.current as DraggableData;
                const { board, contextMap }  = data;
                if(!e.over){
                    return ;
                }
                const overSheet = e.over.data.current as NestSheet;
                if(overSheet !== targetSheetTemp) {
                    targetSheetTemp = overSheet;
                    easy_computeHelperLines(e, e.active.data.current as DraggableData, overSheet);
                    contextMap[board.board.id].onShowMoveTargetSheet(overSheet);
                }

            }}
            onDragEnd={(e) => {
                moving = false;
                const data = e.active.data.current as DraggableData;
                //NOTICE: 移动时为了方便计算，sheet实际上是targetSheet
                const { contextMap, board, sheet, autoApproachLine, layout, actionMap } = data;
                contextMap[board.board.id].onBoardMoveEnd();
                let boardHasMovedToOtherSheet = false;
                if (data.actionMode === 'safe' ? !easyIsDanger(e, data) : true) {
                    if(autoApproachLine) {
                        let scale = data.scale;
                        if(data.layout === 'horizontal'){
                            scale = data.horizontalScale;
                        }
                        const boardActions = actionMap[board.board.id] || [];
                        const rotateActions = boardActions.filter((a) => a.type === 'rotate') as BoardRotateAction[];
                        let boardRotate = false;
                        if (rotateActions.length > 0) {
                            boardRotate = rotateActions[rotateActions.length - 1].rotate;
                        }
                        const moveActions = boardActions.filter((a) => a.type === 'move') as BoardMoveAction[];
                        let boardDeltaX = 0;
                        let boardDeltaY = 0;
                        moveActions.forEach((a) => {
                            boardDeltaX += a.deltaX;
                            boardDeltaY += a.deltaY;
                        });
                        const offsetX = (data.layout === 'horizontal' ? -e.delta.y : e.delta.x) / scale;
                        const offsetY = (data.layout === 'horizontal' ? e.delta.x : e.delta.y) / scale;
                        const boardRect = boardToRect(board, layout, boardRotate, (boardDeltaX + offsetX), (boardDeltaY + offsetY));
                        const xApprochLines = approchLines.filter((a) => a.x);
                        const yApprochLines = approchLines.filter((a) => a.y);
                        xApprochLines.sort((a, b) => {
                            return Math.min(Math.abs(a.x - boardRect.x * scale), Math.abs(a.x - (boardRect.x + boardRect.width) * scale)) > 
                                        Math.min(Math.abs(b.x - boardRect.x * scale), Math.abs(b.x - (boardRect.x + boardRect.width) * scale)) 
                                            ? 1 : -1;
                        });
                        yApprochLines.sort((a, b) => {
                            return Math.min(Math.abs(a.y - boardRect.y * scale), Math.abs(a.y - (boardRect.y + boardRect.height) * scale)) > 
                                Math.min(Math.abs(b.y - boardRect.y * scale), Math.abs(b.y - (boardRect.y + boardRect.height) * scale)) 
                                    ? 1 : -1;
                        });
                        let xBorder = xApprochLines.length > 0 ? xApprochLines[0].x : -1;
                        let yBorder = yApprochLines.length > 0 ? yApprochLines[0].y : -1;
                        
                        
                        let xMoved = data.layout === 'horizontal' ? -e.delta.y / data.horizontalScale : e.delta.x / data.scale;
                        let yMoved = data.layout === 'horizontal' ? e.delta.x / data.horizontalScale : e.delta.y / data.scale;
                        if(yBorder> 0){
                            if(layout === 'horizontal') {
                                yBorder = sheet.width * scale - yBorder;
                                yBorder /= scale;
                                console.log("yBorder", yBorder);
                                if(Math.abs(yBorder - boardRect.y) < Math.abs(yBorder - (boardRect.y + boardRect.height))){
                                    //上界
                                    xMoved += (yBorder - boardRect.y);
                                }else {
                                    //下届
                                    xMoved += (yBorder - (boardRect.y + boardRect.height));
                                }
                            }else  {
                                yBorder /= scale;
                                if(Math.abs(yBorder - boardRect.y) < Math.abs(yBorder - (boardRect.y + boardRect.height))){
                                    //上界
                                    yMoved += (yBorder - boardRect.y);
                                }else {
                                    //下届
                                    yMoved += (yBorder - (boardRect.y + boardRect.height));
                                }

                            }
                        }
                        if(xBorder > 0){
                            xBorder /= scale;
                            console.log("xBorder", xBorder);
                            if(layout === 'horizontal') {
                                if(Math.abs(xBorder - boardRect.x) < Math.abs(xBorder - (boardRect.x + boardRect.width))){
                                    //上界
                                    yMoved += (xBorder - boardRect.x);
                                }else {
                                    //下届
                                    yMoved += (xBorder - (boardRect.x + boardRect.width));
                                }

                            }else {
                                if(Math.abs(xBorder - boardRect.x) < Math.abs(xBorder - (boardRect.x + boardRect.width))){
                                    //上界
                                    xMoved += (xBorder - boardRect.x);
                                }else {
                                    //下届
                                    xMoved += (xBorder - (boardRect.x + boardRect.width));
                                }
                            }
                        }
                        data.contextMap[data.board.board.id].onBoardMoved(xMoved, yMoved);
                        if(targetSheetTemp) {
                            data.contextMap[data.board.board.id].onBoardMoveToSheet(targetSheetTemp);
                            boardHasMovedToOtherSheet = true;
                        }
                    }else {
                        data.contextMap[data.board.board.id].onBoardMoved(
                            data.layout === 'horizontal' ? -e.delta.y / data.horizontalScale : e.delta.x / data.scale,
                            data.layout === 'horizontal' ? e.delta.x / data.horizontalScale : e.delta.y / data.scale,
                        );
                        if(targetSheetTemp) {
                            data.contextMap[data.board.board.id].onBoardMoveToSheet(targetSheetTemp);
                            boardHasMovedToOtherSheet = true;
                        }
                    }
                }
                if(targetSheetTemp) {
                    if(boardHasMovedToOtherSheet) {
                        message.success("移动成功");
                    }else {
                        message.warning("回到原位");
                    }

                }

                
                intersect = false;
                linesTemp = [];
                targetSheetTemp = null;
                sheetBoardDragStore.draggingLock = false;
                contextMap[board.board.id].onSetHelperLines([]);
                contextMap[board.board.id].onShowMoveTargetSheet(null);
            }}
            onDragCancel={(e) => {
            }}
        >
            {props.children}
        </DndContext>
    )



}


/**
 * 将板转化为rect，抽象层面，相对统一的坐标系
 * @param board 
 * @param layout 
 * @param rotate 
 * @param offsetX 
 * @param offsetY 
 * @returns 
 */
const boardToRect = (board: NestBoard, layout: "horizontal" | "vertical", rotate: boolean, offsetX = 0, offsetY = 0): Rectangle => {
    return {
        [layout === 'horizontal' ? 'y' : 'x']: (board.customX + offsetX),
        [layout === 'horizontal' ? 'x' : 'y']: (board.customY + offsetY),
        [layout === 'horizontal' ? 'height' : 'width']: rotate ? board.height : board.width,
        [layout === 'horizontal' ? 'width' : 'height']: rotate ? board.width : board.height,
    } as Rectangle;
}

/**
 * 小板是否相交
 * @param board 
 * @param sheet 
 * @returns 
 */
const easy_isSheetBoardIntersect = (e: DragMoveEvent, data: DraggableData) => {
    const { layout, actionMap, board, sheet } = data;
    const { delta } = e;
    let scale = data.scale;
    if (data.layout === 'horizontal') {
        scale = data.horizontalScale;
    }
    const offsetX = (layout === 'horizontal' ? -delta.y : delta.x) / scale;
    const offsetY = (layout === 'horizontal' ? delta.x : delta.y) / scale;
    const boardActions = actionMap[board.board.id] || [];
    const rotateActions = boardActions.filter((a) => a.type === 'rotate') as BoardRotateAction[];
    let boardRotate = false;
    if (rotateActions.length > 0) {
        boardRotate = rotateActions[rotateActions.length - 1].rotate;
    }
    const moveActions = boardActions.filter((a) => a.type === 'move') as BoardMoveAction[];
    let boardDeltaX = 0;
    let boardDeltaY = 0;
    moveActions.forEach((a) => {
        boardDeltaX += a.deltaX;
        boardDeltaY += a.deltaY;
    });
    for (let i = 0; i < sheet.boards.length; i++) {
        const sheetBoard = sheet.boards[i];
        if (sheetBoard.board.id === board.board.id) {
            continue;
        }
        const sheetBoardActions = actionMap[sheetBoard.board.id] || [];
        const rotateActions = sheetBoardActions.filter((a) => a.type === 'rotate') as BoardRotateAction[];
        let sheetBoardRotate = false;
        if (rotateActions.length > 0) {
            sheetBoardRotate = rotateActions[rotateActions.length - 1].rotate;
        }
        const moveActions = sheetBoardActions.filter((a) => a.type === 'move') as BoardMoveAction[];
        let sheetBoardDeltaX = 0;
        let sheetBoardDeltaY = 0;
        moveActions.forEach((a) => {
            sheetBoardDeltaX += a.deltaX;
            sheetBoardDeltaY += a.deltaY;
        });
        if (isRectIntersect(
                boardToRect(board, layout, boardRotate, (boardDeltaX + offsetX), (boardDeltaY + offsetY)),
                boardToRect(sheetBoard, layout, sheetBoardRotate, sheetBoardDeltaX, sheetBoardDeltaY)
        )) {
            return true;
        }
        // if (boardRotate) {
        //     if (sheetBoardRotate) {
        //         if (isRectIntersect({ 
        //             [layout === 'horizontal' ? 'y' : 'x']: board.customX + offsetX + boardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: board.customY + offsetY + boardDeltaY, 
        //             [layout === 'horizontal' ? 'height' : 'width']: board.height,
        //             [layout === 'horizontal' ? 'width' : 'height']: board.width,
        //         } as Rectangle, { 
        //             [layout === 'horizontal' ? 'y' : 'x']: sheetBoard.customX + sheetBoardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: sheetBoard.customY + sheetBoardDeltaY, 
        //             [layout === 'horizontal' ? 'height' : 'width']: sheetBoard.width,
        //             [layout === 'horizontal' ? 'width' : 'height']: sheetBoard.height,
        //         } as Rectangle)) {
        //             return true;
        //         }

        //     } else {
        //         if (isRectIntersect({ 
        //             [layout === 'horizontal' ? 'y' : 'x']: board.customX + offsetX + boardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: board.customY + offsetY + boardDeltaY, 
        //             [layout === 'horizontal' ? 'height' : 'width']: board.height,
        //             [layout === 'horizontal' ? 'width' : 'height']: board.width,
        //         } as Rectangle, { 
        //             [layout === 'horizontal' ? 'y' : 'x']: sheetBoard.customX + sheetBoardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: sheetBoard.customY + sheetBoardDeltaY,
        //             [layout === 'horizontal' ? 'height' : 'width']: sheetBoard.width,
        //             [layout === 'horizontal' ? 'width' : 'height']: sheetBoard.height,
        //         } as Rectangle)) {
        //             return true;
        //         }
        //     }

        // } else {
        //     if (sheetBoardRotate) {
        //         if (isRectIntersect({ 
        //             [layout === 'horizontal' ? 'y' : 'x']: board.customX + offsetX + boardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: board.customY + offsetY + boardDeltaY, 
        //             [layout === 'horizontal' ? 'height' : 'width']: board.width,
        //             [layout === 'horizontal' ? 'width' : 'height']: board.height,
        //         } as Rectangle, { 
        //             [layout === 'horizontal' ? 'y' : 'x']: sheetBoard.customX + sheetBoardDeltaX,
        //             [layout === 'horizontal' ? 'x' : 'y']: sheetBoard.customY + sheetBoardDeltaY,
        //             [layout === 'horizontal' ? 'height' : 'width']: sheetBoard.height,
        //             [layout === 'horizontal' ? 'width' : 'height']: sheetBoard.width,
        //         } as Rectangle)) {
        //             return true;
        //         }

        //     } else {
        //         if (isRectIntersect({ 
        //             [layout === 'horizontal' ? 'y' : 'x']: board.customX + offsetX + boardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: board.customY + offsetY + boardDeltaY, 
        //             [layout === 'horizontal' ? 'height' : 'width']: board.width,
        //             [layout === 'horizontal' ? 'width' : 'height']: board.height,
        //         } as Rectangle, { 
        //             [layout === 'horizontal' ? 'y' : 'x']: sheetBoard.customX + sheetBoardDeltaX, 
        //             [layout === 'horizontal' ? 'x' : 'y']: sheetBoard.customY + sheetBoardDeltaY,
        //             [layout === 'horizontal' ? 'height' : 'width']: sheetBoard.width,
        //             [layout === 'horizontal' ? 'width' : 'height']: sheetBoard.height,
        //         } as Rectangle)) {
        //             return true;
        //         }

        //     }
        // }
    }
    return false;
}

/**
 * 板子是否被sheet包含
 */
const easy_isSheetContainBoard = (e: DragMoveEvent, data: DraggableData) => {
    const { board, actionMap, layout, sheet } = data;
    const boardActions = actionMap[board.board.id] || [];
    const rotateActions = boardActions.filter((a) => a.type === 'rotate') as BoardRotateAction[];
    let boardRotate = false;
    if (rotateActions.length > 0) {
        boardRotate = rotateActions[rotateActions.length - 1].rotate;
    }
    const moveActions = boardActions.filter((a) => a.type === 'move') as BoardMoveAction[];
    let deltaX = 0;
    let deltaY = 0;
    moveActions.forEach((a) => {
        deltaX += a.deltaX;
        deltaY += a.deltaY;
    });
    let scale = data.scale;
    if (data.layout === 'horizontal') {
        scale = data.horizontalScale;
    }

    const offsetX = (data.layout === 'horizontal' ? -e.delta.y : e.delta.x) / scale;
    const offsetY = (data.layout === 'horizontal' ? e.delta.x : e.delta.y) / scale;
    const boardRect = boardToRect(board, layout, boardRotate, (deltaX + offsetX), (deltaY + offsetY));
    // boardRotate ? {
    //     [data.layout === 'horizontal' ? 'y' : 'x']: (board.customX + deltaX) + offsetX,
    //     [data.layout === 'horizontal' ? 'x' : 'y']: (board.customY + deltaY) + offsetY,
    //     [data.layout === 'horizontal' ? 'height' : 'width']: board.height,
    //     [data.layout === 'horizontal' ? 'width' : 'height']: board.width,
    // } as Rectangle : {
    //     [data.layout === 'horizontal' ? 'y' : 'x']: (board.customX + deltaX) + offsetX,
    //     [data.layout === 'horizontal' ? 'x' : 'y']: (board.customY + deltaY) + offsetY,
    //     [data.layout === 'horizontal' ? 'height' : 'width']: board.width,
    //     [data.layout === 'horizontal' ? 'width' : 'height']: board.height,
    // } as Rectangle

    const sheetRect = data.layout === 'horizontal' ? {
        x: 0,
        y: 0,
        width: sheet.height,
        height: sheet.width
    } : {
        x: 0,
        y: 0,
        width: sheet.width,
        height: sheet.height
    }
    if (isRectContained(boardRect, sheetRect)) {
        return true;
    }
    return false;
}

const easyIsDanger = (e: DragMoveEvent, data: DraggableData) => {
    // const startTime = Date.now();
    const boardCompareDanger = easy_isSheetBoardIntersect(e, data);
    if (boardCompareDanger) {
        // const endTime = Date.now();
        // console.log(`boardCompareDanger`, (endTime - startTime) + "ms");
        return true;
    }
    const sheetCompareDanger = !easy_isSheetContainBoard(e, data);
    if (sheetCompareDanger) {
        // const endTime = Date.now();
        // console.log(`sheetCompareDanger`, (endTime - startTime) + "ms");
        return true;
    }
    return false;
}


//=======base func========//

type Rectangle = { x: number, y: number, width: number, height: number };
/**
 * 矩形是否相交
 * @param rect1 
 * @param rect2 
 */
const isRectIntersect = (rect1: Rectangle,
    rect2: Rectangle) => {
    const x1 = rect1.x;
    const y1 = rect1.y;
    const x2 = rect2.x;
    const y2 = rect2.y;
    const w1 = rect1.width;
    const w2 = rect2.width;
    const h1 = rect1.height;
    const h2 = rect2.height;

    // 判断两个边界框是否相交  
    if (x1 >= x2 + w2 || x1 + w1 <= x2 || y1 >= y2 + h2 || y1 + h1 <= y2) {
        return false;
    }

    return true;

}

/**
 * rect1是否被rect2包含
 * @param rect1 
 * @param rect2 
 * @returns 
 */
const isRectContained = (rect1: Rectangle, rect2: Rectangle) => {
    // 检查 rect1 的左上角是否在 rect2 的内部  
    if (rect2.x >= rect1.x || (rect1.x + rect1.width) >= (rect2.x + rect2.width)) {
        return false;
    }
    if (rect2.y >= rect1.y || (rect1.y + rect1.height) >= (rect2.y + rect2.height)) {
        return false;
    }
    return true;
}
