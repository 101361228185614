import { Circle, Layer, Line, Rect, Text } from "react-konva";
import { CANVAS_SIZE, m2px, postDefaultSize } from "../../../store"
import { useEffect, useMemo, useState } from "react";
import { isCircleInPolygon, isRectInPolygon } from "../../../gpt/isRectInPolygon";
import { calcPointDistanceToPolygon, findClosestIntersections } from "../../../gpt/calcPointDistanceToPolygon";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { Vector2d } from "konva/lib/types";
import { Vector2dLine } from "../../../types";
import { message } from "antd";
import { KonvaEventObject } from "konva/lib/Node";
import { generateUUID } from "three/src/math/MathUtils";
import { useStructDrawing } from "./store";
import { defaultFloorHeight } from "../../../store/space-store";

export const LayerDrawingStruct = (
    
) => {

    const {
        drawStructType,
        drawedArea, floorHeight, floorThickness,
        brushPos, pushDrawed, cancelDraw
    } = useStructDrawing();

    const [canPut, setCanPut] = useState(false);
    
    const [offsetHelper, setOffsetHelper] = useState<{
        top: number, left: number, bottom: number, right: number,
        topLine: Vector2dLine, leftLine: Vector2dLine, bottomLine: Vector2dLine, rightLine: Vector2dLine
    }>(null);

    const [closestXHelper, setClosestXHelper] = useState<Vector2dLine>(null);
    const [closestYHelper, setClosestYHelper] = useState<Vector2dLine>(null);

    const [beamDirect, setBeamDirect] = useState<"x" | 'y'>(null);

    const [isDanger, setIsDanger] = useState(true);

    useEffect(() => {
        setBeamDirect(drawStructType?.indexOf("struct-beam") === 0 ? drawStructType.replace('struct-beam-', '') as ("x" | "y") : null);
        if(!drawStructType) {
            resetDraw();
        }
    }, [drawStructType])

    useEffect(() => {
        if (!drawStructType || !drawedArea) {
            return;
        }
        const isIn = isRectInPolygon(brushPos.x, brushPos.y, postDefaultSize, postDefaultSize, drawedArea.innerContour);
        setIsDanger(!isIn);
    }, [brushPos, drawedArea, drawStructType]);

    //计算offset辅助线
    useEffect(() => {
        if (!drawStructType || !drawedArea) {
            return;
        }
        if (isDanger) {
            setCanPut(false);
            setOffsetHelper(null);
        } else {
            const offset = findClosestIntersections(brushPos, drawedArea.innerContour);
            setOffsetHelper(offset);
            setCanPut(true);
        }
    }, [drawStructType, brushPos, drawedArea, isDanger]);

    //计算closest辅助线
    useEffect(() => {
        if (!offsetHelper) {
            return;
        }
        const approachDistance= 10 + postDefaultSize;
        let yLine: Vector2dLine = null;
        if (offsetHelper.left < approachDistance && offsetHelper.left < offsetHelper.right) {
            yLine = offsetHelper.leftLine;
        }
        if (offsetHelper.right < approachDistance && offsetHelper.right < offsetHelper.left) {
            yLine = offsetHelper.rightLine;
        }

        let xLine: Vector2dLine = null;
        if (offsetHelper.top < approachDistance && offsetHelper.top < offsetHelper.bottom) {
            xLine = offsetHelper.topLine;
        }
        if (offsetHelper.bottom < approachDistance && offsetHelper.bottom < offsetHelper.top) {
            xLine = offsetHelper.bottomLine;
        }
        setClosestXHelper(xLine);
        setClosestYHelper(yLine);
    }, [brushPos, offsetHelper]);

    const resetDraw = () => {
        
        cancelDraw();
        setCanPut(false);
        setIsDanger(true);
        setBeamDirect(null);
        setOffsetHelper(null);
        setClosestXHelper(null);
        setClosestYHelper(null);
    }


    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        if (e.evt.button === 0) {
            if (!canPut) {
                message.info("结构应该放在墙体区域内");
                return;
            }
            //计算相对brushPos的位置
            let xOffset = closestYHelper ? closestYHelper.from.x - brushPos.x + (offsetHelper.left < offsetHelper.right ? postDefaultSize / 2 : -postDefaultSize / 2) : 0;
            let yOffset = closestXHelper ? closestXHelper.from.y - brushPos.y + (offsetHelper.top < offsetHelper.bottom ? postDefaultSize / 2 : -postDefaultSize / 2) : 0;
            let size = drawStructType.indexOf("struct-beam") === 0 ? (
                beamDirect === 'x' ? {
                    x: offsetHelper.rightLine.from.x - offsetHelper.leftLine.from.x,
                    y: postDefaultSize,
                    z: postDefaultSize,
                } : {
                    x: postDefaultSize,
                    y: offsetHelper.bottomLine.from.y - offsetHelper.topLine.from.y,
                    z: postDefaultSize
                }
            ) : {
                x: postDefaultSize,
                y: postDefaultSize,
                z: -1
            };
            
            pushDrawed({
                type: drawStructType,
                data: {
                    id: generateUUID(),
                    structType: drawStructType,
                    pos: drawStructType.indexOf("struct-beam") === 0 ? (
                        beamDirect === 'x' ? {
                            x: offsetHelper.leftLine.from.x + size.x / 2,
                            y: brushPos.y + yOffset
                        } : {
                            x: brushPos.x + xOffset,
                            y: offsetHelper.topLine.from.y + size.y / 2,
                        }
                    ) : {
                        x: brushPos.x + xOffset,
                        y: brushPos.y + yOffset
                    },
                    size: size,
                    direct: drawStructType.indexOf("struct-beam") === 0 ? beamDirect : undefined,
                    offset: {
                        top: closestXHelper ? offsetHelper.top < offsetHelper.bottom ? brushPos.y - closestXHelper.from.y : offsetHelper.top : offsetHelper.top,
                        bottom: closestXHelper ? offsetHelper.bottom < offsetHelper.top ? closestXHelper.from.y - brushPos.y : offsetHelper.bottom : offsetHelper.bottom,
                        left: closestYHelper ? offsetHelper.left < offsetHelper.right ? brushPos.x - closestYHelper.from.x : offsetHelper.left : offsetHelper.left,
                        right: closestYHelper ? offsetHelper.right < offsetHelper.left ? closestYHelper.from.x - brushPos.x : offsetHelper.right : offsetHelper.right,
                        topLine: offsetHelper.topLine,
                        leftLine: offsetHelper.leftLine,
                        rightLine: offsetHelper.rightLine,
                        bottomLine: offsetHelper.bottomLine
                    },
                    groundClearance: drawStructType.indexOf("struct-beam") === 0 ? -1 : 0,
                    floorHeight,
                    floorThickness
                },
                
            });
            resetDraw();
        } else if (e.evt.button === 1) {
            // 取消鼠标中键切换方向
            // if (drawStructType.indexOf("struct-beam") === 0) {
            //     setBeamDirect(beamDirect === 'x' ? 'y' : 'x');
            // }
        }
    }

    return (
        <Layer
            visible={!!drawStructType}
        >
            {
                //梁的情况
                useMemo(() => {
                    if(!drawStructType || drawStructType.indexOf("struct-beam") === -1) {
                        return ;
                    }
                    if (!canPut) {
                        
                        
                        return (
                            <>
                            {
                                beamDirect === 'x' ? (
                                    <Rect
                                        stroke={"#f00"}
                                        fill={"#ff000011"}
                                        width={postDefaultSize * 4}
                                        height={postDefaultSize}
                                        x={brushPos.x - postDefaultSize * 2}
                                        y={brushPos.y - postDefaultSize / 2}
                                    />

                                ) : (

                                    <Rect
                                        stroke={"#f00"}
                                        fill={"#ff000011"}
                                        width={postDefaultSize}
                                        height={postDefaultSize * 4}
                                        x={brushPos.x - postDefaultSize / 2}
                                        y={brushPos.y - postDefaultSize * 2}
                                    />
                                )
                            }
                            </>
                        )
                    } else {
                        if(!offsetHelper) {
                            return ;
                        }
                        if (beamDirect === 'x') {
                            if(!offsetHelper.leftLine || !offsetHelper.rightLine) {
                                return ;
                            }
                            const left = offsetHelper.leftLine.from.x;
                            const width = offsetHelper.rightLine.from.x - offsetHelper.leftLine.from.x;
                            const height = postDefaultSize;
                            return (
                                <Rect
                                    stroke={"#ffffff"}
                                    fill={"#ffffffaa"}
                                    width={width}
                                    height={height}
                                    x={left}
                                    y={brushPos.y - height / 2}
                                />
                            )

                        } else {
                            if(!offsetHelper.topLine || !offsetHelper.bottomLine) {
                                return ;
                            }
                            const top = offsetHelper.topLine.from.y;
                            const width = postDefaultSize;
                            const height = offsetHelper.bottomLine.from.y - offsetHelper.topLine.from.y;
                            
                            return (
                                <Rect
                                    stroke={"#ffffff"}
                                    fill={"#ffffff88"}
                                    width={width}
                                    height={height}
                                    x={brushPos.x - width / 2}
                                    y={top}
                                />
                            )
                        }
                    }

                }, [isDanger, drawStructType, brushPos, offsetHelper, beamDirect])
            }

            {
                useMemo(() => {
                    if (drawStructType === 'struct-round-post') {
                        return (
                            <Circle
                                stroke={canPut ? "#000" : "#f00"}
                                fill={canPut ? "#ffffffaa" : "#ff000011"}
                                radius={postDefaultSize / 2}
                                strokeWidth={1}
                                strokeScaleEnabled={false}
                                x={brushPos.x}
                                y={brushPos.y}

                            />
                        )
                    } else if (drawStructType === 'struct-rect-post') {
                        return (
                            <Rect
                                stroke={canPut ? "#000" : "#f00"}
                                fill={canPut ? "#ffffffaa" : "#ff000011"}
                                width={postDefaultSize}
                                height={postDefaultSize}
                                strokeWidth={1}
                                strokeScaleEnabled={false}
                                x={brushPos.x - postDefaultSize / 2}
                                y={brushPos.y - postDefaultSize / 2}
                            />

                        )
                    }
                }, [drawStructType, brushPos, isDanger])
            }
            {
                useMemo(() => {
                    if (!drawStructType || !offsetHelper) {
                        return;
                    }
                    return (
                        <>
                            {
                                // x梁不显示x辅助线
                                !(drawStructType.indexOf("struct-beam") === 0 && beamDirect === 'x') && (
                                    <>
                                        <ShapeDimension
                                            direct={"x"}
                                            from={brushPos.x - offsetHelper.left}
                                            to={brushPos.x - postDefaultSize / 2}
                                            offset={0}
                                            base={brushPos.y}
                                        />
                                        <ShapeDimension
                                            direct={"x"}
                                            from={brushPos.x + postDefaultSize / 2}
                                            to={brushPos.x + offsetHelper.right}
                                            offset={0}
                                            base={brushPos.y}
                                        />
                                    </>
                                )
                            }
                            {
                                !(drawStructType.indexOf("struct-beam") === 0 && beamDirect === 'y') && (
                                    <>
                                        <ShapeDimension
                                            direct={"y"}
                                            from={brushPos.y - offsetHelper.top}
                                            to={brushPos.y - postDefaultSize / 2}
                                            offset={0}
                                            base={brushPos.x}
                                        />
                                        <ShapeDimension
                                            direct={"y"}
                                            from={brushPos.y + postDefaultSize / 2}
                                            to={brushPos.y + offsetHelper.bottom}
                                            offset={0}
                                            base={brushPos.x}
                                        />
                                    </>
                                )
                            }



                            {
                                closestXHelper && (
                                    <Line
                                        points={[
                                            closestXHelper.from.x, closestXHelper.from.y,
                                            closestXHelper.to.x, closestXHelper.to.y
                                        ]}
                                        stroke={"cyan"}
                                        strokeWidth={2}
                                        strokeScaleEnabled={false}
                                    />
                                )
                            }
                            {
                                closestYHelper && (
                                    <Line
                                        points={[
                                            closestYHelper.from.x, closestYHelper.from.y,
                                            closestYHelper.to.x, closestYHelper.to.y
                                        ]}
                                        stroke={"cyan"}
                                        strokeWidth={2}
                                        strokeScaleEnabled={false}

                                    />
                                )
                            }
                        </>
                    )
                }, [offsetHelper, brushPos, beamDirect, drawStructType])
            }
            {/* 用于接住onClick事件 */}
            {
                drawStructType && (
                    <Rect
                        x={0}
                        y={0}
                        width={CANVAS_SIZE * m2px}
                        height={CANVAS_SIZE * m2px}
                        // fill="
                        // stroke={}
                        // fill="#ff000066"
                        opacity={0}
                        onClick={onClick}
                    />
                )
            }
        </Layer>
    )


}