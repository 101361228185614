import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX } from "../../../util/graph";
import { 无护角有卡槽 } from "./config";
import { corner5WaiWuhujiao } from "./corner5-wai-wuhujiao";

export const corner6WaiWuhujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {

    const size = {width: 0, height: 0};
    let paths = corner5WaiWuhujiao(dx, dy, startX, startY, true, size);
    if(无护角有卡槽) {
        paths =  paths.slice(11, paths.length);
    }else {
        paths =  paths.slice(1, paths.length);
    }
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, size.width);
    if(typeof sizeRes === 'object') {
        sizeRes.width =  size.width;
        sizeRes.height = 34.130141;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);

}