import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { cewaiBoardLeft } from "./01-cewai-left";

export const cewaiBoardRight = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    mengao?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const [ boardPaths, cornerPaths ] = cewaiBoardLeft(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, lianjiekongbianju, corner, geceng, gecengX, gecengY, gecengkongjing, mengao, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
    const combind = conbinePaths([boardPaths, cornerPaths]);
    let mirrorCombind = pathMirrorY(combind, startX);
    mirrorCombind = pathMoveX(mirrorCombind, DirectX.RIGHT, width);
    return [
        mirrorCombind.slice(0, boardPaths.length),
        mirrorCombind.slice(boardPaths.length, boardPaths.length + cornerPaths.length),
    ]
}