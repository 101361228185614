import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner10 } from "../../coners/corner10";
import { getBottomLine, getLeftLine, getRightLine, getTopLine } from "../general-board";

export const _05dineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    x: number,
    y: number,
    guijiaoX: number,
    guijiaoY: number,
    doorWidth: number,
    nobeiwai: boolean,
    extraConfig: any
): [Path[], Path[]] => {
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL;
    let cornerBLW;
    let cornerBLH;
    if (nobeiwai) {
        cornerBL = corner1(dx, -dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);

    } else {

        cornerBL = corner10(dx, dy, startX, startY, true, cornerBLSize);
        cornerBL = pathRotate2(cornerBL, startX, startY, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;

    }
    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    
    const paths: Path[] = [];
    const b1Lines = getBottomLine("inner", dx, dy, startX, startY, (width - (x + 11)), 0, cornerBLW, 0, {
        middleware: {
            //@ts-ignore
            bottom: (((width - (x + 11)) > 500) as boolean) && "far"
        }
    });
    const r1Lines = getRightLine("inner", dx, dy, startX + dx * (width - (x + 11)), startY, 0, (y + 11), 0, 0, {
    });
    const b2Lines = getBottomLine("inner", dx, dy, startX + dx * (width - (x + 11)), startY + dy * (y + 11), (x + 11), 0, 0, 0, {
    });
    const r2Lines = getRightLine("inner", dx, dy, startX + dx * width, startY + dy * (y + 11), 0, height - (y + 11), 0, cornerTRH, {
        middleware: {
            //@ts-ignore
            right: height - (y + 11)> 500 && "far",
        }
    });
    const t1Lines = getTopLine("inner", dx, dy, startX, startY + dy * height, width, 0, cornerTRW, cornerTLW, {
        middleware: {
            //@ts-ignore
            // top: width > 500 && "far"
        }

    });
    const l1Lines = getLeftLine("inner", dx, dy, startX, startY, 0, height, cornerTLH, cornerBLH, {
        middleware: {
            //@ts-ignore
            left: height > 500 && "far"
        }
    });
    paths.push(
        ...b1Lines, 
        ...r1Lines,
        ...b2Lines,
        ...r2Lines,
        ...t1Lines,
        ...l1Lines,
    // {
    //     type: Line,
    //     params: [
    //         startX + dx * (width - (x + 11)), startY,
    //         startX + dx * (width - (x + 11)), startY + dy * (y + 11),
    //     ]
    // }, 
    // {
    //     type: Line,
    //     params: [
    //         startX + dx * (width - (x + 11)), startY + dy * (y + 11),
    //         startX + dx * width, startY + dy * (y + 11),
    //     ]
    // },
    //  {
    //     type: Line,
    //     params: [
    //         startX + dx * width, startY + dy * (y + 11),
    //         startX + dx * width, startY + dy * height - dy * cornerTRH,
    //     ]
    // }, 
    // {
    //     type: Line,
    //     params: [
    //         startX + dx * width - dx * cornerTRW, startY + dy * height,
    //         startX + dx * cornerTLW, startY + dy * height,
    //     ]
    // }, 
    // {
    //     type: Line,
    //     params: [
    //         startX, startY + dy * height - dy * cornerTLH,
    //         startX, startY + dy * cornerBLH
    //     ]
    // }
    )
    //板子四个角的四孔
    if (!extraConfig?.无柜腿孔) {

        paths.push({
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        },
            //====================================
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (guijiaoX + 1.5 + (x + 11)), startY + dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 1.5 + (x + 11)) - dx * 50, startY + dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 1.5 + (x + 11)) - dx * 50, startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 1.5 + (x + 11)), startY + dy * (guijiaoY + 12.5) + dy * 35,
                2.5
            ]
        },
            //========================================
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50, startY + dy * height - dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5) - dx * 50, startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        },
            //=====================================
            {
                type: Circle,
                params: [
                    startX + dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5),
                    2.5
                ]
            }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * height - dy * (guijiaoY + 12.5),
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5) + dx * 50, startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (guijiaoX + 12.5), startY + dy * height - dy * (guijiaoY + 12.5) - dy * 35,
                2.5
            ]
        });

    }
    //其他孔，逆时针来
    const corners: Path[] = [];
    if (!nobeiwai) {
        corners.push({
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 11) - dx * 70.53, startY + dy * 19.39,
                2.1
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 11) - dx * 70.53 + dx * 31.5, startY + dy * 19.39,
                2.1
            ]
        });
    }
    corners.push(
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 11) - dx * 9, startY + dy * 32.03,
                2.1
            ]
        }, {
        type: Circle,
        params: [

            startX + dx * width - dx * (x + 11) - dx * 9, startY + dy * 32.03 + dy * 31.5,
            2.1
        ]
    },
        //=================================
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 11) - dx * 9, startY + dy * y - dy * 24.53,
                2.1
            ]
        }, {
        type: Circle,
        params: [
            startX + dx * width - dx * (x + 11) - dx * 9, startY + dy * y - dy * 24.53 + dy * 31.5,
            2.1
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * width - dx * (x - 42.53), startY + dy * (y + 11) + dy * 9,
            2.1
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * width - dx * (x - 42.53) - dx * 31.5, startY + dy * (y + 11) + dy * 9,
            2.1
        ]
    },
        //=====================
        {
            type: Circle,
            params: [
                startX + dx * width - dx * 50.03, startY + dy * (y + 11) + dy * 9,
                2.1
            ]
        }, {
        type: Circle,
        params: [
            startX + dx * width - dx * 50.03 - dx * 31.5, startY + dy * (y + 11) + dy * 9,
            2.1
        ]
    },
        //====================
        {
            type: Circle,
            params: [
                startX + dx * (doorWidth + 2), startY + dy * height - dy * 32.03,
                2.1
            ]
        }, {
        type: Circle,
        params: [
            startX + dx * (doorWidth + 2), startY + dy * height - dy * 32.03 - dy * 31.5,
            2.1
        ]
    })
    return [
        paths,
        corners.concat(cornerBL, cornerTR, cornerTL)
    ]
}