import { DirectX, DirectY } from "../../../constants/enum";
import { _02dineiBoard } from "./02-dinei";

export const _02dingneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return _02dineiBoard(dx, dy, startX, startY, width, height);
}