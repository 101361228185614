import { createRef, CSSProperties, forwardRef, LegacyRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Arc, Circle, Group, KonvaNodeComponent, Layer, Line, Rect, Shape, Stage, StageProps, Text } from "react-konva";
import useImage from "use-image";
import Konva from 'konva';
import { B_Arc, B_Circle, B_Line, CupboardBroad, Material } from "../../../../@interface";
import { Dropdown } from "antd";
import { Context } from "konva/lib/Context";

interface Props {
    height: number;
    width: number;
    scale: number;
    board: CupboardBroad;
    material: Material;
    draggable?: boolean;
    style?: CSSProperties;
    zoomable?: boolean;
    fill?: boolean;
    background?: string;
    compatibleV1?: boolean;
}

export const BoardGraph = (props: Props) => {

    const [framePoints, setFramePoints] = useState<any[]>([]);
    const [scale, setScale] = useState(props.scale || (props.width > props.height ? props.height / props.board.height : props.width / props.board.width))
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [stageScale, setStageScale] = useState(1);

    useEffect(() => {
        setScale(props.scale)
    }, [props.scale]);

    useEffect(() => {
        if (!props.scale) {
            setScale((props.width > props.height ? props.height / props.board.height : props.width / props.board.width))
        }
    }, [props.board]);

    const [grabing, setGrabing] = useState(false);


    return (
        <Stage
            draggable={props.draggable}
            scaleX={stageScale}
            scaleY={stageScale}
            style={{ cursor: props.draggable ? grabing ? 'grabbing' : 'grab' : undefined, ...(props.style || {}) }}
            x={stageX}
            y={stageY}
            width={props.width}
            height={props.height}
            onDblClick={props.zoomable ? (e) => {
                e.evt.preventDefault();
                const stage = e.target.getStage();
                const pointer = stage.getPointerPosition();
                stage.setAttr("x", 0);
                stage.setAttr("y", 0);
                setStageScale(1);

            } : undefined}
            onWheel={props.zoomable ? (e) => {
                e.evt.preventDefault();
                const stage = e.target.getStage();
                const pointer = stage.getPointerPosition();
                const oldScale = stage.scaleX();
                const scaleBy = 1.2;

                var mousePointTo = {
                    x: (pointer.x - stage.x()) / oldScale,
                    y: (pointer.y - stage.y()) / oldScale,
                };
                // how to scale? Zoom in? Or zoom out?
                let direction = e.evt.deltaY > 0 ? -1 : 1;

                // when we zoom on trackpad, e.evt.ctrlKey is true
                // in that case lets revert direction
                if (e.evt.ctrlKey) {
                    direction = -direction;
                }

                var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                // stage.scale({ x: newScale, y: newScale });

                var newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale,
                };
                setStageScale(newScale);
                setStageX(newPos.x);
                setStageY(newPos.y);
            } : undefined}
            onMouseDown={() => {
                if (props.draggable) {
                    setGrabing(true);
                }
            }}
            onMouseUp={() => {
                if (props.draggable) {
                    setGrabing(false);
                }
            }}
        >
            <Layer >
                <RectWithAngles
                    board={props.board}
                    scale={scale}
                    stageScale={stageScale}
                    material={props.material}
                    x={(props.width - props.board.width * scale) / 2}
                    y={(props.height - props.board.height * scale) / 2}
                    fill={props.fill}
                    background={props.background || "#000"}
                    compatibleV1={props.compatibleV1}
                />
            </Layer>
        </Stage>
    )
}

export interface RectWithAnglesProps {
    text?: string,
    x?: number,
    y?: number,
    rotate?: boolean,
    scale?: number;
    stageScale?: number;
    board: CupboardBroad,
    material?: Material,
    color?: string,
    fill?: boolean;
    background?: string;
    compatibleV1?: boolean;
    defaultHide?: boolean;  //服务于hover
    defaultStrokeColor?: boolean; //服务于选中
}
export const RectWithAngles = forwardRef((props: RectWithAnglesProps, ref: any) => {

    const [x, setX] = useState(props.x || 0);
    const [y, setY] = useState(props.y || 0);
    const [scale, setScale] = useState(props.scale || 1);
    const [image, setImage] = useState(props.material?.texture.type === 'IMAGE' ? props.material.texture.value : undefined);
    const [color, setColor] = useState((props.material?.texture.type === 'COLOR' ? props.material.texture.value : props.color || '#fff'));
    const [fill, setFill] = useState(props.fill || false);



    useEffect(() => {
        setX(props.x);
    }, [props.x])

    useEffect(() => {
        setY(props.y);
    }, [props.y]);

    useEffect(() => {
        setFill(props.fill);
    }, [props.fill]);

    useEffect(() => {
        if (props.material?.texture.type === 'IMAGE') {
            setImage(props.material.texture.value);
        } else if (props.material?.texture.type === 'COLOR') {
            setColor(props.material.texture.value);
        }
    }, [props.material]);

    const groupRef = useRef<any>();
    const borderRef = useRef<any>();

    const [opacity, setOpacity] = useState((typeof props.defaultHide === 'boolean') ? props.defaultHide ? 0 : 1 : 1);

    useImperativeHandle(ref, () =>{
        return {
            hide:  ()=>{
                groupRef.current?.setAttr('opacity', 0);
            },
            show: ()=>{
                groupRef.current?.setAttr('opacity', 1);

            },
            moveReady: () =>{
                borderRef.current?.setAttr("stroke", "#06D6A0")
                borderRef.current?.setAttr("strokeWidth", 2)
            },
            danger: () => {
                borderRef.current?.setAttr("stroke", "red")
                borderRef.current?.setAttr("strokeWidth", 2)

            },
            resetStroke: ()=> {
                borderRef.current?.setAttr("stroke", fill ? color : '#fff')
                borderRef.current?.setAttr("strokeWidth", 0.5)  

            },
            setScale: (scale) => {
                groupRef.current?.setAttr('scaleX', scale);
                groupRef.current?.setAttr('scaleY', scale);
            }
            // setStrokeColor: (color: string)=> {
            //     borderRef.current?.setAttr("stroke", color);
            // }

        }
    });

    return (
        <Group
            ref={groupRef}
            x={props.rotate ? x + props.board.height * scale : x}
            y={y}
            rotation={props.rotate ? 90 : 0}
            scaleX={scale}
            scaleY={scale}
            opacity={opacity}
        >
            {
                !props.compatibleV1 ? (
                    <>

                        <Shape
                            // strokeWidth={lineWidth}
                            ref={borderRef}
                            strokeWidth={0.5}
                            strokeScaleEnabled={false}
                            sceneFunc={(context, shape) => {
                                const { computedComponents } = props.board;
                                const boardBorder = computedComponents?.find((item) => item.flags === 1);
                                if (!boardBorder) {
                                    return;
                                }
                                const data = boardBorder.shapes;
                                if (!data?.length) {
                                    return;
                                }
                                context.beginPath();
                                context.moveTo(data[0][0], props.board.height - data[0][1]);
                                for (let i = 0; i < data.length; i++) {
                                    const item = data[i];
                                    if (item.length === 4) {
                                        context.lineTo(item[2], props.board.height - item[3]);

                                    } else if (item.length === 6) {
                                        context.arcTo(item[5][4], props.board.height - item[5][5], item[5][2], props.board.height - item[5][3], item[2]);
                                    }
                                }
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            
                            stroke={fill ? color : '#fff'}
                            fill={props.compatibleV1 ? undefined : (fill && color)}
                        // fillPatternImage={image}
                        />
                        {
                            props.board.computedComponents?.filter((c) => c.flags === 0).map((component) => {
                                return (
                                    <Shape
                                        strokeWidth={0.5}
                                        strokeScaleEnabled={false}
                                        sceneFunc={(context, shape) => {
                                            const data = component.shapes;
                                            if (!data?.length) {
                                                return;
                                            }
                                            context.beginPath();
                                            if (data[0].length === 4) {
                                                context.moveTo(data[0][0], props.board.height - data[0][1]);
                                            }
                                            for (let i = 0; i < data.length; i++) {
                                                const item = data[i];
                                                if (item.length === 4) {
                                                    context.lineTo(item[2], props.board.height - item[3]);

                                                } else if (item.length === 6) {
                                                    context.arcTo(item[5][4], props.board.height - item[5][5], item[5][2], props.board.height - item[5][3], item[2]);
                                                }
                                            }
                                            context.closePath();
                                            context.fillStrokeShape(shape);
                                        }}
                                        stroke={props.fill ? props.background : "#fff"}
                                        fill={props.background}
                                    />
                                )
                            })
                        }

                        {
                            props.board.computedComponents?.map((c) => {
                                return c.shapes?.map((shape) => {
                                    if (shape.length !== 3) {
                                        return;
                                    }
                                    return (
                                        <Circle
                                            x={shape[0]}
                                            y={props.board.height - shape[1]}
                                            radius={shape[2]}
                                            // strokeWidth={lineWidth}
                                            strokeWidth={0.5}
                                            strokeScaleEnabled={false}
                                            stroke={props.fill ? props.background : "#fff"}
                                            fill={props.background}
                                        />

                                    )
                                })
                            })
                        }
                    </>
                ) : (
                    <>
                        <Rect
                            width={props.board.width}
                            height={props.board.height}
                            fill={fill && (props.background || "#000")}
                        />
                        <Shape
                            sceneFunc={(context, shape) => {
                                const offsetX = 0;
                                const offsetY = 0;
                                const height = props.board.height;
                                const scale = 1;
                                context.strokeStyle = props.color || "#fff";
                                // context.lineWidth = lineWidth;
                                context.lineWidth = 1;
                                // context.lineWidth
                                props.board.computedComponents?.forEach((component) => {
                                    component.shapes.forEach((item) => {
                                        context.beginPath();
                                        if (item.length === 3) {
                                            context.arc(item[0] * scale + offsetX, height - item[1] * scale - offsetY, item[2] * scale, 0, 2 * Math.PI);
                                        } else if (item.length === 4) {
                                            if (isNaN(item[0] * scale + offsetX)) {
                                                return;
                                            }
                                            context.moveTo(item[0] * scale + offsetX, height - item[1] * scale - offsetY);
                                            context.lineTo(item[2] * scale + offsetX, height - item[3] * scale - offsetY);
                                        } else {
                                            context.arc(
                                                item[0] * scale + offsetX, height - item[1] * scale - offsetY,
                                                item[2] * scale,
                                                (360 - item[3]) / 360 * 2 * Math.PI,
                                                (360 - item[4]) / 360 * 2 * Math.PI,
                                                true
                                            );
                                        }
                                        context.stroke();

                                    })
                                    context.closePath();
                                    context.fillStrokeShape(shape);
                                });
                            }}
                            stroke={props.color || "#fff"}
                            strokeWidth={1}
                            strokeScaleEnabled={false}
                        />
                    </>
                )
            }
        </Group>
    )

})