import { DirectX, DirectY } from "../../../constants/enum"
import { menwaiBoard } from "../地柜/01-menwai"

export const _11diwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
): [Path[], Path[]] => {
    return menwaiBoard(cornerType,dx, dy, startX, startY, width, height, cornerType === "wujiao" ? false : true, false, true);
}