import { Input } from "antd";
// import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { isEn } from "../../../utils/locale";

export const Materials = (props) => {

    const [materials, setMaterials] = useState(props.value || []);

    useEffect(() => {
        setMaterials((props.value || []).filter((item) => item.hide === false));
    }, [props.value]);


    const handlerChange = (materials) => {
        if(typeof props.onChange === 'function') {
            props.onChange(materials);
        }
    }

    const [availableMaterials, setAvailableMaterials] = useState(((props.value) || []).filter((item) => item.hide === false));
    useEffect(() => {
        setAvailableMaterials(materials.filter((item) => item.hide === false));
    }, [materials]);

    return (
        <div className="materials">
            {
                availableMaterials.length > 0 && availableMaterials.map((item, index) => {
                    return (
                        <div className="input-wrapper" style={{display: 'inline-block', marginRight: 10, textAlign: 'center'}}>
                            <Input style={{textAlign: 'center'}} placeholder={item.name} value={item.name} onChange={(e) => {
                                item.name = e.target.value;
                                materials[index] = {...item};
                                // setMaterials(materials.set(index, { ...item }));
                                handlerChange([...materials]);
                            }}/><br />
                            <span style={{color: 'gray'}}>{isEn ? "No." + (index + 1) : `第${index + 1}种`}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}