import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach, numberEqual } from "../../../util";
import { corner12WaiWujiao } from "./corner12-wai-wujiao";


export const corner14WaiWujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner12WaiWujiao(dx, dy, startX, startY, true, size, dx * 28.07);
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        // if(path.type === Circle)
        // //console.log(path.params[0]);
        if(path.type === Circle && numberEqual(startX + dx * 15.385, path.params[0])) {
            path.params[0] = startX + dx * 32.385;
        }
    }
    if (typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = size.width;
            sizeRes.height = size.height;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);
}
