import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum"
import { conbinePaths } from "../../../util/graph";
import { diwaiBoard } from "../地柜/01-diwai";

export const _03beiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = diwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner, {
        禁止长度增加自动加孔: true,
        背外: true,
    });
    const paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    const circles = paths.filter((path) => path.type === Circle);
    for (let i = 0; i < circles.length; i++) {
        const circle = circles[i];
        const [x, y] = circle.params;
        if(x < startX + dx * width / 3) {
            circle.params[0] -= dx * 17;
        }else if(x >  startX + dx * width * 2 / 3) {
            circle.params[0] += dx * 17;
        }
    }
    //顶部的孔
    const corners: Path[] = [
        //顶部孔
        {
        type: Circle,
        params: [
            startX + dx * width - dx * (a + 15 - 9), startY + dy * height - dy * 45.028293,
            2.61,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * width - dx * (a + 15 - 9), startY + dy * height - dy * 45.028293 - dy * 15.75,
            3.11,
        ]
    }, { 
        type: Circle,
        params: [
            startX + dx * width - dx * (a + 15 - 9), startY + dy * height - dy * 45.028293 - dy * 15.75 - dy * 15.75,
            2.61,
        ]
    }, 
    //底部孔
    {
        type: Circle,
        params: [
            startX + dx * width - dx * (a + 15 - 9), startY + dy * 45.028293,
            2.61,
        ]
    }, 
    {
        type: Circle,
        params: [
            startX + dx * width - dx * (a + 15 - 9), startY + dy * 45.028293 + dy * 15.75,
            3.11,
        ]
    },
     { 
        type: Circle,
        params: [
            startX + dx * width - dx * (a + 15 - 9), startY + dy * 45.028293 + dy * 15.75 + dy * 15.75,
            2.61,
        ]
    }];
    return [
        paths.slice(0, dineiBoardPaths.length),
        corners.concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]
 
}   