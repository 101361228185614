import { Divider, Input, Modal, Radio } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react'


export const HanjieguiBoardUpdateModal = (props) => {

    //只是为了代码复用, 这里省区改变量名
    const [hanjieguiBoardAddConfig, setHanjieguiBoardAddConfig] = useState({});
    const [config, setConfig] = useState({});

    useEffect(() => {
        const { hanjieguiBoardUpdateConfig: board } = props;
        const temp = {
            name: board.name,
            isDoor: board.config?.isDoor,
            width: board.width,
            height: board.height,

        }

        if (board.config?.qiejiaoConfig) {
            temp['有切角'] = true;
            temp['切角位置'] = board.config.qiejiaoConfig.切角位置;
            temp['切角宽'] = board.config.qiejiaoConfig.切角宽;
            temp['切角高'] = board.config.qiejiaoConfig.切角高;
            temp['切角位置X'] = board.config.qiejiaoConfig.切角位置X;
            temp['切角位置Y'] = board.config.qiejiaoConfig.切角位置Y;
        }
        if (board.config?.jiaolianConfig) {
            temp['有铰链'] = true;
            temp['上铰链边距'] = board.config.jiaolianConfig.上铰链边距;
            temp['下铰链边距'] = board.config.jiaolianConfig.下铰链边距;
            temp['切角位置X'] = board.config.jiaolianConfig.切角位置X;
            temp['切角位置Y'] = board.config.jiaolianConfig.切角位置Y;
        }
        if (board.config?.lashouConfig) {
            temp['有门拉手'] = true;
            temp['门拉手类型'] = board.config.lashouConfig.门拉手类型;
            temp['门拉手方向'] = board.config.lashouConfig.门拉手方向;
            temp['拉手X'] = board.config.lashouConfig.拉手X;
            temp['拉手Y'] = board.config.lashouConfig.拉手Y;
            temp['拉手间距'] = board.config.lashouConfig.拉手间距;
            temp['开门方向'] = board.config.lashouConfig.开门方向;
        }


        // 有铰链: hanjieguiBoardAddConfig.有铰链,
        // 上铰链边距: hanjieguiBoardAddConfig.上铰链边距,
        // 切角位置Y: hanjieguiBoardAddConfig.切角位置Y,
        // 切角位置Y: hanjieguiBoardAddConfig.切角位置Y,

        setHanjieguiBoardAddConfig(temp);
    }, [props.hanjieguiBoardUpdateConfig])

    return (
        <Modal
            title="修改柜板"
            visible={props.visible}
            onCancel={props.onCancel}
            centered
            onOk={() => {
                const config = {};
                config.isDoor = props.hanjieguiBoardUpdateConfig.isDoor;
                if (hanjieguiBoardAddConfig.有切角) {
                    config.qiejiaoConfig = {
                        切角位置: hanjieguiBoardAddConfig.切角位置,
                        切角宽: Number(hanjieguiBoardAddConfig.切角宽),
                        切角高: Number(hanjieguiBoardAddConfig.切角高),
                        切角位置X: Number(hanjieguiBoardAddConfig.切角位置X),
                        切角位置Y: Number(hanjieguiBoardAddConfig.切角位置Y)
                    }
                }
                if (hanjieguiBoardAddConfig.有铰链) {
                    config.jiaolianConfig = {
                        上铰链边距: Number(hanjieguiBoardAddConfig.上铰链边距),
                        下铰链边距: Number(hanjieguiBoardAddConfig.下铰链边距),
                        开门方向: hanjieguiBoardAddConfig.开门方向,
                    }
                }
                if (hanjieguiBoardAddConfig.有门拉手) {
                    config.lashouConfig = {
                        门拉手类型: hanjieguiBoardAddConfig.门拉手类型,
                        门拉手方向: hanjieguiBoardAddConfig.门拉手方向,
                        拉手X: Number(hanjieguiBoardAddConfig.拉手X),
                        拉手Y: Number(hanjieguiBoardAddConfig.拉手Y),
                        拉手间距: Number(hanjieguiBoardAddConfig.拉手间距),
                        开门方向: hanjieguiBoardAddConfig.开门方向,
                    }
                }
                if(hanjieguiBoardAddConfig.有隔层) {
                    config.gecengConfig = {
                        开门方向: hanjieguiBoardAddConfig.开门方向,
                        隔层孔前X: Number(hanjieguiBoardAddConfig.隔层孔前X),
                        隔层孔后X: Number(hanjieguiBoardAddConfig.隔层孔后X),
                        隔层孔Y: Number(hanjieguiBoardAddConfig.隔层孔Y),
                        隔层孔径: Number(hanjieguiBoardAddConfig.隔层孔径)
                    }
                }

                const data = {
                    ...props.hanjieguiBoardUpdateConfig,
                    name: hanjieguiBoardAddConfig.name,
                    height: Number(hanjieguiBoardAddConfig.height),
                    width: Number(hanjieguiBoardAddConfig.width),
                    config
                }
                props.onSave(data);
            }}
        >
            <div className="hanjiegui-modal" style={{ display: 'flex' }}>

                <div style={{ flex: 1 }}>
                    <div style={{ padding: 10 }}>
                        <div>板名：</div>
                        <div>
                            <Input
                                placeholder="请输入板名"
                                value={hanjieguiBoardAddConfig.name}
                                onChange={(e) => {
                                    hanjieguiBoardAddConfig.name = e.target.value;
                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ padding: 10 }}>
                            <div>宽度：</div>
                            <div>
                                <Input
                                    placeholder="请输入宽度"
                                    suffix="mm"
                                    value={hanjieguiBoardAddConfig.width}
                                    onChange={(e) => {
                                        hanjieguiBoardAddConfig.width = e.target.value;
                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ padding: 10 }}>
                            <div>高度：</div>
                            <div>
                                <Input
                                    placeholder="请输入高度"
                                    suffix="mm"
                                    value={hanjieguiBoardAddConfig.height}
                                    onChange={(e) => {
                                        hanjieguiBoardAddConfig.height = e.target.value;
                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{}}>

                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ padding: 10, borderRight: '1px solid #444' }}>
                                <div>铰链孔：</div>
                                <div>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={hanjieguiBoardAddConfig.有铰链}
                                        onChange={() => {
                                            hanjieguiBoardAddConfig.有铰链 = !hanjieguiBoardAddConfig.有铰链;
                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                        }}
                                    />
                                    {hanjieguiBoardAddConfig.有铰链 ? '有铰链' : '无铰链'}
                                </div>
                            </div>
                            <div style={{ padding: 10 }}>
                                <div>切角：</div>
                                <div>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={hanjieguiBoardAddConfig.有切角}
                                        onChange={() => {
                                            hanjieguiBoardAddConfig.有切角 = !hanjieguiBoardAddConfig.有切角;
                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                        }}
                                    />
                                    {hanjieguiBoardAddConfig.有切角 ? '有切角' : '无切角'}
                                </div>
                            </div>
                            {
                                hanjieguiBoardAddConfig.isDoor && (

                                    <div style={{ padding: 10 }}>
                                        <div>门拉手：</div>
                                        <div>
                                            <Checkbox
                                                style={{ marginRight: 8 }}
                                                checked={hanjieguiBoardAddConfig.有门拉手}
                                                onChange={() => {
                                                    hanjieguiBoardAddConfig.有门拉手 = !hanjieguiBoardAddConfig.有门拉手;
                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                }}
                                            />
                                            {hanjieguiBoardAddConfig.有门拉手 ? '有门拉手' : '无门拉手'}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {
                            hanjieguiBoardAddConfig.有铰链 && (
                                <>
                                    <Divider>铰链设置</Divider>
                                    <div style={{ marginBottom: 10 }}>
                                        <div>开门方向：</div>
                                        <div>
                                            <Radio.Group value={hanjieguiBoardAddConfig.开门方向} onChange={(e) => {
                                                hanjieguiBoardAddConfig.开门方向 = e.target.value;
                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                            }}>
                                                <Radio value="左开">左开</Radio>
                                                <Radio value="右开">右开</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ padding: 10 }}>
                                            <div>上铰链边距</div>
                                            <div>
                                                <Input
                                                    placeholder="上铰链边距"
                                                    suffix="mm"
                                                    value={hanjieguiBoardAddConfig.上铰链边距}
                                                    onChange={(e) => {
                                                        hanjieguiBoardAddConfig.上铰链边距 = e.target.value;
                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <div>下铰链边距</div>
                                            <div>
                                                <Input
                                                    placeholder="下铰链边距"
                                                    suffix="mm"
                                                    value={hanjieguiBoardAddConfig.下铰链边距}
                                                    onChange={(e) => {
                                                        hanjieguiBoardAddConfig.下铰链边距 = e.target.value;
                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div style={{ padding: 10 }}>
                                                <div>侧边边距</div>
                                                <div>
                                                    <Input
                                                        placeholder="侧边边距"
                                                        suffix="mm"
                                                        value={hanjieguiBoardAddConfig.侧边边距}
                                                        onChange={(e) => {
                                                            hanjieguiBoardAddConfig.侧边边距 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                    </div>

                                </>
                            )
                        }
                        {
                            hanjieguiBoardAddConfig.有切角 && (
                                <>
                                    <Divider>切角设置</Divider>
                                    <div style={{ marginBottom: 10 }}>
                                        <div>切角位置：</div>
                                        <div>
                                            <Radio.Group value={hanjieguiBoardAddConfig.切角位置} onChange={(e) => {
                                                hanjieguiBoardAddConfig.切角位置 = e.target.value;
                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                            }}>
                                                <Radio value="lt">左上</Radio>
                                                <Radio value="lb">左下</Radio>
                                                <Radio value="rt">右上</Radio>
                                                <Radio value="rb">右下</Radio>
                                                <Radio value="center">正中</Radio>
                                                <Radio value="xy">自定义</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {/* <div style={{ marginBottom: 10 }}>
                                                                <div>切角类型：</div>
                                                                <div>
                                                                    <Radio.Group value={hanjieguiBoardAddConfig.切角类型} onChange={(e) => {
                                                                        hanjieguiBoardAddConfig.切角类型 = e.target.value;
                                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                    }}>
                                                                        <Radio value="round">圆形</Radio>
                                                                        <Radio value="rect">矩形</Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div> */}
                                    {
                                        hanjieguiBoardAddConfig.切角位置 === 'xy' && (
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ padding: '0 10px 10px 0' }}>
                                                    <div>切角位置X</div>
                                                    <div>
                                                        <Input
                                                            placeholder="请输入切角位置X"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.切角位置X}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.切角位置X = e.currentTarget.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 0 10px 10px' }}>
                                                    <div>切角位置Y</div>
                                                    <div>
                                                        <Input
                                                            placeholder="请输入切角位置Y"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.切角位置Y}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.切角位置Y = e.currentTarget.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ padding: '0 10px 10px 0' }}>
                                                <div>切角宽</div>
                                                <div>
                                                    <Input
                                                        placeholder="切角宽"
                                                        suffix="mm"
                                                        value={hanjieguiBoardAddConfig.切角宽}
                                                        onChange={(e) => {
                                                            hanjieguiBoardAddConfig.切角宽 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ padding: '0 0 10px 10px' }}>
                                                <div>切角高</div>
                                                <div>
                                                    <Input
                                                        placeholder="切角高"
                                                        suffix="mm"
                                                        value={hanjieguiBoardAddConfig.切角高}
                                                        onChange={(e) => {
                                                            hanjieguiBoardAddConfig.切角高 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        }
{
                                        hanjieguiBoardAddConfig.有隔层 && (
                                            <div>
                                                <Divider>隔层孔设置</Divider>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>开门方向：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.开门方向} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.开门方向 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="左开">左开</Radio>
                                                            <Radio value="右开">右开</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔前X</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔前X"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔前X}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔前X = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔后X</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔后X"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔后X}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔后X = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔Y</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔Y"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔Y}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔Y = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔径</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔径"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔径}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔径 = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                        {
                            hanjieguiBoardAddConfig.有门拉手 && (
                                <div>
                                    <Divider>拉手设置</Divider>
                                    <div style={{ marginBottom: 10 }}>
                                        <div>门拉手类型：</div>
                                        <div>
                                            <Radio.Group value={hanjieguiBoardAddConfig.门拉手类型} onChange={(e) => {
                                                hanjieguiBoardAddConfig.门拉手类型 = e.target.value;
                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                            }}>
                                                <Radio value="暗拉手">暗拉手</Radio>
                                                <Radio value="明拉手">明拉手</Radio>
                                                {/* <Radio value="rt">G型拉手</Radio> */}
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {
                                        hanjieguiBoardAddConfig.门拉手类型 === "明拉手" && (
                                            <>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>开门方向：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.开门方向} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.开门方向 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="左开">左开</Radio>
                                                            <Radio value="右开">右开</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>门拉手方向：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.门拉手方向} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.门拉手方向 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="horizontal">横向</Radio>
                                                            <Radio value="vertical">纵向</Radio>
                                                            {/* <Radio value="rt">G型拉手</Radio> */}
                                                        </Radio.Group>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ padding: '0 5px 10px 0' }}>
                                                        <div>拉手X</div>
                                                        <div>
                                                            <Input
                                                                placeholder="拉手X"
                                                                suffix="mm"
                                                                value={hanjieguiBoardAddConfig.拉手X}
                                                                onChange={(e) => {
                                                                    hanjieguiBoardAddConfig.拉手X = e.target.value;
                                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: '0 5px 10px 5px' }}>
                                                        <div>拉手Y</div>
                                                        <div>
                                                            <Input
                                                                placeholder="拉手Y"
                                                                suffix="mm"
                                                                value={hanjieguiBoardAddConfig.拉手Y}
                                                                onChange={(e) => {
                                                                    hanjieguiBoardAddConfig.拉手Y = e.target.value;
                                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "0 10px 10px 5px" }}>
                                                        <div>拉手间距</div>
                                                        <div>
                                                            <Input
                                                                placeholder="拉手间距"
                                                                suffix="mm"
                                                                value={hanjieguiBoardAddConfig.拉手间距}
                                                                onChange={(e) => {
                                                                    hanjieguiBoardAddConfig.拉手间距 = e.target.value;
                                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }
                                </div>

                            )
                        }
                    </div>
                </div>
            </div>

        </Modal>
    )
}