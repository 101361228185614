import { DirectX, DirectY } from "../../../constants/enum";
import { _11cewaiBoardLeft } from "./11-cewai-left";

export const _11cewaiBoardRight = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
): [Path[], Path[]] => {
    return _11cewaiBoardLeft(cornerType,dx, dy, startX, startY, width, height, corner);
}