import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { clearCorner, pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner10 } from "../../coners/corner10";
import { corner14 } from "../../coners/corner14";
import { corner2 } from "../../coners/corner2";
import { corner14WaiWuhujiao } from "../../coners/无护角/corner14-wai-wuhujiao";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner14WaiWujiao } from "../../coners/无角/corner14-wai-wujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { rectBoard } from "../general-board";

export const _07diwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    x: number,
    y: number,
    doorWidth: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];
    // = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wujiao") {
        cornerBL = corner2WaiWujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner2WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);

    }
    
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR: Path[] = [];// = corner2(-dx, -dy, startX, startY,corner,  cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner2(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }else if(cornerType === "wujiao") {
        cornerBR = corner2WaiWujiao(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner2WaiWuhujiao(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        
    }

    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = []; // = corner14(dx, dy, startX, startY,corner, cornerTRSize);
    let cornerTRW: number = cornerTRSize.height;
    let cornerTRH: number = cornerTRSize.width;
    if(cornerType === "youhujiao") {
        cornerTR = corner14(dx, dy, startX, startY,corner, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
    }else if(cornerType === "wujiao") {
        cornerTR = corner14WaiWujiao(dx, dy, startX, startY,corner, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTR = corner14WaiWuhujiao(dx, dy, startX, startY,corner, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
        
    }
    // if(!corner) {
    //     cornerTR = clearCorner(cornerTR);
    // }


    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];// = corner14(dx, dy, startX, startY, corner,  cornerTLSize);
    let cornerTLW: number = cornerTLSize.height;
    let cornerTLH: number = cornerTLSize.width;
    if(cornerType === "youhujiao") {
        cornerTL = corner14(dx, dy, startX, startY, corner,  cornerTLSize);
        cornerTLW = cornerTLSize.height;
        cornerTLH = cornerTLSize.width;
        cornerTL = pathRotate2(cornerTL, startX, startY, -90);
        cornerTL = pathMirrorY(cornerTL, startX);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLW);
    }else if(cornerType === "wujiao") {
        cornerTL = corner14WaiWujiao(dx, dy, startX, startY, corner,  cornerTLSize);
        cornerTLW = cornerTLSize.height;
        cornerTLH = cornerTLSize.width;
        cornerTL = pathRotate2(cornerTL, startX, startY, -90);
        cornerTL = pathMirrorY(cornerTL, startX);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLW);
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner14WaiWuhujiao(dx, dy, startX, startY, corner,  cornerTLSize);
        cornerTLW = cornerTLSize.height;
        cornerTLH = cornerTLSize.width;
        cornerTL = pathRotate2(cornerTL, startX, startY, -90);
        cornerTL = pathMirrorY(cornerTL, startX);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLW);
        
    }
    // if(!corner) {
    //     cornerTL = clearCorner(cornerTL);
    // }

    const boardConfig: any = {
        middleware: {
            top: (width > 500),
            left: (height > 500),
            right: (height > 500),
            bottom: false,
        }
    }


    let paths;
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    //其他孔
    const corners: Path[] = [
        {
            type: Circle,
            params: [
                startX + dx * (doorWidth + 15), startY + dy * 45.03,
                2.61
            ]
        },{
            type: Circle,
            params: [
                startX + dx * (doorWidth + 15), startY + dy * 45.03 + dy * 15.75,
                3.11
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (doorWidth + 15), startY + dy * 45.03 + dy * 15.75 + 15.75,
                2.61
            ]
        },
       
    ]
    // const 

    return [
        paths,
        corners.concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
 }