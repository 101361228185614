import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner16 } from "../../coners/corner16";
import { corner16WaiWuhujiao } from "../../coners/无护角/corner16-wai-wuhujiao";
import { corner16WaiWujiao } from "../../coners/无角/corner16-wai-wujiao";
import { rectBoard } from "../general-board";

export const _02beiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    diaokongbianju: number,
    corner: boolean,
    extraConfig: any
): [Path[], Path[]] => {
    //console.log(extraConfig);
    
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];// = corner16(dx, -dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if (cornerType === "youhujiao") {
        cornerBL = corner16(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.DOWN, cornerBLSize.height - 2  * (cornerBLSize.height - cornerBLSize.width));
        for (let i = 0; i < cornerBL.length; i++) {
            const temp = cornerBL[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    } else if (cornerType === "wujiao") {
        cornerBL = corner16WaiWujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.DOWN, cornerBLSize.height - 2  * (cornerBLSize.height - cornerBLSize.width));
        for (let i = 0; i < cornerBL.length; i++) {
            const temp = cornerBL[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    } else if (cornerType === "wuhujiao") {
        cornerBL = corner16WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.DOWN, cornerBLSize.height - 2  * (cornerBLSize.height - cornerBLSize.width));
        for (let i = 0; i < cornerBL.length; i++) {
            const temp = cornerBL[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    // = corner16(-dx, -dy, startX, startY, corner, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if (cornerType === "youhujiao") {
        cornerBR = corner16(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBRSize.height - 2  * (cornerBRSize.height - cornerBRSize.width));
        for (let i = 0; i < cornerBR.length; i++) {
            const temp = cornerBR[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    } else if (cornerType === "wujiao") {
        cornerBR = corner16WaiWujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBRSize.height - 2  * (cornerBRSize.height - cornerBRSize.width));
        for (let i = 0; i < cornerBR.length; i++) {
            const temp = cornerBR[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }
    } else if (cornerType === "wuhujiao") {
        cornerBR = corner16WaiWuhujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBRSize.height - 2  * (cornerBRSize.height - cornerBRSize.width));
        for (let i = 0; i < cornerBR.length; i++) {
            const temp = cornerBR[i];
            if (temp.type === Circle) {
                if (numberEqual(startY + dy * 32.385, temp.params[1])) {
                    temp.params[1] = startY + dy * 15.385;
                }
            }
        }

    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if (cornerType === "youhujiao") {
        cornerTR = corner16(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    } else if (cornerType === "wujiao") {
        cornerTR = corner16WaiWujiao(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    } else if (cornerType === "wuhujiao") {
        cornerTR = corner16WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    }

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if (cornerType === "youhujiao") {
        cornerTL = corner16(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    } else if (cornerType === "wujiao") {
        cornerTL = corner16WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    } else if (cornerType === "wuhujiao") {
        cornerTL = corner16WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    }

    const boardConfig: any = {
        middleware: {
            top: width > 500 && "close",
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: width > 500 && "close",
        }
    }
    let paths;
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    if (diaokongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 24), startY + dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 24), startY + dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 24), startY + dy * height - dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 24), startY + dy * height - dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        });
    }

    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}