import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { _05beineiBoard } from "./05-beinei";
import { _05beiwaiBoard } from "./05-beiwai";
import { _05ceneiBoardLeft } from "./05-cenei-left";
import { _05ceneiBoardRight } from "./05-cenei-right";
import { _05cewaiBoardLeft } from "./05-cewai-left";
import { _05cewaiBoardRight } from "./05-cewai-right";
import { _05dineiBoard } from "./05-dinei";
import { _05diwaiBoard } from "./05-diwai";
import { _05liwaiBoard } from "./05-liwai";
import { _05zhuanjiaoneiYBoard } from "./05-zhuanjiaonei-y";
import { _07zhuanjiaoneiYBoard } from "./07-zhuanjiaonei-y";

export const _08zhuanjiaoneiYBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _07zhuanjiaoneiYBoard(dx, dy, startX, startY, width, height);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]
 }