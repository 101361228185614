import { _03beineiBoard } from "../../boards/三门柜/03-beinei";
import { _03beiwaiBoard } from "../../boards/三门柜/03-beiwai";
import { _03ceneiBoardLeft } from "../../boards/三门柜/03-cenei-left";
import { _03ceneiBoardRight } from "../../boards/三门柜/03-cenei-right";
import { _03cewaiBoardLeft } from "../../boards/三门柜/03-cewai-left";
import { _03cewaiBoardRight } from "../../boards/三门柜/03-cewai-right";
import { _03dineiBoard } from "../../boards/三门柜/03-dinei";
import { _03diwaiBoard } from "../../boards/三门柜/03-diwai";
import { _03gecengneiBoard } from "../../boards/三门柜/03-gecengnei";
import { _03gecengwaiBoard } from "../../boards/三门柜/03-gecengwai";
import { _03gongyongceneiBoard } from "../../boards/三门柜/03-gongyongcenei";
import { _03gongyongcewaiBoard } from "../../boards/三门柜/03-gongyongcewai";
import { _03menneiBoard } from "../../boards/三门柜/03-mennei";
import { _03menwaiBoard } from "../../boards/三门柜/03-menwai";
import { _03qiandangneiBoard } from "../../boards/三门柜/03-qiandangnei";
import { _03qiandangwaiBoard } from "../../boards/三门柜/03-qiandangwai";
import { BaseCupboard } from "../@base/BaseCupboard";

export class LeftSanmenguiCebaobei extends BaseCupboard {
    key: string = "LeftSanmenguiCebaobei";
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: _03dineiBoard,
            diwaiBoard: _03diwaiBoard,
            ceneiBoardLeft: _03ceneiBoardLeft,
            ceneiBoardRight: _03ceneiBoardRight,
            cewaiBoardLeft: _03cewaiBoardLeft,
            cewaiBoardRight: _03cewaiBoardRight,
            gongyongceneiBoard: _03gongyongceneiBoard,
            gongyongcewaiBoard: _03gongyongcewaiBoard,
            beiwaiBoard: _03beiwaiBoard,
            beineiBoard: _03beineiBoard,
            menwaiBoard: _03menwaiBoard,
            menneiBoard: _03menneiBoard,
            "menwaiBoard-left": _03menwaiBoard,
            "menneiBoard-left": _03menneiBoard,
            "menwaiBoard-right": _03menwaiBoard,
            "menneiBoard-right": _03menneiBoard,
            menneiBoard2: _03menneiBoard,
            menwaiBoard2: _03menwaiBoard,
            qiandangneiBoard: _03qiandangneiBoard,
            qiandangwaiBoard: _03qiandangwaiBoard,
            gecengneiBoard1: _03gecengneiBoard,
            gecengwaiBoard1: _03gecengwaiBoard,
            gecengneiBoard2: _03gecengneiBoard,
            gecengwaiBoard2: _03gecengwaiBoard,
            
        }
    }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
        /* 2021 12 27 - 5 G型拉手门内高度+13*/
        let menneiHeightIncre = 0;
        let menneiWidthIncre = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                menneiHeightIncre = 13;
            }else {
                menneiWidthIncre = 13;
            }
        }
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [deep + 47, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep + 21, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21, height + 3],
            },
            "beiwaiBoard": {
                size: [long + 11, height + 29]
            },
            "beineiBoard": {
                size: [long - 15, height + 3]
            },
            "gongyongceneiBoard": {
                size: [deep + 3, height + 3],
            },
            "gongyongcewaiBoard": {
                size: [deep + 29, height + 29]
            },
            "qiandangwaiBoard": {
                size: [long + 11, 74 + 47],
            },
            "qiandangneiBoard": {
                size: [long - 44, 74 + 21],
            },
            "menneiBoard-left": {
                size: [extraConfig.a / 2 + 19 - wReduce - menneiWidthIncre, height - 40 + 21 - hReduce + menneiHeightIncre],
                quantity: 1,
            },
            "menwaiBoard-left": {
                size: [extraConfig.a / 2 + 45 - wReduce, height + 7 - hReduce],
                quantity: 1
            },
            "menneiBoard-right": {
                size: [extraConfig.a / 2 + 19 - wReduce - menneiWidthIncre, height - 40 + 21 - hReduce +menneiHeightIncre],
                quantity: 1,
            },
            "menwaiBoard-right": {
                size: [extraConfig.a / 2 + 45 - wReduce, height + 7 - hReduce],
                quantity: 1
            },
            "menneiBoard": {
                size: [long - extraConfig.a + 19 - wReduce + menneiWidthIncre, height - 19 - hReduce + menneiHeightIncre],

            },
            "menwaiBoard": {
                size: [long - extraConfig.a + 45 - wReduce, height + 7 - hReduce],

            }
        }
        if(extraConfig.geceng) {
            list['gecengwaiBoard2'] = {
                size: [long - extraConfig.a + 29, deep - extraConfig.gecengX + 29],
            };
            list['gecengneiBoard2'] = {
                size: [long - extraConfig.a + 3 - 9, deep - extraConfig.gecengX + 3]
            };

            list["gecengwaiBoard1"] = {
                // size: [extraConfig.a + 20 , deep - extraConfig.gecengX + 29]
                size: [extraConfig.a + 20 , deep - extraConfig.gecengX + 20 + 9]
            }
            list["gecengneiBoard1"] = {
                // size: [extraConfig.a - 6, deep - extraConfig.gecengX + 3]
                size: [extraConfig.a - 6, deep - extraConfig.gecengX - 6 + 9]
            }
        }
        
        if(extraConfig.nobeinei) {
            delete list["beineiBoard"];
        }
        
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        if(extraConfig.上免拉手) {
            delete list['qiandangneiBoard'];
            delete list['qiandangwaiBoard'];
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.guituiX, extraConfig.guituiY, extraConfig.a, extraConfig);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });                  
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou,  extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name === "gongyongcewaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name === "gongyongceneiBoard") {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height,extraConfig.jiaolianbianju, extraConfig.nojiaoliankong,  extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing,extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu,  extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.a, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.a);
        }
        return temp;
    }

}