import { _05beineiBoard } from "../../boards/转角柜/05-beinei";
import { _05beiwaiBoard } from "../../boards/转角柜/05-beiwai";
import { _05ceneiBoardLeft } from "../../boards/转角柜/05-cenei-left";
import { _05ceneiBoardRight } from "../../boards/转角柜/05-cenei-right";
import { _05cewaiBoardLeft } from "../../boards/转角柜/05-cewai-left";
import { _05cewaiBoardRight } from "../../boards/转角柜/05-cewai-right";
import { _05dineiBoard } from "../../boards/转角柜/05-dinei";
import { _05diwaiBoard } from "../../boards/转角柜/05-diwai";
import { _05fengneiBoard } from "../../boards/转角柜/05-fengnei";
import { _05fengwaiBoard } from "../../boards/转角柜/05-fengwai";
import { _05lineiBoard } from "../../boards/转角柜/05-linei";
import { _05liwaiBoard } from "../../boards/转角柜/05-liwai";
import { _05menneiBoard } from "../../boards/转角柜/05-mennei";
import { _05menwaiBoard } from "../../boards/转角柜/05-menwai";
import { _05qiandangneiBoard } from "../../boards/转角柜/05-qiandangnei";
import { _05qiandangwaiBoard } from "../../boards/转角柜/05-qiandangwai";
import { _05zhuanjiaoneiXBoard } from "../../boards/转角柜/05-zhuanjiaonei-x";
import { _05zhuanjiaoneiYBoard } from "../../boards/转角柜/05-zhuanjiaonei-y";
import { _05zhuanjiaowaiXBoard } from "../../boards/转角柜/05-zhuanjiaowai-x";
import { _05zhuanjiaowaiYBoard } from "../../boards/转角柜/05-zhuanjiaowai-y";
import { BaseCupboard } from "../@base/BaseCupboard";

export class LeftQiezhuanjiaoguiCebaobei extends BaseCupboard {
    key: string = "LeftQiezhuanjiaoguiCebaobei";

    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: _05dineiBoard,
            diwaiBoard: _05diwaiBoard,
            ceneiBoardLeft: _05ceneiBoardLeft,
            ceneiBoardRight: _05ceneiBoardRight,
            cewaiBoardLeft: _05cewaiBoardLeft,
            cewaiBoardRight: _05cewaiBoardRight,
            beiwaiBoard: _05beiwaiBoard,
            beineiBoard: _05beineiBoard,
            menwaiBoard: _05menwaiBoard,
            menneiBoard: _05menneiBoard,
            "menwaiBoard-left": _05menwaiBoard,
            "menneiBoard-left": _05menneiBoard,
            "menwaiBoard-right": _05menwaiBoard,
            "menneiBoard-right": _05menneiBoard,
            qiandangneiBoard: _05qiandangneiBoard,
            qiandangwaiBoard: _05qiandangwaiBoard,
            zhuanjiaoneiXBoard: _05zhuanjiaoneiXBoard,
            zhuanjiaowaiXBoard: _05zhuanjiaowaiXBoard,
            zhuanjiaoneiYBoard: _05zhuanjiaoneiYBoard,
            zhuanjiaowaiYBoard: _05zhuanjiaowaiYBoard,
            lineiBoard: _05lineiBoard,
            liwaiBoard: _05liwaiBoard,
            fengneiBoard: _05fengneiBoard,
            fengwaiBoard: _05fengwaiBoard
        }
    }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
        /* 2021 12 27 - 5 G型拉手门内高度+13*/
        let menneiHeightIncre = 0;
        let menneiWidthIncre = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                menneiHeightIncre = 13;
            }else {
                menneiWidthIncre = 13;
            }
        }
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [deep - extraConfig.y + 47, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep - extraConfig.y + 21, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21, height + 3],
            },
            "beiwaiBoard": {
                size: [long - extraConfig.x + 11, height + 29]
            },
            "beineiBoard": {
                size: [long - extraConfig.x - 15, height + 3]
            },
            "qiandangwaiBoard": {
                size: [long + 11, 74 + 47],
                //rotate90: true,
            },
            "qiandangneiBoard": {
                size: [long - 44, 74 + 21],
                //rotate90: true,
            },
            
            "zhuanjiaoneiXBoard": {
                size: [extraConfig.x + 3, height + 3],
            },
            "zhuanjiaowaiXBoard": {
                size: [extraConfig.x + 29, height + 29],
            },
            "zhuanjiaoneiYBoard": {
                size: [extraConfig.y + 39, height + 3],
            },
            "zhuanjiaowaiYBoard": {
                size: [extraConfig.y + 65, height + 29],
            },
            "lineiBoard": {
                size: [96, height + 3 - 14.6]
            },
            "liwaiBoard": {
                size: [122, height + 29]
            },
            "fengneiBoard": {
                size: [121, height - 19]
            },
            "fengwaiBoard": {
                size: [147, height + 7]
            }
        }
        if(extraConfig.nobeiban) {
            list["beineiBoard"] = {
                size: [long - extraConfig.x + 11 - 55, 95],
                //rotate90: true
            }
            list["beiwaiBoard"] = {
                size: [long - extraConfig.x + 11, 121],
                //rotate90: true
            }
        }
        if(extraConfig.doorNumber === 2) {
            list["menneiBoard-left"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-left"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],   
                quantity: 1,
            }
            list["menneiBoard-right"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-right"] = {
                size: [extraConfig.doorWidth / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }else {
            list["menneiBoard"] = {
                size: [extraConfig.doorWidth + 19 - wReduce + menneiWidthIncre, /* height - 19 */extraConfig.mengao + 19 - hReduce + menneiHeightIncre],
            };
            list["menwaiBoard"] = {
                size: [extraConfig.doorWidth + 45 - wReduce, /* height + 7 */ extraConfig.mengao + 45 - hReduce],
            };
        }
        if(extraConfig.nobeinei) {
            delete list["beineiBoard"];
        }
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        if(extraConfig.上免拉手) {
            delete list['qiandangneiBoard'];
            delete list['qiandangwaiBoard'];
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.x, extraConfig.y, extraConfig.guituiX, extraConfig.guituiY, extraConfig.doorWidth, extraConfig.nobeiban, extraConfig);
        }else if(board.name === "diwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.x, extraConfig.y, extraConfig.doorWidth, extraConfig.waibanjiao, extraConfig.nobeiban);
        } else if(board.name === "beineiBoard") {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nobeiban);
        } else if(board.name === "beiwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao, extraConfig.nobeiban);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            // temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
                doorNumber: Number(extraConfig.doorNumber),
                jiaolianbianju: Number(extraConfig.jiaolianbianju),

            });                    
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.qiandanghengfang, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name === "liwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.waibanjiao, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        }else if(board.name === "lineiBoard") {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手: Boolean(extraConfig.中免拉手),
                中免拉手X: Number(extraConfig.中免拉手X),
                中免拉手Y: Number(extraConfig.中免拉手Y),
                中免拉手Z: Number(extraConfig.中免拉手Z),
            });
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }
}