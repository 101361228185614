import { AbstractMeta, MetaProperty, mm2RealValue, px2DisplayValue } from "./base";

const commonProperties: MetaProperty[] = [

    {
        key: "onWallWidth",
        label: "宽度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "onWallHeight",
        label: "高度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "groundClearance",
        label: "离地高度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]

    }

];

const commonSingleDoorProperties: MetaProperty[] = [
    {
        key: "openClockwise",
        label: "开扇过程",
        defaultValue: 0,
        valueType: "enum:single",
        options: [
            {
                key: "clockwise",
                value: true,
                label: "顺时针"
            },
            {
                key: "anticlockwise",
                value: false,
                label: "逆时针"
            }
        ],
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }
]

const commonOnXProperties: MetaProperty[] = [
    
    {
        key: "d1",
        label: "距左",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "d2",
        label: "距右",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }
]

const commonOnYProperties: MetaProperty[] = [
    {
        key: "d1",
        label: "距上",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "d2",
        label: "距下",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }
]

export const singleDoorOnX = new AbstractMeta<{}>("单开门属性", [
    ...commonProperties,
    {
        
        key: "openDirect",
        label: "开门朝向",
        defaultValue: 0,
        valueType: "enum:single",
        options: [
            {
                key: "top",
                value: "top",
                label: "向上"
            },
            {
                key: "bottom",
                value: "bottom",
                label: "向下"
            }
        ],
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    ...commonSingleDoorProperties,
    ...commonOnXProperties,
]);


export const singleDoorOnY = new AbstractMeta<{}>("单开门属性", [
    ...commonProperties,
    {
        
        key: "openDirect",
        label: "开门朝向",
        defaultValue: 0,
        valueType: "enum:single",
        options: [
            {
                key: "left",
                value: "left",
                label: "朝左"
            },
            {
                key: "right",
                value: "right",
                label: "朝右"
            }
        ],
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    ...commonSingleDoorProperties,
    ...commonOnYProperties,
]);


export const onWallX = new AbstractMeta<{}>("门窗属性", [
    ...commonProperties,
    ...commonOnXProperties
]);
export const onWallY = new AbstractMeta<{}>("门窗属性", [
    ...commonProperties,
    ...commonOnYProperties
]);
