import { Circle, Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";

export const corner20 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const paths: Path[] = [
        {
            type: Line,
            params: [
                startX, startY,
                startX - dx * 42, startY
            ]
        }, {
            type: Line,
            params: [
                startX - dx * 42, startY,
                startX - dx * 42, startY + dy * 99
            ]
        }
    ]
    if (typeof sizeRes === 'object') {
        // if (!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // } else {
            sizeRes.width = 42;
            sizeRes.height = 99;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);;
}