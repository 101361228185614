import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveY } from "../../../util/graph";
import { corner2Sanheyi } from "./corner2-sanheyi";

export const corner13Sanheyi = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner2Sanheyi(dx, -dy, startX, startY, true, size);
    paths = pathMoveY(paths, DirectY.UP, dy * size.height);
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === Circle) {
            path.params[0] = startX + dx * 15.35;
        }
    }
    if(typeof sizeRes === 'object') {
        sizeRes.width = size.width;
        sizeRes.height = size.height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);;
}
