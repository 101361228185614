
// export const host = "http://design.dasheng.toskies.com:8081"
export let host = "https://api.dasheng.toskies.com:4431";

if(process.env.NODE_ENV === "development") {
   host = "http://localhost:8081";
}
// export const host = "https://api.dasheng.toskies.com:4431";
const _apis = {
    download: host + "/download",
    login: host + "/login",
    account: host + "/account",
    order: host + "/order",
    costSettings: host + "/cost-settings",
    sheet: host + "/sheet",
}


export const apis = _apis;

