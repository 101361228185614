import { DownloadOutlined, ExportOutlined, } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Checkbox, ConfigProvider, Descriptions, Divider, Drawer, Dropdown, message, Modal, Popconfirm, Progress, Skeleton, Spin, Switch, Table, Tabs, Tooltip } from "antd";
import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Group, Layer, Line, Stage } from "react-konva";

import { Cupboard, CupboardBroad, Material, NestBoard, NestRequest, NestResult, NestSheet } from "../../../../@interface";
import { RuleStage } from "../../components/rule-stage";
import { BoardGraph, RectWithAngles, RectWithAnglesProps } from "../../components/graph/board";
import './style.less';
import { host, host2, nestHost, wsHost } from "../../utils/apis";
import { sleep } from "../../utils2";
import { useLocation, useNavigate } from "react-router-dom";
import { BoardView } from "../../components/board-view";
import { SmartNestWidget } from "./components/SmartNestWidget";
import qs from 'querystring';
import { request } from "../../utils2/request";
import { apis } from "../../utils2/apis";

interface Props {

}

export const NestView = () => {



    const [data, setData] = useState<NestRequest>();
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [displayBoards, setDisplayBoard] = useState<CupboardBroad[]>([]);
    const [allBoards, setAllBoards] = useState<CupboardBroad[]>([]);
    const [materials, setMaterials] = useState<Material[]>([]);
    const [materialMap, setMaterialMap] = useState<Map<number | string, Material>>();
    const [materialNestSettings, setMaterialNestSettings] = useState<any[]>([]);
    const [nesting, setNesting] = useState(false);
    //2023 11 12新增需求
    //1、根据选中的橱柜展示右侧板子
    const [selectedCupboardIds, setSelectedCupboardIds] = useState<string[]>([]);
    //2、右下角只显示用过的材料
    const [usedMaterialIds, setUsedMaterialIds] = useState<{ [materialId: string]: boolean }>({});

    const location = useLocation();

    useEffect(() => {
        let tempData:string;
        const w: any = window;
        if(w.$$nestRequest) {
            tempData = JSON.stringify(w.$$nestRequest);
            // message.success("从内存读取nest request");
        }else {
            tempData = localStorage.getItem("SK_NEST_REQUEST_TEMP");
        }
        if (!tempData) {
            // alert("未能获取初始数据，");
            window.location.href = `/3d`;
            return;
        }
        const query = qs.parse(location.search.replace("?", ""));
        (async () => {
            let bomData = null;
            let order = w.$$order;
            // if(order) {
            //     message.success("从内存读取order");
            // }
            if (!order && query.orderId) {
                order = await request.get(apis.order + `/${query.orderId}/detail`).then((res) => {
                    if(res.code === 1) {
                        return res.data;
                    }else {
                        return null;
                    }
                });
              
            }
            if(order?.bomData) {
                bomData = order.bomData;
            }
            if(order?.id) {
                //id
                tempData = JSON.parse(tempData);
                //@ts-ignore
                tempData.order.id = order.id;
                if(order.phoneNumber) {
                    //@ts-ignore
                    tempData.order.phoneNumber = order.phoneNumber;
                }

                tempData = JSON.stringify(tempData);

            }
            const source = fetch(`${host}/api/nest/init`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: tempData
            }).then((res) => {
                return res.json();
            }).then((res) => {
                return res.data;
            });
            source.then((nestRequest: NestRequest) => {
                const allBoards: CupboardBroad[] = [];
                const cupboardIds: string[] = [];
                if(bomData) {
                    nestRequest.order.bomData = JSON.parse(bomData);
                }
                nestRequest.cupboards.forEach((c, i) => {
                    cupboardIds.push(c.id);
                    c.boards.forEach((b) => {
                        b.cupboardIndex = i;
                        allBoards.push(b);
                        usedMaterialIds[b.materialId] = true; //针对右下角只显示用过的材料
                    })
                });

                const materialMap = new Map<number | string, Material>();
                nestRequest.materials.forEach((m) => {
                    materialMap.set(m.id, m);
                });
                setMaterials(nestRequest.materials);
                setData(nestRequest);
                setAllBoards(allBoards);
                setMaterialMap(materialMap);
                setSelectedCupboardIds(cupboardIds);
                setUsedMaterialIds({ ...usedMaterialIds })
                setReady(true);
            });
        })()





    }, []);


    useEffect(() => {
        if (!ready) {
            return;
        }
        setDisplayBoard(allBoards.filter((b) => selectedCupboardIds.includes(b.cupboardId)));
        if (loading) {
            setLoading(false);
        }
    }, [allBoards, ready, selectedCupboardIds]);

    const boardCards = useRef<any>();
    const boardView = useRef<any>();
    const noNestExportRef = useRef<any>();

    const navigate = useNavigate();
    const nestResultDrawer = useRef<any>();

    return (
        < div className="boards" style={{ background: "#333336", overflowX: 'hidden' }}>
            <Spin spinning={loading} tip="正在处理...">
                <div className="flex" style={{ height: 'calc(100vh)' }}>

                    <MaterialTabBoardTable
                        onBoardChange={(board: CupboardBroad) => {
                            for (let i = 0; i < data.cupboards.length; i++) {
                                const cupboard = data.cupboards[i];
                                for (let j = 0; j < cupboard.boards.length; j++) {
                                    const b = cupboard.boards[j];
                                    if (b.id === board.id) {
                                        data.cupboards[i].boards[j] = board;
                                        break;
                                    }
                                }
                            }
                            const index = allBoards.findIndex((b) => b.id === board.id);
                            if (index > -1) {
                                allBoards[index] = board;
                                setAllBoards([...allBoards]);
                            }
                        }}
                        onBoardsChange={(boards: CupboardBroad[]) => {
                            setAllBoards([...allBoards]);
                        }}
                        materials={materials}
                        compatibleV1={data?.compatibleV1}
                        boards={displayBoards}
                        materialMap={materialMap}
                        onShowBoardView={(board) => {
                            boardView.current?.open(board, materialMap.get(board.materialId));
                        }}
                    />

                    <div className="flex column" style={{ width: 400, padding: '0 12px' }}>
                        <div style={{ color: "#fff", fontSize: 14, padding: '12px 6px' }}>
                            <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <div className="bold" style={{ fontSize: 15 }}>订单信息</div>
                                <div style={{ color: "gray" }}>订单号：{data?.order.orderNo}</div>
                            </div>

                            <div style={{ color: "lightgray", marginTop: 6 }}>订单地址： {data?.order.address}</div>
                            <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: 6 }}>
                                <div style={{ color: "gray", marginTop: 2 }}>橱柜数：{data?.cupboards.length}个</div>
                                <div style={{ color: "gray", marginTop: 2 }}>板材种类：{data?.materials.length}种</div>
                            </div>
                        </div>
                        <div style={{ flex: '1 0', overflowY: 'scroll' }}>
                            <div className="flex  center" style={{ justifyContent: 'space-between' }}>
                                <Checkbox disabled={nesting} checked={selectedCupboardIds.length === data?.cupboards.length} onClick={() => {
                                    if (selectedCupboardIds.length !== data.cupboards.length) {
                                        setSelectedCupboardIds(data.cupboards.map(c => c.id));
                                    } else {
                                        setSelectedCupboardIds([]);
                                    }
                                }} style={{ marginLeft: 16 }}><span style={{ marginLeft: 4 }}>全选</span></Checkbox>
                                <Button type="link" onClick={() => {
                                    noNestExportRef.current?.open(data.cupboards.filter((c) => selectedCupboardIds.includes(c.id)));
                                }}><ExportOutlined /> 不排板，直接导出DXF文件</Button>
                            </div>

                            {
                                data?.cupboards.map((cupboard, index) => {
                                    return (
                                        <Card
                                            style={{ marginBottom: 12, cursor: "pointer" }}
                                            onClick={() => {
                                                boardCards?.current.open(cupboard, materialMap);
                                            }}
                                        >
                                            <Card.Meta
                                                title={(
                                                    <div onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (selectedCupboardIds.includes(cupboard.id)) {
                                                            setSelectedCupboardIds(selectedCupboardIds.filter((_id) => _id !== cupboard.id));
                                                        } else {
                                                            setSelectedCupboardIds(selectedCupboardIds.concat(cupboard.id));
                                                        }
                                                    }}>
                                                        <Checkbox disabled={nesting} checked={selectedCupboardIds.includes(cupboard.id)}>
                                                            <span style={{ marginLeft: 4 }}>{(index + 1) + ". " + cupboard.name}</span>
                                                        </Checkbox>
                                                    </div>
                                                )}
                                                description={`长: ${cupboard.length}mm × 深: ${cupboard.deep}mm × 高: ${cupboard.height}mm`}

                                            />
                                            <div className="flex" style={{ color: "gray", marginTop: 8 }}>
                                                <div style={{ flex: 1 }}>工艺: {cupboard.connectorType}</div>
                                                <div style={{ flex: 1 }}>角类型: {cupboard.cornerType}</div>
                                            </div>
                                        </Card>
                                    )
                                })
                            }
                        </div>
                        <div style={{ flexShrink: 0 }}>
                            {
                                data && (
                                    <SmartNestWidget
                                        data={data}
                                        allBoards={allBoards}
                                        usedMaterialIds={usedMaterialIds}
                                        selectedCupboardIds={selectedCupboardIds}
                                        onNestStart={() => {
                                            setNesting(true);
                                        }}
                                        onNestFinish={(data, materialId) => {
                                            setNesting(false);
                                            // if(!materialId){
                                            //     nestResultDrawer.current?.open(data);
                                            // }
                                            // nestResultDrawer.current?.open(data, materialId, materialMap);
                                            // navigate("/nest/result", {
                                            //     state: {
                                            //         data: data,
                                            //     },

                                            // });

                                        }}
                                    />

                                )
                            }
                        </div>
                    </div>
                </div>

            </Spin>
            <BoardView
                ref={boardView}
                compatibleV1={data?.compatibleV1}
            />
            <BoardCards
                ref={boardCards}
                compatibleV1={data?.compatibleV1}
                onShowBoardView={(board) => {
                    boardView.current?.open(board, materialMap.get(board.materialId));
                }}
            />
            <NoNestDxfPreview
                ref={noNestExportRef}
                data={data}
                materialMap={materialMap}
                materials={materials}
                compatibleV1={data?.compatibleV1}
            />
        </div >
    )

}

export const NoNestDxfPreview = forwardRef((props: {
    data: NestRequest;
    materials: Material[];
    materialMap: Map<string | number, Material>;
    compatibleV1: boolean;
}, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [cupboards, setCupboards] = useState<Cupboard[]>([]);
    const [downloadUrl, setDownloadUrl] = useState("");
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            open: (cupboards: Cupboard[]) => {
                // setSelectedMaterials(props.materials);
                setCupboards(cupboards);
                setVisible(true);
                setLoading(true);
                fetch(`${host}/api/cupboard/no-nest-dxf/export`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    // body: localStorage.getItem("SK_NEST_REQUEST::1fbceebb-71a0-4ced-b862-ae0aa92ca2b2")
                    // body: JSON.stringify(instance2)
                    body: JSON.stringify(props.data)
                }).then((res) => {
                    return res.json();
                }).then((res) => {
                    setLoading(false);
                    setDownloadUrl(res.data.allFileZipDownloadUrl)
                }).catch((e) => {
                    Modal.confirm({
                        title: "请求出错",
                        content: e.message,
                        okText: "关闭",
                        centered: true,
                        closable: false,
                        maskClosable: false,
                        okButtonProps: {
                            style: { background: "orangered" }
                        },
                        onOk: close,
                        onCancel: close
                    })
                });
            },
            close
        }
    });
    const close = () => {
        setVisible(false);
        setDownloadUrl("");
    }
    const scale = 0.3;
    return (
        <Modal
            title="不排板导出DXF文件"
            open={visible}
            centered
            width={window.innerWidth - 400 + 48}
            onCancel={close}
            destroyOnClose
            okText={(<><DownloadOutlined />下载Dxf文件</>)}
            onOk={() => {
                window.open(host + downloadUrl)
            }}
            okButtonProps={{
                loading: loading || !downloadUrl,
                disabled: loading
            }}
        >

            <Tabs>

                {
                    props.materials.map((m) => {
                        let hasBoard = false;
                        for (let i = 0; i < cupboards.length; i++) {
                            const cupboard = cupboards[i];
                            let _break = false;
                            for (let j = 0; j < cupboard.boards.length; j++) {
                                const board = cupboard.boards[j];
                                if (board.materialId === m.id) {
                                    hasBoard = true;
                                    _break = true;
                                    break;
                                }
                            }
                            if (_break) {
                                break;
                            }
                        }
                        let yTemp = 0;
                        return hasBoard && (
                            <Tabs.TabPane tab={m.name} key={m.id.toString()} tabKey={m.id.toString()}>
                                <RuleStage
                                    width={window.innerWidth - 400}
                                    height={window.innerHeight - 400}
                                    ruleScale={scale}
                                    zoomable
                                    draggable

                                >
                                    <Layer>
                                        {
                                            cupboards.map((cupboard, index) => {
                                                let xTemp = 0;
                                                let maxHeight = 0;
                                                const boards = cupboard.boards.filter((b) => b.materialId === m.id && !b.notNest);
                                                if (index >= 0) {
                                                    for (let i = 0; i < boards.length; i++) {
                                                        const b = boards[i];
                                                        if (b.height > maxHeight) {
                                                            maxHeight = b.height;
                                                        }
                                                    }
                                                    maxHeight += 300;
                                                }
                                                const comp = (
                                                    <Group>
                                                        {
                                                            boards.map((board, i) => {
                                                                const material = props.materialMap.get(board.materialId);
                                                                let lastX = xTemp;
                                                                xTemp += board.width + 20;
                                                                return (
                                                                    <RectWithAngles
                                                                        key={"board-" + i + "-" + index}
                                                                        x={lastX * scale}
                                                                        y={yTemp * scale}
                                                                        scale={scale}
                                                                        board={board}
                                                                        rotate={false}
                                                                        material={material}
                                                                        background="#000"
                                                                        compatibleV1={props.compatibleV1}
                                                                    />
                                                                )
                                                            })
                                                        }

                                                    </Group>
                                                )
                                                yTemp += maxHeight;

                                                return comp;
                                            })
                                        }
                                    </Layer>
                                </RuleStage>
                            </Tabs.TabPane>
                        )
                    })

                }
            </Tabs>



        </Modal>
    )


})

const MaterialTabBoardTable = (props: {
    materials: Material[];
    compatibleV1?: boolean;
    boards: CupboardBroad[],
    materialMap: Map<number | string, Material>,
    onShowBoardView: (board: CupboardBroad) => void;
    onBoardChange: (board: CupboardBroad) => void;
    onBoardsChange: (boards: CupboardBroad[]) => void;
}) => {

    const [materialBoards, setMaterialBoards] = useState<any[]>([]);
    //材料是否非全选
    const [materialNotAllMap, setMaterialNotAllMap] = useState<{ [materialId: string]: boolean }>({});

    useEffect(() => {
        const _materialBoards: any[] = [];
        let materialNotAllMap = {};
        let allNotAllFlag = false;
        props.boards.forEach((board) => {
            const material = props.materialMap.get(board.materialId);

            let materialBoard = _materialBoards.find((mb) => mb.id === board.materialId);

            if (!materialBoard) {
                materialBoard = {
                    ...material,
                    boards: []
                };
                _materialBoards.push(materialBoard);
            }
            if (board.notNest) {
                materialNotAllMap[material.id] = true;
                allNotAllFlag = true;
            }
            materialBoard.boards.push(board);
        });
        materialNotAllMap[0] = allNotAllFlag;
        setMaterialNotAllMap(materialNotAllMap);
        setMaterialBoards(_materialBoards);
    }, [props.boards]);


    return (
        <div style={{ flex: '1 0', paddingLeft: 12 }}>
            <Tabs>
                <Tabs.TabPane key={"all"} tab={(
                    <>
                        <Checkbox checked={!materialNotAllMap[0]} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (!materialNotAllMap[0]) {
                                //全部不排板
                                props.boards.forEach((b) => {
                                    b.notNest = true;
                                });
                                props.onBoardsChange([...props.boards]);

                            } else {
                                props.boards.forEach((b) => {
                                    b.notNest = false;
                                });
                                props.onBoardsChange([...props.boards]);

                            }
                        }} />
                        <span style={{ marginLeft: 8 }}>全部</span>
                    </>
                )}>
                    <BoardTable
                        materials={props.materials}
                        boards={props.boards}
                        onBoardChange={props.onBoardChange}
                        materialMap={props.materialMap}
                        onShowBoardView={props.onShowBoardView}
                        compatibleV1={props.compatibleV1}
                        onCupboardCheck={(cupboardId) => {
                            const boards = props.boards.filter((b) => b.cupboardId === cupboardId);
                            const hasNotNestBoard = Boolean(boards.find((b) => b.notNest));
                            if (hasNotNestBoard) {
                                boards.forEach((b) => {
                                    b.notNest = false;
                                });
                            } else {
                                boards.forEach((b) => {
                                    b.notNest = true;
                                });
                            }
                            props.onBoardsChange([...props.boards]);
                        }}
                    />
                </Tabs.TabPane>
                {
                    materialBoards.map((item) => {
                        return (
                            <Tabs.TabPane key={item.id} tab={(
                                <>
                                    <Checkbox checked={!materialNotAllMap[item.id]}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (!materialNotAllMap[item.id]) {
                                                props.boards.filter((b) => b.materialId === item.id).forEach((b) => {
                                                    b.notNest = true;
                                                });
                                            } else {

                                                props.boards.filter((b) => b.materialId === item.id).forEach((b) => {
                                                    b.notNest = false;
                                                });
                                            }
                                            props.onBoardsChange([...props.boards]);
                                        }}
                                    />
                                    <span style={{ marginLeft: 8 }}>{item.name}</span>
                                </>
                            )}>
                                <BoardTable
                                    onBoardChange={props.onBoardChange}
                                    materials={props.materials}
                                    boards={item.boards}
                                    materialMap={props.materialMap}
                                    onShowBoardView={props.onShowBoardView}
                                    compatibleV1={props.compatibleV1}
                                    onCupboardCheck={(cupboardId) => {
                                        const boards = props.boards.filter((b) => b.materialId === item.id && b.cupboardId === cupboardId);
                                        const hasNotNestBoard = Boolean(boards.find((b) => b.notNest));
                                        if (hasNotNestBoard) {
                                            boards.forEach((b) => {
                                                b.notNest = false;
                                            });
                                        } else {
                                            boards.forEach((b) => {
                                                b.notNest = true;
                                            });
                                        }
                                        props.onBoardsChange([...props.boards]);
                                    }}
                                />
                            </Tabs.TabPane>
                        )
                    })
                }
            </Tabs>

        </div>
    )
}

const BoardTable = (props: {
    materials: Material[];
    compatibleV1?: boolean;
    boards: CupboardBroad[],
    materialMap: Map<number | string, Material>,
    onShowBoardView: (board: CupboardBroad) => void;
    onBoardChange: (board: any) => void;
    onCupboardCheck: (cupboardId: string) => void;
}) => {

    const { boards, materialMap } = props;

    const columns: any[] = [
        {
            title: "#",
            align: 'center',
            key: "index",
            width: 64,
            render: (_, __, index) => {
                return index + 1;
            }
        },
        {
            title: "排板/导出",
            align: 'center',
            width: 100,
            key: "checked",
            render: (_, b) => {
                return (
                    <Checkbox checked={!Boolean(b.notNest)} onClick={() => {
                        b.notNest = !Boolean(b.notNest);
                        props.onBoardChange({ ...b });
                    }} />
                )
            }
        },
        {
            title: "橱柜",
            key: "cupboard",
            dataIndex: "cupboardName",
            className: "bold",
            align: 'center',
            width: 150,
            render: (cupboardName, b) => {
                return (<><span style={{ marginRight: 8 }}>{b.cupboardIndex + 1}.</span> {cupboardName}</>)
            }
        },
        {
            title: "板名",
            key: "name",
            dataIndex: "name",
            className: "bold",
            align: 'right',
            width: 100,
        },
        {
            title: "类型",
            key: "type",
            dataIndex: "type",
            width: 64,
        },
        {
            title: "尺寸",
            key: "size",
            width: 220,
            render: (_, board: CupboardBroad) => {
                return (
                    <>

                        <span>宽：<span className="inline-block" style={{ width: 64 }}>{board.width}mm, </span> 高：{board.height}mm</span>
                    </>
                )
            }
        },
        {
            title: "工艺",
            key: "connectorType",
            dataIndex: "connectorType",
            width: 100,
        },
        {
            title: "角类型",
            key: "cornerType",
            dataIndex: "cornerType",
            width: 100,
        },
        {
            title: "材料",
            key: "material",
            align: 'right',
            // width: 100,
            render: (_, board: CupboardBroad) => {

                const material = materialMap.get(board.materialId);
                if (material) {
                    return (
                        <div className="flex center" style={{ justifyContent: 'flex-end' }}>
                            {/* {
                                    material.texture.type === 'IMAGE' ? (
                                        <div style={{ height: 60, width: 60 }}>
                                            <img src={material.texture.value} height="60px" />
                                        </div>
                                    ) : (
                                        <div style={{ height: 42, width: 42, backgroundColor: material.texture.value, border: "1px solid lightgray" }} />
                                    )
                                } */}
                            <Dropdown trigger={["click"]} menu={{
                                items: props.materials.map((item) => {
                                    return {
                                        label: item.name,
                                        key: item.id,
                                        icon: (item.texture.value ? (item.texture.type === 'COLOR' ? <div style={{ height: 12, width: 12, borderRadius: 2, background: item.texture.value || '#000' }} /> : <img src={item.texture.value} style={{ height: 12, width: 12, borderRadius: 2 }} />) : undefined),
                                        onClick: () => {
                                            board.materialId = item.id;
                                            props.onBoardChange(board);
                                        }
                                    }
                                })
                            }}>
                                <Button type="link" style={{ marginLeft: 4, marginRight: 0, paddingRight: 0 }}>{material.name}</Button>
                            </Dropdown>
                        </div>
                    )
                }
            }
        }, {
            title: "预览",
            key: "2d-view",
            // align: 'center',
            width: 120,
            render: (_, board: CupboardBroad) => {
                const scale = ((80 - 4) / Math.max(board.height, board.width));
                const material = materialMap.get(board.materialId);
                return (
                    <div style={{ display: "inline-block", width: 80, height: 80, cursor: "zoom-in" }} onClick={() => {
                        props.onShowBoardView(board);
                    }}>
                        <BoardGraph
                            board={board}
                            height={80}
                            width={80}
                            scale={scale}
                            material={material}
                            background="#000"
                            fill
                            compatibleV1={props.compatibleV1}
                        />
                    </div>
                )
            }
        }
    ];

    const [cupboardIds, setCupboardIds] = useState<string[]>([]);

    useEffect(() => {

        setCupboardIds(Array.from(new Set(boards.map(b => b.cupboardId))));
    }, [props.boards]);

    return (
        <div>
            <Table
                className="header-only"
                columns={columns}
            />

            <div style={{ height: window.innerHeight - 130, overflowY: 'scroll', background: "#333336" }}>

                {
                    cupboardIds.map((cupboardId) => {
                        const _boards = boards.filter((b) => b.cupboardId === cupboardId);
                        if (_boards.length === 0) {
                            return;
                        }
                        const nestBoards = _boards.filter((b) => !b.notNest);
                        const materials = Array.from(new Set(_boards.map((b) => b.materialId))).map((materialId) => props.materialMap.get(materialId));
                        const materialBoardsCount = {};
                        nestBoards.forEach((b) => {
                            if (!materialBoardsCount[b.materialId]) {
                                materialBoardsCount[b.materialId] = 0;
                            }
                            materialBoardsCount[b.materialId]++;
                        })
                        return (
                            <div >
                                <div style={{ margin: '10px 0 6px' }}>
                                    <Checkbox checked={nestBoards.length === _boards.length} style={{ marginRight: 8 }} onClick={() => {
                                        props.onCupboardCheck(cupboardId);
                                    }} />
                                    <span style={{ fontSize: 14 }}>
                                        {_boards[0].cupboardIndex + 1}. {_boards[0].cupboardName}（{materials.map(m => m.name).join(' + ')}），
                                        已选：{(nestBoards.length)}/{_boards.length}，
                                        其中：{materials.map((m, i) => {
                                            return (<><span className="color-primary">{m.name}</span>选了<span className="color-primary" >{materialBoardsCount[m.id] || 0}</span>块{i === materials.length - 1 ? '' : '，'}</>)
                                        })}。</span>
                                </div>
                                <Table
                                    dataSource={_boards}
                                    pagination={false}
                                    columns={columns}
                                    showHeader={false}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <Table
                className="header-only"
                showHeader={false}
                footer={() => {
                    return (
                        <div style={{ textAlign: 'center' }}> <span style={{ fontWeight: 'bold', fontSize: 14 }}>{boards.length}</span> 块板</div>
                    )
                }}

            />

        </div>
    )
}


const BoardCards = forwardRef((props: {
    compatibleV1?: boolean;
    onShowBoardView: (board: CupboardBroad) => void
}, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [cupboard, setCupboard] = useState<Cupboard>();
    const [materialMap, setMaterialMap] = useState<Map<number | string, Material>>();
    const [scale, setScale] = useState(0);


    useImperativeHandle(ref, () => {
        return {
            open: (cupboard: Cupboard, materialMap: Map<(number | string), Material>) => {
                setCupboard(cupboard);
                setMaterialMap(materialMap);
                setVisible(true);
            },
            close: close
        }
    });

    const close = () => {
        setVisible(false);
        setCupboard(undefined);
    }

    const [cateBoards, setCateBoards] = useState<any[]>([]);
    useEffect(() => {
        if (!visible) {
            return;
        }
        const cateBoards: any[] = [];
        let maxHeight = cupboard.boards[0].height;
        let maxWidth = cupboard.boards[0].width;
        //板材分类, 收集所有板
        for (let j = 0; j < cupboard.boards.length; j++) {
            const board = cupboard.boards[j];
            if (board.height > maxHeight) {
                maxHeight = board.height;
            }
            if (board.width > maxWidth) {
                maxWidth = board.width;
            }
            let cate = cateBoards.find((cb) => cb.name === board.cate);
            if (!cate) {
                cate = {
                    name: board.cate,
                    boards: []
                }
                cateBoards.push(cate);
            }
            cate.boards.push(board);
        }
        setCateBoards(cateBoards);
        //计算高度比例， 并根据板材分好每个橱柜的类型
        setScale((280 - 10) / Math.max(maxHeight, maxWidth));
    }, [visible, cupboard])

    return (
        <Modal
            title={cupboard?.name + "的板子"}
            open={visible}
            centered
            onCancel={close}
            width={650}
            footer={[]}
            destroyOnClose

        >
            <div style={{ height: window.innerHeight - 400, overflowY: 'scroll' }}>

                {
                    cateBoards.map((cate) => {
                        return (
                            <div style={{ marginBottom: 20 }}>
                                <div className="cate-title" style={{ marginBottom: 4 }}>{cate.name}</div>
                                {
                                    cate.boards.map((board: CupboardBroad) => {
                                        const material = materialMap.get(board.materialId);
                                        return (
                                            <div className="board-item inline-block" style={{ width: 280, margin: '0 12px 12px 0', border: '1px solid #ddd' }}>
                                                <div className="flex" style={{ padding: '6px 12px', justifyContent: 'space-between' }}>
                                                    <span className="bold " style={{ fontSize: 14 }}>{board.name} - {board.type}</span>
                                                    <span>{board.connectorType} / {board.cornerType}</span>
                                                </div>
                                                <div className="flex center" style={{ backgroundColor: "black", height: 280, cursor: "zoom-in" }} onClick={() => {
                                                    // boardModal.current?.open(board, materialMap[board.materialId])
                                                    props.onShowBoardView(board)
                                                }}>
                                                    {
                                                        scale > 0 && (
                                                            <BoardGraph
                                                                board={board}
                                                                height={280}
                                                                width={280}
                                                                scale={scale}
                                                                material={material}
                                                                style={{ border: '1px solid lightgray', borderLeft: 0, borderRight: 0 }}
                                                                fill
                                                                draggable={false}
                                                                zoomable={false}
                                                                compatibleV1={props.compatibleV1}
                                                            />

                                                        )
                                                    }
                                                </div>
                                                <div className="flex center size-sm" style={{ justifyContent: 'space-between', padding: '6px 8px' }}>
                                                    <span className="color-gray" style={{ margin: '0 6px' }}>宽 {board.width}mm × 高 {board.height}mm</span>
                                                    <div className="flex center" >
                                                        <span style={{ marginLeft: 4 }}>板材：{material?.name || '无'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                <div className="color-gray" style={{ paddingTop: 10, textAlign: 'center' }}>到底了~</div>
            </div>

        </Modal>
    )
});

