// import {  } from "dxf-writer";
import { Arc, Line, Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner7 } from "../../coners/corner7";
import { rectBoard } from "../general-board";

export const menneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    nolashou?: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
): [Path[], Path[]] => {

    let jiaolianOffsetY = 0;
    let lbc = true;
    let rbc = true;
    let rtc = true;
    let ltc = true;
    if (!nolashou) {
        if (gxinglashou) {
            gxinglashouGaodu = gxinglashouGaodu as number;
            if (gxinglashouType === "left-or-right") {
                if (doorSide === "left") {
                    rbc = false;
                    rtc = false;
                } else {
                    lbc = false;
                    ltc = false;
                }
            } else {
                ltc = false;
                rtc = false;
                jiaolianOffsetY = -(10 + gxinglashouGaodu);
            }
        }
    }
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW = 0;
    let cornerBLH = 0;
    if (lbc) {
        cornerBL = corner7(dx, -dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];;
    let cornerBRW = 0;
    let cornerBRH = 0;
    if (rbc) {
        cornerBR = corner7(-dx, -dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW = 0;
    let cornerTRH = 0;
    if (rtc) {
        cornerTR = corner7(-dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW = 0;
    let cornerTLH = 0;
    if (ltc) {
        cornerTL = corner7(dx, dy, startX, startY, true, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }

    //门把手
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    if (jiaolianbianju > -1 && !nojiaoliankong) {
        if (doorSide !== "right") {

            paths.push({
                type: Arc,
                params: [
                    startX + dx * 38.769913, startY + dy * (jiaolianbianju - 14),
                    3,
                    90, 270
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913, startY + dy * (jiaolianbianju - 14) - dy * 3,
                    startX + dx * 38.769913 + dx * 2, startY + dy * (jiaolianbianju - 14) - dy * 3,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * (jiaolianbianju - 14),
                    3,
                    270, 450
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * (jiaolianbianju - 14) + dy * 3,
                    startX + dx * 38.769913, startY + dy * (jiaolianbianju - 14) + dy * 3,
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * 33.769913, startY + dy * (jiaolianbianju + 10),
                    17.75
                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * 38.769913, startY + dy * 48 + dy * (jiaolianbianju - 14),
                    3,
                    90, 270
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913, startY + dy * 48 + dy * (jiaolianbianju - 14) - dy * 3,
                    startX + dx * 38.769913 + dx * 2, startY + dy * 48 + dy * (jiaolianbianju - 14) - dy * 3,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * 48 + dy * (jiaolianbianju - 14),
                    3,
                    270, 450
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * 48 + dy * (jiaolianbianju - 14) + dy * 3,
                    startX + dx * 38.769913, startY + dy * 48 + dy * (jiaolianbianju - 14) + dy * 3,
                ]
            });
            //上部分
            paths.push({
                type: Arc,
                params: [
                    startX + dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * jiaolianOffsetY,
                    3,
                    90, 270
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 3 - dy * jiaolianOffsetY,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * jiaolianOffsetY,
                    3,
                    270, 450
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) + dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) + dy * 3 - dy * jiaolianOffsetY,
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * 33.769913, startY + dy * height - dy * (jiaolianbianju + 10) - dy * jiaolianOffsetY,
                    17.75
                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * jiaolianOffsetY,
                    3,
                    90, 270
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * 3 - dy * jiaolianOffsetY,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * jiaolianOffsetY,
                    3,
                    270, 450
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 + dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 + dy * 3 - dy * jiaolianOffsetY,
                ]
            });
        } else {
            paths.push({
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * (jiaolianbianju - 14),
                    3,
                    // 90, 270
                    270, 450
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * (jiaolianbianju - 14) - dy * 3,
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * (jiaolianbianju - 14) - dy * 3,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * (jiaolianbianju - 14),
                    3,
                    // 270, 450
                    90, 270,
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * (jiaolianbianju - 14) + dy * 3,
                    startX + dx * width - dx * 38.769913, startY + dy * (jiaolianbianju - 14) + dy * 3,
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * width - dx * 33.769913, startY + dy * (jiaolianbianju + 10),
                    17.75
                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * 48 + dy * (jiaolianbianju - 14),
                    3,
                    // 90, 270
                    270, 450,
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * 48 + dy * (jiaolianbianju - 14) - dy * 3,
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * 48 + dy * (jiaolianbianju - 14) - dy * 3,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * 48 + dy * (jiaolianbianju - 14),
                    3,
                    // 270, 450
                    90, 270
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * 48 + dy * (jiaolianbianju - 14) + dy * 3,
                    startX + dx * width - dx * 38.769913, startY + dy * 48 + dy * (jiaolianbianju - 14) + dy * 3,
                ]
            });
            //上半部分
            paths.push({
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * jiaolianOffsetY,
                    3,
                    // 90, 270
                    270, 450
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 3 - dy * jiaolianOffsetY,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * jiaolianOffsetY,
                    3,
                    // 270, 450
                    90, 270,
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) + dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * width - dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) + dy * 3 - dy * jiaolianOffsetY,
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * width - dx * 33.769913, startY + dy * height - dy * (jiaolianbianju + 10) - dy * jiaolianOffsetY,
                    17.75
                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * jiaolianOffsetY,
                    3,
                    // 90, 270
                    270, 450,
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * 3 - dy * jiaolianOffsetY,

                ]
            }, {
                type: Arc,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 - dy * jiaolianOffsetY,
                    3,
                    // 270, 450
                    90, 270
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 + dy * 3 - dy * jiaolianOffsetY,
                    startX + dx * width - dx * 38.769913, startY + dy * height - dy * (jiaolianbianju - 14) - dy * 48 + dy * 3 - dy * jiaolianOffsetY,
                ]
            });
        }

    }

    const corners: Path[] = [];
    if (!nolashou) {

        if (menminglashou) {
            menlashouX = menlashouX as number;
            menlashouY = menlashouY as number;
            menlashoubianju = menlashoubianju as number;
            if (doorSide === "right") {
                corners.push({
                    type: Circle,
                    params: [
                        startX + dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5),
                        2.1
                    ]
                });
                if (menlashoubianju > 0) {

                    if (menlashoufangxiang === "horizontal") {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * (menlashouX + 10.5 + menlashoubianju), startY + dy * height - dy * (menlashouY + 10.5),
                                2.1
                            ]
                        })
                    } else {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5 + menlashoubianju),
                                2.1
                            ]
                        })
                    }
                }
            } else {
                corners.push({
                    type: Circle,
                    params: [
                        startX + dx * width - dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5),
                        2.1
                    ]
                });
                if (menlashoubianju > 0) {

                    if (menlashoufangxiang === "horizontal") {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * width - dx * (menlashouX + 10.5 + menlashoubianju), startY + dy * height - dy * (menlashouY + 10.5),
                                2.1
                            ]
                        })
                    } else {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * width - dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5 + menlashoubianju),
                                2.1
                            ]
                        })
                    }
                }
            }

        }
    }

    return [
        paths,
        corners.concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}