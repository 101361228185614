import { Vector2d } from "konva/lib/types";

// type Point = { x: number, y: number };
type Point = Vector2d;

function isPointInPolygon(point: Point, polygon: Point[]): boolean {
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = polygon[i].x, yi = polygon[i].y;
        let xj = polygon[j].x, yj = polygon[j].y;

        let intersect = ((yi > point.y) !== (yj > point.y))
            && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}


export function isRectInPolygon(centerX: number, centerY: number, width: number, height: number, polygon: Point[]): boolean {
    let topLeft: Point = { x: centerX - width / 2, y: centerY - height / 2 };
    let topRight: Point = { x: centerX + width / 2, y: centerY - height / 2 };
    let bottomLeft: Point = { x: centerX - width / 2, y: centerY + height / 2 };
    let bottomRight: Point = { x: centerX + width / 2, y: centerY + height / 2 };
    return (
        isPointInPolygon(topLeft, polygon) &&
        isPointInPolygon(topRight, polygon) &&
        isPointInPolygon(bottomLeft, polygon) &&
        isPointInPolygon(bottomRight, polygon)
    );
}

export function isCircleInPolygon(x: number, y: number, radius: number, polygon: Point[]): boolean {
    return isRectInPolygon(x, y, radius * 2, radius * 2, polygon);
}