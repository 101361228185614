import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach } from "../../../util";
import { 无护角有卡槽 } from "./config";

export const corner2WaiWuhujiao = (
    dx: DirectX, 
    dy: DirectY, 
    startX: number, 
    startY: number, 
    corner: boolean, 
    sizeRes?: { width: number, height: number }
): Path[] => {
    let paths: Path[] = [];
    if(无护角有卡槽) {
        paths.push({
            type: Line,
            params: [
                startX, startY,
                startX + dx * 1.995, startY,
            ],
        }, {
            type: Line,
            params: [
                startX + dx * 1.995, startY,
                startX + dx * 1.995, startY + dy * 1.92,
            ]
        }, {
    
            type: Line,
            params: [
                startX + dx * 1.995, startY + dy * 1.92,
                startX, startY + dy * 1.92,
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * 1.92,
                startX, startY + dy * 1.92 + dy * 29.58,
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * 1.92 + dy * 29.58,
                startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58,
                startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 1.995, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
                startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
                startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
            ]
        })
    }else {
        paths.push({
            type: Line,
            params: [
                startX, startY,
                startX, startY + dy * 43.49
            ]
        })
    }
    paths.push({
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2,
            2.61,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 32.385, startY + dy * 1.92 / 2 + dy * 15.75,
            3.11,
        ]
    }, {
        type: Circle,
        params: [
                startX + dx * 32.385, startY + dy * 1.92 / 2 + dy * 15.75 + dy * 15.75,
                2.61,
        ]
    }, 
    // {
    //     type: Line,
    //     params: [
    //         startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92,
    //         startX, startY + dy * 1.92 + dy * 29.58,
    //     ]
    // },
     
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07,
        ]
    },
    {
        type: Arc,
        params: [
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2,
            0.2,
            270,
            360,
            // ...degreeTransform(270, 360, dx, dy),
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 1.8 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2,
            startX + dx * 1.8 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75,
            0.2,
            0,
            90,
            // ...degreeTransform(0, 90, dx, dy),
        ]
    },
     {
        type: Line,
        params: [
            startX + dx * 1.8, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
        ]
    },
     {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2,
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227,
        ]
    }, {
        type: Line,
        params: [
            startX, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227,
            startX + dx * 6.5, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5,
            startX + dx * 6.5 + dx * 17, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5,
            0.4,
            180, 
            450,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4,
            startX + dx * 6.5 + dx * 17 + dx * 0.4, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914,
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5,
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5,

        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5,
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5 - dy * 1.8,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5 - dy * 1.8,
            0.2,
            180,
            270
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5 - dy * 1.8 - dy * 0.2,
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022 + dx * 0.2 + dx * 1.75, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5 - dy * 1.8 - dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022 + dx * 0.2 + dx * 1.75, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5 - dy * 1.8,
            0.2,
            270,
            360
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022 + dx * 0.2 + dx * 1.75 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5 - dy * 1.8,
            startX + dx * 6.5 + dx * 17 + dx * 0.4 + dx * 6.5 + dx * 1.70022 + dx * 0.2 + dx * 1.75 + dx * 0.2, startY + dy * 1.92 + dy * 29.58 + dy * 1.92 + dy * 10.07 + dy * 0.2 + dy * 1.75 + dy * 0.2 + dy * 1.700227 + dy * 6.5 + dy * 0.4 + dy * 16.879914 + dy * 6.5,

        ]
    });
    if(typeof sizeRes === 'object') {
        sizeRes.width = 34.25022;
        sizeRes.height = 77.238423 + 0.381718; //77.74022;
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);

}