import { Group, Layer, Line, Rect, Text } from "react-konva";
import { RuleStage } from "../components/rule-stage";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Material, NestRequest, NestResult, NestSheet } from "../../../../../@interface";
import { useEffect, useState } from "react";
import { RectWithAngles } from "../../../components/graph/board";
import { BoardActionMap } from "../comp-draggable/draggable-data";

export const MaterialDxf = () => {


    // const { data: context} = useOutletContext<{ data: { data: NestRequest } }>();
    // const [data] = useState(context.data);
    
    const context = useOutletContext<{ data: NestRequest, onSheetBoardChange: (sheetId: string | number, boardActionMap: BoardActionMap) => void }>();
    const data = context.data;
    // console.log(context);
    const param = useParams<{ materialId?: string }>();
    const [material, setMaterial] = useState<Material>(null);
    const [result, setResult] = useState<NestResult>(null);
    const [ready, setReady] = useState(false);
    const [scale, setScale] = useState(1);
    const [hover, setHover] = useState(-1);
    
    useEffect(() => {
        if (!param.materialId) {
            return;
        }
        const result = data.nestResult.find((r) => r.materialId == param.materialId);
        const material = data.materials.find((m) => m.id == param.materialId);
        const settings = data.nestConfig.materialNestSettings.find((s) => s.materialId == param.materialId);
        const scale = window.innerHeight / 2 / settings.sheetHeight;
        setMaterial(material);
        setResult(result);
        setScale(scale);
        setReady(true);
        // const relatedSheetIds = Array.from(new Set(sheetBoardDragStore.globalActionList.map((a) => a.sheetId)));
        // console.log("relatedSheetIds", relatedSheetIds);
        
        // for (let i = 0; i < relatedSheetIds.length; i++) {
        //     const sheetId = relatedSheetIds[i];
        //     context.onSheetBoardChange(sheetId, sheetBoardDragStore.boardActionMaps);
        // }
    }, [param.materialId, data]);


    const navigate = useNavigate();

    return ready && (
        <div>
            <div>
                <RuleStage
                    width={window.innerWidth - 237}
                    height={window.innerHeight}
                    ruleScale={scale}
                    zoomable
                    draggable
                >
                    <Layer>
                        {
                            result.sheets.map((sheet, index) => {
                                const materialNest = result;
                                const sheetIndex = index;
                                const startX = sheet.width * 0.3 + sheet.width * 1.3 * (index);
                                const startY = sheet.height * 0.3 + 24;
                                return (
                                    <Group
                                        key={`group-` + index}
                                        onMouseEnter={() => {
                                            setHover(index);
                                        }} onMouseOut={() => {
                                            setHover(-1);
                                        }}
                                        onClick={() => {
                                            navigate(`/nest/result/${materialNest.materialId}/${sheet.id}`, {
                                                state: { data },
                                                replace: true
                                            });
                                        }}
                                    >
                                        <Text
                                            x={startX * scale + 5}
                                            y={startY * scale - 24}
                                            text={`大板${sheetIndex + 1}/${materialNest.sheets.length}`}
                                            stroke={hover === index ? "orange" : "#fff"}
                                            fontSize={14}
                                            strokeWidth={1}
                                            strokeScaleEnabled={false}
                                            letterSpacing={2}

                                        />
                                        {
                                            sheet.boards.map((board, index) => {
                                                return (
                                                    <RectWithAngles
                                                        key={"edit-" + index}
                                                        x={startX * scale + 1 + board.customX * scale}
                                                        y={startY * scale + 1 + board.customY * scale}
                                                        scale={scale}
                                                        board={board.board}
                                                        rotate={board.rotate}
                                                        material={material}
                                                        fill
                                                        background="#000"
                                                        compatibleV1={data.compatibleV1}
                                                    />
                                                )
                                            })
                                        }
                                        <Text
                                            x={startX * scale + sheet.width / 3 * scale}
                                            y={startY * scale + 12 + sheet.height * scale}
                                            text={`${sheet.boards.length}块小板`}
                                            stroke={hover === index ? "orange" : "#fff"}
                                            fontSize={12}
                                            strokeWidth={1}
                                            strokeScaleEnabled={false}
                                            letterSpacing={2}
                                            fontStyle="Tiny"

                                        />
                                        <Line
                                            x={startX * scale}
                                            y={startY * scale}
                                            points={[0, 0, sheet.width * scale + 1, 0]}
                                            strokeWidth={1}
                                            strokeScaleEnabled={false}
                                            stroke={hover === index ? "orange" : "gray"}
                                        />
                                        <Line
                                            x={startX * scale}
                                            y={startY * scale}
                                            points={[sheet.width * scale + 1, 0, sheet.width * scale + 1, sheet.height * scale + 1]}
                                            strokeWidth={1}
                                            strokeScaleEnabled={false}
                                            stroke={hover === index ? "orange" : "gray"}
                                        />
                                        <Line
                                            x={startX * scale}
                                            y={startY * scale}
                                            points={[0, 0, 0, sheet.height * scale + 2]}
                                            strokeWidth={1}
                                            strokeScaleEnabled={false}
                                            stroke={hover === index ? "orange" : "gray"}
                                        />
                                        <Line
                                            x={startX * scale}
                                            y={startY * scale}
                                            points={[0, sheet.height * scale + 1, sheet.width * scale + 1, sheet.height * scale + 1]}
                                            strokeWidth={1}
                                            strokeScaleEnabled={false}
                                            stroke={hover === index ? "orange" : "gray"}
                                        />
                                        <Rect
                                            x={startX * scale}
                                            y={startY * scale - 24}
                                            width={sheet.width * scale}
                                            height={sheet.height * scale + 24 + 12 + 12}
                                        />
                                    </Group>
                                )
                            })
                        }
                    </Layer>
                </RuleStage>
            </div>
        </div>
    )
}