import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05zhuanjiaoneiXBoard } from "./05-zhuanjiaonei-x";

export const _07zhuanjiaoneiXBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return _05zhuanjiaoneiXBoard(dx, dy, startX, startY, width, height);
}