import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _11ceneiBoardLeft } from "./11-cenei-left";

export const _11ceneiBoardMiddle = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
): [Path[], Path[]] => {
    const [ boardPaths, cornerPaths ] = _11ceneiBoardLeft(dx, dy, startX, startY, width, height);
    boardPaths.push({
        type: Circle,
        params: [
            startX + dx * width - dx * 71.1, startY + dy * 211,
            30
        ]
    })
    return [boardPaths, cornerPaths];
}