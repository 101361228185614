import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _02AdiwaiBoard } from "./02-a-diwai"

export const _12AdiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number,
    corner: boolean,
): [Path[], Path[]] => {
    const [boardPaths, cornerPaths] = _02AdiwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
    cornerPaths.push(
        {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a - 3.5), startY + dy * (60.78 - 15.75),
                2.61
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a - 3.5), startY + dy * 60.78,
                3.11
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a - 3.5), startY + dy * (60.78 + 15.75),
                2.61
            ]
        },
        
        {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a - 3.5), startY + dy * height - dy * (60.78 - 15.75),
                2.61
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a - 3.5), startY + dy * height - dy * 60.78,
                3.11
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * ( a - 3.5), startY + dy * height - dy * (60.78 + 15.75),
                2.61
            ]
        })
    return [boardPaths, cornerPaths];
}