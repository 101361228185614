import { Layer, Line, Rect, Transformer } from "react-konva"
import { DrawStructType, StructData, m2px } from "../../../store"
import { ShapeStruct } from "../$shapes/shape-struct";
import { CANVAS_SIZE } from "../../../store/base";
import { useEffect, useMemo, useRef, useState } from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { isCircleInPolygon, isRectInPolygon } from "../../../gpt/isRectInPolygon";
import { Vector2dLine } from "../../../types";
import { findClosestIntersections } from "../../../gpt/calcPointDistanceToPolygon";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { Html } from "react-konva-utils";
import { structBeamX, structBeamY, structRectPost, structRoundPost } from "../../../store/struct-store";
import { useStructEdit } from "./store";
import { message } from "antd";
import { Group } from "konva/lib/Group";

export const LayerEditStruct = (props: {}) => {

    const { targetData: editData, drawedArea, cancelDraw, setStageCursor, updateDrawed, updateDrawedToList } = useStructEdit();

    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        cancelDraw();
    }

    const transformerRef = useRef<any>();

    const setShapeRef = (shapeRef: any) => {
        if (shapeRef) {
            transformerRef.current?.nodes([shapeRef]);
            // transformerRef.current.getLayer().batchDraw();
        }
    }

    const [curPos, setCurPos] = useState<Vector2d>(null);
    const [curSize, setCurSize] = useState<{ x: number, y: number, z: number }>(null);
    const [style, setStyle] = useState({ stroke: "cyan", fill: "#ffffff", opacity: 1 });
    const [isDanger, setIsDanger] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isTransforming, setIsTransforming] = useState(false);

    const [offsetHelper, setOffsetHelper] = useState<{
        top: number, left: number, bottom: number, right: number,
        topLine: Vector2dLine, leftLine: Vector2dLine, bottomLine: Vector2dLine, rightLine: Vector2dLine
    }>(null);

    const [closestXHelper, setClosestXHelper] = useState<Vector2dLine>(null);
    const [closestYHelper, setClosestYHelper] = useState<Vector2dLine>(null);

    useEffect(() => {
        if (!editData) {
            setCurPos(null);
            setCurSize(null);
        } else {
            setCurPos(editData.pos);
            setCurSize(editData.size);
        }
    }, [editData]);


    useEffect(() => {
        if (isDanger) {
            setStyle({ stroke: "#ff0000aa", fill: "#ff000066", opacity: 1 })
        } else {
            setStyle({ stroke: "cyan", fill: "#ffffff", opacity: 1 })
        }
    }, [isDanger]);

    //判断是否在范围内
    useEffect(() => {
        if (!editData || !curSize || !curPos || !drawedArea) {
            setIsDanger(false);
            return;
        }
        let isIn = isStructInArea(curPos, curSize, drawedArea.innerContour);
        setIsDanger(!isIn);
    }, [curPos, curSize, editData, drawedArea]);

    const isStructInArea = (curPos: Vector2d, curSize: Vector2d, contour: Vector2d[]) => {
        const isIn = isRectInPolygon(curPos.x, curPos.y, curSize.x - 1, curSize.y - 1, contour);
        return isIn;
    }

    //计算offset辅助线
    useEffect(() => {
        if (!curPos || !drawedArea) {
            setOffsetHelper(null);
            return;
        }
        if (isDanger) {
            setOffsetHelper(null);
        } else {
            const offset = findClosestIntersections(curPos, drawedArea.innerContour);
            setOffsetHelper(offset);

        }
    }, [curPos, drawedArea, isDanger]);


    //计算closest辅助线
    useEffect(() => {
        if (!offsetHelper || !curSize || isTransforming || isDanger) {
            setClosestXHelper(null);
            setClosestYHelper(null);
            return;
        }
        const approachDistance = 10;
        let yLine: Vector2dLine = null;
        if (offsetHelper.left - curSize.x < approachDistance && offsetHelper.left < offsetHelper.right) {
            yLine = offsetHelper.leftLine;
        }
        if (offsetHelper.right - curSize.x < approachDistance && offsetHelper.right < offsetHelper.left) {
            yLine = offsetHelper.rightLine;
        }

        let xLine: Vector2dLine = null;
        if (offsetHelper.top - curSize.y < approachDistance && offsetHelper.top < offsetHelper.bottom) {
            xLine = offsetHelper.topLine;
        }
        if (offsetHelper.bottom - curSize.y < approachDistance && offsetHelper.bottom < offsetHelper.top) {
            xLine = offsetHelper.bottomLine;
        }
        setClosestXHelper(xLine);
        setClosestYHelper(yLine);
    }, [offsetHelper, curSize]);


    const onUpdateTop = (top: number /* px */) => {
        if (isNaN(top)) {
            return;
        }
        if (!offsetHelper || !curPos || !curSize || !drawedArea) {
            return;
        }
        const y = curPos.y + top - offsetHelper.top;

        const valid = y - curSize.y / 2 >= drawedArea.innerBound.top &&
            y + curSize.y / 2 <= drawedArea.innerBound.bottom;
        if (!valid) {
            message.info("超出房间范围");
            return;
        }
        const drawed = {
            type: editData.structType,
            data: {
                ...editData,
                pos: {
                    x: curPos.x,
                    y: y
                }
            }
        };
        updateDrawed(drawed);
    }

    const onUpdateBottom = (bottom: number) => {

        if (isNaN(bottom)) {
            return;
        }
        if (!offsetHelper || !curPos || !curSize || !drawedArea) {
            return;
        }
        const y = curPos.y - (bottom - offsetHelper.bottom);

        const valid = y - curSize.y / 2 >= drawedArea.innerBound.top &&
            y + curSize.y / 2 <= drawedArea.innerBound.bottom;
        if (!valid) {
            message.info("超出房间范围");
            return;
        }
        const drawed = {
            type: editData.structType,
            data: {
                ...editData,
                pos: {
                    x: curPos.x,
                    y: y
                }
            }
        };
        updateDrawed(drawed);
    }

    const onUpdateLeft = (left: number) => {

        if (isNaN(left)) {
            return;
        }
        if (!offsetHelper || !curPos || !curSize || !drawedArea) {
            return;
        }
        const x = curPos.x + left - offsetHelper.left;

        const valid = x - curSize.x / 2 >= drawedArea.innerBound.left &&
            x + curSize.x / 2 <= drawedArea.innerBound.right;

        if (!valid) {
            message.info("超出房间范围");
            return;
        }
        const drawed = {
            type: editData.structType,
            data: {
                ...editData,
                pos: {
                    x: x,
                    y: curPos.y
                }
            }
        };
        updateDrawed(drawed);
    }

    const onUpdateRight = (right: number) => {

        if (isNaN(right)) {
            return;
        }
        if (!offsetHelper || !curPos || !curSize || !drawedArea) {
            return;
        }
        const x = curPos.x - (right - offsetHelper.right);
        const valid = x - curSize.x / 2 >= drawedArea.innerBound.left &&
            x + curSize.x / 2 <= drawedArea.innerBound.right;

        if (!valid) {
            message.info("超出房间范围");
            return;
        }
        const drawed = {
            type: editData.structType,
            data: {
                ...editData,
                pos: {
                    x: x,
                    y: curPos.y
                }
            }
        };

        updateDrawed(drawed);
    }

    const onUpdateSize = (x: number, y: number, z: number) => {
        if (!editData) {
            return;
        }
        const valid = curPos.y - y / 2 >= drawedArea.innerBound.top &&
            curPos.y + y / 2 <= drawedArea.innerBound.bottom &&
            curPos.x - x / 2 >= drawedArea.innerBound.left &&
            curPos.x + x / 2 <= drawedArea.innerBound.right;
        if (!valid) {
            message.info("超出房间范围");
            return;
        }
        const drawed = {
            type: editData.structType,
            data: {
                ...editData,
                size: {
                    x, y, z
                }
            }
        };
        updateDrawed(drawed);
    }

    /**
     * 修改离地高度
     * @param groundClearance 
     */
    const onUpdateGroundClearance = (groundClearance: number) => {
        
        const drawed = {
            type: editData.structType,
            data: {
                ...editData,
                groundClearance
            }
        };
        updateDrawed(drawed);
    }

    const [boundSize, setBoundSize] = useState<{ x: number, y: number }>(null);

    useEffect(() => {
        if (boundSize && editData) {
            setCurSize({
                x: boundSize.x,
                y: boundSize.y,
                z: editData.size.z
            });
        }
    }, [boundSize]);

    // const transformCache = useMemo(() => {
    //     return {
    //         originX: 0,
    //         originY: 0
    //     }
    // }, []);

    return (
        <>

            <Layer
                visible={Boolean(editData)}
            >
                <Rect
                    x={0}
                    y={0}
                    width={CANVAS_SIZE * m2px}
                    height={CANVAS_SIZE * m2px}
                    onClick={onClick}
                    listening={false}
                />

                {

                    useMemo(() => {
                        //此处只产生pos数据
                        if (!editData || !curPos || !curSize) {
                            return;
                        }
                        return (
                            <ShapeStruct
                                setShapeRef={setShapeRef}
                                draggable
                                {...editData}
                                style={style}
                                pos={curPos}
                                // size={curSize}
                                onDragStart={() => {
                                    setIsDragging(true);
                                }}
                                onDragMove={(e) => {
                                    if (editData.structType.indexOf("struct-beam") === 0) {
                                        if (editData.direct === 'x') {
                                            e.currentTarget.x(editData.pos.x);
                                            setCurPos({
                                                x: editData.pos.x,
                                                y: e.currentTarget.y()
                                            })
                                        } else {
                                            e.currentTarget.y(editData.pos.y);
                                            setCurPos({
                                                x: e.currentTarget.x(),
                                                y: editData.pos.y
                                            })
                                        }
                                    } else {
                                        setCurPos({
                                            x: e.currentTarget.x(),
                                            y: e.currentTarget.y()
                                        });

                                    }
                                }}
                                onDragEnd={(e) => {
                                    //dragEnd以mouseUp为准
                                    if (isDanger || !offsetHelper) {
                                        //返回原点
                                        setCurPos({
                                            x: editData.pos.x,
                                            y: editData.pos.y
                                        });

                                    } else {
                                        let x = e.currentTarget.x();
                                        let y = e.currentTarget.y();
                                        if (editData.structType.indexOf("struct-beam") === 0) {
                                            if (editData.direct === 'x') {
                                                x = editData.pos.x;
                                            } else {
                                                y = editData.pos.y;
                                            }
                                        }
                                        if (closestXHelper) {
                                            if (offsetHelper.top < offsetHelper.bottom) {
                                                //靠近上边
                                                y = closestXHelper.from.y + curSize.y / 2;
                                            } else {
                                                //靠近下边
                                                y = closestXHelper.from.y - curSize.y / 2;
                                            }
                                        }
                                        if (closestYHelper) {
                                            if (offsetHelper.left < offsetHelper.right) {
                                                //靠近左边
                                                x = closestYHelper.from.x + curSize.x / 2;
                                            } else {
                                                //靠近右边
                                                x = closestYHelper.from.x - curSize.x / 2;
                                            }
                                        }
                                        const drawed = {
                                            type: editData.structType,
                                            data: {
                                                ...editData,
                                                size: {
                                                    x: curSize.x,
                                                    y: curSize.y,
                                                    z: editData.size.z
                                                },
                                                pos: {
                                                    x: x,
                                                    y: y
                                                }
                                            }
                                        };
                                        setCurPos({ x, y })
                                        updateDrawed(drawed);
                                    }
                                    setIsDanger(false);
                                    setStageCursor(null);
                                    setIsDragging(false);
                                }}
                                onMouseOver={() => {
                                    setStageCursor("move");
                                }}
                                onMouseLeave={() => {
                                    setStageCursor(null);
                                }}
                                onMouseDown={(e) => {
                                    if (e.evt.button === 1) {
                                        cancelDraw();
                                    }
                                }}

                            />
                        )

                    }, [editData, style, curSize, isDanger, offsetHelper, closestXHelper, closestYHelper])
                }
                <Transformer
                    ref={transformerRef}
                    rotateEnabled={false}
                    borderEnabled={false}
                    flipEnabled={false}
                    anchorSize={10}
                    anchorCornerRadius={10}
                    anchorStroke={isDanger ? 'pink' : 'cyan'}
                    anchorStrokeWidth={1}
                    anchorFill="#0000ff11"
                    enabledAnchors={
                        editData?.structType === 'struct-round-post' ?
                            ["top-left", "top-right", "bottom-left", "bottom-right"] :
                            editData?.structType.indexOf("struct-beam") === 0 ?
                                editData.direct === 'x' ? ['top-center', 'bottom-center'] : ['middle-left', 'middle-right'] : undefined}
                    centeredScaling
                    onTransformStart={(e) => {
                        setIsTransforming(true);
                    }}
                    onTransform={(e) => {
                        //setBoundSize -> setCurSize不会影响编辑层shape的大小，只会影响store的大小，由scale控制transformer时的大小
                        //所以transform时，真正改变大小，需要在onTransformEnd中处理，并把scale恢复为1
                        if(editData.structType.indexOf("struct-beam") === 0){
                            if(editData.direct === 'x') {
                                e.currentTarget.width(editData.size.x);
                                setBoundSize({
                                    x: editData.size.x,
                                    y: e.currentTarget.height()
                                });
                            }else {
                                e.currentTarget.height(editData.size.y);
                                setBoundSize({
                                    x: e.currentTarget.width(),
                                    y: editData.size.y
                                });
                            }
                        }else {
                            setBoundSize({
                                x: e.currentTarget.width(),
                                y: e.currentTarget.height()
                            });
                        }
                        
                    }}
                    onTransformEnd={(e) => {
                        if (boundSize && !isDanger) {
                            onUpdateSize(boundSize.x, boundSize.y, editData.size.z);

                        }else {
                            //恢复curSize大小
                            setCurSize({
                                x: editData.size.x,
                                y: editData.size.y,
                                z: editData.size.z
                            });

                        }
                        //关键一行,重置子节点的scale!
                        transformerRef.current?.nodes()[0]?.setAttrs({
                            scaleX: 1,
                            scaleY: 1,
                        });
                        setIsTransforming(false);
                        setBoundSize(null);


                    }}
                />
                {
                    useMemo(() => {
                        if (!editData || !curPos || !curSize || !offsetHelper) {
                            return;
                        }
                        return (
                            <>
                                {
                                    // x梁不显示x辅助线
                                    !(editData.structType.indexOf("struct-beam") === 0 && editData.direct === 'x') && (
                                        <>
                                            <ShapeDimension
                                                direct={"x"}
                                                from={curPos.x - offsetHelper.left}
                                                to={curPos.x - curSize.x / 2}
                                                offset={0}
                                                base={curPos.y}
                                                canInput={!isDragging && !isTransforming}
                                                onLengthSet={(length) => {
                                                    onUpdateLeft(length + curSize.x / 2);
                                                }}
                                            />
                                            <ShapeDimension
                                                direct={"x"}
                                                from={curPos.x + curSize.x / 2}
                                                to={curPos.x + offsetHelper.right}
                                                offset={0}
                                                base={curPos.y}
                                                canInput={!isDragging && !isTransforming}
                                                onLengthSet={(length) => {
                                                    onUpdateRight(length + curSize.x / 2);
                                                }}
                                            />
                                        </>
                                    )
                                }
                                {
                                    !(editData.structType.indexOf("struct-beam") === 0 && editData.direct === 'y') && (
                                        <>
                                            <ShapeDimension
                                                direct={"y"}
                                                from={curPos.y - offsetHelper.top}
                                                to={curPos.y - curSize.y / 2}
                                                offset={0}
                                                base={curPos.x}
                                                canInput={!isDragging && !isTransforming}
                                                onLengthSet={(length) => {
                                                    onUpdateTop(length + curSize.y / 2);
                                                }}
                                            />
                                            <ShapeDimension
                                                direct={"y"}
                                                from={curPos.y + curSize.y / 2}
                                                to={curPos.y + offsetHelper.bottom}
                                                offset={0}
                                                base={curPos.x}
                                                canInput={!isDragging && !isTransforming}
                                                onLengthSet={(length) => {
                                                    onUpdateBottom(length + curSize.y / 2);
                                                }}
                                            />
                                        </>
                                    )
                                }
                                {
                                    closestXHelper && (
                                        <Line
                                            points={[
                                                closestXHelper.from.x, closestXHelper.from.y,
                                                closestXHelper.to.x, closestXHelper.to.y
                                            ]}
                                            stroke={"cyan"}
                                            strokeWidth={2}
                                            strokeScaleEnabled={false}
                                        />
                                    )
                                }
                                {
                                    closestYHelper && (
                                        <Line
                                            points={[
                                                closestYHelper.from.x, closestYHelper.from.y,
                                                closestYHelper.to.x, closestYHelper.to.y
                                            ]}
                                            stroke={"cyan"}
                                            strokeWidth={2}
                                            strokeScaleEnabled={false}

                                        />
                                    )
                                }
                            </>
                        )
                    }, [offsetHelper, curPos, curSize, editData, isDragging, isTransforming])
                }

            </Layer>
            {
                useMemo(() => {
                    return (
                        <Layer>
                            <Html
                                // groupProps={{

                                // }}
                                transform={false}
                            >
                                {
                                    curSize && curPos && offsetHelper && editData && (
                                        editData.structType === 'struct-round-post' ? (
                                            <StructRoundPost
                                                radius={curSize.x / 2}
                                                zHeight={curSize.z}
                                                offsetTop={offsetHelper.top - curSize.y / 2}
                                                offsetBottom={offsetHelper.bottom - curSize.y / 2}
                                                offsetLeft={offsetHelper.left - curSize.x / 2}
                                                offsetRight={offsetHelper.right - curSize.x / 2}
                                                onZHeightChange={(zHeight) => {
                                                    onUpdateSize(curSize.x, curSize.y, zHeight);
                                                }}
                                                onRadiusChange={(radius) => {
                                                    onUpdateSize(radius * 2, radius * 2, editData.size.z);
                                                }}
                                                onOffsetTopChange={(offset: number) => {
                                                    onUpdateTop(offset + curSize.y / 2);
                                                }}
                                                onOffsetBottomChange={(offset: number) => {
                                                    onUpdateBottom(offset + curSize.y / 2);

                                                }}
                                                onOffsetLeftChange={(offset: number) => {
                                                    onUpdateLeft(offset + curSize.x / 2);

                                                }}
                                                onOffsetRightChange={(offset: number) => {
                                                    onUpdateRight(offset + curSize.x / 2);

                                                }}
                                            />
                                        ) : editData.structType === 'struct-rect-post' ? (
                                            <StructRectPost
                                                width={curSize.x}
                                                height={curSize.y}
                                                zHeight={curSize.z}
                                                offsetTop={offsetHelper.top - curSize.y / 2}
                                                offsetBottom={offsetHelper.bottom - curSize.y / 2}
                                                offsetLeft={offsetHelper.left - curSize.x / 2}
                                                offsetRight={offsetHelper.right - curSize.x / 2}
                                                onSizeChange={(width: number, height: number) => {
                                                    onUpdateSize(width, height, editData.size.z);
                                                }}
                                                onZHeightChange={(zHeight) => {
                                                    onUpdateSize(curSize.x, curSize.y, zHeight);
                                                }}
                                                onOffsetTopChange={(offset: number) => {
                                                    onUpdateTop(offset + curSize.y / 2);
                                                }}
                                                onOffsetBottomChange={(offset: number) => {
                                                    onUpdateBottom(offset + curSize.y / 2);

                                                }}
                                                onOffsetLeftChange={(offset: number) => {
                                                    onUpdateLeft(offset + curSize.x / 2);

                                                }}
                                                onOffsetRightChange={(offset: number) => {
                                                    onUpdateRight(offset + curSize.x / 2);

                                                }}

                                            />
                                        ) : editData.structType.indexOf("struct-beam") === 0 ? (
                                            editData.direct === 'x' ? (
                                                <StructBeamX
                                                    sizeY={curSize.y}
                                                    sizeZ={curSize.z}
                                                    offsetTop={offsetHelper.top - curSize.y / 2}
                                                    offsetBottom={offsetHelper.bottom - curSize.y / 2}
                                                    groundClearance={editData.groundClearance}
                                                    onSizeYChange={(sizeY) => {
                                                        onUpdateSize(curSize.x, sizeY, editData.size.z);
                                                    }}
                                                    onSizeZChange={(sizeZ) => {
                                                        onUpdateSize(curSize.x, curSize.y, sizeZ);
                                                    }}
                                                    onOffsetTopChange={(offset) => {
                                                        onUpdateTop(offset + curSize.y / 2);
                                                    }}
                                                    onOffsetBottomChange={(offset) => {
                                                        onUpdateBottom(offset + curSize.y / 2);
                                                    }}
                                                    onGroundClearanceChange={(groundClearance) => {
                                                        onUpdateGroundClearance(groundClearance);
                                                    }}

                                                />
                                            ) : (
                                                <StructBeamY
                                                    sizeX={curSize.x}
                                                    sizeZ={curSize.z}
                                                    offsetLeft={offsetHelper.left - curSize.x / 2}
                                                    offsetRight={offsetHelper.right - curSize.x / 2}
                                                    groundClearance={editData.groundClearance}
                                                    onSizeXChange={(sizeX) => {
                                                        onUpdateSize(sizeX, curSize.y, editData.size.z);
                                                    }}
                                                    onSizeZChange={(sizeZ) => {
                                                        onUpdateSize(curSize.x, curSize.y, sizeZ);
                                                    }}
                                                    onOffsetLeftChange={(offset) => {
                                                        onUpdateLeft(offset + curSize.x / 2);
                                                    }}
                                                    onOffsetRightChange={(offset) => {
                                                        onUpdateRight(offset + curSize.x / 2);
                                                    }}
                                                    onGroundClearanceChange={(groundClearance) => {
                                                        onUpdateGroundClearance(groundClearance);
                                                    }}
                                                />
                                            )
                                        ) : null
                                    )
                                }
                            </Html>
                        </Layer>
                    )
                }, [curSize, curPos, offsetHelper, editData])
            }

        </>
    )
}


export const StructRoundPost = (props: {
    radius: number,
    zHeight: number,
    offsetTop: number,
    offsetBottom: number,
    offsetLeft: number,
    offsetRight: number,
    onRadiusChange: (radius: number) => void,
    onZHeightChange: (zHeight: number) => void,
    onOffsetTopChange: (offset: number) => void,
    onOffsetBottomChange: (offset: number) => void,
    onOffsetLeftChange: (offset: number) => void,
    onOffsetRightChange: (offset: number) => void,
}) => {

    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(structRoundPost.updateStoreBatch({
        radius: props.radius,
        zHeight: props.zHeight,
        top: props.offsetTop,
        bottom: props.offsetBottom,
        left: props.offsetLeft,
        right: props.offsetRight
    }));

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== structRoundPost.$getStore()) {
            structRoundPost.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        if (!init) {
            return;
        }
        setStore({
            radius: props.radius,
            zHeight: props.zHeight,
            top: props.offsetTop,
            bottom: props.offsetBottom,
            left: props.offsetLeft,
            right: props.offsetRight
        });
    }, [init, props.offsetTop, props.offsetBottom, props.offsetLeft, props.offsetRight, props.radius, props.zHeight]);

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{structRoundPost.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    structRoundPost.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {
                                if (Math.abs(newStore[key] - store[key]) > 0.1) {
                                    // console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'radius') {
                                        props.onRadiusChange(newStore[key]);
                                    } else if(key === 'zHeight') {
                                        props.onZHeightChange(newStore[key]);
                                    } else if (key === 'top') {
                                        props.onOffsetTopChange(newStore[key]);
                                    } else if (key === 'bottom') {
                                        props.onOffsetBottomChange(newStore[key]);
                                    } else if (key === 'left') {
                                        props.onOffsetLeftChange(newStore[key]);
                                    } else if (key === 'right') {
                                        props.onOffsetRightChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}


export const StructRectPost = (props: {
    height: number,
    width: number,
    zHeight: number,
    offsetTop: number,
    offsetBottom: number,
    offsetLeft: number,
    offsetRight: number,
    onSizeChange: (width: number, height: number) => void,
    onZHeightChange: (zHeight: number) => void,
    onOffsetTopChange: (offset: number) => void,
    onOffsetBottomChange: (offset: number) => void,
    onOffsetLeftChange: (offset: number) => void,
    onOffsetRightChange: (offset: number) => void,
}) => {



    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(structRectPost.updateStoreBatch({
        height: props.height,
        width: props.width,
        zHeight: props.zHeight,
        top: props.offsetTop,
        bottom: props.offsetBottom,
        left: props.offsetLeft,
        right: props.offsetRight
    }));

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== structRoundPost.$getStore()) {
            structRoundPost.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        if (!init) {
            return;
        }
        setStore({
            width: props.width,
            height: props.height,
            zHeight: props.zHeight,
            top: props.offsetTop,
            bottom: props.offsetBottom,
            left: props.offsetLeft,
            right: props.offsetRight
        });
    }, [init, props.offsetTop, props.offsetBottom, props.offsetLeft, props.offsetRight, props.width, props.height, props.zHeight]);


    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{structRectPost.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    structRectPost.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {
                                if (Math.abs(newStore[key] - store[key]) > 0.1) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'width') {
                                        props.onSizeChange(newStore[key], newStore['height']);
                                    } else if (key === 'height') {
                                        props.onSizeChange(newStore['width'], newStore[key]);

                                    }else if(key === 'zHeight') {
                                        props.onZHeightChange(newStore[key]);
                                    } else if (key === 'top') {
                                        props.onOffsetTopChange(newStore[key]);
                                    } else if (key === 'bottom') {
                                        props.onOffsetBottomChange(newStore[key]);
                                    } else if (key === 'left') {
                                        props.onOffsetLeftChange(newStore[key]);
                                    } else if (key === 'right') {
                                        props.onOffsetRightChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}

export const StructBeamX = (props: {
    sizeY: number,
    sizeZ: number,
    offsetTop: number,
    offsetBottom: number,
    groundClearance: number,
    onSizeYChange: (sizeY: number) => void,
    onSizeZChange: (sizeZ: number) => void,
    onOffsetTopChange: (offset: number) => void,
    onOffsetBottomChange: (offset: number) => void,
    onGroundClearanceChange: (groundClearance: number) => void,
}) => {


    const [init, setInit] = useState(false);
    const [store, setStore] = useState(structBeamX.updateStoreBatch({
        sizeY: props.sizeY,
        sizeZ: props.sizeZ,
        top: props.offsetTop,
        bottom: props.offsetBottom,
        groundClearance: props.groundClearance,

    }));

    useEffect(() => {
        setInit(true);
    }, [store]);


    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== structRoundPost.$getStore()) {
            structRoundPost.updateStoreBatch(store);
        }
    }, [init, store]);


    useEffect(() => {
        if (!init) {
            return;
        }
        setStore({
            sizeY: props.sizeY,
            sizeZ: props.sizeZ,
            top: props.offsetTop,
            bottom: props.offsetBottom,
            groundClearance: props.groundClearance,
        });
    }, [init, props.offsetTop, props.offsetBottom, props.sizeY, props.sizeZ, props.groundClearance]);


    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{structBeamX.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    structBeamX.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {
                                if (Math.abs(newStore[key] - store[key]) > 0.1) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'sizeY') {
                                        props.onSizeYChange(newStore[key]);
                                    } else if (key === 'sizeZ') {
                                        props.onSizeZChange(newStore[key]);
                                    } else if (key === 'top') {
                                        props.onOffsetTopChange(newStore[key]);
                                    } else if (key === 'bottom') {
                                        props.onOffsetBottomChange(newStore[key]);
                                    } else if (key === 'groundClearance') {
                                        props.onGroundClearanceChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )

}



export const StructBeamY = (props: {
    groundClearance: number,
    sizeX: number,
    sizeZ: number,
    offsetLeft: number,
    offsetRight: number,
    onSizeXChange: (sizeY: number) => void,
    onSizeZChange: (sizeZ: number) => void,
    onOffsetLeftChange: (offset: number) => void,
    onOffsetRightChange: (offset: number) => void,
    onGroundClearanceChange: (groundClearance: number) => void,
}) => {

    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(structBeamY.initStore());
  
    useEffect(() => {
        setInit(true);
    }, [store]);


    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== structRoundPost.$getStore()) {
            structRoundPost.updateStoreBatch(store);
        }
    }, [init, store]);


    useEffect(() => {
        if (!init) {
            return;
        }
        setStore({
            sizeX: props.sizeX,
            sizeZ: props.sizeZ,
            left: props.offsetLeft,
            right: props.offsetRight,
            groundClearance: props.groundClearance,
        });
    }, [init, props.offsetLeft, props.offsetRight, props.sizeX, props.sizeZ, props.groundClearance]);


    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{structBeamY.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    structBeamY.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {
                                if (Math.abs(newStore[key] - store[key]) > 0.1) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'sizeX') {
                                        props.onSizeXChange(newStore[key]);
                                    } else if (key === 'sizeZ') {
                                        props.onSizeZChange(newStore[key]);
                                    } else if (key === 'left') {
                                        props.onOffsetLeftChange(newStore[key]);
                                    } else if (key === 'right') {
                                        props.onOffsetRightChange(newStore[key]);
                                    } else if (key === 'groundClearance') {
                                        props.onGroundClearanceChange(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }
            </div>
        </div>
    )
}