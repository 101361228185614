import { useContext, useEffect, useState } from "react";
import { FloorPlannerContext } from "../../../context-reducer";
import { DrawStructType, DrawedMeta, DrawedStructMeta, StructData, useDrawedArea } from "../../../store";


export const useStructDrawing = () => {

    const { state, dispatch } = useContext(FloorPlannerContext);
    const [ drawStructType, setDrawStructType] = useState<string>(null);

    const drawedArea = useDrawedArea();

    useEffect(() => {
        const drawStructType = state.drawType?.indexOf("struct") === 0 ? state.drawType : null;
        setDrawStructType(drawStructType);
    }, [state.drawType]);

    const pushDrawed = (drawed: DrawedStructMeta) => {
        dispatch({
            type: "pushDrawed",
            payload: drawed
        })
    }
    
    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        })
    }

    return {
        floorHeight: state.floorHeight,
        floorThickness: state.floorThickness,
        drawedArea,
        drawStructType: drawStructType as DrawStructType,
        brushPos: state.brushPos,
        pushDrawed,
        cancelDraw

    }
}


/**
 * 获取当前编辑的结构数据
 * @returns 
 */
export const useStructEdit = () => {
    const {  state, dispatch } = useContext(FloorPlannerContext);

    const [ targetData, setTargetData ] = useState<StructData>(null);
    const drawedArea = useDrawedArea();

    useEffect(() => {
        if(state.editTarget?.type?.indexOf("struct") === 0) {
            setTargetData(state.editTarget.data as StructData);
        }else {
            setTargetData(null)
        }
    }, [state.editTarget]);

    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        });
    }

    const setStageCursor = (cursor: "move" | null) => {
        dispatch({
            type: "setStageCursor",
            payload: cursor
        })
    }

    const updateDrawed = (drawed: DrawedStructMeta) => {
        dispatch({
            type: "updateDrawed",
            payload: drawed
        })
    }

    const updateDrawedToList = (drawed: DrawedStructMeta) => {
        const drawedList = state.drawedList.map((d) => {
            if(d.data.id === drawed.data.id) {
                return drawed;
            }
            return d;
        });
        dispatch({
            type: "updateDrawedList",
            payload: drawedList
        })
    }

    return { targetData, drawedArea, cancelDraw, setStageCursor, updateDrawed, updateDrawedToList };

}
