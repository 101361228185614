import { Arc, Circle, Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { degreeTransformForEach } from "../../util";

export const corner1 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    
    let paths: Path[] = [{
        type: Line,
        params: [
            startX, startY,
            startX + dx * 12, startY
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY,
            startX + dx * 12, startY + dy * 4.95
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95,
            startX + dx * 12 + dx * 2, startY + dy * 4.95
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 12 + dx * 2 + dx * 5.39, startY + dy * 4.95 + dy * 1.61,
            3.61
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95,
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * 12 + dx * 2 + dx * 5.39, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 1.61,
            3.61,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88,
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123,
            0.2,
            90,
            180,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2,
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2,
            0.2,
            270,
            360,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2,
            startX + dx * 12 + dx * 0.2 + dx * 1.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2,
            0.2,
            0,
            90
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 0.2 + dx * 1.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2,
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2,
            0.2,
            180,
            270
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2,
            startX + dx * 12, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71,
            2,
            90,
            180
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2,
            startX + dx * 12 + dx * 2 + dx * 1.01, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2,
            startX + dx * 12 + dx * 2 + dx * 1.01, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01,
            2,
            90,
            180
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01 + dy * 2,
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 + dy * 2 + dy * 1.01 + dy * 2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
            0.2,
            0,
            90
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
            0.2,
            180,
            270

        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2 - dy * 0.2,
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2 - dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
            0.2,
            270,
            360,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 - dy * 1.2,
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2,
            0.2,
            90,
            180,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2,
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2 + dx * 6.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2,
        ]
    }, {
        type: Arc,
        params: [
            startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2 + dx * 6.2, startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2 + dy * 0.51,
            0.51,
            270,
            270 + 68.962446917137058017291095061078,
        ]
    }];
    let nowX = startX + dx * 12 + dx * 2 + dx * 1.01 + dx * 2 + dx * 1.71 + dx * 0.2 + dx * 0.2 + dx * 2.2 + dx * 0.2 + dx * 0.2 + dx * 6.2 + dx * 0.51 * Math.sin(68.962446917137058017291095061078 / 360 * 2 * Math.PI);
    let nowY = startY + dy * 4.95 + dy * 3.62 + dy * 27.88 + dy * 3.62 + dy * 8.315123 + dy * 0.2 + dy * 0.2 + dy * 2.2 + dy * 0.2 + dy * 0.2 + dy * 1.71 - dy * 0.2 + dy * 2 + dy * 1.01 + dy * 2 + dy * 0.2 + dy * 0.51 * (1 - Math.cos(68.962446917137058017291095061078 / 360 * 2 * Math.PI));
    paths.push({
        type: Line,
        params: [
            nowX, nowY,
            nowX + dx * 4.469648, nowY + dy * 11.65308/* 11.67308 */
        ]
    });
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = Math.abs(nowX + dx * 4.469648 - startX);
            sizeRes.height = Math.abs(nowY + dy * 11.65308 - startY);
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);
}