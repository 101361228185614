import { useEffect, useState } from "react"
import { Arc, Circle, Layer, Line, Shape, Stage } from "react-konva";
import { NestRequest } from "../../../../../@interface";
interface Props {

}
export const NestChecking = (props: Props) => {

    const [data, setData] = useState<NestRequest>();
    const [computedData, setComputedData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [stageScale, setStageScale] = useState(1);

    const [lineWidth, setLineWidth] = useState(1);

    useEffect(() => {
        if (!stageScale) {
            return;
        }
        const scale = 1 / stageScale;
        let width = scale;
        if (width > 1) {
            width = 1;
        }
        setLineWidth(width);
    }, [stageScale]);
    

    useEffect(() => {
        setData(JSON.parse((localStorage.getItem("SK_NEST_REQUEST_TEMP"))));
    }, []);

    useEffect(() => {
        if(!data) {
            return ;
        }
        let offsetX = 0;    //同一个柜的板在同一个X轴， 多板的偏移通过此变量控制
        let offsetY = 0;    //不同柜通过不同的Y level区分，由此变量控制Y轴偏移
        let topLevel = Number.MIN_SAFE_INTEGER
        let bottomLevel = Number.MAX_SAFE_INTEGER;
        let leftLevel = Number.MAX_SAFE_INTEGER;
        let rightLevel = Number.MIN_SAFE_INTEGER;
        console.log("===========start=============");
        const _data = JSON.parse(JSON.stringify(data));
        _data.cupboards.forEach((cupboard, index) => {
            let thisBoardTop = Number.MIN_SAFE_INTEGER;
            let thisBoardBottom = Number.MAX_SAFE_INTEGER;
            let thisBoardLeft = Number.MAX_SAFE_INTEGER;
            let thisBoardRight = Number.MIN_SAFE_INTEGER;
            offsetX = 0;
            for (let i = 0; i < cupboard.boards.length; i++) {
                const board = cupboard.boards[i];
                board.components.forEach((component) => {
                    component.shapes.forEach((shape) => {
                        //偏移
                        if(shape.length === 4) {
                            shape[0] += offsetX;
                            shape[2] += offsetX;
                            shape[1] += offsetY;
                            shape[3] += offsetY;
                        }else if(shape.length === 3) {
                            shape[0] += offsetX;
                            shape[1] += offsetY;
                        }else if(shape.length === 6) {
                            shape[0] += offsetX;
                            shape[1] += offsetY;
                            shape[5][0] += offsetX;
                            shape[5][1] += offsetY;
                            shape[5][2] += offsetX;
                            shape[5][3] += offsetY;
                            shape[5][4] += offsetX;
                            shape[5][5] += offsetY;
                        }
                        if(shape.length === 4) {
                            //横轴扩边
                            if(shape[0] < thisBoardLeft || shape[2] < thisBoardLeft) {
                                thisBoardLeft = Math.min(shape[0], shape[2]);
                            }
                            if(shape[0] > thisBoardRight || shape[2] > thisBoardRight) {
                                thisBoardRight = Math.max(shape[0], shape[2]);
                            }
                            //纵轴扩边
                            if(shape[1] < thisBoardBottom || shape[3] < thisBoardBottom) {
                                thisBoardBottom = Math.min(shape[1], shape[3]);
                            }
                            if(shape[1] > thisBoardTop || shape[3] > thisBoardTop) {
                                thisBoardTop = Math.max(shape[1], shape[3]);
                            }
                        }else if(shape.length === 6) {
                            //横轴扩边
                            if(shape[5][0] < thisBoardLeft || shape[5][2] < thisBoardLeft) {
                                thisBoardLeft = Math.min(shape[5][0], shape[5][2]);
                            }
                            if(shape[5][0] > thisBoardRight || shape[5][2] > thisBoardRight) {
                                thisBoardRight = Math.max(shape[5][0], shape[5][2]);
                            }
                            //纵轴扩边
                            if(shape[5][1] < thisBoardBottom || shape[5][3] < thisBoardBottom) {
                                thisBoardBottom = Math.min(shape[5][1], shape[5][3]);
                            }
                            if(shape[5][1] > thisBoardTop || shape[5][3] > thisBoardTop) {
                                thisBoardTop = Math.max(shape[5][1], shape[5][3]);
                            }

                        }
                    });
                });
                offsetX = thisBoardRight + 100;
                // console.log("offsetX, offsetY", offsetX, offsetY);
                
            }
            if(thisBoardLeft < leftLevel) {
                leftLevel = thisBoardLeft;
            }
            if(thisBoardRight > rightLevel) {
                rightLevel = thisBoardRight;
            }
            if(thisBoardBottom < bottomLevel) {
                bottomLevel = thisBoardBottom;
            }
            if(thisBoardTop > topLevel) {
                topLevel = thisBoardTop;
            }
            offsetY = topLevel + 100;
            
        });
        setComputedData(_data);
        console.log(_data);
        
        setLoading(false);
    }, [data])


    if (loading) {
        return (
            <div>加载中...</div>
        )
    }
    return (
        <div className="nest-checking">
            <Stage
                draggable
                x={stageX}
                y={stageY}
                scaleX={stageScale} scaleY={stageScale}
                width={innerWidth} height={innerHeight} style={{
                    width: innerWidth, height: innerHeight, background: "#000"
                }}
                
                onWheel={(e) => {

                    e.evt.preventDefault();
                    const stage = e.target.getStage();
                    const pointer = stage.getPointerPosition();
                    const oldScale = stage.scaleX();
                    const scaleBy = 1.2;

                    var mousePointTo = {
                        x: (pointer.x - stage.x()) / oldScale,
                        y: (pointer.y - stage.y()) / oldScale,
                    };
                    // how to scale? Zoom in? Or zoom out?
                    let direction = e.evt.deltaY > 0 ? -1 : 1;

                    // when we zoom on trackpad, e.evt.ctrlKey is true
                    // in that case lets revert direction
                    if (e.evt.ctrlKey) {
                        direction = -direction;
                    }

                    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                    // stage.scale({ x: newScale, y: newScale });

                    var newPos = {
                        x: pointer.x - mousePointTo.x * newScale,
                        y: pointer.y - mousePointTo.y * newScale,
                    };
                    setStageScale(newScale);
                    setStageX(newPos.x);
                    setStageY(newPos.y);
                }}
            >

                <Layer>
                    {/* <Shape
                        strokeWidth={lineWith}
                        stroke="#f00"
                        sceneFunc={(context, shape) => {
                            let offsetX = 0;    //同一个柜的板在同一个X轴， 多板的偏移通过此变量控制
                            let offsetY = 0;    //不同柜通过不同的Y level区分，由此变量控制Y轴偏移
                            let topLevel = Number.MIN_SAFE_INTEGER
                            let bottomLevel = Number.MAX_SAFE_INTEGER;
                            let leftLevel = Number.MAX_SAFE_INTEGER;
                            let rightLevel = Number.MIN_SAFE_INTEGER;
                            console.log("===========start=============");
                            const _data = JSON.parse(JSON.stringify(data));
                            _data.cupboards.forEach((cupboard, index) => {
                                console.log("cupboard", index);
                                
                                let thisBoardTop = Number.MIN_SAFE_INTEGER;
                                let thisBoardBottom = Number.MAX_SAFE_INTEGER;
                                let thisBoardLeft = Number.MAX_SAFE_INTEGER;
                                let thisBoardRight = Number.MIN_SAFE_INTEGER;
                                offsetX = 0;
                                for (let i = 0; i < cupboard.boards.length; i++) {
                                    const board = cupboard.boards[i];
                                    board.components.forEach((component) => {
                                        component.shapes.forEach((shape) => {
                                            //偏移
                                            if(shape.length === 4) {
                                                shape[0] += offsetX;
                                                shape[2] += offsetX;
                                                shape[1] += offsetY;
                                                shape[3] += offsetY;
                                            }else if(shape.length === 3) {
                                                shape[0] += offsetX;
                                                shape[1] += offsetY;
                                            }else if(shape.length === 6) {
                                                shape[0] += offsetX;
                                                shape[1] += offsetY;
                                                shape[5][0] += offsetX;
                                                shape[5][1] += offsetY;
                                                shape[5][2] += offsetX;
                                                shape[5][3] += offsetY;
                                                shape[5][4] += offsetX;
                                                shape[5][5] += offsetY;
                                            }
                                            if(shape.length === 4) {
                                                //横轴扩边
                                                if(shape[0] < thisBoardLeft || shape[2] < thisBoardLeft) {
                                                    thisBoardLeft = Math.min(shape[0], shape[2]);
                                                }
                                                if(shape[0] > thisBoardRight || shape[2] > thisBoardRight) {
                                                    thisBoardRight = Math.max(shape[0], shape[2]);
                                                }
                                                //纵轴扩边
                                                if(shape[1] < thisBoardBottom || shape[3] < thisBoardBottom) {
                                                    thisBoardBottom = Math.min(shape[1], shape[3]);
                                                }
                                                if(shape[1] > thisBoardTop || shape[3] > thisBoardTop) {
                                                    thisBoardTop = Math.max(shape[1], shape[3]);
                                                }
                                            }else if(shape.length === 6) {
                                                //横轴扩边
                                                if(shape[5][0] < thisBoardLeft || shape[5][2] < thisBoardLeft) {
                                                    thisBoardLeft = Math.min(shape[5][0], shape[5][2]);
                                                }
                                                if(shape[5][0] > thisBoardRight || shape[5][2] > thisBoardRight) {
                                                    thisBoardRight = Math.max(shape[5][0], shape[5][2]);
                                                }
                                                //纵轴扩边
                                                if(shape[5][1] < thisBoardBottom || shape[5][3] < thisBoardBottom) {
                                                    thisBoardBottom = Math.min(shape[5][1], shape[5][3]);
                                                }
                                                if(shape[5][1] > thisBoardTop || shape[5][3] > thisBoardTop) {
                                                    thisBoardTop = Math.max(shape[5][1], shape[5][3]);
                                                }

                                            }
                                        });
                                    });
                                    offsetX = thisBoardRight + 100;
                                    // console.log("offsetX, offsetY", offsetX, offsetY);
                                    
                                }
                                if(thisBoardLeft < leftLevel) {
                                    leftLevel = thisBoardLeft;
                                }
                                if(thisBoardRight > rightLevel) {
                                    rightLevel = thisBoardRight;
                                }
                                if(thisBoardBottom < bottomLevel) {
                                    bottomLevel = thisBoardBottom;
                                }
                                if(thisBoardTop > topLevel) {
                                    topLevel = thisBoardTop;
                                }
                                offsetY = topLevel + 100;
                                
                            });
                            const xScale = (window.innerWidth - 20) / (rightLevel - leftLevel);
                            const yScale = (window.innerHeight - 20) / (topLevel - bottomLevel);
                            console.log("left - right", leftLevel, rightLevel);
                            console.log("bottom-top", bottomLevel, topLevel);
                            console.log("xScale", xScale);
                            console.log("yScale", yScale);
                            const scale = 0.6;
                            // console.log("===========end=============");
                            const height = (window.innerHeight - 20) * scale;
                            console.log(height);
                            // const offset
                            offsetX = 0;
                            offsetY = 0;
                            _data.cupboards.forEach((cupboard) => {
                                cupboard.boards.forEach((board) => {
                                    board.components.forEach((component) => {
                                        component.shapes.forEach((shape, i) => {
                                            context.beginPath();
                                            const item = shape;
                                            if (item.length === 3) {
                                                // console.log("circle")
                                                // console.log(item[0] * scale + offsetX, height - item[1] * scale - offsetY, item[2] * scale, 0, 2 * Math.PI);
                                                
                                                context.arc(item[0] * scale + offsetX, height - item[1] * scale - offsetY, item[2] * scale, 0, 2 * Math.PI);
                                                context.stroke();
                                            } else if (item.length === 4) {
                                                // if (isNaN(item[0] * scale + offsetX)) {
                                                //     console.log("moveTo", [item[2], height - item[3]]);
                                                //     console.log("line", [item[0], height - item[1]])
                                                //     console.log("moveToTrans", [item[0] * scale + offsetX, height - item[1] * scale - offsetY]);
                                                //     console.log("lineToTrans", [item[2] * scale + offsetX, height - item[3] * scale - offsetY]);
                                                //     return ;
                                                // }
                                                console.log("moveTo", [item[0] * scale + offsetX, height - item[1] * scale - offsetY]);
                                                console.log("lineTo", [item[2] * scale + offsetX, height - item[3] * scale - offsetY]);
                                                context.moveTo(item[0] * scale + offsetX, height - item[1] * scale - offsetY);
                                                context.lineTo(item[2] * scale + offsetX, height - item[3] * scale - offsetY);
                                                context.stroke();
                                            } else {
                                                // console.log("arc")
                                                // console.log(
                                                //     item[0] * scale + offsetX, height - item[1] * scale - offsetY,
                                                //     item[2] * scale,
                                                //     (360 - item[3]) / 360 * 2 * Math.PI,
                                                //     (360 - item[4]) / 360 * 2 * Math.PI,
                                                    // true);
                                                
                                                // context.arc(
                                                //     item[0] * scale + offsetX, height - item[1] * scale - offsetY,
                                                //     item[2] * scale,
                                                //     (360 - item[3]) / 360 * 2 * Math.PI,
                                                //     (360 - item[4]) / 360 * 2 * Math.PI,
                                                //     true
                                                // );
                                            }
                                        })
                                    })
                                });
                            })
                            // console.log(_data);
                            
                            
                            // context.closePath();
                            context.fillStrokeShape(shape);
                            // data.forEach((item) => {
                            //     //@ts-ignore
                            //     if (item.length !== 3 && item.length !== 4 && item.length !== 5) {
                            //         console.log("problem");

                            //         console.log(item)
                            //     }
                            // })
                            // const maxY = data.reduce((prev, item) => {
                            //     if (item.length === 4) {
                            //         return Math.max(prev, Math.max(item[1], item[3]));
                            //     }
                            //     return prev;
                            // }, Number.MIN_SAFE_INTEGER);

                            // const minY = data.reduce((prev, item) => {
                            //     if (item.length === 4) {
                            //         return Math.min(prev, Math.min(item[1], item[3]));
                            //     }
                            //     return prev;
                            // }, Number.MAX_SAFE_INTEGER);

                            // const maxX = data.reduce((prev, item) => {
                            //     if (item.length === 4) {
                            //         return Math.max(prev, Math.max(item[0], item[2]));
                            //     }
                            //     return prev;
                            // }, Number.MIN_SAFE_INTEGER);

                            // const minX = data.reduce((prev, item) => {
                            //     if (item.length === 4) {
                            //         return Math.min(prev, Math.min(item[0], item[2]));
                            //     }
                            //     return prev;
                            // }, Number.MAX_SAFE_INTEGER);
                            // const cornerWidth = maxX - minX;
                            // const cornerHeight = maxY - minY;
                            // console.log("maxY", maxY);
                            // console.log("maxX", maxX);
                            // console.log("minY", minY);
                            // console.log("minY", minY);
                            // console.log("cornerWidth", cornerWidth);
                            // console.log("cornerHeight", cornerWidth);

                            // const scale = 1 / (cornerWidth > cornerHeight ? cornerWidth / (innerWidth - 40) : cornerHeight / ((innerHeight - 54 - 40)));
                            // const offsetX = (innerWidth - (maxX - minX) * scale) / 2 - minX * scale;
                            // const offsetY = ((innerHeight - 54) - (maxY - minY) * scale) / 2 - minY * scale;

                            // const height = (innerHeight - 54);
                            // context.strokeStyle = "#fff";
                            // console.log("scale", scale);
                            // console.log("offset x,y", offsetX, offsetY);


                            // for (let i = 0; i < data.length; i++) {
                            //     context.beginPath();
                            //     const item = data[i];
                            //     if (item.length === 3) {
                            //         console.log("circle")
                            //         context.arc(item[0] * scale + offsetX, height - item[1] * scale - offsetY, item[2] * scale, 0, 2 * Math.PI);
                            //     } else if (item.length === 4) {
                            //         if (isNaN(item[0] * scale + offsetX)) {
                            //             console.log("moveTo", [item[2], height - item[3]]);
                            //             console.log("line", [item[0], height - item[1]])
                            //             console.log("moveToTrans", [item[0] * scale + offsetX, height - item[1] * scale - offsetY]);
                            //             console.log("lineToTrans", [item[2] * scale + offsetX, height - item[3] * scale - offsetY]);


                            //             continue;
                            //         }
                            //         context.moveTo(item[0] * scale + offsetX, height - item[1] * scale - offsetY);
                            //         context.lineTo(item[2] * scale + offsetX, height - item[3] * scale - offsetY);
                            //     } else {
                            //         console.log("arc")
                            //         context.arc(
                            //             item[0] * scale + offsetX, height - item[1] * scale - offsetY,
                            //             item[2] * scale,
                            //             (360 - item[3]) / 360 * 2 * Math.PI,
                            //             (360 - item[4]) / 360 * 2 * Math.PI,
                            //             true
                            //         );
                            //     }
                            //     context.stroke();
                            // }
                            // context.closePath();
                            // context.fillStrokeShape(shape);
                        }}
                    /> */}
                    {
                        computedData.cupboards.map((cupboard) => {
                            return cupboard.boards.map((board) => {
                                return board.components.map((component) => {
                                    return component.shapes.map((shape) => {
                                        return shape.length === 4 ? (
                                            <Line 
                                                strokeWidth={lineWidth}
                                                stroke="#fff"
                                                points={shape}
                                            />
                                        ) : shape.length === 6 ? (
                                            <Arc 
                                                x={shape[0]}
                                                y={shape[1]}
                                                angle={shape[4] - shape[3]}
                                                rotation={shape[3]}
                                                innerRadius={shape[2]}
                                                outerRadius={shape[2]}
                                                stroke="#fff"
                                                strokeWidth={lineWidth}
                                            />

                                        ) : shape.length === 3 ? (
                                            <Circle 
                                                x={shape[0]}
                                                y={shape[1]}
                                                radius={shape[2]}
                                                stroke="#fff"
                                                strokeWidth={lineWidth}
                                            />
                                        ): undefined
                                    })
                                })
                            })
                        })
                    }
                </Layer>
            </Stage>
        </div>
    )
}