import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { boardRotate90, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner9 } from "../../coners/corner9";
import { rectBoard } from "../general-board";

export const _09qiandangneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number
): [Path[], Path[]] => {
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL = corner9(dx, -dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR = corner9(-dx, -dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner9(-dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL = corner9(dx, dy, startX, startY, true, cornerTLSize);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;

    const paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    if (width >= 500) {
        // paths.push(
        //     {
        //         type: Circle,
        //         params: [
        //             startX + dx * width / 2, startY + dy * height - dy * 31.75,
        //             2.61,
        //         ]
        //     },
        //     {
        //         type: Circle,
        //         params: [
        //             startX + dx * width / 2, startY + dy * height - dy * 31.75 - dy * 31.5,
        //             2.61,
        //         ]
        //     })
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]

}