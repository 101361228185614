import { DrawedMeta } from "../../store"
import { BottomWidget } from "./widget-bottom"
import { Left0Widget } from "./widget-left0"
import { Left1Widget } from "./widget-left1"
import { RightWidget } from "./widget-right"
import { Right1Widget } from "./widget-right1"
import { Top0Widget } from "./widget-top0"

export const Widgets = (props: {
    mode: "3d" | "2d";
    onSave: (order: any, drawedList: DrawedMeta[], auto?: boolean) => Promise<boolean>;
    onModeChange: (mode: "3d" | "2d") => void;
    onOrderChange: (order: any) => void;
}) => {

    return (
        <>
        <Left0Widget 
            mode={props.mode}
            onChangeTo2D={() => {
                props.onModeChange("2d");
            }}
            onChangeTo3D={() => {
                props.onModeChange("3d");
            }}
        />
        
        <BottomWidget
            mode={props.mode}
            onChangeTo2D={() => {
                props.onModeChange("2d");
            }}
            onChangeTo3D={() => {
                props.onModeChange("3d");
            }}

        />
        {
            props.mode === '2d' && (
                <>
                    <Top0Widget 
                        onSave={props.onSave} 
                        onOrderChange={props.onOrderChange}
                    />
                    <Left1Widget />
                    <RightWidget />
                    <Right1Widget />
                </>
            )
        }
        {
            props.mode === '3d' && (
                <>
                </>

            )
        }
        </>
    )

}