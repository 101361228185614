import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner10 } from "../../coners/corner10";
import { rectBoard } from "../general-board";

export const _01AbeineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner10(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    cornerBL = pathMirrorY(cornerBL, startX);
    cornerBL = pathRotate(cornerBL, -180);
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    // cornerBL = cornerBL.filter((item) => item.type !== Circle);
    // //右下角
    // const cornerBRSize = {width: 0, height: 0};
    // let cornerBR = corner1(-dx, -dy, startX, startY, true, cornerBRSize);
    // let cornerBRW: number = cornerBRSize.width;
    // let cornerBRH: number = cornerBRSize.height;
    // cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    // cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner10(dx, dy, startX, startY, true, cornerBRSize);
    cornerBR = pathRotate(cornerBR, 90)
    cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathRotate(cornerBR, -90);
    cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, dx * width);
    // const cornerBRCircles: Path[] = cornerBR.filter((item) => item.type === Circle);
    // cornerBR = cornerBR.filter((item) => item.type !== Circle);
    // cornerBR.push(...cornerBRCircles.slice(0, 2));
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;

    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner1(-dx, dy, startX, startY,true,  cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    // cornerTR = cornerTR.filter((item) => item.type !== Circle);

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner1(dx, dy, startX, startY,true,  cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    
    const boardConfig: any = {
        middleware:  {
            top: false,
            left: (height > 500) && "far",
            right: (height > 500) && "far",
            bottom: (width > 500 && "far"),
        }
    }
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    // cornerTL = cornerTL.filter((item) => item.type !== Circle);
    //board
    // const paths: Path[] = [{
    //     type: Line,
    //     params: [
    //         startX + dx * cornerBLW, startY,
    //         (2 * startX + dx * width) / 2 - dx * 45.02 / 2, startY,

    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2, startY,
    //         startX + dx * width - dx * cornerBRW, startY
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         startX + dx * width , startY + dy * cornerBRH,
    //         startX + dx * width, startY + dy * height - dy * cornerTRH,
    //     ]
    // },  {
    //     type: Line,
    //     params: [
    //         startX + dx * width - dx * cornerTRW, startY + dy * height,
    //         startX + dx * cornerTLW, startY + dy * height,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         startX, startY + dy * height - dy * cornerTLH,
    //         startX, startY + dy * cornerBLH    
    //     ]
    // }];
    // const corners: Path[] = [{
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2, startY,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2, startY + dy * 12,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2, startY + dy * 12,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95, startY + dy * 12,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95, startY + dy * 12,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95, startY + dy * 12 + dy * 2,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95, startY + dy * 12 + dy * 2,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62, startY + dy * 12 + dy * 2,

    //     ]
    // }, {
    //     type: Circle,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 / 2, startY + dy * 19.39,
    //         3.61
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62, startY + dy * 12 + dy * 2,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62, startY + dy * 12,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62, startY + dy * 12,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88, startY + dy * 12,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88, startY + dy * 12,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88, startY + dy * 12 + dy * 2,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88, startY + dy * 12 + dy * 2,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62, startY + dy * 12 + dy * 2,
    //     ]
    // },{
    //     type: Circle,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62 / 2, startY + dy * 19.39,
    //         3.61
    //     ]
    // },  {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62, startY + dy * 12 + dy * 2,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62, startY + dy * 12,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62, startY + dy * 12,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62 - dx * 4.95, startY + dy * 12,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62 - 4.95, startY + dy * 12,
    //         (2 * startX + dx * width) / 2 + dx * 45.02 / 2 - dx * 4.95 - dx * 3.62 - dx * 27.88 - dx * 3.62 - 4.95, startY
    //     ]
    // }]
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}