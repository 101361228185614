import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner3 } from "../../coners/corner3";
import { corner4 } from "../../coners/corner4";
import { rectBoard } from "../general-board";

// export const 
export const _10ceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
): [Path[], Path[]] => {

    const {
        上免拉手,
        上免拉手X,
        上免拉手Y,
        中免拉手1,
        中免拉手1X,
        中免拉手1Y,
        中免拉手1Z,
        中免拉手2,
        中免拉手2X,
        中免拉手2Y,
        中免拉手2Z,
        中免拉手3,
        中免拉手3X,
        中免拉手3Y,
        中免拉手3Z,
    } = extraConfig;
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    const cornerBL = corner3(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR = corner4(dx, dy, startX, startY, true, cornerBRSize);
    // cornerBR = pathMirrorY(cornerBR, startX);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    // cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);


    // //右上角
    // const cornerTRSize = {width: 0, height: 0};
    // let cornerTR = corner1(dx, dy, startX, startY, true, cornerTRSize);
    // // let cornerTR = corner4(dx, dy, startX, startY, true, cornerTRSize);
    // let cornerTRW: number = cornerTRSize.width;
    // let cornerTRH: number = cornerTRSize.height;
    // cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height)

    // //右上角
    // const cornerTLSize = {width: 0, height: 0};
    // let cornerTL = corner1(-dx, dy, startX, startY, true, cornerTLSize);
    // let cornerTLW: number = cornerTLSize.width;
    // let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    // cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    //右上角
    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if (!上免拉手) {
        cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        if (qiandanghengfang) {
            cornerTL = pathMoveY(cornerTL, -DirectY.UP, height);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTL = pathRotate2(cornerTL, startX, startY, 90);
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            const widthTemp = cornerTLSize.width;
            cornerTLW = cornerTLSize.height;
            cornerTLH = widthTemp;
        }
    } else {
        cornerTL.push({
            type: "Line",
            params: [
                startX, startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height,
            ]
        });
        cornerTLH = 上免拉手Y + 10.5;
        cornerTLW = 上免拉手X + 10.5;
    }

    const boardConfig: any = {
        middleware: {
            top: false,
            left: height > 500 && "far",
            right: false,
            bottom: width > 500 && "close",
        }
    }
    let paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    const mianlashouTemp = [
        {
            中免拉手: 中免拉手1,
            中免拉手X: 中免拉手1X,
            中免拉手Y: 中免拉手1Y,
            中免拉手Z: 中免拉手1Z
        },
        {
            中免拉手: 中免拉手2,
            中免拉手X: 中免拉手2X,
            中免拉手Y: 中免拉手2Y,
            中免拉手Z: 中免拉手2Z
        },
        {
            中免拉手: 中免拉手3,
            中免拉手X: 中免拉手3X,
            中免拉手Y: 中免拉手3Y,
            中免拉手Z: 中免拉手3Z
        },
    ].filter((item) => item.中免拉手).sort((a, b) => {
        return a.中免拉手Z > b.中免拉手Z ? 1 : -1;
    });
    if(mianlashouTemp.length > 0) {
        paths = paths.filter((_, index) => index !== 3);
        let lastZFlag = 0;

        for (let i = 0; i < mianlashouTemp.length; i++) {
            const temp = mianlashouTemp[i];
            const { 中免拉手, 中免拉手X, 中免拉手Z, 中免拉手Y } = temp;
            let yTemp = i === 0 ? cornerBLH :  lastZFlag;
            paths.push({
                type: Line,
                params: [
                    startX, startY + dy * yTemp,
                    startX, startY + dy * (中免拉手Z - 7.5)
                ]
            }, {
                type: Line,
                params: [
                    startX, startY + dy * (中免拉手Z - 7.5),
                    startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5)
                ]
            }, {                                                                                                                                                     
                type: Line,
                params: [
                    startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                    startX,  startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
                ]
            }, {
                type: Line,
                params: [
                    startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5),
                    startX + dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
                ]
            });
            lastZFlag = (中免拉手Z - 7.5) + 中免拉手Y;
        }
        paths.push({
            type: "Line",
            params: [
                startX, startY + dy * lastZFlag,
                startX, startY + dy * height - dy * cornerTLH
            ]
        })
    }

    if (lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }

    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];
}