import { Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner19 } from "../../coners/corner19";
import { corner7 } from "../../coners/corner7";
import { corner9 } from "../../coners/corner9";
import { rectBoard } from "../general-board";

export const _05lineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    extraConfig: any
): [Path[], Path[]] => {
    const {
        上免拉手,
        上免拉手X,
        上免拉手Y,
        中免拉手,
        中免拉手X,
        中免拉手Y,
        中免拉手Z
    } = extraConfig;

    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL = corner9(dx, -dy, startX, startY, true, cornerBLSize);
    cornerBL = pathMirrorY(cornerBL, startX);
    cornerBL = pathRotate2(cornerBL, startX, startY, -90);
    let cornerBLW: number = cornerBLSize.height;
    let cornerBLH: number = cornerBLSize.width;
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.width - cornerBLSize.width);
    cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR = corner9(-dx, -dy, startX, startY, true, cornerBRSize);
    cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathRotate2(cornerBR, startX, startY, 90);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRSize.height);
    //  cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.width);

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner7(-dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if (!上免拉手) {
        cornerTL = corner19(dx, dy, startX, startY, true, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    } else {
        cornerTL.push({
            type: "Line",
            params: [
                startX, startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height,
            ]
        });
        cornerTLH = 上免拉手Y + 10.5;
        cornerTLW = 上免拉手X + 10.5;
    }


    let paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    if (中免拉手) {
        paths = paths.filter((_, index) => index !== 3);
        paths.push({
            type: Line,
            params: [
                startX, startY + dy * cornerBLH,
                startX, startY + dy * (中免拉手Z - 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z - 7.5),
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5),
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                startX, startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                startX, startY + dy * height - dy * cornerTLH
            ]
        })
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}