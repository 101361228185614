import { Outlet } from "react-router-dom"
import { Header } from "./Header"

export const MainLayout = () => {

    return (
        <>
        
            <Header />
            <div style={{ paddingTop: "calc(54px + 24px)", background: "#f1f1f1", height: "100vh", overflowY: 'scroll' }}>
                <Outlet />
            </div>
        </>
    )

}