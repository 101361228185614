import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner11 } from "../../coners/corner11";
import { qiandangneiBoard } from "../地柜/01-qiandangnei";
import { rectBoard } from "../general-board";

export const _05beineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nobeiban?: boolean,
    extraConfig?: any
): [Path[], Path[]] => {
    if (nobeiban) {
        //console.log("qiandangnei");
        return qiandangneiBoard(dx, dy, startX, startY, width, height);
    }
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL = corner11(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR = corner11(dx, dy, startX, startY, true, cornerBRSize);
    cornerBR = pathMirrorY(cornerBR, startX);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    cornerTR = cornerTR.filter((item) => item.type !== Circle);

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    cornerTL = cornerTL.filter((item) => item.type !== Circle);


    const boardConfig: any = {
        middleware: extraConfig?.noMiddleware ? {} : {
            top: false,
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: width > 500 && "close",
        }
    }
    //board
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);


    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}