import { AbstractMeta, m2px, mm2RealValue, px2DisplayValue } from "./base";

export const defaultFloorHeight_m = 2.8;
export const defaultFloorThickness_m = 0.12;
export const defaultWallThickness_m = 0.24;

export const defaultFloorHeight = 2.8 * m2px;
export const defaultFloorThickness = 0.12 * m2px;
export const defaultWallThickness = 0.24 * m2px;


export const space = new AbstractMeta<{
    floorHeight: number,
    wallThickness: number,
    floorThickness: number
}>(
    "房间属性",
    [
        {
            key: "roomName",
            label: "房间名称",
            placeholder: "请输入房间名称",
            defaultValue: "厨房",
            valueType: "string",
            rule: [
                {
                    key: "required",
                    value: true
                }
            ]
        },
        {
            key: "floorThickness",
            label: "地板厚度",
            placeholder: "请输入地板厚度",
            defaultValue: defaultFloorThickness,
            toDisplayValue: px2DisplayValue,
            toRealValue: mm2RealValue,
            valueType: "integer",
            suffix: "mm",
            rule: [
                {
                    key: "min",
                    value: 1,
                }
            ]
        }, {
            key: "wallThickness",
            label: "墙体厚度",
            placeholder: "请输入墙体厚度",
            defaultValue: defaultWallThickness,
            toDisplayValue: px2DisplayValue,
            toRealValue: mm2RealValue,
            valueType: "integer",
            suffix: "mm",
            rule: [
                {
                    key: "min",
                    value: 1,
                }
            ]
        }, {
            key: "floorHeight",
            label: "墙体高度",
            placeholder: "请输入墙体高度",
            defaultValue: defaultFloorHeight,
            toDisplayValue: px2DisplayValue,
            toRealValue: mm2RealValue,
            valueType: "integer",
            suffix: "mm",
            rule: [
                {
                    key: "min",
                    value: 1,
                }
            ]
        }
    ]);