import { DirectX, DirectY } from "../../../constants/enum";
import { gecengneiBoard } from "../地柜/01-gecengnei";
import { _05beineiBoard } from "../转角柜/05-beinei";

export const _02gecengneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
 ): [Path[], Path[]]  => {
    return gecengneiBoard(dx, dy, startX, startY, width, height);
 }