import { CiOutlined, CloseCircleFilled, CloseOutlined, CloseSquareOutlined, EllipsisOutlined, FileFilled, FileOutlined, HomeFilled, HomeOutlined, PlusOutlined, ReloadOutlined, SettingFilled } from "@ant-design/icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import clsx from 'clsx';
import { Dropdown, MenuProps, message } from "antd";
import _ from 'lodash';

export interface TabItemData {
    key: string;
    path: string;
    icon?: React.ReactNode;
    title?: string;
    closable?: boolean;
}

export const SettingTab = {
    key: "settings",
    path: "/settings",
    icon: <SettingFilled />,
    title: "设置"
}

const homeTab: TabItemData = {
    key: "home",
    path: "/",
    icon: <HomeFilled />,
    closable: false
}

let memoTabs: TabItemData[] = [
    homeTab
];

let tabPathHistoryStack: string[] = [window.location.pathname];

interface Props {

}

export default forwardRef(function Tabs(props: Props, ref: React.ForwardedRef<{
    push: (tab: TabItemData) => void;
    closeAll: () => void;
}>) {
    useImperativeHandle(ref, () => {
        return {
            push: (tab: TabItemData) => {
                const exists = tabs.find((t) => t.key === tab.key);
                if (!exists) {
                    tabs.push(tab);
                    setTabs([...tabs]);
                }
                onTabActive(tab);
            },

            close: (config?: { path?: string }) => {
                if(config?.path) {
                    closeTabByPath(config.path);
                }
            },
            closeAll: onCloseAllTabs
        }
    });

    const location = useLocation();
    const navigate = useNavigate();

    const [tabs, setTabs] = useState<TabItemData[]>(memoTabs);

    const onCloseAllTabs = () => {
        memoTabs = [homeTab];
        navigate("/");
    }

    const onTabActive = (tab: TabItemData) => {
        //记录到历史记录
        if (tabPathHistoryStack[tabPathHistoryStack.length - 1] !== tab.path) {
            if (tabPathHistoryStack.includes(tab.path)) {
                tabPathHistoryStack = tabPathHistoryStack.filter((path) => path !== tab.path);
            }
            tabPathHistoryStack.push(tab.path);
        }
        navigate(tab.path);
    }

    const onTabClose = (tab: TabItemData) => {
        memoTabs = tabs.filter((t) => tab.path !== t.path);
        setTabs(memoTabs);
        tabPathHistoryStack = tabPathHistoryStack.filter((t) => t !== tab.path);
        navigate(tabPathHistoryStack[tabPathHistoryStack.length - 1]);
    }

    const closeTabByPath = (path: string) => {
        memoTabs = tabs.filter((t) => path !== t.path);
        setTabs(memoTabs);
        tabPathHistoryStack = tabPathHistoryStack.filter((t) => t !== path);
        navigate(tabPathHistoryStack[tabPathHistoryStack.length - 1]);
    }


    const items: MenuProps['items'] = [
        {
            key: 'close-all',
            icon: <ReloadOutlined />,
            label: "刷新当前",
            onClick: () => {

            },
        },
        {
            key: 'home',
            icon: <HomeOutlined />,
            label: "回到首页",
            onClick: () => {
                navigate("/");
            },
            style: {
                color: "#fff"
            }
        },
        {
            key: 'close-all',
            icon: <CloseSquareOutlined />,
            label: "全部关闭",
            onClick: onCloseAllTabs,
            danger: true
        }
    ];


    return (
        <Wrapper className="tabs">
            {
                tabs.map((tab, index) => {
                    return (
                        <TabItem
                            {...tab}
                            index={index}
                            currentPath={location.pathname}
                            onClick={onTabActive}
                            onClose={onTabClose}
                        />
                    )
                })
            }
            <TabItem
                index={-1}
                currentPath={location.pathname}
                path="/new"
                key="new"
                icon={(location.pathname === '/new' ? (<FileFilled />) : (<PlusOutlined />))}
                title={(location.pathname === '/new' ? "新建" : undefined)}
                closable={(location.pathname === '/new')}
                onClose={() => {
                    navigate(tabPathHistoryStack[tabPathHistoryStack.length - 1]);
                }}
                onClick={() => {
                    navigate('/new')
                }}
            />
            {/* <Dropdown menu={{ items: items }} trigger={["click"]}>
                <TabItem
                    key="more"
                    path="$"
                    icon={(
                        <EllipsisOutlined />
                    )}
                    closable={false}
                />
            </Dropdown> */}
        </Wrapper>
    )
});

const Wrapper = styled.div`
    flex-shrink: 0;
    flex-grow: 1;
    font-size: 0;
    display: flex;
`;
interface TabItemProps extends TabItemData {
    index?: number;
    currentPath?: string;
    onClick?: (tab: TabItemData) => void;
    onClose?: (tab: TabItemData) => void;
}
const TabItem = (tab: TabItemProps) => {
    return (
        <Item
            title={tab.title}
            key={tab.key}
            className={clsx(
                'tab-item',
                {
                    'active': tab.currentPath === tab.path
                },
            )}
            onClick={() => {
                tab.onClick && tab.onClick(tab);
            }}
        >

            {
                tab.icon && (
                    <i className="icon">{tab.icon}</i>
                )
            }
            {
                tab.title && (
                    <span className="text">{tab.title}</span>
                )
            }
            {
                (tab.closable !== false) && (
                    <CloseCircleFilled
                        className="icon-close"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            tab.onClose && tab.onClose(tab);
                        }}
                    />
                )
            }
        </Item>
    )
}

const Item = styled.div`
    -webkit-app-region: no-drag;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 0 10px;
    color: #888;
    border-radius: 2px;
    margin-right: 2px;
    user-select: none;
    .icon {
        padding: 0 3px;
    }
    .text {
        padding: 0 3px;
        display: inline-block;
        max-width: 120px;
        /* height: 36px; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .icon-close {
        color: #444;
        padding: 0 3px;
        /* margin-left: 10px; */
        position: relative;
        right: -3px;
        &:hover {
            color: #666;
        }
    }
    &:not(.active):hover {
        background-color: #222;
        color: #ddd;
        .icon-close {
            color: #666;
            &:hover {
                color: #777;
            }
        }
    }
    &.active{
        background-color: #333;
        color: #fff;
        .icon-close {
            color: #888;
            &:hover {
                color: #999;
            }
        }
    }
`;