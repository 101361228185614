import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { menneiBoard } from "../地柜/01-mennei";
import { _05beineiBoard } from "./05-beinei";
import { _05beiwaiBoard } from "./05-beiwai";
import { _05ceneiBoardLeft } from "./05-cenei-left";
import { _05ceneiBoardRight } from "./05-cenei-right";
import { _05cewaiBoardLeft } from "./05-cewai-left";
import { _05cewaiBoardRight } from "./05-cewai-right";
import { _05dineiBoard } from "./05-dinei";
import { _05lineiBoard } from "./05-linei";

export const _08menneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    nolashou: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
): [Path[], Path[]] => {

    return menneiBoard(dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, nolashou, menminglashou, menlashouX, menlashouY, menlashoubianju, menlashoufangxiang, doorSide, gxinglashou, gxinglashouType, gxinglashouGaodu);
}