import { Circle, Line } from "../../constants"
import { DirectX, DirectY } from "../../constants/enum"
import { degreeTransformForEach } from "../../util";
import { pathMirrorY, pathMoveX, pathMoveY, pathReverse, pathRotate } from "../../util/graph";
import { corner4 } from "./corner4";
// import { corner1 } from "./corner1";

export const corner11 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner4(dx, dy, startX, startY, true, size).filter((path) => path.type !== Circle);
    paths = pathMirrorY(paths, dx * startX);
    paths = pathMoveX(paths, DirectX.RIGHT, dx * size.width - dx * 0.004877);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = 70.094877 - 0.009754;
            sizeRes.height = 70.085123;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}