import { DirectX, DirectY } from "../../../constants/enum";
import { menwaiBoard } from "../地柜/01-menwai";

export const _06menwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean,
    menbashou?: boolean,
    nolashou?: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
 ): [Path[], Path[]]  => {
    return menwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner, menbashou, nolashou, menminglashou, menlashouX, menlashouY, menlashoubianju, menlashoufangxiang, doorSide, gxinglashou, gxinglashouType, gxinglashouGaodu);
}