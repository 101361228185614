import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum"
import { conbinePaths } from "../../../util/graph";
import { diwaiBoard } from "./01-diwai";

export const _01AdiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = diwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner, {
        noMiddleware: true
    });
    
    const paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    return [
        paths.slice(0, dineiBoardPaths.length),
        paths.slice(dineiBoardPaths.length, paths.length).concat({
            type: Line,
            params: [
                dx * (2 * startX + width) / 2 + dx * 29.58 / 2 + dx * 1.92, startY + dy * height,
                dx * (2 * startX + width) / 2 + dx * 29.58 / 2 + dy * 1.92, startY + dy * height - dy * 1.995,
            ]
        }, {
            type: Line,
            params: [
                dx * (2 * startX + width) / 2 + dx * 29.58 / 2 + dx * 1.92, startY + dy * height - dy * 1.995,
                dx * (2 * startX + width) / 2 + dx * 29.58 / 2, startY + dy * height - dy * 1.995,
            ]
        }, {
            type: Line,
            params: [
                dx * (2 * startX + width) / 2 + dx * 29.58 / 2, startY + dy * height - dy * 1.995,
                dx * (2 * startX + width) / 2 + dx * 29.58 / 2, startY + dy * height,
            ]
        }, {
            type: Line,
            params: [
                dx * (2 * startX + width) / 2 - dx * 29.58 / 2 - dx * 1.92, startY + dy * height,
                dx * (2 * startX + width) / 2 - dx * 29.58 / 2 - dx * 1.92, startY + dy * height - dy * 1.995,
            ]
        }, {
            type: Line,
            params: [
                dx * (2 * startX + width) / 2 - dx * 29.58 / 2 - dx * 1.92, startY + dy * height - dy * 1.995,
                dx * (2 * startX + width) / 2 - dx * 29.58 / 2, startY + dy * height - dy * 1.995,
            ]
        }, {
            type: Line,
            params: [
                dx * (2 * startX + width) / 2 - dx * 29.58 / 2, startY + dy * height - dy * 1.995,
                dx * (2 * startX + width) / 2 - dx * 29.58 / 2, startY + dy * height
            ]
        }, {
            type: Circle,
            params: [
                dx * (2 * startX + width) / 2, startY + dy * height - dy * 15.385,
                3.11
            ]
        }, {
            type: Circle,
            params: [
                dx * (2 * startX + width) / 2 - dx * 15.75, startY + dy * height - dy * 15.385,
                2.61
            ]
        }, {
            type: Circle,
            params: [
                dx * (2 * startX + width) / 2 + dx * 15.75, startY + dy * height - dy * 15.385,
                2.61
            ]
        }),
    ]
 
} 