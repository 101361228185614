import { OrderCreateModal } from "../../$v1/components/OrderCreateModal";
import WindowFrame from "../../components/WindowFrame";
import { v1 as uuid } from 'uuid';
import dayjs from "dayjs";
import { Button, ConfigProvider, Divider, Form, Input, InputNumber, message, Radio, theme } from "antd";
import { useEffect, useRef, useState } from "react";
import { locale } from "../../$v1/utils/locale";
import { Materials } from "../../$v1/components/OrderCreateModal/components/Materials";
import { BoardTypes } from "../../$v1/components/OrderCreateModal/components/BoardTypes";
import { PageHeader, PageContainer } from '@ant-design/pro-layout';
import { request } from "../../utils2/request";
import { apis } from "../../utils2/apis";
import { useNavigate } from "react-router-dom";


export default function New() {
    const [submitting, setSubmitting] = useState(false);
    const [materialQuantity, setMaterialQuantity] = useState(2);
    const [materials, setMaterials] = useState([
        {id: 1, name: locale["不锈钢1"], hide: false},
        {id: 2, name: locale["不锈钢2"], hide: false},
        {id: 3, name: locale["不锈钢3"], hide: true},
        {id: 4, name: locale["不锈钢4"], hide: true},
    ]);
    const [boardTypes, setBoardTypes] = useState([
        {id: 1, key: "guinei", name: locale["柜内"], selected: true, materialId: 1},
        {id: 2, key: "guiwai", name: locale["柜外"], selected: true, materialId: 1},
        {id: 5, key: "beinei", name: locale["背内"], selected: true, materialId: 1},
        {id: 6, key: "beiwai", name: locale["背外"], selected: true, materialId: 1},
        {id: 3, key: "mennei", name: locale["门内"], selected: true, materialId: 1},
        {id: 4, key: "menwai", name: locale["门外"], selected: true, materialId: 2},
    ]);

    const formRef = useRef<any>();
    const navigate = useNavigate();

    useEffect(() => {
        //判断本地是否有配置缓存
        const localConfig = localStorage.getItem("local-config");
        if(!localConfig) {
            return ;
        }
        let config = JSON.parse(localConfig);
        setMaterialQuantity(config.materialQuantity);
        setMaterials(config.materials);
        setBoardTypes(config.boardTypes);
    }, []);

    useEffect(() => {
        if(!materialQuantity) {
            return ;
        }
        for (let i = 0; i < materialQuantity; i++) {
            materials[i].hide = false;
        }
        for (let i = materialQuantity; i < materials.length; i++) {
            materials[i].hide = true;
        }
        const newMaterials = [...materials];
        formRef.current?.setFieldsValue({
            materials: newMaterials,
        });
        setMaterials(newMaterials);
    }, [materialQuantity]);


    const restore = () => {
        const config = {
            materialQuantity,
            materials,
            boardTypes,
        }
        // localStorage.setItem("local-config", JSON.stringify(config));
    }

    const onFinish = (data: any) => {
        //console.log(data);
        const order: any = {};
        //生成订单信息
        const now = new Date();
        order.address = data.address;
        order.createDate = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" +  now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
        //生成第一个橱柜模板
        order.struct = data.struct;
        order.materials = materials.filter((item) => item.hide === false);
        const availableMaterials = order.materials.filter((item: any) => item.hide === false);
        const availableMaterialIds = availableMaterials.map((item: any) => item.id);
        order.boardTypes = boardTypes.filter((item) => item.selected)
        const selectedBoardTypes = order.boardTypes.filter((item: any) => item.selected)
        //console.log(availableMaterialIds);
        //console.log(order.boardTypes);
        const orderUsedMaterialIds = new Set();
        for (let i = 0; i < selectedBoardTypes.length; i++) {
            const boardType = selectedBoardTypes[i];
            orderUsedMaterialIds.add(boardType.materialId);
            if(!availableMaterialIds.includes(boardType.materialId)) {
                message.warning(boardType.name + "没勾选材料, 请勾选");
                return ;
            }
        }
        let notUsedMaterial = [];
        for (let i = 0; i < availableMaterials.length; i++) {
            const material = availableMaterials[i];
            if(!orderUsedMaterialIds.has(material.id)) {
                notUsedMaterial.push(material.name);
            }
        }
        let _continue = false
        if(notUsedMaterial.length > 0) {
            message.warning(notUsedMaterial.join("、") + "没有使用");
            return ;
        }else {
            _continue = true;
        }
        if(!_continue) {
            return ;
        }
        setSubmitting(true);
        if(data.struct === "beibaoce") {
            //背包侧模板
            order.boards = [{
                id: 1,
                key: "digui-beibaoce",
                name: locale["地柜"],
                struct: "beibaoce",
                long: 800,
                deep: 550,
                height: 680,
                spacing: 10,
                jiaolianbianju: 100,
                lianjiekong: true,
                lianjiekongbianju: 40,
                guituiX: 60,
                guituiY: 50,
                doorNumber: 2,
                doorSide: "left",
                menwaibanjiao: true,
                nowaibanjiao: false,
                geceng: true,
                gecengX: 60,
                gecengY: 340,
                mengao: 640,
                waibanjiaoType: "youhujiao",
            }];
        }else if(data.struct === "cebaobei"){
            //侧包背模板
            order.boards = [{
                id: 1,
                key: "digui-cebaobei",
                name: locale["地柜"],
                struct: "cebaobei",
                long: 800,
                deep: 550,
                height: 680,
                spacing: 10,
                jiaolianbianju: 100,
                lianjiekong: true,
                lianjiekongbianju: 40,
                guituiX: 60,
                guituiY: 50,
                doorNumber: 2,
                doorSide: "left",
                menwaibanjiao: true,
                nowaibanjiao: false,
                geceng: true,
                gecengX: 60,
                gecengY: 340,
                mengao: 640,
                waibanjiaoType: "youhujiao",
            }];
        }
        //存储配置信息
        restore();
        //订单创建成功的回调
        //console.log(order);
        // props.onFinish(order);
        console.log(order);
        request.post(apis.order + "/create", {
            address: order.address,
            boards: order.boards,
            boardTypes: order.boardTypes.filter((bt) => bt.selected),
            materials: order.materials.filter((m) => !m.hide),
            struct: order.struct,
        }).then((res) => {
            if(res.code === 1) {
                console.log(res.data);
                navigate(`/v1/${res.data.id}`, {
                    replace: true
                });
                message.success("订单创建成功")
                
            }
        })
        return ;
    }


    const frameRef = useRef<any>();
    return (
        <ConfigProvider theme={{
            algorithm: [theme.defaultAlgorithm]
        }}>
        {/* <WindowFrame ref={frameRef}> */}
            <PageContainer
                header={{
                    title: "创建订单"
                }}
                footer={[(
                    <Button type="primary" loading={submitting} disabled={submitting} onClick={() => {
                        formRef.current?.submit();
                    }}>
                        确认
                    </Button>
                )]}
            >

                <div style={{margin: '0 auto', width: 740, paddingBottom: 60}}>
                    
                    <Form labelCol={{span: 5}}
                            ref={formRef}
                            onFinish={onFinish}
                            initialValues={{
                                materialQuantity,
                                // materialType: 3,
                                materials: materials,
                                boardTypes: boardTypes,
                                // boardMaterial: boardMaterial,
                                struct: "cebaobei",
                            }}
                            onValuesChange={(item) => {
                                // //console.log(item);
                                if("materialQuantity" in item) {
                                    setMaterialQuantity(item.materialQuantity);
                                }
                                if("materials" in item) {
                                    setMaterials(item.materials);
                                }
                                if("boardTypes" in item) {
                                    setBoardTypes(item.boardTypes);
                                }
                            }}
                        >
                            <Form.Item
                                label={locale["收货地址"]}
                                name="address"
                                rules={[{required: true, message: locale["请输入收货地址"]}]}
                            >
                                <Input placeholder={locale["请输入收货地址"]} autoFocus autoComplete="off"/>
                            </Form.Item>
                            {/* <Divider>{locale['板材设置']}</Divider> */}
                            <Form.Item
                                name="materialQuantity"
                                label={locale['板材种数']}
                                extra={locale["最少1种, 最多4种"]}
                                rules={[{required: true, message: locale["请输入材料种数"]}]}
                            >
                                <InputNumber min={1} max={4}/>
                            </Form.Item>
                            <Form.Item
                                name={"materials"}
                                label={locale["材料名字"]}
                                rules={[{required: true, message: locale["请输入材料名字"]}]}
                            >
                                <Materials />
                            </Form.Item> 
                            {/* <Divider>{locale['板材设置']}</Divider> */}
                            <Form.Item
                                name={"boardTypes"}
                                label={locale["板件材料"]}
                                rules={[{required: true, message: "请配置板件材料"}]}
                            >
                                <BoardTypes materials={materials}/>
                            </Form.Item> 
                            {/* <Divider>{locale['结构设置']}</Divider> */}
                            <Form.Item
                                label={locale["结构"]}
                                name="struct"
                                rules={[{required: true, message: "请选择板件结构"}]}
                            >
                                <Radio.Group>
                                    <Radio value={"cebaobei"}>{locale['侧包背']}</Radio>
                                    <Radio value={"beibaoce"}>{locale['背包侧']}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                            </div>
            </PageContainer>
        {/* </WindowFrame> */}
        </ConfigProvider>
    )

}   