import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { cornerMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner10 } from "../../coners/corner10";
import { corner11 } from "../../coners/corner11";
import { rectBoard } from "../general-board";

export const _02AbeineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    diaokongbianju: number,
    extraConfig: any
 ): [Path[], Path[]]  => {
    const { 吊码 } = extraConfig;
    if(吊码) {
        diaokongbianju = -1;
    }
    //左下角
    // const cornerBLSize = {width: 0, height: 0};
    // let cornerBL = corner11(dx, dy, startX, startY, true, cornerBLSize);
    // let cornerBLW: number = cornerBLSize.width;
    // let cornerBLH: number = cornerBLSize.height;
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner10(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.height;
    let cornerBLH: number = cornerBLSize.width;
    cornerBL = pathRotate(cornerBL, 90);
    cornerBL = pathMoveX(cornerBL, DirectX.RIGHT, cornerBLSize.height);
    // cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.width);

    //右下角
    // const cornerBRSize = {width: 0, height: 0};
    // let cornerBR = corner11(dx, dy, startX, startY, true, cornerBRSize);
    // cornerBR = pathMirrorY(cornerBR, startX);
    // let cornerBRW: number = cornerBRSize.width;
    // let cornerBRH: number = cornerBRSize.height;
    // cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner10(dx, dy, startX, startY, true, cornerBRSize);
    // cornerBR = pathRotate(cornerBR, 90)
    cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathRotate(cornerBR, -90);
    // cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, dx * width - dx * cornerBRSize.height);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;

    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner10(dx, dy, startX, startY, true, cornerTRSize);
    // cornerTR = pathMirrorY(cornerTR, startX);
    cornerTR = pathRotate(cornerTR, -90);
    // cornerTR = cornerMirrorX(cornerTR, startX, cornerTRSize);
    let cornerTRW: number = cornerTRSize.height;
    let cornerTRH: number = cornerTRSize.width;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width -  cornerTRSize.height);

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner10(dx, dy, startX, startY, true, cornerTLSize);
    cornerTL = pathMirrorY(cornerTL, startX);
    cornerTL = pathRotate(cornerTL, 90);
    let cornerTLW: number = cornerTLSize.height;
    let cornerTLH: number = cornerTLSize.width;
    cornerTL = pathMoveX(cornerTL, DirectX.RIGHT,  cornerTLSize.height);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height);

    
    const boardConfig: any = {
        middleware: extraConfig?.noMiddleware ? {} : {
            top: width > 500 && "close",
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: width > 500 && "close",
        }
    }
    //board
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
   
        
    if(diaokongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 11), startY + dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 11), startY + dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 11), startY + dy * height - dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 11), startY + dy * height - dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        });
    }

    if (吊码) {
        paths.push(
            {
                type: Line,
                params: [
                    startX + dx * 28.5, startY + dy * height - dy * 65.5,
                    startX + dx * 28.5 + dx * 22, startY + dy * height -  dy * 65.5
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 28.5 + dx * 22, startY + dy * height -  dy * 65.5,
                startX + dx * 28.5 + dx * 22, startY + dy * height - (dy * 65.5 - dy * 19)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 28.5 + dx * 22, startY + dy * height - (dy * 65.5 - dy * 19),
                startX + dx * 28.5, startY + dy * height - (dy * 65.5 - dy * 19),

            ]
        }, {
            type: Line,
            params: [
                startX + dx * 28.5, startY + dy * height - (dy * 65.5 - dy * 19),
                startX + dx * 28.5, startY + dy * height - dy * 65.5
            ]
        },

            {
                type: Line,
                params: [
                    startX + dx * width - dx * 28.5, startY + dy * height - dy * 65.5,
                    startX + dx * width - (dx * 28.5 + dx * 22), startY + dy * height - dy * 65.5
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 28.5 + dx * 22), startY + dy * height - dy * 65.5,
                startX + dx * width - (dx * 28.5 + dx * 22), startY + dy * height - (dy * 65.5 - dy * 19)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 28.5 + dx * 22), startY + dy * height - (dy * 65.5 - dy * 19),
                startX + dx * width - dx * 28.5, startY + dy * height - (dy * 65.5 - dy * 19),

            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 28.5, startY + dy * height - (dy * 65.5 - dy * 19),
                startX + dx * width - dx * 28.5, startY + dy * height - dy * 65.5
            ]
        }
        )
    }

    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}