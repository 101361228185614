import { QRCode } from "antd";
import BarCode from "react-barcode";

export const TagView = (props: {
    selected?: boolean;
    body: {
        list: {
            content: string
        }[],
        $rects: {
            x: number,
            y: number,
            height: number,
            width: number,
            number: string;
            id: string
        }
    },
    footer: {
        content: string;
    }
    tagRects: {
        x: number,
        y: number,
        height: number,
        width: number,
        number: string;
        id: string
    }[]
}) => {


    const scale = 13.5 / 1218;

    return (
        <div style={{ zoom: 1.5, position: 'relative', background: `${props.selected ? "#dddddd" : "#ffffff"}`, boxShadow: '-1px 3px 10px rgba(0, 0, 0, .2)', marginBottom: '2mm', width: '60mm', height: '40mm', fontSize: "2.6mm", lineHeight: "4.2mm", padding: "1mm 1mm 0 1mm", border: `1mm solid ${props.selected ? '#dddddd' : '#1668dc'}`, borderRadius: '1mm', color: "#000" }}>
            <div className="flex column" style={{ position: 'relative', height: "100%", justifyContent: 'space-between', }}>
                <div style={{ fontFamily: '楷书' }}>
                    {
                        props.body.list.map((item) => {
                            return (
                                <div>{item.content}</div>
                            )
                        })
                    }
                </div>
                <div style={{ borderTop: "1px solid #000", padding: '1mm 0' }}>
                    {props.footer.content}
                </div>
                {
                    props.tagRects.map((rect) => {
                        return (
                            <div style={{
                                position: 'absolute',
                                zIndex: 9,
                                border: "1px solid #000",
                                width: `${(rect.width * scale)}mm`,
                                height: `${(rect.height * scale)}mm`,
                                background: rect.id === props.body.$rects.id ? '#000' : undefined,
                                left: `calc(42mm + ${(rect.x * scale)}mm)`,
                                top: `${rect.y * scale}mm`,
                            }} />
                        )
                    })
                }
            </div>
            {/* {
                (
                    <div style={{ position: 'absolute', height: '100%', width: "100%", left: 0, top: 0, background: `rgba(0,0,0,${props.selected ? '.6' : '.1'})`, zIndex: 99 }} />
                )
            } */}
        </div>
    )
}



export const TagView2 = (props: {
    showWaterMark?: boolean;
    waterMark?: string;
    waterMarkColor?: string;
    selected?: boolean;

    showFrame?: boolean;

    sheetHeight: number;
    sheetWidth: number;
    body: {
        list: {
            content: string
        }[],
        $rects: {
            x: number,
            y: number,
            height: number,
            width: number,
            number: string;
            id: string
        }
    },
    footer: {
        content: string;
    }
    tagRects: {
        x: number,
        y: number,
        height: number,
        width: number,
        number: string;
        id: string
    }[],
    extra?: {
        qrcodeUrl?: string
    },
    barCodeWidth?: number;
}) => {

    const scale = 13.5 / 1218 * 0.8;

    const 地址 = props.body.list[0].content?.replace("地址：", "");
    const 橱柜 = props.body.list[1].content?.replace("橱柜：", "");
    const 编号 = props.body.list[2].content?.replace("编号：", "");
    const 板件 = props.body.list[3].content?.replace("板件：", "");
    const 材质 = props.body.list[4].content?.replace("材质：", "");
    const 尺寸 = props.body.list[5].content?.replace("尺寸：", "");
    const 所属大板 = props.body.list[6].content?.replace("所属大板：", "")?.split("/")[0];
    const 单号 = props.footer.content?.replace("单号：", "");

    const 尺寸宽 = 尺寸?.split("×")[0]?.replace("mm", "");
    const 尺寸高 = 尺寸?.split("×")[1]?.replace("mm", "");
    const 橱柜编号 = 编号?.split("-")[0];
    const 板件编号 = 编号?.split("-")[1];

    return (
        <div style={{ zoom: 1.5, position: 'relative', background: `${props.selected ? "#dddddd" : "#ffffff"}`, boxShadow: '-1px 3px 10px rgba(0, 0, 0, .2)', marginBottom: '2mm', width: '60mm', height: '40mm', fontSize: "2.6mm", lineHeight: "4.2mm", padding: "1mm 1mm 0 1mm", border: `1mm solid ${props.selected ? '#dddddd' : '#1668dc'}`, borderRadius: '1mm', color: "#000" }}>
            {
                props.showWaterMark && (
                    <div className="absolute flex column center" style={{ zIndex: 999, rotate: '-30deg', opacity: 0.2, lineHeight: '42px', fontSize: 36, color: props.waterMarkColor === 'blue' ? '#1668dc' : props.waterMarkColor || "#1668dc", fontWeight: "bold", left: 0, top: 0, width: "100%", height: "100%" }}>
                        <div>{props.waterMark}</div>
                    </div>

                )
            }
            <div className="flex column" style={{ position: 'relative', height: "100%", justifyContent: 'space-between', }}>
                <div style={{ fontFamily: '楷书' }}>
                    <div className="bold">{地址}</div>
                    <div>橱柜：{橱柜}</div>
                    <div>材质：{材质}</div>
                    <div>板件：{板件}</div>
                    <div>尺寸：{尺寸宽}x{尺寸高}</div>
                </div>
                <QRCode style={{ position: "absolute", right: 40, bottom: 52 }} value={props.extra?.qrcodeUrl || "https://toskies.com/2"} size={80} color="#000" />
                <div style={{ position: 'absolute', bottom: 18, left: 0 }}>

                    <div style={{ width: "100%" }}>
                        <BarCode

                            format="CODE128"
                            value={单号.replace("BXGCG", '') + 橱柜编号 + 板件编号}
                            displayValue={false}
                            width={isNaN(props.barCodeWidth) || !Number(props.barCodeWidth) ? 1.9 : Number(props.barCodeWidth)}
                            height={30}
                            background={props.selected ? "#dddddd" : "#ffffff"}
                            margin={0}

                        />

                    </div>
                </div>
                <div className="flex" style={{ borderTop: undefined, padding: '1mm 0', justifyContent: 'space-between' }}>
                    <div>{单号}</div>
                    <div>{橱柜编号}#柜{板件编号}#板</div>
                    <div>{所属大板}#大板</div>
                </div>
                {
                    props.showFrame && (

                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 9,
                                border: "1px dashed #000",
                                width: `${(props.sheetWidth * scale)}mm`,
                                height: `${(props.sheetHeight * scale)}mm`,
                                left: `calc(45mm)`,
                                bottom: `calc(${16 - (((props.sheetHeight - 2438) * scale))}mm)`,
                            }}
                        />
                    )
                }
                {
                    props.tagRects.map((rect) => {
                        return (
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 9,
                                    border: "1px solid #000",
                                    width: `${(rect.width * scale)}mm`,
                                    height: `${(rect.height * scale)}mm`,
                                    background: rect.id === props.body.$rects.id ? '#000' : undefined,
                                    left: `calc(45mm + ${(rect.x * scale)}mm)`,
                                    bottom: `${rect.y * scale + 16 - (props.sheetHeight - 2438) * scale}mm`,
                                }}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
