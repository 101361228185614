import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";

export const _05zhuanjiaoneiXBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return _05beineiBoard(dx, dy, startX, startY, width, height, false, {
        noMiddleware: true
    });
}