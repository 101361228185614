import { Layer, Rect } from "react-konva";
import { useWallDrawing } from "./store";
import { useEffect, useMemo, useRef, useState } from "react";
import { ShapeWall } from "../$shapes/shape-wall";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { AreaData, CANVAS_SIZE, WallData, m2px } from "../../../store";
import { computeWallDirectSign, computeWallEndAnchorPos } from "../../../util";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { message } from "antd";
import { generateUUID } from "three/src/math/MathUtils";
import { calculateWallContours } from "../../../gpt/calculateWallContours";

export const LayerDrawRectWall = () => {
    const {
        drawWallType, brushPos, stageScale, wallThickness, roomName, floorHeight, floorThickness,
        //开始绘画
        drawedWallList: drawedWalls, pushDrawedArea, pushDrawedWall, cancelDraw
    } = useWallDrawing();

    const [isDrawRectWall, setIsDrawRectWall] = useState(false);
    
    const reset = () => {
        setCanShowWalls(false);
        setFirstAnchor(null);
        setSecondAnchor(null);
        setIsDanger(false);
        setCanSelect(false);
        setWallsData({ left: null, right: null, top: null, bottom: null });
    }
    useEffect(() => {
        if (drawWallType === "wall-rectangle") {
            setIsDrawRectWall(true);
        } else {
            setIsDrawRectWall(false);
            reset();
            
        }
    }, [drawWallType]);

    // const [drawing, setDrawing] = useState(false);

    const [firstAnchor, setFirstAnchor] = useState<Vector2d>(null);
    const [secondAnchor, setSecondAnchor] = useState<Vector2d>(null);

    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        if (e.evt.button === 0) {
            //fist confirm
            if (!firstAnchor) {
                setFirstAnchor({ x: brushPos.x, y: brushPos.y });
            } else {
                if (secondAnchor) {
                    //save
                    if(isDanger) {
                        message.warning("绘制区域过小");
                        return ;
                    }
                    onFinish(firstAnchor, secondAnchor);
                }
            }
        }
    }

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {

        if (firstAnchor) {
            setSecondAnchor({ x: brushPos.x, y: brushPos.y });
        }
        setCanSelect(false);
    }

    const [canShowWalls, setCanShowWalls] = useState(false);
    const [isDanger, setIsDanger] = useState(false);

    type FakeWallData = {
        wallStartAnchor: Vector2d,
        wallDirect: "x" | "y",
        wallLength: number,
        wallThickness: number,
    }
    const [wallsData, setWallsData] = useState<{
        left: FakeWallData,
        right: FakeWallData,
        top: FakeWallData,
        bottom: FakeWallData
    }>({ left: null, right: null, top: null, bottom: null });

    const getWallsData = (firstAnchor: Vector2d, secondAnchor: Vector2d, wallThickness: number) => {
        if(!firstAnchor || !secondAnchor || !wallThickness || wallThickness < 0) {
            throw new Error("cannot get walls data, params error.");
        }
        let xLength = secondAnchor.x - firstAnchor.x;
        let yLength = secondAnchor.y - firstAnchor.y;
        if(Math.abs(xLength) < 1 || Math.abs(yLength) < 1) {
            setIsDanger(true);
            return ;
        }
        const xSign = computeWallDirectSign(xLength);
        const ySign = computeWallDirectSign(yLength);
        const left: FakeWallData = {
            wallStartAnchor: { x: firstAnchor.x - xSign * wallThickness / 2, y: firstAnchor.y - ySign * wallThickness / 2 },
            wallDirect: "y",
            wallLength: yLength + ySign * wallThickness,
            wallThickness: wallThickness
        }
        const bottom: FakeWallData = {
            wallStartAnchor: { x: firstAnchor.x - xSign * wallThickness / 2, y: firstAnchor.y + yLength + ySign * wallThickness / 2 },
            wallDirect: "x",
            wallLength: xLength + xSign * wallThickness,
            wallThickness: wallThickness
        }
        const right: FakeWallData = {
            wallStartAnchor: { x: firstAnchor.x + xLength + xSign * wallThickness / 2, y: firstAnchor.y + yLength + ySign * wallThickness / 2 },
            wallDirect: "y",
            wallLength: -yLength - ySign * wallThickness,
            wallThickness: wallThickness
        }
        const top: FakeWallData = {
            wallStartAnchor: { x: firstAnchor.x + xLength + xSign * wallThickness / 2, y: firstAnchor.y - ySign * wallThickness / 2 },
            wallDirect: "x",
            wallLength: -xLength - xSign * wallThickness,
            wallThickness: wallThickness
        }
        return { left, right, top, bottom };
    }

    useEffect(() => {
        if (firstAnchor && secondAnchor) {
            const wallsData = getWallsData(firstAnchor, secondAnchor, wallThickness);
            if(wallsData) {
                setWallsData(wallsData);
                setCanShowWalls(true);
                setIsDanger(false);
            }
        } else {
            setIsDanger(false);
            setCanShowWalls(false);
        }
    }, [firstAnchor, secondAnchor, wallThickness]);

    type Dimension = {
        direct: "x" | "y", from: number, to: number, offset: number, base: number
    }
    const [xDimension, setXDimension] = useState<Dimension>(null);
    const [yDimension, setYDimension] = useState<Dimension>(null);

    const [canSelect, setCanSelect] = useState(false);
    const xDimensionRef = useRef<any>();
    const yDimensionRef = useRef<any>();

    useEffect(() => {
        let tabListener: (e: KeyboardEvent) => void;
        let flag = false;
        if(canSelect) {
            if(flag) {
                yDimensionRef.current?.select();
            }else {
                xDimensionRef.current?.select();
            }
            flag = !flag;
            tabListener = (e: KeyboardEvent) => {
                if(e.key === "Tab") {
                    e.preventDefault();
                    if(flag) {
                        yDimensionRef.current?.select();
                    }else {
                        xDimensionRef.current?.select();
                    }
                    flag = !flag;
                }
            }
            window.addEventListener("keydown", tabListener);
        }
        return () => {
            if(tabListener) {
                window.removeEventListener("keydown", tabListener);
                tabListener = undefined;
            }
        }
    }, [canSelect]);

    useEffect(() => {
        if(firstAnchor && secondAnchor) {
            // let xLength = secondAnchor.x - firstAnchor.x;
            // let yLength = secondAnchor.y - firstAnchor.y;
            
            let xBase = Math.min(firstAnchor.x, secondAnchor.x);
            let yBase = Math.min(firstAnchor.y, secondAnchor.y);
            const xDimension: Dimension = {
                direct: "x",
                from: Math.min(firstAnchor.x, secondAnchor.x),
                to: Math.max(firstAnchor.x, secondAnchor.x),
                offset: wallThickness,
                base: yBase
            }
            const yDimension: Dimension = {
                direct: "y",
                from: Math.min(firstAnchor.y, secondAnchor.y),
                to: Math.max(firstAnchor.y, secondAnchor.y),
                offset: wallThickness,
                base: xBase
            }
            setXDimension(xDimension);
            setYDimension(yDimension);
        }else {
            setXDimension(null);
            setYDimension(null);
        }
        
    }, [firstAnchor, secondAnchor]);


    const onFinish = (firstAnchor: Vector2d, secondAnchor: Vector2d) => {
        const wallsData = getWallsData(firstAnchor, secondAnchor, wallThickness);
        if(!(wallsData.bottom && wallsData.left && wallsData.right && wallsData.top)) {
            message.warning("当前数据异常，请清空重试");
            return ;
        }
        const anchors = [
            wallsData.left.wallStartAnchor, 
            wallsData.bottom.wallStartAnchor, 
            wallsData.right.wallStartAnchor, 
            wallsData.top.wallStartAnchor
        ];
        const endAnchors = [
            computeWallEndAnchorPos(anchors[0], wallsData.left.wallLength, wallsData.left.wallDirect, wallThickness),
            computeWallEndAnchorPos(anchors[1], wallsData.bottom.wallLength, wallsData.bottom.wallDirect, wallThickness),
            computeWallEndAnchorPos(anchors[2], wallsData.right.wallLength, wallsData.right.wallDirect, wallThickness),
            computeWallEndAnchorPos(anchors[3], wallsData.top.wallLength, wallsData.top.wallDirect, wallThickness)
        ]
        const contour = calculateWallContours(anchors, wallThickness);
        const areaData: AreaData = {
            id: generateUUID(),
            areaName: roomName,
            anchors: anchors,
            ...contour,
            wallThickness: wallThickness,
            floorThickness: floorThickness,
            walls: [
                {
                    id: generateUUID(),
                    wallType: "wall-straight",
                    startAnchorPos: anchors[0],
                    endAnchorPos: endAnchors[0],
                    wallThickness: wallThickness,
                    wallLength: wallsData.left.wallLength,
                    wallDirect: wallsData.left.wallDirect,
                    nextAnchorPos: anchors[1],
                    floorHeight,
                    floorThickness
                },
                {
                    id: generateUUID(),
                    wallType: "wall-straight",
                    startAnchorPos: anchors[1],
                    endAnchorPos: endAnchors[1],
                    wallThickness: wallThickness,
                    wallLength: wallsData.bottom.wallLength,
                    wallDirect: wallsData.bottom.wallDirect,
                    nextAnchorPos: anchors[2],
                    floorHeight,
                    floorThickness
                },
                {
                    id: generateUUID(),
                    wallType: "wall-straight",
                    startAnchorPos: anchors[2],
                    endAnchorPos: endAnchors[2],
                    wallThickness: wallThickness,
                    wallLength: wallsData.right.wallLength,
                    wallDirect: wallsData.right.wallDirect,
                    nextAnchorPos: anchors[3],
                    floorHeight,
                    floorThickness
                },
                {
                    id: generateUUID(),
                    wallType: "wall-straight",
                    startAnchorPos: anchors[3],
                    endAnchorPos: endAnchors[3],
                    wallThickness: wallThickness,
                    wallLength: wallsData.top.wallLength,
                    wallDirect: wallsData.top.wallDirect,
                    nextAnchorPos: anchors[0],
                    floorHeight,
                    floorThickness
                }
            ]
        }
        pushDrawedArea({
            type: "area",
            data: areaData
        });
        cancelDraw();
        setCanSelect(false);

    }


    return (
        <Layer
            visible={isDrawRectWall}
        >
            {
                useMemo(() => {
                    return canShowWalls && (
                        <>
                            {/* top */}
                            <ShapeWall
                                type="fake"
                                {...wallsData.top}
                                danger={isDanger}
                            />
                            {/* bottom */}
                            <ShapeWall
                                type="fake"
                                {...wallsData.bottom}
                                danger={isDanger}
                            />
                            {/* right */}
                            <ShapeWall
                                type="fake"
                                {...wallsData.right}
                                danger={isDanger}
                            />
                            {/* left */}
                            <ShapeWall
                                type="fake"
                                {...wallsData.left}
                                danger={isDanger}
                            />
                        </>
                    )
                }, [canShowWalls, isDanger, wallsData])
            }
            {
                useMemo(() => {
                    if(!xDimension || !firstAnchor || !secondAnchor) {
                        return ;
                    }
                    return (   
                        <ShapeDimension 
                            ref={xDimensionRef}
                            canInput={true}
                            // autoSelect
                            {...xDimension}
                            lengthSetTriggers={["enter", "blur"]}
                            onLengthSet={(length) => {
                                if(firstAnchor.x  < secondAnchor.x) {
                                    setSecondAnchor({x: firstAnchor.x + length , y: secondAnchor.y});
                                }else {
                                    setSecondAnchor({x: firstAnchor.x - length, y: secondAnchor.y });
                                
                                }
                            }}
                            onLengthEnter={(length) => {
                                let _secondAnchor: Vector2d;
                                if(firstAnchor.x  < secondAnchor.x) {
                                    _secondAnchor = {x: firstAnchor.x + length , y: secondAnchor.y};
                                }else {
                                    _secondAnchor = {x: firstAnchor.x - length, y: secondAnchor.y };
                                
                                }
                                onFinish(firstAnchor, _secondAnchor);
                            }}
                            
                        />
                    )
                }, [xDimension, firstAnchor, secondAnchor])
            }
            {
                useMemo(() => {
                    if(!yDimension || !secondAnchor || !firstAnchor) {
                        return ;
                    }
                    return (   
                        <ShapeDimension 
                            ref={yDimensionRef}
                            canInput={true}
                            // autoSelect
                            {...yDimension}
                            lengthSetTriggers={["enter", "blur"]}
                            onCanSelect={() => {
                                setCanSelect(true);
                            }}
                            onLengthSet={(length) => {
                                if(firstAnchor.x  < secondAnchor.x) {
                                    setSecondAnchor({x: secondAnchor.x , y: firstAnchor.y + length});
                                }else {
                                    setSecondAnchor({x: secondAnchor.x , y: firstAnchor.y - length});
                                
                                }
                            }}
                            onLengthEnter={(length) => {
                                let _secondAnchor: Vector2d;
                                if(firstAnchor.x  < secondAnchor.x) {
                                    _secondAnchor = {x: secondAnchor.x , y: firstAnchor.y + length};
                                }else {
                                    _secondAnchor = {x: secondAnchor.x , y: firstAnchor.y - length};
                                
                                }
                                onFinish(firstAnchor, _secondAnchor);
                            }}
                            
                        />
                    )
                }, [yDimension, secondAnchor, firstAnchor])
            }
            {
                isDrawRectWall && (
                    <Rect
                        x={0}
                        y={0}
                        width={CANVAS_SIZE * m2px}
                        height={CANVAS_SIZE * m2px}
                        fill="#ff000011"
                        opacity={0}
                        onClick={onClick}
                        onMouseMove={onMouseMove}
                    />
                )
            }
        </Layer>
    )


}