import { corner1Sanheyi } from "./corner1-sanheyi";
import { pathMirrorY, pathMoveX, pathRotate2 } from "../../../util/graph";
import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";

export const corner3Sanheyi = (_dx: DirectX, _dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const dx = 1;
    const dy = 1;
    const size: {width: number, height: number} = {width: 0, height: 0};
    let  paths = corner1Sanheyi(dx, dy, startX, startY, true, size);
    paths = pathRotate2(paths, startX, startY, 90);
    paths = pathMoveX(paths, DirectX.RIGHT, dx * size.height);
    paths.push({
        type: Circle,
        params: [
            startX + dx * 43.1, startY + dy * 43.1,
            4
        ]
    })
    let width = size.height;
    let height = size.width;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startY);
        paths = pathRotate2(paths, startX, startY, 180);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startY);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
    }
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}