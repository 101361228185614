import { Arc } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { numberEqual } from "../../../util";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { _02AcewaiBoardLeft } from "./02-a-cewai-left";
import { _02ceneiBoardLeft } from "./02-cenei-left";
import { _02cewaiBoardLeft } from "./02-cewai-left";

export const _02AcewaiBoardRight = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    menxiagaochu?: number,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const [ boardPaths, cornerPaths ] = _02AcewaiBoardLeft(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, lianjiekongbianju, corner, geceng, gecengX, gecengY, gecengkongjing, gxinglashou, gxinglashouType, gxinglashouGaodu, menxiagaochu, extraConfig);
    const combind = conbinePaths([boardPaths, cornerPaths]);
    let mirrorCombind = pathMirrorY(combind, startX);
    mirrorCombind = pathMoveX(mirrorCombind, DirectX.RIGHT, width);
    for (let i = 0; i < mirrorCombind.length; i++) {
        const temp = mirrorCombind[i];
        if(temp.type === Arc) {
            // //console.log(temp.params);
            if(numberEqual(temp.params[3] % 360, 90) && numberEqual(temp.params[4] % 360, -180)) {
                temp.params[3] = 270;
                temp.params[4] = 360;
            }else if(numberEqual(temp.params[3], -90) && numberEqual(temp.params[4], -360) && numberEqual(temp.params[2], 0.2)) {
                // //console.log(temp);
                temp.params[3] = 90;
                temp.params[4] = 180;
            }
        }
    }
    return [
        mirrorCombind.slice(0, boardPaths.length),  
        mirrorCombind.slice(boardPaths.length, boardPaths.length + cornerPaths.length),
    ]
}