import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner11 } from "../../coners/corner11";
import { corner17 } from "../../coners/corner17";
import { corner3 } from "../../coners/corner3";
import { rectBoard } from "../general-board";

export const _03gongyongceneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    
    jiaolianbianju: number,
    nojiaoliankong: boolean,

    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const {
        上免拉手  ,
        上免拉手X ,
        上免拉手Y ,
        中免拉手  ,
        中免拉手X ,
        中免拉手Y ,
        中免拉手Z
    } = extraConfig;
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner11(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;

    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner3(dx, dy, startX, startY, true, cornerBRSize);
    cornerBR = pathMirrorY(cornerBR, startX);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);

    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(!上免拉手) {
        cornerTR = corner17(dx, dy, startX, startY, true, cornerTRSize);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        if(qiandanghengfang) {
            cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMoveX(cornerTR, -DirectX.RIGHT, width);
            cornerTR = pathMirrorY(cornerTR, startX);
            cornerTR = pathRotate2(cornerTR, startX, startY, -90);
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
            const widthTemp = cornerTRSize.width;
            cornerTRW = cornerTRSize.height;
            cornerTRH = widthTemp;
        }
       
    }else {
        cornerTR.push({
            type: "Line",
            params: [
                startX + dx * width, startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * width - dx * (上免拉手X + 10.5),  startY + dy * height - dy * (上免拉手Y + 10.5)
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * width - dx * (上免拉手X + 10.5),  startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * width - dx * (上免拉手X + 10.5), startY + dy * height
            ]
        });
        cornerTRH = 上免拉手Y + 10.5;
        cornerTRW = 上免拉手X + 10.5;
    }

    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
    cornerTL = cornerTL.filter((temp) => temp.type !== Circle);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    
    
    const boardConfig: any = {
        // middleware: {
        //     top: false,
        //     left: false,
        //     right: (height > 500) && "close",
        //     bottom: (width > 500) && "close",
        // }
    }
    let paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    
    if(中免拉手) {
        paths = paths.filter((_, index) => index !== 1);
        paths.push({
            type: Line,
            params: [
                startX + dx * width, startY + dy * cornerBRH,
                startX + dx * width, startY + dy * (中免拉手Z- 7.5)

            ]
        }, {
            type: Line,
            params: [
                startX + dx * width, startY + dy * (中免拉手Z- 7.5),
                startX + dx * width - dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z- 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z- 7.5),
                startX + dx * width - dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z- 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * (中免拉手X + 10.5),  startY + dy * (中免拉手Z- 7.5 + 中免拉手Y),
                startX + dx * width,  startY + dy * (中免拉手Z- 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width, startY + dy * (中免拉手Z- 7.5 + 中免拉手Y),
                startX + dx * width, startY + dy * height - dy * cornerTRH
            ]
        });
    }

    
    // if (jiaolianbianju > -1 && !nojiaoliankong) {
    //     let offsetGX = 0;
    //     let offsetGY = 0;
    //     if (gxinglashou) {
    //         if (gxinglashouType === "up") {
    //             offsetGY = -1.5;
    //         } else if (gxinglashouType === "left-or-right") {
    //             offsetGX = 0;
    //         }
    //     }
    //     const temp: Path[] = [{
    //         type: Arc,
    //         params: [
    //             startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14),
    //             3.25,
    //             180, 360
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14),
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8,
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14)
    //         ]
    //     },
    //         //----------------
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12),
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12),
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4,
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12)
    //         ]
    //     },
    //         //**********************
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32,
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32,
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 14) + dy * 32
    //         ]
    //     },
    //         //----------------
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 32,
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 32,
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 51.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * (jiaolianbianju - 12) + dy * 32
    //         ]
    //     },
    //         //==============================================
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) - dy * offsetGY,
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) - dy * offsetGY,
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) - dy * offsetGY
    //         ]
    //     },
    //         //----------------
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) - dy * offsetGY,
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) - dy * offsetGY,
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) - dy * offsetGY
    //         ]
    //     },
    //         //******************* 
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
    //             startX + dx * 64.75 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
    //             startX + dx * 64.75 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY
    //         ]
    //     },
    //         //----------------
    //         {
    //             type: Arc,
    //             params: [
    //                 startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
    //                 3.25,
    //                 180, 360
    //             ]
    //         }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
    //             startX + dx * 51.25 - dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,

    //         ]
    //     }, {
    //         type: Arc,
    //         params: [
    //             startX + dx * 51.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
    //             3.25,
    //             0, 180
    //         ]
    //     }, {
    //         type: Line,
    //         params: [
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
    //             startX + dx * 51.25 + dx * 3.25 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY
    //         ]
    //     }
    //     ];
    //     paths.push(...(temp.map((item) => {
    //         item.params[0] = startX + dx * width - (item.params[0] - startX);
    //         if(item.type === "Line") {
    //             item.params[2] = startX + dx * width - (item.params[2] - startX);
    //         }
    //         return item;
    //     })));
    // }


    const cornerPaths: Path[] = [];
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * width - dx * (59 + gecengX), startY + dy * (gecengY + 6) - dy * 13,
                1.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * 63, startY + dy * (gecengY + 6) - dy * 13,
                1.5
            ]
        });
    }

    return [
        paths,
        cornerPaths.concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]

 }