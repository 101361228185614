import { DirectX, DirectY } from "../../../constants/enum";
import { _02AceneiBoardRight } from "./02-a-cenei-right"

export const _12AceneiBoardRight = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number
): [Path[], Path[]] => {
    return _02AceneiBoardRight(dx, dy, startX, startY, width, height, lianjiekongbianju);
}