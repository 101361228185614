import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _02AbeineiBoard } from "./02-a-beinei";

export const _12AbeineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    diaokongbianju: number,
    a: number,
    extraConfig: any
): [Path[], Path[]] => {
    const [boardPaths, cornerPaths] = _02AbeineiBoard(dx, dy, startX, startY, width, height, diaokongbianju, extraConfig);
    cornerPaths.push({
        type: Circle,
        params: [
            startX + dx * (a + 1.5), startY + dy * (32.03 + 18),
            3.65
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * (a + 1.5), startY + dy * (31.5 + 32.03 + 18),
            3.65
        ]
    },
    
    {
        type: Circle,
        params: [
            startX + dx * (a + 1.5), startY + dy * height - dy * (31.5 + 32.03 + 18),
            3.65
        ]
    }, {
        type: Circle,
        params: [ 
            startX + dx * (a + 1.5), startY + dy * height - dy * (32.03 + 18),
            3.65
        ]
    })
    return [boardPaths, cornerPaths];
}