import { AbstractMeta, mm2RealValue, mm2RealValueForNumber_KeepNegative, px2DisplayValue, px2DisplayValueForNumber_KeepNegative } from "./base";

export const structRoundPost = new AbstractMeta<{}>("圆柱属性", [
    
    {
        key: "radius",
        label: "半径",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "zHeight",
        label: "柱高",
        defaultValue: 0,
        valueType: "enum:single#with:integer",
        options: [
            {
                key: "-1",
                value: -1,
                label: "跟随层高"
            }
        ],
        suffix: "mm",
        toDisplayValueForNumber: px2DisplayValueForNumber_KeepNegative,
        toRealValueForNumber: mm2RealValueForNumber_KeepNegative,
        rule: [
            {
                key: "min",
                value: -1
            }
        ]
    },
    {
        key: "top",
        label: "距上",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "bottom",
        label: "距下",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "left",
        label: "距左",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "right",
        label: "距右",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }

]);

export const structRectPost = new AbstractMeta<{}>("方柱属性", [
    {
        key: "width",
        label: "宽度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "height",
        label: "深度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 1
            }
        ]

    },
    {
        key: "zHeight",
        label: "高度",
        defaultValue: 0,
        valueType: "enum:single#with:integer",
        options: [
            {
                key: "-1",
                value: -1,
                label: "跟随层高"
            }
        ],
        suffix: "mm",
        toDisplayValueForNumber: px2DisplayValueForNumber_KeepNegative,
        toRealValueForNumber: mm2RealValueForNumber_KeepNegative,
        rule: [
            {
                key: "min",
                value: -1
            }
        ]
    },
    {
        key: "top",
        label: "距上",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "bottom",
        label: "距下",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "left",
        label: "距左",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "right",
        label: "距右",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }
]);


export const structBeamX = new AbstractMeta<{}>("梁属性(横)", [

    {
        key: "sizeY",
        label: "梁宽",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 1
            }
        ]

    },
    {
        key: "sizeZ",
        label: "梁高",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 1
            }
        ]

    },
    {
        key: "top",
        label: "距上",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "bottom",
        label: "距下",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "groundClearance",
        label: "高度",
        defaultValue: 0,
        valueType: "enum:single#with:integer",
        suffix: "mm",
        toDisplayValueForNumber: px2DisplayValueForNumber_KeepNegative,
        toRealValueForNumber: mm2RealValueForNumber_KeepNegative,
        options: [{
            key: "-1",
            value: -1,
            label: "贴顶（层高-梁高）"
        }, {
            key: "0",
            value: 0,
            label: "贴地"   
        }],
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }
]);
export const structBeamY = new AbstractMeta<{}>("梁属性(纵)", [
    {
        key: "sizeX",
        label: "梁宽",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 1
            }
        ]

    },
    {
        key: "sizeZ",
        label: "梁高",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 1
            }
        ]

    },
    {
        key: "left",
        label: "距左",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "right",
        label: "距右",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "groundClearance",
        label: "高度",
        defaultValue: 0,
        valueType: "enum:single#with:integer",
        toDisplayValueForNumber: px2DisplayValueForNumber_KeepNegative,
        toRealValueForNumber: mm2RealValueForNumber_KeepNegative,
        suffix: "mm",
        options: [{
            key: "-1",
            value: -1,
            label: "贴顶（层高-梁高）"
        }, {
            key: "0",
            value: 0,
            label: "贴地"   
        }],
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }
]);