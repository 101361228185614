import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorX, pathMirrorY, pathMoveX } from "../../../util/graph";
import { dineiBoard } from "../地柜/01-dinei";
import { _03beineiBoard } from "./03-beinei";

export const _04beineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number
 ): [Path[], Path[]]  => {

    const [dineiBoardPaths, dineiBoardCorners] = _03beineiBoard(dx, dy, startX, startY, width, height, a);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]

 }