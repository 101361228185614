import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { NestRequest, NestResult, NestSheet, Material, NestSheetSettings, NestBoard } from "../../../../../@interface";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { DraggableBoard, MoveTargetBoard } from "../comp-draggable/draggable-board";
import { ExportOutlined, FullscreenExitOutlined, FullscreenOutlined, InsertRowAboveOutlined, InsertRowRightOutlined, RedoOutlined, ReloadOutlined, UndoOutlined } from "@ant-design/icons";
import { BoardAction, BoardActionMap, BoardMoveAction, BoardMoveToSheetAction, BoardRotateAction } from "../comp-draggable/draggable-data";
import { Button, Skeleton, Switch, Tooltip, message } from "antd";
import { sheetBoardDragStore } from "../store";
import { sleep } from "../../../utils2";
// import { FullscreenExitOutlined, FullscreenOutlined, InsertRowAboveOutlined, InsertRowRightOutlined } from "@ant-design/icons";

let _setMainSizeAndScale: () => void;

let timeout: any = 0;
const resize = () => {
    if (_setMainSizeAndScale) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = 0;
        }
        timeout = setTimeout(() => {
            _setMainSizeAndScale();
            timeout = 0;
        }, 50);
    }
}
export const SheetEditor = ({
}) => {
    console.log("[render]SheetEditor");
    const context = useOutletContext<{ data: NestRequest, setTempSheet: (sheet: NestSheet | null) => void, onSheetBoardChange: (sheetId: string | number, boardActionMap: BoardActionMap) => void }>();
    const data = context.data;
    const param = useParams<{ materialId: string, sheetId: string }>();

    const [ready, setReady] = useState(false);




    //业务代码
    const [sheet, setSheet] = useState<NestSheet>(null);
    const [sheetSettings, setSheetSettings] = useState<NestSheetSettings>(null);
    const [material, setMaterial] = useState<Material>(null);
    const [scale, setScale] = useState(1);
    const [result, setResult] = useState<NestResult>(null);
    const [mainSize, setMainSize] = useState({ width: 0, height: 0, top: 0, left: 0 });
    const [horizontalScale, setHorizontalScale] = useState(1);
    const [horizontalMainSize, setHorizontalMainSize] = useState({ width: 0, height: 0, top: 0, left: 0 })
    const [layout, setLayout] = useState<"vertical" | "horizontal">("vertical");
    const [materialMap, setMaterialMap] = useState(new Map<string | number, Material>())

    useEffect(() => {
        if (!param.materialId || !param.sheetId) {
            return;
        }
        //准备工作
        const result = data.nestResult.find((r) => r.materialId == param.materialId);
        const _material = data.materials.find((m) => m.id == param.materialId);
        const settings = data.nestConfig.materialNestSettings.find((s) => s.materialId == param.materialId);
        const sheet = result.sheets.find((s) => s.id.toString() === param.sheetId);
        if (material?.id !== _material.id) {
            const map = new Map<string | number, Material>();
            data.materials.forEach((m) => {
                map.set(m.id, m);
            });
            setMaterial(_material);
            setMaterialMap(map);
            setResult(result);
            setMainSizeAndScale(sheet);
            setSheetSettings(settings);
            
        }
        setSheet(sheet)
        setReady(true);
        const relatedSheetIds = Array.from(new Set(sheetBoardDragStore.globalActionList.map((a) => a.sheetId)));
        for (let i = 0; i < relatedSheetIds.length; i++) {
            const sheetId = relatedSheetIds[i];
            context.onSheetBoardChange(sheetId, sheetBoardDragStore.boardActionMaps);
        }
    }, [param.materialId, param.sheetId]);


    const [boardActionsMap, setBoardActionsMap] = useState<BoardActionMap>({...sheetBoardDragStore.boardActionMaps});
    const [globalActions, setGlobalActions] = useState<BoardAction[]>([...sheetBoardDragStore.globalActionList]);
    const [actionMode, setActionMode] = useState<("free" | "safe")>("free");
    const [autoApproachLine, setAutoApprochLine] = useState(true);
    const [moveTargetSheet, setMoveTargetSheet] = useState<NestSheet | null>(null);
    
    useEffect(() => {
        context.setTempSheet(moveTargetSheet);
    }, [moveTargetSheet])

    useEffect(() => {
        addEventListener('resize', resize);
        return () => {
            removeEventListener("resize", resize);
            _setMainSizeAndScale = undefined;
        }
    }, []);
    const navigate = useNavigate();
    const location = useLocation();

    const setMainSizeAndScale = (sheet: NestSheet) => {
        //垂直情况布局尺寸
        const mainSize = { width: 0, height: 0, top: 0, left: 0 };
        mainSize.height = window.innerHeight - 2 - 36;
        mainSize.width = mainSize.height * (sheet.width / sheet.height);
        mainSize.left = (window.innerWidth - 237 - 2 - mainSize.width) / 2;
        const scale = mainSize.height / sheet.height;
        setScale(scale);
        setMainSize(mainSize);
        //水平情况布局尺寸
        const horizontalMainSize = { width: 0, height: 0, top: 0, left: 0 };
        horizontalMainSize.height = window.innerHeight - 2 - 36 - 80;
        horizontalMainSize.width = horizontalMainSize.height * (sheet.height / sheet.width);
        if (horizontalMainSize.width > (window.innerWidth - 237 - 2)) {
            horizontalMainSize.width = window.innerWidth - 237 - 2;
            horizontalMainSize.height = horizontalMainSize.width * (sheet.width / sheet.height);
        }
        horizontalMainSize.top = (window.innerHeight - 2 - 36 - horizontalMainSize.height) / 2;
        horizontalMainSize.left = (window.innerWidth - 237 - 2 - horizontalMainSize.width) / 2;
        const horizontalScale = horizontalMainSize.width / sheet.height;
        setHorizontalScale(horizontalScale);
        setHorizontalMainSize(horizontalMainSize);
    }
    _setMainSizeAndScale = () => {
        if (!sheet) {
            return;
        }
        setMainSizeAndScale(sheet);
    };

    const helperLinesRef = useRef<any>();

    const onActionChangeWrapper = async (board: NestBoard, cb: () => { action: BoardAction, relatedSheetIds: (string | number)[] }) => {
        if (!boardActionsMap[board.board.id]) {
            boardActionsMap[board.board.id] = [];
        }
        if(!sheetBoardDragStore.boardActionMaps[board.board.id]) {
            sheetBoardDragStore.boardActionMaps[board.board.id] = [];
        }
        const { action, relatedSheetIds } = cb();
        boardActionsMap[board.board.id].push(action);
        globalActions.push(action);
        sheetBoardDragStore.boardActionMaps[board.board.id].push(action);
        sheetBoardDragStore.globalActionList.push(action)
        setGlobalActions([...globalActions]);
        setBoardActionsMap({ ...boardActionsMap });

        for (let i = 0; i < relatedSheetIds.length; i++) {
            const sheetId = relatedSheetIds[i];
            context.onSheetBoardChange(sheetId, boardActionsMap);
            await sleep(300);
        }
    }
    const [resetLoading, setResetLoading] = useState(false);
    const [messageApi] = message.useMessage();
    const onClearActions = async () => {
        //针对移到其他板进行
        messageApi.open({
            type: 'loading',
            content: '正在处理...',
            duration: 0,
        });
        setResetLoading(true);
        const moveToSheetActions = globalActions.filter((a) => a.type === 'move-to-sheet') as BoardMoveToSheetAction[];
        const relatedSheetIds = new Set<string | number>();
        if (moveToSheetActions.length > 0) {
            for (let i = moveToSheetActions.length - 1; i >= 0; i--) {
                const action = moveToSheetActions[i];
                const originSheet = result?.sheets.find((s) => s.id === action.targetSheetId);
                if (originSheet) {
                    const board = originSheet.boards.find((b) => b.board.id === action.boardId);
                    originSheet.boards = originSheet.boards.filter((b) => b.board.id !== action.boardId);
                    relatedSheetIds.add(originSheet.id);
                    if (board) {
                        const targetSheet = result.sheets.find((s) => s.id === action.fromSheetId);
                        if (targetSheet) {
                            targetSheet.boards.push(board);
                            relatedSheetIds.add(targetSheet.id);
                        }
                    }
                }
            }
            sheet.boards = [...sheet.boards];
            setSheet({ ...sheet });
        }
        globalActions.forEach((a) => {
            relatedSheetIds.add(a.sheetId);
        })
        const sheetIdArr = Array.from(relatedSheetIds.values());

        for (let i = 0; i < sheetIdArr.length; i++) {
            const sheetId = sheetIdArr[i];
            context.onSheetBoardChange(sheetId, {});
            await sleep(100);
        }
        setBoardActionsMap({});
        setGlobalActions([]);
        sheetBoardDragStore.globalActionList = [];
        sheetBoardDragStore.boardActionMaps = {};
        messageApi.destroy();
        message.success("已清除所有操作");
        setResetLoading(false);

    }



    const toolBar = (

        <div className="flex center" style={{ background: "#000", width: '100%' }}>
            <div className="flex center" style={{ height: 36 }}>
                <div className="flex center" style={{ padding: '0 10px', color: layout === 'vertical' ? 'orange' : "#fff", cursor: "pointer" }} onClick={() => {
                    setLayout("vertical");
                }}>
                    <InsertRowRightOutlined style={{ fontSize: 16 }} />
                    <span style={{ fontSize: 12, marginLeft: 4 }}>竖向</span>
                </div>
                <div className="flex center" style={{ padding: '0 10px', color: layout === 'horizontal' ? 'orange' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
                    setLayout("horizontal");
                }}>
                    <InsertRowRightOutlined style={{ rotate: "90", fontSize: 16 }} />
                    <span style={{ fontSize: 12, marginLeft: 4 }}>横向</span>
                </div>
                <div className="flex center" style={{ padding: '0 10px', color: layout === 'horizontal' ? 'orange' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
                    // setLayout("horizontal");

                }}>
                    <Tooltip title={(<>
                        <div>说明：</div>
                        <div>● 安全模式：在重叠，或移动到板外时，则自动归位</div>
                        <div>● 自由模式：可以任意移动</div>
                    </>)}>
                        <Switch
                            checked={actionMode === 'safe'}
                            onClick={() => {
                                setActionMode(actionMode === 'free' ? 'safe' : 'free')
                            }}
                            checkedChildren="安全模式"
                            unCheckedChildren="自由模式"
                        />
                    </Tooltip>
                </div>
                <div className="flex center" style={{ padding: '0 10px', color: layout === 'horizontal' ? 'orange' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
                    // setLayout("horizontal");

                }}>
                    <Tooltip title={(<>
                        <div>说明：</div>
                        <div>● 自动贴线：移动时，当辅助线显示时，松开自动贴近辅助线</div>
                        <div>● 关闭自动贴线则由用户自主控制边距</div>
                    </>)}>
                        <Switch
                            checked={autoApproachLine}
                            onClick={() => {
                                setAutoApprochLine(!autoApproachLine);
                            }}
                            checkedChildren="自动贴线"
                            unCheckedChildren="手动贴线"
                        />
                    </Tooltip>
                </div>
                <div className="flex center" style={{ padding: '0 10px', color: layout === 'horizontal' ? 'orange' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
                    onClearActions();
                }}>
                    <Button type="link" disabled={globalActions.length === 0 || resetLoading} loading={resetLoading}>
                        <ReloadOutlined />
                        重置所有操作
                    </Button>
                </div>
                {/* <div className="flex center" style={{ padding: '0 10px', color: layout === 'horizontal' ? 'orange' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
                    // onClearActions();
                }}>
                    <Button size="small" style={{ marginLeft: 8 }} disabled={exporting} loading={exporting} type="primary" key={"export-dxf"} onClick={() => {


                        onExporting(data)
                    }}>
                        <ExportOutlined />
                        导出图纸DXF和标签PDF
                    </Button>

                </div> */}
                {/* <div className="flex center" style={{ padding: '0 10px', color: layout === 'horizontal' ? 'orange' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
            }}>
                <Button type="link" disabled={true}>
                    <UndoOutlined />
                    下一步
                </Button>
            </div> */}
                <div className="flex center" style={{ padding: '0 10px', color: document.fullscreenElement ? 'orangered' : "#fff", cursor: "pointer", borderLeft: '1px solid #333' }} onClick={() => {
                    // setLayout("horizontal");
                    if (document.fullscreenElement) {
                        document.exitFullscreen();
                    } else {
                        document.documentElement.requestFullscreen();
                    }
                }}>
                    {
                        document.fullscreenElement ? (
                            <FullscreenExitOutlined />
                            // <FullscreenOutlined />
                        ) : (
                            <FullscreenOutlined />
                        )
                    }
                    {/* <InsertRowAboveOutlined style={{ fontSize: 16 }} /> */}
                    <span style={{ fontSize: 12, marginLeft: 4 }}>{document.fullscreenElement ? '退出' : ''}满屏</span>
                </div>

            </div>
        </div>

    )

    return !ready ? (
        <Skeleton active />
    ) : (
        <div style={{
            marginLeft: 237,
            width: window.innerWidth - 237, background: "#312f31", position: 'fixed', left: 0, top: 0,  /* paddingLeft: (window.innerWidth - 237 - (window.innerHeight - 2) * rate) / 2, */ height: "100%"
        }}>
            {toolBar}
            <div className="relative" style={{ background: "#000", ...(layout === 'horizontal' ? horizontalMainSize : mainSize), border: "1px  solid orange", borderColor: param.sheetId !== sheet.id.toString() ? '#1668dc' : 'orange', boxSizing: 'content-box' }}>
                {
                    moveTargetSheet && sheet.id !== moveTargetSheet.id && (
                        <DroppableTargetSheet
                            layout={layout}
                            actionMap={boardActionsMap}
                            sheet={moveTargetSheet}
                            horizontalMainSize={horizontalMainSize}
                            mainSize={mainSize}
                            scale={scale}
                            material={material}
                            horizontalScale={horizontalScale}
                        />
                    )
                }
                {
                    sheet.boards.map((board, index) => {
                        const refs = new Array(sheet.boards.length).fill(null)
                        const actions = boardActionsMap[board.board.id] || [];
                        const boardRotateActions = actions.filter((a) => a.type === 'rotate');
                        let rotate = false;
                        if (boardRotateActions.length > 0) {
                            rotate = (boardRotateActions[boardRotateActions.length - 1] as BoardRotateAction).rotate;
                        }
                        const moveRotateActions = actions.filter((a) => a.type === 'move') as BoardMoveAction[];
                        let deltaX = 0;
                        let deltaY = 0;
                        moveRotateActions.forEach((a) => {
                            deltaX += a.deltaX;
                            deltaY += a.deltaY;
                        });
                        let isMovedToOtherSheet = false;
                        const boardMoveToSheetActions = actions.filter((a) => a.type === 'move-to-sheet') as BoardMoveToSheetAction[];
                        if (boardMoveToSheetActions.length > 0) {
                            const lastMove = boardMoveToSheetActions[boardMoveToSheetActions.length - 1];
                            if (lastMove.targetSheetId !== sheet.id) {
                                isMovedToOtherSheet = true;
                            }
                        }

                        return (
                            <>
                                <DraggableBoard
                                    index={index}
                                    actionMap={boardActionsMap}
                                    actionMode={actionMode}
                                    autoApproachLine={autoApproachLine}
                                    moveTargetSheet={moveTargetSheet}
                                    ref={(r) => {
                                        refs[index] = r;
                                    }}
                                    sheet={sheet}
                                    sheetSettings={sheetSettings as NestSheetSettings}
                                    onBoardMoved={(deltaX: number, deltaY: number) => {
                                        onActionChangeWrapper(board, () => {
                                            const action: BoardMoveAction = { type: "move", deltaX, deltaY, boardId: board.board.id, sheetId: sheet.id }
                                            return {
                                                action,
                                                relatedSheetIds: [sheet.id]
                                            }
                                        });
                                        // if (!boardActionsMap[board.board.id]) {
                                        //     boardActionsMap[board.board.id] = [];
                                        // }
                                        // boardActionsMap[board.board.id].push(action);
                                        // globalActions.push(action);
                                        // setGlobalActions([...globalActions]);
                                        // setBoardActionsMap({ ...boardActionsMap });
                                    }}
                                    onBoardMoveToSheet={(toSheet: NestSheet) => {

                                        onActionChangeWrapper(board, () => {
                                            const action: BoardMoveToSheetAction = {
                                                type: "move-to-sheet",
                                                boardId: board.board.id,
                                                sheetId: sheet.id,
                                                fromType: "sheet",
                                                fromSheetId: sheet.id,
                                                targetSheetId: toSheet.id,
                                            }
                                            sheet.boards = sheet.boards.filter((b) => b !== board);
                                            toSheet.boards.push(board);
                                            let originSheetId = sheet.id;
                                            navigate(`/nest/result/${param.materialId}/${toSheet.id}`, {
                                                state: location.state,
                                                replace: true
                                            });
                                            return {
                                                action,
                                                relatedSheetIds: [toSheet.id, originSheetId]
                                            }
                                        });
                                        // if (!boardActionsMap[board.board.id]) {
                                        //     boardActionsMap[board.board.id] = [];
                                        // }
                                        // const action: BoardMoveToSheetAction = {
                                        //     type: "move-to-sheet",
                                        //     fromType: "sheet",
                                        //     fromSheetId: sheet.id,  
                                        //     targetSheetId: toSheet.id,
                                        // }
                                        // sheet.boards = sheet.boards.filter((b) => b !== board);
                                        // toSheet.boards.push(board);
                                        // boardActionsMap[board.board.id].push(action);
                                        // globalActions.push(action);
                                        // setGlobalActions([...globalActions]);
                                        // setBoardActionsMap({ ...boardActionsMap });
                                        // navigate(`/nest/result/${param.materialId}/${toSheet.id}`, {
                                        //     state: location.state,
                                        //     replace: true
                                        // });
                                    }}
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                        [layout === 'vertical' ? 'left' : 'bottom']: (layout === 'vertical' ? scale : horizontalScale) * (board.customX + deltaX),
                                        [layout === 'vertical' ? 'top' : 'left']: (layout === 'vertical' ? scale : horizontalScale) * (board.customY + deltaY)
                                    }}
                                    board={board}
                                    scale={scale}
                                    layout={layout}
                                    horizontalScale={horizontalScale}
                                    material={material}
                                    compatibleV1={data.compatibleV1}
                                    rotate={rotate}
                                    onSetRotate={(rotate) => {
                                        onActionChangeWrapper(board, () => {
                                            let targetRotate = rotate;
                                            const action: BoardRotateAction = { type: "rotate", rotate: targetRotate, boardId: board.board.id, sheetId: sheet.id }
                                            return {
                                                action,
                                                relatedSheetIds: [sheet.id]
                                            }
                                        });
                                        // let targetRotate = rotate;
                                        // if (!boardActionsMap[board.board.id]) {
                                        //     boardActionsMap[board.board.id] = [];
                                        // }
                                        // const action: BoardRotateAction = { type: "rotate", rotate: targetRotate }
                                        // boardActionsMap[board.board.id].push(action);
                                        // globalActions.push(action);
                                        // setGlobalActions([...globalActions]);
                                        // setBoardActionsMap({ ...boardActionsMap });

                                    }}
                                    onSetHelperLines={(helperLines) => {
                                        helperLinesRef.current?.setHelperLines(helperLines);
                                    }}
                                    onShowMoveTargetSheet={(sheet) => {
                                        if (!sheet || sheet.id.toString() === param.sheetId) {
                                            setMoveTargetSheet(null);
                                        } else {
                                            setMoveTargetSheet(sheet);
                                        }
                                    }}
                                />
                                {(boardActionsMap[board.board.id] || []).map(_ => (<></>/* 仅为触发更新 */))}
                            </>
                        )
                    })
                }
                <HelperLines
                    ref={helperLinesRef}
                />

            </div>
        </div>
    )

}


/**
 * 移动目标板的预览
 */
const DroppableTargetSheet = memo((props: {
    layout: "horizontal" | "vertical",
    actionMap: BoardActionMap,
    sheet: NestSheet,
    horizontalMainSize: any,
    mainSize: any,
    scale: number;
    material: Material;
    horizontalScale: number;
    compatibleV1?: boolean;
}) => {
    const { actionMap: boardActionsMap, layout, sheet: moveTargetSheet, scale, horizontalScale, horizontalMainSize, mainSize, material } = props;

    return (
        <div className="absolute mvoe-target-sheet" style={{ zIndex: 9, background: "#000", ...(layout === 'horizontal' ? horizontalMainSize : mainSize), left: -1, top: -1, border: "1px  solid #1668dc", boxSizing: 'content-box' }}>
            {
                moveTargetSheet.boards.map((board, index) => {
                    // const refs = new Array(moveTargetSheet.boards.length).fill(null);
                    // const refs = new Array(sheet.boards.length).fill(null)
                    const actions = boardActionsMap[board.board.id] || [];
                    const boardRotateActions = actions.filter((a) => a.type === 'rotate');
                    let rotate = false;
                    if (boardRotateActions.length > 0) {
                        rotate = (boardRotateActions[boardRotateActions.length - 1] as BoardRotateAction).rotate;
                    }
                    const moveRotateActions = actions.filter((a) => a.type === 'move') as BoardMoveAction[];
                    let deltaX = 0;
                    let deltaY = 0;
                    moveRotateActions.forEach((a) => {
                        deltaX += a.deltaX;
                        deltaY += a.deltaY;
                    });
                    let isMovedToOtherSheet = false;
                    const boardMoveToSheetActions = actions.filter((a) => a.type === 'move-to-sheet') as BoardMoveToSheetAction[];
                    if (boardMoveToSheetActions.length > 0) {
                        const lastMove = boardMoveToSheetActions[boardMoveToSheetActions.length - 1];
                        if (lastMove.targetSheetId !== moveTargetSheet.id) {
                            isMovedToOtherSheet = true;
                        }
                    }
                    return (
                        <>
                            <MoveTargetBoard
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    [layout === 'vertical' ? 'left' : 'bottom']: (layout === 'vertical' ? scale : horizontalScale) * (board.customX + deltaX),
                                    [layout === 'vertical' ? 'top' : 'left']: (layout === 'vertical' ? scale : horizontalScale) * (board.customY + deltaY)
                                }}
                                board={board}
                                scale={scale}
                                layout={layout}
                                rotate={rotate}
                                horizontalScale={horizontalScale}
                                material={material}
                                compatibleV1={props.compatibleV1}
                            />
                        </>
                    )
                })
            }
        </div>

    )
});

/**
 * 辅助线helperlines
 */

const HelperLines = memo(forwardRef((props: {}, ref: any) => {
    const [helperLines, setHelperLines] = useState<{ x?: number, y?: number }[]>([]);

    useImperativeHandle(ref, () => {
        return {
            setHelperLines
        }
    });

    return (
        <>
            {
                helperLines.map((line) => {
                    let lineX = (line as { x: number }).x;
                    let lineY = (line as { y: number }).y;
                    // const lineWidth = space / 2 * (layout === 'horizontal' ? horizontalScale : scale);
                    const lineWidth = 1;
                    return lineX ? (
                        <div className="helper-line x absolute" style={{
                            zIndex: 99, top: 0, height: "100%",
                            left: lineX - lineWidth / 2,
                            width: lineWidth
                        }} />
                    ) : (

                        <div className="helper-line y absolute" style={{
                            zIndex: 99, left: 0, width: "100%",
                            top: (lineY - lineWidth / 2),
                            height: lineWidth
                        }} />
                        // <div className="absolute" style={{zIndex: 99, left: 0, top: lineY - 1, borderTop: '3px solid green', height: 3, width: "100%"}}/>
                    )
                })
            }
        </>
    )
}))