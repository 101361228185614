import { Vector2d } from "konva/lib/types";
import { Vector2dLine } from "../types";

type Point = Vector2d;

/**
 * 计算多边形内的任一点到多边形的距离
 * @param point
 * @param polygon 
 * @returns 
 */
export function calcPointDistanceToPolygon(point: Point, polygon: Point[]): { top: number, left: number, bottom: number, right: number } {
    let top = Infinity;
    let left = Infinity;
    let bottom = Infinity;
    let right = Infinity;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = polygon[i].x, yi = polygon[i].y;
        let xj = polygon[j].x, yj = polygon[j].y;

        // Check for intersection with horizontal line
        if ((yi < point.y && yj >= point.y) || (yj < point.y && yi >= point.y)) {
            let x = xi + (point.y - yi) * (xj - xi) / (yj - yi);
            if (x < point.x) {
                left = Math.min(left, point.x - x);
            } else {
                right = Math.min(right, x - point.x);
            }
        }

        // Check for intersection with vertical line
        if ((xi < point.x && xj >= point.x) || (xj < point.x && xi >= point.x)) {
            let y = yi + (point.x - xi) * (yj - yi) / (xj - xi);
            if (y < point.y) {
                top = Math.min(top, point.y - y);
            } else if (y > point.y) {
                bottom = Math.min(bottom, y - point.y);
            }
        }
    }

    return { top, left, bottom, right };
}

/**
 * 计算多边形内的任一点到多边形的距离外，再计算多边形对应的边
 * @param point 
 * @param polygon 
 * @returns 
 */
export function findClosestIntersections(point: Point, polygon: Point[]): { top: number, left: number, bottom: number, right: number, topLine: Vector2dLine, leftLine: Vector2dLine, bottomLine: Vector2dLine, rightLine: Vector2dLine } {
    let top = Infinity;
    let left = Infinity;
    let bottom = Infinity;
    let right = Infinity;
    let topLine: Vector2dLine = null;
    let leftLine: Vector2dLine = null;
    let bottomLine: Vector2dLine = null;
    let rightLine: Vector2dLine = null;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = polygon[i].x, yi = polygon[i].y;
        let xj = polygon[j].x, yj = polygon[j].y;

        // Check for intersection with horizontal line
        if ((yi < point.y && yj >= point.y) || (yj < point.y && yi >= point.y)) {
            let x = xi + (point.y - yi) * (xj - xi) / (yj - yi);
            if (x < point.x && point.x - x < left) {
                left = point.x - x;
                leftLine = { from: polygon[i], to: polygon[j] };
            } else if (x > point.x && x - point.x < right) {
                right = x - point.x;
                rightLine = { from: polygon[i], to: polygon[j] };
            }
        }

        // Check for intersection with vertical line
        if ((xi < point.x && xj >= point.x) || (xj < point.x && xi >= point.x)) {
            let y = yi + (point.x - xi) * (yj - yi) / (xj - xi);
            if (y < point.y && point.y - y < top) {
                top = point.y - y;
                topLine = { from: polygon[i], to: polygon[j] };
            } else if (y > point.y && y - point.y < bottom) {
                bottom = y - point.y;
                bottomLine = { from: polygon[i], to: polygon[j] };
            }
        }
    }

    return { top, left, bottom, right, topLine, leftLine, bottomLine, rightLine };
}