import { HTMLAttributes, HtmlHTMLAttributes, MouseEvent, useEffect, useMemo, useState } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
    active?: boolean;
    disable?: boolean;
}
const BG_DEFAULT = "#f7f7f7";
const BG_HOVER = "#f0f0f0";
const BG_TOUCH = "#e7e7e7";
const BG_SELECTED = "#edf5fe";

export const TouchableDiv =  (props: Props) => {

    const [background, setBackground] = useState(BG_DEFAULT);
    const [opacity, setOpacity] = useState(0.2);
    const [cursor, setCursor] = useState("not-allowed");
    const [isOver, setIsOver] = useState(false);
    const [isDown, setIsDown] = useState(false);


    const onMouseOver = (e) => {
        setIsOver(true);
        props.onMouseOver && props.onMouseOver(e);
    }

    const onMouseLeave = (e) => {
        setIsOver(false);
        props.onMouseLeave && props.onMouseLeave(e);
    }

    const onMouseDown = (e) => {
        setIsDown(true);
        props.onMouseDown && props.onMouseDown(e);
    }

    const onMouseUp = (e) => {
        setIsDown(false);
        props.onMouseUp && props.onMouseUp(e);
    }

    useEffect(() => {
        if(props.disable) {
            setBackground(BG_DEFAULT);
            setOpacity(0.2);
            setCursor("not-allowed");
            return ;
        }else {
            setOpacity(1);
            setCursor("pointer");
        }
        
        if(props.active) {
            setBackground(BG_SELECTED);
        }else {
            if(isDown) {
                setBackground(BG_TOUCH);
            }else if(!isOver){
                setBackground(BG_DEFAULT);
            }else{
                setBackground(BG_HOVER);
            }
        }
    }, [props.disable, props.active, isOver, isDown]);

    return useMemo(() => (
        <div 
            onMouseOver={onMouseOver} 
            onMouseDown={onMouseDown} 
            onMouseUp={onMouseUp} 
            onMouseLeave={onMouseLeave}
            onClick={props.disable ? undefined : props.onClick}
            {...props} 
            style={{background, cursor, opacity,  ...(props.style || {})}} 
        />
    ), [props, background, cursor, opacity])
}