import { useEffect, useState } from "react";
import { RouterProps, useNavigate, useOutletContext } from "react-router-dom";
import { NestRequest, Material } from "../../../../../@interface";
import { NestSheetView } from "../../../components/sheet-view";

export const DisplayAll = (props: {
    data?: NestRequest,
    drawer?: boolean
}) => {

    let { data} = useOutletContext<{ data: NestRequest }>();
    // console.log(data);
    if(props.data){
        data = props.data
    }
    // const [data] = useState(context.data);
    const [scale] = useState((window.innerHeight / 2.4) / 2440);
    const [materialMap, setMaterialMap] = useState<Map<string | number, Material>>(null);

    

    useEffect(() => {
        const map = new Map<string | number, Material>();
        data.materials.forEach((m) => {
            map.set(m.id, m);
        });
        setMaterialMap(map);
    }, []);

    const navigate = useNavigate();

    return (
        <div className="display-all no-scroll-bar">
            {
                materialMap && data.nestResult?.length > 0 && data.nestResult.map((materialNest) => {
                    // const 
                    const material = data.materials.find((m) => m.id === materialNest.materialId);
                    return (
                        <>
                            {
                                materialNest.sheets.map((sheet, sheetIndex) => {
                                    return (
                                        <div className="inline-block" style={{ marginRight: sheetIndex === materialNest.sheets.length - 1 ? 12 : 0 }}>
                                            {
                                                sheetIndex === 0 ? (
                                                    <div className="bold color-primary" style={{ height: 30, borderBottom: '2px solid orange'/* writingMode: "vertical-lr", width:30, verticalAlign:"top" */ }}>{material.name}</div>
                                                ) : (
                                                    <div style={{ height: 30, borderBottom: '2px solid orange' }}></div>
                                                )
                                            }
                                            <div className="sheet-wrapper" onClick={() => {
                                                navigate(`/nest/result/${materialNest.materialId}/${sheet.id}`, {
                                                    state: { data },
                                                    replace: true
                                                });
                                            }} style={{ cursor: "pointer", margin: '10px 0 24px 0', padding: `0 ${sheetIndex === materialNest.sheets.length - 1 ? '0px' : '8px'} 0 ${sheetIndex === 0 ? '0px' : '8px'}`, fontSize: 0 }}>
                                                <div className="sheet">
                                                    <div className="sheet-header" style={{}}>
                                                        <span>大板{sheetIndex + 1}/{materialNest.sheets.length}</span>
                                                        <span style={{ fontSize: 12 }}>

                                                        </span>
                                                    </div>
                                                    <NestSheetView
                                                        displayWidth={sheet.width * scale}
                                                        sheet={sheet}
                                                        materialMap={materialMap}
                                                        compatibleV1={data?.compatibleV1}
                                                    />

                                                    <div className="sheet-footer" style={{}}>
                                                        {sheet.boards.length}块小板
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                })
            }
        </div>
    )
}