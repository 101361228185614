import { forwardRef, useState } from "react";
import { CupboardBroad, NestSheet, Material } from "../../../@interface";
import { RuleStage } from "./rule-stage";
import { Group, Layer, Line, Stage } from "react-konva";
import { RectWithAngles, RectWithAnglesProps } from "./graph/board";

export const NestSheetView = forwardRef((props: {
    compatibleV1?: boolean;
    sheet: NestSheet;
    displayWidth?: number;
    editable?: boolean;
    materialMap: Map<number | string, Material>;
    onSheetBoardChange?: (sheet: NestSheet, board: any) => void;
    onFocusBoard?: (board: CupboardBroad) => void;
    onLeaveBoard?: () => void;
    onDeleteRect?: (sheet: NestSheet, board: any) => void;
}, ref: any) => {

    const { sheet } = props;
    const [scale] = useState(() => {
        if (props.editable) {
            return (window.innerHeight - 45 - 24) / props.sheet.height;
        } else {
            return ((props.displayWidth || 120) / props.sheet.width);
        }
    });
    const [draggingBoard, setDraggingBoard] = useState(null);



    return props.editable ? (
        <RuleStage
            height={window.innerHeight - 45}
            width={scale * props.sheet.width + 48}
            zoomable
            ruleScale={scale}
            draggable
        >
            <Layer>
                <Group>

                    {
                        sheet.boards.map((board, index) => {
                            const material = props.materialMap.get(board.board.materialId);
                            return (
                                <EditableRectWithAngles
                                    key={"edit-" + index}
                                    x={board.customX * scale}
                                    y={board.customY * scale}
                                    scale={scale}
                                    text={index + ""}
                                    board={board.board}
                                    rotate={board.rotate}
                                    material={material}
                                    onRectPositionChange={(deltaX: number, deltaY: number) => {
                                        props.onSheetBoardChange(sheet, { $$index: index, $deltaX: deltaX / scale, $deltaY: deltaY / scale });
                                    }}
                                    onRectRotateChange={() => {
                                        const isRotate = !board.rotate;
                                        props.onSheetBoardChange(sheet, {
                                            $$index: index,
                                            // rotate: rotated ? !board.rotate : board.rotate, 
                                            rotate: isRotate,
                                            width: isRotate ? board.height : board.width,
                                            height: isRotate ? board.width : board.height
                                        });
                                    }}
                                    onFocusBoard={props.onFocusBoard}
                                    onLeaveBoard={props.onLeaveBoard}
                                    compatibleV1={props.compatibleV1}
                                    onDeleteRect={() => {
                                        props.onDeleteRect(sheet, { ...board, $$index: index });
                                    }}
                                    draggingBoard={draggingBoard}
                                    onDraggingBoard={(board) => {
                                        setDraggingBoard(board);
                                    }}
                                />
                            )
                        })
                    }
                    <Line
                        x={0}
                        y={0}
                        points={[0, 0, props.sheet.width * scale, 0]}
                        strokeWidth={0.5}
                        stroke="#f00"
                    />
                    <Line
                        x={0}
                        y={0}
                        points={[props.sheet.width * scale, 0, props.sheet.width * scale, props.sheet.height * scale]}
                        strokeWidth={0.5}
                        stroke="#f00"
                    />
                    <Line
                        x={0}
                        y={0}
                        points={[0, 0, 0, props.sheet.height * scale]}
                        strokeWidth={0.5}
                        stroke="#f00"
                    />
                    <Line
                        x={0}
                        y={0}
                        points={[0, props.sheet.height * scale, props.sheet.width * scale, props.sheet.height * scale]}
                        strokeWidth={0.5}
                        stroke="#f00"
                    />
                </Group>
            </Layer>
        </RuleStage>
    ) : (
        // <Stage
        //     height={sheet.height * props.scale}
        //     width={sheet.width * props.scale}
        //     style={{ display: 'inline-block', background: "#000", }}
        // >
        <Stage
            height={sheet.height * scale}
            width={sheet.width * scale}
            style={{ display: 'inline-block', background: "#000", }}
        >
            <Layer>
                <Group>

                    {
                        sheet.boards.map((board, index) => {
                            const material = props.materialMap.get(board.board.materialId);
                            return (

                                <RectWithAngles
                                    key={"uneditable-" + index}
                                    x={board.customX * scale}
                                    y={board.customY * scale}
                                    scale={scale}
                                    board={board.board}
                                    material={material}
                                    text={index + ""}
                                    rotate={board.rotate}
                                    fill
                                    background="#000"
                                    color="gray"
                                    compatibleV1={props.compatibleV1}
                                />
                            )
                        })
                    }

                </Group>
            </Layer>
        </Stage>
    )

})



const EditableRectWithAngles = (props: RectWithAnglesProps & {
    onRectPositionChange: (deltaX: number, deltaY: number) => void;
    onRectRotateChange: () => void;
    onFocusBoard?: (board: CupboardBroad) => void;
    onLeaveBoard?: () => void;
    onDeleteRect?: (board: CupboardBroad) => void;
    draggingBoard?: CupboardBroad;
    onDraggingBoard?: (board: CupboardBroad) => void;
}) => {

    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [moving, setMoving] = useState(false);
    const [focus, setFocus] = useState(false);
    const [hover, setHover] = useState(false);
    // const [rotate, setRotate] = useState(false);

    // useEffect(() => {

    // }, [props.x]);

    // useEffect(() => {
    //     setPos({x: pos.x, y: props.y || 0});
    // }, [props.y]);

    // useEffect(() => {
    //     props.onRectPositionChange(pos.x, pos.y);
    // }, [pos]);
    const [dragging, setDragging] = useState(false);

    return (
        <Group
            // x={pos.x}
            // y={pos.y}

            // onClick={() => {

            //     console.log("onClick");
            //     if (focus) {
            //         setFocus(false);
            //     } else {
            //         setFocus(true);
            //     }
            // }}

            onContextMenu={(e) => {
                console.log("onContextMenu");
                e.evt.stopPropagation();
                e.evt.preventDefault();
                props.onDeleteRect(props.board);

            }}
            onDblClick={(e) => {
                console.log("onDblClick");
                e.evt.stopPropagation();
                e.evt.preventDefault();
                if (e.evt.button === 0 /* left */) {
                    // const rotated = !rotate;
                    // props.onRectRotateChange(rotated);
                    // setRotate(rotated);

                    props.onRectRotateChange();
                }
            }}
            onMouseEnter={() => {
                if (props.draggingBoard) {
                    return;
                }
                // console.log("onMouseEnter");
                setHover(true);
                props.onFocusBoard && props.onFocusBoard(props.board);
            }}
            onMouseLeave={() => {
                if (props.draggingBoard) {
                    return;
                }
                // console.log("onMouseLeave");
                setHover(false);
                props.onLeaveBoard && props.onLeaveBoard();
            }}
            draggable
            dragBoundFunc={(pos) => {
                // console.log(pos)
                return { x: 0, y: 0 }
            }}
            onDragStart={(e) => {
                // console.log("onDragStart");
                e.evt.stopPropagation();
                e.evt.preventDefault();
                setMoving(true);
                setFocus(false);
            }}
            onDragMove={(e) => {
                // console.log("onDragMove");
                e.evt.stopPropagation();
                e.evt.preventDefault();
                props.onRectPositionChange(e.evt.movementX, e.evt.movementY);
            }}
            onDragEnd={(e) => {
                // console.log("onDragEnd");
                e.evt.stopPropagation();
                e.evt.preventDefault();
                // const newPos = { x: Math.floor(e.target.x()), y: Math.floor(e.target.y()) };
                // props.onRectPositionChange(newPos.x - pos.x, newPos.y - pos.y);
                // setPos(newPos);
                setMoving(false);
            }}
        >
            <RectWithAngles {...props} material={undefined}/*  rotate={rotate ? !props.rotate : props.rotate} */ rotate={props.rotate} color="#fff" />
            {
                (hover) && (

                    <RectWithAngles {...props} /* rotate={rotate ? !props.rotate : props.rotate} */ rotate={props.rotate}
                        fill
                        material={props.compatibleV1 ? undefined : props.material}
                        background="#000"
                        color="#0f0"
                    />
                )
            }
        </Group>
    )
}
