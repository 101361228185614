import { Arc, Line, Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner7 } from "../../coners/corner7";
import { rectBoard } from "../general-board";
import { menneiBoard } from "../地柜/01-mennei";

export const _09menneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    nolashou: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
): [Path[], Path[]] => {


    return menneiBoard(dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, nolashou, menminglashou, menlashouX, menlashouY, menlashoubianju, menlashoufangxiang, doorSide, gxinglashou, gxinglashouType, gxinglashouGaodu);
}