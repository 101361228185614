import styled from "styled-components";

import pkg from '../../../../package.json';

export default function Logo() {

    return (
        <Wrapper>
            <img className="logo-image" src="./favicon.ico" alt="logo" width={16} height={16} />
            <span className="logo-text">{pkg['cn-name']}</span>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 16px 0 10px;
    .logo-text {
        color: gray;
        font-size: 14px;
        margin-left: 6px;
    }
`;