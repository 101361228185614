import { DirectX, DirectY } from "../../../constants/enum";

import { ceneiBoardRight } from "../地柜/01-cenei-right";

export const _04ceneiBoardRight = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return ceneiBoardRight(dx, dy, startX, startY, width, height, lianjiekongbianju, qiandanghengfang, extraConfig);
 }