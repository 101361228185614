import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { corner12Sanheyi } from "./corner12-sanheyi";

export const corner15Sanheyi = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner12Sanheyi(dx, dy, startX, startY, true, size);
    paths = paths.slice(0, paths.length - 11);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, size.width);
    paths = pathMoveY(paths, DirectY.DOWN, 43.49);
    if(typeof sizeRes === 'object') {
    //     if(!corner) {
    //     sizeRes.width = 0;
    //     sizeRes.height = 0;
    // }else {
            sizeRes.width = 77.488293;
            sizeRes.height = 34.000335;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}