import { DirectX, DirectY } from "../../../constants/enum";
import { menneiBoard } from "../地柜/01-mennei"

export const _11dineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
): [Path[], Path[]] => {
    return menneiBoard(dx, dy, startX, startY, width, height, true, -1);
}