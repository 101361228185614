import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { _05beineiBoard } from "./05-beinei";

export const _06beineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nobeiban: boolean
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _05beineiBoard(dx, dy, startX, startY, width, height, nobeiban);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]
 }