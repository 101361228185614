import { _04beineiBoard } from "../../boards/三门柜/04-beinei";
import { _04beiwaiBoard } from "../../boards/三门柜/04-beiwai";
import { _04ceneiBoardLeft } from "../../boards/三门柜/04-cenei-left";
import { _04ceneiBoardRight } from "../../boards/三门柜/04-cenei-right";
import { _04cewaiBoardLeft } from "../../boards/三门柜/04-cewai-left";
import { _04cewaiBoardRight } from "../../boards/三门柜/04-cewai-right";
import { _04dineiBoard } from "../../boards/三门柜/04-dinei";
import { _04diwaiBoard } from "../../boards/三门柜/04-diwai";
import { _04gecengneiBoard } from "../../boards/三门柜/04-gecengnei";
import { _04gecengwaiBoard } from "../../boards/三门柜/04-gecengwai";
import { _04gongyongceneiBoard } from "../../boards/三门柜/04-gongyongcenei";
import { _04gongyongcewaiBoard } from "../../boards/三门柜/04-gongyongcewai";
import { _04menneiBoard } from "../../boards/三门柜/04-mennei";
import { _04menwaiBoard } from "../../boards/三门柜/04-menwai";
import { _04qiandangneiBoard } from "../../boards/三门柜/04-qiandangnei";
import { _04qiandangwaiBoard } from "../../boards/三门柜/04-qiandangwai";
import { LeftSanmenguiCebaobei } from "./leftSanmenguiCebaobei";

export class RightSanmenguiCebaobei extends LeftSanmenguiCebaobei {
    key: string = "RightSanmenguiCebaobei";

    setNameMap() {
        return {
            dineiBoard: _04dineiBoard,
            diwaiBoard: _04diwaiBoard,
            ceneiBoardLeft: _04ceneiBoardLeft,
            ceneiBoardRight: _04ceneiBoardRight,
            cewaiBoardLeft: _04cewaiBoardLeft,
            cewaiBoardRight: _04cewaiBoardRight,
            gongyongceneiBoard: _04gongyongceneiBoard,
            gongyongcewaiBoard: _04gongyongcewaiBoard,
            beiwaiBoard: _04beiwaiBoard,
            beineiBoard: _04beineiBoard,
            menwaiBoard: _04menwaiBoard,
            menneiBoard: _04menneiBoard,
            "menwaiBoard-left": _04menwaiBoard,
            "menneiBoard-left": _04menneiBoard,
            "menwaiBoard-right": _04menwaiBoard,
            "menneiBoard-right": _04menneiBoard,
            menneiBoard2: _04menneiBoard,
            menwaiBoard2: _04menwaiBoard,
            qiandangneiBoard: _04qiandangneiBoard,
            qiandangwaiBoard: _04qiandangwaiBoard,
            gecengneiBoard1: _04gecengneiBoard,
            gecengwaiBoard1: _04gecengwaiBoard,
            gecengneiBoard2: _04gecengneiBoard,
            gecengwaiBoard2: _04gecengwaiBoard,
        }
    }

}