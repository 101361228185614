import { sanheyi02CeneiBoardLeft } from "../../../boards/吊柜/sanheyi/sanheyi-02-cenei-left";
import { sanheyi02CewaiBoardLeft } from "../../../boards/吊柜/sanheyi/sanheyi-02-cewai-left";
import { sanheyi02DineiBoard } from "../../../boards/吊柜/sanheyi/sanheyi-02-dinei";
import { sanheyi02DingneiBoard } from "../../../boards/吊柜/sanheyi/sanheyi-02-dingnei";
import { sanheyi02DingwaiBoard } from "../../../boards/吊柜/sanheyi/sanheyi-02-dingwai";
import { sanheyi02DiwaiBoard } from "../../../boards/吊柜/sanheyi/sanheyi-02-diwai";
import { BaseCupboard } from "../../@base/BaseCupboard";

export class SanheyiDiaoguiCebaobei extends BaseCupboard {
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: sanheyi02DineiBoard,
            diwaiBoard: sanheyi02DiwaiBoard,
            dingneiBoard: sanheyi02DingneiBoard,
            dingwaiBoard: sanheyi02DingwaiBoard,
            ceneiBoardLeft: sanheyi02CeneiBoardLeft,
            // ceneiBoardRight: _02ceneiBoardRight,
            cewaiBoardLeft: sanheyi02CewaiBoardLeft,
            // cewaiBoardRight: _02cewaiBoardRight,
            // beiwaiBoard: _02beiwaiBoard,
            // beineiBoard: _02beineiBoard,
            // menwaiBoard: _02menwaiBoard,
            // menneiBoard: _02menneiBoard,
            // "menwaiBoard-left": _02menwaiBoard,
            // "menneiBoard-left": _02menneiBoard,
            // "menwaiBoard-right": _02menwaiBoard,
            // "menneiBoard-right": _02menneiBoard,
            // gecengneiBoard: _02gecengneiBoard,
            // gecengwaiBoard: _02gecengwaiBoard
        };
    }
    // setTypeMap(): { [boardName: string]: BoardTypeKey; } {
    //     return {
    //         dineiBoard: "guinei", 
    //         diwaiBoard: "guiwai", 
    //         dingneiBoard:  "guinei", 
    //         dingwaiBoard:   "guiwai",
    //         ceneiBoardLeft: "guinei",
    //         ceneiBoardRight: "guiwai",
    //         cewaiBoardLeft: "guiwai",
    //         cewaiBoardRight: "guiwai",
    //         beiwaiBoard:  "beiwai",  
    //         beineiBoard:  "beinei",
    //         menwaiBoard:   "menwai",
    //         menneiBoard:   "mennei", 
    //         gecengneiBoard:"guinei", 
    //         gecengwaiBoard: "guiwai"
    //     }
    // }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 11, deep + 47],
            },
            "dineiBoard": {
                size: [long - 15, deep + 21],
            },
            "dingwaiBoard": {
                size: [long + 11, deep + 47],
            },
            "dingneiBoard": {
                size: [long - 15, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [ deep + 47, height + 47],
            },
        //     "cewaiBoardRight": {
        //         size: [ deep + 47, height + 47],
        //     },
            "ceneiBoardLeft": {
                size: [ deep + 21, height + 21],
            },
        //     "ceneiBoardRight": {
        //         size: [ deep + 21, height + 21],
        //     },
        //     "beiwaiBoard": {
        //         size: [long + 11, height + 11]
        //     },
        //     "beineiBoard": {
        //         size: [long - 15, height - 15]
        //     },
        };
        // if(extraConfig.doorNumber === 2) {
            
        //     list["menneiBoard-left"] = {
        //         size: [long / 2 - 2 + 21, height + 19],
        //         quantity: 1
        //     };
        //     list["menwaiBoard-left"] = {
        //         size: [long / 2 - 2 + 47, height + 45],
        //         quantity: 1,
        //     };
        //     list["menneiBoard-right"] = {
        //         size: [long / 2 - 2 + 21, height + 19],
        //         quantity: 1
        //     };
        //     list["menwaiBoard-right"] = {
        //         size: [long / 2 - 2 + 47, height + 45],
        //         quantity: 1,
        //     };
        // }else {
        //     list["menneiBoard"] = {
        //         size: [long - 2 + 21, height + 19],
        //         quantity: 1
        //     };
        //     list["menwaiBoard"] = {
        //         size: [long - 2 + 47, height + 45],
        //         quantity: 1,
        //     }
        // }
        
        // if(extraConfig.geceng) {
        //     list['gecengwaiBoard'] = {
        //         size: [long + 11, deep - extraConfig.gecengX + 29],
        //     };
        //     list['gecengneiBoard'] = {
        //         size: [long - 15, deep - extraConfig.gecengX + 3]
        //     };
        // }
        // if(extraConfig.nobeinei) {
        //     delete list["beineiBoard"];
        // }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'beineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.diaokongbianju);
        } else if(board.name === "beiwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.diaokongbianju, extraConfig.waibanjiao/* 内板没有其他参数，多一个没事 */);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            if(extraConfig.doorNumber === 2) {
                temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY);                    
            }else {
                if(board.name.toLowerCase().includes(extraConfig.doorSide)) {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY);
                }else {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, -1, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY);
                }
            }
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju);
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.jiaolianbianju,extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang,  doorSide);
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao)
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }



}
