import { useEffect, useRef, useState } from "react";
import { request } from "../../../utils2/request";
import { apis } from "../../../utils2/apis";
import { Alert, Button, Input, Popover, Skeleton, Space, Spin, Table, Tabs } from "antd";
import { PageContainer, PageHeader } from "@ant-design/pro-layout";
import { CheckOutlined, QuestionCircleFilled } from "@ant-design/icons";


export const PartCostSettingsPage = () => {

    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        request.post(apis.costSettings + `/part/update-from-order`)
            .then((res) => {
                if (res.code === 1) {
                    setData(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const updatePartFromOrder = () => {
        setRefreshing(true);
        request.post(apis.costSettings + `/part/update-from-order`)
            .then((res) => {
                if (res.code === 1) {
                    setData(res.data);
                }
            })
            .finally(() => {
                setRefreshing(false);
            })
    }

    const [editPartKey, setEditPartKey] = useState("");
    const [editItem, setEditItem] = useState<any>(null);
    const [editPrice, setEditPrice] = useState("");
    const [editError, setEditError] = useState("");
    const [editSubmitting, setSubmitting] = useState(false);

    const debounceTimer = useRef<any>(null);

    const startPriceEdit = (key: string, item: any, priceTemp: string) => {
        setEditPrice(priceTemp);
        setEditError("");
        setEditItem(item);
        setEditPartKey(key);
    }

    const submitPriceEdit = () => {

        const price = Number(editPrice);
        if (!price || isNaN(price)) {
            setEditError("请输入正确的价格");
            return;
        }
        if (price < 0) {
            setEditError("价格不能小于0");
            return;
        }
        if (editSubmitting) {
            return;
        }
        setSubmitting(true);
        request.post(apis.costSettings + "/part/update-item", {
            name: editItem.name,
            mode: editItem.mode,
            brand: editItem.brand,
            size: editItem.size,
            unit: editItem.unit,
            isStandard: editItem.isStandard,
            order: editItem.order,
            price: price * 100
        })
            .then((res) => {
                if (res.code === 1) {
                    setData(data.map((item) => {
                        const itemKey = `${item.name}-${item.mode}-${item.brand}-${item.size}-${item.unit}`
                        if (editPartKey === itemKey) {
                            return {
                                ...item,
                                price: price * 100,
                                displayPrice: editPrice
                            }
                        }
                        return item;
                    }));
                    onEditCancel();
                } else {
                    setEditError(res.msg);
                }
            }).catch(e => {
                setEditError("网络错误，请稍后再试")
            }).finally(() => {
                setSubmitting(false);
            })

    }

    const onEditCancel = () => {
        setEditPartKey("");
        setEditPrice("");
        setEditItem(null);
    }

    const priceColumnRender = (_, record: any) => {
        const key = `${record.name}-${record.mode}-${record.brand}-${record.size}-${record.unit}`;
        const displayPrice = record.displayPrice;
        return editPartKey === key ? (
            <div>
                <div>
                    <Input autoFocus onPressEnter={() => {
                        submitPriceEdit();
                    }} style={{ width: 100 }} placeholder="请输入价格" value={editPrice} onChange={(e) => {
                        setEditPrice(e.target.value);
                    }} />
                    {
                        !editError && (
                            <Space style={{ marginLeft: 10 }}>
                                <Button size="small" type="link" onClick={() => {
                                    submitPriceEdit();
                                }}>确认</Button>
                                <Button size="small" type="link" onClick={() => {
                                    setEditPartKey("");
                                    setEditPrice("");
                                }}>取消</Button>
                            </Space>
                        )

                    }  {
                        editError && (
                            <span style={{ color: "red" }}>{editError}</span>
                        )
                    }
                </div>

            </div>
        ) : (
            <>

                <span>{displayPrice}</span>
                <Space style={{ marginLeft: 10 }}>
                    <Button disabled={key === editPartKey} type="link" onClick={() => {
                        // setEditPartKey(key);
                        // if (record.price) {
                        //     setEditPrice(record.displayPrice);
                        // } else {
                        //     setEditPrice("");
                        // }
                        startPriceEdit(key, record, record.price ? (record.price / 100).toString() : "");
                    }}>{
                            key === editPartKey ? "编辑中" : "编辑"

                        }</Button>
                </Space>
            </>
        )
    }

    /**
     * @deprecated
     */
    const debounceUpdatePrice = (name: string, mode: string, brand: string, size: string, unit: string, priceValue: string, order: number) => {

        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        setEditPrice(priceValue);
        setEditError("");
        // setSubmitSuccess(false);
        const price = Number(priceValue);
        if (!price || isNaN(price)) {
            setEditError("请输入正确的价格");
            return;
        }
        if (price < 0) {
            setEditError("价格不能小于0");
            return;
        }
        // setSubmitting(true);
        debounceTimer.current = setTimeout(() => {

        }, 300);
    }

    const [displayNormalParts, setNormalParts] = useState<any[]>([]);
    const [displayNonStandardParts, setNonStandardParts] = useState<any[]>([]);

    useEffect(() => {
        setNormalParts(data.filter((item) => {
            return item.isStandard;
        }));
        setNonStandardParts(data.filter((item) => {
            return !item.isStandard;
        }));
    }, [data])

    return (
        <Spin spinning={refreshing}>
            <PageHeader
                title={"零配件成本设置"}

                extra={(
                    <Space>
                        <Button loading={refreshing} disabled={refreshing} type="link" onClick={() => {
                            updatePartFromOrder();
                        }}>
                            自动更新零配件
                            <Popover content="从云端获取新的零配件">
                                <QuestionCircleFilled />
                            </Popover>
                        </Button>
                    </Space>
                )}
            >
                
                <Alert message="导出Excel时，根据设置的价格计算成本表" type="info" style={{marginBottom: 24}}/>
                <div style={{ background: "#fff", padding: 12 }}>

                    {
                        loading ? (
                            <Skeleton active />
                        ) : (
                            <Tabs>
                                <Tabs.TabPane tab="常规零配件" key={"1"}>
                                    <Table
                                        loading={loading}
                                        dataSource={displayNormalParts}
                                        size="small"
                                        pagination={false}
                                        columns={[
                                            {
                                                title: "#",
                                                key: "index",
                                                width: 50,
                                                render: (text: any, record: any, index: number) => {
                                                    return index + 1;
                                                }
                                            }, {
                                                title: "零配件名称",
                                                width: 150,
                                                key: "name",
                                                dataIndex: "name"
                                            },
                                            {
                                                title: "型号",
                                                width: 150,
                                                key: "mode",
                                                dataIndex: "mode"
                                            },
                                            {
                                                title: "品牌",
                                                width: 100,
                                                key: "brand",
                                                dataIndex: "brand"
                                            },
                                            {
                                                title: "尺寸",
                                                width: 200,
                                                key: "size",
                                                dataIndex: "size"
                                            },
                                            {
                                                title: "单位",
                                                width: 100,
                                                key: "unit",
                                                dataIndex: "unit"
                                            },
                                            {
                                                title: "成本（元/单位）",
                                                width: 280,
                                                key: "price",
                                                dataIndex: "price",
                                                render: priceColumnRender,
                                            }
                                        ]}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="非标零配件" key={"2"}>
                                    <Table
                                        loading={loading}
                                        dataSource={displayNonStandardParts}
                                        size="small"
                                        pagination={false}
                                        columns={[
                                            {
                                                title: "#",
                                                key: "index",
                                                width: 50,
                                                render: (text: any, record: any, index: number) => {
                                                    return index + 1;
                                                }
                                            }, {
                                                title: "零配件名称",
                                                width: 150,
                                                key: "name",
                                                dataIndex: "name"
                                            },
                                            {
                                                title: "型号",
                                                width: 150,
                                                key: "mode",
                                                dataIndex: "mode"
                                            },
                                            {
                                                title: "品牌",
                                                width: 100,
                                                key: "brand",
                                                dataIndex: "brand"
                                            },
                                            {
                                                title: "尺寸",
                                                width: 200,
                                                key: "size",
                                                dataIndex: "size"
                                            },
                                            {
                                                title: "单位",
                                                width: 100,
                                                key: "unit",
                                                dataIndex: "unit"
                                            },
                                            {
                                                title: "成本（元/尺寸）",
                                                width: 280,
                                                key: "price",
                                                dataIndex: "price",
                                                render: priceColumnRender,
                                            }
                                        ]}
                                    />

                                </Tabs.TabPane>
                            </Tabs>

                        )
                    }

                </div>
                <div style={{ textAlign: 'center', padding: '50px 0', fontSize: 12, color: "gray" }}>到底了~</div>

            </PageHeader>


        </Spin>
    )
}

