import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05beiwaiBoard } from "./05-beiwai";
import { _05fengwaiBoard } from "./05-fengwai";
import { _05liwaiBoard } from "./05-liwai";

export const _07liwaiBoard = (
   cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    corner: boolean,
    mengao: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return _05liwaiBoard(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, corner, mengao, gxinglashou, gxinglashouType, gxinglashouGaodu, extraConfig);
 }