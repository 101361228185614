import { useEffect, useRef, useState } from "react"
import { request } from "../../../utils2/request";
import { Button, Divider, Dropdown, Space, message, theme } from "antd";
import { apis } from "../../../utils2/apis";
import { SheetCreateModal } from "../../../components/sheet-create-modal";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";

const { useToken } = theme;

export const SheetSelect = (props: {
    materialName: string,
    taskRunning: boolean,
    onMaterialNestSettingsChange: (settings: any, key: string, value: string) => void,
    settings: any
}) => {

    const { taskRunning, onMaterialNestSettingsChange, settings } = props;

    const [sheetList, setSheetList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState<"input" | "select">();

    useEffect(() => {
        setLoading(true)
        request.get(apis.sheet + "/list")
            .then((res) => {
                if (res.code === 1) {
                    const materialSheets = res.data.filter((item) => item.materialName === props.materialName);
                    if(materialSheets.length === 0) {
                        setMode("input");
                    }else {
                        setSheetList(materialSheets);
                        setMode("select");

                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []); 

    console.log("mode", mode)

    // console.log("sheetList", sheetList)

    const [showOptions, setShowOptions] = useState(false);

    const [checkSheet, setCheckSheet] = useState(false);

    useEffect(() => {
        if (loading) {
            //等待加载初始数据
            return;
        }
        if(mode !== 'select') {
            //防止2d模式下找不到对应的板材，则不进行自动建板操作
            return ;
        }
        if(checkSheet) {
            //保证只执行一次
            return ;
        }
        if (!settings?.sheetHeight || !settings?.sheetWidth) {
            return;
        }
        //加载结束查找是否有对应尺寸的板材，没有则创建
        const sheet = sheetList.find((sheet) => `${sheet.length}x${sheet.width}` === `${settings.sheetHeight}x${settings.sheetWidth}`);
        if (!sheet) {
            request.post(apis.sheet + '/add/multiple', {
                baseOn: "size",
                size: {
                    width: Math.min(settings.sheetWidth, settings.sheetHeight),
                    length: Math.max(settings.sheetWidth, settings.sheetHeight)
                }
            })
                .then((res) => {
                    if (res.code === 1) {
                        const newSheet = res.data.find((item) => item.materialName === props.materialName);
                        if(newSheet) {
                            setSheetList([...sheetList, newSheet]);

                        }else {

                        }
                    }
                })
                .catch((e) => {

                }).finally(() => {
                    
                    setCheckSheet(true);
                })
        } else {
            setCheckSheet(true);
        }
    }, [props.settings?.sheetWidth, props.settings?.sheetHeight, loading, checkSheet, mode]);

    const addRef = useRef<any>(null);

    const { token } = useToken();

    return mode !== 'select' ?  (
        <SheetSizeEditor 
            taskRunning={props.taskRunning} 
            settings={props.settings} 
            onMaterialNestSettingsChange={props.onMaterialNestSettingsChange}        
        />
    ) : (
        <>


            <Dropdown
                trigger={["click"]}
                open={showOptions}

                onOpenChange={(open) => {
                    setShowOptions(open);
                }}

                menu={{
                    selectable: true,
                    selectedKeys: [`${settings?.sheetHeight}x${settings?.sheetWidth}`],
                    items: sheetList.map((sheet) => {
                        return {
                            key: `${sheet.length}x${sheet.width}`,
                            label: `长${sheet.length}mm × ${sheet.width}mm`,
                            onClick: () => {
                                onMaterialNestSettingsChange(settings, "sheetWidth", (sheet.width).toString());
                                onMaterialNestSettingsChange(settings, "sheetHeight", (sheet.length).toString());
                                setShowOptions(false);
                            }
                        }
                    })
                }}
                dropdownRender={(menu) => (
                    <div style={{
                        backgroundColor: token.colorBgElevated,
                        borderRadius: token.borderRadiusLG,
                        boxShadow: token.boxShadowSecondary,
                    }}>
                        {React.cloneElement(menu as React.ReactElement, {
                            style: {
                                boxShadow: 'none',
                            }
                        })}
                        <Divider style={{ margin: 0 }} />
                        <div className="flex center">
                            <Button onClick={() => {
                                addRef.current?.open();
                            }} type="link"><PlusOutlined />添加尺寸</Button>

                        </div>
                    </div>
                )}

            >
                <span>
                    <span className={`${(!Number(settings?.sheetWidth) || Number(settings?.sheetWidth) < 0) ? 'nest-error' : ''}`}>
                        <span className={`${(!Number(settings?.sheetHeight) || Number(settings?.sheetHeight) < 0) ? 'nest-error' : ''}`}>
                            长:<input disabled={taskRunning} className={`nest-input`} value={settings?.sheetHeight}
                                onChange={(e) => {
                                    // onMaterialNestSettingsChange(settings, "sheetHeight", e.target.value);
                                }}
                                onClick={() => {
                                    setShowOptions(true);

                                }}
                            />mm
                        </span>
                        ×
                        宽:<input disabled={taskRunning} className={`nest-input`} value={settings?.sheetWidth}
                            onChange={(e) => {
                                // onMaterialNestSettingsChange(settings, "sheetWidth", e.target.value);
                            }}
                            onClick={() => {
                                setShowOptions(true);

                            }}
                        />mm
                    </span>

                </span>
            </Dropdown>
            <SheetCreateModal
                ref={addRef}
                onSheetsAdd={(newSheets) => {
                    const materialSheet = newSheets.find((sheet) => sheet.materialName === props.materialName);
                    if (materialSheet) {
                        setSheetList([...sheetList, materialSheet]);
                    }
                    //选择
                    onMaterialNestSettingsChange(settings, "sheetWidth", (materialSheet.width).toString());
                    onMaterialNestSettingsChange(settings, "sheetHeight", (materialSheet.length).toString());
                    setShowOptions(false);
                    message.info("已添加并设置")

                }}
            />
        </>
    )
}

export const SheetSizeEditor = (props: {
    taskRunning: boolean,
    settings: any,
    onMaterialNestSettingsChange: (settings: any, key: string, value: string) => void
}) => {
    const { settings, onMaterialNestSettingsChange } = props;
    return (
        <div>
            <span>
                长:<input disabled={props.taskRunning} className={`nest-input`} value={settings?.sheetHeight}
                    onChange={(e) => {
                        onMaterialNestSettingsChange(settings, "sheetHeight", e.target.value);
                    }} />mm
                ×
                宽:<input disabled={props.taskRunning} className={`nest-input`} value={settings?.sheetWidth}
                    onChange={(e) => {
                        onMaterialNestSettingsChange(settings, "sheetWidth", e.target.value);
                    }} />mm
            </span>
        </div>
    )
}