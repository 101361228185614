import { Vector2d } from "konva/lib/types";
import { useEffect, useState } from "react";

export const useWallBasicData = (brushPos: Vector2d, startAnchorPos: Vector2d, wallThickness: number) => {

    if (startAnchorPos) {
        let wallDirect: "x" | "y";
        let wallLength: number = 0;
        const deltaX = Math.abs(brushPos.x - startAnchorPos.x + wallThickness / 2);
        const deltaY = Math.abs(brushPos.y - startAnchorPos.y + wallThickness / 2);
        //移动大于一定范围
        if (deltaX > deltaY) {
            wallDirect = "x";
            if ((brushPos.x - startAnchorPos.x) > 0) {
                //->方向
                wallLength = (brushPos.x - startAnchorPos.x);
            } else {
                //<-方向
                wallLength = (brushPos.x - startAnchorPos.x)
            }
        } else {
            wallDirect = "y";
            if ((brushPos.y - startAnchorPos.y) > 0) {
                //↓方向
                wallLength = (brushPos.y - startAnchorPos.y);
            } else {
                //↑方向
                wallLength = (brushPos.y - startAnchorPos.y);
            }
        }
        return {
            wallDirect,
            wallLength
        }
    } else {
        return null;
    }

}

// export const useWallLength = (brush)

export const useNextAnchorPos = (brushPos: Vector2d, startAnchorPos: Vector2d, wallThickness: number) => {

    const [pos, setPos] = useState(brushPos);
    const fakeWallBasic = useWallBasicData(brushPos, startAnchorPos, wallThickness);

    useEffect(() => {
        let x = brushPos.x;
        let y = brushPos.y;
        if (fakeWallBasic && fakeWallBasic.wallLength !== 0) {
            if (fakeWallBasic.wallDirect === 'x') {
                y = startAnchorPos.y;
            } else {
                x = startAnchorPos.x;
            }
        }
        if (x !== pos.x || y !== pos.y) {
            setPos({ x, y });
        }
    }, [brushPos, fakeWallBasic]);

    return pos;

}

