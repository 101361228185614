// import { _11iiCeneiBoardMiddle } from "../../boards/yangtaigui/11-ii-cenei";
// import { _11iiCewaiBoardMiddle } from "../../boards/yangtaigui/11-II-cewai";
import { _11iiCeneiBoardMiddle } from "../../boards/yangtaigui/11-ii-cenei";
import { _11iiCewaiBoardMiddle } from "../../boards/yangtaigui/11-ii-cewai";
import { _11iiMenneiBoard } from "../../boards/yangtaigui/11-ii-mennei";
import { _11iiMenwaiBoard } from "../../boards/yangtaigui/11-ii-menwai";
import { BaseCupboard } from "../@base/BaseCupboard";

export class IIYangtaiguiCebaobei extends BaseCupboard {
    key: string = "IIYangtaiguiCebaobei";

    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        const result: any = {}
        result["cewaiBoard"] = _11iiCewaiBoardMiddle;
        result["ceneiBoard"] = _11iiCeneiBoardMiddle;
        result["menwaiBoard"] = _11iiMenwaiBoard;
        result["menneiBoard"] = _11iiMenneiBoard;
        // for (let i = 0; i < 100; i++) {
        //     result["cewaiBoard-" + i] = _11iiCewaiBoardMiddle;
        //     result["ceneiBoard-" + i] = _11iiCeneiBoardMiddle;
        //     result["menwaiBoard-" + i] = _11iiMenwaiBoard;
        //     result["menneiBoard-" + i] = _11iiMenneiBoard;
        // }
        return result;
    }

    setBoardNameMap() {
        return {};
    }

    // setTypeMap() {
    //     const typeMap: any = {};
    //     for (const name in this.nameMap) {
    //         if(name.indexOf("beinei") > -1) {
    //             typeMap[name] = "beinei";
    //         }else if(name.indexOf("beiwai") > -1) {
    //             typeMap[name] = "beiwai";
    //         }else if(name.indexOf("mennei") > -1) {
    //             typeMap[name] = "mennei";
    //         }else if(name.indexOf("menwai") > -1) {
    //             typeMap[name] = "menwai"; 
    //         }else if(name.indexOf("nei") > -1) {
    //             typeMap[name] = "guinei";
    //         }else if(name.indexOf("wai") > -1) {
    //             typeMap[name] = "guiwai";
    //         }
    //     }
    //     typeMap["qiandangwaiBoard"] = "menwai";
    //     typeMap["mentouwaiBoard"] = "menwai";

    //     return typeMap;
    // }
    // boardNameHasCircleMap: {[baordName: string]: boolean} = {};
    ceBoardNameDetailMap: {
        [boardName: string]: {
            waibanjiaoType: string,
            hasCircle: boolean,
            hasGuijiao: boolean
        }
    } = {}
    menBoardNameDetailMap: {
        [boardName: string]: {
            waibanjiaoType: string,
            jiaolianbianju: number,
            menminglashou: boolean,
            menlashoubianju: Number
            menlashoufangxiang: string;
            menlashouX: number;
            menlashouY: number;
            menlashou: boolean;
            heye: boolean,
            gxinglashou: boolean,
            gxinglashouType: "up" | "left-or-right",
            gxinglashouGaodu: number,
            doorSide: "right" | "left"
        }
    } = {}

    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {

        }
        this.ceBoardNameDetailMap = {};
        this.menBoardNameDetailMap = {};
        // //console.log("diyBoards", extraConfig.diyBoards);
        
        
        //动态重设name和type
        for (let i = 0; i < extraConfig.diyBoards.length; i++) {
            const board = extraConfig.diyBoards[i];
            // //console.log("diyBoard", board.quantity || 1);
            for (let j = 0; j < (board.quantity || 1); j++) {
                // //console.log("boardName: ", board.boardName);
                if (board.boardName?.indexOf("ce") > -1) {
                    this.boardCNNameMap['cewaiBoard-' + i + '-' + j] = '侧板_外板';
                    this.boardCNNameMap['ceneiBoard-' + i + '-' + j] = '侧板_内板';
                    this.nameMap['cewaiBoard-' + i + '-' + j] = this.nameMap['cewaiBoard'];
                    this.nameMap['ceneiBoard-' + i + '-' + j] = this.nameMap['ceneiBoard'];
                } else if (board.boardName?.indexOf("men") > -1) {
                    this.boardCNNameMap['menwaiBoard-' + i + '-' + j] = '门板_外板';
                    this.boardCNNameMap['menneiBoard-' + i + '-' + j] = '门板_内板';
                    this.nameMap['menwaiBoard-' + i + '-' + j] = this.nameMap['menwaiBoard'];
                    this.nameMap['menneiBoard-' + i + '-' + j] = this.nameMap['menneiBoard'];
                }
            }
        }
        
        this.updateTypeMap();
        // //console.log(this.nameMap);
        //计算size
        for (let i = 0; i < extraConfig.diyBoards.length; i++) {
            const board = extraConfig.diyBoards[i];
            
            let wReduce = 0;
            let hReduce = 0;
            if(board.gxinglashou) {
                if(board.gxinglashouType === "up") {
                    hReduce = 23.5 + Number(board.gxinglashouGaodu);
                }else {
                    wReduce = 23.5 + Number(board.gxinglashouGaodu);
                }
            }
            /* 2021 12 27 - 5 G型拉手门内高度+13*/
            let menneiHeightIncre = 0;
            let menneiWidthIncre = 0;
            if(board.gxinglashou) {
                if(board.gxinglashouType === "up") {
                    menneiHeightIncre = 13;
                }else {
                    menneiWidthIncre = 13;
                }
            }
            for (let j = 0; j < (board.quantity || 1); j++) {   
                if (board.boardName?.indexOf("ce") > -1) {
                    list["cewaiBoard-" + i + '-' + j] = {
                        size: [Number(board.boardWidth) + 47, Number(board.boardHeight) + 47]
                    }
                    this.ceBoardNameDetailMap["cewaiBoard-" + i + '-' + j] = {
                        waibanjiaoType: board.waibanjiaoType,
                        hasCircle: board.hasCircle,
                        hasGuijiao: board.hasGuijiao, 
                    };
                    list["ceneiBoard-" + i + '-' + j] = {
                        size: [Number(board.boardWidth) + 21, Number(board.boardHeight) + 21]
                    }
                    this.ceBoardNameDetailMap["ceneiBoard-" + i + '-' + j] = {
                        waibanjiaoType: board.waibanjiaoType,
                        hasCircle: board.hasCircle,
                        hasGuijiao: board.hasGuijiao
                    };
                } else if (board.boardName?.indexOf("men") > -1) {
                    list["menwaiBoard-" + i + '-' + j] = {
                        size: [Number(board.boardWidth) + 47 - wReduce, Number(board.boardHeight) + 47 - hReduce]
                    }
                    this.menBoardNameDetailMap["menwaiBoard-" + i + '-' + j] = {
                        waibanjiaoType: board.waibanjiaoType,
                        jiaolianbianju: Number(board.jiaolianbianju),
                        menminglashou: Boolean(board.menminglashou),
                        menlashoubianju: Number(board.menlashoubianju),
                        menlashoufangxiang: board.menlashoufangxiang,
                        menlashouX: Number(board.menlashouX),
                        menlashouY: Number(board.menlashouY),
                        menlashou: Boolean(board.menlashou),
                        heye: Boolean(board.heye),
                        gxinglashou: Boolean(board.gxinglashou),
                        gxinglashouType: board.gxinglashouType,
                        gxinglashouGaodu: Number(board.gxinglashouGaodu),
                        doorSide: board.doorSide,
                    }
                    list["menneiBoard-" + i + '-' + j] = {
                        size: [Number(board.boardWidth) + 21 - wReduce + menneiWidthIncre, Number(board.boardHeight) + 21 - hReduce + menneiHeightIncre]
                    }
                    this.menBoardNameDetailMap["menneiBoard-" + i + '-' + j] = {
                        waibanjiaoType: board.waibanjiaoType,
                        jiaolianbianju: Number(board.jiaolianbianju),
                        menminglashou: Boolean(board.menminglashou),
                        menlashoubianju: Number(board.menlashoubianju),
                        menlashoufangxiang: board.menlashoufangxiang,
                        menlashouX: Number(board.menlashouX),
                        menlashouY: Number(board.menlashouY),
                        menlashou: Boolean(board.menlashou),
                        heye: Boolean(board.heye),
                        gxinglashou: Boolean(board.gxinglashou),
                        gxinglashouType: board.gxinglashouType,
                        gxinglashouGaodu: Number(board.gxinglashouGaodu),
                        doorSide: board.doorSide
                    }
                    
                }
            }

        }
        // //console.log(this.ceBoardNameDetailMap);
        // //console.log(this.menBoardNameDetailMap);
        // //console.log(list);
        
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }, index: number): [Path[], Path[]] {
        let temp;
        if (board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if (board.name.indexOf("left") > -1) {
                doorSide = "left";
            } else if (board.name.indexOf("right") > -1) {
                doorSide = "right";
            } else {
                doorSide = this.menBoardNameDetailMap[board.name].doorSide;
            }
            //console.log("门外: ", doorSide);
            const menwaibanjiao = this.menBoardNameDetailMap[board.name].waibanjiaoType === "wujiao" ? false : true;
            temp = this.nameMap[board.name](
                this.menBoardNameDetailMap[board.name].waibanjiaoType, 
                1, 
                1, 
                board.startX, 
                board.startY, 
                board.width, 
                board.height, 
                menwaibanjiao, 
                undefined/* menbashou */, 
                this.menBoardNameDetailMap[board.name].menminglashou, 
                this.menBoardNameDetailMap[board.name].menlashouX, 
                this.menBoardNameDetailMap[board.name].menlashouY, 
                this.menBoardNameDetailMap[board.name].menlashoubianju, 
                this.menBoardNameDetailMap[board.name].menlashoufangxiang, 
                doorSide,
                this.menBoardNameDetailMap[board.name].menlashou,
                this.menBoardNameDetailMap[board.name].gxinglashou,
                this.menBoardNameDetailMap[board.name].gxinglashouType,
                this.menBoardNameDetailMap[board.name].gxinglashouGaodu,
            );
        } else if (board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if (board.name.indexOf("left") > -1) {
                doorSide = "left";
            } else if (board.name.indexOf("right") > -1) {
                doorSide = "right";
            } else {
                doorSide = this.menBoardNameDetailMap[board.name].doorSide;
            }
            //console.log("门内: ", doorSide);
            
            temp = this.nameMap[board.name](
                1, 
                1, 
                board.startX, 
                board.startY, 
                board.width, 
                board.height, 
                this.menBoardNameDetailMap[board.name].jiaolianbianju, 
                this.menBoardNameDetailMap[board.name].menminglashou, 
                this.menBoardNameDetailMap[board.name].menlashouX, 
                this.menBoardNameDetailMap[board.name].menlashouY, 
                this.menBoardNameDetailMap[board.name].menlashoubianju, 
                this.menBoardNameDetailMap[board.name].menlashoufangxiang, 
                doorSide, 
                this.menBoardNameDetailMap[board.name].menlashou, 
                this.menBoardNameDetailMap[board.name].heye,
                this.menBoardNameDetailMap[board.name].gxinglashou,
                this.menBoardNameDetailMap[board.name].gxinglashouType,
                this.menBoardNameDetailMap[board.name].gxinglashouGaodu,
            )
        } else if (board.name.indexOf("cewai") > -1) {
            temp = this.nameMap[board.name](this.ceBoardNameDetailMap[board.name].waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao, this.ceBoardNameDetailMap[board.name].hasCircle, this.ceBoardNameDetailMap[board.name].hasGuijiao);
        } else if (board.name.indexOf("cenei") > -1) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, this.ceBoardNameDetailMap[board.name].hasCircle, this.ceBoardNameDetailMap[board.name].hasGuijiao);
        } else if (board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }

    //排版
    sheetBoardHandler(boardsInSheet: Sheet[], extraConfig: { [key: string]: any }) {
        this.getSizeMap(0, 0, 0, extraConfig);
        return super.sheetBoardHandler(boardsInSheet, extraConfig);
    }

}