import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner20 } from "../../coners/corner20";
import { corner6 } from "../../coners/corner6";
import { corner8 } from "../../coners/corner8";
import { corner6WaiWuhujiao } from "../../coners/无护角/corner6-wai-wuhujiao";
import { corner8WaiWuhujiao } from "../../coners/无护角/corner8-wai-wuhujiao";
import { corner6WaiWujiao } from "../../coners/无角/corner6-wai-wujiao";
import { corner8WaiWujiao } from "../../coners/无角/corner8-wai-wujiao";
import { rectBoard } from "../general-board";

export const _05liwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    corner: boolean,
    mengao: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    extraConfig?: any,
): [Path[], Path[]]  => {
    const {
        上免拉手,
        上免拉手X,
        上免拉手Y,
        中免拉手,
        中免拉手X,
        中免拉手Y,
        中免拉手Z
    } = extraConfig;
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];// = corner8(dx, -dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner8(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wujiao") {
        cornerBL = corner8WaiWujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner8WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }

    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR: Path[] = []; // = corner6(dx, dy, startX, startY, corner, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner6(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRSize.width);
    }else if(cornerType === "wujiao") {
        cornerBR = corner6WaiWujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRSize.width);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner6WaiWuhujiao(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRSize.width);
        
    }

    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    let cornerTR: Path[] = [];
    if(!上免拉手) {
        cornerTR = corner20(dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else {
        cornerTR.push({
            type: "Line",
            params: [
                startX + dx * width, startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * width - dx * (上免拉手X + 23.5),  startY + dy * height - dy * (上免拉手Y + 23.5)
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5),  startY + dy * height - dy * (上免拉手Y + 23.5),
                startX + dx * width - dx * (上免拉手X + 23.5), startY + dy * height
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5) - dx * 10, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        }, {
            type: "Circle",
            params: [
                startX + dx * width - dx * (上免拉手X + 23.5) - dx * 10 - dx * 25.5, startY + dy * height - dy * (上免拉手Y + 23.5) / 2,
                1.6 
            ]
        })
        cornerTRW = 上免拉手X + 23.5;
        cornerTRH = 上免拉手Y + 23.5;
    }
    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = []; // = corner8(dx, dy, startX, startY, corner, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner8(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wujiao") {
        cornerTL = corner8WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner8WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        
    }


    let paths;
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, cornerTRW, cornerTRH, 0, 0);
    }

    
    if(中免拉手) {
        paths = paths.filter((_, index) => index !== 1);
        paths.push({
            type: Line,
            params: [
                startX + dx * width, startY + dy * cornerBRH,
                startX + dx * width, startY + dy * (中免拉手Z + 5.5)

            ]
        }, {
            type: Line,
            params: [
                startX + dx * width, startY + dy * (中免拉手Z + 5.5),
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5),
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5),  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                startX + dx * width,  startY + dy * (中免拉手Z + 5.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y),
                startX + dx * width, startY + dy * height - dy * cornerTRH
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5) - dx * 10, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                1.6
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (中免拉手X + 23.5) - dx * 10 - dx * 25.5, startY + dy * (中免拉手Z + 5.5 + 中免拉手Y / 2),
                1.6
            ]
        })
    }
   
    if (jiaolianbianju > -1 && !nojiaoliankong) {
        let offsetGX = 0;
        let offsetGY = 0;
        if (gxinglashou) {
            if (gxinglashouType === "up") {
                offsetGY = -1.5;
            }else if(gxinglashouType === "left-or-right") {
                offsetGX = 0;
            }
        }
        const temp: Path[] = [{
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14),
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14)
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 51.25, startY + dy * (jiaolianbianju - 12),
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12),
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 51.25, startY + dy * (jiaolianbianju - 12) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4,
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12)
            ]
        },
            //**********************
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 51.25, startY + dy * (jiaolianbianju - 12) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32,
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 51.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32
            ]
        },
            //==============================================
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 - dy * offsetGY,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) - dy * offsetGY
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 51.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY,
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 51.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 - dy * offsetGY,
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) - dy * offsetGY
            ]
        },
            //******************* 
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 - dy * offsetGY
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 51.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 51.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 51.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 - dy * offsetGY,
                startX + dx * width - dx * 51.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 - dy * offsetGY
            ]
        }
        ];
        paths.push(...temp);
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)

    ]
}