import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { cornerMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner11 } from "../../coners/corner11";
import { rectBoard } from "../general-board";

export const _02beineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    diaokongbianju: number,
    extraConfig: any
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner11(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;

    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner11(dx, dy, startX, startY, true, cornerBRSize);
    cornerBR = pathMirrorY(cornerBR, startX);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner11(dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathMirrorY(cornerTR, startX);
    cornerTR = cornerMirrorX(cornerTR, startX, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height + cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - 4 * cornerTRSize.width);

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner11(dx, dy, startX, startY, true, cornerTLSize);
    cornerTL = pathRotate(cornerTL, 90);    
    cornerTL = pathMirrorY(cornerTL, startX);
    cornerTL = pathRotate(cornerTL, -90);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    cornerTL = pathMoveX(cornerTL, DirectX.RIGHT,  2 * cornerTLSize.width);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height);

    
    const boardConfig: any = {
        middleware: {
            top: width > 500 && "close",
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: width > 500 && "close",
        }
    }
    //board
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
   
        
    if(diaokongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 11), startY + dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 11), startY + dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 11), startY + dy * height - dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 11), startY + dy * height - dy * (diaokongbianju + 11),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        });
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}