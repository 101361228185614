import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner2Sanheyi } from "./corner2-sanheyi";

export const corner8Sanheyi = (
    _dx: DirectX, 
    _dy: DirectY, 
    startX: number, 
    startY: number, 
    corner: boolean, 
    sizeRes?: {
        width: number, 
        height: number
    }
): Path[] => {
    const dx = 1;
    const dy = 1;
    const corner2Paths = corner2Sanheyi(dx, dy, startX, startY, true);
    let paths = corner2Paths.filter((item) => item.type !== Circle);
    paths = paths.slice(12, paths.length);
    paths = pathMoveY(paths, DirectY.DOWN, dy * 35.601707);
    const width = 34.0006;
    const height = 33.998293;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
        paths = pathMoveY(paths, DirectY.UP, dy * height);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);
        paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
        paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
        paths = pathMoveY(paths, DirectY.UP, dy * height);
    }
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}