import { router } from './route';
import { useEffect } from 'react';

import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider, theme } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { useLocaleStore } from './locale';

const localeMap = {
  'zh-CN': zhCN,
  'en-US': enUS,
  'vi-VN': viVN
}

function App() {

  const locale = useLocaleStore(state => state.locale);

  return (
    <ConfigProvider locale={localeMap[locale]} theme={{
      algorithm: [theme.darkAlgorithm, theme.compactAlgorithm]
    }}>
      <RouterProvider router={router}/>
    </ConfigProvider>
  );
}

export default App;
