import { DirectX, DirectY } from "../../../constants/enum";
import { _02AceneiBoardLeft } from "./02-a-cenei-left"

export const _12AceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number,
): [Path[], Path[]] => {
    return _02AceneiBoardLeft(dx, dy, startX, startY, width, height, lianjiekongbianju);
}