import { Arc, Circle, Line } from "../../../../constants";
import { DirectX, DirectY } from "../../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../../util/graph";
import { corner2Sanheyi } from "../../../coners/三合一/corner2-sanheyi";
import { corner5Sanheyi } from "../../../coners/三合一/corner5-sanheyi";
import { corner6Sanheyi } from "../../../coners/三合一/corner6-sanheyi";
import { rectBoard } from "../../general-board";

export const sanheyi01CewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    mengao?: number
): [Path[], Path[]] => {
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner5Sanheyi(dx, dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
    }
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner6Sanheyi(dx, dy, startX, startY, corner, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;        
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner2Sanheyi(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner2Sanheyi(-dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    }


    let paths = [];
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0);
    }

    if (lianjiekongbianju >= 0) {
    paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        });
    }
    if (jiaolianbianju > -1) {
        let offsetY = 0;
        mengao = mengao as number;

        paths.push({
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14),
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14)
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12),
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12),
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12)
            ]
        },
            //**********************
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 8 + dy * 32,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju - 14) + dy * 32
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 4 + dy * 32,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju - 12) + dy * 32
            ]
        },
            //==============================================
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * offsetY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * offsetY,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * offsetY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * offsetY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * offsetY,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * offsetY
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * offsetY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * offsetY,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * offsetY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * offsetY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * offsetY,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * offsetY
            ]
        },
            //******************* 
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 + dy * offsetY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 + dy * offsetY,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 + dy * offsetY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 + dy * offsetY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 8 + dy * 32 + dy * offsetY,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 84) + dy * 32 + dy * offsetY
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 + dy * offsetY,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 + dy * offsetY,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 + dy * offsetY,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 + dy * offsetY,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 4 + dy * 32 + dy * offsetY,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (mengao + 67) - dy * (jiaolianbianju + 82) + dy * 32 + dy * offsetY
            ]
        },
        )
    }
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        paths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                2.48
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                2.48
            ]
        });
    }

    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ];
}