import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveY } from "../../../util/graph";
import { corner2WaiWujiao } from "./corner2-wai-wujiao";

export const corner8WaiWujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const corner2Paths = corner2WaiWujiao(dx, dy, startX, startY, true);
    let paths = corner2Paths.filter((item) => item.type !== Circle);
    paths = paths.slice(8, paths.length);
    paths = pathMoveY(paths, DirectY.DOWN, dy * 43.49);
    paths.push({
        type: Line,
        params: [
            startX, startY  + dy * 33.998293,
            startX, startY
        ]
    })
    if(typeof sizeRes === 'object') {
        sizeRes.width = 34.0000;
        sizeRes.height = 33.998293;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}