export const json2Form = (json: any) => {
    let formStr: string[] = [];
    for (const key in json) {
        if (json.hasOwnProperty(key)) {
            formStr.push(encodeURIComponent(key) + '=' + encodeURIComponent(json[key]));
        }
    }
    return formStr.join('&');
}

/**search中的数据截取
 * @getSearchData window.location.search中的数据转换为对象,?name=zhangsan&age=18&sex=man
 * @return Object {name:"张三",age:18,sex:"男"}
 */
export const search2Query = (search: string) => {
    let obj: any = {};
    let arr = search.slice(1).split('&');
    arr.forEach(item => {
        let newArr = item.split('=');
        obj[newArr[0]] = newArr[1];
    })
    return obj;
}


export const sleep = async (ms: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    })
}

