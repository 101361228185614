import { Vector2d as Point } from "konva/lib/types";
/**
 * 计算多边形墙的内轮廓顶点和外轮廓顶点
 *
 * @param points 多边形墙中线所组成多边形的顶点Point {x: number, y: number}数组
 * @param thickness 墙厚度
 * @returns 一个对象，包含内轮廓顶点数组和外轮廓顶点数组
 */
export function calculateWallContours(points: Point[], thickness: number) {
    // points.push(points[0]);
    // 计算法线并存放在一个数组中
    const normals = points.map((current, i, array) => {
        const next = array[(i + 1) % array.length];
        return computeNormal(current, next);
    });

    // 生成内外轮廓
    let inner: Point[] = [];
    let outer: Point[] = [];

    for (let i = 0; i < points.length; i++) {
        // 取得前一条边和当前边的法线
        const prevNormal = normals[(i - 1 + points.length) % points.length];
        const currentNormal = normals[i];

        // 计算顶点偏移
        inner.push({
            x: points[i].x - (prevNormal.x + currentNormal.x) * thickness / 2,
            y: points[i].y - (prevNormal.y + currentNormal.y) * thickness / 2
        });

        outer.push({
            x: points[i].x + (prevNormal.x + currentNormal.x) * thickness / 2,
            y: points[i].y + (prevNormal.y + currentNormal.y) * thickness / 2
        });
    }

    
    let innerArea = polygonArea(inner);
    let outerArea = polygonArea(outer);

    let temp: Point[];
    let areaTemp: number;
    if (innerArea > outerArea) {
        temp = outer;
        outer = inner;
        inner = temp;

        areaTemp = outerArea;
        outerArea = innerArea;
        innerArea = areaTemp;
    }
    
    const outerBound =  { left: Number.MAX_VALUE, top: Number.MAX_VALUE, right: Number.MIN_VALUE, bottom: Number.MIN_VALUE }
    const innerBound =  { left: Number.MAX_VALUE, top: Number.MAX_VALUE, right: Number.MIN_VALUE, bottom: Number.MIN_VALUE }
    
    inner.forEach((v) => {
        if (v.x < innerBound.left) {
            innerBound.left = v.x;
        }
        if (v.x > innerBound.right) {
            innerBound.right = v.x;
        }
        if (v.y < innerBound.top) {
            innerBound.top = v.y;
        }
        if (v.y > innerBound.bottom) {
            innerBound.bottom = v.y;
        }
    });

    outer.forEach((v) => {
        if (v.x < outerBound.left) {
            outerBound.left = v.x;
        }
        if (v.x > outerBound.right) {
            outerBound.right = v.x;
        }
        if (v.y < outerBound.top) {
            outerBound.top = v.y;
        }
        if (v.y > outerBound.bottom) {
            outerBound.bottom = v.y;
        }
    });


    return { 
        outerContour: outer,
        outerArea: outerArea,
        outerBound: outerBound,
        innerContour: inner,
        innerArea: innerArea,
        innerBound: innerBound
    };
}


// 定义转换函数计算垂直单位向量
function computeNormal(p1: Point, p2: Point): Point {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const len = Math.sqrt(dx * dx + dy * dy);
    return { x: -dy / len, y: dx / len };
}
// 这个函数用来计算多边形的面积，使用鞋带公式
function polygonArea(points: Point[]): number {
    let total = 0;
  
    for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
      total += (points[j].x + points[i].x) * (points[j].y - points[i].y);
    }
  
    return Math.abs(total) / 2;
  }