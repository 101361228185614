import { DirectX, DirectY } from "../../../constants/enum"
import { ceneiBoardLeft } from "../地柜/01-cenei-left"

export const _05ceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return ceneiBoardLeft(dx, dy, startX, startY, width, height, lianjiekongbianju, qiandanghengfang, extraConfig);
}