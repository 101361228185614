import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner8 } from "../../coners/corner8";
import { corner8WaiWuhujiao } from "../../coners/无护角/corner8-wai-wuhujiao";
import { corner8WaiWujiao } from "../../coners/无角/corner8-wai-wujiao";
import { rectBoard } from "../general-board";

export const outerBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    extraConfig?: {
        jiaolianConfig: any,
        lashouConfig: any,
        qiejiaoConfig: any,
        isDoor?: boolean,
        gecengConfig: any
    }
): [Path[], Path[]] => {
    let cornerType = "wuhujiao";
    let corner = true;

    let lbc = true;/* left bottom corner */
    let rbc = true;
    let rtc = true;
    let ltc = true;

    if (extraConfig?.qiejiaoConfig) {
        const { 切角位置, 切角宽, 切角高 } = extraConfig.qiejiaoConfig;
        if (切角位置 === 'rb') {
            rbc = false;
        } else if (切角位置 === 'rt') {
            rtc = false;
        } else if (切角位置 === 'lb') {
            lbc = false;
        } else if (切角位置 === 'lt') {
            ltc = false;
        }
    }

    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if (lbc) {
        if (cornerType === "wujiao" || cornerType === "youhujiao") {
            cornerBL = corner8(dx, -dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
        } else if (cornerType === "wuhujiao") {
            cornerBL = corner8WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
        }
    }
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if (rbc) {
        if (cornerType === "wujiao" || cornerType === "youhujiao") {
            cornerBR = corner8(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        } else if (cornerType === "wuhujiao") {
            cornerBR = corner8WaiWuhujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        }
    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if (rtc) {

        if (cornerType === "wujiao" || cornerType === "youhujiao") {
            cornerTR = corner8(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        } else if (cornerType === "wuhujiao") {
            cornerTR = corner8WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        }
    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if (ltc) {
        if (cornerType === "wujiao" || cornerType === "youhujiao") {
            cornerTL = corner8(dx, dy, startX, startY, corner, cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        } else if (cornerType === "wuhujiao") {
            cornerTL = corner8WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        }
    }


    //切角
    if (extraConfig?.qiejiaoConfig) {
        const { 切角位置, 切角宽, 切角高 } = extraConfig.qiejiaoConfig;
        if (切角位置 === 'lb') {
            cornerBLW = 切角宽 + 23.5;
            cornerBLH = 切角高 + 23.5;
        } else if (切角位置 === 'rb') {
            cornerBRW = 切角宽 + 23.5;
            cornerBRH = 切角高 + 23.5;
        } else if (切角位置 === 'rt') {
            cornerTRW = 切角宽 + 23.5;
            cornerTRH = 切角高 + 23.5;
        } else if (切角位置 === 'lt') {
            cornerTLW = 切角宽 + 23.5;
            cornerTLH = 切角高 + 23.5;
        }
    }

    let paths;
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0);
    }

    //切角
    if (extraConfig?.qiejiaoConfig) {
        const { 切角位置, 切角宽, 切角高, 切角位置X, 切角位置Y } = extraConfig.qiejiaoConfig;
        if (切角位置 === 'lb') {
            paths.push({
                type: "Line",
                params: [
                    startX, startY + dy * cornerBLH,
                    startX + dx * cornerBLW, startY + dy * cornerBLH
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * cornerBLW, startY + dy * cornerBLH,
                    startX + dx * cornerBLW, startY
                ]
            });
        } else if (切角位置 === 'rb') {
            paths.push({
                type: "Line",
                params: [
                    startX + dx * width, startY + dy * cornerBRH,
                    startX + dx * width - dx * cornerBRW, startY + dy * cornerBRH
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * width - dx * cornerBRW, startY + dy * cornerBRH,
                    startX + dx * width - dx * cornerBRW, startY
                ]
            });
        } else if (切角位置 === 'rt') {
            paths.push({
                type: "Line",
                params: [
                    startX + dx * width, startY + dy * height - dy * cornerTRH,
                    startX + dx * width - dx * cornerTRW, startY + dy * height - dy * cornerTRH,
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * width - dx * cornerTRW, startY + dy * height - dy * cornerTRH,
                    startX + dx * width - dx * cornerTRW, startY + dy * height
                ]
            });
        } else if (切角位置 === 'lt') {
            paths.push({
                type: "Line",
                params: [
                    startX, startY + dy * height - dy * cornerTLH,
                    startX + dx * cornerTLW, startY + dy * height - dy * cornerTLH,
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * cornerTLW, startY + dy * height - dy * cornerTLH,
                    startX + dx * cornerTLW, startY + dy * height
                ]
            });
        } else if (切角位置 === 'center') {
            const sx = (startX + startX + dx * width) / 2 - 切角宽 / 2;
            const sy = (startY + startY + dy * height) / 2 - 切角高 / 2;

            paths.push({
                type: "Line",
                params: [
                    sx, sy,
                    sx + dx * 切角宽, sy
                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy,
                    sx + dx * 切角宽, sy + 切角高

                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy + 切角高,
                    sx, sy + 切角高
                ]
            }, {
                type: "Line",
                params: [
                    sx, sy + 切角高,
                    sx, sy
                ]
            });
        } else if (切角位置 === 'xy') {
            const sx = startX + dx * (切角位置X + 23.5);
            const sy = startY + dy * (height - 23.5 - 切角位置Y) - 切角高;
            paths.push({
                type: "Line",
                params: [
                    sx, sy,
                    sx + dx * 切角宽, sy
                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy,
                    sx + dx * 切角宽, sy + 切角高

                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy + 切角高,
                    sx, sy + 切角高
                ]
            }, {
                type: "Line",
                params: [
                    sx, sy + 切角高,
                    sx, sy
                ]
            });

        }
    }

    let corners: Path[] = [];
    if (extraConfig?.lashouConfig) {
        const { 门拉手类型, 门拉手方向, 拉手X, 拉手Y, 拉手间距 } = extraConfig.lashouConfig;
        if (门拉手类型 === "暗拉手") {
            const centerX = (2 * startX + width) / 2;
            const _startX = centerX - dx * 64.75 - dx * 1.5;
            const _startY = startY + dy * height - dy * 129.299982;
            paths.push({
                type: Arc,
                params: [
                    _startX, _startY,
                    1.5,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5, _startY,
                    _startX - dx * 1.5, _startY + dy * 46.3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3, _startY + dy * 46.3,
                    3,
                    90, 180,
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3, _startY + dy * 46.3 + dy * 3,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5, _startY + dy * 46.3 + dy * 3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5, _startY + dy * 46.3,
                    3,
                    0, 90
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3, _startY + dy * 46.3,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3, _startY + dy * 46.3 - dy * 46.3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5, _startY + dy * 46.3 - dy * 46.3,
                    1.5,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5, _startY + dy * 46.3 - dy * 46.3,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3,
                    0.5,
                    0, 90
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5 - dy * 0.5,
                    0.5,
                    90, 180
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5 - dy * 0.5,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5 - dy * 0.5 - dy * 45.3,
                ]
            });
        } else if (门拉手类型 === "明拉手") {
            const { 开门方向, 拉手X: menlashouX, 拉手Y: menlashouY, 拉手间距: menlashoubianju, 门拉手方向: menlashoufangxiang } = extraConfig.lashouConfig;
            if (开门方向 === "右开") {
                corners.push({
                    type: Circle,
                    params: [
                        startX + dx * (menlashouX + 23.5), startY + dy * height - dy * (menlashouY + 23.5),
                        2.1
                    ]
                });
                if (menlashoubianju > 0) {

                    if (menlashoufangxiang === "horizontal") {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * (menlashouX + 23.5 + menlashoubianju), startY + dy * height - dy * (menlashouY + 23.5),
                                2.1
                            ]
                        })
                    } else {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * (menlashouX + 23.5), startY + dy * height - dy * (menlashouY + 23.5 + menlashoubianju),
                                2.1
                            ]
                        })
                    }
                }
            } else {
                corners.push({
                    type: Circle,
                    params: [
                        startX + dx * width - dx * (menlashouX + 23.5), startY + dy * height - dy * (menlashouY + 23.5),
                        2.1
                    ]
                });
                if (menlashoubianju > 0) {

                    if (menlashoufangxiang === "horizontal") {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * width - dx * (menlashouX + 23.5 + menlashoubianju), startY + dy * height - dy * (menlashouY + 23.5),
                                2.1
                            ]
                        })
                    } else {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * width - dx * (menlashouX + 23.5), startY + dy * height - dy * (menlashouY + 23.5 + menlashoubianju),
                                2.1
                            ]
                        })
                    }
                }
                //console.log(corners);

            }

        } else if (extraConfig.lashouConfig.type === "gxinglashou") {

        }
    }

    if (!extraConfig?.isDoor) {

        if (extraConfig?.jiaolianConfig) {
            let { 上铰链边距, 下铰链边距, 开门方向 } = extraConfig.jiaolianConfig;
            上铰链边距 = Number(上铰链边距);
            下铰链边距 = Number(下铰链边距);
            let xSign = 1;
            let xOffset = 0;
            if (开门方向 === '右开') {
                xSign = -1;
                xOffset = dx * width;
            }

            let 下偏移 = 17.5;
            let 上偏移 = -40.5;

            下铰链边距 += 下偏移;
            上铰链边距 += 上偏移;

            const temp: Path[] = [{
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (下铰链边距 - 14),
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (下铰链边距 - 14),
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (下铰链边距 - 14) + dy * 8,
                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (下铰链边距 - 14) + dy * 8,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (下铰链边距 - 14) + dy * 8,
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (下铰链边距 - 14),
                ]
            },
            //----------------
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (下铰链边距 - 14 + 2),
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (下铰链边距 - 14 + 2),
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 4,
                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 4,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 4,
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (下铰链边距 - 14 + 2)
                ]
            },
            //**********************
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (下铰链边距 - 14) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (下铰链边距 - 14) + dy * 32,
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (下铰链边距 - 14) + dy * 8 + dy * 32,

                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (下铰链边距 - 14) + dy * 8 + dy * 32,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (下铰链边距 - 14) + dy * 8 + dy * 32,
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (下铰链边距 - 14) + dy * 32
                ]
            },
            //----------------
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 32,
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 4 + dy * 32,

                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 4 + dy * 32,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 4 + dy * 32,
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (下铰链边距 - 14 + 2) + dy * 32
                ]
            },
            //==============================================
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (height) - dy * (上铰链边距 + 84),
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84),
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 8,

                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 8,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 8,
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84)
                ]
            },
            //----------------
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (height) - dy * (上铰链边距 + 82),
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82),
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 4,
                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 4,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 4,
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82)
                ]
            },
            //******************* 
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 32,
                    startX + xOffset + xSign * (dx * 64.75 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 8 + dy * 32,

                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 64.75), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 8 + dy * 32,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 8 + dy * 32,
                    startX + xOffset + xSign * (dx * 64.75 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 84) + dy * 32
                ]
            },
            //----------------
            {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 32,
                    startX + xOffset + xSign * (dx * 49.25 + dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 4 + dy * 32,
                ]
            }, {
                type: Arc,
                params: [
                    startX + xOffset + xSign * (dx * 49.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 4 + dy * 32,
                    3.25,
                    0, 180
                ]
            }, {
                type: Line,
                params: [
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 4 + dy * 32,
                    startX + xOffset + xSign * (dx * 49.25 - dx * 3.25), startY + dy * (height) - dy * (上铰链边距 + 82) + dy * 32
                ]
            }
            ];
            paths.push(...temp);
        }
    }

    if (extraConfig?.qiejiaoConfig) {

    }

    if (extraConfig?.gecengConfig) {
        let { 隔层孔前X, 隔层孔后X, 隔层孔Y, 隔层孔径, 开门方向 } = extraConfig.gecengConfig;
        if(开门方向 === '左开') {
            paths.push({
                type: Circle,
                params: [
                    startX + dx * (23.5 + 隔层孔前X), startY + dy * (隔层孔Y + 23.5),
                    (隔层孔径 || 4.96) / 2
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * width - dx * (23.5 + 隔层孔后X), startY + dy * (隔层孔Y + 23.5),
                    (隔层孔径 || 4.96) / 2
                ]
            });

        }else {
            paths.push({
                type: Circle,
                params: [
                    startX + dx * width - dx * (23.5 + 隔层孔前X), startY + dy * (隔层孔Y + 23.5),
                    (隔层孔径 || 4.96) / 2
                ]
            }, {
                type: Circle,
                params: [
                    startX + dx * (23.5 + 隔层孔后X), startY + dy * (隔层孔Y + 23.5),
                    (隔层孔径 || 4.96) / 2
                ]
            });
        }
    }

    return [
        paths,
        corners.concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}