import { _02AbeineiBoard } from "../../boards/吊柜/02-a-beinei";
import { _02AbeiwaiBoard } from "../../boards/吊柜/02-a-beiwai";
import { _02AceneiBoardLeft } from "../../boards/吊柜/02-a-cenei-left";
import { _02AceneiBoardRight } from "../../boards/吊柜/02-a-cenei-right";
import { _02AcewaiBoardLeft } from "../../boards/吊柜/02-a-cewai-left";
import { _02AcewaiBoardRight } from "../../boards/吊柜/02-a-cewai-right";
import { _02AdineiBoard } from "../../boards/吊柜/02-a-dinei";
import { _02AdingneiBoard } from "../../boards/吊柜/02-a-dingnei";
import { _02AdingwaiBoard } from "../../boards/吊柜/02-a-dingwai";
import { _02AdiwaiBoard } from "../../boards/吊柜/02-a-diwai";
import { _02gecengneiBoard } from "../../boards/吊柜/02-gecengnei";
import { _02gecengwaiBoard } from "../../boards/吊柜/02-gecengwai";
import { _02menneiBoard } from "../../boards/吊柜/02-mennei";
import { _02menwaiBoard } from "../../boards/吊柜/02-menwai";
import { BaseCupboard } from "../@base/BaseCupboard";



export class DiaoguiBeibaoce extends BaseCupboard {
    
    key: string = "DiaoguiBeibaoce";

    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: _02AdineiBoard,
            diwaiBoard: _02AdiwaiBoard,
            dingneiBoard: _02AdingneiBoard,
            dingwaiBoard: _02AdingwaiBoard,
            ceneiBoardLeft: _02AceneiBoardLeft,
            ceneiBoardRight: _02AceneiBoardRight,
            cewaiBoardLeft: _02AcewaiBoardLeft,
            cewaiBoardRight: _02AcewaiBoardRight,
            beiwaiBoard: _02AbeiwaiBoard,
            beineiBoard: _02AbeineiBoard,
            menwaiBoard: _02menwaiBoard,
            menneiBoard: _02menneiBoard,
            "menwaiBoard-left": _02menwaiBoard,
            "menneiBoard-left": _02menneiBoard,
            "menwaiBoard-right": _02menwaiBoard,
            "menneiBoard-right": _02menneiBoard,
            gecengneiBoard: _02gecengneiBoard,
            gecengwaiBoard: _02gecengwaiBoard,
        }
    }
    
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 11, deep + 47 - 18],
            },
            "dineiBoard": {
                size: [long - 15, deep + 21 - 18],
            },
            "dingwaiBoard": {
                size: [long + 11, deep + 47 - 18],
            },
            "dingneiBoard": {
                size: [long - 15, deep + 21 - 18],
            },
            "cewaiBoardLeft": {
                size: [ deep + 47 - 18, height + 47],
            },
            "cewaiBoardRight": {
                size: [ deep + 47 - 18, height + 47],
            },
            "ceneiBoardLeft": {
                size: [ deep + 21 - 18, height + 21],
            },
            "ceneiBoardRight": {
                size: [ deep + 21 - 18, height + 21],
            },
            "beiwaiBoard": {
                size: [long + 11 + 36, height + 11 + 36]
            },
            "beineiBoard": {
                size: [long - 15 + 36, height - 15 + 36]
            },
        };
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
        /* 2021 12 27 - 5 G型拉手门内高度+13*/
        let menneiHeightIncre = 0;
        let menneiWidthIncre = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                menneiHeightIncre = 13;
            }else {
                menneiWidthIncre = 13;
            }
        }
        if(extraConfig.doorNumber === 2) {
            list["menneiBoard-left"] = {
                size: [long / 2 - 2 + 21 - wReduce + menneiWidthIncre, height + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-left"] = {
                size: [long / 2 - 2 + 47 - wReduce, height + 45 - hReduce + (extraConfig.menxiagaochu || 0)],   
                quantity: 1,
            }
            list["menneiBoard-right"] = {
                size: [long / 2 - 2 + 21 - wReduce + menneiWidthIncre, height + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard-right"] = {
                size: [long / 2 - 2 + 47 - wReduce, height + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }else {
            list["menneiBoard"] = {
                size: [long - 2 + 21 - wReduce + menneiWidthIncre, height + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            list["menwaiBoard"] = {
                size: [long - 2 + 47 - wReduce, height + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }
        
        if(extraConfig.geceng) {
            list['gecengwaiBoard'] = {
                size: [long + 11, deep - extraConfig.gecengX + 29],
            };
            list['gecengneiBoard'] = {
                size: [long - 15, deep - extraConfig.gecengX + 3]
            };
        }
        if(extraConfig.nobeinei) {
            delete list["beineiBoard"];
        }
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'beineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.diaokongbianju, extraConfig);
        }else if(board.name === "beiwaiBoard") {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.diaokongbianju, extraConfig.waibanjiao, extraConfig);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            if(extraConfig.doorNumber === 2) {
                temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.menxiagaochu, extraConfig);                    
            }else {
                if(board.name.toLowerCase().includes(extraConfig.doorSide)) {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.menxiagaochu, extraConfig);
                }else {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, -1,                         extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.menxiagaochu, extraConfig);
                }
            }
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju);
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao)
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }


}