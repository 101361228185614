import { forwardRef, useImperativeHandle, useState } from "react";
import { CupboardBroad, Material } from "../../../@interface";
import { Modal, Switch } from "antd";
import { RuleStage } from "./rule-stage";
import { Layer } from "react-konva";
import { RectWithAngles } from "./graph/board";

export const BoardView = forwardRef((props: {
    compatibleV1?: boolean
}, ref: any) => {

    const [board, setBoard] = useState<CupboardBroad>();
    const [visible, setVisible] = useState(false);
    const [material, setMaterial] = useState<Material>();
    const [scale, setScale] = useState(0);
    const [fill, setFill] = useState(true);

    const [stageScale, setStageScale] = useState(1);

    useImperativeHandle(ref, () => {
        return {
            open: (board: CupboardBroad, material: Material) => {
                setBoard(board);
                setMaterial(material);
                setScale((window.innerHeight - 400 - 24) / Math.max(board.height, board.width));
                setVisible(true);
            },
            close: close
        }
    });

    const close = () => {
        setVisible(false);
        setMaterial(undefined);
        setScale(0);
        setFill(true);
        setBoard(undefined);
    }

    return (
        <Modal
            open={visible}
            centered
            title={board?.cupboardName + " - " + board?.name + " - " + board?.type}
            destroyOnClose
            width={window.innerHeight - 400 + 48}
            onCancel={close}
            footer={(
                <div className="flex center" style={{ justifyContent: 'space-between' }}>
                    <span className="flex center">
                        {
                            !props.compatibleV1 && (
                                <>

                                    <Switch checked={fill} style={{ marginRight: 4 }} onChange={() => {
                                        setFill(!fill);
                                    }} />
                                    {fill ? '填充' : '线框'}
                                </>
                            )
                        }
                    </span>
                    <span>当前缩放：{(scale * stageScale * 100).toFixed(2)}%</span>

                </div>
            )}

        >
            <RuleStage
                height={window.innerHeight - 400}
                width={window.innerHeight - 400}
                ruleScale={scale}
                draggable
                zoomable
                onStageScaleChange={(stageScale) => {
                    console.log("onStageScaleChange", stageScale);
                    setStageScale(stageScale);
                }}
            >
                <Layer>
                    {
                        scale > 0 && (
                            <RectWithAngles
                                board={board}
                                material={material}
                                scale={scale}
                                fill={fill}
                                stageScale={stageScale}
                                background={"#000"}
                                compatibleV1={props.compatibleV1}
                            />
                        )
                    }
                </Layer>
            </RuleStage>
        </Modal>
    )
});




