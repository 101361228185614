import { PageHeader } from "@ant-design/pro-layout";
import { Button, ConfigProvider, Form, Input, message, Modal, Select, theme } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Material } from "../../../../../@interface";
import { apis } from "../../../utils2/apis";
import { request } from "../../../utils2/request";
import { EditOutlined } from "@ant-design/icons";
import { useLocaleStore } from "../../../locale";
import { useTranslation } from "react-i18next";

export const materials: Material[] = [{
    id: "la_si_wu_zhi_wen",
    name: "拉丝无指纹",
    texture: {
        id: 0,
        type: "COLOR",
        value: "lightgray",
    }
}, {
    id: "liang_guang_xiao_yu_di",
    name: "亮光小雨滴",
    texture: {
        id: 0,
        type: "COLOR",
        value: "lightgray",
    }
}, {
    id: "xue_hua_sha",
    name: "雪花砂",
    texture: {
        id: 0,
        type: "COLOR",
        value: "lightgray",
    }
}, {
    id: "ya_you_xiao_yu_di",
    name: "哑油小雨滴",
    texture: {
        id: 0,
        type: "COLOR",
        value: "lightgray",
    }
}, {
    id: "men_ban_cai_liao",
    name: "门板材料",
    texture: {
        id: 1,
        type: "COLOR",
        value: "#0FF",
    }
}, {
    id: "men_nei_cai_liao",
    name: "门内材料",
    texture: {
        id: 1,
        type: "COLOR",
        value: "#0FF",
    }
}, {
    id: "men_wai_cai_liao",
    name: "门外材料",
    texture: {
        id: 1,
        type: "COLOR",
        value: "#0FF",
    }
}];

// localStorage.setItem("SK_ENUM_MATERIALS", JSON.stringify(materials));


//门外  门内  侧外  侧内  底外  底内  背外  背内  前挡外  前挡内   层板外  层板内  顶板外  顶板内
export const boardTypes = [{
    name: "侧外",
    key: "cewai",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "侧内",
    key: "cenei",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "底外",
    key: "diwai",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "底内",
    key: "dinei",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "背外",
    key: "beiwai",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "背内",
    key: "beinei",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "前挡外",
    key: "qiandangwai",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "前挡内",
    key: "qiandangnei",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "层板外",
    key: "cengbanwai",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "层板内",
    key: "cengbannei",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "顶板外",
    key: "dingbanwai",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "顶板内",
    key: "dingbannei",
    initialMaterialId: "la_si_wu_zhi_wen",
}, {
    name: "门外",
    key: "menwai",
    initialMaterialId: "men_wai_cai_liao",
}, {
    name: "门内",
    key: "mennei",
    initialMaterialId: "la_si_wu_zhi_wen",
}];

export default forwardRef(function New3DOrder(props: {
    onCreateOrder: (orderSimple: any, orderDtail?: any) => void;
}, ref: any) {

    const [visible, setVisible] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [displayType, setDisplayType] = useState<"3d-room" | "3d">(null);

    const locale = useLocaleStore(state => state.locale);
    const { t } = useTranslation();


    useImperativeHandle(ref, () => {
        return {
            open: (initialValues: any) => {
                initialValues = initialValues || {};
                initialValues.boardTypes = {};
                for (let i = 0; i < boardTypes.length; i++) {
                    const boardType = boardTypes[i];
                    initialValues.boardTypes[boardType.key] = boardType.initialMaterialId;
                }
                setInitialValues(initialValues);
                setDisplayType(initialValues.displayType || "3d");
                setVisible(true);
            },
            close: close
        }
    });

    const close = () => {
        setVisible(false);
        setDisplayType(null);
        setSubmitting(false);
        setInitialValues({});
    }


    const formRef = useRef<any>();

    const onSubmit = (data: any) => {
        console.log(data);
        data.address = data.address.replace(/\\/g, '＼');
        const _boardTypes: {
            key: string;
            name: string;
            materialId: string; //index
        }[] = [];
        const usedMaterialIds = new Set<string>();
        for (const key in data.boardTypes) {
            if (Object.prototype.hasOwnProperty.call(data.boardTypes, key)) {
                const materialId = data.boardTypes[key];
                if(!materialId) {
                    message.warning(t("存在未设置板材的情况"));
                    return ;
                }
                const realBoardType = boardTypes.find((b) => b.key === key);
                _boardTypes.push({
                    key: realBoardType.key,
                    name: realBoardType.name,
                    materialId: materialId
                });
            }
        }
        const param = {
            ...data,
            boardTypes: _boardTypes,
            // materials: Array.from(usedMaterialIds).map((materialId, index) => {
            //     const material = materials.find((m) => m.id === materialId);
            //     return {
            //         id: index,
            //         name: material.name,
            //         materialId: material.id,
            //         texture: material.texture
            //     }
            // })
            materials: materials.map((material, index) => {
                return {
                    id: index,
                    name: material.name,
                    materialId: material.id,
                    texture: material.texture
                }
            })
        }
        setSubmitting(true);
        request.post(apis.order + "/create", param)
            .then((res) => {
                if(res.code ===1 ) {
                    const orderSimple = res.data;
                    request.get(apis.order + `/${res.data.id}/detail`)
                        .then((res) => {
                            if(res.code === 1) {
                                localStorage.setItem("SK_CURRENT_ORDER", JSON.stringify(res.data));
                                message.success(t("创建成功, 订单号为：") + res.data.orderNo);
                                close();
                                props.onCreateOrder(orderSimple, res.data);
                            }else {
                                props.onCreateOrder(orderSimple);
                            }
                        }).catch((e) => {
                            props.onCreateOrder(orderSimple);
                        });
                }else {
                    message.warning(res.msg || res.message);
                }
            }).catch((e) => {
                message.warning(t("网络异常"));
            }).finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <ConfigProvider
            theme={{
                algorithm: [theme.defaultAlgorithm]
            }}
        >
            <Modal
                centered
                open={visible}
                onCancel={close}
                destroyOnClose
                onOk={() => {
                    formRef.current?.submit();
                }}
                okText={submitting ? t('正在创建') : t('确认创建')}
                okButtonProps={{
                    loading: submitting,
                    disabled: submitting
                }}
                width={locale === 'zh-CN' ? 560 : 1080}

            >
                <PageHeader
                    title={displayType === '3d' ? t("新建3D订单") : t("完善订单信息")}
                >
                    <Form
                        ref={formRef}
                        onFinish={onSubmit}
                        labelCol={{ span: 5 }}
                        initialValues={initialValues}
                    >
                        <Form.Item hidden name="displayType"/>
                        <Form.Item name="address" label={t("客户地址")} rules={[{ required: true, message: t("请输入客户地址") }]}>
                            <Input placeholder={t("请输入客户地址")} autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="phoneNumber" label={t("联系方式")}>
                            <Input placeholder={t("请输入联系方式")} />
                        </Form.Item>
                        <Form.Item name="brand" label={t("橱柜品牌")}>
                            <Input placeholder={t("请输入橱柜品牌")} />
                        </Form.Item>
                        <Form.Item name="boardTypes" rules={[{required:  true, message: t("请设置板材")}]}>
                            <BoardTypeMaterialSelect

                            />
                        </Form.Item>
                    </Form>
                </PageHeader>
            </Modal>
        </ConfigProvider>
    )

});


export const BoardTypeMaterialSelect = (props: {
    value?: { [key: string]: string };
    onChange?: (value?: { [key: string]: string }) => void
}) => {

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(value);
    }, [props.value]);

    const [selectableMaterials, setSelectedMaterials] = useState<Material[]>(materials);

    const renameRef = useRef<any>();

    const { t } = useTranslation();

    const locale = useLocaleStore(state => state.locale);

   

    return (
        <>
            <div style={{ marginLeft: 24, marginBottom: 10 }}>{t("板材选择：")}</div>
            <div className="flex" style={{ flexWrap: 'wrap' }}>
                {
                    boardTypes.map((bt) => {
                        return (
                            <div className="flex" style={{ marginBottom: 10, width: locale === 'zh-CN' ?  220 : 500 }}>
                                <div style={{ width: locale === 'zh-CN' ? 120 : 160, flexShrink: 0, textAlign: 'right', padding: '0 12px' }}>{t(bt.name)}:</div>
                                <div className="">
                                <Select style={{width: locale === 'zh-CN' ? 140 : 280}} value={value ? value[bt.key] : undefined} onChange={(v) => {
                                    let _value = value || {};
                                    _value[bt.key] = v;
                                    _value = { ..._value };
                                    setValue(_value);
                                    props.onChange && props.onChange(_value);
                                }}>
                                    {
                                        selectableMaterials.map((m) => {
                                            return (
                                                <Select.Option value={m.id}>
                                                    <div className="flex" style={{ alignItems: 'center' }}>

                                                        {
                                                            m.texture.type === 'COLOR' ? (
                                                                <div style={{ height: 16, width: 16, borderRadius: 4, backgroundColor: m.texture.value }} />
                                                            ) : (

                                                                <div style={{ height: 16, width: 16, borderRadius: 4, backgroundColor: 'gray', overflow: "hidden" }}>
                                                                    <img src={m.texture.value} width="100%" height="100%" />
                                                                </div>
                                                            )
                                                        }
                                                        <span style={{ marginLeft: 4 }}>
                                                            {t(m.name)}
                                                        </span>
                                                    </div>
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                {
                                    bt.key === 'menwai' && (
                                        <Button type="link" onClick={() => {
                                            renameRef.current?.open(t(bt.name));
                                        }}><EditOutlined /> {t("门外板重命名")}</Button>
                                        
                                    )
                                }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <RenameModal ref={renameRef} onRename={(newName) => {
                const material = selectableMaterials.find((m) => m.id === 'men_wai_cai_liao');
                material.name = newName;
                setSelectedMaterials([...selectableMaterials]);
            }}/>
        </>

    )

}

export const RenameModal = forwardRef((props:{ onRename: (newName: string) => void }, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [temp, setTemp] = useState("");

    useImperativeHandle(ref, () => {
        return {
            open: (name: string) => {
                setTemp(name);
                setVisible(true);
            }
        }
    });
    const close = () => {
        setVisible(false);
    }

    const formRef = useRef<any>();

    const onSubmit = (data: { name: string }) => {
        props.onRename(data.name);
        close();
    }

    const { t } = useTranslation();

    return (
        <Modal
            centered
            open={visible}
            title={t("重命名")}
            onCancel={close}
            onOk={() => {
                formRef.current?.submit();
            }}
        >
            <Form
                ref={formRef}
                onFinish={onSubmit}
            >
                <Form.Item
                    label={t("门外板材料名称")}
                    name="name"
                    rules={[{required: true, message: t("请输入门外板材料名称")}]}
                >
                    <Input placeholder={t("请输入门外板材料名称")} />
                </Form.Item>
            </Form>

        </Modal>
    )

})