import { FileTextFilled, HighlightFilled } from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"

export const Left0Widget = (props: {
    mode: "3d" | "2d";
    onChangeTo3D: () => void;
    onChangeTo2D: () => void;
}) => {

    const navigate = useNavigate();

    const location = useLocation();
    

    return (
        <div className="left-menu-0 fixed no-scroll-bar" style={{ paddingLeft: 4, top: (props.mode === '3d' ?  0 : 52), paddingTop: 2, zIndex: 2, left: 0, bottom: 0, overflow: 'hidden' }}>
            {
                props.mode === '3d' && (
                    <div style={{height: 52}}/>
                )
            }
            <div onClick={() => {
                props.onChangeTo2D();
            }} className={`cate-item flex center ${props.mode === '2d' ? 'active' : ''}`} style={{ height: 50, cursor: "pointer", justifyContent: 'flex-start' }}>
                {/* <HighlightFilled style={{ fontSize: 20, marginLeft: 8 }} /> */}
                <i className="iconfont icon-floor-planner" style={{ fontSize: 18, marginLeft: 8 }} />
                <div className="title" style={{ marginLeft: 12, whiteSpace: 'nowrap' }}>房间绘制</div>
            </div>
            <div onClick={() => {
                props.onChangeTo3D();
            }} className={`cate-item flex center ${props.mode === '3d' ? 'active' : ''}`} style={{ height: 50, cursor: "pointer", justifyContent: 'flex-start' }}>
                {/* <HighlightFilled style={{ fontSize: 20, marginLeft: 8 }} /> */}
                <i className="iconfont icon-cupboard" style={{ fontSize: 20, marginLeft: 8 }} />
                <div className="title" style={{ marginLeft: 12, whiteSpace: 'nowrap' }}>橱柜设计</div>
            </div>
        </div>
    )

}