import { Input, message } from "antd"
import { useEffect, useState } from "react"
import { Layer, Shape, Stage } from "react-konva";
import { B_Arc, B_Circle, B_Line } from "../../../../../@interface";

interface Props {

}

export const CornerTest = (props: Props) => {

    const [json, setJson] = useState("");
    const [data, setData] = useState<(B_Arc | B_Line | B_Circle)[]>([]);
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [stageScale, setStageScale] = useState(1);

    const [lineWith, setLineWidth] = useState(1);
    
    useEffect(() => {
        if (!stageScale) {
            return;
        }
        const scale = 1 / stageScale;
        let width = scale;
        if (width > 1) {
            width = 1;
        }
        setLineWidth(width);
    }, [stageScale]);
    
    return (
        <div>
            {/* 输入json */}
            <Input.TextArea
                placeholder="粘贴格式为 Shap[] JSON"
                onChange={(e) => {
                    setJson(e.target.value);
                }}
                onBlur={() => {
                    if (!json) {
                        return;
                    }
                    try {
                        setData(JSON.parse(json));
                    } catch (e) {
                        message.warning("JSON.parse() error");
                    }
                }}
            />
            {/* 图形展示 */}
            {
                data.length > 0 && (
                    <Stage

                        x={stageX}
                        y={stageY}
                        scaleX={stageScale} scaleY={stageScale} 
                        width={innerWidth} height={innerHeight - 54} style={{
                            width: innerWidth, height: innerHeight - 54, background: "#000"
                        }}
                        onWheel={(e) => {

                            e.evt.preventDefault();
                            const stage = e.target.getStage();
                            const pointer = stage.getPointerPosition();
                            const oldScale = stage.scaleX();
                            const scaleBy = 1.2;

                            var mousePointTo = {
                                x: (pointer.x - stage.x()) / oldScale,
                                y: (pointer.y - stage.y()) / oldScale,
                            };
                            // how to scale? Zoom in? Or zoom out?
                            let direction = e.evt.deltaY > 0 ? -1 : 1;

                            // when we zoom on trackpad, e.evt.ctrlKey is true
                            // in that case lets revert direction
                            if (e.evt.ctrlKey) {
                                direction = -direction;
                            }

                            var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                            // stage.scale({ x: newScale, y: newScale });

                            var newPos = {
                                x: pointer.x - mousePointTo.x * newScale,
                                y: pointer.y - mousePointTo.y * newScale,
                            };
                            setStageScale(newScale);
                            setStageX(newPos.x);
                            setStageY(newPos.y);
                        }}
                    >

                        <Layer>
                            <Shape
                                strokeWidth={lineWith}
                                stroke="#fff"
                                sceneFunc={(context, shape) => {
                                    console.log(data);
                                    data.forEach((item) => {
                                        //@ts-ignore
                                        if (item.length !== 3 && item.length !== 4 && item.length !== 5) {
                                            console.log("problem");

                                            console.log(item)
                                        }
                                    })
                                    const maxY = data.reduce((prev, item) => {
                                        if (item.length === 4) {
                                            return Math.max(prev, Math.max(item[1], item[3]));
                                        }
                                        return prev;
                                    }, Number.MIN_SAFE_INTEGER);

                                    const minY = data.reduce((prev, item) => {
                                        if (item.length === 4) {
                                            return Math.min(prev, Math.min(item[1], item[3]));
                                        }
                                        return prev;
                                    }, Number.MAX_SAFE_INTEGER);

                                    const maxX = data.reduce((prev, item) => {
                                        if (item.length === 4) {
                                            return Math.max(prev, Math.max(item[0], item[2]));
                                        }
                                        return prev;
                                    }, Number.MIN_SAFE_INTEGER);

                                    const minX = data.reduce((prev, item) => {
                                        if (item.length === 4) {
                                            return Math.min(prev, Math.min(item[0], item[2]));
                                        }
                                        return prev;
                                    }, Number.MAX_SAFE_INTEGER);
                                    const cornerWidth = maxX - minX;
                                    const cornerHeight = maxY - minY;
                                    console.log("maxY", maxY);
                                    console.log("maxX", maxX);
                                    console.log("minY", minY);
                                    console.log("minY", minY);
                                    console.log("cornerWidth", cornerWidth);
                                    console.log("cornerHeight", cornerWidth);

                                    const scale = 1 / (cornerWidth > cornerHeight ? cornerWidth / (innerWidth - 40) : cornerHeight / ((innerHeight - 54 - 40)));
                                    const offsetX = (innerWidth - (maxX - minX) * scale) / 2 - minX * scale;
                                    const offsetY = ((innerHeight - 54) - (maxY - minY) * scale) / 2 - minY * scale;
                                    
                                    const height = (innerHeight - 54);
                                    context.strokeStyle = "#fff";
                                    console.log("scale", scale);
                                    console.log("offset x,y", offsetX, offsetY);


                                    for (let i = 0; i < data.length; i++) {
                                        context.beginPath();
                                        const item = data[i];
                                        if (item.length === 3) {
                                            console.log("circle")
                                            context.arc(item[0] * scale + offsetX, height - item[1] * scale - offsetY, item[2] * scale, 0, 2 * Math.PI);
                                        } else if (item.length === 4) {
                                            if (isNaN(item[0] * scale + offsetX)) {
                                                console.log("moveTo", [item[2], height - item[3]]);
                                                console.log("line", [item[0], height - item[1]])
                                                console.log("moveToTrans", [item[0] * scale + offsetX, height - item[1] * scale - offsetY]);
                                                console.log("lineToTrans", [item[2] * scale + offsetX, height - item[3] * scale - offsetY]);


                                                continue;
                                            }
                                            context.moveTo(item[0] * scale + offsetX, height - item[1] * scale - offsetY);
                                            context.lineTo(item[2] * scale + offsetX, height - item[3] * scale - offsetY);
                                        } else {
                                            console.log("arc")
                                            context.arc(
                                                item[0] * scale + offsetX, height - item[1] * scale - offsetY,
                                                item[2] * scale,
                                                (360 - item[3]) / 360 * 2 * Math.PI,
                                                (360 - item[4]) / 360 * 2 * Math.PI,
                                                true
                                            );
                                        }
                                        context.stroke();
                                    }
                                    context.closePath();
                                    context.fillStrokeShape(shape);
                                }}
                            />

                        </Layer>
                    </Stage>
                )
            }
        </div>
    )

}