import { BoardAction, BoardActionMap } from "./comp-draggable/draggable-data";

export const sheetBoardDragStore = {
    draggingLock: false,
    boardActionMaps: {} as BoardActionMap,
    globalActionList: [] as  BoardAction[],
    reset() {
        this.draggingLock = false;
        this.boardActionMaps = {} as BoardActionMap;
        this.globalActionList = [] as  BoardAction[];
    }
}