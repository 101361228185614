import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner3 } from "../../coners/corner3";
import { corner4 } from "../../coners/corner4";
import { rectBoard } from "../general-board";

// export const 
export const _09ceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
): [Path[], Path[]] => {
    const {
        上免拉手,
        上免拉手X,
        上免拉手Y,
        中免拉手,
        中免拉手X,
        中免拉手Y,
        中免拉手Z
    } = extraConfig;
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    const cornerBL = corner3(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR = corner3(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);


    // //右上角
    // const cornerTRSize = {width: 0, height: 0};
    // let cornerTR = corner1(dx, dy, startX, startY, true, cornerTRSize);
    // let cornerTRW: number = cornerTRSize.width;
    // let cornerTRH: number = cornerTRSize.height;
    // cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height)

    // //右上角
    // const cornerTLSize = {width: 0, height: 0};
    // let cornerTL = corner1(-dx, dy, startX, startY, true, cornerTLSize);
    // let cornerTLW: number = cornerTLSize.width;
    // let cornerTLH: number = cornerTLSize.height;
    // cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, width);
    // cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if (qiandanghengfang) {
        cornerTR = pathMoveX(cornerTR, -DirectX.RIGHT, width);
        cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        const widthTemp = cornerTRSize.width;
        cornerTRW = cornerTRSize.height;
        cornerTRH = widthTemp;
    }
    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    let cornerTL: Path[] = [];
    if (!上免拉手) {

        cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height)
        if (qiandanghengfang) {
            cornerTL = pathMoveY(cornerTL, -DirectY.UP, height - cornerTLSize.height);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTL = pathRotate2(cornerTL, startX, startY, 90);
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.height);
            const widthTemp = cornerTLSize.width;
            cornerTLW = cornerTLSize.height;
            cornerTLH = widthTemp;
        }
    } else {
        cornerTL.push({
            type: "Line",
            params: [
                startX, startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
            ]
        }, {
            type: "Line",
            params: [
                startX + dx * (上免拉手X + 10.5), startY + dy * height - dy * (上免拉手Y + 10.5),
                startX + dx * (上免拉手X + 10.5), startY + dy * height,
            ]
        });
        cornerTLH = 上免拉手Y + 10.5;
        cornerTLW = 上免拉手X + 10.5;
    }


    const boardConfig: any = {
        middleware: {
            top: false,
            left: false,
            right: height > 500 && "far",
            bottom: width > 500 && "close",
        }
    }
    let paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);

    if (中免拉手) {
        paths = paths.filter((_, index) => index !== 3);
        paths.push({
            type: Line,
            params: [
                startX, startY + dy * cornerBLH,
                startX, startY + dy * (中免拉手Z - 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z - 7.5),
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5),
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * (中免拉手X + 10.5), startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                startX, startY + dy * (中免拉手Z - 7.5 + 中免拉手Y)
            ]
        }, {
            type: Line,
            params: [
                startX, startY + dy * (中免拉手Z - 7.5 + 中免拉手Y),
                startX, startY + dy * height - dy * cornerTLH
            ]
        })
    }

    if (lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }

    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];
}