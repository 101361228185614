import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { menwaiBoard } from "../地柜/01-menwai";

export const _11cewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
 ): [Path[], Path[]]  => {
    const [ boardPaths, cornerPaths ] = menwaiBoard(cornerType, dx, dy, startX, startY, width, height, cornerType === "wujiao" ?  false : true, false, true);
    cornerPaths.push({
        type: Circle,
        params: [startX + dx * 74, startY + dy * 15.2, 5],
    }, {
        type: Circle,
        params: [startX + dx * width - dx * 74, startY + dy * 15.2, 5]
    })
    return [boardPaths, cornerPaths];
};