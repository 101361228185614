import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { clearCorner, pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner14 } from "../../coners/corner14";
import { corner2 } from "../../coners/corner2";
import { corner14WaiWuhujiao } from "../../coners/无护角/corner14-wai-wuhujiao";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner14WaiWujiao } from "../../coners/无角/corner14-wai-wujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { getBottomLine, getLeftLine, getRightLine, getTopLine } from "../general-board";

export const _05diwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    x: number,
    y: number,
    doorWidth: number,
    corner: boolean,
    nobeiban: boolean
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];// = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wujiao") {
        cornerBL = corner2WaiWujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner2WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);

    }
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR: Path[] = []; // = corner2(-dx, -dy, startX, startY,corner,  cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner2(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }else if(cornerType === "wujiao") {
        cornerBR = corner2WaiWujiao(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner2WaiWuhujiao(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }
    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];
    let cornerTLW: number = 0;
    let cornerTLH: number = 0;
    if(nobeiban) {
        if(cornerType === "youhujiao") {
            cornerTL = corner2(dx, dy, startX, startY, corner,  cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLH);
        }else if(cornerType === "wujiao") {
            cornerTL = corner2WaiWujiao(dx, dy, startX, startY, corner,  cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLH);
        }else if(cornerType === "wuhujiao") {
            cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY, corner,  cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLH);

        }
    }else {
        if(cornerType === "youhujiao") {
            cornerTL = corner14(dx, dy, startX, startY, corner,  cornerTLSize);
            cornerTL = pathRotate2(cornerTL, startX, startY, -90);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTLW = cornerTLSize.height;
            cornerTLH = cornerTLSize.width;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLW);
        }else if(cornerType === "wujiao") {
            cornerTL = corner14WaiWujiao(dx, dy, startX, startY, corner,  cornerTLSize);
            cornerTL = pathRotate2(cornerTL, startX, startY, -90);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTLW = cornerTLSize.height;
            cornerTLH = cornerTLSize.width;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLW);
        }else if(cornerType === "wuhujiao") {
            cornerTL = corner14WaiWuhujiao(dx, dy, startX, startY, corner,  cornerTLSize);
            cornerTL = pathRotate2(cornerTL, startX, startY, -90);
            cornerTL = pathMirrorY(cornerTL, startX);
            cornerTLW = cornerTLSize.height;
            cornerTLH = cornerTLSize.width;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height);
            cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLW);

        }
    }
    if(!corner) {
        cornerTL = clearCorner(cornerTL);
    }

    if(!corner) {
        cornerBLW = 0;
        cornerBLH = 0;
        cornerBRW = 0;
        cornerBRH = 0;
        cornerTLW = 0;
        cornerTLH = 0;
    }
    const b1Lines = getBottomLine("outer", dx, dy, startX, startY, width, 0, cornerBLW, cornerBRW, {
        middleware: {
            //@ts-ignore
            // bottom: width > 500 && "far"
        }
    });
    const r1Lines = getRightLine("outer", dx, dy, startX + dx * width, startY, 0, height - (y + 24), cornerBRH, 0, {
        middleware: {
            //@ts-ignore
            right: (height - (y + 24)) > 500 && "far"
        }
    });

    const t1Lines = getTopLine("outer", dx, dy, startX, startY + dy * height, width - (x + 24), 0, 0, cornerTLW, {
        middleware: {
            //@ts-ignore
            top: (width - (x + 24)) > 500 && "far"
        }
    });
    const l1Lines = getLeftLine("outer", dx, dy, startX, startY, 0, height, cornerTLH, cornerBLH, {
        middleware: {
            //@ts-ignore
            left: (height > 500) && "far"
        }
    });
    const paths: Path[] = [];
    paths.push(
    //     {
    //     type: Line,
    //     params: [
    //         startX + dx * cornerBLW, startY,
    //         startX + dx * width - dx * cornerBRW, startY,
    //     ]
    // }, 
    ...b1Lines,
    // {
    //     type: Line,
    //     params: [
    //         startX + dx * width, startY + dy * cornerBRH,
    //         startX + dx * width, startY + dy * height - dy * (y + 24),
            
    //     ]
    // }, 
    ...r1Lines,
    {
        type: Line,
        params: [
            startX + dx * width, startY + dy * height - dy * (y + 24),
            startX + dx * width - dx * (x + 24), startY + dy * height - dy * (y + 24),
        ]
    }, {
        type: Line,
        params: [
            startX + dx * width - dx * (x + 24), startY + dy * height - dy * (y + 24),
            startX + dx * width - dx * (x + 24), startY + dy * height,
        ]
    }, 
    // {
    //     type: Line,
    //     params: [
    //         startX + dx * width - dx * (x + 24), startY + dy * height,
    //         startX + dx * cornerTLW, startY + dy * height,
    //     ]
    // },
    ...t1Lines,
    // {
    //     type: Line,
    //     params: [
    //         startX, startY + dy * height - dy * cornerTLH,
    //         startX, startY + dy * cornerBLH
    //     ]
    // }
    ...l1Lines
    )


    //其他孔
    const corners: Path[] = [
        {
            type: Circle,
            params: [
                startX + dx * (doorWidth + 15), startY + dy * 45.03,
                2.61
            ]
        },{
            type: Circle,
            params: [
                startX + dx * (doorWidth + 15), startY + dy * 45.03 + dy * 15.75,
                3.11
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (doorWidth + 15), startY + dy * 45.03 + dy * 15.75 + 15.75,
                2.61
            ]
        },
        //=========================
        {
            type: Circle,
            params: [
                startX + dx * width - dx  * 32.3850088,  startY + dy * height - dy * (y + 76.529993),
                2.61
            ]
        },
        {
            type: Circle,
            params: [
                startX + dx * width - dx  * 32.3850088,  startY + dy * height - dy * (y + 76.529993) + dy * 15.75,
                3.11
            ]
        },
        {
            type: Circle,
            params: [
                startX + dx * width - dx  * 32.3850088,  startY + dy * height - dy * (y + 76.529993) + dy * 15.75 + dy * 15.75,
                2.61
            ]
        },
        {
            type: Line,
            params: [
                startX + dx * width, startY + dy * height - dy * (y + 24 + 20.07001),
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 20.07001),
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 20.07001),
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 20.07001) - dy * 1.92,
            ]
        },  {
            type: Line,
            params: [
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 20.07001) - dy * 1.92,
                startX + dx * width, startY + dy * height - dy * (y + 24 + 20.07001) - dy * 1.92,
            ]
        },
        {
            type: Line,
            params: [
                startX + dx * width, startY + dy * height - dy * (y + 24 + 51.57001),
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 51.57001),
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 51.57001),
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 51.57001) - dy * 1.92,
            ]
        },  {
            type: Line,
            params: [
                startX + dx * width - dx * 1.995, startY + dy * height - dy * (y + 24 + 51.57001) - dy * 1.92,
                startX + dx * width, startY + dy * height - dy * (y + 24 + 51.57001) - dy * 1.92,
            ]
        },
        //=========================
        {
            type: Circle,
            params: [
                startX + dx * width - dx * 78.78,  startY + dy * height - dy * (y + 33),
                3.11
            ]
        },
        {
            type: Circle,
            params: [
                startX + dx * width - dx * 78.78 + dx * 15.75,  startY + dy * height - dy * (y + 33),
                2.61
            ]
        },
        {
            type: Circle,
            params: [
                startX + dx * width - dx * 78.78 - dx * 15.75,  startY + dy * height - dy * (y + 33),
                2.61
            ]
        },
        //=========================
        
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x - 13.78),  startY + dy * height - dy * (y + 33),
                3.11
            ]
        },
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x - 13.78) + dx * 15.75,  startY + dy * height - dy * (y + 33),
                2.61
            ]
        },
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x - 13.78) - dx * 15.75,  startY + dy * height - dy * (y + 33),
                2.61
            ]
        },

        //=========================
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 33), startY + dy * height - dy * (y + 4.22),
                3.11
            ]
        },{
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 33), startY + dy * height - dy * (y + 4.22) - dy * 15.75,
                2.61
            ]
        },{
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 33), startY + dy * height - dy * (y + 4.22) + dy * 15.75,
                2.61
            ]
        },
        //==================================
        {
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 33), startY + dy * height - dy * 60.78,
                3.11
            ]
        },{
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 33), startY + dy * height - dy * 60.78 - dy * 15.75,
                2.61
            ]
        },{
            type: Circle,
            params: [
                startX + dx * width - dx * (x + 33), startY + dy * height - dy * 60.78 + dy * 15.75,
                2.61
            ]
        },
        //===================================
        
        
    ]
    if(!nobeiban) {
        corners.push(
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (x + 24 + 39.030056), startY + dy * height - dy * 32.385049,
                    2.61
                ]
            },
            
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (x + 24 + 39.030056) - dx * 15.75, startY + dy * height - dy * 32.385049,
                    3.11
                ]
            },
            
            {
                type: Circle,
                params: [
                    startX + dx * width - dx * (x + 24 + 39.030056) - dx * 15.75 - dx * 15.75, startY + dy * height - dy * 32.385049,
                    2.61
                ]
            },
            {
                type: Line,
                params: [
                    startX + dx * width - dx * (x + 24 + 38.070056), startY + dy * height,
                    startX + dx * width - dx * (x + 24 + 38.070056), startY + dy * height - dy * 1.995,
                ]
            },
            {
                type: Line,
                params: [
                    startX + dx * width - dx * (x + 24 + 38.070056), startY + dy * height - dy * 1.995,
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 1.92, startY + dy * height - dy * 1.995,
                ]
            },
            {
                type: Line,
                params: [
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 1.92, startY + dy * height - dy * 1.995,
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 1.92, startY + dy * height,
                ]
            },
    
            {
                type: Line,
                params: [
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 29.58, startY + dy * height,
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 29.58, startY + dy * height - dy * 1.995,
                ]
            },
            {
                type: Line,
                params: [
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 29.58, startY + dy * height - dy * 1.995,
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 29.58 - dx * 1.92, startY + dy * height - dy * 1.995
                ]
            },
            {
                type: Line,
                params: [
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 29.58 - dx * 1.92, startY + dy * height - dy * 1.995,
                    startX + dx * width - dx * (x + 24 + 38.070056) - dx * 29.58 - dx * 1.92, startY + dy * height,
                ]
            },
            )
    }

    return [
        paths,
        corners.concat(cornerBL, cornerBR, cornerTL)
    ]
 }