import { menneiBoard } from "../../../boards/地柜/01-mennei";
import { menwaiBoard } from "../../../boards/地柜/01-menwai";
import { sanheyi01BeineiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-beinei";
import { sanheyi01BeiwaiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-beiwai";
import { sanheyi01CeneiBoardLeft } from "../../../boards/地柜/sanheyi/sanheyi-01-cenei-left";
import { sanheyi01CeneiBoardRight } from "../../../boards/地柜/sanheyi/sanheyi-01-cenei-right";
import { sanheyi01CewaiBoardLeft } from "../../../boards/地柜/sanheyi/sanheyi-01-cewai-left";
import { sanheyi01CewaiBoardRight } from "../../../boards/地柜/sanheyi/sanheyi-01-cewai-right";
import { sanheyi01DineiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-dinei";
import { sanheyi01DiwaiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-diwai";
import { sanheyi01GecengneiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-gecengnei";
import { sanheyi01GecengwaiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-gecengwai";
import { sanheyi01MenneiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-mennei";
import { sanheyi01MenwaiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-menwai";
import { sanheyi01QiandangneiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-qiandangnei";
import { sanheyi01QiandangwaiBoard } from "../../../boards/地柜/sanheyi/sanheyi-01-qiandangwai";
import { BaseCupboard } from "../../@base/BaseCupboard";

export class SanheyiDiguiCebaobei extends BaseCupboard {
    
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: sanheyi01DineiBoard,
            diwaiBoard: sanheyi01DiwaiBoard,
            cewaiBoardLeft: sanheyi01CewaiBoardLeft,
            cewaiBoardRight: sanheyi01CewaiBoardRight,
            ceneiBoardLeft: sanheyi01CeneiBoardLeft,
            ceneiBoardRight: sanheyi01CeneiBoardRight,
            beiwaiBoard: sanheyi01BeiwaiBoard,
            beineiBoard: sanheyi01BeineiBoard,
            qiandangneiBoard: sanheyi01QiandangneiBoard,
            qiandangwaiBoard: sanheyi01QiandangwaiBoard,
            menwaiBoard: sanheyi01MenwaiBoard,
            menneiBoard: sanheyi01MenneiBoard,
            "menwaiBoard-left": menwaiBoard,
            "menneiBoard-left": menneiBoard,
            "menwaiBoard-right": menwaiBoard,
            "menneiBoard-right": menneiBoard,
            gecengneiBoard: sanheyi01GecengneiBoard,
            gecengwaiBoard: sanheyi01GecengwaiBoard,
        };
    }
    // setTypeMap(): { [boardName: string]: BoardTypeKey; } {
    //     return {
    //         beineiBoard: "beinei",
    //         beiwaiBoard: "beiwai",
    //         ceneiBoardLeft: "guinei",
    //         ceneiBoardRight: "guiwai",
    //         cewaiBoardLeft: "guinei",
    //         cewaiBoardRight: "guiwai",
    //         dineiBoard: "guinei",
    //         diwaiBoard: "guiwai",
    //         menneiBoard: "mennei",
    //         menwaiBoard: "menwai",
    //         qiandangneiBoard: "guinei",
    //         qiandangwaiBoard: "guiwai",
    //         gecengneiBoard: "guinei",
    //         gecengwaiBoard: "guiwai",
    //     }
    // }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const size: { [name: string]: { size: [number, number], rotate90?: boolean, quantity?: number } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [deep + 47, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep + 21, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21, height + 3],
            },
            "beiwaiBoard": {
                size: [long + 11, height + 29]
            },
            "beineiBoard": {
                size: [long - 15, height + 3]
            },
            "qiandangwaiBoard": {
                size: [long + 11, 74 + 47],
                //rotate90: true,
            },
            "qiandangneiBoard": {
                size: [long - 44, 74 + 21],
                //rotate90: true,
            }
        };
        if(extraConfig.doorNumber === 2) {
            size["menneiBoard-left"] = {
                size: [long / 2 - 2 + 21, /* height + 21 - 40 */ extraConfig.mengao + 19],
                quantity: 1
            };
            size["menwaiBoard-left"] = {
                size: [long / 2 - 2 + 47, /* height + 47 - 40 */extraConfig.mengao + 45],
                quantity: 1,
            }
            size["menneiBoard-right"] = {
                size: [long / 2 - 2 + 21, /* height + 21 - 40 */ extraConfig.mengao + 19],
                quantity: 1
            };
            size["menwaiBoard-right"] = {
                size: [long / 2 - 2 + 47, /* height + 47 - 40 */extraConfig.mengao + 45],
                quantity: 1,
            }
        }else {
            size["menneiBoard"] = {
                size: [long - 2 + 21, /* height + 21 - 40 */extraConfig.mengao + 19],
                quantity: 1
            };
            size["menwaiBoard"] = {
                size: [long - 2 + 47, /* height + 47 - 40 */extraConfig.mengao + 45],
                quantity: 1,
            }
        }
        if (extraConfig.geceng) {
            size['gecengwaiBoard'] = {
                size: [long + 11, deep - extraConfig.gecengX + 29],
            };
            size['gecengneiBoard'] = {
                size: [long - 15, deep - extraConfig.gecengX + 3]
            };
        }
        //console.log(extraConfig.nobeinei)
        if (extraConfig.nobeinei) {
            delete size["beineiBoard"];
        }
        return size;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.guituiX, extraConfig.guituiY);
        } else if (board.name.indexOf("cewaiBoard") === 0) {
            if (extraConfig.doorNumber === 2) {
                temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.mengao);
            } else {
                if (board.name.toLowerCase().includes(extraConfig.doorSide)) {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.mengao);
                } else {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, -1, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.mengao);
                }
            }
        } else if (board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju);
        } else if (board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide);
        } else if (board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.jiaolianbianju, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide)
        } else if (board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }
    
}