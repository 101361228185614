import { Vector2d } from "konva/lib/types";

interface Line {
    start: Vector2d;
    end: Vector2d;
}

export function areaLinesIntersecting(line1: Line, line2: Line): boolean {
    const { start: p1, end: p2 } = line1;
    const { start: q1, end: q2 } = line2;

    const orientation1 = getOrientation(p1, p2, q1);
    const orientation2 = getOrientation(p1, p2, q2);
    const orientation3 = getOrientation(q1, q2, p1);
    const orientation4 = getOrientation(q1, q2, p2);

    if (
        orientation1 !== orientation2 &&
        orientation3 !== orientation4
    ) {
        return true;
    }

    if (
        orientation1 === 0 &&
        isPointOnLine(p1, p2, q1)
    ) {
        return true;
    }

    if (
        orientation2 === 0 &&
        isPointOnLine(p1, p2, q2)
    ) {
        return true;
    }

    if (
        orientation3 === 0 &&
        isPointOnLine(q1, q2, p1)
    ) {
        return true;
    }

    if (
        orientation4 === 0 &&
        isPointOnLine(q1, q2, p2)
    ) {
        return true;
    }

    return false;
}

function getOrientation(p: Vector2d, q: Vector2d, r: Vector2d): number {
    const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);

    if (val === 0) {
        return 0;
    }

    return val > 0 ? 1 : 2;
}

function isPointOnLine(p: Vector2d, q: Vector2d, r: Vector2d): boolean {
    return (
        r.x >= Math.min(p.x, q.x) &&
        r.x <= Math.max(p.x, q.x) &&
        r.y >= Math.min(p.y, q.y) &&
        r.y <= Math.max(p.y, q.y)
    );
}