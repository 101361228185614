import { Vector2d } from "konva/lib/types";
import { useMemo } from "react";
import { Circle, Line } from "react-konva";

export const ShapeDrawWallHelpers = (props: {
    wallThickness: number;

    isShowAnchor: boolean;
    anchors: Vector2d[];

    isShowHelperLine: boolean;
    showingHelperLines: { targetAnchor: Vector2d, approachEndAnchor: Vector2d }[];

    isShowingCloseLine: boolean;
    closeWallAnchors: Vector2d[];
    showingInnerContour: Vector2d[];
    showingOuterContour: Vector2d[];
}) => {

    const {
        wallThickness,
        isShowAnchor, anchors,
        isShowHelperLine, showingHelperLines,
        isShowingCloseLine, closeWallAnchors, showingInnerContour, showingOuterContour
    } = props;

    return (
        <>
            {
                useMemo(() => {
                    // 渲染辅助线或辅助点
                    if(!isShowHelperLine) {
                        return false;
                    }
                    console.log("[render] helper lines");
                    
                    return showingHelperLines.map(helperLine => {
                        const { targetAnchor, approachEndAnchor } = helperLine;
                        console.log(helperLine);
                        if(!targetAnchor || !approachEndAnchor) {
                            return ;
                        }

                        return (
                            <>
                                <Circle x={targetAnchor.x} y={targetAnchor.y} fill="cyan" radius={wallThickness / 4} />
                                <Line
                                    points={[
                                        targetAnchor.x, targetAnchor.y,
                                        approachEndAnchor.x, approachEndAnchor.y
                                    ]}
                                    stroke={"cyan"}
                                    // strokeWidth={4}
                                    strokeWidth={2}
                                    strokeScaleEnabled={false}
                                    opacity={0.6}
                                    dash={[3, 2]}
                                />
                            </>
                        )
                    });
                }, [isShowHelperLine, showingHelperLines])
            }

            {
                useMemo(() => {
                    if(!isShowAnchor) {
                        return (<></>);
                    }
                    return anchors && anchors.map(anchor => (
                        <Circle x={anchor.x} y={anchor.y} fill={"cyan"} opacity={0.6} radius={wallThickness / 4} />
                    ));
                }, [isShowAnchor, anchors])
            }

            {
                useMemo(() => {
                    if (!isShowingCloseLine) {
                        return false;
                    }
                    console.log("[render] close line");
                    const points: number[] = [];
                    closeWallAnchors.map((v) => {
                        points.push(v.x, v.y);
                    });
                    const innerOutlinePoints: number[] = [];
                    const outerOutlinePoints: number[] = [];
                    showingInnerContour.forEach((v) => {
                        innerOutlinePoints.push(v.x, v.y);
                    });
                    showingOuterContour.forEach((v) => {
                        outerOutlinePoints.push(v.x, v.y);
                    });
                    if (innerOutlinePoints.length >= 2) {
                        innerOutlinePoints.push(innerOutlinePoints[0], innerOutlinePoints[1]);
                    }
                    if (outerOutlinePoints.length >= 2) {
                        outerOutlinePoints.push(outerOutlinePoints[0], outerOutlinePoints[1]);
                    }
                    return (
                        <>

                            <Line
                                points={outerOutlinePoints}
                                opacity={1}
                                stroke={"cyan"}
                                strokeWidth={2}
                                strokeScaleEnabled={false}
                            />
                            <Line
                                points={innerOutlinePoints}
                                opacity={1}
                                stroke={"cyan"}
                                strokeWidth={2}
                                strokeScaleEnabled={false}
                            />
                        </>
                    )
                }, [isShowingCloseLine, closeWallAnchors, showingOuterContour, showingInnerContour])
            }

        </>
    )

}