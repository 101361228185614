import { DirectX, DirectY } from "../../../constants/enum";
import { qiandangneiBoard } from "../地柜/01-qiandangnei";

export const _07qiandangneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return qiandangneiBoard(dx, dy, startX, startY, width, height);
}