
import { DirectX, DirectY } from "../../../../constants/enum";
import { sanheyi01MenneiBoard } from "./sanheyi-01-mennei";

export const sanheyi01GecengneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
 ): [Path[], Path[]]  => {

   //  return _05beineiBoard(dx, dy, startX, startY, width, height);
   return sanheyi01MenneiBoard(dx, dy, startX, startY, width, height, true, -1, true);
 }