export enum DirectX {
    RIGHT = 1,
    LEFT = -1,
} 

export enum DirectY {
    UP = 1,
    DOWN = -1,

}


export enum DxfLayer {
    CORNER = "L_CORNER",
    BOARD = "L_BOARD",
}