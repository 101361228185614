import { Arc, Circle, Line } from "../../../../constants";
import { DirectX, DirectY } from "../../../../constants/enum";
import { cornerMirrorX, pathMoveX, pathMoveY } from "../../../../util/graph";
import { corner14Sanheyi } from "../../../coners/三合一/corner14-sanheyi";
import { corner15Sanheyi } from "../../../coners/三合一/corner15-sanheyi";
import { rectBoard } from "../../general-board";

// export const 
export const sanheyi02CewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number
): [Path[], Path[]] => {
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];//　 = corner14(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner14Sanheyi(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);
    }
    // if(!corner) {
    //     cornerBL = clearCorner(cornerBL);
    // }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];// = corner15(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner15Sanheyi(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - 3 * cornerBRSize.width);
    }
    // if(!corner) {
    //     cornerBR = clearCorner(cornerBR);
    // }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];// = corner15(dx, dy, startX, startY, corner, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner15Sanheyi(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    }

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];//
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner14Sanheyi(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }

    let paths: Path[];
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0);
    }
    if (lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        });
    }
    if (jiaolianbianju > -1) {

        paths.push({
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju + 4),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju + 4),
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju + 4) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju + 4)
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6),
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6),
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6)
            ]
        },
            //**********************
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju + 4) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 32,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * (jiaolianbianju + 4) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8 + dy * 32,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 32
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 32,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4 + dy * 32,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 32
            ]
        },
            //==============================================
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * height - dy * (jiaolianbianju + 4 + 40),
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40),
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 8,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 8,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40)
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42),
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42),
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 4,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42)
            ]
        },
            //******************* 
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 64.75, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 32,
                startX + dx * width - dx * 64.75 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 8 + dy * 32,
                startX + dx * width - dx * 64.75 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40) + dy * 32
            ]
        },
            //----------------
            {
                type: Arc,
                params: [
                    startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 32,
                    3.25,
                    180, 360
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 32,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 4 + dy * 32,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42) + dy * 32
            ]
        },
        )
    }
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        paths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                1.5
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                1.5
            ]
        });
    }
    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ];
}
