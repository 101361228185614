import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { Material, NestBoard, NestRequest, NestResult, NestSheet } from "../../../../../@interface";
import { useNavigate } from "react-router-dom";
import { useDroppable } from "@dnd-kit/core";
import { NestSheetView } from "../../../components/sheet-view";
import { RightOutlined } from "@ant-design/icons";
import { BoardAction, BoardActionMap, BoardMoveAction, BoardMoveToSheetAction, BoardRotateAction } from "./draggable-data";

export const DroppableSheet = memo(forwardRef((props: {
  data: NestRequest;
  materialNest: NestResult;
  sheet: NestSheet;
  sheetIndex: number;
  materialMap: Map<string | number, Material>;
  disableDrag?: boolean;
}, ref: any) => {
  // console.log("drappable sheet");


  const navigate = useNavigate();
  const { isOver, setNodeRef } = useDroppable({
      id: `droppable-${props.sheetIndex}`,
      disabled: props.disableDrag,
      data: props.sheet
  });


  return (
      <div ref={setNodeRef} className={`item sub-item flex ${isOver ? 'over' : ''}`} onClick={() => {
          navigate(`/nest/result/${props.materialNest.materialId}/${props.sheet.id}`, {
              state: { data: props.data },
              replace: true
          });
      }}>
          <OneTimeRenderNestSheetView
              ref={ref}
              displayWidth={50}
              materialNest={props.materialNest}
              sheet={props.sheet}
              sheetIndex={props.sheetIndex}

              materialMap={props.materialMap}
              compatibleV1={props.data?.compatibleV1}
          />
      </div>
  )

}))

const OneTimeRenderNestSheetView = memo(forwardRef((props: {
  materialNest: NestResult;
  sheet: NestSheet;
  sheetIndex: number;
  displayWidth: number;
  materialMap: Map<string | number, Material>,
  compatibleV1?: boolean;
}, ref: any) => {
  const [originSheet, setOriginSheet] = useState(props.sheet);
  const [renderSheet, setRenderSheet] = useState<NestSheet | null>(null);
  useEffect(() => {
    setOriginSheet(originSheet);
    setRenderSheet(JSON.parse(JSON.stringify(props.sheet)));
  }, [props.sheet]);

  useImperativeHandle(ref, () => {
      return {
        updateByBoardActions: (boardActionMap: BoardActionMap) => {
          const boards: NestBoard[] = [];
          originSheet.boards.forEach((originBoard, i) =>{
              const actions = boardActionMap[originBoard.board.id] || [];
              // const renderBoard = renderSheet.boards[i];

              //计算
              const boardRotateActions = actions.filter((a) => a.type === 'rotate');
              let rotate = false;
              if (boardRotateActions.length > 0) {
                  rotate = (boardRotateActions[boardRotateActions.length - 1] as BoardRotateAction).rotate;
              }
              const moveRotateActions = actions.filter((a) => a.type === 'move') as BoardMoveAction[];
              let deltaX = 0;
              let deltaY = 0;
              moveRotateActions.forEach((a) => {
                  deltaX += a.deltaX;
                  deltaY += a.deltaY;
              });
              let isMovedToOtherSheet = false;
              const boardMoveToSheetActions = actions.filter((a) => a.type === 'move-to-sheet') as BoardMoveToSheetAction[];
              if (boardMoveToSheetActions.length > 0) {
                  const lastMove = boardMoveToSheetActions[boardMoveToSheetActions.length - 1];
                  if (lastMove.targetSheetId !== originSheet.id) {
                      isMovedToOtherSheet = true;
                  }
              }
              if(!isMovedToOtherSheet){
                const newBoard = JSON.parse(JSON.stringify(originBoard)) as (typeof originBoard);
                newBoard.customX = originBoard.customX + deltaX;
                newBoard.customY = originBoard.customY + deltaY;
                if(rotate) {
                  newBoard.rotate =!newBoard.rotate;
                }
                boards.push(newBoard);
              }
          })
          if(renderSheet) {
            renderSheet.boards = boards;
            setRenderSheet({...renderSheet});

          }
        }
      }
  })

  return renderSheet && (
      <>
          <div style={{ width: 50, height: 50 * 2440 / 1220, background: "#000" }}>
              <NestSheetView
                  ref={ref}
                  displayWidth={50}
                  sheet={renderSheet}
                  materialMap={props.materialMap}
                  compatibleV1={props?.compatibleV1}
              />
          </div>
          <div className="flex column space-between" style={{ flex: 1, paddingLeft: 6 }}>
              <div className="title flex center space-between">
                  <div>大板{props.sheetIndex + 1}/{props.materialNest.sheets.length}</div>
                  <span className="flex center">
                      <span style={{ marginRight: 4, fontSize: 10 }}>编辑</span>
                      <RightOutlined style={{ fontSize: 12 }} />
                  </span>
              </div>
              <div style={{ fontSize: 12, lineHeight: '20px' }}>
                  <div>有{props.sheet.boards.length}块小板</div>
                  {/* <div>利用率10%</div> */}
              </div>
          </div>
      </>
  )
}))