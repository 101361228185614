import { Divider, Input, Modal, Radio, Button, message, Form, Checkbox, Select } from 'antd';
import React, { useEffect, useState } from 'react'


export const HanjieguiBoardAddModal = (props) => {

    const { hanjieguiBoardAddConfig, setHanjieguiBoardAddConfig } = props;
    const [hanjieguiBoardAddKey, setHanjieguiBoardAddKey] = useState("");
    const [templates, setTemplates] = useState([]);


    const [renameModalVisible, setRenameModalVisible] = useState(false);
    const [renameTemp, setRenameTemp] = useState("");
    const [currentTemp, setCurrentTemp] = useState({});
    const openRenameModal = (cur) => {
        setRenameModalVisible(true);
        setCurrentTemp(cur);
        setRenameTemp(cur.name);
    }
    const closeRenameModal = () => {
        setRenameModalVisible(false);
        setRenameTemp("");
    }

    const [officialData, setOfficialData] = useState(null);
    const [diaoguiOfficialData, setDiaoguiOfficialData] = useState(null);

    useEffect(() => {
        if (props.visible) {
            const local = localStorage.getItem("hanjiegui-templates");
            if (local) {
                const templates = JSON.parse(local);
                setTemplates(templates);
            }
        }
    }, [props.visible])

    return (
        <Modal
            title="添加柜板"
            visible={props.visible}
            centered
            onOk={() => {
                const config = {};
                if (hanjieguiBoardAddKey.indexOf("menban") > -1) {
                    config.isDoor = true;
                }
                if (hanjieguiBoardAddConfig.有切角) {
                    config.qiejiaoConfig = {
                        切角位置: hanjieguiBoardAddConfig.切角位置,
                        切角宽: Number(hanjieguiBoardAddConfig.切角宽),
                        切角高: Number(hanjieguiBoardAddConfig.切角高),
                        切角位置X: Number(hanjieguiBoardAddConfig.切角位置X),
                        切角位置Y: Number(hanjieguiBoardAddConfig.切角位置Y)
                    }
                }
                if (hanjieguiBoardAddConfig.有铰链) {
                    config.jiaolianConfig = {
                        上铰链边距: Number(hanjieguiBoardAddConfig.上铰链边距),
                        下铰链边距: Number(hanjieguiBoardAddConfig.下铰链边距),
                        开门方向: hanjieguiBoardAddConfig.开门方向,
                    }
                }
                if (hanjieguiBoardAddConfig.有门拉手) {
                    config.lashouConfig = {
                        门拉手类型: hanjieguiBoardAddConfig.门拉手类型,
                        门拉手方向: hanjieguiBoardAddConfig.门拉手方向,
                        拉手X: Number(hanjieguiBoardAddConfig.拉手X),
                        拉手Y: Number(hanjieguiBoardAddConfig.拉手Y),
                        拉手间距: Number(hanjieguiBoardAddConfig.拉手间距),
                        开门方向: hanjieguiBoardAddConfig.开门方向,
                    }
                }
                if(hanjieguiBoardAddConfig.有隔层) {
                    config.gecengConfig = {
                        隔层孔前X: Number(hanjieguiBoardAddConfig.隔层孔前X),
                        隔层孔后X: Number(hanjieguiBoardAddConfig.隔层孔后X),
                        隔层孔Y: Number(hanjieguiBoardAddConfig.隔层孔Y),
                        隔层孔径: Number(hanjieguiBoardAddConfig.隔层孔径),
                        开门方向: hanjieguiBoardAddConfig.开门方向,
                    }
                }
                const data = {
                    name: hanjieguiBoardAddConfig.name,
                    height: Number(hanjieguiBoardAddConfig.height),
                    width: Number(hanjieguiBoardAddConfig.width),
                    key: hanjieguiBoardAddKey,
                    boardName: "freeBoard",
                    hasInnerBoard: true,
                    hasOuterBoard: true,
                    config
                }
                props.onSave(data);
            }}
            onCancel={props.onCancel}
        >
            <div className="hanjiegui-modal" style={{ display: 'flex' }}>
                <div style={{ width: 100, borderRight: "1px solid rgba(255, 255, 255, .5)", paddingRight: 10, marginRight: 10 }}>
                    <div>
                        {
                            [{
                                key: "free-board",
                                name: "创建柜板",
                            }, {
                                key: "free-menban",
                                name: "创建门板"
                            }, {
                                key: "",
                                name: "使用模板"
                            }
                            ].map((item) => {
                                return (
                                    <div className="board-item" onClick={() => {
                                        setHanjieguiBoardAddKey(item.key);
                                    }} style={{ color: item.key === hanjieguiBoardAddKey ? 'lightblue' : '', fontSize: 16 }}>{item.name}</div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <Modal
                        visible={renameModalVisible}
                        title="模板重命名"
                        centered
                        onCancel={closeRenameModal}
                        onOk={() => {
                            currentTemp.name = renameTemp;
                            const index = templates.findIndex((t) => t.id === currentTemp.id);
                            if (index > 0) {
                                templates[index] = currentTemp;
                                setTemplates([...templates]);
                            }
                            localStorage.setItem("hanjiegui-templates", JSON.stringify(templates));
                            closeRenameModal();
                        }}
                    >
                        <Form.Item label="模板名称">
                            <Input placeholder="请输入模板名称" value={renameTemp} onChange={(e) => {
                                setRenameTemp(e.target.value)
                            }} />
                        </Form.Item>

                    </Modal>
                    {
                        !hanjieguiBoardAddKey && (
                            <>
                            <Divider>官方模板</Divider>
                            <div>
                                地柜
                                {
                                    !officialData ? (
                                        <Button type="link" onClick={() => {
                                            setOfficialData({
                                                key: "digui",
                                                长: "",
                                                深: "",
                                                高: "",
                                                前条: "",
                                                params: [{
                                                    name: "长",
                                                    value: 800,
                                                    type: "number",
                                                    required: true,
                                                }, {
                                                    name: "深",
                                                    value: 550,
                                                    type: "number",
                                                    required: true,
                                                }, {
                                                    name: "高",
                                                    value: 680,
                                                    type: "number"
                                                }, {
                                                    name: "前条",
                                                    value: "",
                                                    type: "number",
                                                    required: true,
                                                }, {
                                                    name: "是否带门板",
                                                    value: false,
                                                    type: "boolean",
                                                    required: true,
                                                    booleanDesc: {
                                                        true: "带门板",
                                                        false: "不带门板"
                                                    }
                                                }, {
                                                    name: "是否带层板",
                                                    value: false,
                                                    type: "boolean",
                                                    required: true,
                                                    booleanDesc: {
                                                        true: "带层板",
                                                        false: "不带层板"
                                                    }
                                                }, {
                                                    name: "门高",
                                                    value: '',
                                                    precondition: {
                                                        name: "是否带门板",
                                                        requiredValue: true,
                                                    },
                                                    type: "number",
                                                    required: true
                                                }, {
                                                    name: "门数",
                                                    value: "单门",
                                                    precondition: {
                                                        name: "是否带门板",
                                                        requiredValue: true,
                                                    },
                                                    type: "enum:单门|双门",
                                                }]
                                                
                                                // onSave: () => {
                                                //     message.info(JSON.stringify(officialData))
                                                // }
                                            });
                                        }}>使用该模板添加</Button>
                                    ) : (
                                        <div style={{marginTop: 20, width: 200, padding: '0 10px'}}>
                                            <Form >
                                                {
                                                    officialData?.params.length > 0 &&  officialData.params.map((item) => {
                                                        if(item.precondition) {
                                                            const param = officialData.params.find((itm) => itm.name === item.precondition.name);
                                                            if(item.precondition.requiredValue === param?.value) {

                                                            }else {
                                                                return undefined;
                                                            }
                                                        }
                                                        return (
                                                            <Form.Item label={item.name}>
                                                            {
                                                                item.type === 'number' ? (
                                                                    <Input suffix="mm" value={item.value} onChange={(e) => {
                                                                        item.value = e.target.value;
                                                                        setOfficialData({...officialData});
                                                                    }}/>
                                                                ) : item.type === 'boolean' ? (
                                                                    <span onClick={() => {
                                                                        item.value = !item.value;
                                                                        setOfficialData({...officialData});
                                                                    }}>
                                                                        <Checkbox checked={item.value} style={{marginRight: 8}}/> 
                                                                        <span>{item.booleanDesc[item.value + ""]}</span>
                                                                    </span>
                                                                ) : item.type.indexOf("enum") === 0 ? (() => {
                                                                    const values = item.type.replace("enum:", "").split("|");
                                                                    return (
                                                                        <Select value={item.value} onChange={(v) => {
                                                                            item.value = v;
                                                                            setOfficialData({...officialData});
                                                                        }}>
                                                                            {
                                                                                values.map((v) => {
                                                                                    return (
                                                                                        <Select.Option value={v}>{v}</Select.Option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>

                                                                    )
                                                                })() : (
                                                                    <></>
                                                                )
                                                            }
                                                                
                                                            </Form.Item>

                                                        )
                                                    })
                                                }
                                            <div style={{marginTop: 10}}>
                                                <Button type='primary' onClick={() => {
                                                    //校验
                                                    for (let i = 0; i < officialData?.params.length; i++) {
                                                        const param = officialData?.params[i];
                                                        if(param.required) {
                                                            let shouldCheck = false;
                                                            if(param.precondition) {    
                                                                const param2 = officialData.params.find((itm) => itm.name === param.precondition.name);
                                                                if(param.precondition.requiredValue === param2?.value) {
                                                                    shouldCheck = true;
                                                                }
                                                            }else {
                                                                shouldCheck = true;
                                                            }

                                                            if(shouldCheck) {
                                                                if(param.value === null || param.value === '' || param.value === undefined) {
                                                                    message.info("请输入" + param.name);
                                                                    return ;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    //类型转换
                                                    for (let i = 0; i < officialData?.params.length; i++) {
                                                        const param = officialData?.params[i];
                                                        if(param.type === 'number') {
                                                            param.value = Number(param.value);
                                                        }else if(param.type === 'boolean') {
                                                            param.value = Boolean(param.value);
                                                        }
                                                    }
                                                    //参数提取
                                                    const data = {};
                                                    for (let i = 0; i < officialData?.params.length; i++) {
                                                        const param = officialData?.params[i];
                                                        data[param.name] = param.value;
                                                    }
                                                    if(officialData.key === "digui") {
                                                        const { 长,  深, 高, 前条, 是否带层板, 是否带门板, 门高, 门数 } = data;
                                                        const temp = [{
                                                            name: "底板",
                                                            width: 长,
                                                            height: 深,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                        }, {
                                                            name: "左侧板",
                                                            width: 深,
                                                            height: 高 - 18,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                            config: {
                                                                jiaolianConfig: 是否带门板 ? {
                                                                    上铰链边距: 100 - 6,
                                                                    下铰链边距: 100 - 18,
                                                                    开门方向: "左开",
                                                                } : undefined,
                                                                gecengConfig: 是否带层板 ? {
                                                                    开门方向: "左开",
                                                                    隔层孔前X: 70,
                                                                    隔层孔后X: 50,
                                                                    隔层孔Y: 340,
                                                                    隔层孔径: 4.8
                                                                } : undefined
                                                            }
                                                        }, {
                                                            name: "右侧板",
                                                            width: 深,
                                                            height: 高 - 18,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                            config: {
                                                                jiaolianConfig: 是否带门板 && 门数 === '双门' ? {
                                                                    上铰链边距: 100 - 6,
                                                                    下铰链边距: 100 - 18,
                                                                    开门方向: "右开"
                                                                } : undefined,
                                                                gecengConfig: 是否带层板 ? {
                                                                    开门方向: "右开",
                                                                    隔层孔前X: 70,
                                                                    隔层孔后X: 50,
                                                                    隔层孔Y: 340,
                                                                    隔层孔径: 4.8
                                                                } : undefined
                                                            }
                                                        }, {
                                                            name: "背板",
                                                            width: 长 - 36,
                                                            height: 高 - 18,
                                                            key: hanjieguiBoardAddKey,
                                                            hasInnerBoard: false,
                                                            hasOuterBoard: true,
                                                        }, {
                                                            name: "前条",
                                                            width: 长 - 36,
                                                            height: 前条,
                                                            key: hanjieguiBoardAddKey,
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                        }];
                                                        if(是否带层板) {
                                                            temp.push({
                                                                name: "层板",
                                                                width: 长 - 36,
                                                                height: 深 - 18 - 50,
                                                                key: hanjieguiBoardAddKey,
                                                                hasInnerBoard: true,
                                                                hasOuterBoard: true,
                                                                config: {

                                                                }
                                                            })
                                                        }
                                                        if(是否带门板) {
                                                            if(门数 === '单门') {
                                                                temp.push({
                                                                    name: "门板",
                                                                    width: 长 + 45,
                                                                    height: 门高 + 45,
                                                                    key: hanjieguiBoardAddKey,
                                                                    hasInnerBoard: true,
                                                                    hasOuterBoard: true,
                                                                    config: {
                                                                        isDoor: true,
                                                                        jiaolianConfig: {
                                                                            上铰链边距: 100,
                                                                            下铰链边距: 100 - 18 * 2,
                                                                            开门方向: "左开",
                                                                            
                                                                        },
                                                                        lashouConfig: {
                                                                            门拉手类型: "暗拉手"
                                                                        }
                                                                    }
                                                                });
                                                            }else if(门数 === '双门') {
                                                                temp.push({
                                                                    name: "门板",
                                                                    width: (长 / 2) + 45,
                                                                    height: 门高 + 45,
                                                                    key: hanjieguiBoardAddKey,
                                                                    hasInnerBoard: true,
                                                                    hasOuterBoard: true,
                                                                    config: {
                                                                        isDoor: true,
                                                                        jiaolianConfig: {
                                                                            上铰链边距: 100,
                                                                            下铰链边距: 100 - 2 * 18,
                                                                            开门方向: "左开",
                                                                            
                                                                        },
                                                                        lashouConfig: {
                                                                            门拉手类型: "暗拉手"
                                                                        }
                                                                    }
                                                                },{
                                                                    name: "门板",
                                                                    width: (长 / 2) + 45,
                                                                    height: 门高 + 45,
                                                                    key: hanjieguiBoardAddKey,
                                                                    hasInnerBoard: true,
                                                                    hasOuterBoard: true,
                                                                    config: {
                                                                        isDoor: true,
                                                                        jiaolianConfig: {
                                                                            上铰链边距: 100,
                                                                            下铰链边距: 100 - 2 * 18,
                                                                            开门方向: "左开",
                                                                            
                                                                        },
                                                                        lashouConfig: {
                                                                            门拉手类型: "暗拉手"
                                                                        }
                                                                    }
                                                                });

                                                            }
                                                        }
                                                        props.onSaveFromBoards(temp);
                                                    }
                                                    message.success("已添加");
                                                    props.onCancel();
                                                    setOfficialData(null);

                                                }}>确认</Button>
                                                <Button type='default' style={{marginLeft: 10}} onClick={() => setOfficialData(null)}>取消</Button>                                                
                                            </div>
                                            </Form>
                                        </div>
                                    )
                                }
                                
                            </div>
                            <div>
                                吊柜
                                {
                                    !diaoguiOfficialData ? (
                                        <Button type="link" onClick={() => {
                                            setDiaoguiOfficialData({
                                                key: "digui",
                                                长: "",
                                                深: "",
                                                高: "",
                                                前条: "",
                                                params: [{
                                                    name: "长",
                                                    value: 800,
                                                    type: "number",
                                                    required: true,
                                                }, {
                                                    name: "深",
                                                    value: 350,
                                                    type: "number",
                                                    required: true,
                                                }, {
                                                    name: "高",
                                                    value: 680,
                                                    type: "number"
                                                },{
                                                    name: "是否带门板",
                                                    value: false,
                                                    type: "boolean",
                                                    required: true,
                                                    booleanDesc: {
                                                        true: "带门板",
                                                        false: "不带门板"
                                                    }
                                                }, {
                                                    name: "是否带层板",
                                                    value: false,
                                                    type: "boolean",
                                                    required: true,
                                                    booleanDesc: {
                                                        true: "带层板",
                                                        false: "不带层板"
                                                    }
                                                }, {
                                                    name: "门高",
                                                    value: '',
                                                    precondition: {
                                                        name: "是否带门板",
                                                        requiredValue: true,
                                                    },
                                                    type: "number",
                                                    required: true
                                                }, {
                                                    name: "门数",
                                                    value: "单门",
                                                    precondition: {
                                                        name: "是否带门板",
                                                        requiredValue: true,
                                                    },
                                                    type: "enum:单门|双门",
                                                }]
                                                
                                                // onSave: () => {
                                                //     message.info(JSON.stringify(officialData))
                                                // }
                                            });
                                        }}>使用该模板添加</Button>
                                    ) : (
                                        <div style={{marginTop: 20, width: 200, padding: '0 10px'}}>
                                            <Form >
                                                {
                                                    diaoguiOfficialData?.params.length > 0 &&  diaoguiOfficialData.params.map((item) => {
                                                        if(item.precondition) {
                                                            const param = diaoguiOfficialData.params.find((itm) => itm.name === item.precondition.name);
                                                            if(item.precondition.requiredValue === param?.value) {

                                                            }else {
                                                                return undefined;
                                                            }
                                                        }
                                                        return (
                                                            <Form.Item label={item.name}>
                                                            {
                                                                item.type === 'number' ? (
                                                                    <Input suffix="mm" value={item.value} onChange={(e) => {
                                                                        item.value = e.target.value;
                                                                        setDiaoguiOfficialData({...diaoguiOfficialData});
                                                                    }}/>
                                                                ) : item.type === 'boolean' ? (
                                                                    <span onClick={() => {
                                                                        item.value = !item.value;
                                                                        setDiaoguiOfficialData({...diaoguiOfficialData});
                                                                    }}>
                                                                        <Checkbox checked={item.value} style={{marginRight: 8}}/> 
                                                                        <span>{item.booleanDesc[item.value + ""]}</span>
                                                                    </span>
                                                                ) : item.type.indexOf("enum") === 0 ? (() => {
                                                                    const values = item.type.replace("enum:", "").split("|");
                                                                    return (
                                                                        <Select value={item.value} onChange={(v) => {
                                                                            item.value = v;
                                                                            setDiaoguiOfficialData({...diaoguiOfficialData});
                                                                        }}>
                                                                            {
                                                                                values.map((v) => {
                                                                                    return (
                                                                                        <Select.Option value={v}>{v}</Select.Option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>

                                                                    )
                                                                })() : (
                                                                    <></>
                                                                )
                                                            }
                                                                
                                                            </Form.Item>

                                                        )
                                                    })
                                                }
                                            <div style={{marginTop: 10}}>
                                                <Button type='primary' onClick={() => {
                                                    //校验
                                                    for (let i = 0; i < diaoguiOfficialData?.params.length; i++) {
                                                        const param = diaoguiOfficialData?.params[i];
                                                        if(param.required) {
                                                            let shouldCheck = false;
                                                            if(param.precondition) {    
                                                                const param2 = diaoguiOfficialData.params.find((itm) => itm.name === param.precondition.name);
                                                                if(param.precondition.requiredValue === param2?.value) {
                                                                    shouldCheck = true;
                                                                }
                                                            }else {
                                                                shouldCheck = true;
                                                            }

                                                            if(shouldCheck) {
                                                                if(param.value === null || param.value === '' || param.value === undefined) {
                                                                    message.info("请输入" + param.name);
                                                                    return ;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    //类型转换
                                                    for (let i = 0; i < diaoguiOfficialData?.params.length; i++) {
                                                        const param = diaoguiOfficialData?.params[i];
                                                        if(param.type === 'number') {
                                                            param.value = Number(param.value);
                                                        }else if(param.type === 'boolean') {
                                                            param.value = Boolean(param.value);
                                                        }
                                                    }
                                                    //参数提取
                                                    const data = {};
                                                    for (let i = 0; i < diaoguiOfficialData?.params.length; i++) {
                                                        const param = diaoguiOfficialData?.params[i];
                                                        data[param.name] = param.value;
                                                    }
                                                    if(diaoguiOfficialData.key === "digui") {
                                                        const { 长,  深, 高, 前条, 是否带层板, 是否带门板, 门高, 门数 } = data;
                                                        const temp = [{
                                                            name: "底板",
                                                            width: 长,
                                                            height: 深,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                        },{
                                                            name: "顶板",
                                                            width: 长,
                                                            height: 深,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                        }, {
                                                            name: "左侧板",
                                                            width: 深,
                                                            height: 高 - 18,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                            config: {
                                                                jiaolianConfig: 是否带门板 ? {
                                                                    上铰链边距: 100 - 6,
                                                                    下铰链边距: 100 - 18,
                                                                    开门方向: "左开",
                                                                } : undefined,
                                                                gecengConfig: 是否带层板 ? {
                                                                    开门方向: "左开",
                                                                    隔层孔前X: 70,
                                                                    隔层孔后X: 50,
                                                                    隔层孔Y: 340,
                                                                    隔层孔径: 4.8
                                                                } : undefined
                                                            }
                                                        }, {
                                                            name: "右侧板",
                                                            width: 深,
                                                            height: 高 - 18,
                                                            key: hanjieguiBoardAddKey,
                                                            boardName: "freeBoard",
                                                            hasInnerBoard: true,
                                                            hasOuterBoard: true,
                                                            config: {
                                                                jiaolianConfig: 是否带门板 && 门数 === '双门' ? {
                                                                    上铰链边距: 100 - 6,
                                                                    下铰链边距: 100 - 18,
                                                                    开门方向: "右开"
                                                                } : undefined,
                                                                gecengConfig: 是否带层板 ? {
                                                                    开门方向: "右开",
                                                                    隔层孔前X: 70,
                                                                    隔层孔后X: 50,
                                                                    隔层孔Y: 340,
                                                                    隔层孔径: 4.8
                                                                } : undefined
                                                            }
                                                        }, {
                                                            name: "背板",
                                                            width: 长 - 36,
                                                            height: 高 - 18,
                                                            key: hanjieguiBoardAddKey,
                                                            hasInnerBoard: false,
                                                            hasOuterBoard: true,
                                                        }];
                                                        if(是否带层板) {
                                                            temp.push({
                                                                name: "层板",
                                                                width: 长 - 36,
                                                                height: 深 - 18 - 50,
                                                                key: hanjieguiBoardAddKey,
                                                                hasInnerBoard: true,
                                                                hasOuterBoard: true,
                                                                config: {

                                                                }
                                                            })
                                                        }
                                                        if(是否带门板) {
                                                            if(门数 === '单门') {
                                                                temp.push({
                                                                    name: "门板",
                                                                    width: 长 + 45,
                                                                    height: 门高 + 45,
                                                                    key: hanjieguiBoardAddKey,
                                                                    hasInnerBoard: true,
                                                                    hasOuterBoard: true,
                                                                    config: {
                                                                        isDoor: true,
                                                                        jiaolianConfig: {
                                                                            上铰链边距: 100,
                                                                            下铰链边距: 100 - 18 * 2,
                                                                            开门方向: "左开",
                                                                            
                                                                        },
                                                                        lashouConfig: {
                                                                            门拉手类型: "暗拉手"
                                                                        }
                                                                    }
                                                                });
                                                            }else if(门数 === '双门') {
                                                                temp.push({
                                                                    name: "门板",
                                                                    width: (长 / 2) + 45,
                                                                    height: 门高 + 45,
                                                                    key: hanjieguiBoardAddKey,
                                                                    hasInnerBoard: true,
                                                                    hasOuterBoard: true,
                                                                    config: {
                                                                        isDoor: true,
                                                                        jiaolianConfig: {
                                                                            上铰链边距: 100,
                                                                            下铰链边距: 100 - 2 * 18,
                                                                            开门方向: "左开",
                                                                            
                                                                        },
                                                                        lashouConfig: {
                                                                            门拉手类型: "暗拉手"
                                                                        }
                                                                    }
                                                                },{
                                                                    name: "门板",
                                                                    width: (长 / 2) + 45,
                                                                    height: 门高 + 45,
                                                                    key: hanjieguiBoardAddKey,
                                                                    hasInnerBoard: true,
                                                                    hasOuterBoard: true,
                                                                    config: {
                                                                        isDoor: true,
                                                                        jiaolianConfig: {
                                                                            上铰链边距: 100,
                                                                            下铰链边距: 100 - 2 * 18,
                                                                            开门方向: "左开",
                                                                            
                                                                        },
                                                                        lashouConfig: {
                                                                            门拉手类型: "暗拉手"
                                                                        }
                                                                    }
                                                                });

                                                            }
                                                        }
                                                        props.onSaveFromBoards(temp);
                                                    }
                                                    message.success("已添加");
                                                    props.onCancel();
                                                    setOfficialData(null);

                                                }}>确认</Button>
                                                <Button type='default' style={{marginLeft: 10}} onClick={() => setOfficialData(null)}>取消</Button>                                                
                                            </div>
                                            </Form>
                                        </div>
                                    )
                                }
                            </div>
                            <Divider>自定义模板</Divider>
                                {
                                    templates.length > 0 && templates.map((t) => {
                                        return (
                                            <div style={{ padding: '3px 10px' }}>{t.name}, {t.boards.length}块板
                                                <br />
                                                <Button type="link" onClick={() => {
                                                    props.onSaveFromBoards(t.boards)
                                                }}>使用该模板添加</Button> <Button type="link" danger onClick={() => {
                                                    Modal.confirm({
                                                        title: "请确认是否删除模板",
                                                        content: "删除后无法恢复,请确认是否删除",
                                                        centered: true,
                                                        onOk: () => {
                                                            const _new = templates.filter((_t) => _t.id !== t.id);
                                                            setTemplates(_new);
                                                            localStorage.setItem("hanjiegui-templates", JSON.stringify(_new));
                                                            message.info("已删除");
                                                        }
                                                    })
                                                }}>删除该模板</Button> <Button type="link" style={{ color: 'gray' }} onClick={() => {
                                                    openRenameModal(t);
                                                }}>重命名</Button></div>
                                        )
                                    })
                                }
                            </>
                        )
                    }
                    {
                        hanjieguiBoardAddKey && (
                            <>
                                <div style={{ padding: 10 }}>
                                    <div>板名：</div>
                                    <div>
                                        <Input
                                            placeholder="请输入板名"
                                            value={hanjieguiBoardAddConfig.name}
                                            onChange={(e) => {
                                                hanjieguiBoardAddConfig.name = e.target.value;
                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ padding: 10 }}>
                                        <div>宽度：</div>
                                        <div>
                                            <Input
                                                placeholder="请输入宽度"
                                                suffix="mm"
                                                value={hanjieguiBoardAddConfig.width}
                                                onChange={(e) => {
                                                    hanjieguiBoardAddConfig.width = e.target.value;
                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ padding: 10 }}>
                                        <div>高度：</div>
                                        <div>
                                            <Input
                                                placeholder="请输入高度"
                                                suffix="mm"
                                                value={hanjieguiBoardAddConfig.height}
                                                onChange={(e) => {
                                                    hanjieguiBoardAddConfig.height = e.target.value;
                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{}}>

                                    <div style={{ display: 'flex', marginBottom: 10 }}>
                                        <div style={{ padding: 10, borderRight: '1px solid #444' }}>
                                            <div>铰链孔：</div>
                                            <div>
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    checked={hanjieguiBoardAddConfig.有铰链}
                                                    onChange={() => {
                                                        hanjieguiBoardAddConfig.有铰链 = !hanjieguiBoardAddConfig.有铰链;
                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                    }}
                                                />
                                                {hanjieguiBoardAddConfig.有铰链 ? '有铰链' : '无铰链'}
                                            </div>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <div>切角：</div>
                                            <div>
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    checked={hanjieguiBoardAddConfig.有切角}
                                                    onChange={() => {
                                                        hanjieguiBoardAddConfig.有切角 = !hanjieguiBoardAddConfig.有切角;
                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                    }}
                                                />
                                                {hanjieguiBoardAddConfig.有切角 ? '有切角' : '无切角'}
                                            </div>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <div>隔层：</div>
                                            <div>
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    checked={hanjieguiBoardAddConfig.有隔层}
                                                    onChange={() => {
                                                        hanjieguiBoardAddConfig.有隔层 = !hanjieguiBoardAddConfig.有隔层;
                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                    }}
                                                />
                                                {hanjieguiBoardAddConfig.有隔层 ? '有隔层' : '无隔层'}
                                            </div>
                                        </div>
                                        {
                                            hanjieguiBoardAddKey.indexOf("menban") > -1 && (

                                                <div style={{ padding: 10 }}>
                                                    <div>门拉手：</div>
                                                    <div>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={hanjieguiBoardAddConfig.有门拉手}
                                                            onChange={() => {
                                                                hanjieguiBoardAddConfig.有门拉手 = !hanjieguiBoardAddConfig.有门拉手;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                        {hanjieguiBoardAddConfig.有门拉手 ? '有门拉手' : '无门拉手'}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        hanjieguiBoardAddConfig.有铰链 && (
                                            <>
                                                <Divider>铰链设置</Divider>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>开门方向：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.开门方向} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.开门方向 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="左开">左开</Radio>
                                                            <Radio value="右开">右开</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ padding: 10 }}>
                                                        <div>上铰链边距</div>
                                                        <div>
                                                            <Input
                                                                placeholder="上铰链边距"
                                                                suffix="mm"
                                                                value={hanjieguiBoardAddConfig.上铰链边距}
                                                                onChange={(e) => {
                                                                    hanjieguiBoardAddConfig.上铰链边距 = e.target.value;
                                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: 10 }}>
                                                        <div>下铰链边距</div>
                                                        <div>
                                                            <Input
                                                                placeholder="下铰链边距"
                                                                suffix="mm"
                                                                value={hanjieguiBoardAddConfig.下铰链边距}
                                                                onChange={(e) => {
                                                                    hanjieguiBoardAddConfig.下铰链边距 = e.target.value;
                                                                    setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ padding: 10 }}>
                                                                <div>侧边边距</div>
                                                                <div>
                                                                    <Input
                                                                        placeholder="侧边边距"
                                                                        suffix="mm"
                                                                        value={hanjieguiBoardAddConfig.侧边边距}
                                                                        onChange={(e) => {
                                                                            hanjieguiBoardAddConfig.侧边边距 = e.target.value;
                                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                </div>

                                            </>
                                        )
                                    }

                                    {
                                        hanjieguiBoardAddConfig.有切角 && (
                                            <>
                                                <Divider>切角设置</Divider>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>切角位置：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.切角位置} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.切角位置 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="lt">左上</Radio>
                                                            <Radio value="lb">左下</Radio>
                                                            <Radio value="rt">右上</Radio>
                                                            <Radio value="rb">右下</Radio>
                                                            <Radio value="center">正中</Radio>
                                                            <Radio value="xy">自定义</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                {/* <div style={{ marginBottom: 10 }}>
                                                                <div>切角类型：</div>
                                                                <div>
                                                                    <Radio.Group value={hanjieguiBoardAddConfig.切角类型} onChange={(e) => {
                                                                        hanjieguiBoardAddConfig.切角类型 = e.target.value;
                                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                    }}>
                                                                        <Radio value="round">圆形</Radio>
                                                                        <Radio value="rect">矩形</Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div> */}
                                                {
                                                    hanjieguiBoardAddConfig.切角位置 === 'xy' && (
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ padding: '0 10px 10px 0' }}>
                                                                <div>切角位置X</div>
                                                                <div>
                                                                    <Input
                                                                        placeholder="请输入切角位置X"
                                                                        suffix="mm"
                                                                        value={hanjieguiBoardAddConfig.切角位置X}
                                                                        onChange={(e) => {
                                                                            hanjieguiBoardAddConfig.切角位置X = e.currentTarget.value;
                                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: '0 0 10px 10px' }}>
                                                                <div>切角位置Y</div>
                                                                <div>
                                                                    <Input
                                                                        placeholder="请输入切角位置Y"
                                                                        suffix="mm"
                                                                        value={hanjieguiBoardAddConfig.切角位置Y}
                                                                        onChange={(e) => {
                                                                            hanjieguiBoardAddConfig.切角位置Y = e.currentTarget.value;
                                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ padding: '0 10px 10px 0' }}>
                                                            <div>切角宽</div>
                                                            <div>
                                                                <Input
                                                                    placeholder="切角宽"
                                                                    suffix="mm"
                                                                    value={hanjieguiBoardAddConfig.切角宽}
                                                                    onChange={(e) => {
                                                                        hanjieguiBoardAddConfig.切角宽 = e.target.value;
                                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: '0 0 10px 10px' }}>
                                                            <div>切角高</div>
                                                            <div>
                                                                <Input
                                                                    placeholder="切角高"
                                                                    suffix="mm"
                                                                    value={hanjieguiBoardAddConfig.切角高}
                                                                    onChange={(e) => {
                                                                        hanjieguiBoardAddConfig.切角高 = e.target.value;
                                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        hanjieguiBoardAddConfig.有隔层 && (
                                            <div>
                                                <Divider>隔层孔设置</Divider>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>开门方向：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.开门方向} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.开门方向 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="左开">左开</Radio>
                                                            <Radio value="右开">右开</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔前X</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔前X"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔前X}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔前X = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔后X</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔后X"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔后X}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔后X = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔Y</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔Y"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔Y}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔Y = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                    <div>隔层孔径</div>
                                                    <div>
                                                        <Input
                                                            placeholder="隔层孔径"
                                                            suffix="mm"
                                                            value={hanjieguiBoardAddConfig.隔层孔径}
                                                            onChange={(e) => {
                                                                hanjieguiBoardAddConfig.隔层孔径 = e.target.value;
                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        hanjieguiBoardAddConfig.有门拉手 && (
                                            <div>
                                                <Divider>拉手设置</Divider>
                                                <div style={{ marginBottom: 10 }}>
                                                    <div>门拉手类型：</div>
                                                    <div>
                                                        <Radio.Group value={hanjieguiBoardAddConfig.门拉手类型} onChange={(e) => {
                                                            hanjieguiBoardAddConfig.门拉手类型 = e.target.value;
                                                            setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                        }}>
                                                            <Radio value="暗拉手">暗拉手</Radio>
                                                            <Radio value="明拉手">明拉手</Radio>
                                                            {/* <Radio value="rt">G型拉手</Radio> */}
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                {
                                                    hanjieguiBoardAddConfig.门拉手类型 === "明拉手" && (
                                                        <>
                                                            <div style={{ marginBottom: 10 }}>
                                                                <div>开门方向：</div>
                                                                <div>
                                                                    <Radio.Group value={hanjieguiBoardAddConfig.开门方向} onChange={(e) => {
                                                                        hanjieguiBoardAddConfig.开门方向 = e.target.value;
                                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                    }}>
                                                                        <Radio value="左开">左开</Radio>
                                                                        <Radio value="右开">右开</Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 10 }}>
                                                                <div>门拉手方向：</div>
                                                                <div>
                                                                    <Radio.Group value={hanjieguiBoardAddConfig.门拉手方向} onChange={(e) => {
                                                                        hanjieguiBoardAddConfig.门拉手方向 = e.target.value;
                                                                        setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                    }}>
                                                                        <Radio value="horizontal">横向</Radio>
                                                                        <Radio value="vertical">纵向</Radio>
                                                                        {/* <Radio value="rt">G型拉手</Radio> */}
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ padding: '0 5px 10px 0' }}>
                                                                    <div>拉手X</div>
                                                                    <div>
                                                                        <Input
                                                                            placeholder="拉手X"
                                                                            suffix="mm"
                                                                            value={hanjieguiBoardAddConfig.拉手X}
                                                                            onChange={(e) => {
                                                                                hanjieguiBoardAddConfig.拉手X = e.target.value;
                                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: '0 5px 10px 5px' }}>
                                                                    <div>拉手Y</div>
                                                                    <div>
                                                                        <Input
                                                                            placeholder="拉手Y"
                                                                            suffix="mm"
                                                                            value={hanjieguiBoardAddConfig.拉手Y}
                                                                            onChange={(e) => {
                                                                                hanjieguiBoardAddConfig.拉手Y = e.target.value;
                                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: "0 10px 10px 5px" }}>
                                                                    <div>拉手间距</div>
                                                                    <div>
                                                                        <Input
                                                                            placeholder="拉手间距"
                                                                            suffix="mm"
                                                                            value={hanjieguiBoardAddConfig.拉手间距}
                                                                            onChange={(e) => {
                                                                                hanjieguiBoardAddConfig.拉手间距 = e.target.value;
                                                                                setHanjieguiBoardAddConfig({ ...hanjieguiBoardAddConfig });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }
                                            </div>

                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </div>

        </Modal>
    )
}