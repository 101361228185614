import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { _11ceneiBoardLeft } from "./11-cenei-left";

export const _11iiCeneiBoardMiddle = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    hasCircle: boolean,
    hasGuijiao: boolean
): [Path[], Path[]] => {
    const [ boardPaths, cornerPaths ] = _11ceneiBoardLeft(dx, dy, startX, startY, width, height);
    if(hasGuijiao) {
        boardPaths.shift();
    }
    cornerPaths.pop();
    cornerPaths.pop();
    cornerPaths.pop();
    cornerPaths.pop();
    cornerPaths.pop();
    cornerPaths.pop();
    if(hasCircle) {
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * width - dx * 71.1, startY + dy * (211 - 80),
                30
            ]
        })
        cornerPaths.push({
            type: Circle,
            params: [
                startX + dx * width - dx * 71.1, startY + dy * 211,
                30
            ]
        });
    }
    if(hasGuijiao) {

        boardPaths.push({
            type: Line,
            params: [
                startX + dx * 32.845654, startY,
                startX + dx * 32.845654 + dx * 28.154346, startY,
    
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346, startY,
                startX + dx * 32.845654 + dx * 28.154346, startY + dy * 10.6,
    
            ]
        }, {
            type: Arc,
            params: [
                
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5, startY + dy * 10.6,
                0.5,
                90, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5, startY + dy * 10.6 + dy * 0.5,
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21, startY + dy * 10.6 + dy * 0.5,
    
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21, startY + dy * 10.6 + dy * 0.5,
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21, startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
    
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21, startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9, startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9, startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9, startY + dy * 10.6 + dy * 0.5,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9, startY + dy * 10.6 + dy * 0.5,
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21, startY + dy * 10.6 + dy * 0.5,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21, startY + dy * 10.6,
                0.5,
                0, 90
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21 + dx * 0.5, startY + dy * 10.6,
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21 + dx * 0.5, startY,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21 + dx * 0.5, startY,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21 + dx * 0.5), startY,
            ]
        });
        boardPaths.push({
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21 + dx * 0.5), startY,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21 + dx * 0.5), startY + dy * 10.6,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21), startY + dy * 10.6,
                0.5,
                90, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9 + dx *  21), startY + dy * 10.6 + dy * 0.5,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9), startY + dy * 10.6 + dy * 0.5,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9), startY + dy * 10.6 + dy * 0.5,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9), startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21 + dx * 9), startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21), startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21), startY + dy * 10.6 + dy * 0.5 + dy * 2.3,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21), startY + dy * 10.6 + dy * 0.5,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5 + dx * 21), startY + dy * 10.6 + dy * 0.5,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5), startY + dy * 10.6 + dy * 0.5,
            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346 + dx * 0.5), startY + dy * 10.6,
                0.5,
                0, 90
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346), startY + dy * 10.6,
                startX + dx * width - (dx * 32.845654 + dx * 28.154346), startY,
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 32.845654 + dx * 28.154346), startY,
                startX + dx * width - (dx * 32.845654), startY,
            ]
        })
    }
    return [boardPaths, cornerPaths];
}