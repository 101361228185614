import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { NestRequest,Material, NestSheetSettings } from "../../../../../@interface";
import { Button, Modal, Skeleton, Tabs, Tooltip, message } from "antd";
import { host } from "../../../utils/apis";
import { CheckCircleFilled, DownloadOutlined, FileExcelFilled, FileImageFilled, FilePdfFilled, LoadingOutlined, WarningFilled } from "@ant-design/icons";
import { RuleStage } from "../../../components/rule-stage";
import { Group, Layer, Line } from "react-konva";
import { RectWithAngles } from "../../../components/graph/board";
import { TagView, TagView2 } from "../../../components/tag-view";

/**
 * 排板结果展示
 */
export const ExportViewModal = forwardRef((props: {
    compatibleV1?: boolean;
}, ref: any) => {

    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<NestRequest>();
    const [materialMap, setMaterialMap] = useState<Map<number | string, Material>>();
    const [zipDownload, setZipDownload] = useState<{
        status: "loading" | "success" | "fail",
        errorMessage?: string
        fileName?: string,
        downloadUrl?: string,
    }>();

    const [tabs, setTabs] = useState<{
        iconStatus: "loading" | "success" | "fail",
        fileType: "dxf" | "tag-pdf" | "excel",
        name: string,
        downloadUrl?: string,
        errorMessage?: string,
        materialId: string | number
    }[]>([]);
    
    const exportSettings = useRef<any>({});

    useImperativeHandle(ref, () => {
        return {
            //打开，代表websocket成功建立链接
            open: (data: NestRequest, materialMap: Map<number | string, Material>, download: boolean) => {
                
                const localExportSettings = localStorage.getItem("SK_EXPORT_SETTINGS");
                if (localExportSettings) {
                    try {
                        exportSettings.current = JSON.parse(localExportSettings);
                    }catch(e) {

                    }
                }
                if(!exportSettings.current) {
                    exportSettings.current = {};
                }
                if(!exportSettings.current.tagSettings) {
                    exportSettings.current.tagSettings = {
                        tagTemplate: "v2",
                        showWaterMark: true,
                        waterMark: "大圣设备",
                        waterMarkColor: "blue",
                        barCodeWidth: 1.9
                    }
                }
                setData(data);
                setMaterialMap(materialMap);
                setVisible(true);
            },
            onDxfGenerating: (materialId: string) => {
                const material = materialMap.get(materialId);
                tabs.push({
                    iconStatus: "loading",
                    fileType: "dxf",
                    name: `正在生成${material?.name}图纸DXF`,
                    materialId: materialId
                });
                setTabs([...tabs]);
            },
            onDxfGenerateSuccess: (materialId: string, fileName: string, downloadUrl: string) => {
                const tab = tabs.find((t) => t.materialId === materialId && t.fileType === 'dxf');
                if (tab) {
                    tab.iconStatus = 'success';
                    tab.name = fileName;
                    tab.downloadUrl = downloadUrl;
                    setTabs([...tabs]);
                }
            },
            onDxfGenerateFail: (materialId: string, errorMessage: string) => {
                const tab = tabs.find((t) => t.materialId === materialId && t.fileType === 'dxf');
                if (tab) {
                    const material = materialMap.get(materialId);
                    tab.iconStatus = 'fail';
                    tab.name = `生成${material?.name}图纸DXF出错`;
                    tab.errorMessage = errorMessage || "无错误信息";
                    setTabs([...tabs]);
                }
            },

            onTagPdfGenerating: (materialId: string) => {
                const material = materialMap.get(materialId);
                tabs.push({
                    iconStatus: "loading",
                    fileType: "tag-pdf",
                    name: `正在生成${material?.name}标签PDF`,
                    materialId: materialId
                });
                setTabs([...tabs]);
            },
            onTagPdfGenerateSuccess: (materialId: string, fileName: string, downloadUrl: string) => {
                const tab = tabs.find((t) => t.materialId === materialId && t.fileType === 'tag-pdf');
                if (tab) {
                    tab.iconStatus = 'success';
                    tab.name = fileName;
                    tab.downloadUrl = downloadUrl;
                    setTabs([...tabs]);
                }
            },
            onTagPdfGenerateFail: (materialId: string, errorMessage: string) => {
                const tab = tabs.find((t) => t.materialId === materialId && t.fileType === 'tag-pdf');
                if (tab) {
                    const material = materialMap.get(materialId);
                    tab.iconStatus = 'fail';
                    tab.name = `生成${material?.name}标签PDF出错`;
                    tab.errorMessage = errorMessage || "无错误信息";
                    setTabs([...tabs]);
                }
            },
            onExcelGenerating: () => {
                tabs.push({
                    iconStatus: "loading",
                    fileType: "excel",
                    name: `正在生成报表Excel`,
                    materialId: 0
                });
                setTabs([...tabs]);
            },
            onExcelGenerateSuccess: (fileName: string, downloadUrl: string) => {
                // message.success(`生成${fileName}成功`);
                const tab = tabs.find((t) => t.fileType === 'excel');
                if (tab) {
                    tab.iconStatus = 'success';
                    tab.name = fileName;
                    tab.downloadUrl = downloadUrl;
                    setTabs([...tabs]);
                }
            },
            onExcelGenerateFail: (errorMessage: string) => {
                // message.error(`生成Excel出错：${errorMessage}`);
                const tab = tabs.find((t) => t.fileType === 'excel');
                if (tab) {
                    tab.iconStatus = 'fail';
                    tab.name = `生成排板结果Excel出错`;
                    tab.errorMessage = errorMessage || "无错误信息";
                    setTabs([...tabs]);
                }
            },
            onZipGenerating: () => {
                setZipDownload({
                    status: "loading"
                });
            },
            onZipGenerateSuccess: (fileName: string, downloadUrl: string) => {

                setZipDownload({
                    status: "success",
                    fileName,
                    downloadUrl
                });
            },
            onZipGenerateFail: (errorMessage: string) => {

                setZipDownload({
                    status: "fail",
                    errorMessage: errorMessage || "无错误信息"
                });
            },
        }
    });

    const close = () => {
        setVisible(false);
        setZipDownload(undefined);
        setTabs([]);

    }
    return (
        <Modal
            open={visible}
            title="导出图纸DXF和标签PDF"
            centered
            okText={zipDownload ?
                (zipDownload.status === 'success' ? `下载“${zipDownload.fileName || ""}”` : zipDownload.status === 'loading' ? '正在准备压缩包' : zipDownload.errorMessage) : '等待各文件生成'}
            okButtonProps={{
                disabled: zipDownload?.status !== 'success' || !zipDownload.downloadUrl,
                loading: zipDownload?.status === 'loading'
            }}
            cancelButtonProps={{
                disabled: !zipDownload || zipDownload.status === 'loading'

            }}
            onOk={() => {
                if (zipDownload.downloadUrl) {
                    window.open(host + zipDownload.downloadUrl)
                } else {
                    message.warning("异常情况：无下载链接");
                }
            }}
            width={window.innerWidth - 400 + 48}
            onCancel={close}
            destroyOnClose
        >
            {
                tabs.length === 0 ? (
                    <div className="flex" style={{height: window.innerHeight - 400}}>
                        <Skeleton active style={{width: 240, marginRight: 20}}/>
                        <div style={{flex:1}}>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />

                        </div>
                    </div>
                ) : (

                    <Tabs tabPosition="left">
                        {
                            tabs.map((tab) => {
                                const material = materialMap.get(tab.materialId);
                                const result = data.nestResult.find((n) => n.materialId.toString() === tab.materialId.toString());
                                let settings: NestSheetSettings;
                                let scale = 1;
                                if(tab.fileType === 'dxf') {
                                    settings = data.nestConfig.materialNestSettings.find((mns) => mns.materialId === result.materialId);
                                    scale = ((window.innerHeight - 424) / settings.sheetHeight);
                                }
                                return (
                                    <Tabs.TabPane 
                                        key={tab.materialId + "-" + tab.fileType} tab={(
                                        <div className="flex center " style={{ width: 240, justifyContent: 'space-between' }}>
                                            <div className="flex center">
                                                {
                                                    tab.iconStatus === 'loading' ? (
                                                        <LoadingOutlined spin  />
                                                    ) : tab.iconStatus === 'success' ? (
                                                        <CheckCircleFilled style={{ color: "green" }} />
                                                    ) : tab.iconStatus === 'fail' ? (
                                                        <Tooltip title={tab.errorMessage}>
                                                            <WarningFilled style={{ color: "orangered" }} />
                                                        </Tooltip>
                                                    ) : (<></>)
                                                }
                                                
                                                <div style={{ width: 200, textAlign: 'left', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{tab.name}</div>
                                            </div>
                                            <Button title={tab.downloadUrl} onClick={() => {
                                                window.open(host + tab.downloadUrl)
                                            }} type="link" >
                                                <DownloadOutlined />
                                            </Button>
                                        </div>
                                    )}>
                                        {
                                            tab.fileType === 'dxf' && (
                                                <RuleStage
                                                    width={window.innerWidth - 400 - 240 - 48}
                                                    height={window.innerHeight - 400}
                                                    ruleScale={scale}
                                                    zoomable
                                                    draggable
                                                >
                                                    <Layer>
                                                        {
                                                            result.sheets.map((sheet, index) => {

                                                                const startX = sheet.width * 1.5 * index;
                                                                return (
                                                                    <Group>

                                                                        {
                                                                            sheet.boards.map((board, index) => {
                                                                                return (
                                                                                    <RectWithAngles
                                                                                        key={"edit-" + index}
                                                                                        x={startX * scale + board.customX * scale}
                                                                                        y={board.customY * scale}
                                                                                        scale={scale}
                                                                                        board={board.board}
                                                                                        rotate={board.rotate}
                                                                                        material={material}
                                                                                        background="#000"
                                                                                        compatibleV1={props.compatibleV1}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                        <Line
                                                                            x={startX * scale}
                                                                            y={0}
                                                                            points={[0, 0, sheet.width * scale, 0]}
                                                                            strokeWidth={0.5}
                                                                            stroke="#f00"
                                                                        />
                                                                        <Line
                                                                            x={startX * scale}
                                                                            y={0}
                                                                            points={[sheet.width * scale, 0, sheet.width * scale, sheet.height * scale]}
                                                                            strokeWidth={0.5}
                                                                            stroke="#f00"
                                                                        />
                                                                        <Line
                                                                            x={startX * scale}
                                                                            y={0}
                                                                            points={[0, 0, 0, sheet.height * scale]}
                                                                            strokeWidth={0.5}
                                                                            stroke="#f00"
                                                                        />
                                                                        <Line
                                                                            x={startX * scale}
                                                                            y={0}
                                                                            points={[0, sheet.height * scale, sheet.width * scale, sheet.height * scale]}
                                                                            strokeWidth={0.5}
                                                                            stroke="#f00"
                                                                        />
                                                                    </Group>
                                                                )
                                                            })
                                                        }
                                                    </Layer>
                                                </RuleStage>
                                            )
                                        }
                                        {
                                            tab.fileType === 'tag-pdf' && (
                                                <div style={{ height: window.innerHeight - 400, overflowY: 'scroll' }}>
                                                    {
                                                        result.sheets.map((s) => {
                                                            return s.tags.map((t) => {
                                                                return exportSettings.current.tagSettings.tagTemplate === 'v2' ? (
                                                                    <TagView2 
                                                                        {...t} 
                                                                        showWaterMark={exportSettings.current.tagSettings.showWaterMark}
                                                                        waterMark={exportSettings.current.tagSettings.waterMark}
                                                                        waterMarkColor={exportSettings.current.tagSettings.waterMarkColor || "blue"}
                                                                        barCodeWidth={exportSettings.current.tagSettings.barCodeWidth || 1.9}
                                                                        selected 
                                                                        tagRects={s.tagRects}
                                                                        sheetHeight={s.height}
                                                                        sheetWidth={s.width}
                                                                        showFrame={exportSettings.current.tagSettings.showFrame}
                                                                    />
                                                                ) : (
                                                                    <TagView 
                                                                        selected 
                                                                        tagRects={s.tagRects}
                                                                         {...t} 
                                                                    />
                                                                )
                                                            })

                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            tab.fileType === 'excel' && (
                                                <div style={{ height: window.innerHeight - 400, overflowY: 'scroll' }}>
                                                    <div>报表，Excel暂不支持预览</div>
                                                </div>
                                            )
                                        }
                                    </Tabs.TabPane>

                                )
                            })
                        }
                    </Tabs>
                )
            }

        </Modal>
    )
});