import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05fengneiBoard } from "./05-fengnei";
import { _05lineiBoard } from "./05-linei";

export const _07lineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    extraConfig: any,
 ): [Path[], Path[]]  => {
    return _05lineiBoard(dx, dy, startX, startY, width, height, extraConfig);
 }