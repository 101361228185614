import { useContext } from "react";
import { FloorPlannerContext } from "../../context-reducer";
import { DrawedMeta, konva, useIsDrawing } from "../../store";

export const useDrawTool = () => {

    const {  state, dispatch } = useContext(FloorPlannerContext);

    const isDrawing = useIsDrawing();

    const updateDrawedList = (drawedList: DrawedMeta[]) => {
        dispatch({
            type: "updateDrawedList",
            payload: drawedList
        });
    }

    const updateRedoList = (redoList: DrawedMeta[]) => {
        dispatch({
            type: "updateRedoList",
            payload: redoList
        });
    }

    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        })
    }

    const removeSelectedTargets = () => {
        console.log("removeSelectedTargets");
        
        dispatch({
            type: "removeSelectedTargets"
            
        });
    }

    return {
        selectedTargets: state.selectedTargets,
        drawedList: state.drawedList,
        redoList: state.redoList,
        editTarget: state.editTarget,
        isDrawing,
        removeSelectedTargets,
        updateDrawedList: updateDrawedList,
        updateRedoList,
        cancelDraw,
        stage: konva.stage,
    }

}
