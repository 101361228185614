import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach } from "../../../util";
import { pathMoveX, pathMoveY, pathReverse, pathRotate, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../corner1";
import { sanheyiNeijiaoPart } from "./@parts";
import { corner1Sanheyi } from "./corner1-sanheyi";

export const corner10Sanheyi = (
    dx: DirectX, 
    dy: DirectY, 
    startX: number, 
    startY: number, 
    corner: boolean, 
    sizeRes?: {width: number, height: number}
): Path[] => {

    const paths: Path[] = [{
        type: Line,
        params: [
            startX, startY,
            startX + dx * 12.000000, startY
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12.000000, startY,
            startX + dx * 12.000000, startY + dy * 4.367136,
        ]
    }   ];
    const sx1 = startX + dx * 12.000000;
    const sy1 = startY + dy * 4.367136;
    paths.push(...sanheyiNeijiaoPart(sx1, sy1, dx, dy, "none"));
    const sx2 = sx1;
    const sy2 = sy1 + dy * 26.265727;
    paths.push({
        type: Line,
        params: [
            sx2, sy2,
            sx2, sy2 + dy * 4.367136,
        ]
    }, {
        type: Line,
        params: [
            sx2, sy2 + dy * 4.367136,
            startX, sy2 + dy * 4.367136,
        ]
    });

    // paths.push({
    //     type: Line,
    //     params: [
            
    //     ]
    // })
    const size = {height: 0, width: 0};
    let part2 = corner1Sanheyi(-dx, dy, startX, startY, true, size);
    part2 = pathRotate2(part2, startX, startY, 90);
    part2 = pathMoveX(part2, DirectX.RIGHT, dx * size.height);
    part2 = pathMoveY(part2, DirectY.UP, dy * size.width);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = size.height;
            sizeRes.height = 88.085123;
        // }
    }
    return degreeTransformForEach(paths.concat(part2), dx, dy).filter((item) => corner ? true : item.type === Circle);
}