import { useEffect, useMemo, useRef, useState } from "react";
import Widgets from "../../components/WindowFrame/Header/Widgets";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { getJSONByDrawedList } from "../2d-design/utils";
import { useNavigate } from "react-router-dom";

export const _3DWrapper = (props: { mode: "2d" | "3d", order: any, visible?: boolean }) => {

    

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [load, setLoad] = useState(false);

    const [mode, setMode] = useState(props.mode);
    
    const getTo3DData = () => {
        const nestUrl = process.env.NODE_ENV === 'development' ? window.location.origin + `/#/nest?orderId=${props.order.id}` : `/#/nest?orderId=${props.order.id}`
        if(props.order) {
            return {
                nestUrl: nestUrl,
                order: props.order,
                room3DData: getJSONByDrawedList(JSON.parse(props.order.floorPlannerData || "[]"))
            };
        }else {
            return {
                nestUrl: nestUrl,
                order: null,
                room3DData: []
            }
        }
    }

    const navigate = useNavigate();

    const loadReport = () => {
        setLoad(true);
        return getTo3DData();
    }

    const onGoNest = (payload: {
        order: any;
        nestRequest: any;
    }) => {
         //@ts-ignore
         window.$$order = payload.order;
         //@ts-ignore
         window.$$nestRequest = payload.nestRequest;
         navigate(`/nest?orderId=${payload.order.id}`)
    }

    const cache = useMemo(() => {
        return {
            loadReport,
            onGoNest
        }
    }, []);

    cache.loadReport = loadReport;
    cache.onGoNest = onGoNest;



    useEffect(() => {
        let t = 0;
        let timer = null;
        //@ts-ignore
        window.$goNest = cache.onGoNest;
        //@ts-ignore
        window.$loadReport = () => {
            t++;
            if(timer) {
                clearTimeout(timer);
            }
            return cache.loadReport();
        }
        timer = setTimeout(() => {
            setLoad(true);
        }, 5 * 1000);

        return () => {
            clearTimeout(timer);
            timer = null;
        }

    }, []);


    useEffect(() => {
        if (mode !== props.mode) {
            if (props.mode === '3d') {
                //@ts-ignore
                if (iframeRef.current?.contentWindow?.$onChangeTo3D) {
                    //@ts-ignore
                    iframeRef.current?.contentWindow?.$onChangeTo3D(getTo3DData());
                }
            }
            if (props.mode === '2d') {
                //@ts-ignore
                if (iframeRef.current?.contentWindow?.$onChangeTo2D) {
                    //@ts-ignore
                    iframeRef.current?.contentWindow?.$onChangeTo2D();
                }
            }

        }
        setMode(props.mode);
    }, [props.mode]);


    return (
        <>
            {
                !load && (
                    <div className="flex center column" style={{ color: "gray", height: '100vh', width: "100vw", position: 'absolute', left: 0, top: 0, zIndex: 999, background: "#FFF" }}>
                        <LoadingOutlined style={{ fontSize: 64 }} />
                        <span style={{ lineHeight: 3 }}>正在加载...</span>
                    </div>
                )
            }
            <iframe
                ref={iframeRef}
                style={{
                    display: (load && props.visible) ? undefined : 'none',
                    position: 'absolute',
                    top: 0,
                    left: 44,
                    width: "calc(100vw - 44px)",
                    height: "100vh",
                    border: "none",
                    zIndex: 1
                }}
                src={localStorage.getItem("SK_CUPBOARD_DESIGN_URL") || "/3d/index.html"}
            />
        </>
    )
}