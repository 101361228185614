import { boardRotate90 } from "../../../util/graph";

type NameMap = { [boardName: string]: (...args: any[]) => [Path[], Path[]] };
type TypeMap = { [boardName: string]:  BoardTypeKey };
type SizeMap =  { [name: string]: { size: [number, number], rotate90?: boolean, quantity?: number } };
type BoardCNNameMap = { [boardName: string]: string }

export abstract class BaseCupboard {

    key = this.constructor.name;
    

    protected readonly nameMap: NameMap;
    abstract setNameMap(): NameMap;

    protected typeMap: TypeMap = {};
    protected setTypeMap(): TypeMap {
        
        const typeMap: TypeMap = {};
        for (const name in this.nameMap) {
            if(name.indexOf("beinei") > -1) {
                typeMap[name] = "beinei";
            }else if(name.indexOf("beiwai") > -1) {
                typeMap[name] = "beiwai";
            }else if(name.indexOf("mennei") > -1) {
                typeMap[name] = "mennei";
            }else if(name.indexOf("menwai") > -1 || name.indexOf("fengwai") > -1) {
                typeMap[name] = "menwai"; 
            }else if(name.indexOf("nei") > -1) {
                typeMap[name] = "guinei";
            }else if(name.indexOf("wai") > -1) {
                typeMap[name] = "guiwai";
            }
        }
        return typeMap;
    };

    readonly boardCNNameMap: BoardCNNameMap;
    setBoardNameMap () {
        const cnMap: BoardCNNameMap = {};
        //2022 11 30改动， geceng放在ce前面
        for (const name in this.nameMap) {
            let neiwai = '内板';
            if(name.indexOf("wai") > -1 || name.indexOf("outer") > -1) {
                neiwai = '外板';
            }
            let cnname = '板';
            if(name.indexOf("ding") > -1) {
                cnname = '顶板';
            } else if(name.indexOf("di") > -1) {
                cnname = "底板"
            } else if(name.indexOf("geceng") > -1) {
                cnname = '隔层板';
            } else if(name.indexOf("ce") > -1) {
                cnname = '侧板'
                if(name.indexOf("left") > -1) {
                    cnname = '左侧板'
                }else if(name.indexOf("right") > -1) {
                    cnname = '右侧板'
                }else if(name.indexOf("middle") > -1) {
                    cnname = '中侧板';
                }else if(name.indexOf("gongyong") > -1) {
                    cnname = '公用侧板';
                }
            }else if(name.indexOf("bei") > -1) {
                cnname = '背板'
            }else if(name.indexOf("li") > -1) {
                cnname = '立板'
            }else if(name.indexOf("feng") > -1) {
                cnname = '封板'
            }else if(name.indexOf("men") > -1) {
                cnname = '门板';
            }else if(name.indexOf("qiandang") > -1) {
                cnname = '前挡板';
            }else if(name.indexOf("zhunjiao") > -1) {
                cnname = "转角板"
            }else if(name.indexOf("mentou") > -1) {
                cnname = '门头';
            }
            cnMap[name] = `${cnname}_${neiwai}`;
        }
        return cnMap;
    }


    protected updateTypeMap(){
        this.typeMap = this.setTypeMap();
    }
    abstract getSizeMap(long: number, deep: number, height: number, extraConfig: {[key: string]: any}): SizeMap;


    materials: Material[];
    boardTypes: BoardType[];

    constructor(boardTypes: BoardType[], materials: Material[]) {
        this.boardTypes = boardTypes;
        this.materials = materials;
        this.nameMap = this.setNameMap();
        this.boardCNNameMap = this.setBoardNameMap();
        this.updateTypeMap();
    }

    private factoryCount = 0;
    private spacing = 10;


    getFactoryCount() {
        return this.factoryCount;
    }

    factory(long: number, deep: number, height: number, extraConfig: {[key: string]: any}, offsetX = 0, offsetY = 0) {
        this.factoryCount++;
        // //console.log(this.materials)
        const availableMaterials = this.materials/* .filter((material) => material.hide === false) */;
        const sheetsMap: {[key: string]: Sheet[]} = {}; 
        const sheets: Sheet[][] = [];
        const sheetsSizeMap: {[materialId: string]: {width: number, height: number}} = {};
        const resultMap: {[materialId: string]: {width: number, height: number, paths: Path[], offsetX: number, offsetY: number}} = {};
        for (let i = 0; i < availableMaterials.length; i++) {
            const m = availableMaterials[i];
            const sheet: Sheet[] = [];
            sheets.push(sheet);
            sheetsMap[m.id] = sheet;
            sheetsSizeMap[m.id] = {width: offsetX, height: offsetY};
            resultMap[m.id] = {width: 0, height: 0, paths: [], offsetX: 0, offsetY: 0};
        }

        const boardsSize = this.getSizeMap(long, deep, height, extraConfig);
        // console.log(boardsSize);
        
        for (const _key in boardsSize) {
            // console.log("key", _key);
            
            const boardTypeKey = this.typeMap[_key];
            let boardType: BoardType | undefined = this.boardTypes.find((item) => item.key === boardTypeKey);
            if(!boardType?.selected) {
                if(boardType) {
                    delete boardsSize[boardType?.key];
                }
                continue ;
            }
            const boardSize = boardsSize[_key];
            let { quantity, size: [ width, height ], rotate90 } = boardSize;
            if(rotate90) {
                let temp = width;
                width = height;
                height = temp;
            }
            if(!quantity) {
                quantity = 1;
            }
            for (let i = 0; i < quantity; i++) {
                //计算startX
                let sheet = sheetsMap[boardType.materialId];
                let sheetLastBoard: Sheet | undefined;
                if(sheet.length > 0) {
                    sheetLastBoard = sheet[sheet.length - 1];
                }
                let startX = sheetLastBoard ?  offsetX + sheetLastBoard.startX + sheetLastBoard.width + this.spacing : 0;
                sheet.push({
                    key: this.key,
                    group: this.factoryCount,
                    name: _key,
                    startX: startX,
                    startY: 0,
                    width,
                    height,
                    rotate90: rotate90 || false,
                    extraConfig,
                });
                sheetsSizeMap[boardType.materialId].width += width;              
                if(height > sheetsSizeMap[boardType.materialId].height) {
                    sheetsSizeMap[boardType.materialId].height = height;
                }
            }
        }
        for (const materialId in sheetsMap) {
            const count = sheetsMap[materialId].length;
            sheetsSizeMap[materialId].width += (this.spacing * (count - 1));
            resultMap[materialId].height = sheetsSizeMap[materialId].height;
            resultMap[materialId].width = sheetsSizeMap[materialId].width;
            resultMap[materialId].paths = this.sheetBoardHandler(sheetsMap[materialId], extraConfig);
            resultMap[materialId].offsetX = offsetX;
            resultMap[materialId].offsetY = offsetY;
        }
        return resultMap;
    }

    
    getSheets(long: number, deep: number, height: number, extraConfig: {[key: string]: any}) {
        const availableMaterials = this.materials/* .filter((material) => material.hide === false) */;
        const sheetsMap: {[key: string]: Sheet[]} = {};
        const sheets: Sheet[][] = [];
        for (let i = 0; i < availableMaterials.length; i++) {
            const m = availableMaterials[i];
            const sheet: Sheet[] = [];
            sheets.push(sheet);
            sheetsMap[m.id] = sheet;
        }

        const boardsSize = this.getSizeMap(long, deep, height, extraConfig);
        for (const _key in boardsSize) {
            const boardTypeKey = this.typeMap[_key];
            const boardType: BoardType | undefined = this.boardTypes.find((item) => item.key === boardTypeKey);
            if(!boardType?.selected) {
                if(boardType) {
                    delete boardsSize[boardType.key];
                }
                continue ;
            }
            const boardSize = boardsSize[_key];
            let { quantity, size: [ width, height ], rotate90 } = boardSize;
            if(rotate90) {
                let temp = width;
                width = height;
                height = temp;
            }
            if(!quantity) {
                quantity = 1;
            }
            for (let i = 0; i < quantity; i++) {
                //计算startX
                let sheet = sheetsMap[boardType.materialId];
                let sheetLastBoard: Sheet | undefined;
                if(sheet.length > 0) {
                    sheetLastBoard = sheet[sheet.length - 1];
                }
                let startX = sheetLastBoard ? sheetLastBoard.startX + sheetLastBoard.width + this.spacing : 0;
                sheet.push({
                    key: this.key,
                    group: this.factoryCount,
                    name: _key,
                    startX: startX,
                    //@ts-ignore
                    friendlyName: this.boardCNNameMap[key],
                    startY: 0,
                    width,
                    height,
                    rotate90: rotate90 || false,
                    extraConfig,
                });
            }
        }
        return sheetsMap;
    }

    getRects(long: number, deep: number, height: number, extraConfig: {[key: string]: any}) {
        const boardsSize = this.getSizeMap(long, deep, height, extraConfig);
        const materialRects: {[materialId: string]: {width: number, height: number}[]} = {};
        for (const _key in boardsSize) {
            const boardTypeKey = this.typeMap[_key];
            const boardType: BoardType | undefined = this.boardTypes.find((item) => item.key === boardTypeKey);
            if(!boardType?.selected) {
                if(boardType) {
                    delete boardsSize[boardType.key];
                }
                continue ;
            }
            const boardSize = boardsSize[_key];
            let { quantity, size: [ width, height ] } = boardSize;
            
            if(!quantity) {
                quantity = 1;
            }
            for (let i = 0; i < quantity; i++) {
                if(boardType.materialId.toString() in materialRects) {
                    materialRects[boardType.materialId].push({
                        width,
                        height,
                    })
                }else {
                    materialRects[boardType.materialId] = [{
                        width,
                        height,
                    }]
                }
            }
        }
        return materialRects;
    }

    getConfigs(long: number, deep: number, height: number, extraConfig: {[key: string]: any}) {
        return extraConfig;
    }

    getBoards(long: number, deep: number, height: number, extraConfig: {[key: string]: any}) {
        const boards: any[] = [];
        const boardsSize = this.getSizeMap(long, deep, height, extraConfig);
        for (const _key in boardsSize) {
            const boardTypeKey = this.typeMap[_key];
            let boardType: BoardType | undefined = this.boardTypes.find((item) => item.key === boardTypeKey);
            if(!boardType?.selected) {
                if(boardType) {
                    delete boardsSize[boardType?.key];
                }
                continue ;
            }
            const boardSize = boardsSize[_key];
            let { quantity, size: [ width, height ], rotate90 } = boardSize;
            if(rotate90) {
                let temp = width;
                width = height;
                height = temp;
            }
            if(!quantity) {
                quantity = 1;
            }
            for (let i = 0; i < quantity; i++) {
                const board: any = {
                    key: this.key,
                    name: _key,
                    friendlyName: this.boardCNNameMap[_key],
                    width,
                    height,
                    startX: 0,
                    startY: 0,
                    rotate90: rotate90 || false,
                    materialId: boardType.materialId,
                    extraConfig,
                }
                boards.push(board);
                board.paths = this.executeBoard(board, extraConfig);
            }

        }
        return boards;
    }

    abstract executeBoard(board: Sheet, extraConfig: {[key: string]: any}, index?: number): [Path[], Path[]];

    
    sheetBoardHandler(boardsInSheet: Sheet[], extraConfig: {[key: string]: any}) {
        const paths = [];
        for (let i = 0; i < boardsInSheet.length; i++) {
            let board = boardsInSheet[i];
            board = JSON.parse(JSON.stringify(board));
            if(board.rotate90) {
                let temp = board.width;
                board.width = board.height;
                board.height = temp;
            }
            let temp;
            temp = this.executeBoard(board, extraConfig, i);
            let [boardPaths, cornerPaths] = temp;
            if(board.rotate90) {
                const [_boardPaths, _cornerPaths] = boardRotate90(boardPaths, cornerPaths, board.startX, board.startY, board.width, board.height);
                boardPaths = _boardPaths;
                cornerPaths = _cornerPaths;
            }
            paths.push(...boardPaths, ...cornerPaths);
        }
        return paths;
    }

    sheetBoardHandler_withNumber(index: number, boardsInSheet: Sheet[], extraConfig: {[key: string]: any}) {
        const paths = [];
        //console.log("sheetBoardHandler_withNumber");
        //console.log(boardsInSheet);
        for (let i = 0; i < boardsInSheet.length; i++) {
            let board = boardsInSheet[i];
            board = JSON.parse(JSON.stringify(board));
            if(board.rotate90) {
                let temp = board.width;
                board.width = board.height;
                board.height = temp;
            }
            let temp;
            temp = this.executeBoard(board, extraConfig, i);
            let [boardPaths, cornerPaths] = temp;
            if(board.rotate90) {
                const [_boardPaths, _cornerPaths] = boardRotate90(boardPaths, cornerPaths, board.startX, board.startY, board.width, board.height);
                boardPaths = _boardPaths;
                cornerPaths = _cornerPaths;
            }
            
            if(board.rotate90) {

                paths.push(
                    {
                        type: "Text",
                        params: [(board.startX + board.startX + board.height) / 2 - 180, (board.startY + board.startY + board.width) / 2 - 50, 100, 0, (board.friendlyNumber)]
                    }
                );
            }else {

                paths.push(
                    {
                        type: "Text",
                        params: [(board.startX + board.startX + board.width) / 2 - 180, (board.startY + board.startY + board.height) / 2 - 50, 100, 0, (board.friendlyNumber)]
                    }
                );
            }
            paths.push(...boardPaths, ...cornerPaths);
        }
        //console.log("sheetBoardHandler_withNumber");
        //console.log(paths);
        // //console.log(paths);
        
        return paths;
    }
}