import { BaseCupboard } from "../@base/BaseCupboard";


import { gecengneiBoard } from "../../boards/地柜/01-gecengnei";
import { gecengwaiBoard } from "../../boards/地柜/01-gecengwai";
import { menneiBoard } from "../../boards/地柜/01-mennei";
import { menwaiBoard } from "../../boards/地柜/01-menwai";
import { qiandangneiBoard } from "../../boards/地柜/01-qiandangnei";
import { qiandangwaiBoard } from "../../boards/地柜/01-qiandangwai";
import { _01AbeineiBoard } from "../../boards/地柜/01-a-beinei";
import { _01AbeiwaiBoard } from "../../boards/地柜/01-a-beiwai";
import { _01AceneiBoardLeft } from "../../boards/地柜/01-a-cenei-left";
import { _01AceneiBoardRight } from "../../boards/地柜/01-a-cenei-right";
import { _01AcewaiBoardLeft } from "../../boards/地柜/01-a-cewai-left";
import { _01AcewaiBoardRight } from "../../boards/地柜/01-a-cewai-right";
import { _01AdineiBoard } from "../../boards/地柜/01-a-dinei";
import { _01AdiwaiBoard } from "../../boards/地柜/01-a-diwai";


export class DiguiBeibaoce extends BaseCupboard {
    key: string = "DiguiBeibaoce";
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            beineiBoard: _01AbeineiBoard,
            beiwaiBoard: _01AbeiwaiBoard,
            ceneiBoardLeft: _01AceneiBoardLeft,
            ceneiBoardRight: _01AceneiBoardRight,
            cewaiBoardLeft: _01AcewaiBoardLeft,
            cewaiBoardRight: _01AcewaiBoardRight,
            dineiBoard: _01AdineiBoard,
            diwaiBoard: _01AdiwaiBoard,
            menneiBoard: menneiBoard,
            menwaiBoard: menwaiBoard,
            "menwaiBoard-left": menwaiBoard,
            "menneiBoard-left": menneiBoard,
            "menwaiBoard-right": menwaiBoard,
            "menneiBoard-right": menneiBoard,
            
            qiandangneiBoard: qiandangneiBoard,
            qiandangwaiBoard: qiandangwaiBoard,
            gecengneiBoard: gecengneiBoard,
            gecengwaiBoard: gecengwaiBoard,
        };
    }
    // setTypeMap(): { [boardName: string]: BoardTypeKey; } {
    //     return {
    //         beineiBoard: "beinei",
    //         beiwaiBoard: "beiwai",
    //         ceneiBoardLeft: "guinei",
    //         ceneiBoardRight: "guiwai",
    //         cewaiBoardLeft: "guinei",
    //         cewaiBoardRight: "guiwai",
    //         dineiBoard: "guinei",
    //         diwaiBoard: "guiwai",
    //         menneiBoard: "mennei",
    //         menwaiBoard: "menwai",
    //         qiandangneiBoard: "guinei",
    //         qiandangwaiBoard: "guiwai",
    //         gecengneiBoard: "guinei",
    //         gecengwaiBoard: "guiwai",
    //     };    
    // }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const size: { [name: string]: { size: [number, number], rotate90?: boolean, quantity?: number } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47 - 18],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21 - 18],
            },
            "cewaiBoardLeft": {
                size: [deep + 47 - 18, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47 - 18, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep + 21 - 18, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21 - 18, height + 3],
            },
            "beiwaiBoard": {
                size: [long + 11 + 36, height + 29 + 18]
            },
            "beineiBoard": {
                size: [long - 15 + 36, height + 3 + 18]
            },
            "qiandangwaiBoard": {
                size: [long + 11, 74 + 47],
                //rotate90: true,
            },
            "qiandangneiBoard": {
                size: [long - 44, 74 + 21],
                //rotate90: true,
            }
        };
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
        /* 2021 12 27 - 5 G型拉手门内高度+13*/
        let menneiHeightIncre = 0;
        let menneiWidthIncre = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                menneiHeightIncre = 13;
            }else {
                menneiWidthIncre = 13;
            }
        }
        if(extraConfig.doorNumber === 2) {
            size["menneiBoard-left"] = {
                size: [long / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            size["menwaiBoard-left"] = {
                size: [long / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],   
                quantity: 1,
            }
            size["menneiBoard-right"] = {
                size: [long / 2 - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            size["menwaiBoard-right"] = {
                size: [long / 2 - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }else {
            size["menneiBoard"] = {
                size: [long - 2 + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 19 - hReduce + menneiHeightIncre + (extraConfig.menxiagaochu || 0)],
                quantity: 1
            };
            size["menwaiBoard"] = {
                size: [long - 2 + 47 - wReduce, extraConfig.mengao + 45 - hReduce + (extraConfig.menxiagaochu || 0)],
                quantity: 1,
            }
        }
        
        if(extraConfig.geceng) {
            size['gecengwaiBoard'] = {
                size: [long + 11, deep - extraConfig.gecengX + 29],
            };
            size['gecengneiBoard'] = {
                size: [long - 15, deep - extraConfig.gecengX + 3]
            };
        }
        if(extraConfig.nobeinei) {
            delete size["beineiBoard"];
        }
        if(extraConfig.nocenei) {
            delete size['ceneiBoardLeft'];
            delete size['ceneiBoardRight'];
        }
        return size;
    }
    executeBoard(board: Sheet, extraConfig: {[key: string]: any}): [Path[], Path[]] {
        let temp;
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.guituiX, extraConfig.guituiY, extraConfig);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            if(extraConfig.doorNumber === 2) {
                temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang);                    
            }else {
                if(board.name.toLowerCase().includes(extraConfig.doorSide)) {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang);
                }else {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, -1,                         extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.mengao, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, extraConfig.qiandanghengfang);
                }
            }
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.qiandanghengfang);
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }


}