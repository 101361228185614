import { useEffect, useState } from "react";
import { AreaData, px2m, useSpace } from "../../../store";
import { Color, ExtrudeGeometry, Shape, Vector2 } from "three";
import { Base, Geometry } from "@react-three/csg";

interface Props extends AreaData {
    globalOffset: { x: number, y: number };

}

export const Area3D = (props: Props) => {

    const [geometry, setGeometry] = useState<ExtrudeGeometry>(null);
    const space = useSpace();

    useEffect(() => {
        if (props.anchors.length < 3) {
            setGeometry(null)
        } else {
            const vectors: Vector2[] = props.innerContour.map(p => new Vector2(p.x + props.globalOffset.x, p.y + props.globalOffset.y));
            vectors.forEach(v => {
                v.setX(v.x * px2m);
                v.setY(v.y * px2m);
            });
            const shape = new Shape(vectors);
            const height = props.floorThickness * px2m;
            const geo = new ExtrudeGeometry(shape, {
                depth: height,
                bevelEnabled: false
            });
            geo.translate(0, 0, -height);
            geo.rotateX(Math.PI / 2);
            setGeometry(geo);
        }
    }, [props.innerContour, space]);

    return (
        <mesh receiveShadow castShadow >
            <Geometry computeVertexNormals>
                <Base geometry={geometry} />
            </Geometry>
            <meshStandardMaterial envMapIntensity={1} color={new Color(0x847362)} />
        </mesh>
    )

}