import { useEffect, useState } from "react";
import { useWidgets } from "../../store";
import { TouchableDiv } from "../touchable-div";

export const Left1Widget = (props: {


}) => {
    const {
        //限制相关
        areaDrawed, editTarget,
        //绘图相关
        drawType, startDraw,
    } = useWidgets();

    const [drawingDisable, setDrawingDisable] = useState(false);

    useEffect(() => {
        if(editTarget || drawType) {
            setDrawingDisable(true);
        }else {
            setDrawingDisable(false);
        }
    }, [editTarget, drawType])

    return (

        <div className="left-menu fixed no-scroll-bar" style={{ boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', opacity: 1, left: 44, top: 54, bottom: 10, width: 240, borderRight: '1px solid rgba(5, 5, 5, 0.06)', borderTopRightRadius: 4, borderBottomRightRadius: 4, background: "#fff", overflowY: 'scroll' }}>
            <div className="title" style={{ height: 50, lineHeight: '50px', borderBottom: '1px solid rgba(5, 5, 5, 0.06)', padding: '0 8px' }}>
                房间绘制
            </div>
            <div>
                <div className="sub-title" style={{ fontSize: 14, height: 36, lineHeight: '36px', padding: '12px 8px' }}>画墙</div>
                <div className="flex" style={{ justifyContent: 'space-around', padding: '12px 8px' }}>
                    <TouchableDiv onClick={() => {
                        startDraw("wall-straight");
                    }} disable={drawingDisable || areaDrawed}
                        active={drawType === 'wall-straight'}
                        className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex center" style={{ width: 60, height: 60 }}>
                            <div style={{ width: 54, height: 10, border: '2px solid #8d959c', background: "#eaf5ff" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>直墙</div>
                    </TouchableDiv>

                    {/* <TouchableDiv onClick={() => {
                    startDraw("wall-round");
                }} disable={!areaDrawed} active={drawType === 'wall-round'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                    <div className="flex center" style={{ width: 60, height: 60 }}>
                        <div style={{ height: 52 / 2, width: 52, overflow: 'hidden' }}>
                            <div className="flex center relative" style={{ height: 52, width: 52, borderRadius: 52, border: '2px solid #8d959c', background: "#eaf5ff", }}>
                                <div style={{ height: 4, background: "#8d959c", width: '100%' }} />
                                <div className="flex center absolute" style={{ height: '100%', width: '100%' }}>
                                    <div style={{ height: 36, width: 36, borderRadius: 36, border: '2px solid #8d959c', background: "#f7f7f7", }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>弧墙</div>
                </TouchableDiv> */}

<TouchableDiv onClick={() => {
                        startDraw("wall-rectangle");
                    }} disable={drawingDisable || areaDrawed} active={drawType === 'wall-rectangle'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex center" style={{ width: 60, height: 60 }}>
                            <div className="flex center" style={{ width: 40, height: 40, border: '2px solid #8d959c', background: "#eaf5ff" }}>
                                <div style={{ width: 26, height: 26, border: '2px solid #8d959c', background: "#f2f2f2" }} />
                            </div>
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>矩形墙</div>
                    </TouchableDiv>
                    {/* <TouchableDiv onClick={() => {
                        startDraw("area-rect");
                    }} disable={areaDrawed} active={drawType === 'area-rect'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex center" style={{ width: 60, height: 60 }}>
                            <div className="flex center" style={{ width: 40, height: 40, border: '2px solid #8d959c', background: "#eaf5ff" }}>
                                <div style={{ width: 26, height: 26, border: '2px solid #8d959c', background: "#f2f2f2" }} />
                            </div>
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>矩形墙</div>
                    </TouchableDiv> */}
                </div>
                <div className="sub-title" style={{ fontSize: 14, height: 36, lineHeight: '36px', padding: '12px 8px' }}>结构</div>
                <div className="flex" style={{ justifyContent: 'space-around', padding: '12px 8px' }}>

                    <TouchableDiv onClick={() => {
                        startDraw("struct-round-post");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'struct-round-post'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex column center" style={{ width: 60, height: 60 }}>
                            <div style={{ height: 34, width: 34, borderRadius: 17, background: "#fff", border: "2px solid #8d959c" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>圆柱</div>
                    </TouchableDiv>
                    <TouchableDiv onClick={() => {
                        startDraw("struct-rect-post");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'struct-rect-post'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex column center" style={{ width: 60, height: 60 }}>
                            <div style={{ height: 34, width: 34, background: "#fff", border: "2px solid #8d959c" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>方柱</div>
                    </TouchableDiv>
                </div>
                <div className="flex" style={{ justifyContent: 'space-around', padding: '12px 8px' }}>
                    
                    <TouchableDiv onClick={() => {
                        startDraw("struct-beam-x");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'struct-beam-x'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex column center" style={{ width: 60, height: 60 }}>
                            <div style={{ height: 16, width: 48, background: "#fff", border: "2px solid #8d959c" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>横梁</div>
                    </TouchableDiv>
                    
                    <TouchableDiv onClick={() => {
                        startDraw("struct-beam-y");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'struct-beam-y'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex column center" style={{ width: 60, height: 60 }}>
                            <div style={{ height: 48, width: 16, background: "#fff", border: "2px solid #8d959c" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>纵梁</div>
                    </TouchableDiv>
                </div>
                <div className="sub-title" style={{ fontSize: 14, height: 36, lineHeight: '36px', padding: '12px 8px' }}>门窗</div>
                <div className="flex" style={{ justifyContent: 'space-around', padding: '12px 8px' }}>
                    <TouchableDiv onClick={() => {
                        startDraw("onwall-single-door");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'onwall-single-door'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex column center" style={{ width: 60, height: 60 }}>
                            <div className="relative" style={{ height: 28, width: 28, overflow: 'hidden' }}>
                                <div style={{ height: 56, width: 56, borderRadius: 28, border: "1px solid #888" }}></div>
                                <div className="absolute" style={{ top: 0, right: 0, height: 28, width: 5, border: "1px solid #888", background: "#fff" }} />
                            </div>
                            <div className="flex space-between" style={{ width: 48 }}>
                                <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                                <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                            </div>
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>单开门</div>
                    </TouchableDiv>
                    {/* <TouchableDiv onClick={() => {
                    startDraw("onwall-double-door");
                }} disable={!areaDrawed} active={drawType === "onwall-double-door"} className="flex center column" style={{ height: 68, width: 68, borderRadius: 6 }}>
                    <div className="flex column center" style={{ width: 60, height: 60 }}>
                        <div className="flex space-between relative" style={{ height: 28, width: 36, overflow: 'hidden' }}>
                            <div style={{ top: 0, right: 0, height: 28, width: 5, border: "1px solid #888", background: "#fff" }} />
                            <div className="flex absolute" style={{ top: 0, left: -18, height: 14, overflow: "hidden" }}>
                                <div style={{ height: 28, width: 36, borderRadius: 14, border: "1px solid #888" }} />
                                <div style={{ height: 28, width: 36, borderRadius: 14, border: "1px solid #888" }} />
                            </div>
                            <div style={{ top: 0, right: 0, height: 28, width: 5, border: "1px solid #888", background: "#fff" }} />
                        </div>
                        <div className="flex space-between" style={{ width: 48 }}>
                            <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                            <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                        </div>
                    </div>
                    <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>双开门</div>
                </TouchableDiv> */}

                    <TouchableDiv onClick={() => {
                        startDraw("onwall-sliding-door");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'onwall-sliding-door'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex center" style={{ width: 54, height: 60 }}>
                            <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                            <div style={{ flex: 1, fontSize: 0 }}>
                                <div>
                                    <div style={{ height: 5, width: 24, border: "1px solid #888", background: "#fff" }} />
                                </div>
                                <div style={{ textAlign: 'right' }} >
                                    <div className="inline-block" style={{ height: 5, width: 24, border: "1px solid #888", background: "#fff" }} />
                                </div>
                            </div>
                            <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>移门</div>
                    </TouchableDiv>
                    <TouchableDiv onClick={() => {
                        startDraw("onwall-window");
                    }} disable={drawingDisable || !areaDrawed} active={drawType === 'onwall-window'} className="flex center column" style={{ height: 68, width: 68, borderRadius: 4 }}>
                        <div className="flex center" style={{ width: 54, height: 60 }}>
                            <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                            <div style={{ flex: 1, fontSize: 0 }}>
                                <div>
                                    <div style={{ height: 5, width: 34, border: "1px solid #888", background: "#fff" }} />
                                </div>
                                <div style={{ textAlign: 'right' }} >
                                    <div className="inline-block" style={{ height: 5, width: 34, border: "1px solid #888", borderTop: 0, background: "#fff" }} />
                                </div>
                            </div>
                            <div style={{ height: 10, width: 10, border: "1px solid #000", background: "#eaf5ff" }} />
                        </div>
                        <div style={{ fontSize: 22, zoom: .5, marginBottom: 4 }}>一字窗</div>
                    </TouchableDiv>
                </div>
            </div>
        </div>
    )

}