import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const _3DWrapperV1 = () => {

    const iframeRef = useRef<HTMLIFrameElement>(null);
    


    const navigate = useNavigate();

    useEffect(() => {
        //@ts-ignore
        if(!window.$$order) {
            navigate("/", {
                replace: true
            });
        }
    }, []);

    const getTo3DData = () => {
        const w = window as any;
        if(w.$$order) {
            const order = w.$$order;
            const nestUrl = process.env.NODE_ENV === 'development' ? 
                    window.location.origin + `/#/nest?orderId=${order.id}` :
                         `/#/nest?orderId=${order.id}`
            return {
                nestUrl: nestUrl,
                order: order
            };
        }
    }

    useEffect(() => {
        //@ts-ignore
        window.$goNest = (payload: {
            order: any;
            nestRequest: any;
        }) => {
            //@ts-ignore
            window.$$order = payload.order;
            //@ts-ignore
            window.$$nestRequest = payload.nestRequest;
            navigate(`/nest?orderId=${payload.order.id}`)
        }
        //@ts-ignore
        window.$loadReport = () => {
            // let timer = setInterval(() => {
            //     //@ts-ignore
            //     if(iframeRef.current?.contentWindow?.$onChangeTo3D) {
            //         //@ts-ignore
            //         iframeRef.current?.contentWindow?.$onChangeTo3D(getTo3DData());
            //         clearInterval(timer);
            //     }
            // }, 50);
            return getTo3DData();
        }
    }, []);

    return (
        <iframe ref={iframeRef} src={localStorage.getItem("SK_CUPBOARD_DESIGN_URL") || "/3d/index.html"} style={{height: "100vh", width: "100vw", overflow: "hidden", border: 0}} />
    )


}