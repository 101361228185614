import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05zhuanjiaoneiXBoard } from "./05-zhuanjiaonei-x";
import { _05zhuanjiaoneiYBoard } from "./05-zhuanjiaonei-y";
import { _05zhuanjiaowaiYBoard } from "./05-zhuanjiaowai-y";

export const _07zhuanjiaowaiYBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return _05zhuanjiaowaiYBoard(cornerType, dx, dy, startX, startY, width, height, corner);
}