import {  Checkbox, Radio } from "antd";
// import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { isEn, locale } from "../../../utils/locale";

export const BoardTypes = (props) => {
    
    const [boardTypes, setBoardTypes] = useState(props.value || []);

    useEffect(() => {
        setBoardTypes(props.value || []);
    }, [props.value]);


    const handlerChange = (boardTypes) => {
        if(typeof props.onChange === 'function') {
            //console.log(boardTypes);
            props.onChange(boardTypes);
        }
    }

    const [selectedBoardTypes, setSelectedBoardTypes] = useState((props.value || []).filter((item) => item.selected));
    useEffect(() => {
        setSelectedBoardTypes(boardTypes.filter((item) => item.selected))
    }, [boardTypes]);

    const [availableMaterials, setAvailableMaterials] = useState(((props.value) || []).filter((item) => item.hide === false));
    useEffect(() => {
        //console.log("materials");
        setAvailableMaterials(props.materials.filter((item) => item.hide === false));
    }, [props.materials]);


    return (
        <div className="board-types">
            {
                boardTypes.length > 0 && boardTypes.map((item, index) => {
                    return (
                        <div onClick={() => {
                            item.selected = !item.selected;
                            boardTypes[index] = {...item};
                            setBoardTypes([...boardTypes]);
                            handlerChange(JSON.parse(JSON.stringify(boardTypes)));
                        }} className="input-wrapper" style={{cursor: 'pointer', display: 'inline-block', lineHeight: '30px', marginRight: 16, textAlign: 'center'}}>
                            <Checkbox style={{marginRight: 5}} checked={item.selected}/>
                            <span>{item.name}</span>
                        </div>
                    )
                })
            }

             <div className="boards-material">
            {
                selectedBoardTypes.length > 0 && selectedBoardTypes.map((board) => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', padding: '5px 8px'}}>
                            <div style={{width: isEn ? 130 : 60, marginRight: 10}}>
                                {board.name}{locale['材料']}: 
                            </div>
                            <div style={{flex: 1}}>
                                <Radio.Group value={board.materialId} onChange={(e) => {
                                    const index = boardTypes.findIndex((item) => item.id === board.id);
                                    //console.log(index);
                                    board.materialId = e.target.value;
                                    boardTypes[index] = {...board};
                                    // setBoardTypes(.set(index, {...board}));
                                    handlerChange([...boardTypes]);
                                }}>
                                    {
                                        availableMaterials.length > 0 && availableMaterials.map((item) => {
                                            return (
                                                <Radio value={item.id}>{item.name}</Radio>
                                            )
                                        })
                                    }
                                </Radio.Group>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}