import { Circle } from "../../../../constants";
import { DirectX, DirectY } from "../../../../constants/enum";
import { conbinePaths } from "../../../../util/graph";
import { sanheyi01DiwaiBoard } from "./sanheyi-01-diwai";

export const sanheyi01BeiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = sanheyi01DiwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
    const paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    const circles = paths.filter((path) => path.type === Circle);
    for (let i = 0; i < circles.length; i++) {
        const circle = circles[i];
        const [x, y] = circle.params;
        if(x < (startX + startX + dx * width) / 2) {
            circle.params[0] = startX + dx * 15.35
        }else {
            circle.params[0] = startX + dx * width - dx * 15.35
        }
    }
    return [
        paths.slice(0, dineiBoardPaths.length),
        paths.slice(dineiBoardPaths.length, paths.length),
    ]
 
}   