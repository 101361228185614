import { ConfigProvider, theme } from "antd";
import { forwardRef, MutableRefObject, Ref, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Tabs, { TabItemData } from "./Tabs";
import Widgets from "./Widgets";

interface Props {
    children?: React.ReactNode;
}

const { darkAlgorithm, /* compactAlgorithm */ } = theme;



export default forwardRef(function Header(props: Props, ref: any) {

    const tabsRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            tabsRef
        }
    });

    return (
        <ConfigProvider 
            theme={{
                algorithm: [ darkAlgorithm ]
            }}
        >
            <Wrapper>
                <Logo />
                <Tabs ref={tabsRef}/>
                <Widgets 
                    tabsRef={tabsRef}
                />
            </Wrapper>
        </ConfigProvider>
    )

})

const Wrapper = styled.div`
    display: flex;
    background-color: #111;
    -webkit-app-region: drag;
`;