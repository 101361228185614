import { Circle } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../util/graph";
import { corner1 } from "./corner1";

export const corner7 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const corner1Paths = corner1(dx, dy, startX, startY, true);
    let paths1 = corner1Paths.slice(corner1Paths.length - 13, corner1Paths.length);
    paths1 = pathMoveX(paths1, DirectX.LEFT, dx * 15.01);
    paths1 = pathMoveY(paths1, DirectY.DOWN, dy * 55.095123);
    let paths2 = JSON.parse(JSON.stringify(paths1));
    paths2 = pathMirrorY(paths2, startX);
    paths2  = pathRotate(paths2, dx * dy * 90);
    let result = [...paths1, ...paths2];
    result = pathMoveX(result, DirectX.RIGHT, dx * 14.99);
    result = pathMoveY(result, DirectY.UP, dy * 17.855654);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = 17.855654 + 14.99;
            sizeRes.height = 14.99 + 17.855654;
        // }
    }
    return result.filter((item) => corner ? true : item.type === Circle);
}
