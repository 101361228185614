import { Arc } from "../constants";
import { DirectX, DirectY } from "../constants/enum";
import { degreeTransform } from "./graph";

export const degreeTransformForEach = (paths: Path[], dx: DirectX, dy: DirectY) => {
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === Arc) {
            const [ fromD, toD ] = degreeTransform(path.params[3], path.params[4], dx, dy);
            
            path.params[3] = fromD;
            path.params[4] = toD;
        }
    }
    return paths;
}

export const numberEqual = (number1: number, number2: number, precision: number = 0.001) => {
    if(number1 + precision > number2 && number2 > number1 - precision) {
        return true;
    }
    return false;
}