import { LayerDrawRectWall } from "./drawing-rect-wall"
import { LayerDrawingStraightWall } from "./drawing-straight-wall"

export const LayerDrawingWall = () => {

    return (
        <>
            <LayerDrawingStraightWall />
            <LayerDrawRectWall />
        </>
    )
}