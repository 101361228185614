import { Circle, Line, Arc } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { clearCorner, cornerMirrorX, pathMoveX, pathMoveY } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner14 } from "../../coners/corner14";
import { corner15 } from "../../coners/corner15";
import { corner14WaiWuhujiao } from "../../coners/无护角/corner14-wai-wuhujiao";
import { corner15WaiWuhujiao } from "../../coners/无护角/corner15-wai-wuhujiao";
import { corner14WaiWujiao } from "../../coners/无角/corner14-wai-wujiao";
import { corner15WaiWujiao } from "../../coners/无角/corner15-wai-wujiao";
import { rectBoard } from "../general-board";

// export const 
export const _02cewaiBoardLeft = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    menxiagaochu?: number,
    extraConfig?: any
): [Path[], Path[]] => {
    
    menxiagaochu = menxiagaochu || 0;

    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];//　 = corner14(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner14(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);
    }else if(cornerType === "wujiao") {
        cornerBL = corner14WaiWujiao(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);
        
    }
    // if(!corner) {
    //     cornerBL = clearCorner(cornerBL);
    // }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];// = corner15(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner15(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - 3 * cornerBRSize.width);
    }else if(cornerType === "wujiao") {
        cornerBR = corner15WaiWujiao(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - 3 * cornerBRSize.width);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner15WaiWuhujiao(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - 3 * cornerBRSize.width);
        
    }
    // if(!corner) {
    //     cornerBR = clearCorner(cornerBR);
    // }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];// = corner15(dx, dy, startX, startY, corner, cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner15(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    }else if(cornerType === "wujiao") {
        cornerTR = corner15WaiWujiao(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTR = corner15WaiWuhujiao(dx, dy, startX, startY, corner, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        
    }

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];//
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner14(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wujiao") {
        cornerTL = corner14WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner14WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        
    }

    const boardConfig: any = {
        middleware: extraConfig?.noMiddleware ? {} : {
            top: width > 500 && true,
            left: height > 500 && true,
            right: false,
            bottom: width > 500 && true,
        }
    }
    let paths: Path[];
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    if (lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 24.5), startY + dy * height - dy * (lianjiekongbianju + 24.5),
                2.75
            ]
        });
    }
    if (jiaolianbianju > -1 && !nojiaoliankong) {
        let offsetGX = 0;
        let offsetGY = 0;
        if (gxinglashou) {
            if (gxinglashouType === "up") {
                offsetGY = -1.5;
            }else if(gxinglashouType === "left-or-right") {
                offsetGX = 0;
            }
        }
        
        const temp: Path[] = [{
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju + 4) - dy * menxiagaochu,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju + 4) - dy * menxiagaochu,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8 - dy * menxiagaochu,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju + 4) + dy * 8 - dy * menxiagaochu,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8 - dy * menxiagaochu,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju + 4) - dy * menxiagaochu
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) - dy * menxiagaochu,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) - dy * menxiagaochu,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4 - dy * menxiagaochu,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) + dy * 4 - dy * menxiagaochu,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4 - dy * menxiagaochu,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) - dy * menxiagaochu
            ]
        },
        //**********************
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju + 4) + dy * 32 - dy * menxiagaochu,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 32 - dy * menxiagaochu,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8 + dy * 32 - dy * menxiagaochu,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * (jiaolianbianju + 4) + dy * 8 + dy * 32 - dy * menxiagaochu,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 8 + dy * 32 - dy * menxiagaochu,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * (jiaolianbianju + 4) + dy * 32 - dy * menxiagaochu
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) + dy * 32 - dy * menxiagaochu,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 32 - dy * menxiagaochu,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4 + dy * 32 - dy * menxiagaochu,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * (jiaolianbianju + 6) + dy * 4 + dy * 32 - dy * menxiagaochu,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 4 + dy * 32 - dy * menxiagaochu,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * (jiaolianbianju + 6) + dy * 32 - dy * menxiagaochu
            ]
        },
        //==============================================
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY),
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 8,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 8,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 8,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY)
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY),
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY),
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 4,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 4,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 4,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY)
            ]
        },
        //******************* 
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 32,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 32,
                startX + dx * width - dx * 64.75 - dx * offsetGX - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 8 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 8 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 8 + dy * 32,
                startX + dx * width - dx * 64.75 - dx * offsetGX + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 4 + 40 +  + offsetGY) + dy * 32
            ]
        },
        //----------------
        {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 32,
                3.25,
                180, 360
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 32,
                startX + dx * width - dx * 49.25 - dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 4 + dy * 32,

            ]
        }, {
            type: Arc,
            params: [
                startX + dx * width - dx * 49.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 4 + dy * 32,
                3.25,
                0, 180
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 4 + dy * 32,
                startX + dx * width - dx * 49.25 + dx * 3.25, startY + dy * height - dy * (jiaolianbianju + 42 + offsetGY) + dy * 32
            ]
        }
        ];
        paths.push(...temp);
    }
    if(geceng) {
        gecengX = gecengX as number;
        gecengY = gecengY as number;
        paths.push({
            type: Circle,
            params: [
                startX + dx * 74, startY + dy * (gecengY + 6),
                (gecengkongjing || 3) / 2,
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (74 + gecengX), startY + dy * (gecengY + 6),
                (gecengkongjing || 3) / 2
            ]
        });
    }
    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ];
}