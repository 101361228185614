
import { DirectX, DirectY } from "../../../../constants/enum";
import { sanheyi02DineiBoard } from "./sanheyi-02-dinei";

export const sanheyi02DingneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return sanheyi02DineiBoard(dx, dy, startX, startY, width, height);
}