import { Suspense } from "react"
import { Preview3D } from "./3d-preview"

export const RightWidget = ()  => {

    return (
         
        <div className="flex column fixed center 3d-view" style={{ position: 'fixed', right: 0, top: 54, width: 270, height: 240, overflow: 'hidden', background: "#e8e8e8", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
        <div style={{ background: "#f8f9fb", height: 30, width: '100%', fontSize: 14, color: '#666', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px' }}>
            <span className="bold">3D预览</span>
        </div>
        <div className="flex center" style={{ flex: 1 }}>
            <Suspense fallback={"加载中..."}>
            <Preview3D 
                height={240}
                width={240}
            />

            </Suspense>
            {/* <span style={{ fontSize: 48 }}>3D VIEW</span> */}
        </div>
    </div>
    )

}