import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { AreaData, OnWallData, StructData, WallData, useDrawed } from "../../../store"
import { ShapeWall } from "../$shapes/shape-wall";
import { Circle, Group, Layer, Rect, Text } from "react-konva";
import { ShapeArea } from "../$shapes/shape-area";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { Vector2d } from "konva/lib/types";
import { ShapeOnWall } from "../$shapes/shape-onwall";
import { ShapeStruct } from "../$shapes/shape-struct";
import Konva from "konva";

export interface LayerDrawedListRef {
    getGroupRef: () => Konva.Group
}
export const LayerDrawedList = forwardRef((props: {}, ref: ForwardedRef<LayerDrawedListRef>) => {

    const { isEditing, stage, isDrawing, drawedList, startEdit, cancelDraw } = useDrawed();
    const [selectedShapeId, setSelectedShapeId] = useState<string | null>(null);

    useEffect(() => {
        if (!isEditing) {
            setSelectedShapeId(null);
        }
    }, [isEditing]);

    useEffect(() => {
        if (isDrawing) {

        }
    }, [isDrawing]);

    const groupRef = useRef<Konva.Group>(null);

    useImperativeHandle(ref, () => {
        return {
            getGroupRef: () => {
                
                return groupRef.current;
            }
        }
    });

    return (
        <Layer>
            <Group ref={groupRef}>
                {
                    useMemo(() => {
                        return drawedList.length > 0 && drawedList.map((d) => {
                            if (d.type.indexOf('wall') === 0) {
                                const { startAnchorPos, nextAnchorPos, wallDirect, wallLength, wallThickness } = d.data as WallData;
                                return (
                                    <ShapeWall
                                        type="normal"
                                        wallStartAnchor={startAnchorPos}
                                        wallDirect={wallDirect}
                                        wallLength={wallLength}
                                        wallThickness={wallThickness}

                                    />
                                )
                            } else if (d.type === 'area') {
                                const { areaName, anchors, innerContour, outerContour, innerArea, innerBound, outerBound, wallThickness } = d.data as AreaData;
                                return (
                                    <>
                                        <ShapeArea
                                            areaName={areaName}
                                            wallThickness={wallThickness}
                                            anchors={anchors}
                                            innerContour={innerContour}
                                            outerContour={outerContour}
                                            innerArea={innerArea}
                                            innerBound={innerBound}
                                            hideAreaLabel={isDrawing || isEditing}
                                        />
                                        {
                                            (() => {
                                                if (isDrawing || isEditing) {
                                                    return;
                                                }
                                                //复制线
                                                let last = innerContour[0];
                                                const dimension: {
                                                    direct: "x" | "y",
                                                    from: number,
                                                    to: number,
                                                    base: number,
                                                    offset: number,
                                                }[] = [];
                                                const calc = (last: Vector2d, anchor: Vector2d) => {
                                                    let direct: "x" | "y";
                                                    let offset: number;
                                                    if (Math.abs(anchor.x - last.x) !== 0) {
                                                        direct = "x";
                                                        const from = last.x;
                                                        const to = anchor.x;
                                                        const base = anchor.y;
                                                        if (base - outerBound.top < outerBound.bottom - base) {
                                                            offset = -(base - outerBound.top) - 20;
                                                        } else {
                                                            offset = outerBound.bottom - base + 20;
                                                        }
                                                        dimension.push({
                                                            direct,
                                                            from,
                                                            to,
                                                            base,
                                                            offset
                                                        })
                                                    } else {
                                                        direct = "y";
                                                        let offsetSign = 1;
                                                        if (anchor.x <= (innerBound.left + innerBound.right) / 2) {
                                                            offsetSign = -1;
                                                        }
                                                        const from = last.y;
                                                        const to = anchor.y;
                                                        const base = anchor.x;

                                                        if (base - outerBound.left < outerBound.right - base) {
                                                            offset = -(base - outerBound.left) - 20;
                                                        } else {
                                                            offset = outerBound.right - base + 20;
                                                        }
                                                        dimension.push({
                                                            direct,
                                                            from,
                                                            to,
                                                            base,
                                                            offset
                                                        });
                                                    }
                                                }
                                                for (let i = 1; i < innerContour.length; i++) {
                                                    const anchor = innerContour[i];
                                                    calc(last, anchor);
                                                    last = anchor;
                                                }
                                                calc(innerContour[innerContour.length - 1], innerContour[0]);

                                                return dimension.map(d => (
                                                    <ShapeDimension {...d} />
                                                ))
                                            })()
                                        }
                                    </>
                                )
                            } else if (d.type.indexOf("struct") === 0) {
                                const data = d.data as StructData;
                                return (
                                    <ShapeStruct
                                        {...data}
                                        selectable
                                        selected={selectedShapeId === data.id}
                                        onSelectChange={(selected) => {
                                            if (selected) {
                                                setSelectedShapeId(data.id);
                                                startEdit(d);
                                            }
                                        }}
                                    />
                                )
                            } else if (d.type.indexOf("onwall") === 0) {
                                const data = d.data as OnWallData;
                                return (
                                    <ShapeOnWall
                                        {...data}
                                        selectable
                                        selected={selectedShapeId === data.id}
                                        onSelectChange={(selected) => {
                                            if (selected) {
                                                setSelectedShapeId(data.id);
                                                startEdit(d);
                                            }
                                        }}
                                    />
                                )
                            }
                            return (<></>);
                        })

                    }, [drawedList, isDrawing, isEditing, selectedShapeId])
                }
            </Group>
        </Layer>
    )

})