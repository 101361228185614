import { useEffect, useRef, useState } from "react"
import { Outlet, matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import './style.css';
import { ArrowLeftOutlined, ExportOutlined, LeftOutlined, RightOutlined, SettingOutlined } from "@ant-design/icons";
import { NestRequest, Material, NestSheet, NestBoard } from "../../../../@interface";
import { DroppableSheet } from "./comp-draggable/droppable-sheet";
import { SheetDnDContext } from "./comp-draggable/sheet-dnd-context";
import { BoardAction, BoardActionMap, BoardMoveAction, BoardMoveToSheetAction, BoardRotateAction } from "./comp-draggable/draggable-data";
import { Button, message } from "antd";
import { ExportViewModal } from "./comp-export/export-view-modal";
import { wsHost } from "../../utils/apis";
import { sheetBoardDragStore } from "./store";
import { ExportSettingsModel } from "./comp-export/export-settings-modal";
import { request } from "../../utils2/request";
import { apis } from "../../utils2/apis";

export const NestResult = (props: {
    data?: NestRequest
}) => {

    const location = useLocation();
    const [data] = useState<NestRequest>(props.data || location.state.data);
    const [materialMap, setMaterialMap] = useState<Map<string | number, Material>>(null);


    const param = useParams<{ materialId?: string, sheetId?: string }>();
    const navigate = useNavigate();


    useEffect(() => {
        const map = new Map<string | number, Material>();
        data.materials.forEach((m) => {
            map.set(m.id, m);
        });
        setMaterialMap(map);
    }, []);

    const [tempSheet, setTempSheet] = useState<NestSheet | null>(null);

    const [sheetIdRefsMap] = useState<any>({});
    
    const [boardActionsMap,setBoardActionsMap] = useState<any>({});
    const nestResultView = useRef<any>();
    const [exporting, setExporting] = useState(false);
    const onExporting = async (data:NestRequest, downloadDirectly = false) => {
        const boardActionsMap = sheetBoardDragStore.boardActionMaps || {};
        const close = message.loading("准备导出...", 0);
        let sheetCostSettings: any[] = [];
        let partCostSettings: any[] = [];
        try {
            const [partCostRes, sheetCostRes] = await Promise.all([
                request.get(apis.costSettings + "/part/list"),
                request.get(apis.sheet + "/list"),
            ]);
            if(partCostRes.code === 1) {
                partCostSettings = partCostRes.data.filter((item) => !!item.price);
            }
            if(sheetCostRes.code === 1) {
                sheetCostSettings = sheetCostRes.data.filter((item) => !!item.price);
            }

        }catch(e) {
            console.log(e);
        }finally {
            close();
        }
        console.log(sheetCostSettings, partCostSettings);
        try {

            setExporting(true);
            data = JSON.parse(JSON.stringify(data)) as (typeof data);
            data.nestResult?.forEach((r) => {
                const material = data.materials.find((m) => m.id === r.materialId);
                r.sheets.forEach((originSheet, i) => {
                    const boards: NestBoard[] = [];
                    originSheet.boards.forEach((originBoard) => {
                        const actions = boardActionsMap[originBoard.board.id] || [];
                        //计算
                        const boardRotateActions = actions.filter((a) => a.type === 'rotate');
                        let rotate = false;
                        if (boardRotateActions.length > 0) {
                            rotate = (boardRotateActions[boardRotateActions.length - 1] as BoardRotateAction).rotate;
                        }
                        const moveRotateActions = actions.filter((a) => a.type === 'move') as BoardMoveAction[];
                        let deltaX = 0;
                        let deltaY = 0;
                        moveRotateActions.forEach((a) => {
                            deltaX += a.deltaX;
                            deltaY += a.deltaY;
                        });
                        let isMovedToOtherSheet = false;
                        const boardMoveToSheetActions = actions.filter((a) => a.type === 'move-to-sheet') as BoardMoveToSheetAction[];
                        if (boardMoveToSheetActions.length > 0) {
                            const lastMove = boardMoveToSheetActions[boardMoveToSheetActions.length - 1];
                            if (lastMove.targetSheetId !== originSheet.id) {
                                isMovedToOtherSheet = true;
                            }
                        }
                        if (!isMovedToOtherSheet) {
                            // const newBoard = JSON.parse(JSON.stringify(originBoard)) as (typeof originBoard);
                            originBoard.customX = originBoard.customX + deltaX;
                            originBoard.customY = originBoard.customY + deltaY;
                            if (rotate) {
                                originBoard.rotate = !originBoard.rotate;
                                //@ts-ignore hack，only rotate, rect in tag ajust
                                originBoard.rotateFlag = true;
                            }
                            boards.push(originBoard)
                        }
                    })
                    originSheet.tagRects = [];
                    originSheet.tags = [];
                    boards.forEach((placedBoard) => {
                        const board = placedBoard.board;
                        const sheetNo = i + 1;
                        //@ts-ignore 
                        const rotateFlag = placedBoard.rotateFlag;
                        originSheet.tagRects.push({
                                    x: placedBoard.customX,
                                    y: placedBoard.customY,
                                    [rotateFlag ? 'height' : 'width']: placedBoard.width,
                                    [rotateFlag ? 'width' : 'height']: placedBoard.height,
                                    number: sheetNo.toString(),
                                    id: board.nestId
                                } as any);
                                originSheet.tags.push({
                                    body: {
                                        list: [{
                                            content: `地址：${data.order.address}`,
                                        }, {
                                            content: `橱柜：${board.cupboardName}`
                                        }, {
                                            content: `编号：${board.nestId}`,
                                        }, {
                                            content: `板件：${board.name}_${board.type}`,
                                        }, {
                                            content: `材质：${material.name}`,
                                        }, {
                                            content: `尺寸：${board.width}mm × ${board.height}mm`
                                        }, {
                                            content: `所属大板：${sheetNo}/${r.sheets.length}`
                                        }],
                                        $rects: {
                                            x: placedBoard.customX,
                                            y: placedBoard.customY,
                                            [rotateFlag ? 'height' : 'width']: placedBoard.width,
                                            [rotateFlag ? 'width' : 'height']: placedBoard.height,
                                            number: sheetNo.toString(),
                                            id: board.nestId
                                        } as any,
                                    },
                                    footer: {
                                        content: `单号：${data.order.orderNo}`,
                                    },
                                    extra: {
                                        qrcodeUrl: board.qrcodeUrl || "123"
                                    }
                                })
                    })
                });
                
            })

            const websocket = new WebSocket(wsHost + `/ws/nest/result/export`);
            websocket.onmessage = (e) => {
                let exportSettings: any;
                const localExportSettings = localStorage.getItem("SK_EXPORT_SETTINGS");
                if (localExportSettings) {
                    try {
                        exportSettings = JSON.parse(localExportSettings);
                    }catch(e) {
                        console.log(e);
                    }
                }
                if(!exportSettings) {
                    exportSettings = {};
                }
                if(!exportSettings.tagSettings) {
                    exportSettings.tagSettings = {
                        tagTemplate: "v2",
                        showWaterMark: true,
                        waterMark: "大圣设备",
                        waterMarkColor: "blue",
                        barCodeWidth: 1.9
                    }
                }
                const messgae = e.data.toString();
                const json = JSON.parse(messgae);
                if (json.event === 'export:ready') {
                    websocket.send(JSON.stringify({
                        event: "export:start",
                        data: data,
                        more: {
                            tagVersion: exportSettings.tagSettings.tagTemplate,
                            barCodeWidth: Number(exportSettings.tagSettings.barCodeWidth) ? Number(exportSettings.tagSettings.barCodeWidth) : 1.9,
                            showFrame: exportSettings.tagSettings.showFrame,
                            costSettings: {
                                partCostSettings,
                                sheetCostSettings
                            
                            }
                        }
                    }));
                    nestResultView?.current.open(data, materialMap, downloadDirectly);
                } else if (json.event === 'export:dxf-generating') {
                    //导出dxf
                    nestResultView?.current.onDxfGenerating(json.data.materialId);
                } else if (json.event === 'export:dxf-generate-success') {
                    nestResultView?.current.onDxfGenerateSuccess(json.data.materialId, json.data.fileName, json.data.downloadUrl);
                } else if (json.event === 'export:dxf-generate-fail') {
                    nestResultView?.current.onDxfGenerateFail(json.data.materialId, json.data);
                } else if (json.event === 'export:tag-pdf-generating') {
                    //导出标签
                    nestResultView?.current.onTagPdfGenerating(json.data.materialId);
                } else if (json.event === 'export:tag-pdf-generate-success') {
                    nestResultView?.current.onTagPdfGenerateSuccess(json.data.materialId, json.data.fileName, json.data.downloadUrl);
                } else if (json.event === 'export:tag-pdf-generate-fail') {
                    nestResultView?.current.onTagPdfGenerateFail(json.data.materialId, json.data);
                }else if(json.event === 'export:excel-generating') {
                    //导出excel
                    nestResultView?.current.onExcelGenerating();
                } else if(json.event === 'export:excel-generate-success') {
                    nestResultView?.current.onExcelGenerateSuccess(json.data.fileName, json.data.downloadUrl)
                } else if(json.event === 'export:excel-generate-fail') {
                    nestResultView?.current.onExcelGenerateFail(json.data);
                } else if (json.event === 'export:zip-generating') {
                    //生成zip包
                    nestResultView?.current.onZipGenerating();
                } else if (json.event === 'export:zip-generate-success') {
                    nestResultView?.current.onZipGenerateSuccess(json.data.fileName, json.data.downloadUrl);
                    websocket.close();
                } else if (json.event === 'export:zip-generate-fail') {
                    nestResultView?.current.onZipGenerateFail(json.data);
                    websocket.close();
                }
            };
            websocket.onclose = () => {
                setExporting(false);
            }
        } catch (e) {
            message.warning("出现错误");
            console.log(e);
            
            setExporting(false);
        }

    }

    const exportSettingsRef = useRef<any>();

    return (
        <SheetDnDContext>
            <div className="flex">
                <div className="sheet-list">
                    <div className="logo flex center space-between" style={{padding: '12px'}}>
                        {/* <img src="/favicon.ico" className="icon"/> */}
                        <ArrowLeftOutlined style={{cursor: "pointer"}} onClick={() =>{
                            navigate(-1);
                        }}/>
                        <Button type="link" onClick={() => {
                            exportSettingsRef.current?.open();
                        }}><SettingOutlined />导出设置</Button>
                        <ExportViewModal
                            ref={nestResultView}
                            compatibleV1={data.compatibleV1}
                        />
                        <ExportSettingsModel 
                            ref={exportSettingsRef}
                        />
                    </div>
                    <div className="nav-list">
                        
                        <Button size="large" style={{ margin: '-4px 12px 12px 12px' }} disabled={exporting} loading={exporting} type="primary" key={"export-dxf"} onClick={() => { onExporting(data) }}>
                            <ExportOutlined  />
                            导出图纸DXF和标签PDF
                        </Button>
                        <div onClick={() => {
                            navigate(`/nest/result`, {
                                state: location.state,
                                replace: true
                            });
                        }} className={`all flex center space-between static item ${location.pathname === '/nest/result' ? 'active' : ''}`}>
                            <span>排板总览</span>
                            <span className="flex center">
                                <span style={{ marginRight: 4, fontSize: 10 }}>总览</span>
                                <RightOutlined style={{ fontSize: 12 }} />
                            </span>

                        </div>
                        <div className="dynamic no-scroll-bar">
                            <div style={{ color: "gray", fontSize: 12, marginLeft: 12, marginBottom: 4 }}>板材列表</div>
                            {
                                data.nestResult?.length > 0 && data.nestResult.map((materialNest) => {
                                    const material = data.materials.find((m) => m.id === materialNest.materialId);
                                    const settings = data.nestConfig.materialNestSettings.find((s) => s.materialId === materialNest.materialId);

                                    return (
                                        <div className={`item-wrapper ${param.materialId === materialNest.materialId ? matchPath("/nest/result/:materialId", location.pathname) ? 'active' : 'material' : ''}`}>
                                            <div onClick={() => {
                                                navigate(`/nest/result/${materialNest.materialId}`, {
                                                    state: location.state,
                                                    replace: true
                                                });
                                            }} className={`material item `} style={{ marginBottom: 0 }}>
                                                <div className="title flex center space-between" style={{ justifyContent: 'space-between' }}>
                                                    <span className="flex center">
                                                        {
                                                            material?.texture?.type === 'IMAGE' && (
                                                                <img src={material.texture.value} style={{ height: 18, width: 18, borderRadius: 4 }} />
                                                            )
                                                        }
                                                        {
                                                            material?.texture?.type === 'COLOR' && (
                                                                <div className="inline-block" style={{ height: 18, width: 18, borderRadius: 4, background: material.texture.value }} />
                                                            )
                                                        }
                                                        <span style={{ marginLeft: 6 }}>{material.name}</span>
                                                    </span>
                                                    <span className="flex center">
                                                        <span style={{ marginRight: 4, fontSize: 10 }}>查看</span>
                                                        <RightOutlined style={{ fontSize: 12 }} />
                                                    </span>

                                                </div>
                                                <div className="flex center space-between" style={{ fontSize: 11 }}>
                                                    <span>长：{settings?.sheetWidth || '2238'}mm × 宽：{settings?.sheetHeight || '1218'}mm</span>
                                                    <span>{materialNest.sheets.length}块</span>
                                                </div>
                                                <div className="flex center space-between" style={{ fontSize: 11, marginTop: 4 }}>
                                                    <span>切割间隙：{settings?.clipSpace || "10"}mm</span>
                                                    <span>{settings?.rotation ? '可' : '不可'}旋转</span>
                                                </div>
                                            </div>
                                            {
                                                param.materialId === materialNest.materialId && (

                                                    <div className="sheets">
                                                        {
                                                            materialMap && materialNest.sheets.map((sheet, sheetIndex) => {
                                                                return (
                                                                    <div className={`sub-item-wrapper flex center ${param.sheetId === sheet.id ? 'active' : ''} ${sheet.id === tempSheet?.id ? 'temp' : ''}`} >
                                                                        <div className="line" />
                                                                        <DroppableSheet
                                                                            ref={(ref) => {
                                                                                sheetIdRefsMap[sheet.id] = ref;
                                                                            }}
                                                                            data={data}
                                                                            materialNest={materialNest}
                                                                            sheet={sheet}
                                                                            sheetIndex={sheetIndex}
                                                                            materialMap={materialMap}
                                                                            disableDrag={false}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="main no-scroll-bar">
                    <Outlet
                        context={{
                            data: data,
                            setTempSheet,
                            onSheetBoardChange: (sheetId:  string | number, boardActionMap:BoardActionMap) => {
                                sheetIdRefsMap[sheetId]?.updateByBoardActions(boardActionMap);
                            }
                        }}
                    />
                </div>
            </div>
        </SheetDnDContext>
    )
}
