import { DirectX, DirectY } from "../../../constants/enum";

import { cewaiBoardRight } from "../地柜/01-cewai-right";

export const _03cewaiBoardRight = (
   cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    lianjiekongbianju: number,
    corner: boolean,
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number, 
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return cewaiBoardRight(cornerType, dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, lianjiekongbianju, corner, geceng, gecengX, gecengY, gecengkongjing, height - 67, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
 }