import { useEffect, useState } from "react";
import { WallData } from "../../../store";
import { Line, Rect } from "react-konva";
import { Vector2d } from "konva/lib/types";

interface ShapeColor {
    fill: string;
    stroke: string;
}

const shapeWallTypeColorMap = {
    "normal": {
        fill: "#a6a6a6",
        stroke: "#595959"
    },

    "fake": {
        fill: "#147ffa66",
        stroke: "#147ffa"
    }
}

const dangerColor = {
    fill: "#ff450066",
    stroke: "#ff4500"
}

const indicatorColor = {
    fill: "#147ffa66",
    stroke: "#147ffaaa"

}


type ShapeWallType = keyof typeof shapeWallTypeColorMap;

export const ShapeWall = (

    props: {
        type: ShapeWallType;
        danger?: boolean;
        opacity?: number;
        wallStartAnchor: Vector2d,
        wallDirect: "x" | "y",
        wallLength: number,
        wallThickness: number,
    }

) => {


    const [points, setPoints] = useState<number[]>([]);

    const [fill, setFill] = useState("");
    const [stroke, setStroke] = useState("");

    useEffect(() => {
        let color: ShapeColor;
        if (props.danger) {
            color = dangerColor;
        } else {
            color = shapeWallTypeColorMap[props.type];
        }
        setFill(color.fill);
        setStroke(color.stroke);
    }, [props.type, props.danger]);


    const { wallStartAnchor, wallDirect, wallLength, wallThickness } = props;

    useEffect(() => {
        const points: number[] = [];
        if (wallDirect === 'x') {
            if (wallLength > 0) {
                //→方向
                points.push(
                    wallStartAnchor.x - wallThickness / 2, wallStartAnchor.y - wallThickness / 2,
                    wallStartAnchor.x - wallThickness / 2, wallStartAnchor.y + wallThickness / 2,
                    wallStartAnchor.x - wallThickness / 2 + wallLength, wallStartAnchor.y + wallThickness / 2,
                    wallStartAnchor.x - wallThickness / 2 + wallLength, wallStartAnchor.y - wallThickness / 2,
                );
            } else {
                //左
                points.push(
                    wallStartAnchor.x + wallThickness / 2, wallStartAnchor.y - wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2, wallStartAnchor.y + wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2 + wallLength, wallStartAnchor.y + wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2 + wallLength, wallStartAnchor.y - wallThickness / 2,
                );
            }
        } else {
            if (wallLength > 0) {
                //↓方向
                points.push(
                    wallStartAnchor.x - wallThickness / 2, wallStartAnchor.y - wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2, wallStartAnchor.y - wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2, wallStartAnchor.y - wallThickness / 2 + wallLength,
                    wallStartAnchor.x - wallThickness / 2, wallStartAnchor.y - wallThickness / 2 + wallLength,
                )
            } else {
                //↑方向
                points.push(
                    wallStartAnchor.x - wallThickness / 2, wallStartAnchor.y + wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2, wallStartAnchor.y + wallThickness / 2,
                    wallStartAnchor.x + wallThickness / 2, wallStartAnchor.y + wallThickness / 2 + wallLength,
                    wallStartAnchor.x - wallThickness / 2, wallStartAnchor.y + wallThickness / 2 + wallLength,
                )
            }
        }
        setPoints(points);
    }, [wallStartAnchor, wallDirect, wallLength, wallThickness]);


    return (
        <Line
            points={points}
            closed
            strokeScaleEnabled={false}
            fill={fill}
            opacity={props.opacity}
            stroke={stroke}
            strokeWidth={1}
        />
    )
}

export const ShapeWallAnchorIndicator = (props: {
    x: number,
    y: number,
    wallThickness: number;
    opacity?: number;
    danger?: boolean
}) => {

    const [fill, setFill] = useState("");
    const [stroke, setStroke] = useState("");

    useEffect(() => {
        let color: ShapeColor;
        if (props.danger) {
            color = dangerColor;
        } else {
            color = indicatorColor;
        }
        setFill(color.fill);
        setStroke(color.stroke);
    }, [props.danger]);


    return (
        <Rect
            x={props.x - props.wallThickness / 2}
            y={props.y - props.wallThickness / 2}
            fill={fill}
            stroke={stroke}
            strokeWidth={1}
            strokeScaleEnabled={false}
            opacity={(props.opacity || 1) - 0.4}
            width={props.wallThickness}
            height={props.wallThickness}
        />
    )

}