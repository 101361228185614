import React, { createRef } from 'react';
import { Tabs, Button } from 'antd';
import { locale } from '../../utils/locale';

export class DxfCanvasPreview extends React.PureComponent {

    canvasRef = createRef();
    canvas;
    ctx;

    canvasHeight = 0;
    canvasWidth = 0;
    canvasOffsetTop = 0;
    canvasOffsetLeft = 0;

    constructor(props) {
        super(props);
        this.state = {
            height: props.height || 0,
            width: props.width || 0,
            heightInitd: false,
            widthInitd: false
        }
    }

    componentDidMount() {
        const canvas = this.canvasRef.current;

        if (canvas.width && canvas.height) {
            canvas.width = this.state.width - 18;
            this.canvasWidth = this.state.width - 18;
            canvas.height = this.state.height - 37;
            this.canvasHeight = this.state.height - 37;
        }
        if (canvas) {
            this.canvas = canvas;
            this.ctx = canvas.getContext("2d");
            // canvas.addEventListener("mousewheel", this.canvasScaleEvent, false);
        }

    }

    set height(height) {
        this.setState({
            height: height,
            heightInitd: true
        });
        this.canvasRef.current.height = height - 37;
        this.canvasHeight = height - 18;
    }

    set width(width) {
        this.setState({
            width: width,
            heightInitd: true
        });

        this.canvasRef.current.width = width - 18;
        this.canvasWidth = width - 18;
    }
    componentDidUpdate(prevProps, prevState) {
        // if(prevState.width !== this.state.width) {
        //     const canvas = this.canvasRef.current;
        //     canvas.width = this.state.width - 18;
        //     this.canvasWidth = this.state.width - 18;
        // }
        // if(prevState.height !== this.state.height) {
        //     const canvas = this.canvasRef.current;
        //     canvas.height = this.state.height - 37; 
        //     this.canvasHeight = this.state.height - 37;
        // }
    }

    getOffsetTop(obj) {
        let tmp = obj.offsetTop;
        let val = obj.offsetParent;
        while (val != null) {
            tmp += val.offsetTop;
            val = val.offsetParent;
        }
        return tmp;
    }
    getOffsetLeft(obj) {
        let tmp = obj.offsetLeft;
        let val = obj.offsetParent;
        while (val != null) {
            tmp += val.offsetLeft;
            val = val.offsetParent;
        }
        return tmp;
    }



    componentWillUnmount() {

        // this.canvas.removeEventListener("mousewheel", this.canvasScaleEvent);
    }

    snapshot = [[], 0, 0, 0, 0, 0];

    drawCanvas = (paths, contentWidth, contentHeight, _scale, centerX, centerY) => {
        if (!paths) {
            paths = this.snapshot[0];
            contentWidth = this.snapshot[1];
            contentHeight = this.snapshot[2];
            _scale = this.snapshot[3];
            centerX = this.snapshot[4];
            centerY = this.snapshot[5];
        }
        centerX = centerX || 0;
        centerY = centerY || 0;
        this.canvas.height = this.canvasHeight;//重绘
        if (!contentWidth || !contentHeight) {
            return;
        }
        this.canvas.height = this.canvasHeight;//重绘
        if (!paths.length) {

            return;
        }
        let scale;
        let offsetX = 10;
        let offsetY = this.canvasHeight / 2 - contentHeight * (this.canvasWidth - 2 * offsetX) / contentWidth / 2;
        // if(!scale) {
        if (offsetY > 0) {
            scale = (this.canvasWidth - 2 * offsetX) / contentWidth;
        } else {
            offsetY = 50;
            offsetX = this.canvasWidth / 2 - contentWidth * (this.canvasHeight - offsetY) / contentHeight / 2;
            scale = (this.canvasHeight - 2 * offsetY) / contentHeight;
        }
        if (_scale) {
            scale = _scale;
        }
        offsetX += centerX;
        offsetY += centerY;
        this.snapshot[0] = paths;
        this.snapshot[1] = contentWidth;
        this.snapshot[2] = contentHeight;
        this.snapshot[3] = scale;
        this.snapshot[4] = centerX;
        this.snapshot[5] = centerY;
        this.ctx.strokeStyle = "#fff";
        // //console.log(this.canvasHeight);
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];
            this.ctx.beginPath();

            if (path.type === 'Circle') {
                // //console.log(scale, offsetX, this.canvasHeight)
                this.ctx.arc(path.params[0] * scale + offsetX, this.canvasHeight - path.params[1] * scale - offsetY, path.params[2] * scale, 0, 2 * Math.PI);
            } else if (path.type === 'Line') {
                this.ctx.moveTo(path.params[0] * scale + offsetX, this.canvasHeight - path.params[1] * scale - offsetY);
                this.ctx.lineTo(path.params[2] * scale + offsetX, this.canvasHeight - path.params[3] * scale - offsetY);
            } else if (path.type === 'Arc') {
                this.ctx.arc(
                    path.params[0] * scale + offsetX, this.canvasHeight - path.params[1] * scale - offsetY,
                    path.params[2] * scale,
                    (360 - path.params[3]) / 360 * 2 * Math.PI,
                    (360 - path.params[4]) / 360 * 2 * Math.PI,
                    true
                );
            }
            this.ctx.stroke();
        }
        this.ctx.closePath();
    }

    render() {
        return (
            <div className="cg-canvas-wrapper" style={{ height: this.state.height, display: this.state.height === 0 ? 'none' : 'block' }}>
                <Tabs /* tabBarExtraContent={(
                    <Button type="link" onClick={this.props.handleNest}>{locale['启动排版']}</Button>
                )} */>
                    <Tabs.TabPane tab={this.props.title} tabBar>
                        <canvas ref={this.canvasRef} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        )
    }

    steps = [];
    canvasScaleEvent = (e) => {
        let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        let step;
        if (delta > 0) {
            step = this.snapshot[3];
            this.steps.push(step);
        } else {
            if (this.steps.length > 0) {
                step = this.steps.pop();
            } else {
                step = 0;
            }
        }
        this.snapshot[3] += delta * step;
        const [centerX, centerY] = this.focus(e.clientX, e.clientY);
        this.snapshot[4] = centerX;
        this.snapshot[5] = centerY;
        // //console.log(this.focus(e.clientX, e.clientY));
        this.drawCanvas(...this.snapshot);
    }

    focus = (clientX, clientY) => {
        this.canvasOffsetTop = this.getOffsetTop(this.canvas);
        this.canvasOffsetLeft = this.getOffsetLeft(this.canvas);
        return [clientX - this.canvasOffsetLeft, clientY - this.canvasOffsetTop];
    }
} 