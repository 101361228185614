import { useContext, useEffect, useMemo, useState } from "react";
import { FloorPlannerContext } from "../../../context-reducer";
import { DrawedAreaMeta, m2px, useBrushPos, useDrawType } from "../../../store";

export const defaultRectAreaWidth = (4 + 0.24) * m2px;
export const defaultRectAreaHeight = (3 + 0.24) * m2px;


export const useDrawingArea = () => {

    const { state, dispatch } = useContext(FloorPlannerContext);
    const drawType = useDrawType(); 
    const [drawAreaType, setDrawAreaType] = useState<string>(null);

    useEffect(() => {
        if(drawType?.indexOf("area") === 0) {
            setDrawAreaType(drawType);
        }else {
            setDrawAreaType(null);
        }
    }, [drawType]);

    const pushDrawed = (drawed: DrawedAreaMeta) => {
        dispatch({
            type: "pushDrawed",
            payload: drawed
        })
    }

    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        })
    }
    const toStageCenter = () => {
        dispatch({
            type:"toStageCenter"
        });
    }

    return useMemo(() => {
        return {
            roomName: state.roomName,
            drawType: drawAreaType,
            // brushPos
            floorThickness:state.floorThickness,
            wallThickness: state.wallThickness,
            pushDrawed,
            cancelDraw,
            toStageCenter
        }
    }, [drawAreaType, state.wallThickness, state.roomName])

}