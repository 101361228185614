import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner2 } from "../../coners/corner2";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { rectBoard } from "../general-board";

export const diwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    corner: boolean,
    extraConfig?: any
): [Path[], Path[]] => {
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = 0;
    let cornerBLH: number = 0;
    if (cornerType === "youhujiao") {
        cornerBL = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    } else if (cornerType === "wujiao") {
        cornerBL = corner2WaiWujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    } else if (cornerType === "wuhujiao") {
        cornerBL = corner2WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    let cornerBRW: number = 0;
    let cornerBRH: number = 0;
    if (cornerType === "youhujiao") {
        cornerBR = corner2(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
    } else if (cornerType === "wujiao") {
        cornerBR = corner2WaiWujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
    } else if (cornerType === "wuhujiao") {
        cornerBR = corner2WaiWuhujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;

    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if (cornerType === "youhujiao") {
        cornerTR = corner2(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
    } else if (cornerType === "wujiao") {
        cornerTR = corner2WaiWujiao(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
    } else if (cornerType === "wuhujiao") {
        cornerTR = corner2WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;

    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = 0;
    let cornerTLH: number = 0;
    if (cornerType === "youhujiao") {
        cornerTL = corner2(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    } else if (cornerType === "wujiao") {
        cornerTL = corner2WaiWujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
    } else if (cornerType === "wuhujiao") {
        cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;

    }

    const boardConfig: any = {
        middleware: extraConfig?.noMiddleware ? null : {
            top: extraConfig?.背外 ? false : (width > 500),
            left: (height > 500),
            right: (height > 500),
            bottom: extraConfig?.背外 ? (width > 500 && "close") : false,
        }
    }
    let paths;
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}