import { useContext, useEffect, useState } from "react";
import { FloorPlannerContext } from "../../../context-reducer";
import { DrawWallType, DrawedAreaMeta, DrawedWallMeta } from "../../../store";

export const useWallDrawing = () => {

    const {  state, dispatch } = useContext(FloorPlannerContext);

    const [ drawWallType, setDrawWallType ] = useState<string>(null);
    const [ drawedWallList, setDrawedWallList ] = useState<DrawedWallMeta[]>([]);
    
    useEffect(() => {
        const drawedWallList = state.drawedList.filter((d) => d.type.indexOf("wall") === 0) as DrawedWallMeta[];
        setDrawedWallList(drawedWallList);
    }, [state.drawedList]);

    useEffect(() => {
        const drawWallType = state.drawType?.indexOf("wall") === 0 ? state.drawType : null;
        setDrawWallType(drawWallType);
    }, [state.drawType]);

    const pushDrawedArea = (drawed: DrawedAreaMeta) => {
        dispatch({
            type: "updateDrawedList",
            payload: [drawed]
        })
    }

    const pushDrawedWall = (drawed: DrawedWallMeta) => {
        dispatch({
            type: "pushDrawed",
            payload: drawed
        });
    }

    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        })
    }

    return {
        floorHeight: state.floorHeight,
        floorThickness: state.floorThickness,
        roomName: state.roomName,
        wallThickness: state.wallThickness,
        stageScale: state.stageScale,
        drawWallType: drawWallType as DrawWallType,
        brushPos: state.brushPos,
        drawedWallList,
        pushDrawedArea,
        pushDrawedWall,
        cancelDraw
    }
}
