import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner10 } from "../../coners/corner10";
import { rectBoard } from "../general-board";

// export const 
export const _02ceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner10(dx, dy, startX, startY, true, cornerBLSize);
    cornerBL = pathRotate(cornerBL, 90)
    cornerBL = pathMirrorY(cornerBL, startX);
    cornerBL = pathRotate(cornerBL, -90);
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner1(-dx, -dy, startX, startY,true,  cornerBRSize);
    cornerBR = pathMirrorY(cornerBR, startX);
    cornerBR = pathRotate(cornerBR, 90);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRSize.height);
    // cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.width);
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathRotate(cornerTR, 90);
    cornerTR = pathMirrorY(cornerTR, startX);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
    let cornerTRW: number = cornerTRSize.height;
    let cornerTRH: number = cornerTRSize.width;
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height)

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner10(dx, dy, startX, startY, true, cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    
    const boardConfig: any = {
        middleware: {
            top: width > 500 && true,
            left: false,
            right: height > 500 && true,
            bottom: width > 500 && true,
        }
    }
    //console.log(boardConfig);
    
    const paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);

    if(lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];
}