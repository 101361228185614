import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX } from "../../../util/graph";
import { _09ceneiBoardLeft } from "./09-cenei-left";

// export const 
export const _09ceneiBoardRight = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const [ boardPaths, cornerPaths ] = _09ceneiBoardLeft(dx, dy, startX, startY, width, height, lianjiekongbianju, qiandanghengfang, extraConfig);
    const combind = conbinePaths([boardPaths, cornerPaths]);
    let mirrorCombind = pathMirrorY(combind, startX);
    mirrorCombind = pathMoveX(mirrorCombind, DirectX.RIGHT, width);
    return [
        mirrorCombind.slice(0, boardPaths.length),
        mirrorCombind.slice(boardPaths.length, boardPaths.length + cornerPaths.length),
    ]
}