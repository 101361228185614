import { Circle } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { degreeTransformForEach } from "../../util";
import {  pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../util/graph";
import { corner12 } from "./corner12";


export const corner15 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner12(dx, dy, startX, startY, true, size);
    paths = paths.slice(0, paths.length - 11);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, size.width);
    paths = pathMoveY(paths, DirectY.DOWN, 43.49);
    if(typeof sizeRes === 'object') {
    //     if(!corner) {
    //     sizeRes.width = 0;
    //     sizeRes.height = 0;
    // }else {
            sizeRes.width = 77.488293;
            sizeRes.height = 34.000335;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);
}