import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner5Sanheyi } from "./corner5-sanheyi";

export const corner12Sanheyi = (_dx: DirectX, _dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }, special?: number): Path[] => {
    const size = {width: 0, height: 0};
    const dx = 1;
    const dy = 1;
    let paths: Path[] = corner5Sanheyi(dx, dy, startX, startY, true, size);
    paths = pathRotate2(paths, startX, startY, -90);
    // paths = pathRotate(paths, - dx * dy * 90);
    // paths = pathMoveX(paths, DirectX.RIGHT, dx * size.height);
    // paths = pathMoveY(paths, DirectY.DOWN, dy * (size.height - size.width));
    const width = size.height;
    const height = size.width;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
        paths = pathMoveY(paths, DirectY.UP, dy * height);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);
        paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
        paths = pathMoveX(paths, DirectX.RIGHT, dx * width);
        paths = pathMoveY(paths, DirectY.UP, dy * height);
    }
    if (typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = width;
            sizeRes.height = height;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}
