import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Dropdown, Form, Input, QRCode, theme } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleStore } from '../locale';

interface Props {
    logo: any;
    imageUrl: string;
    scanLogin?: {
        code: string;
        tip: string;
    };
    extra?: any;
    accountLogin?: {
        onLogin: (account: string, password: string) => Promise<boolean>;
    };
    onLoginSuccess?: () => void;
}

export default function CommonLoginPage2(props: Props) {

    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState<"login" | "success">("login");

    const { t } = useTranslation();
    const locale = useLocaleStore(state => state.locale);

    return (
        <div className="flex column" style={{ height: '100vh', width: '100vw' }}>
            <ConfigProvider theme={{ algorithm: [theme.darkAlgorithm] }}> <div
                className="header flex"
                style={{ height: 50, padding: '0 10px', alignItems: 'center', justifyContent: 'space-between', background: "#262f3e", color: "#f2f2f7" }}
            >
                <div className="flex" style={{ alignItems: 'center' }}>
                    <div
                        style={{ padding: '0 10px', fontSize: 18 }}
                        className=""
                    >
                        {props.logo}
                    </div>
                    <div style={{ width: 1, background: '#ddd', height: 20 }}></div>
                    <div style={{ padding: '0 10px', fontSize: 20 }}>{t("登录")}</div>
                </div>
                <div>
                    {props.extra}
                </div>
            </div>

            </ConfigProvider>

            <div
                className="flex center"
                style={{
                    flex: 1,
                    backgroundImage: `url(${props.imageUrl})`,
                    backgroundSize: '100% 100%',
                }}
            >
                <div
                    className="flex relative"
                    style={{ background: '#fff', padding: '50px 20px', borderRadius: 6 }}
                >
                    {
                        submitting && (
                            <div className='flex center column' style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, .5)', zIndex: 8 }}>
                                <LoadingOutlined spin style={{ fontSize: 48, color: 'gray' }} className="color-primary" />
                                {
                                    <div className='color-primary' style={{ fontSize: 16, marginTop: 10, height: 24, color: 'gray' }}>{status === 'login' && t('正在登录...')}</div>
                                }
                            </div>
                        )
                    }
                    {
                        status === 'success' && (
                            <div className='flex center column' style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, .95)', zIndex: 9 }}>
                                <CheckCircleFilled className="text-success" style={{ fontSize: 48, opacity: 0.9, color: "green" }} />
                                <div className='text-success' style={{ fontSize: 16, marginTop: 10, height: 24, position: 'relative', top: 0, color: "green" }}>{t("登陆成功! 正在跳转...")}</div>
                            </div>
                        )
                    }
                    {props.scanLogin && (
                        <div style={{ padding: '0 60px', borderRight: '1px solid lightgray' }} className="flex column center">
                            <div
                                style={{ fontWeight: 'bold', marginBottom: 20, fontSize: 18 }}
                            >
                                扫描二维码
                            </div>
                            <QRCode value={props.scanLogin?.code} size={180} />
                            <div style={{ textAlign: 'center', width: 180, marginTop: 24, fontSize: 16, color: "gray" }}>
                                {props.scanLogin.tip}
                            </div>
                        </div>
                    )}
                    {props.accountLogin && (
                        <div style={{ padding: '0 40px', width: 400 }}>
                            <div
                                style={{ fontWeight: 'bold', marginBottom: 20, fontSize: 18 }}
                            >
                                {t("账号登录")}
                            </div>
                            <Form
                                labelCol={{ span: locale === 'zh-CN'?4:6 }}
                                onFinish={async (data) => {
                                    if (!props.accountLogin) {
                                        return;
                                    }
                                    setSubmitting(true);
                                    let startTime = Date.now();
                                    try {
                                        const res: any = await props.accountLogin.onLogin(data.phoneNumber, data.password);
                                        console.log("onLogin res", res);

                                        if (res) {
                                            const deltaTime = Date.now() - startTime;
                                            let sleepTime = deltaTime < 500 ? 500 - deltaTime : 0;
                                            if (sleepTime > 0) {
                                                await new Promise((resolve) => {
                                                    setTimeout(resolve, sleepTime);
                                                });
                                            }
                                            if (props.onLoginSuccess) {
                                                setTimeout(props.onLoginSuccess, 800);
                                            }
                                            setStatus("success");
                                        }
                                        setSubmitting(false);
                                    } catch (e) {
                                        setSubmitting(false);
                                    }
                                }}
                                style={{ height: 'calc(100% - 54px)', minHeight: 200 }}
                                size="large"
                            >
                                <div className='flex column' style={{ height: '100%', justifyContent: 'space-between' }}>
                                    <div>
                                        <div style={{ height: 60 }}>
                                            <Form.Item
                                                name={'phoneNumber'}
                                                label={t("账号")}
                                                rules={[{ required: true, message: t('请输入账号') }]}
                                            >
                                                <Input
                                                    placeholder={t('请输入账号')}
                                                    autoComplete="off"
                                                />
                                            </Form.Item>

                                        </div>
                                        <Form.Item
                                            name={'password'}
                                            label={t("密码")}
                                            rules={[{ required: true, message: t('请输入密码') }]}
                                        >
                                            <Input placeholder={t('请输入密码')} type={'password'} />
                                        </Form.Item>

                                    </div>
                                    <div >
                                        <Form.Item >
                                            <Button
                                                style={{ width: '100%' }}
                                                htmlType="submit"
                                                type="primary"
                                                disabled={submitting}
                                                loading={submitting}
                                            >
                                                {t("登录")}
                                            </Button>
                                        </Form.Item>
                                        <div className='relative' style={{ top: 0, textAlign: 'center', color: 'gray', fontSize: 14, marginTop: 10 }}>{t("欢迎使用大圣橱柜设计&排板系统")}</div>

                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
