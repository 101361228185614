import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner8 } from "../../coners/corner8";
import { corner8WaiWuhujiao } from "../../coners/无护角/corner8-wai-wuhujiao";
import { corner8WaiWujiao } from "../../coners/无角/corner8-wai-wujiao";
import { rectBoard } from "../general-board";

export const menwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner?: boolean,
    menbashou?: boolean,
    nolashou?: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
 ): [Path[], Path[]]  => {
     let side = doorSide || "left";
    let lbc = true;
    let rbc = true;
    let rtc = true;
    let ltc = true;
    if(!nolashou) {
        if(gxinglashou) {
            if(gxinglashouType === "left-or-right") {
                if(side === "left") {
                    rbc = false;
                    rtc = false;
                }else {
                    lbc = false;
                    ltc = false;
                }
            }else {
                ltc = false;
                rtc = false;
            }
        }
    }
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;


    if(corner && lbc) {
        
        if(cornerType === "wujiao" ||  cornerType === "youhujiao") {
            cornerBL = corner8(dx, -dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
        }else if(cornerType === "wuhujiao") {
            //console.log("corner8WaiWuhujiao");
            cornerBL = corner8WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
            cornerBLW = cornerBLSize.width;
            cornerBLH = cornerBLSize.height;
            cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
        }
    } else {
        cornerBL = corner8WaiWujiao(dx, -dy, startX, startY, corner || false, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(corner && rbc) {
        if(cornerType === "wujiao" ||  cornerType === "youhujiao") {
            cornerBR = corner8(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        }else if(cornerType === "wuhujiao") {
            //console.log("corner8WaiWuhujiao");
            cornerBR = corner8WaiWuhujiao(-dx, -dy, startX, startY, corner, cornerBRSize);
            cornerBRW = cornerBRSize.width;
            cornerBRH = cornerBRSize.height;
            cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
            cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
        }
    }else {
        cornerBR = corner8WaiWujiao(-dx, -dy, startX, startY, corner || false, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(corner && rtc) {
        if(cornerType === "wujiao" ||  cornerType === "youhujiao") {
            cornerTR = corner8(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        }else if(cornerType === "wuhujiao") {
            cornerTR = corner8WaiWuhujiao(-dx, dy, startX, startY, corner, cornerTRSize);
            cornerTRW = cornerTRSize.width;
            cornerTRH = cornerTRSize.height;
            cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
            cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
        }
    }else {
        cornerTR = corner8WaiWujiao(-dx, dy, startX, startY, corner || false, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }

    //右上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(corner && ltc) {
        if(cornerType === "wujiao" ||  cornerType === "youhujiao") {
            cornerTL = corner8(dx, dy, startX, startY, corner, cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
        }else if(cornerType === "wuhujiao") {
            cornerTL = corner8WaiWuhujiao(dx, dy, startX, startY, corner, cornerTLSize);
            cornerTLW = cornerTLSize.width;
            cornerTLH = cornerTLSize.height;
            cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

        }
    }else {
        cornerTL = corner8WaiWujiao(dx, dy, startX, startY, corner || false, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }

    const centerX = (2 * startX + width) / 2;
    const _startX = centerX - dx * 64.75 - dx * 1.5;
    const _startY = startY + dy * height - dy * 129.299982;
    
    let paths;
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0);
    }
    let corners: Path[] = [];
    if(!nolashou) {

        if((menbashou || !menminglashou) && !gxinglashou) {

            paths.push({
                type: Arc,
                params: [
                    _startX, _startY,
                    1.5,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5, _startY,
                    _startX - dx * 1.5, _startY + dy * 46.3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3, _startY + dy * 46.3,
                    3,
                    90, 180,
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3, _startY + dy * 46.3 + dy * 3,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5, _startY + dy * 46.3 + dy * 3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5, _startY + dy * 46.3,
                    3,
                    0, 90
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3, _startY + dy * 46.3,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3, _startY + dy * 46.3 - dy * 46.3,
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5, _startY + dy * 46.3 - dy * 46.3,
                    1.5,
                    180, 360
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5, _startY + dy * 46.3 - dy * 46.3,
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3,        
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3,        
                    0.5,
                    0, 90
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5,        
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5,        
                ]
            }, {
                type: Arc,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5 - dy * 0.5,        
                    0.5,
                    90, 180
                ]
            }, {
                type: Line,
                params: [
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5 - dy * 0.5,        
                    _startX - dx * 1.5 + dx * 3 + dx * 129.5 + dx * 3 - dx * 1.5 - dx * 1.5 - dx * 0.5 - dx * 128.5 - dx * 0.5, _startY + dy * 46.3 - dy * 46.3 + dy * 45.3 + dy * 0.5 - dy * 0.5 - dy * 45.3,        
                ]
            });
        
        }

        
    if(menminglashou) {
        menlashouX = menlashouX as number;
        menlashouY = menlashouY as number;
        menlashoubianju = menlashoubianju as number;
        if(doorSide === "right") {
            corners.push({
                type: Circle,
                params: [
                    startX + dx * (menlashouX + 23.5), startY + dy * height - dy * (menlashouY + 23.5),
                    2.1
                ]
            });
            if(menlashoubianju > 0) {
                
                if(menlashoufangxiang === "horizontal") {
                    corners.push({
                        type: Circle,
                        params: [
                            startX + dx * (menlashouX + 23.5 + menlashoubianju),  startY + dy * height - dy * (menlashouY + 23.5),
                            2.1
                        ]
                    })
                }else {
                    corners.push({
                        type: Circle,
                        params: [
                            startX + dx * (menlashouX + 23.5),  startY + dy * height - dy * (menlashouY + 23.5 + menlashoubianju),
                            2.1
                        ]
                    })
                }
            }
        }else {
            corners.push({
                type: Circle,
                params: [
                    startX + dx * width - dx * (menlashouX + 23.5), startY + dy * height - dy * (menlashouY + 23.5),
                    2.1
                ]
            });
            if(menlashoubianju > 0) {
                
                if(menlashoufangxiang === "horizontal") {
                    corners.push({
                        type: Circle,
                        params: [
                            startX + dx * width - dx * (menlashouX + 23.5 + menlashoubianju),  startY + dy * height - dy * (menlashouY + 23.5),
                            2.1
                        ]
                    })
                }else {
                    corners.push({
                        type: Circle,
                        params: [
                            startX + dx * width - dx * (menlashouX + 23.5),  startY + dy * height - dy * (menlashouY + 23.5 + menlashoubianju),
                            2.1
                        ]
                    })
                }
            }
        }
        
    }
    }


    return [
        paths,        
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        corners.concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}