import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { degreeTransformForEach } from "../../../util";
import { pathMoveY } from "../../../util/graph";
import { corner2WaiWuhujiao } from "./corner2-wai-wuhujiao";

export const corner13WaiWuhujiao = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner2WaiWuhujiao(dx, -dy, startX, startY, true, size);
    paths = pathMoveY(paths, DirectY.UP, dy * size.height);
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        if(path.type === Circle) {
            path.params[0] = startX + dx * 15.385;
        }
    }
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = size.width;
            sizeRes.height = size.height;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);;
}
