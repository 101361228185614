import { AbstractMeta, mm2RealValue, px2DisplayValue } from "./base";

export const areaRect = new AbstractMeta<{}>("矩形墙属性", [
    {
        key: "height",
        label: "高度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "width",
        label: "宽度",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    },
    {
        key: "wallThickness",
        label: "墙厚",
        defaultValue: 0,
        valueType: "integer",
        suffix: "mm",
        toDisplayValue: px2DisplayValue,
        toRealValue: mm2RealValue,
        rule: [
            {
                key: "min",
                value: 0
            }
        ]
    }

]);