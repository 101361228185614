import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner3 } from "../../coners/corner3";
import { corner4 } from "../../coners/corner4";
import { rectBoard } from "../general-board";

export const _02dineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL = corner3(dx, dy, startX, startY, true, cornerBLSize);
    cornerBL = pathRotate(cornerBL, 90);
    cornerBL = pathMoveX(cornerBL, DirectX.LEFT, cornerBLSize.width);
    cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.width);
    cornerBL = pathMirrorY(cornerBL, startX);
    let cornerBLW: number = cornerBLSize.height;
    let cornerBLH: number = cornerBLSize.width;

    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner3(dx, dy, startX, startY, true, cornerBRSize);
    cornerBR = pathRotate(cornerBR, 90);
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBLSize.width);
    cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.width);
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;

    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner4(dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathRotate(cornerTR, 90);
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;

    //左上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner4(dx, dy, startX, startY, true, cornerTLSize);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    cornerTL = pathRotate(cornerTL, 90);
    cornerTL = pathMirrorY(cornerTL, startX);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    
    const boardConfig: any = {
        middleware: {
            top: width > 500 && true,
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: false,
        }
    }
    const paths = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];

}