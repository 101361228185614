import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { _03gongyongceneiBoard } from "./03-gongyongcenei";
import { rectBoard } from "../general-board";

export const _04gongyongceneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,

    jiaolianbianju: number,
    nojiaoliankong: boolean,
    
    geceng?: boolean,
    gecengX?: number,
    gecengY?: number,
    gecengkongjing?: number,


    gxinglashou?: boolean,
    gxinglashouType?: "up" | "left-or-right",
    gxinglashouGaodu?: number,
    

    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _03gongyongceneiBoard(dx, dy, startX, startY, width, height, jiaolianbianju, nojiaoliankong, geceng, gecengX, gecengY, gecengkongjing, gxinglashou, gxinglashouType, gxinglashouGaodu, qiandanghengfang, extraConfig);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]

 }