import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner14 } from "../../coners/corner14";
import { corner14WaiWuhujiao } from "../../coners/无护角/corner14-wai-wuhujiao";
import { corner14WaiWujiao } from "../../coners/无角/corner14-wai-wujiao";
import { rectBoard } from "../general-board";

export const _02AbeiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    diaokongbianju: number,
    corner: boolean,
    extraConfig: any
): [Path[], Path[]] => {
    const { 吊码 } = extraConfig;
    if(吊码) {
        diaokongbianju = -1;
    }
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = cornerBLSize.height;
    let cornerBLH: number = cornerBLSize.width;
    // = corner14(dx, dy, startX, startY, true, cornerBLSize);
    if (cornerType === "youhujiao") {
        cornerBL = corner14(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;
        cornerBL = pathRotate(cornerBL, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.LEFT, cornerBLSize.width);
        cornerBL = pathMoveY(cornerBL, DirectY.DOWN, cornerBLSize.height - cornerBLSize.width);
        // if(!corner) {
        //     cornerBL = cornerBL.filter((c) => c.type === Circle);
        // }
    } else if (cornerType === "wujiao") {
        cornerBL = corner14WaiWujiao(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;
        cornerBL = pathRotate(cornerBL, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.LEFT, cornerBLSize.width);
        cornerBL = pathMoveY(cornerBL, DirectY.DOWN, cornerBLSize.height - cornerBLSize.width);
        // if(!corner) {
        //     cornerBL = cornerBL.filter((c) => c.type === Circle);
        // }
    } else if (cornerType === "wuhujiao") {
        cornerBL = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.height;
        cornerBLH = cornerBLSize.width;
        cornerBL = pathRotate(cornerBL, 90);
        cornerBL = pathMoveX(cornerBL, DirectX.LEFT, cornerBLSize.width);
        cornerBL = pathMoveY(cornerBL, DirectY.DOWN, cornerBLSize.height - cornerBLSize.width);
        // if(!corner) {
        //     cornerBL = cornerBL.filter((c) => c.type === Circle);
        // }
    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];// = 
    let cornerBRW: number = cornerBRSize.height;
    let cornerBRH: number = cornerBRSize.width;

    if (cornerType === "youhujiao") {
        cornerBR = corner14(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.height;
        cornerBRH = cornerBRSize.width;
        cornerBR = pathRotate(cornerBR, 90);
        cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width + cornerBRSize.width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBLSize.height - cornerBLSize.width);
    } else if (cornerType === "wujiao") {
        cornerBR = corner14WaiWujiao(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.height;
        cornerBRH = cornerBRSize.width;
        cornerBR = pathRotate(cornerBR, 90);
        cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width + cornerBRSize.width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBLSize.height - cornerBLSize.width);
    } else if (cornerType === "wuhujiao") {
        cornerBR = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.height;
        cornerBRH = cornerBRSize.width;
        cornerBR = pathRotate(cornerBR, 90);
        cornerBR = pathMirrorY(cornerBR, startX);
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width + cornerBRSize.width);
        cornerBR = pathMoveY(cornerBR, DirectY.DOWN, cornerBLSize.height - cornerBLSize.width);

    }
    // if(!corner) {
    //     cornerBR = cornerBR.filter((c) => c.type === Circle);
    // }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.height;
    let cornerTRH: number = cornerTRSize.width;
    if (cornerType === "youhujiao") {
        cornerTR = corner14(dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathRotate(cornerTR, -90);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - 2 * cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, cornerTRSize.height - cornerTRSize.width);
    } else if (cornerType === "wujiao") {
        cornerTR = corner14WaiWujiao(dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathRotate(cornerTR, -90);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - 2 * cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, cornerTRSize.height - cornerTRSize.width);
    } else if (cornerType === "wuhujiao") {
        cornerTR = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.height;
        cornerTRH = cornerTRSize.width;
        cornerTR = pathRotate(cornerTR, -90);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - 2 * cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.width);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, cornerTRSize.height - cornerTRSize.width);

    }
    // if(!corner) {
    //     cornerTR = cornerTR.filter((c) => c.type === Circle);
    // }

    //左上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.height;
    let cornerTLH: number = cornerTLSize.width;
    if (cornerType === "youhujiao") {
        cornerTL = corner14(dx, dy, startX, startY, true, cornerTLSize);
        cornerTLW = cornerTLSize.height;
        cornerTLH = cornerTLSize.width;
        cornerTL = pathMirrorY(cornerTL, startX);
        cornerTL = pathRotate(cornerTL, 90);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - 2 * cornerTLSize.height);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.width);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, cornerTLSize.height - cornerTLSize.width);
    } else if (cornerType === "wujiao") {
        cornerTL = corner14WaiWujiao(dx, dy, startX, startY, true, cornerTLSize);
        cornerTLW = cornerTLSize.height;
        cornerTLH = cornerTLSize.width;
        cornerTL = pathMirrorY(cornerTL, startX);
        cornerTL = pathRotate(cornerTL, 90);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - 2 * cornerTLSize.height);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.width);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, cornerTLSize.height - cornerTLSize.width);
    } else if (cornerType === "wuhujiao") {
        cornerTL = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerTLSize);
        cornerTLW = cornerTLSize.height;
        cornerTLH = cornerTLSize.width;
        cornerTL = pathMirrorY(cornerTL, startX);
        cornerTL = pathRotate(cornerTL, 90);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - 2 * cornerTLSize.height);
        cornerTL = pathMoveX(cornerTL, DirectX.RIGHT, cornerTLSize.width);
        cornerTL = pathMoveY(cornerTL, DirectY.UP, cornerTLSize.height - cornerTLSize.width);

    }

    const boardConfig: any = {
        middleware: extraConfig?.noMiddleware ? {} : {
            top: width > 500 && "close",
            left: height > 500 && "close",
            right: height > 500 && "close",
            bottom: width > 500 && "close",
        }
    }
    let paths;
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    if (diaokongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 24), startY + dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 24), startY + dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (diaokongbianju + 24), startY + dy * height - dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (diaokongbianju + 24), startY + dy * height - dy * (diaokongbianju + 24),
                extraConfig.吊孔孔径 / 2 || 2.8
            ]
        });
    }


    if (吊码) {
        paths.push(
            {
                type: Line,
                params: [
                    startX + dx * 41.5, startY + dy * height - dy * 78.5,
                    startX + dx * 41.5 + dx * 22, startY + dy * height -  dy * 78.5
                ]
            }, {
            type: Line,
            params: [
                startX + dx * 41.5 + dx * 22, startY + dy * height -  dy * 78.5,
                startX + dx * 41.5 + dx * 22, startY + dy * height - (dy * 78.5 - dy * 19)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * 41.5 + dx * 22, startY + dy * height - (dy * 78.5 - dy * 19),
                startX + dx * 41.5, startY + dy * height - (dy * 78.5 - dy * 19),

            ]
        }, {
            type: Line,
            params: [
                startX + dx * 41.5, startY + dy * height - (dy * 78.5 - dy * 19),
                startX + dx * 41.5, startY + dy * height - dy * 78.5
            ]
        },

            {
                type: Line,
                params: [
                    startX + dx * width - dx * 41.5, startY + dy * height - dy * 78.5,
                    startX + dx * width - (dx * 41.5 + dx * 22), startY + dy * height - dy * 78.5
                ]
            }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 41.5 + dx * 22), startY + dy * height - dy * 78.5,
                startX + dx * width - (dx * 41.5 + dx * 22), startY + dy * height - (dy * 78.5 - dy * 19)
            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - (dx * 41.5 + dx * 22), startY + dy * height - (dy * 78.5 - dy * 19),
                startX + dx * width - dx * 41.5, startY + dy * height - (dy * 78.5 - dy * 19),

            ]
        }, {
            type: Line,
            params: [
                startX + dx * width - dx * 41.5, startY + dy * height - (dy * 78.5 - dy * 19),
                startX + dx * width - dx * 41.5, startY + dy * height - dy * 78.5
            ]
        }
        )
    }

    return [
        paths,
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL)
    ]
}