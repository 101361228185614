import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths } from "../../../util/graph";
import { dineiBoard } from "../地柜/01-dinei";

export const _03beineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number
 ): [Path[], Path[]]  => {

    const [dineiBoardPaths, dineiBoardCorners] = dineiBoard(dx, dy, startX, startY, width, height, 0, 0, {
        禁止长度增加自动加孔: true,
        背内: true,
    });
    const paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]).filter((path) => path.type !== Circle);

    const corners: Path[] = [{
        type: Circle,
        params: [
            startX + dx * (a + 2 - 9), startY + dy * height - dy * 32.028293,
            2.61
        ],
    }, {
        type: Circle,
        params: [
            startX + dx * (a + 2 - 9), startY + dy * height - dy * 32.028293 - dy * 31.5,
            2.61
        ]
    }, 
    //======================
    {
        type: Circle,
        params: [
            startX + dx * (a + 2 - 9), startY + dy * 32.028293,
            2.61
        ]
    }, {
        type: Circle,
        params: [
            startX + dx * (a + 2 - 9), startY + dy * 32.028293 + dy * 31.5,
            2.61
        ]
    }
]

    return [
        paths.slice(0, dineiBoardPaths.length),
        corners.concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]

 }