import { Arc, Circle, Line } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { degreeTransformForEach, numberEqual } from "../../util";
import { pathMoveX, pathMoveY, pathRotate } from "../../util/graph";
import { corner12 } from "./corner12";


export const corner16 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: { width: number, height: number }): Path[] => {
    const size = {width: 0, height: 0};
    let paths: Path[] = corner12(dx, dy, startX, startY, true, size);
    for (let i = 0; i < paths.length; i++) {
        
        const path = paths[i];
        // if(path.type === Circle) {
        //     //console.log(path.params[1], startY + dy * size.height - dy * 32.385);
        // }
        if(path.type === Circle) {
            if(numberEqual(startY + dy * size.height - dy * 32.385, path.params[1])) {
                path.params[1] = startY + dy * size.height - dy * 15.385;
            }
        }
    }
    // //console.log(size.width, size.height);
    if (typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
        sizeRes.width = size.width;
        sizeRes.height = size.height;
        // }
    }
    return degreeTransformForEach(paths, dx, dy).filter((item) => corner ? true : item.type === Circle);;
}
