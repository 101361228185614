import { _10beineiBoard } from "../../boards/拉篮柜/10-beinei";
import { _10beiwaiBoard } from "../../boards/拉篮柜/10-beiwai";
import { _10ceneiBoardLeft } from "../../boards/拉篮柜/10-cenei-left";
import { _10ceneiBoardRight } from "../../boards/拉篮柜/10-cenei-right";
import { _10cewaiBoardLeft } from "../../boards/拉篮柜/10-cewai-left";
import { _10cewaiBoardRight } from "../../boards/拉篮柜/10-cewai-right";
import { _10dineiBoard } from "../../boards/拉篮柜/10-dinei";
import { _10diwaiBoard } from "../../boards/拉篮柜/10-diwai";
import { _10gecengneiBoard } from "../../boards/拉篮柜/10-gecengnei ";
import { _10gecengwaiBoard } from "../../boards/拉篮柜/10-gecengwai";
import { _10menneiBoard } from "../../boards/拉篮柜/10-mennei";
import { _10menwaiBoard } from "../../boards/拉篮柜/10-menwai";
import { _10qiandangneiBoard } from "../../boards/拉篮柜/10-qiandangnei";
import { _10qiandangwaiBoard } from "../../boards/拉篮柜/10-qiandangwai";
import { BaseCupboard } from "../@base/BaseCupboard";

export class LalanguiCebaobei extends BaseCupboard {
    key: string = "LalanguiCebaobei";
    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            beineiBoard: _10beineiBoard,
            beiwaiBoard: _10beiwaiBoard,
            ceneiBoardLeft: _10ceneiBoardLeft,
            ceneiBoardRight: _10ceneiBoardRight,
            cewaiBoardLeft: _10cewaiBoardLeft,
            cewaiBoardRight: _10cewaiBoardRight,
            dineiBoard: _10dineiBoard,
            diwaiBoard: _10diwaiBoard,
            menneiBoard: _10menneiBoard,
            menwaiBoard: _10menwaiBoard,
            // "menwaiBoard-left": _10menwaiBoard,
            // "menneiBoard-left": _10menneiBoard,
            // "menwaiBoard-right": _10menwaiBoard,
            // "menneiBoard-right": _10menneiBoard,
            qiandangneiBoard: _10qiandangneiBoard,
            qiandangwaiBoard: _10qiandangwaiBoard,
            gecengneiBoard: _10gecengneiBoard,
            gecengwaiBoard: _10gecengwaiBoard,
        }
    }
    // setTypeMap(): { [boardName: string]: BoardTypeKey; } {
       
    // }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            "diwaiBoard": {
                size: [long + 47, deep + 47],
            },
            "dineiBoard": {
                size: [long + 21, deep + 21],
            },
            "cewaiBoardLeft": {
                size: [deep + 47, height + 29],
            },
            "cewaiBoardRight": {
                size: [deep + 47, height + 29],
            },
            "ceneiBoardLeft": {
                size: [deep + 21, height + 3],
            },
            "ceneiBoardRight": {
                size: [deep + 21, height + 3],
            },
            "beiwaiBoard": {
                size: [long + 11, height + 29]
            },
            "beineiBoard": {
                size: [long - 15, height + 3]
            },
            "qiandangwaiBoard": {
                size: [long + 11, 74 + 47],
                // //rotate90: true,
            },
            "qiandangneiBoard": {
                size: [long - 44, 74 + 21],
                // //rotate90: true,
            }
        };
        // if(extraConfig.doorNumber === 2) {
            
        //     list["menneiBoard-left"] = {
        //         size: [/* long / 2 - 2 + 21 */long + 21, /* height + 21 - 40 */extraConfig.mengao / 2 + 19],
        //         quantity: 1
        //     };
        //     list["menwaiBoard-left"] = {
        //         size: [/* long / 2 - 2 + 47 */long + 45, /* height + 47 - 40 */extraConfig.mengao / 2 + 45],
        //         quantity: 1,
        //     }
        //     list["menneiBoard-right"] = {
        //         size: [/* long / 2 - 2 + 21 */long + 21, /* height + 21 - 40 */extraConfig.mengao / 2 + 19],
        //         quantity: 1
        //     };
        //     list["menwaiBoard-right"] = {
        //         size: [/* long / 2 - 2 + 47 */long + 45, /* height + 47 - 40 */extraConfig.mengao / 2 + 45],
        //         quantity: 1,
        //     }
        // }else {
            
            let menQuantity = 0;
            if(extraConfig.y1) {
                menQuantity ++;
            }
            if(extraConfig.y2) {
                menQuantity ++;
            }
            if(extraConfig.y3) {
                menQuantity ++;
            }
            if(menQuantity) {
                let wReduce = 0;
                let hReduce = 0;
                if(extraConfig.gxinglashou) {
                    if(extraConfig.gxinglashouType === "up") {
                        hReduce = 23.5 + extraConfig.gxinglashouGaodu
                    }else {
                        wReduce = 23.5 + extraConfig.gxinglashouGaodu;
                    }
                }
                list["menneiBoard"] = {
                    size: [/* long / 2 - 2 + 21 */long + 21 - wReduce, /* height + 21 - 40 */extraConfig.mengao / 2 + 19 - hReduce],
                    quantity: menQuantity,
                };
                list["menwaiBoard"] = {
                    size: [/* long / 2 - 2 + 21 */long + 47 - wReduce, /* height + 21 - 40 */extraConfig.mengao / 2 + 45 - hReduce],
                    quantity: menQuantity,
                };
               
            }else {
                delete list['menneiBoard']
                delete list['menwaiBoard']
            }
        // }
        
        if(extraConfig.geceng) {
            list['gecengwaiBoard'] = {
                size: [long + 11, deep - extraConfig.gecengX + 29],
            };
            list['gecengneiBoard'] = {
                size: [long - 15, deep - extraConfig.gecengX + 3]
            };
        }
        if(extraConfig.nobeinei) {
            delete list["beineiBoard"];
        }
        if(extraConfig.nocenei) {
            delete list['ceneiBoardLeft'];
            delete list['ceneiBoardRight'];
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        //console.log(extraConfig.qiandanghengfang);
        
        let temp;
        //==============not common==================/
        if (board.name === 'dineiBoard') {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.guituiX, extraConfig.guituiY, extraConfig);
        } else if(board.name.indexOf("cewaiBoard") === 0) {
            if(extraConfig.doorNumber === 2) {
                temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.a, extraConfig.x, extraConfig.y1, extraConfig.y2, extraConfig.y3, extraConfig.qiandanghengfang, {
                    //from 2022!!! 都在一个对象里
                    //可选， 上、中免拉手X、Y、Z
                    上免拉手: Boolean(extraConfig.上免拉手),
                    上免拉手X: Number(extraConfig.上免拉手X),
                    上免拉手Y: Number(extraConfig.上免拉手Y),
                    中免拉手1: Boolean(extraConfig.中免拉手1),
                    中免拉手1X: Number(extraConfig.中免拉手1X),
                    中免拉手1Y: Number(extraConfig.中免拉手1Y),
                    中免拉手1Z: Number(extraConfig.中免拉手1Z),
                    中免拉手2: Boolean(extraConfig.中免拉手2),
                    中免拉手2X: Number(extraConfig.中免拉手2X),
                    中免拉手2Y: Number(extraConfig.中免拉手2Y),
                    中免拉手2Z: Number(extraConfig.中免拉手2Z),
                    中免拉手3: Boolean(extraConfig.中免拉手3),
                    中免拉手3X: Number(extraConfig.中免拉手3X),
                    中免拉手3Y: Number(extraConfig.中免拉手3Y),
                    中免拉手3Z: Number(extraConfig.中免拉手3Z),
                    无侧板排孔: Boolean(extraConfig.无侧板排孔),
                    
                });                    
            }else {
                if(board.name.toLowerCase().includes(extraConfig.doorSide)) {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY, extraConfig.gecengkongjing, extraConfig.a, extraConfig.x, extraConfig.y1, extraConfig.y2, extraConfig.y3, extraConfig.qiandanghengfang, {
                        //from 2022!!! 都在一个对象里
                        //可选， 上、中免拉手X、Y、Z
                        上免拉手: Boolean(extraConfig.上免拉手),
                        上免拉手X: Number(extraConfig.上免拉手X),
                        上免拉手Y: Number(extraConfig.上免拉手Y),
                        中免拉手1: Boolean(extraConfig.中免拉手1),
                        中免拉手1X: Number(extraConfig.中免拉手1X),
                        中免拉手1Y: Number(extraConfig.中免拉手1Y),
                        中免拉手1Z: Number(extraConfig.中免拉手1Z),
                        中免拉手2: Boolean(extraConfig.中免拉手2),
                        中免拉手2X: Number(extraConfig.中免拉手2X),
                        中免拉手2Y: Number(extraConfig.中免拉手2Y),
                        中免拉手2Z: Number(extraConfig.中免拉手2Z),
                        中免拉手3: Boolean(extraConfig.中免拉手3),
                        中免拉手3X: Number(extraConfig.中免拉手3X),
                        中免拉手3Y: Number(extraConfig.中免拉手3Y),
                        中免拉手3Z: Number(extraConfig.中免拉手3Z),
                        无侧板排孔: Boolean(extraConfig.无侧板排孔),
                    });
                }else {
                    temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, -1, extraConfig.lianjiekongbianju, extraConfig.waibanjiao, extraConfig.geceng, extraConfig.gecengX, extraConfig.gecengY,  extraConfig.gecengkongjing, extraConfig.a, extraConfig.x, extraConfig.y1, extraConfig.y2, extraConfig.y3, extraConfig.qiandanghengfang, {
                        //from 2022!!! 都在一个对象里
                        //可选， 上、中免拉手X、Y、Z
                        上免拉手: Boolean(extraConfig.上免拉手),
                        上免拉手X: Number(extraConfig.上免拉手X),
                        上免拉手Y: Number(extraConfig.上免拉手Y),
                        中免拉手1: Boolean(extraConfig.中免拉手1),
                        中免拉手1X: Number(extraConfig.中免拉手1X),
                        中免拉手1Y: Number(extraConfig.中免拉手1Y),
                        中免拉手1Z: Number(extraConfig.中免拉手1Z),
                        中免拉手2: Boolean(extraConfig.中免拉手2),
                        中免拉手2X: Number(extraConfig.中免拉手2X),
                        中免拉手2Y: Number(extraConfig.中免拉手2Y),
                        中免拉手2Z: Number(extraConfig.中免拉手2Z),
                        中免拉手3: Boolean(extraConfig.中免拉手3),
                        中免拉手3X: Number(extraConfig.中免拉手3X),
                        中免拉手3Y: Number(extraConfig.中免拉手3Y),
                        中免拉手3Z: Number(extraConfig.中免拉手3Z),
                        无侧板排孔: Boolean(extraConfig.无侧板排孔),
                    });
                }
            }
        } else if(board.name.indexOf("ceneiBoard") === 0) {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.lianjiekongbianju, extraConfig.qiandanghengfang, {
                //from 2022!!! 都在一个对象里
                //可选， 上、中免拉手X、Y、Z
                上免拉手: Boolean(extraConfig.上免拉手),
                上免拉手X: Number(extraConfig.上免拉手X),
                上免拉手Y: Number(extraConfig.上免拉手Y),
                中免拉手1: Boolean(extraConfig.中免拉手1),
                中免拉手1X: Number(extraConfig.中免拉手1X),
                中免拉手1Y: Number(extraConfig.中免拉手1Y),
                中免拉手1Z: Number(extraConfig.中免拉手1Z),
                中免拉手2: Boolean(extraConfig.中免拉手2),
                中免拉手2X: Number(extraConfig.中免拉手2X),
                中免拉手2Y: Number(extraConfig.中免拉手2Y),
                中免拉手2Z: Number(extraConfig.中免拉手2Z),
                中免拉手3: Boolean(extraConfig.中免拉手3),
                中免拉手3X: Number(extraConfig.中免拉手3X),
                中免拉手3Y: Number(extraConfig.中免拉手3Y),
                中免拉手3Z: Number(extraConfig.中免拉手3Z),
                无侧板排孔: Boolean(extraConfig.无侧板排孔),
            });
        } else if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu, /* 后面的不是门内板的公共参数 */extraConfig.paikong, extraConfig.paikongX, extraConfig.paikongY, extraConfig.paikongWidth, extraConfig.paikongHeight)
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }

}