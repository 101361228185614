// import {  } from "dxf-writer";
import { Arc, Line, Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner7 } from "../../coners/corner7";
import { rectBoard } from "../general-board";

export const innerBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    extraConfig?: {
        lashouConfig: any,
        jiaolianConfig: any,
        qiejiaoConfig: any,
        isDoor?: boolean
    }
): [Path[], Path[]] => {

    let lbc = true;/* left bottom corner */
    let rbc = true;
    let rtc = true;
    let ltc = true;

    //切角
    if (extraConfig?.qiejiaoConfig) {
        const { 切角位置, 切角宽, 切角高 } = extraConfig.qiejiaoConfig;
        if (切角位置 === 'rb') {
            rbc = false;
        } else if (切角位置 === 'rt') {
            rtc = false;
        } else if (切角位置 === 'lb') {
            lbc = false;
        } else if (切角位置 === 'lt') {
            ltc = false;
        }
    }

    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW = 0;
    let cornerBLH = 0;
    if (lbc) {
        cornerBL = corner7(dx, -dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];;
    let cornerBRW = 0;
    let cornerBRH = 0;
    if (rbc) {
        cornerBR = corner7(-dx, -dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }

    //右上角
    const cornerTRSize = { width: 0, height: 0 };
    let cornerTR: Path[] = [];
    let cornerTRW = 0;
    let cornerTRH = 0;
    if (rtc) {
        cornerTR = corner7(-dx, dy, startX, startY, true, cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }

    //右上角
    const cornerTLSize = { width: 0, height: 0 };
    let cornerTL: Path[] = [];
    let cornerTLW = 0;
    let cornerTLH = 0;
    if (ltc) {
        cornerTL = corner7(dx, dy, startX, startY, true, cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }

    //切角
    if (extraConfig?.qiejiaoConfig) {
        const { 切角位置, 切角宽, 切角高 } = extraConfig.qiejiaoConfig;
        if (切角位置 === 'lb') {
            cornerBLW = 切角宽 + 10.5;
            cornerBLH = 切角高 + 10.5;
        } else if (切角位置 === 'rb') {
            cornerBRW = 切角宽 + 10.5;
            cornerBRH = 切角高 + 10.5;
        } else if (切角位置 === 'rt') {
            cornerTRW = 切角宽 + 10.5;
            cornerTRH = 切角高 + 10.5;
        } else if (切角位置 === 'lt') {
            cornerTLW = 切角宽 + 10.5;
            cornerTLH = 切角高 + 10.5;
        }
    }

    const paths = rectBoard("inner", dx, dy, startX, startY, width, height,
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH);

    //切角
    if (extraConfig?.qiejiaoConfig) {
        const { 切角位置, 切角宽, 切角高, 切角位置X, 切角位置Y } = extraConfig.qiejiaoConfig;
        //console.log(切角位置, 切角宽, 切角高, 切角位置X, 切角位置Y);
        if (切角位置 === 'lb') {
            paths.push({
                type: "Line",
                params: [
                    startX, startY + dy * cornerBLH,
                    startX + dx * cornerBLW, startY + dy * cornerBLH
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * cornerBLW, startY + dy * cornerBLH,
                    startX + dx * cornerBLW, startY
                ]
            });
        } else if (切角位置 === 'rb') {
            paths.push({
                type: "Line",
                params: [
                    startX + dx * width, startY + dy * cornerBRH,
                    startX + dx * width - dx * cornerBRW, startY + dy * cornerBRH
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * width - dx * cornerBRW, startY + dy * cornerBRH,
                    startX + dx * width - dx * cornerBRW, startY
                ]
            });
        } else if (切角位置 === 'rt') {
            paths.push({
                type: "Line",
                params: [
                    startX + dx * width, startY + dy * height - dy * cornerTRH,
                    startX + dx * width - dx * cornerTRW, startY + dy * height - dy * cornerTRH,
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * width - dx * cornerTRW, startY + dy * height - dy * cornerTRH,
                    startX + dx * width - dx * cornerTRW, startY + dy * height
                ]
            });
        } else if (切角位置 === 'lt') {
            paths.push({
                type: "Line",
                params: [
                    startX, startY + dy * height - dy * cornerTLH,
                    startX + dx * cornerTLW, startY + dy * height - dy * cornerTLH,
                ]
            }, {
                type: "Line",
                params: [
                    startX + dx * cornerTLW, startY + dy * height - dy * cornerTLH,
                    startX + dx * cornerTLW, startY + dy * height
                ]
            });
        } else if (切角位置 === 'center') {
            const sx = (startX + startX + dx * width) / 2 - 切角宽 / 2;
            const sy = (startY + startY + dy * height) / 2 - 切角高 / 2;

            paths.push({
                type: "Line",
                params: [
                    sx, sy,
                    sx + dx * 切角宽, sy
                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy,
                    sx + dx * 切角宽, sy + 切角高

                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy + 切角高,
                    sx, sy + 切角高
                ]
            }, {
                type: "Line",
                params: [
                    sx, sy + 切角高,
                    sx, sy
                ]
            });
        } else if (切角位置 === 'xy') {
            const sx = startX + dx * (切角位置X + 10.5);
            const sy = startY + dy * (height - 10.5 - 切角位置Y) - 切角高;
            paths.push({
                type: "Line",
                params: [
                    sx, sy,
                    sx + dx * 切角宽, sy
                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy,
                    sx + dx * 切角宽, sy + 切角高

                ]
            }, {
                type: "Line",
                params: [
                    sx + dx * 切角宽, sy + 切角高,
                    sx, sy + 切角高
                ]
            }, {
                type: "Line",
                params: [
                    sx, sy + 切角高,
                    sx, sy
                ]
            });

        }
    }

    const corners: Path[] = [];

    //门把手
    if (extraConfig?.lashouConfig) {
        const { 门拉手类型, 开门方向, 拉手X: menlashouX, 拉手Y: menlashouY, 拉手间距: menlashoubianju, 门拉手方向: menlashoufangxiang } = extraConfig.lashouConfig;
        if (门拉手类型 === '暗拉手') {

        } else if (门拉手类型 === '明拉手') {
            if (开门方向 === "右开") {
                corners.push({
                    type: Circle,
                    params: [
                        startX + dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5),
                        2.1
                    ]
                });
                if (menlashoubianju > 0) {

                    if (menlashoufangxiang === "horizontal") {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * (menlashouX + 10.5 + menlashoubianju), startY + dy * height - dy * (menlashouY + 10.5),
                                2.1
                            ]
                        })
                    } else {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5 + menlashoubianju),
                                2.1
                            ]
                        })
                    }
                }
            } else {
                corners.push({
                    type: Circle,
                    params: [
                        startX + dx * width - dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5),
                        2.1
                    ]
                });
                if (menlashoubianju > 0) {

                    if (menlashoufangxiang === "horizontal") {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * width - dx * (menlashouX + 10.5 + menlashoubianju), startY + dy * height - dy * (menlashouY + 10.5),
                                2.1
                            ]
                        })
                    } else {
                        corners.push({
                            type: Circle,
                            params: [
                                startX + dx * width - dx * (menlashouX + 10.5), startY + dy * height - dy * (menlashouY + 10.5 + menlashoubianju),
                                2.1
                            ]
                        })
                    }
                }
            }

        }
    }

    if (extraConfig?.isDoor) {
        if (extraConfig?.jiaolianConfig) {

            let { 上铰链边距, 下铰链边距, 开门方向 } = extraConfig.jiaolianConfig;
            上铰链边距 = Number(上铰链边距);
            下铰链边距 = Number(下铰链边距);
            if (开门方向 === "左开") {
                paths.push({
                    type: Arc,
                    params: [
                        startX + dx * 38.769913, startY + dy * (下铰链边距 - 14),
                        3,
                        90, 270
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913, startY + dy * (下铰链边距 - 14) - dy * 3,
                        startX + dx * 38.769913 + dx * 2, startY + dy * (下铰链边距 - 14) - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * (下铰链边距 - 14),
                        3,
                        270, 450
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * (下铰链边距 - 14) + dy * 3,
                        startX + dx * 38.769913, startY + dy * (下铰链边距 - 14) + dy * 3,
                    ]
                }, {
                    type: Circle,
                    params: [
                        startX + dx * 33.769913, startY + dy * (下铰链边距 + 10),
                        17.75
                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * 38.769913, startY + dy * 48 + dy * (下铰链边距 - 14),
                        3,
                        90, 270
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913, startY + dy * 48 + dy * (下铰链边距 - 14) - dy * 3,
                        startX + dx * 38.769913 + dx * 2, startY + dy * 48 + dy * (下铰链边距 - 14) - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * 48 + dy * (下铰链边距 - 14),
                        3,
                        270, 450
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * 48 + dy * (下铰链边距 - 14) + dy * 3,
                        startX + dx * 38.769913, startY + dy * 48 + dy * (下铰链边距 - 14) + dy * 3,
                    ]
                });
                //上部分
                paths.push({
                    type: Arc,
                    params: [
                        startX + dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14),
                        3,
                        90, 270
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 3,
                        startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (上铰链边距 - 14),
                        3,
                        270, 450
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (上铰链边距 - 14) + dy * 3,
                        startX + dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) + dy * 3,
                    ]
                }, {
                    type: Circle,
                    params: [
                        startX + dx * 33.769913, startY + dy * height - dy * (上铰链边距 + 10),
                        17.75
                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48,
                        3,
                        90, 270
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 - dy * 3,
                        startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48,
                        3,
                        270, 450
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * 38.769913 + dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 + dy * 3,
                        startX + dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 + dy * 3,
                    ]
                });
            } else {
                paths.push({
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * (下铰链边距 - 14),
                        3,
                        // 90, 270
                        270, 450
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * (下铰链边距 - 14) - dy * 3,
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * (下铰链边距 - 14) - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * (下铰链边距 - 14),
                        3,
                        // 270, 450
                        90, 270,
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * (下铰链边距 - 14) + dy * 3,
                        startX + dx * width - dx * 38.769913, startY + dy * (下铰链边距 - 14) + dy * 3,
                    ]
                }, {
                    type: Circle,
                    params: [
                        startX + dx * width - dx * 33.769913, startY + dy * (下铰链边距 + 10),
                        17.75
                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * 48 + dy * (下铰链边距 - 14),
                        3,
                        // 90, 270
                        270, 450,
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * 48 + dy * (下铰链边距 - 14) - dy * 3,
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * 48 + dy * (下铰链边距 - 14) - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * 48 + dy * (下铰链边距 - 14),
                        3,
                        // 270, 450
                        90, 270
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * 48 + dy * (下铰链边距 - 14) + dy * 3,
                        startX + dx * width - dx * 38.769913, startY + dy * 48 + dy * (下铰链边距 - 14) + dy * 3,
                    ]
                });
                //上半部分
                paths.push({
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14),
                        3,
                        // 90, 270
                        270, 450
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 3,
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (上铰链边距 - 14),
                        3,
                        // 270, 450
                        90, 270,
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (上铰链边距 - 14) + dy * 3,
                        startX + dx * width - dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) + dy * 3,
                    ]
                }, {
                    type: Circle,
                    params: [
                        startX + dx * width - dx * 33.769913, startY + dy * height - dy * (上铰链边距 + 10),
                        17.75
                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48,
                        3,
                        // 90, 270
                        270, 450,
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 - dy * 3,
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 - dy * 3,

                    ]
                }, {
                    type: Arc,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48,
                        3,
                        // 270, 450
                        90, 270
                    ]
                }, {
                    type: Line,
                    params: [
                        startX + dx * width - dx * 38.769913 - dx * 2, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 + dy * 3,
                        startX + dx * width - dx * 38.769913, startY + dy * height - dy * (上铰链边距 - 14) - dy * 48 + dy * 3,
                    ]
                });
            }

        }
    }


    return [
        paths,
        corners.concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ]
}