import { DirectX, DirectY } from "../../../constants/enum";
import { menneiBoard } from "../地柜/01-mennei";

export const _11qiandangneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    // jiaolianbianju: number
): [Path[], Path[]] => {
    return menneiBoard(dx, dy, startX, startY, width, height, true, -1);
}