class Setting {




    setting: any = {};


    syncSettings = (settings: any) => {
        //console.log("settings sync", settings);
        this.setting = settings || {};
    }

}

export const setting = new Setting();