import { forwardRef, useImperativeHandle, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";

interface Props {
    children?: React.ReactNode
}

export default forwardRef(function WindowFrame(props:  Props, ref: any) {

    const headerRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            headerRef,
            tabsRef: headerRef.current?.tabsRef
        }
    })

    return (
        <Wrapper>
            <Header ref={headerRef}/>
            <Body>{props.children}</Body>
            {/* <Footer>
                {location.pathname}
            </Footer> */}
        </Wrapper>
    )

})

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f2f2f7;
`;
