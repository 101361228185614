import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { create } from 'zustand';

import en from './en-US.json';
import zh from './zh-CN.json';
import vi from './vi-VN.json';

const localLocale = localStorage.getItem("SK_LOCALE") || 'zh-CN';

export const localeMap = {
    'zh-CN': '中文',
    'en-US': 'English',
    'vi-VN': 'Tiếng Việt'
}

export const localeOptions = [
    { label: "中文", value: "zh-CN" },
    { label: "English", value: "en-US" },
    { label: "Tiếng Việt", value: "vi-VN" },
];


interface LocaleStore {
    locale: string,
    localeLabel: string,
    setLocale: (locale: string) => void
}

export const useLocaleStore = create<LocaleStore>((set) => ({
    locale: localLocale,
    localeLabel: localeMap[localLocale],
    setLocale: (locale: string) => {
        i18n.changeLanguage(locale);
        set({ 
            locale,
            localeLabel: localeMap[locale],
        })
        localStorage.setItem("SK_LOCALE", locale);
    },
}));



i18n
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': { translation: en },
      'zh-CN': { translation: zh },
      'vi-VN': { translation: vi }
    },
    lng: localLocale,
    fallbackLng: localLocale,
    interpolation: {
      escapeValue: false
    }
});