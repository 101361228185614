import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { menneiBoard } from "../地柜/01-mennei";

export const _11ceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
 ): [Path[], Path[]]  => {
    const [ boardPaths, cornerPaths ] = menneiBoard(dx, dy, startX, startY, width, height, true, -1);
    cornerPaths.push(
    //左边小方块    
    {
        type: Line,
        params: [startX + dx * 55.1, startY, startX + dx * 55.1, startY + dy * 12]
    }, {
        type: Line,
        params: [startX + dx * 55.1, startY + dy * 12, startX + dx * 55.1 + dx * 12, startY + dy * 12]
    }, {
        type: Line,
        params: [startX + dx * 55.1 + dx * 12, startY + dy * 12,  startX + dx * 55.1 + dx * 12, startY]
    }, 
    //右边小方孔
    {
        type: Line,
        params: [
            startX + dx * width - dx * 55.1, startY, 
            startX + dx * width - dx * 55.1, startY + dy * 12 
        ]
    }, {
        type: Line,
        params: [
            startX + dx * width - dx * 55.1, startY + dy * 12,
            startX + dx * width - dx * 55.1 - dx * 12, startY + dy * 12,
        ]
    }, {
        type: Line,
        params: [
            startX + dx * width - dx * 55.1 - dx * 12, startY + dy * 12,
            startX + dx * width - dx * 55.1 - dx * 12, startY
        ]
    })
    return [boardPaths, cornerPaths];
};