import { AppstoreOutlined, DeleteFilled, DownOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, HighlightOutlined, HomeOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, ConfigProvider, Dropdown, Image, Modal, Popover, Skeleton, Spin, message, theme } from "antd"
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import { request } from "../../utils2/request";
import { apis } from "../../utils2/apis";
import dayjs from "dayjs";


import New3DOrder from "../new-order/3d-order";
import { useTranslation } from "react-i18next";
import './style.css';

export const Home2 = () => {

    const navigate = useNavigate();

    const [orderList, setOrderList] = useState<any[]>([]);
    const [roomOrderList, setRoomOrderList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);


    const { t } = useTranslation();

    useEffect(() => {
        request.get(apis.order + "/list")
            .then((res) => {
                if (res.code === 1) {
                    const orderList: any[] = [];
                    const roomOrderList: any[] = [];
                    for (let i = 0; i < res.data.length; i++) {
                        const o = res.data[i];
                        if (o.displayType === '3d-room') {
                            roomOrderList.push(o);
                        } else {
                            orderList.push(o);
                        }
                    }
                    setOrderList(orderList);
                    setRoomOrderList(roomOrderList)
                    setLoading(false);
                }
            });
    }, []);

    const new3DOrderRef = useRef<any>();

    const onRemoveOrder = (order: any) => {
        Modal.confirm({
            type: "warning",
            title: t('请确认是否删除订单'),
            content: (
                <>
                    <div>{t("删除后不可恢复，请谨慎操作")}</div>
                    <div style={{ color: 'gray' }}>（{t("当前订单地址：")}{order.address}）</div>
                </>
            ),
            centered: true,
            onOk: () => {
                const close = message.loading(t("正在删除") + "...", 0);
                request.delete(apis.order + `/${order.id}`)
                    .then((res) => {
                        if (res.code === 1) {
                            if(order.displayType === '3d-room') {
                                const _roomOrderList = roomOrderList.filter((o) => o.id !== order.id);
                                setRoomOrderList(_roomOrderList);
                            }else {
                                const _orderList = orderList.filter((o) => o.id !== order.id);
                                setOrderList(_orderList);

                            }
                            close();
                            message.info(t("已删除"));
                        }
                    });
            }
        })
    }

    const onGoDesign = (order: any) => {
        if (order.displayType === '3d') {
            request.get(apis.order + `/${order.id}/detail`)
                .then((res) => {
                    if (res.code === 1) {
                        // localStorage.setItem("SK_CURRENT_ORDER", JSON.stringify(res.data));
                        // window.location.href = "/3d";
                        //@ts-ignore
                        window.$$order = res.data;
                        navigate(`/3d/${order.id}`);
                    }
                })
        } else if (order.displayType === '3d-room') {
            request.get(apis.order + `/${order.id}/detail`)
                .then((res) => {
                    if (res.code === 1) {
                        navigate(`/floor-planner?orderId=${res.data.id}`, { state: { order: res.data } });
                    }
                });
        } else {
            navigate("/v1/" + order.id)
        }
    }

    const onCreate3DRoomOrder = () => {
        new3DOrderRef.current?.open({
            displayType: "3d-room"
        });

    }

    const onCreate3DOrder = () => {
        new3DOrderRef.current?.open({
            displayType: "3d"
        });

    }

    const onCreate2DOrder = () => {
        navigate("/new");
    }

    /**
     * 
     * @param orderSimple 
     * @param orderDetail 存在空值情况
     * @returns 
     */
    const onCreateOrder = (orderSimple: any, orderDetail?: any) => {
        orderList.unshift(orderSimple);
        setOrderList([...orderList]);
        if (!orderDetail) {
            return;
        }
        if (orderSimple.displayType === '3d-room') {
            navigate(`/floor-planner?orderId=${orderDetail.id}`, { state: { order: orderDetail } });
        } else {
            onGoDesign(orderSimple)
        }
    }


    return (
        <ConfigProvider theme={{
            algorithm: [theme.defaultAlgorithm]
        }}>

            <New3DOrder
                ref={new3DOrderRef}
                onCreateOrder={onCreateOrder}
            />

            <div className="main-content" style={{}}>
                <div className="flex center space-between" style={{ width: "100%", marginBottom: 12, padding: '0 6px' }}>
                    <div className="flex">

                        <div className="create-btn-main" onClick={() => {
                            onCreate3DRoomOrder();
                        }}>
                            <span>{t("新建房间绘制")} <HomeOutlined style={{ color: "#1940ff" }} /></span>
                            <PlusOutlined />
                        </div>
                        <div className="create-btn" onClick={() => {
                            onCreate3DOrder();
                        }}>
                            <span> {t("新建3D订单")}</span>
                            <PlusOutlined />
                        </div>
                        <Popover trigger={["click"]} content={(

                            <div className="create-btn" onClick={() => {
                                onCreate2DOrder();
                            }}>
                                <span> {t("新建2D订单")}</span>
                                <PlusOutlined />
                            </div>
                        )}>
                            <div className="create-btn-more">
                                <DownOutlined />
                            </div>
                        </Popover>

                    </div>
                    <div className="flex">
                        {/* <div className="create-btn-main" style={{ marginRight: 10 }}>
                            <span> 批量排板（开发中...） <AppstoreOutlined style={{ color: "purple" }} /></span>
                            <ExportOutlined />
                        </div>

                        <Popover trigger={["click"]} content={(

                            <div className="create-btn" style={{ width: 160 }}>
                                <span> 批量不排板导出（开发中...）</span>
                                <ExportOutlined />
                            </div>
                        )}>
                            <div className="create-btn-more">
                                <DownOutlined />
                            </div>
                        </Popover> */}
                    </div>
                </div>
                {
                    loading && (
                        <div className="flex center" style={{ height: 100, color: "gray" }}>
                            <LoadingOutlined spin style={{ marginRight: 6 }} />
                            {t("正在加载数据")}...
                        </div>
                    )
                }
                {
                    roomOrderList.length > 0 && (
                        <div className="flex wrap" style={{ flexWrap: 'wrap', paddingBottom: '10px', borderBottom: "1px solid lightgray" }}>
                            {/* <div className="title">绘制的房间</div> */}
                            {
                                roomOrderList.map((o) => {
                                    return (
                                        <div key={o.orderNo} className="relative room-order-item flex column" style={{ cursor: "pointer", margin: "12px 6px", background: "#fff", borderRadius: 6, overflow: "hidden" }}>

                                            <div style={{
                                                position: 'absolute',
                                                left: 12,
                                                top: 12,
                                                marginRight: 8,
                                                background: "#e3effeaa",
                                                color: "#3288f9ee",
                                                borderRadius: 6, padding: "2px 8px", zIndex: 2
                                            }}>
                                                {t("绘房")}/3D
                                            </div>
                                            <div onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onGoDesign(o);
                                            }} className="room-order-item-mask" style={{
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                                height: 'calc((100vw - 17px - 6 * 12px) / 5)',
                                                minHeight: 210,
                                                minWidth: 210,
                                                width: "100%",
                                                background: "rgba(0,0,0,.5)",
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>

                                                <div className="btn-go-design" style={{ background: "#fff", padding: "12px 20px", fontSize: 16 }}>
                                                    <HighlightOutlined style={{ marginRight: 4 }} />
                                                    {t("去设计")}
                                                </div>
                                            </div>
                                            <div className="img-wrapper flex center" style={{ background: "#e2e2e7", width: "calc((100vw - 17px - 6 * 12px) / 5)", minWidth: 210, minHeight: 210, height: "calc((100vw - 17px - 6 * 12px) / 5)" }}>
                                                {
                                                    o.preview3DImagePath ? (
                                                        <img src={apis.download + o.preview3DImagePath} style={{ width: "80%" }} />
                                                    ) : o.preview2DImagePath ? (
                                                        <img src={apis.download + o.preview2DImagePath} style={{ width: "80%" }} />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="bold" style={{padding: "10px 10px 0 10px"}}>
                                                {o.address}
                                            </div>
                                            {/* <img style={{ alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" /> */}
                                            <div className="flex center space-between" style={{ alignItems: 'flex-end', padding: "10px", paddingTop: 0 }}>

                                      
                                                <div>

                                                    <div style={{ color: "gray", marginTop: 8, fontSize: 12 }}>{t("订单号：")}{o.orderNo}</div>
                                                    <div style={{ color: "gray", marginTop: 6, fontSize: 12 }}>{t("创建于：")}{dayjs(o.createTime).format('YYYY/MM/DD HH:mm')}</div>

                                                </div>

                                                <div className="flex">
                                                    <Dropdown
                                                        trigger={["click"]}
                                                        menu={{
                                                            items: [{
                                                                key: "1",
                                                                label: t("删除"),
                                                                icon: <DeleteFilled />,
                                                                onClick: () => {
                                                                    onRemoveOrder(o);
                                                                },
                                                                danger: true
                                                            }]
                                                        }}
                                                    >

                                                        <div className="btn-more" style={{ marginLeft: 4, height: 42, lineHeight: "32px" }}>
                                                            <EllipsisOutlined style={{}} />
                                                        </div>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
                {
                    orderList.length > 0 && (
                        <div className="flex" style={{ flexWrap: 'wrap', paddingTop: 10 }}>
                            {/* <div className="title">其他订单</div> */}
                            {
                                orderList.map((o) => {

                                    return (
                                        <div key={o.orderNo} className="order-item" style={{}}>
                                            <div className="flex center" style={{ justifyContent: "space-between" }}>
                                                <div className="flex center">
                                                    <div style={{
                                                        marginRight: 8,
                                                        background: o.displayType === '3d' ? "#e3effe" : "#f2f2f7",
                                                        color: o.displayType === '3d' ? "#3288f9" : "#999",
                                                        borderRadius: 6, padding: "2px 8px"
                                                    }}>
                                                        {o.displayType.toUpperCase()}
                                                    </div>
                                                    <span className="bold">

                                                        {o.address}
                                                    </span>

                                                </div>
                                            </div>
                                            <div className="flex center space-between" style={{ alignItems: 'flex-end' }}>
                                                <div>
                                                    <div style={{ color: "gray", marginTop: 8, fontSize: 12 }}>{t("订单号：")}{o.orderNo}</div>
                                                    <div style={{ color: "gray", marginTop: 6, fontSize: 12 }}>{t("创建于：")}{dayjs(o.createTime).format('YYYY/MM/DD HH:mm')}</div>

                                                </div>

                                                <div className="flex">
                                                    <div className="btn-go-design" onClick={() => {
                                                        onGoDesign(o);
                                                    }}>
                                                        <HighlightOutlined style={{ marginRight: 4 }} />
                                                        {t("去设计")}
                                                    </div>
                                                    <Dropdown
                                                        trigger={["click"]}
                                                        menu={{
                                                            items: [{
                                                                key: "1",
                                                                label: t("删除"),
                                                                icon: <DeleteFilled />,
                                                                onClick: () => {
                                                                    onRemoveOrder(o);
                                                                },
                                                                danger: true
                                                            }]
                                                        }}
                                                    >

                                                        <div className="btn-more" style={{ marginLeft: 4 }}>
                                                            <EllipsisOutlined style={{}} />
                                                        </div>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
            
            <div style={{ textAlign: 'center', padding: '50px 0', fontSize: 12, color: "gray"}}>到底了~</div>
        </ConfigProvider>
    )


}