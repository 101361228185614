import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { corner9 } from "../../coners/corner9";
import { _03qiandangneiBoard } from "./03-qiandangnei";
import { rectBoard } from "../general-board";

export const _04qiandangneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _03qiandangneiBoard(dx, dy, startX, startY, width, height, a);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]

 }