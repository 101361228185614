import { BaseCupboard } from "../@base/BaseCupboard";

import { _11dineiBoard } from '../../boards/yangtaigui/11-dinei';
import { _11diwaiBoard } from "../../boards/yangtaigui/11-diwai";
import { _11ceneiBoardLeft } from "../../boards/yangtaigui/11-cenei-left";
import { _11cewaiBoardLeft } from "../../boards/yangtaigui/11-cewai-left";
import { _11ceneiBoardMiddle } from "../../boards/yangtaigui/11-cenei-middle";
import { _11cewaiBoardMiddle } from "../../boards/yangtaigui/11-cewai-middle";
import { _11ceneiBoardRight } from "../../boards/yangtaigui/11-cenei-right";
import { _11cewaiBoardRight } from "../../boards/yangtaigui/11-cewai-right";
import { _11qiandangneiBoard } from "../../boards/yangtaigui/11-qiandangnei";
import { _11qiandangwaiBoard } from "../../boards/yangtaigui/11-qiandangwai";
import { _11mentouneiBoard } from "../../boards/yangtaigui/11-mentounei";
import { _11mentouwaiBoard } from "../../boards/yangtaigui/11-mentouwai";
import { _11menneiBoard } from "../../boards/yangtaigui/11-mennei";
import { _11menwaiBoard } from "../../boards/yangtaigui/11-menwai";

export class YangtaiguiCebaobei extends BaseCupboard {
    key: string = "YangtaiguiCebaobei";

    setNameMap(): { [boardName: string]: (...args: any[]) => [Path[], Path[]]; } {
        return {
            dineiBoard: _11dineiBoard,
            diwaiBoard: _11diwaiBoard,
            ceneiBoardLeft: _11ceneiBoardLeft,
            cewaiBoardLeft: _11cewaiBoardLeft,
            ceneiBoardMiddle: _11ceneiBoardMiddle,
            cewaiBoardMiddle: _11cewaiBoardMiddle,
            ceneiBoardRight: _11ceneiBoardRight,
            cewaiBoardRight: _11cewaiBoardRight,
            qiandangneiBoard: _11qiandangneiBoard,
            qiandangwaiBoard: _11qiandangwaiBoard,
            mentouneiBoard: _11mentouneiBoard,
            mentouwaiBoard: _11mentouwaiBoard,
            menneiBoard: _11menneiBoard,
            menwaiBoard: _11menwaiBoard,
            "menwaiBoard-left": _11menwaiBoard,
            "menneiBoard-left": _11menneiBoard,
            "menwaiBoard-right": _11menwaiBoard,
            "menneiBoard-right": _11menneiBoard,
        }
    }
    setTypeMap() {
        const typeMap: any = {};
        for (const name in this.nameMap) {
            if(name.indexOf("beinei") > -1) {
                typeMap[name] = "beinei";
            }else if(name.indexOf("beiwai") > -1) {
                typeMap[name] = "beiwai";
            }else if(name.indexOf("mennei") > -1) {
                typeMap[name] = "mennei";
            }else if(name.indexOf("menwai") > -1) {
                typeMap[name] = "menwai"; 
            }else if(name.indexOf("nei") > -1) {
                typeMap[name] = "guinei";
            }else if(name.indexOf("wai") > -1) {
                typeMap[name] = "guiwai";
            }
        }
        typeMap["qiandangwaiBoard"] = "menwai";
        typeMap["mentouwaiBoard"] = "menwai";
        
        return typeMap;
    }
    getSizeMap(long: number, deep: number, height: number, extraConfig: { [key: string]: any; }): { [name: string]: { size: [number, number]; rotate90?: boolean; quantity?: number; }; } {
        let wReduce = 0;
        let hReduce = 0;
        if(extraConfig.gxinglashou) {
            if(extraConfig.gxinglashouType === "up") {
                hReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }else {
                wReduce = 23.5 + extraConfig.gxinglashouGaodu;
            }
        }
        /* 2021 12 27 - 5 G型拉手门内高度+13*/
         let menneiHeightIncre = 0;
         let menneiWidthIncre = 0;
         if(extraConfig.gxinglashou) {
             if(extraConfig.gxinglashouType === "up") {
                 menneiHeightIncre = 13;
             }else {
                 menneiWidthIncre = 13;
             }
         }
        const list: { [boardName: string]: { size: [number, number], quantity?: number, rotate90?: boolean } } = {
            
            
            "diwaiBoard": {
                size: [long - 661, extraConfig.dibanshen + 47],
            }, 
            "dineiBoard": {
                size: [long - 687, extraConfig.dibanshen + 21]
            },
            
            "cewaiBoardLeft": {
                size: [extraConfig.zuocekuan + 47,  extraConfig.zuocechang + 47],
            },
            "ceneiBoardLeft": {
                size: [extraConfig.zuocekuan + 21, extraConfig.zuocechang + 21]
            },
          
            "cewaiBoardMiddle": {
                size: [extraConfig.zhongcekuan + 47,  extraConfig.zhongcechang + 47],
            },
            "ceneiBoardMiddle": {
                size: [extraConfig.zhongcekuan + 21, extraConfig.zhongcechang + 21]
            },
            "cewaiBoardRight": {
                size: [extraConfig.youcekuan + 47,  extraConfig.youcechang + 47],
            },
            "ceneiBoardRight": {
                size: [extraConfig.youcekuan + 21, extraConfig.youcechang + 21]
            },
            "mentouwaiBoard": {
                size: [247, long - 623],
            },
            "mentouneiBoard": {
                size: [221, long - 649]
            },
            "qiandangwaiBoard": {
                size: [187, long - 660]
            },
            "qiandangneiBoard": {
                size: [161, long - 686]
            },
            "menwaiBoard": {
                size: [extraConfig.menkuan + 45 - wReduce, extraConfig.mengao + 47 - hReduce],
                quantity: extraConfig.doorNumber,
            },
            "menneiBoard": {
                size: [extraConfig.menkuan + 21 - wReduce + menneiWidthIncre, extraConfig.mengao + 21 - hReduce + menneiHeightIncre],
                quantity: extraConfig.doorNumber
            },
        }
        return list;
    }
    executeBoard(board: Sheet, extraConfig: { [key: string]: any; }): [Path[], Path[]] {
        let temp;
        if(board.name.indexOf("menwaiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.menwaibanjiao, undefined/* menbashou */, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu);
        } else if(board.name.indexOf("menneiBoard") === 0) {
            let doorSide;
            if(board.name.indexOf("left") > -1) {
                doorSide = "left";
            }else if(board.name.indexOf("right") > -1) {
                doorSide = "right";
            }else {
                doorSide = extraConfig.doorSide;
            }
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nojiaoliankong, extraConfig.jiaolianbianju, extraConfig.nolashou, extraConfig.menminglashou, extraConfig.menlashouX, extraConfig.menlashouY, extraConfig.menlashoubianju, extraConfig.menlashoufangxiang, doorSide, extraConfig.gxinglashou, extraConfig.gxinglashouType, extraConfig.gxinglashouGaodu)
        } else if(board.name === "mentouwaiBoard" ) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.nomentouwaibanjiao);
        } else if(board.name === "qiandangwaiBoard"){
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.noqiandangwaibanjiao);
        } else if(board.name.indexOf("wai") > -1) {
            temp = this.nameMap[board.name](extraConfig.waibanjiaoType, 1, 1, board.startX, board.startY, board.width, board.height, extraConfig.waibanjiao);
        } else {
            temp = this.nameMap[board.name](1, 1, board.startX, board.startY, board.width, board.height);
        }
        return temp;
    }

}