import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05ceneiBoardRight } from "./05-cenei-right";

export const _07ceneiBoardRight = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang?: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    return _05ceneiBoardRight(dx, dy, startX, startY, width, height, lianjiekongbianju, qiandanghengfang, {});
 }