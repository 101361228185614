import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths } from "../../../util/graph";
import { dineiBoard } from "../地柜/01-dinei";

export const _10beineiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {

    const [dineiBoardPaths, dineiBoardCorners] = dineiBoard(dx, dy, startX, startY, width, height, 0, 0, {
        禁止长度增加自动加孔: true,
        背内: true,
    });
    const paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]).filter((path) => path.type !== Circle);

    return [
        paths.slice(0, dineiBoardPaths.length),
        paths.slice(dineiBoardPaths.length, paths.length),
    ]

 }