import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { corner1 } from "../../coners/corner1";
import { corner3 } from "../../coners/corner3";
import { corner4 } from "../../coners/corner4";
import { rectBoard } from "../general-board";

// export const 
export const _01AceneiBoardLeft = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number,
    width: number,
    height: number,
    lianjiekongbianju: number,
    qiandanghengfang: boolean
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    const cornerBL = corner3(dx, dy, startX, startY, true, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR = corner4(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width - cornerBRW);
    cornerBR = cornerBR.filter((item) => item.type !== Circle);
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR = corner1(-dx, dy, startX, startY, true, cornerTRSize);
    cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
    cornerTR = cornerTR.filter((item) => item.type !== Circle);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(qiandanghengfang) {
        cornerTR = pathMoveX(cornerTR, -DirectX.RIGHT, width);
        cornerTR = pathMoveY(cornerTR, -DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMirrorY(cornerTR, startX);
        cornerTR = pathRotate2(cornerTR, startX, startY, -90);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width - cornerTRSize.height);
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height);
        const widthTemp = cornerTRSize.width;
        cornerTRW = cornerTRSize.height;
        cornerTRH = widthTemp;
    }

    //右上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL = corner1(dx, dy, startX, startY, true, cornerTLSize);
    cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;


    const boardConfig: any = {
        middleware: {
            top: false,
            left: false,
            right: height > 500 && "close",
            bottom: width > 500 && "close",
        }
    }
    const paths: Path[] = rectBoard("inner", dx, dy, startX, startY, width, height, 
        cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);

    if(lianjiekongbianju >= 0) {
        paths.push({
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * width - dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        }, {
            type: Circle,
            params: [
                startX + dx * (lianjiekongbianju + 11.5), startY + dy * height - dy * (lianjiekongbianju + 11.5),
                2.75
            ]
        });
    }
   
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
    ];
}