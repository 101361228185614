import { Layer, Rect } from "react-konva"
import { defaultRectAreaHeight, defaultRectAreaWidth, useDrawingArea } from "./store";
import { ShapeArea } from "../$shapes/shape-area";
import { useEffect, useMemo, useState } from "react";
import { Vector2d } from "konva/lib/types";
import { CANVAS_SIZE, defaultWallThickness, m2px } from "../../../store";
import { calculateWallContours } from "../../../gpt/calculateWallContours";
import { ShapeDimension } from "../$shapes/shape-dimension";
import { areaRect } from "../../../store/area-store";
import { Html } from "react-konva-utils";
import { Button, Space } from "antd";
import { generateUUID } from "three/src/math/MathUtils";

export const LayerDrawingArea = (props: {

}) => {

    const { floorThickness, wallThickness, drawType, roomName, pushDrawed, cancelDraw, toStageCenter } = useDrawingArea();

    const [rectWidth, setRectWidth] = useState(defaultRectAreaWidth);
    const [rectHeight, setRectHeight] = useState(defaultRectAreaHeight);
    // const [wallThickness, setWallThickness] = useState(defaultWallThickness);

    const [load, setLoad] = useState(false);
    const [anchors, setAnchors] = useState<Vector2d[]>([]);
    const [innerContour, setInnerContour] = useState<Vector2d[]>([]);
    const [outerContour, setOuterContour] = useState<Vector2d[]>([]);
    const [innerArea, setInnerArea] = useState(0);
    const [outerArea, setOuterArea] = useState(0);
    const [innerBound, setInnerBound] = useState<{ left: number, top: number, bottom: number, right: number }>({ left: -Infinity, top: -Infinity, bottom: Infinity, right: Infinity });
    const [outerBound, setOuterBound] = useState<{ left: number, top: number, bottom: number, right: number }>({ left: -Infinity, top: -Infinity, bottom: Infinity, right: Infinity });

    useEffect(() => {
        if (!drawType || drawType !== 'area-rect') {
            setLoad(false);
        }
    }, [drawType]);


    useEffect(() => {
        //初始化墙顶点
        if (load) {
            return;
        }
        if (!drawType) {
            return;
        }
        const brushPos = { x: CANVAS_SIZE * m2px / 2, y: CANVAS_SIZE * m2px / 2 };
        const anchors = [
            { x: brushPos.x - rectWidth / 2, y: brushPos.y - rectHeight / 2 },
            { x: brushPos.x + rectWidth / 2, y: brushPos.y - rectHeight / 2 },
            { x: brushPos.x + rectWidth / 2, y: brushPos.y + rectHeight / 2 },
            { x: brushPos.x - rectWidth / 2, y: brushPos.y + rectHeight / 2 },
        ]
        const res = calculateWallContours(anchors, wallThickness);
        setAnchors(anchors);
        setInnerContour(res.innerContour);
        setOuterContour(res.outerContour);
        setInnerArea(res.innerArea);
        setOuterArea(res.outerArea);
        setInnerBound(res.innerBound);
        setOuterBound(res.outerBound);
        setLoad(true);
        // toStageCenter();
    }, [load, drawType, rectWidth, rectHeight, wallThickness]);

    useEffect(() => {
        if (!load) {
            return;
        }
        const brushPos = { x: CANVAS_SIZE * m2px / 2, y: CANVAS_SIZE * m2px / 2 };
        const anchors = [
            { x: brushPos.x - rectWidth / 2, y: brushPos.y - rectHeight / 2 },
            { x: brushPos.x + rectWidth / 2, y: brushPos.y - rectHeight / 2 },
            { x: brushPos.x + rectWidth / 2, y: brushPos.y + rectHeight / 2 },
            { x: brushPos.x - rectWidth / 2, y: brushPos.y + rectHeight / 2 },
        ]
        const res = calculateWallContours(anchors, wallThickness);
        setAnchors(anchors);
        setInnerContour(res.innerContour);
        setOuterContour(res.outerContour);
        setInnerArea(res.innerArea);
        setOuterArea(res.outerArea);
        setInnerBound(res.innerBound);
        setOuterBound(res.outerBound);
        // toStageCenter();

    }, [load, rectWidth, rectHeight, wallThickness]);



    return (
        <>
            <Layer >
                {
                    load && (
                        <ShapeArea
                            anchors={anchors}
                            innerContour={innerContour}
                            outerContour={outerContour}
                            innerArea={innerArea}
                            innerBound={innerBound}
                            hideAreaLabel
                            wallThickness={wallThickness} 
                            areaName={roomName}                            
                        />
                    )
                }
                {/* <Rect
                x={0}
                y={0}
                width={CANVAS_SIZE * m2px}
                height={CANVAS_SIZE * m2px}
                // fill="
                opacity={0}
                onClick={onClick}
            /> */}


                {
                    useMemo(() => {
                        if (!load) {
                            return;
                        }
                        //复制线
                        let last = innerContour[0];
                        const dimension: {
                            direct: "x" | "y",
                            from: number,
                            to: number,
                            base: number,
                            offset: number,
                        }[] = [];
                        const calc = (last: Vector2d, anchor: Vector2d) => {
                            let direct: "x" | "y";
                            let offset: number;
                            if (Math.abs(anchor.x - last.x) !== 0) {
                                direct = "x";
                                const from = last.x;
                                const to = anchor.x;
                                const base = anchor.y;
                                if (base - outerBound.top < outerBound.bottom - base) {
                                    offset = -(base - outerBound.top) - 20;
                                } else {
                                    offset = outerBound.bottom - base + 20;
                                }
                                dimension.push({
                                    direct,
                                    from,
                                    to,
                                    base,
                                    offset
                                })
                            } else {
                                direct = "y";
                                let offsetSign = 1;
                                if (anchor.x <= (innerBound.left + innerBound.right) / 2) {
                                    offsetSign = -1;
                                }
                                const from = last.y;
                                const to = anchor.y;
                                const base = anchor.x;

                                if (base - outerBound.left < outerBound.right - base) {
                                    offset = -(base - outerBound.left) - 20;
                                } else {
                                    offset = outerBound.right - base + 20;
                                }
                                dimension.push({
                                    direct,
                                    from,
                                    to,
                                    base,
                                    offset
                                });
                            }
                        }
                        for (let i = 1; i < innerContour.length; i++) {
                            const anchor = innerContour[i];
                            calc(last, anchor);
                            last = anchor;
                        }
                        calc(innerContour[innerContour.length - 1], innerContour[0]);

                        return dimension.map(d => (
                            <ShapeDimension
                                {...d}
                                canInput
                                // autoSelect
                                onLengthSet={(length) => {
                                    if (d.direct === "x") {
                                        setRectWidth(length + wallThickness);
                                    } else {
                                        setRectHeight(length + wallThickness);
                                    }
                                }}
                            />
                        ))
                    }, [load, innerContour, outerContour])
                }
            </Layer>

            <Layer>
                {
                    load && (

                        <Html
                            transform={false}
                        >
                            <AreaRectForm
                                height={rectHeight - wallThickness}
                                width={rectWidth - wallThickness}
                                // wallThickness={wallThickness}
                                onHeightSet={(height) => {
                                    setRectHeight(height + wallThickness);
                                }}
                                onWidthSet={(width) => {
                                    setRectWidth(width + wallThickness);
                                }}
                                // onWallThicknessSet={(wallThickness) => {
                                //     setWallThickness(wallThickness)
                                // }}
                                onConfirm={() => {
                                    pushDrawed({
                                        type: "area",
                                        data: {
                                            id: generateUUID(),
                                            areaName: roomName,
                                            anchors: anchors,
                                            innerContour,
                                            outerContour,
                                            innerArea,
                                            innerBound,
                                            outerBound,
                                            outerArea,
                                            wallThickness,
                                            floorThickness,
                                            walls: []
                                        }
                                    });
                                    cancelDraw();
                                }}

                                onCancel={() => {
                                    cancelDraw();

                                }}
                            />
                        </Html>
                    )
                }
            </Layer>
        </>
    )
}


export const AreaRectForm = (props: {
    height: number,
    width: number,
    // wallThickness: number,
    onHeightSet: (height: number) => void,
    onWidthSet: (width: number) => void,
    // onWallThicknessSet: (wallThickness: number) => void,
    onConfirm: () => void,
    onCancel: () => void
}) => {

    const [init, setInit] = useState(false);
    const [store, setStore] = useState<any>(areaRect.updateStoreBatch({
        height: props.height,
        width: props.width,
        // wallThickness: props.wallThickness
    }));

    useEffect(() => {
        setInit(true);
    }, []);



    useEffect(() => {
        if (!init) {
            return;
        }
        if (store !== areaRect.$getStore()) {
            areaRect.updateStoreBatch(store);
        }
    }, [init, store]);

    useEffect(() => {
        if (!init) {
            return;
        }
        if (props.height !== store.height) {
            setStore({
                height: props.height,
                width: store.width,
                // wallThickness: store.wallThickness
            });
        }
    }, [init, props.height, props.width]);

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{areaRect.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    areaRect.getEditForm(
                        store,
                        (newStore) => {
                            for (const key in newStore) {
                                if (Math.abs(newStore[key] - store[key]) > 0.1) {
                                    console.log("update", key, store[key], '->', newStore[key])
                                    if (key === 'height') {
                                        props.onHeightSet(newStore[key]);
                                    } else if (key === 'width') {
                                        props.onWidthSet(newStore[key]);
                                    }
                                }
                            }
                            setStore(newStore);
                        }
                    )()
                }

                <Space align="end">
                    <Button type="primary" onClick={() => {
                        props.onConfirm();
                    }}>确认</Button>
                    <Button type="default" onClick={() => {
                        props.onCancel();
                    }}>
                        取消
                    </Button>

                </Space>
            </div>

        </div>
    )
}