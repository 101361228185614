import { ArrowRightOutlined, CaretDownOutlined, CodeSandboxOutlined, DeleteOutlined, DesktopOutlined, DownOutlined, EditOutlined, EllipsisOutlined, FileSearchOutlined, PlusOutlined, SafetyCertificateOutlined, StarFilled, WindowsFilled } from "@ant-design/icons";
import { Button, ConfigProvider, Divider, Dropdown, message, Modal, Skeleton, Table, theme } from "antd";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import WindowFrame from "../../components/WindowFrame";
import { ipcRenderer, os } from "../../utils/electron";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import pkg from '../../../package.json';
import { request } from "../../utils2/request";
import { apis } from "../../utils2/apis";

import New3DOrder from "../../pages/new-order/3d-order";

const hostname = os?.hostname();

export default function Home() {

    const frameRef = useRef<any>();

    const [orders, setOrders] = useState<any[]>([]);
    const [loginInfo, setLoginInfo] = useState<any>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const local = localStorage.getItem("SK_LOGIN_INFO");
        if (local) {
            setLoginInfo(JSON.parse(local));
        }
        request.get(apis.order + "/list")
            .then((res) => {
                if (res.code === 1) {
                    setOrders(res.data/* .concat(res.data).concat(res.data).concat(res.data) */);
                    setLoading(false);
                }
            });
    }, []);

    const navigate = useNavigate();

    const new3DOrderRef = useRef<any>();



    const onModifyOrder = (order: any) => {
        console.log(order);

    }

    const onRemoveOrder = (order: any) => {
        Modal.confirm({
            type: "warning",
            title: '请确认是否删除该订单',
            content: (
                <>
                    <div>删除后不可恢复，请谨慎操作</div>
                    <div style={{ color: 'gray' }}>（当前订单地址：{order.address}）</div>
                </>
            ),
            centered: true,
            onOk: () => {
                request.delete(apis.order + `/${order.id}`)
                    .then((res) => {
                        if (res.code === 1) {
                            const _orders = orders.filter((o) => o.id !== order.id);
                            setOrders(_orders);
                            message.warning("已删除");
                        }
                    })
            }
        })
    }

    return (
        <ConfigProvider theme={{
            algorithm: [theme.defaultAlgorithm]
        }}>
            <div className="flex center" style={{ background: "#333", padding: '6px 0', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div className="flex account">
                    <div className="icon" style={{ textAlign: 'center', padding: '0 6px' }}>
                        <SafetyCertificateOutlined style={{ fontSize: 16, color: "cyan" }} />
                        <div style={{ color: 'cyan', fontSize: 10, transform: 'scale(0.6)', transformOrigin: "center top", marginTop: 3 }}>已授权</div>
                    </div>
                    <div className="text" style={{ marginTop: 0 }}>
                        <div style={{ color: "#fff", fontSize: 16, fontWeight: 'bold' }}>
                            您好{loginInfo?.accountInfo?.account}，欢迎！
                        </div>
                        <div style={{ fontSize: 10, marginTop: 2, color: '#aaa' }}>
                            <WindowsFilled style={{ marginRight: 3 }} />
                            {hostname || "浏览器"}
                        </div>
                    </div>
                </div>
                <div style={{ color: "gray", marginRight: 16 }}>
                    
                    当前版本：{pkg.displayVersion}
                    <Button style={{color: "orange"}} type="link" onClick={() => {
                        window.open('/#/next', "_blank");
                    }}><StarFilled/> 体验新版 <ArrowRightOutlined /></Button>
                </div>
            </div>
            {
                loading ? (
                    <div style={{ padding: 16, textAlign: 'center' }}>
                        <Skeleton active />
                        <span style={{ marginTop: 10, color: "gray" }}>正在获取数据...</span>
                    </div>
                ) : (
                    <div style={{ height: "calc(100vh - 52px)", overflowY: 'auto' }}>
                        <Table
                            dataSource={orders}
                            pagination={false}
                            columns={[{
                                key: "address",
                                dataIndex: "address",
                                title: "订单列表",
                                render: (address: string, order: any) => {
                                    return (
                                        <div className="flex" style={{ alignItems: 'center' }}>
                                            <div>
                                                <div className="bold" style={{ fontSize: 16 }}>地址：{address}</div>
                                                <div className="color-gray" style={{ fontSize: 12 }}>订单号：{order.orderNo}</div>
                                            </div>
                                            {
                                                order.displayType === '3d' && (
                                                    <i style={{ fontSize: 18, fontWeight: 'bold', marginLeft: 16, color: "blue" }}>3D版</i>
                                                )
                                            }
                                            <div>

                                            </div>

                                        </div>
                                    )
                                }
                            }, {
                                key: "createTime",
                                dataIndex: "createTime",
                                title: "创建日期",
                                width: 200,
                                render: (createTime: string) => {
                                    return (
                                        dayjs(createTime).format('YYYY年MM月DD日 HH:mm:ss')
                                    )
                                }
                            }, {
                                title: (
                                    <Button.Group>

                                        <Button
                                            style={{ backgroundColor: "purple", color: "#fff", border: 0 }}
                                            onClick={() => {
                                                new3DOrderRef.current?.open({
                                                    displayType: "3d"
                                                });
                                            }}
                                        >
                                            <PlusOutlined />
                                            新建3D订单
                                        </Button>
                                        <Dropdown
                                            trigger={["click"]}
                                            menu={{
                                                items: [
                                                {
                                                    key: "/new/3d",
                                                    icon: <PlusOutlined />,
                                                    label: "新建2D订单",
                                                    onClick: () => {
                                                        navigate("/new");
                                                    }
                                                }]
                                            }}
                                        >
                                            <Button style={{ backgroundColor: "purple", paddingLeft: 0, border: 0, outline: "none" }}>
                                                <CaretDownOutlined style={{ color: "#fff" }} />
                                            </Button>
                                        </Dropdown>
                                    </Button.Group>
                                ),
                                key: "actions",
                                dataIndex: "actions",
                                align: "right",
                                width: 120,
                                render: (_, order: any) => {
                                    return (
                                        <div className="flex column">
                                            <Button
                                                type="primary"
                                                size="small"
                                                style={{ marginBottom: 5 }}
                                                onClick={() => {
                                                    // frameRef.current.tabsRef.current.push({
                                                    //     key: "/v1/" + order.id,
                                                    //     path: "/v1/" + order.id,
                                                    //     title: `订单：${order.address}`
                                                    // });
                                                    if (order.displayType === '3d') {
                                                        request.get(apis.order + `/${order.id}/detail`)
                                                            .then((res) => {
                                                                if (res.code === 1) {
                                                                    localStorage.setItem("SK_CURRENT_ORDER", JSON.stringify(res.data));
                                                                    window.location.href = "/3d";
                                                                }
                                                            })
                                                    } else {
                                                        navigate("/v1/" + order.id)
                                                    }
                                                }}
                                            >
                                                <FileSearchOutlined />
                                                编辑
                                            </Button>
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        {
                                                            key: "delete",
                                                            icon: (
                                                                <DeleteOutlined />
                                                            ),
                                                            label: "删除订单",
                                                            danger: true,
                                                            onClick: () => {
                                                                onRemoveOrder(order);
                                                            }
                                                        }]
                                                }}
                                            >
                                                <Button size="small">
                                                    <EllipsisOutlined />
                                                    更多操作
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    )
                                }
                            }]}
                        />
                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                            <span style={{ color: 'gray', fontSize: 12 }}>到底了，共{orders.length}个订单</span>
                        </div>
                    </div>
                )
            }

            <New3DOrder
                ref={new3DOrderRef}
                onCreateOrder={(order: any) => {
                    orders.unshift(order);
                    setOrders([...orders]);
                }}
            />
        </ConfigProvider>
    )

}


const Header = styled.div`
    height: 50px;
    background-color: #333;
    width: 100%;
    display: flex;
    align-items: center;
    /* border-top: 1px solid #111; */
    .account {
        display: flex;
        align-items: center;
        .icon {
            position: relative;
            top: 3px;
        }
        .text {
            position: relative;
            top: -1px;
        }
    }
`
