import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CommonLoginPage2 from "../../@common/CommonLoginPage";
import { search2Query } from "../../utils2";
import { apis } from "../../utils2/apis";
import { request } from '../../utils2/request';
import pkg from '../../../package.json';
import { useTranslation } from "react-i18next";
import { LocaleSwitch } from "../../locale/LocaleSwich";

export default function LoginPage() {

    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        // <div style={{height: '100vh', width: "100vw"}}>
        <CommonLoginPage2
            logo={(
                <div className='flex center'>
                    <img className='relative' style={{position: 'relative', top: 1, marginRight: 6}} src="/favicon.ico" width={22} height={22}/>
                    <span style={{fontSize: 20}}>{t("大圣设计")}</span>
                </div>
            )}
            extra={(
                <LocaleSwitch />
            )}
            imageUrl={"/assets/images/db91faaf73c14b0df39f5a47e69bcbf.jpg"}
            accountLogin={{
                onLogin: async (account: string, password: string) => {
                    const localClient = localStorage.getItem("SK_CLIENT");
                    let client;
                    if(localClient) {
                        client = JSON.parse(localClient);
                    }
                    const params = {
                        account,
                        password,
                        clientUUID: client?.uuid || undefined,
                        clientVersion: pkg.version
                    }
                    const res = await request.post(apis.login, params, {
                        autoErrorTip: false,
                        withoutAuthorization: true
                    });
                    request.reset();
                    if(res.accessToken) {
                        const tokenExpireAt = Date.now() + res.expiresIn * 1000;
                        localStorage.setItem("SK_LOGIN_INFO", JSON.stringify(res));
                        localStorage.setItem("SK_TOKEN_EXPIRE_AT", tokenExpireAt.toString());
                        localStorage.setItem("SK_CLIENT", JSON.stringify(res.client));
                        //@ts-ignore
                        window.loginInfo = res;
                        //@ts-ignore
                        window.client = res.client;
                        return true;
                    }else {
                        message.warning(t("登陆失败，请检查账号或密码"));
                        return false;
                    }
                }
            }}
            // scanLogin={{
            //     code: "123",
            //     tip: "使用微信扫码进行登录"
            // }}
            onLoginSuccess={() => {
                const search = search2Query(location.search);
                if(search.redirectTo) {
                    navigate(search.redirectTo)
                }else {
                    navigate("/")

                }
            }}
        />

        // </div>
    )

}