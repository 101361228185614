import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { sanheyiWaijiaoPart2, sanheyiWaijiaoPartYuan } from "./@parts";





export const corner2Sanheyi = (
    _dx: DirectX, 
    _dy: DirectY, 
    startX: number, 
    startY: number, 
    corner: boolean, 
    sizeRes?: { 
        width: number, 
        height: number 
    }
): Path[] => {
    const dx = 1;
    const dy = 1;
    let paths: Path[] = [];
    paths.push(...sanheyiWaijiaoPartYuan(startX, startY, dx, dy));
    const sx = startX;
    const sy = startY + dy * 25.200000 + dy * 10.401707;
    paths.push({
        type: Line,
        params: [
            startX, startY + dy * 25.200000,
            startX, startY + dy * 25.200000 + dy * 10.401707
        ]
    });
    paths.push(...sanheyiWaijiaoPart2(sx, sy, dx, dy));
    const width = 34.000335;
    const height = 69.6;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);

    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
    }
    
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);

}