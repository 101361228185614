import { Checkbox, Form, Input, Modal, Radio, Skeleton, Switch, Tabs } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { TagView, TagView2 } from "../../../components/tag-view";
type TagSettings = {
    tagTemplate: string;
    showWaterMark: boolean;
    waterMark: string;
    waterMarkColor: string;
    barCodeWidth: string;
    showFrame: boolean;
};
export const ExportSettingsModel = forwardRef((props: {

}, ref: any) => {

    const [visible, setVisible] = useState(false);

    const open = () => {
        setVisible(true);
    }

    const close = () => {
        setVisible(false);
    }

    useImperativeHandle(ref, () => {
        return {
            open,
            close
        }
    })

    return (
        <Modal
            title="导出设置"
            open={visible}
            onCancel={close}
            centered
            footer={[]}
            destroyOnClose
        >
            <TagTemplateSelector />
        </Modal>
    )
});


export const TagTemplateSelector = (
    props: {
        value?: string;
        onChange?: (value: string) => void;
    }
) => {

    const [currentSettings, setCurrentSettings] = useState<TagSettings>({} as any);
    const [loading, setLoading] = useState(true);
    const exportSettingsRef = useRef<any>({});

    useEffect(() => {
        const settings = localStorage.getItem("SK_EXPORT_SETTINGS");
        if (settings) {
            try {
                const exportSettings = JSON.parse(settings);
                exportSettingsRef.current = exportSettings || {};
                const tagSettings = exportSettings?.tagSettings || {
                    tagTemplate: "v2",
                    showWaterMark: true,
                    waterMark: "大圣设备",
                    waterMarkColor: "blue",
                    barCodeWidth: '1.9',
                    showFrame: false,
                };
                setCurrentSettings(tagSettings);
            } catch (e) {
                console.error(e);
            } finally {
            }
        } else {
            setCurrentSettings({
                tagTemplate: "v2",
                showWaterMark: true,
                waterMark: "大圣设备",
                waterMarkColor: "blue",
                barCodeWidth: '1.9',
                showFrame: false,
            });
        }
        setLoading(false);
    }, []);
    useEffect(() => {
        let timer: any;
        if (loading) {
            return;
        }
        exportSettingsRef.current.tagSettings = currentSettings;
        timer = setTimeout(() => {
            localStorage.setItem("SK_EXPORT_SETTINGS", JSON.stringify(exportSettingsRef.current));
        }, 50);
        return () => {
            clearTimeout(timer);
        }
    }, [currentSettings, loading])

    return loading ? (<Skeleton active />) : (
        <div>

            <div className="标签模板" style={{ display: "flex", }}>
                <div style={{ cursor: "pointer" }} onClick={() => {
                    currentSettings.tagTemplate = 'v2';
                    setCurrentSettings({ ...currentSettings });

                }}>
                    <div style={{ margin: '6px 0' }}>
                        <Checkbox checked={currentSettings.tagTemplate === 'v2'}>新版标签</Checkbox>
                    </div>
                    <div style={{ zoom: .6 }}>
                        <TagView2
                            waterMark={currentSettings.waterMark}
                            waterMarkColor={currentSettings.waterMarkColor}
                            showWaterMark={currentSettings.showWaterMark}
                            barCodeWidth={Number(currentSettings.barCodeWidth)}
                            {...tagDataDemo}
                            sheetHeight={2438}
                            sheetWidth={1218}
                            showFrame={currentSettings.showFrame}

                        />
                    </div>
                </div>
                <div style={{ cursor: "pointer", marginLeft: 18 }} onClick={() => {

                    currentSettings.tagTemplate = '';
                    setCurrentSettings({ ...currentSettings });
                }}>
                    <div style={{ margin: '6px 0' }}>
                        <Checkbox checked={currentSettings.tagTemplate === ''} />
                        原始标签
                    </div>
                    <div style={{ zoom: .6 }}>

                        <TagView
                            {...tagDataDemo}
                        />
                    </div>

                </div>
            </div>
            {/* <Form >  */}

            {
                currentSettings.tagTemplate === 'v2' && (
                    <div style={{ marginLeft: 18 }}>
                        <div style={{ margin: '6px 0' }}>
                            <Form.Item label="显示水印">

                                <Switch onChange={(e) => {
                                    currentSettings.showWaterMark = e;
                                    setCurrentSettings({ ...currentSettings });
                                }} value={currentSettings.showWaterMark} />
                            </Form.Item>
                        </div>
                        {
                            currentSettings.showWaterMark && (
                                <>
                                    <div style={{ margin: '6px 0' }}>
                                        <Form.Item label="水印文字">
                                            <Input style={{ width: 150 }} value={currentSettings.waterMark} onChange={(e) => {
                                                currentSettings.waterMark = e.currentTarget.value;
                                                setCurrentSettings({ ...currentSettings });
                                            }} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ margin: '6px 0' }}>
                                        <Form.Item label="水印颜色">
                                            <Radio.Group value={currentSettings.waterMarkColor} onChange={(e) => {
                                                currentSettings.waterMarkColor = e.target.value;
                                                setCurrentSettings({ ...currentSettings });

                                            }}>
                                                <Radio value="blue">蓝色</Radio>
                                                <Radio value="red">红色</Radio>
                                                <Radio value="black">灰色</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Form.Item label="条码宽度参数调节">
                                            <Input style={{ width: 150 }} value={currentSettings.barCodeWidth} onChange={(e) => {
                                                currentSettings.barCodeWidth = e.currentTarget.value;
                                                setCurrentSettings({ ...currentSettings });
                                            }} />
                                        </Form.Item>
                                    </div>


                                </>
                            )
                        }

                    </div>
                )
            }
            {/* </Form> */}
            <div style={{ margin: '6px 0' }}>
                <Form.Item label="显示大板轮廓">

                    <Switch onChange={(e) => {
                        currentSettings.showFrame = e;
                        setCurrentSettings({ ...currentSettings });
                    }} value={currentSettings.showFrame} />
                </Form.Item>
            </div>
        </div>
    )
}


const tagDataDemo = {
    "selected": true,
    "tagRects": [
        {
            "x": 611,
            "y": 806,
            "width": 597,
            "height": 847,
            "number": "1",
            "id": "00-00"
        },
        {
            "x": 29,
            "y": 806,
            "width": 571.2,
            "height": 821.2,
            "number": "1",
            "id": "00-01"
        },
        {
            "x": 524,
            "y": 10,
            "width": 683.2,
            "height": 785.2,
            "number": "1",
            "id": "00-03"
        },
        {
            "x": 611,
            "y": 1663,
            "width": 597,
            "height": 709,
            "number": "1",
            "id": "00-04"
        },
        {
            "x": 29,
            "y": 1638,
            "width": 571.2,
            "height": 683.2,
            "number": "1",
            "id": "00-05"
        },
        {
            "x": 20,
            "y": 10,
            "width": 493.2,
            "height": 785.2,
            "number": "1",
            "id": "00-11"
        }
    ],
    "body": {
        "list": [
            {
                "content": "地址：123"
            },
            {
                "content": "橱柜：双开门地柜"
            },
            {
                "content": "编号：01-01"
            },
            {
                "content": "板件：底板_外板"
            },
            {
                "content": "材质：拉丝无指纹"
            },
            {
                "content": "尺寸：847mm × 597mm"
            },
            {
                "content": "所属大板：1/2"
            }
        ],
        "$rects": {
            "x": 611,
            "y": 806,
            "width": 597,
            "height": 847,
            "number": "1",
            "id": "00-00"
        }
    },
    "footer": {
        "content": "单号：BXGCG2024022125"
    }
}