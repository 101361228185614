import { Vector2d } from "konva/lib/types";
import { DrawOnWallType } from "../../../store"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Arc, Group, Rect } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";


const defaultFrameStyle = {
    stroke: "lightgray",
    fill: "#ffffff",
    strokeWidth: 2,
    strokeScaleEnabled: false,
    opacity: 1
}

const defaultDoorStyle = {
    stroke: "lightgray",
    fill: "#ffffffaa",
    strokeWidth: 2,
    strokeScaleEnabled: false,
    opacity: 1


}

export const ShapeOnWall = forwardRef((props: {

    onWallType: DrawOnWallType,
    openDirect: "top" | "left" | "right" | "bottom";//开扇方向
    openClockwise?: boolean;                         //单开扇门是否顺时针
    onWallWidth: number;
    anchor: number;                         //锚点，根据openDirect来确定是anchorX还是anchorY
    wall: { from: Vector2d, to: Vector2d };   //门从哪开始到哪结束
    wallThickness: number;
    onWallThickness: number;                  //墙厚
    frameStyle?: Partial<typeof defaultFrameStyle>;
    doorStyle?: Partial<typeof defaultDoorStyle>;
    draggable?: boolean;
    selectable?: boolean;
    selected?: boolean;
    onSelectChange?: (selected: boolean) => void;

    
    onDragStart?: (e: KonvaEventObject<DragEvent>) => void;
    onDragMove?: (e: KonvaEventObject<DragEvent>) => void;
    onDragEnd?: (e: KonvaEventObject<DragEvent>) => void;

    onMouseOver?: (e: KonvaEventObject<MouseEvent>) => void;
    onMouseLeave?: (e: KonvaEventObject<MouseEvent>) => void;
    onMouseUp?: (e: KonvaEventObject<MouseEvent>) => void;
    onMouseDown?: (e: KonvaEventObject<MouseEvent>) => void;

    setShapeRef?: (ref: any) => void;
}, ref: any) => {


    const [ frameStyle, setFrameStyle] = useState({...defaultFrameStyle, ...(props.frameStyle || {})});
    const [ doorStyle, setDoorStyle ] = useState({...defaultDoorStyle, ...(props.doorStyle || {})});
    const [isSelected, setIsSelected] = useState(false);
    const [isOver, setIsOver] = useState(false);

    const doorRef = useRef<any>();

    useEffect(() => {
        setIsSelected(props.selected || false);
    }, [props.selected])

    useEffect(() => {
        if(isSelected) {
            setFrameStyle({
                stroke: "cyan",
                fill: "#ffffff", 
                strokeWidth: 2, 
                strokeScaleEnabled: false,
                opacity: 0
            });
            setDoorStyle({
                stroke: "cyan", 
                fill: "#ffffff",
                strokeWidth: 2,
                strokeScaleEnabled: false,
                opacity: 0
            });
        } else if(isOver) {
            setFrameStyle({
                stroke: "cyan", 
                fill: "#ffffff", 
                strokeWidth: 2, 
                strokeScaleEnabled: false,
                opacity: 1
            });
            setDoorStyle({
                stroke: "cyan", 
                fill: "#ffffffaa", 
                strokeWidth: 1,
                strokeScaleEnabled: false,
                opacity: 2
            });
        }else {
            setFrameStyle({...defaultFrameStyle, ...(props.frameStyle || {})});
            setDoorStyle({...defaultDoorStyle, ...(props.doorStyle || {})});
        }
    }, [isSelected, isOver]);


    const onSelectChange = (targetSelected: boolean) => {
        setIsSelected(targetSelected);
        props.onSelectChange && props.onSelectChange(targetSelected);
    }

    const onClick = (e: KonvaEventObject<MouseEvent>) => {
        if (!props.selectable) {
            return;
        }
        if (e.evt.button !== 0) {
            return;
        }
        const targetSelected = !isSelected;
        onSelectChange(targetSelected);
    }
    
    const onWallThickness = props.onWallThickness;
    const onWallWidth = props.onWallWidth;

    useImperativeHandle(ref, () => ({
        getDoorRef: () => doorRef.current,
    }));

    
    const [anchor, setAnchor] = useState<Vector2d>(() => {
        if (props.openDirect === "left" || props.openDirect === "right") {
            return { x: props.wall.from.x, y: props.anchor }
        } else {
            return { x: props.anchor, y: props.wall.from.y }
        }
    });

    useEffect(() => {
        setAnchor((() => {
            if (props.openDirect === "left" || props.openDirect === "right") {
                return { x: props.wall.from.x, y: props.anchor }
            } else {
                return { x: props.anchor, y: props.wall.from.y }
            }
        })())
    }, [props.openDirect, props.anchor, props.wall.from.x, props.wall.from.y]);

    return (
        <Group
            draggable={props.draggable}
            x={anchor.x}
            y={anchor.y}
            onClick={onClick}
            onMouseOver={(e) => {
                props.onMouseOver && props.onMouseOver(e);
                props.selectable && !isSelected && setIsOver(true)
            }}
            onMouseLeave={(e) => {
                props.onMouseLeave && props.onMouseLeave(e);    
                setIsOver(false)
            }}
            onMouseUp={props.onMouseUp}
            onMouseDown={props.onMouseDown}
            onDragStart={props.onDragStart}
            onDragMove={props.onDragMove}
            onDragEnd={props.onDragEnd}
            
        >
            {
                //onWall部分
                useMemo(() => {
                    return (props.openDirect === "left" || props.openDirect === "right") ?
                        (
                            <Group 
                                ref={props.setShapeRef}>
                                <Rect
                                    x={-onWallThickness / 2}
                                    y={-onWallWidth / 2}
                                    height={onWallWidth}
                                    width={onWallThickness / 2}
                                    {...frameStyle}
                                />
                                <Rect
                                    x={0}
                                    y={-onWallWidth / 2}
                                    height={onWallWidth}
                                    width={onWallThickness / 2}
                                    {...frameStyle}
                                />
                            </Group>
                        ) : (
                            <Group 
                                ref={props.setShapeRef}>
                                <Rect
                                    x={-onWallWidth / 2}
                                    y={-onWallThickness / 2}
                                    width={onWallWidth}
                                    height={onWallThickness / 2}
                                    {...frameStyle}
                                />
                                <Rect
                                    x={-onWallWidth / 2}
                                    y={0}
                                    width={onWallWidth}
                                    height={onWallThickness / 2}
                                    {...frameStyle}
                                />

                            </Group>
                        )
                }, [frameStyle,props.openDirect, props.anchor, props.onWallWidth, props.onWallThickness])
            }
            {
                /* 开扇 */
                useMemo(() => {
                    return (
                        <Group
                            ref={doorRef}
                            
                        >
                        {
                            (() => {
                                const onWallThickness = 0; //墙中间
                                if (props.onWallType === "onwall-single-door") {
                                    const openClockwise = props.openClockwise;
                                    const openDirect = props.openDirect;
                                    if (openDirect === "left" || openDirect === "right") {
                                        return openDirect === 'left' ? (
                                            openClockwise ? (
                                                <Arc
                                                    x={-onWallThickness / 2}
                                                    y={-onWallWidth / 2}
                                                    angle={90}
                                                    innerRadius={0}
                                                    outerRadius={onWallWidth}
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={90}
                                                    {...doorStyle}
                                                    // strokeScaleEnabled={false}
                                                />
                                            ) : (
                                                <Arc
                                                    x={-onWallThickness / 2}
                                                    y={onWallWidth / 2}
                                                    angle={90}
                                                    innerRadius={0}
                                                    outerRadius={onWallWidth}
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={180}
                                                    // strokeScaleEnabled={false}
                                                    {...doorStyle}
                                                />
            
                                            )
                                        ) : (
                                            openClockwise ? (
                                                <Arc
                                                    x={onWallThickness / 2}
                                                    y={onWallWidth / 2}
                                                    angle={90}
                                                    innerRadius={0}
                                                    outerRadius={onWallWidth}
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={-90}
                                                    // strokeScaleEnabled={false}
                                                    {...doorStyle}
                                                />
            
                                            ) : (
                                                <Arc
                                                    x={onWallThickness / 2}
                                                    y={-onWallWidth / 2}
                                                    angle={90}
                                                    innerRadius={0}
                                                    outerRadius={onWallWidth}
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={0}
                                                    // strokeScaleEnabled={false}
                                                    {...doorStyle}
                                                />
                                            )
            
                                        )
                                    } else {
                                        return openDirect === 'top' ? (
                                                    
                                            openClockwise ?  (
                                                <Arc
                                                    y={-onWallThickness / 2}
                                                    x={onWallWidth / 2}
                                                    angle={90} 
                                                    innerRadius={0} 
                                                    outerRadius={onWallWidth} 
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={180}
                                                    // strokeScaleEnabled={false}
                                                    
                                                    {...doorStyle}
                                                />
            
                                            ) : (
                                                <Arc
                                                    y={-onWallThickness / 2}
                                                    x={-onWallWidth / 2}
                                                    angle={90} 
                                                    innerRadius={0} 
                                                    outerRadius={onWallWidth} 
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={-90}
                                                    strokeScaleEnabled={false}
                                                    
                                                    {...doorStyle}
                                                />
                                            )
                                        ) : (
                                            openClockwise ? (
                                                <Arc
                                                    y={onWallThickness / 2}
                                                    x={-onWallWidth / 2}
                                                    angle={90} 
                                                    innerRadius={0} 
                                                    outerRadius={onWallWidth}
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={0}
                                                    strokeScaleEnabled={false}
                                                    
                                                    {...doorStyle}
                                                />
            
                                            ) : (
                                                <Arc
                                                    y={onWallThickness / 2}
                                                    x={onWallWidth / 2}
                                                    angle={90} 
                                                    innerRadius={0} 
                                                    outerRadius={onWallWidth} 
                                                    // stroke={"lightgray"}
                                                    // strokeWidth={2}
                                                    // fill="#ffffffaa"
                                                    rotation={90}
                                                    strokeScaleEnabled={false}
                                                    
                                                    {...doorStyle}
                                                />
                                            )
            
                                        )
                                    }
                                }

                            })()
                        }
                        
                        </Group>
                    )
                }, [ doorStyle, props.onWallWidth, props.onWallThickness, props.openDirect, props.openClockwise])
            }
        </Group>
    )

})