import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMoveX, pathMoveY } from "../../../util/graph";
import { corner2 } from "../../coners/corner2";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { rectBoard } from "../general-board";

export const _10diwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean,
    extraConfig?: any
 ): [Path[], Path[]]  => {
    //左下角
    const cornerBLSize = {width: 0, height: 0};
    let cornerBL: Path[] = []; // = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
    let cornerBLW: number = cornerBLSize.width;
    let cornerBLH: number = cornerBLSize.height;
    if(cornerType === "youhujiao") {
        cornerBL = corner2(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wujiao") {
        cornerBL = corner2WaiWujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner2WaiWuhujiao(dx, -dy, startX, startY, corner, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = pathMoveY(cornerBL, DirectY.UP, cornerBLSize.height);

    }
    //右下角
    const cornerBRSize = {width: 0, height: 0};
    let cornerBR: Path[] = [];
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner2(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }else if(cornerType === "wujiao") {
        cornerBR = corner2WaiWujiao(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner2WaiWuhujiao(-dx, -dy, startX, startY,corner,  cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
        cornerBR = pathMoveY(cornerBR, DirectY.UP, cornerBRSize.height);

    }
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = [];// = corner2(-dx, dy, startX, startY,corner,  cornerTRSize);
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner2(-dx, dy, startX, startY,corner,  cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else if(cornerType === "wujiao") {
        cornerTR = corner2WaiWujiao(-dx, dy, startX, startY,corner,  cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else if(cornerType === "wuhujiao") {
        cornerTR = corner2WaiWuhujiao(-dx, dy, startX, startY,corner,  cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    }
    
    //右上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = []; // = corner2(dx, dy, startX, startY,corner,  cornerTLSize);
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner2(dx, dy, startX, startY,corner,  cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wujiao") {
        cornerTL = corner2WaiWujiao(dx, dy, startX, startY,corner,  cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY,corner,  cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }


    const boardConfig: any = {
        middleware: {
            top: true,
            left: true,
            right: true,
            bottom: false,
        }
    }
    let paths;
    if(corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    }else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height, 
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    
    if(!extraConfig?.禁止长度增加自动加孔) {
        if (width >= 500) {
            // paths.push(
            //     //顶部孔
            //     {
            //         type: Circle,
            //         params: [
            //             startX + dx * width / 2, startY + dy * height - dy * 45.028293,
            //             2.61,
            //         ]
            //     }, {
            //         type: Circle,
            //         params: [
            //             startX + dx * width / 2, startY + dy * height - dy * 45.028293 - dy * 15.75,
            //             3.11,
            //         ]
            //     }, {
            //         type: Circle,
            //         params: [
            //             startX + dx * width / 2, startY + dy * height - dy * 45.028293 - dy * 15.75 - dy * 15.75,
            //             2.61,
            //         ]
            //     },
            //     //底部孔
            //     {
            //         type: Circle,
            //         params: [
            //             startX + dx * width / 2, startY + dy * 45.028293,
            //             2.61,
            //         ]
            //     },
            //     {
            //         type: Circle,
            //         params: [
            //             startX + dx * width / 2, startY + dy * 45.028293 + dy * 15.75,
            //             3.11,
            //         ]
            //     },
            //     {
            //         type: Circle,
            //         params: [
            //             startX + dx * width / 2, startY + dy * 45.028293 + dy * 15.75 + dy * 15.75,
            //             2.61,
            //         ]
            //     }
            // );
        }
    }
    return [
        paths,
        ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL),
        // corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
    ]
}