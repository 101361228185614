import { Form, FormInstance, Input, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import { RadioInput } from "./components/radio-input";


//画布大小 (m)
export const CANVAS_SIZE = 200;
//窗口画布大小（m)
const CANVAS_WINDOW_SIZE = 12;

//单位转换
export const m2px  = 1000 / CANVAS_WINDOW_SIZE; //1000像素对30米
export const px2m = 1 / m2px;

// export const win2canvas = (CANVAS_SIZE * m2px) / window.innerWidth;
// export const canvas2win = window.innerWidth / (CANVAS_SIZE * m2px); 


export interface MetaProperty {
    key: string,
    label: string,
    defaultValue: any,
    toDisplayValue?: (value: any) => string,
    toRealValue?: (value: string) => any,
    toDisplayValueForNumber?: (value: number) => number,
    toRealValueForNumber?: (value: number) => number,
    valueType: "integer" | "string" | "boolean" | "enum:single" | "enum:single#with:integer" | "enum:multi",
    prefix?: string,
    suffix?: string,
    options?: {
        key: string,
        value: any,
        label?: string,
    }[],
    placeholder?: string,
    rule: {
        key: "min" | "max" | "enum" | "pattern" | "required" | "message" | "validator",
        value: any
    }[]

}

export class AbstractMeta<T extends object> {

    label: string;
    store: T;
    properties: MetaProperty[];
    onStoreChange?: (store: T) => void;

    constructor(label: string, properties: MetaProperty[], onStoreChange?: (store: T) => void) {
        this.label = label;
        this.properties = properties;
        this.onStoreChange = onStoreChange;
    }

    $getStore() {
        return this.store;
    }

    initStore() {
        if (!this.properties) {
            throw new Error("properties is not initialized");
        }
        if (this.store) {
            return this.store;
        }
        const store = {} as T;
        this.properties.forEach(p => {
            store[p.key] = p.defaultValue;
        });
        this.store = store;
        this.onStoreChange && this.onStoreChange(this.store);
        return this.store;
    }

    updateStore(key: string, value: any) {
        if (!this.properties) {
            throw new Error("properties is not initialized");
        }
        if (!this.store) {
            this.store = { [key]: value } as T;
        } else {
            this.store = {
                ...this.store,
                [key]: value
            }
        }
        this.onStoreChange && this.onStoreChange(this.store);
        return this.store;
    }

    updateStoreBatch(data: Partial<T>) {

        if (!this.properties) {
            throw new Error("properties is not initialized");
        }
        if (!this.store) {
            this.store = data as T;
        } else {
            this.store = {
                ...this.store,
                ...data
            }
        }
        this.onStoreChange && this.onStoreChange(this.store);
        return this.store;
    }

    

    getEditForm(store: T, onStoreChange: (store: T) => void, options?: {
        disabledKeys: (string)[]
    }) {

        const ref = useRef<FormInstance>();
        const initValues = () => {
            const values: {[key: string]: any} = {};
            for (const key in store) {
                const property = this.properties.find(p => p.key === key);
                if(!property) {
                    continue ;
                }
                //real -> display
                if(property.toDisplayValue) {
                    values[key] = property.toDisplayValue(store[key]);
                }else if(property.toDisplayValueForNumber) {
                    values[key] = property.toDisplayValueForNumber(Number(store[key]));
                }else {
                    if(property.valueType === "integer") {
                        values[key] = String(store[key]);
                    }else {
                        values[key] = store[key];
                    }
                }
            }
            return values;
        }
        
        const displayValueToRealValue: (values: {[key: string]: any}) => T = (values) => {
            const realValues = {} as T;
            for (const key in values) {
                const property = this.properties.find(p => p.key === key);
                if(!property) {
                    continue ;
                }
                //display -> real
                if(property.toRealValue) {
                    realValues[key] = property.toRealValue(values[key]);
                }else if(property.toRealValueForNumber) {
                    realValues[key] = property.toRealValueForNumber(values[key]);
                } else {
                    if(property.valueType === "integer") {
                        realValues[key] = Number(values[key]);
                    }else {
                        realValues[key] = values[key];
                    }
                }   
                
            }
            return realValues;
        }
        const updateFormValues = (allValues:  {[key: string]: any}) => {
            for (const k in allValues) {
                const v = allValues[k];
                const p = this.properties.find(p => p.key === k);
                if(!p) {
                    continue ;
                }
                const type = p.valueType;
                if (type === 'integer') {
                    if(v) {
                        const numV = Number(v);
                        if (!isNaN(numV)) {
                            allValues[k] = Number(v);
                        }else {
                            if(store) {
                                allValues[k] = store[k];
                            }
                        }
                    }
                }else {
                    allValues[k] = v;
                }
            }
            return allValues;
        }
        const [initialValues] = useState(initValues);
        

        useEffect(() => {
            if(store) {
                ref.current?.setFieldsValue(initValues());
            }
        }, [store]);


        return () => {
            
            return (
                <Form
                    size="small"
                    ref={ref}
                    initialValues={initialValues}
                    
                    onFinish={(values) => {
                        console.log(values);
                        onStoreChange && onStoreChange(displayValueToRealValue(values));
                    }}
                    onValuesChange={(changedValue, allValues) => {
                        // console.log(changedValue);
                        //有些组件没有按回车键提交，所以这里需要手动提交
                        let shouldSubmit = false;
                        for (const key in changedValue) {
                            const p = this.properties.find((p) => p.key === key);
                            if(p?.options) {
                                shouldSubmit = true;
                                break;
                            }
                        }
                        console.log("shouldSubmit");
                        
                        ref.current.setFieldsValue(updateFormValues(allValues));
                        if(shouldSubmit) {
                            ref.current?.submit();
                        }
                    }}
                >
                    {
                        this.properties.map(p => {
                            return (
                                <Form.Item name={p.key} label={p.label}>
                                    {
                                        p.valueType === "integer" || p.valueType === 'string' ? (
                                            <Input
                                                disabled={options?.disabledKeys.includes(p.key)}
                                                placeholder={p.placeholder}
                                                autoComplete="off"
                                                suffix={p.suffix}
                                                prefix={p.prefix}
                                                onKeyDownCapture={(e) => {
                                                    e.stopPropagation();
                                                    if(e.key === "Enter") {
                                                        e.currentTarget.blur();
                                                        // ref.current?.submit();
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    e.currentTarget.select();
                                                }}
                                                onBlur={() => {
                                                    ref.current?.submit();
                                                }}
                                                // onKeyDown={(e) => {
                                                //     else if(e.key === 'Escape') {
                                                //         // ref.current?.resetFields();
                                                //     }
                                                // }}

                                                onInput={(e) => {
                                                    console.log(e.currentTarget.value);
                                                }}
                                                
                                            />
                                        ) : p.valueType === 'enum:single' ? (
                                            <Radio.Group 
                                                disabled={options?.disabledKeys.includes(p.key)}>
                                                {
                                                    p.options?.map(o => {
                                                        return (
                                                            <Radio key={o.key} value={o.value}>{o.label}</Radio>
                                                        )
                                                    })
                                                }
                                            </Radio.Group>
                                        ) : p.valueType === 'enum:single#with:integer' ? (
                                            <RadioInput
                                                disabled={options?.disabledKeys.includes(p.key)}
                                                options={p.options}
                                                suffix={p.suffix}
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            )
                        })
                    }
                </Form>
            )
        }
    }

}


//除了字符串转换外，还要考虑单位换算
export const px2DisplayValue = (value: number): string => {
    return Math.round(value * px2m * 1000).toString();
}

export const mm2RealValue = (value: string): number => {
    return Number(value) / 1000 / px2m;
}

/**
 * 小于0的值保持不变
 * @param value 
 */
export const mm2RealValueForNumber_KeepNegative = (value: number) => {
    if(value < 0) {
        return value;
    }
    return mm2RealValue(String(value));
}

export const px2DisplayValueForNumber_KeepNegative = (value: number) => {
    if(value < 0) {
        return value;
    }
    return Number(px2DisplayValue(value));
}