import { useContext, useEffect, useState } from "react";
import { FloorPlannerContext } from "../../../context-reducer";
import { DrawOnWallType, DrawedOnWallMeta, DrawedStructMeta, OnWallData, StructData, useDrawedArea } from "../../../store";

/**
 * 画墙用到的store
 * @returns 
 */
export const useOnWallDrawing = () => {

    const { state, dispatch } = useContext(FloorPlannerContext);
    const [ drawOnWallType, setDrawOnWallType] = useState<DrawOnWallType>(null);

    const drawedArea = useDrawedArea();

    useEffect(() => {
        const drawOnWallType = state.drawType?.indexOf("onwall") === 0 ? state.drawType as DrawOnWallType : null;
        setDrawOnWallType(drawOnWallType);
    }, [state.drawType]);

    
    const pushDrawed = (drawed: DrawedOnWallMeta) => {
        dispatch({
            type: "pushDrawed",
            payload: drawed
        })
    }

    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        })
    }

    return {
        wallThickness: state.wallThickness,
        floorThickness: state.floorThickness,
        drawOnWallType,
        brushPos: state.brushPos,
        drawedArea,
        pushDrawed,
        cancelDraw
    }


}

/**
 * 编辑墙的store
 * @returns 
 */
export const useOnWallEdit = () => {
    const {  state, dispatch } = useContext(FloorPlannerContext);

    const [ targetData, setTargetData ] = useState<OnWallData>(null);
    const drawedArea = useDrawedArea();

    useEffect(() => {
        if(state.editTarget?.type?.indexOf("onwall") === 0) {
            setTargetData(state.editTarget.data as OnWallData);
        }else {
            setTargetData(null)
        }
    }, [state.editTarget]);

    const cancelDraw = () => {
        dispatch({
            type: "cancelDraw"
        });
    }

    const setStageCursor = (cursor: "move" | null) => {
        dispatch({
            type: "setStageCursor",
            payload: cursor
        })
    }

    const updateDrawed = (drawed: DrawedOnWallMeta) => {
        dispatch({
            type: "updateDrawed",
            payload: drawed
        })
    }

    const updateDrawedToList = (drawed: DrawedStructMeta) => {
        const drawedList = state.drawedList.map((d) => {
            if(d.data.id === drawed.data.id) {
                return drawed;
            }
            return d;
        });
        dispatch({
            type: "updateDrawedList",
            payload: drawedList
        })
    }

    return { targetData, drawedArea, cancelDraw, setStageCursor, updateDrawed, updateDrawedToList };

}
