import { corner1Sanheyi } from "./corner1-sanheyi";
import { pathMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate2 } from "../../../util/graph";
import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";

export const corner4Sanheyi = (_dx: DirectX, _dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const dx = 1;
    const dy = 1;
    const size: {width: number, height: number} = {width: 0, height: 0};
    let  paths = corner1Sanheyi(dx, dy, startX, startY, true, size);
    paths = pathRotate2(paths, startX, startY, 90);
    paths = pathMirrorY(paths, startX);
    paths = paths.slice(0, 28);
    let  paths2 = corner1Sanheyi(-dx, -dy, startX, startY, true, size);
    paths2 = paths2.slice(0, 28);
    paths2 = pathMoveX(paths2, DirectX.RIGHT, size.height + dx * 0.02);
    paths2 = pathMoveY(paths2, DirectY.UP, size.height + dy * 0.02);
    paths = paths.concat(paths2);
    
    let width = size.height;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startY);
    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
    }
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width + dx * 0.02;
        sizeRes.height = width + dx * 0.02;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}