import { Button, Checkbox, Divider, Popconfirm, Progress, Radio, Space, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { CheckOutlined, ClearOutlined, CloseOutlined, DeliveredProcedureOutlined, ReloadOutlined, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { NestRequest, Material, CupboardBroad, NestSheet, NestResult, NestSheetSettings } from "../../../../../@interface";
import { request } from "../../../utils2/request";
import { apis } from "../../../utils2/apis";
import { wsHost, wsHost2 } from "../../../utils/apis";
import { useNavigate, useParams } from "react-router-dom";
import { SheetSelect, SheetSizeEditor } from "./SheetSelect";

interface NestTask {
    id: string | number,
    taskRequestId: string;
    uniqueSymbol: string,
    material: Material,
    setting: NestSheetSettings,
    boards: CupboardBroad[],
    boardsTotalArea: number,
    status: "未开始" | "正在进行" | "已完成" | "排队中" | "已中断" | "正在准备",
    tip?: any;
    error?: string;
    nestResult?: NestRequest
    progress?: number;
    algorithm?: "fast" | "pro";
    nestTime: number;
    utilizationRate: number;

}
let resultDataTemp = null;
let setTimeoutTemp; //延迟若干时间后再跳到下一页，期间如果手动点击，则清除次定时任务
export const SmartNestWidget = (props: {
    data: NestRequest,
    usedMaterialIds: { [materialId: string]: boolean },
    allBoards: CupboardBroad[],
    selectedCupboardIds?: string[],
    onNestStart: () => void,
    onNestFinish: (data: NestRequest, materialId?: string, downloadDirectly?: boolean) => void,
}) => {

    const { data, usedMaterialIds, selectedCupboardIds, allBoards } = props;
    const [materialNestSettings, setMaterialNestSettings] = useState<NestSheetSettings[]>([]);
    const [nesting, setNesting] = useState(false);
    const [tasks, setTasks] = useState<NestTask[]>([]);
    const [allNestTime, setAllNestTime] = useState(0);
    const [downloadDirectly, setDownloadDirectly] = useState(false);
    const [ready, setReady] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let localMaterialNestSettingsJSON = localStorage.getItem("SK_MATERIAL_NEST_SETTINGS");
        let _materialNestSettings: NestSheetSettings[];
        if (localMaterialNestSettingsJSON) {
            _materialNestSettings = JSON.parse(localMaterialNestSettingsJSON);
        } else {
            _materialNestSettings = [];
        }
        let settingsHasChanged = false;
        data.materials.forEach((m) => {
            let nestSettings = _materialNestSettings.find((mns) => mns.materialId === m.id);
            if (!nestSettings) {
                nestSettings = {
                    materialId: m.id,
                    sheetHeight: 2438,
                    sheetWidth: 1218,
                    clipSpace: 10,
                    rotation: true
                }
                _materialNestSettings.push(nestSettings);
                settingsHasChanged = true;
            }
        });
        if (settingsHasChanged) {
            localStorage.setItem("SK_MATERIAL_NEST_SETTINGS", JSON.stringify(_materialNestSettings));
        }
        setMaterialNestSettings(_materialNestSettings);
        nestSettingsInit(data, _materialNestSettings);
        taskInit(data, _materialNestSettings);
        setReady(true);

    }, [data, selectedCupboardIds, allBoards]);

    const taskInit = (data: NestRequest, settings: NestSheetSettings[]) => {
        let tasks: NestTask[] = [];
        let localTasks = localStorage.getItem("SK_NEST_TASKS_TEMP");
        let useLocalTasks = false;
        if (params.taskId && localTasks) {
            const storeTasks = JSON.parse(localTasks);
            if (storeTasks.taskId === params.taskId) {
                tasks = storeTasks.tasks;
                resultDataTemp = storeTasks.resultDataTemp;
                tasks.forEach((t) => {
                    if(t.status !== '已完成') {
                        t.status = '未开始';
                        t.progress = 0;
                        t.tip = "";
                    }
                })
                useLocalTasks = true;
            }
        }
        if (!useLocalTasks) {
            data.cupboards.filter((c) => selectedCupboardIds.includes(c.id)).forEach((cupboard) => {
                cupboard.boards.forEach((board) => {
                    if (board.notNest) {
                        return;
                    }
                    let task = tasks.find((t) => t.id === board.materialId);
                    if (!task) {
                        const material = data.materials.find((m) => m.id === board.materialId);
                        const setting = settings.find((s) => s.materialId === material.id);
                        task = {
                            id: board.materialId,
                            taskRequestId: null,
                            uniqueSymbol: data.id + "-" + board.materialId + "-" + Date.now(),
                            material: material,
                            setting,
                            boards: [],
                            boardsTotalArea: 0,
                            status: "未开始",
                            algorithm: "pro",
                            nestTime: 0,
                            utilizationRate: 0
                        }
                        tasks.push(task);
                    }
                    task.boards.push(board);
                    task.boardsTotalArea += board.width * board.height;
                })
            });
            tasks.forEach((t) => {
                //单一板类型，用快速排板的方式排板
                const typeCount = new Set(Array.from(t.boards.map((b) => `${b.cate}_${b.type}`))).size;
                if (typeCount === 1) {
                    t.algorithm = 'fast';
                }
            });

        }
        const downloadDirectly = localStorage.getItem("SK_DOWNLOAD_RIGHT_NOW");
        if (downloadDirectly === 'true') {
            setDownloadDirectly(true);
        }
        setTasks(tasks);
    }

    const nestSettingsInit = (data: NestRequest, materialNestSettings) => {
        for (let i = 0; i < materialNestSettings.length; i++) {
            const settings = materialNestSettings[i];

            const sheetWidthTemp = Number(settings.sheetWidth);
            if (isNaN(sheetWidthTemp) || sheetWidthTemp <= 0) {
                message.warning("宽度设置有误");
                return;
            }
            const sheetHeightTemp = Number(settings.sheetHeight);
            if (isNaN(sheetHeightTemp) || sheetHeightTemp <= 0) {
                message.warning("高度设置有误");
                return;
            }
            const clipSpaceTemp = Number(settings.clipSpace);
            if (isNaN(clipSpaceTemp) || clipSpaceTemp < 0) {
                message.warning("间隙设置有误");
                return;
            }
            settings.sheetWidth = sheetWidthTemp;
            settings.sheetHeight = sheetHeightTemp;
            settings.clipSpace = clipSpaceTemp;
            settings.rotation = Boolean(settings.rotation);
        }
        if (!data.nestConfig) {
            data.nestConfig = {
                materialNestSettings: materialNestSettings
            };
        } else {
            data.nestConfig.materialNestSettings = materialNestSettings;
        }
    }


    const onStartNest2 = async () => {
        const _data: NestRequest = JSON.parse(JSON.stringify(data));
        _data.cupboards = _data.cupboards.filter((c) => selectedCupboardIds.includes(c.id));
        // nest2Ref.current?.start(_data, materialNestSettings);
        nestSettingsInit(_data, materialNestSettings);
        await startNest(_data);
    }



    const onMaterialNestSettingsChange = (settings: NestSheetSettings, key: string, value: any) => {

        settings[key] = value;
        setMaterialNestSettings([...materialNestSettings]);
        const task = tasks.find((t) => t.material.id === settings.materialId);
        if (task?.status === '已完成' || task?.status === '已中断') {
            task.status = "未开始";
            task.progress = 0;
            task.setting = settings;
        }
        setTasks([...tasks]);
        localStorage.setItem("SK_MATERIAL_NEST_SETTINGS", JSON.stringify(materialNestSettings));
    }

    const [nestMaterials, setNestMaterials] = useState<Material[]>([]);

    useEffect(() => {
        setNestMaterials(data?.materials.filter((m) => usedMaterialIds[m.id]) || []);
    }, [data, usedMaterialIds]);




    const startNest = async (data: NestRequest) => {
        const startTime = Date.now();
        tasks.forEach((t) => {
            t.status = '正在准备';
        });
        setTasks([...tasks]);
        const asyncs: Promise<unknown>[] = [];
        tasks.forEach((task, i) => {
            if (task.status === '已完成') {
                return;
            }
            if (task.algorithm === 'pro') {
                asyncs.push(startNest_pro(data, task));
            } else {
                asyncs.push(startNest_fast(data, task));
            }
        });
        await Promise.all(asyncs);
        const endTime = Date.now();
        const spendTime = endTime - startTime;
        setAllNestTime(spendTime);
        resultDataTemp = data;
        // props.onNestFinish(data, null, downloadDirectly);
    }

    const reportLog = (nestLog: any) => {
        console.log(nestLog);
        let localCurOrder = localStorage.getItem("SK_CURRENT_ORDER");
        let order;
        if (localCurOrder) {
            order = JSON.parse(localCurOrder);
        }
        request.post(apis.order + `/nest/log/report`, {
            orderId: order?.id || data.order?.orderNo,
            nestLog
        }).catch(e => console.log(e));
    }

    /**
     * 快速排班
     * @param data 
     * @param task 
     */
    const startNest_fast = async (data: NestRequest, task: NestTask) => {
        let nestLog = {
            startTime: Date.now(),
            nestRequestId: data.id,
            materialId: task.material.id,
            materialName: task.material.name,
            clipSpace: task.setting.clipSpace,
            rotation: task.setting.rotation,
            sheetHeight: task.setting.sheetHeight,
            sheetWidth: task.setting.sheetWidth,
            boardsLength: task.boards.length,
            nestResult: null,
            algorithm: "fast",
            messgae: [],
            latestProgress: 0,
            retryTimes: 0,
            spendTime: 0,
        }
        if (task.boards.length === 0) {
            nestLog.messgae.push({
                timestamp: Date.now(),
                messgae: "没有需要排料的板"
            });
            return nestLog;
        }
        return new Promise((resolve, reject) => {
            const startTime = nestLog.startTime;
            if (task.status !== '正在准备') {
                task.status = '正在准备';
                setTasks([...tasks]);
            }
            nestLog.messgae.push({
                timestamp: Date.now(),
                messgae: "开始排板"
            });
            const data2 = JSON.parse(JSON.stringify(data)) as (typeof data);
            const websocket = new WebSocket(wsHost + `/ws/nest/packer`);
            websocket.onmessage = (e) => {
                const messgae = e.data.toString();
                const json = JSON.parse(messgae);
                if (json.event === 'packer:ready') {
                    nestLog.messgae.push({
                        timestamp: Date.now(),
                        messgae: "与服务器通信准备就绪（packer:ready）"
                    });
                    data2.materials = [task.material];
                    websocket.send(JSON.stringify({
                        event: "job:start",
                        data: data2
                    }));

                } else if (json.event === 'packer:progress') {
                    task.progress = json.data;
                    task.status = '正在进行';
                    nestLog.latestProgress = json.data;
                    nestLog.messgae.push({
                        timestamp: Date.now(),
                        messgae: `正在排板，进度${task.progress}`
                    });
                    setTasks([...tasks]);
                } else if (json.event === 'packer:finished') {
                    task.progress = 100;
                    task.status = '已完成';
                    const nestResults = json.data;
                    nestLog.nestResult = json.data;
                    if (!data.nestResult) {
                        data.nestResult = [];
                    }
                    nestResults.forEach((nestResult) => {
                        let dataNestResultIndex = data.nestResult.findIndex((n) => n.materialId === nestResult.materialId);
                        if (dataNestResultIndex > -1) {
                            data.nestResult[dataNestResultIndex] = nestResult;
                        } else {
                            data.nestResult.push(nestResult);
                        }
                    });
                    data.nestResult.sort((a, b) => a.materialId > b.materialId ? 1 : -1);
                    const endTime = Date.now();
                    const spendTime = endTime - startTime;
                    task.nestTime = spendTime / 1000;
                    nestLog.spendTime = spendTime;
                    nestLog.messgae.push({
                        timestamp: Date.now(),
                        messgae: `排板已完成`
                    });
                    setTasks([...tasks]);
                    websocket.close();
                    reportLog(nestLog);
                    resultDataTemp = data;
                    resolve(nestLog);

                } else if (json.event === 'packer:error') {
                    // task.progress = 100;
                    task.status = '已中断';
                    task.error = json.data;
                    nestLog.messgae.push({
                        timestamp: Date.now(),
                        messgae: `排板中断（${task.error}）`
                    });
                    setTasks([...tasks]);
                    websocket.close();
                    reportLog(nestLog);
                    resolve(false);
                }
            };
            websocket.onclose = () => {
                resolve(false);
            }
        });
    }

    /**
     * 高级排板
     */
    const startNest_pro = async (data: NestRequest, task: NestTask) => {
        let nestLog = {
            startTime: Date.now(),
            nestRequestId: data.id,
            materialId: task.material.id,
            materialName: task.material.name,
            clipSpace: task.setting.clipSpace,
            rotation: task.setting.rotation,
            sheetHeight: task.setting.sheetHeight,
            sheetWidth: task.setting.sheetWidth,
            boardsLength: task.boards.length,
            nestResult: null,
            algorithm: "pro",
            messgae: [],
            latestProgress: 0,
            retryTimes: 0,
            spendTime: 0,
        }
        if (task.boards.length === 0) {
            nestLog.messgae.push({
                timestamp: Date.now(),
                messgae: "没有需要排料的板"
            });
            return nestLog;
        }
        return new Promise((resolve, reject) => {
            const startTime = nestLog.startTime;
            const tryDo = () => {
                nestLog.messgae.push({
                    timestamp: Date.now(),
                    messgae: "开始排板"
                });
                if (task.status !== '正在准备') {
                    task.status = '正在准备';
                    task.progress = 0;
                    task.tip = "";
                    setTasks([...tasks]);
                }
                const websocket = new WebSocket(wsHost2 + "/2dnest/ws");
                let timeout;
                // timeout = setTimeout(() => {
                //     if (websocket) {
                //         websocket.close(1000, "retry");
                //         setTimeout(() => {
                //             tryDo(false)
                //         }, 100);
                //     }
                //     timeout = undefined;
                // }, 1500);
                websocket.onopen = () => {
                    websocket.send(JSON.stringify({
                        event: "user:subscribe",
                        data: {
                            userId: request.getLoginInfo()?.accountInfo.id,
                        }
                    }));
                }
                websocket.onmessage = (e) => {
                    const messgae = e.data.toString();
                    const json = JSON.parse(messgae);
                    if (json.event === 'welcome') {
                        nestLog.messgae.push({
                            timestamp: Date.now(),
                            messgae: "与服务器通信准备就绪（welcome）"
                        });
                        const nestSettings = task.setting;
                        const sheetUsageEstimate = Math.floor(task.boardsTotalArea / (0.6/* 最低利用率 */ * nestSettings.sheetWidth * nestSettings.sheetHeight)) + 1;
                        const param = {
                            sheet: {
                                width: nestSettings.sheetWidth,
                                height: nestSettings.sheetHeight,
                                quantity: sheetUsageEstimate,
                                rotatable: nestSettings.rotation,
                            },
                            rects: task.boards.map((b, i) => {
                                return {
                                    id: i.toString(),
                                    width: b.width,
                                    height: b.height,
                                }
                            }),
                            space: nestSettings.clipSpace
                        }
                        websocket.send(JSON.stringify({
                            event: "job:create",
                            data: param
                        }));
                    }else if (json.event === 'job:created') {
                        task.status = '正在进行';
                        setTasks([...tasks]);
                        nestLog.messgae.push({
                            timestamp: Date.now(),
                            messgae: `排板任务已创建`
                        });
                        setTasks([...tasks]);
                        
                    }  else if(json.event === 'job:queuing') {
                        task.status = '排队中';
                        task.tip = '排队中，等待' + json.data.waitLength + "位"
                        setTasks([...tasks]);
                        nestLog.messgae.push({
                            timestamp: Date.now(),
                            messgae: `排队中`
                        });
                        setTasks([...tasks]);
                        if(timeout) {
                            clearTimeout(timeout);
                        }
                    }else if (json.event === 'job:progress') {
                        if (timeout) {
                            clearTimeout(timeout);
                           
                        }
                        timeout = setTimeout(() => {
                            if (websocket) {
                                websocket.close(1000, "retry");
                                setTimeout(() => {
                                    tryDo()
                                }, 100);
                            }
                            timeout = undefined;
                        }, 3000);
                        task.status ='正在进行';
                        task.progress = parseInt(json.data);
                        setTasks([...tasks]);
                        nestLog.latestProgress = json.data;
                        nestLog.messgae.push({
                            timestamp: Date.now(),
                            messgae: `正在排板，进度${task.progress}`
                        });
                    } else if (json.event === 'job:finished') {
                        task.progress = 100;
                        task.status = '已完成';
                        nestLog.latestProgress = 100;
                        const nestResult: NestResult = {
                            materialId: task.id,
                            sheets: []
                        }
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        if (json.data.length === 0) {
                            nestLog.messgae.push({
                                timestamp: Date.now(),
                                messgae: `排板完成，但结果为空，准备重新提交任务`
                            });
                            task.progress = 0;

                            //排板没结果，重试一次
                            websocket.close(1000, "retry");
                            setTimeout(() => {
                                tryDo();
                            }, 100);
                            return;
                        }
                        for (let i = 0; i < json.data.length; i++) {
                            const jobSheet = json.data[i];
                            const sheetTemp: NestSheet = {
                                id: i.toString(),
                                height: jobSheet.height,
                                width: jobSheet.width,
                                boards: [],
                                tagRects: [],
                                tags: []
                            }
                            nestResult.sheets.push(sheetTemp);
                            for (let j = 0; j < jobSheet.rects.length; j++) {
                                const rect = jobSheet.rects[j];
                                const placedBoard = {
                                    nestId: `${i}-${rect.id}`,
                                    board: null,
                                    width: rect.width,
                                    height: rect.height,
                                    initRotate: false,
                                    rotate: false,
                                    initX: 0,
                                    initY: 0,
                                    customX: 0,
                                    customY: 0,
                                    customSheetIndex: i
                                }
                                const board = task.boards[Number(rect.id)];
                                if (!board) {
                                    continue;
                                }
                                placedBoard.board = board;
                                placedBoard.initRotate = rect.rotated;
                                placedBoard.rotate = placedBoard.initRotate;
                                placedBoard.initX = rect.startX;
                                placedBoard.initY = rect.startY;
                                placedBoard.customX = placedBoard.initX;
                                placedBoard.customY = placedBoard.initY;
                                sheetTemp.boards.push(placedBoard);
                                const sheetNo = i + 1;
                                sheetTemp.tagRects.push({
                                    x: placedBoard.customX,
                                    y: placedBoard.customY,
                                    height: placedBoard.height,
                                    width: placedBoard.width,
                                    number: sheetNo.toString(),
                                    id: board.nestId
                                });
                                sheetTemp.tags.push({
                                    body: {
                                        list: [{
                                            content: `地址：${data.order.address}`,
                                        }, {
                                            content: `橱柜：${board.cupboardName}`
                                        }, {
                                            content: `编号：${board.nestId}`,
                                        }, {
                                            content: `板件：${board.name}_${board.type}`,
                                        }, {
                                            content: `材质：${task.material.name}`,
                                        }, {
                                            content: `尺寸：${board.width}mm × ${board.height}mm`
                                        }, {
                                            content: `所属大板：${sheetNo}/${json.data.length}`
                                        }],
                                        $rects: {
                                            x: placedBoard.customX,
                                            y: placedBoard.customY,
                                            height: placedBoard.height,
                                            width: placedBoard.width,
                                            number: sheetNo.toString(),
                                            id: board.nestId
                                        },
                                    },
                                    footer: {
                                        content: `单号：${data.order.orderNo}`,
                                    },
                                    extra: {
                                        qrcodeUrl: board.qrcodeUrl,
                                    }
                                })
                            }
                        }
                        nestLog.nestResult = nestResult;
                        if (!data.nestResult) {
                            data.nestResult = [];
                        }
                        let dataNestResultIndex = data.nestResult.findIndex((n) => n.materialId === nestResult.materialId);
                        if (dataNestResultIndex > -1) {
                            data.nestResult[dataNestResultIndex] = nestResult;
                        } else {
                            data.nestResult.push(nestResult);
                        }
                        data.nestResult.sort((a, b) => a.materialId > b.materialId ? 1 : -1);
                        const endTime = Date.now();
                        const spendTime = endTime - startTime;
                        task.nestTime = spendTime / 1000;
                        nestLog.spendTime = spendTime;
                        nestLog.messgae.push({
                            timestamp: Date.now(),
                            messgae: `排板已完成`
                        });
                        setTasks([...tasks]);
                        websocket.close();
                        resultDataTemp = data;
                        reportLog(nestLog);
                        resolve(nestLog);
                    }
                };
                websocket.onclose = (e) => {
                    console.log(e.code , e.reason);
                    
                    if(e.reason !== 'retry') {
                        resolve(false);
                    }
                }
            };
            tryDo();
        });
    }


    const [taskRunning, setTaskRunning] = useState(false);
    const [allTaskFinished, setAllTaskFinished] = useState(false);

    useEffect(() => {
        if(!ready) {
            return ;
        }
        const activeTask = tasks.find((t) => t.status === '正在进行' || t.status === '排队中');
        if (activeTask) {
            setTaskRunning(true);
        } else {
            setTaskRunning(false);
        }
        const notFinishedTask = tasks.find((t) => t.status !== '已完成');
        if (!notFinishedTask) {
            setAllTaskFinished(true);
        } else {
            setAllTaskFinished(false);
        }
    }, [tasks, ready]);


    const onTaskStore = () => {
        const taskId = Date.now().toString();
        localStorage.setItem("SK_NEST_TASKS_TEMP", JSON.stringify({
            taskId: taskId,
            tasks,
            resultDataTemp
        }));
        navigate(`/nest/${taskId}`, {
            replace: true
        });
    }


    return (
        <>
            <Divider>
                排板设置

                <Popconfirm
                    placement="top"
                    trigger={["click"]}
                    title={"重置设置"}
                    description={"恢复到原始数据（长2438mm × 宽1218mm，可旋转）"}
                    onConfirm={() => {
                        materialNestSettings.forEach((mns) => {
                            mns.sheetHeight = 2438;
                            mns.sheetWidth = 1218;
                            mns.clipSpace = 10;
                            mns.rotation = true;
                        });
                        setMaterialNestSettings([...materialNestSettings]);
                        localStorage.setItem("SK_MATERIAL_NEST_SETTINGS", JSON.stringify(materialNestSettings));
                    }}
                    okText="重置"
                    cancelText="取消"
                >
                    <span className="settings">
                        <ReloadOutlined title="重置设置" style={{ marginLeft: 12, fontSize: 12 }} />
                        <span style={{ fontSize: 12, marginLeft: 4, position: 'relative' }}>重置</span>

                    </span>

                </Popconfirm>
            </Divider>
            {
                nestMaterials.map((material) => {
                    const settings = materialNestSettings.find((mns) => mns.materialId === material.id);
                    const task = tasks.find((t) => t.material.id === material.id);
                    return (
                        <div className="relative nest-material" style={{ height: 138, background: "#141414", overflow: 'hidden', color: "lightgray", padding: '10px 6px', paddingRight: 0, marginBottom: 10, borderRadius: 10 }}>
                            {
                                !task && (
                                    <div style={{ zIndex: 9, color:  "yellow", fontSize:14, position: 'absolute', width: "100%", height: "100%", display: "flex", justifyContent: 'center', alignItems: 'center', background: "rgba(0,0,0,.8)" }}>
                                        没有小板需要排板
                                    </div>
                                )
                            }

                            <div className="title flex center space-between" style={{ justifyContent: 'space-between', marginBottom: 12, paddingRight: 16 }}>
                                <span className="flex center">
                                    {
                                        material?.texture?.type === 'IMAGE' && (
                                            <img src={material.texture.value} style={{ height: 18, width: 18, borderRadius: 4 }} />
                                        )
                                    }
                                    {
                                        material?.texture?.type === 'COLOR' && (
                                            <div className="inline-block" style={{ height: 18, width: 18, borderRadius: 4, background: material.texture.value }} />
                                        )
                                    }
                                    <span style={{ marginLeft: 6, fontSize: 14, fontWeight: 'bold' }}>{material.name}</span>
                                </span>
                                <span className="flex center">


                                    <span>
                                        {task?.boards.length}块小板
                                    </span>
                                </span>
                                {/* <span className="flex center">
                                                        <span style={{ marginRight: 4, fontSize: 10 }}>查看</span>
                                                        <RightOutlined style={{ fontSize: 12 }} />
                                                    </span> */}
                            </div>
                            <div className="flex center space-between" style={{ fontSize: 12 }}>
                                {/* <span>
                                    <span className={`${(!Number(settings?.sheetWidth) || Number(settings?.sheetWidth) < 0) ? 'nest-error' : ''}`}>
                                        长:<input disabled={taskRunning} className={`nest-input`} value={settings?.sheetWidth}
                                            onChange={(e) => {
                                                onMaterialNestSettingsChange(settings, "sheetWidth", e.target.value);
                                            }}
                                        />mm
                                    </span>
                                    ×
                                    <span className={`${(!Number(settings?.sheetHeight) || Number(settings?.sheetHeight) < 0) ? 'nest-error' : ''}`}>
                                        宽:<input disabled={taskRunning} className={`nest-input`} value={settings?.sheetHeight}
                                            onChange={(e) => {
                                                onMaterialNestSettingsChange(settings, "sheetHeight", e.target.value);
                                            }} />mm
                                    </span>
                                </span> */}
                                {/* <SheetSizeEditor 
                                    taskRunning={taskRunning}
                                    onMaterialNestSettingsChange={onMaterialNestSettingsChange}
                                    settings={settings}
                                /> */}
                                <SheetSelect
                                    materialName={material.name}
                                    taskRunning={taskRunning}
                                    onMaterialNestSettingsChange={onMaterialNestSettingsChange}
                                    settings={settings}
                                />
                                <span className={`${(isNaN(Number(settings?.clipSpace)) || Number(settings?.clipSpace) < 0) ? 'nest-error' : ''}`}>
                                    切割间隙:<input style={{ width: 24 }} disabled={taskRunning} className={`nest-input`} value={settings?.clipSpace}
                                        onChange={(e) => {
                                            onMaterialNestSettingsChange(settings, "clipSpace", e.target.value);
                                        }} />mm
                                </span>
                                <span style={{ display: 'inline-block', width: 70 }}><Checkbox disabled={taskRunning} checked={settings?.rotation} onClick={() => {
                                    onMaterialNestSettingsChange(settings, "rotation", !Boolean(settings.rotation));

                                }}> {settings?.rotation ? '可' : '不可'}旋转</Checkbox></span>

                                {/* <span>{materialNest.sheets.length}块</span> */}
                            </div>
                            {
                                task && (
                                    <>
                                        <div className="flex center space-between" style={{ marginTop: 10 }}>
                                            <span>
                                                {/* 排板算法： */}

                                                <Radio.Group disabled={taskRunning || task.status === '已完成'} value={task.algorithm} onChange={(e) => {
                                                    task.algorithm = e.target.value;
                                                    setTasks([...tasks]);
                                                }}>
                                                    <Space direction="horizontal">
                                                        <Radio value={'pro'}>高级排板</Radio>
                                                        <Radio value={'fast'}>快速排板</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </span>
                                            <span>
                                                {
                                                    ['未开始', '已中断'].includes(task.status) && (
                                                        <Button type="link" style={{ color: "purple" }} onClick={async () => {
                                                            if (task.algorithm === 'fast') {
                                                                await startNest_fast(data, task);
                                                            } else {
                                                                await startNest_pro(data, task);
                                                            }
                                                            onTaskStore();
                                                            // props.onNestFinish(data, task.material.id);
                                                        }}>单独排板 <SendOutlined /> </Button>
                                                    )
                                                }
                                                {
                                                    ['已完成'].includes(task.status) && (
                                                        <>
                                                            <span><Button type="link" onClick={() => {
                                                                // if (resultDataTemp) {
                                                                //     props.onNestFinish(resultDataTemp, task.material.id);
                                                                // }
                                                                if(setTimeoutTemp) {
                                                                    clearTimeout(setTimeoutTemp);
                                                                }
                                                                navigate(`/nest/result/${task.material.id}`, {
                                                                    state: {
                                                                        data: resultDataTemp
                                                                    }
                                                                });
                                                            }}>查看结果 <SearchOutlined /> </Button></span>

                                                            <Tooltip title="清除">
                                                                <Button onClick={async () => {
                                                                    task.progress = 0;
                                                                    task.status = '未开始';
                                                                    setTasks([...tasks]);
                                                                }} type="link" style={{ color: "orangered", margin: 0, paddingLeft: 0 }}>清除结果<CloseOutlined /></Button>
                                                            </Tooltip>
                                                        </>
                                                    )
                                                }
                                                {/* {
                                                    !['未开始', '已中断', '已完成'].includes(task.status) && (
                                                    )
                                                } */}
                                                {
                                                    ['排队中'].includes(task.status) && (
                                                        <span style={{color: "yellow", marginRight: 6}}>{task.tip}</span>
                                                    )
                                                }

                                            </span>
                                        </div>
                                        <div className="flex center" style={{ marginTop: 4, paddingRight: 16 }}>
                                            {
                                                ['已完成'].includes(task.status) ? (
                                                    <></>
                                                ) : (
                                                    <span style={{ flexShrink: 0 }}>排板进度： </span>

                                                )
                                            }
                                            <Progress percent={task.progress} status={task.status === '已完成' ? 'success' : task.status === '已中断' ? 'exception' : "active"} showInfo />
                                            {
                                                ['已完成'].includes(task.status) && (
                                                    <span style={{ flexShrink: 0 }}>耗时{task.nestTime.toFixed(2)}秒</span>
                                                )
                                            }

                                        </div>

                                    </>
                                )
                            }
                        </div>
                    )
                })
            }

            <div className="flex center" style={{ justifyItems: 'space-between', paddingBottom: 10 }}>

                {/* <Button type="primary" disabled={taskRunning} loading={taskRunning} size="large" style={{ flex: 3 }} onClick={onStartNest2}>
                    {taskRunning ? '正在排板' : '开始排板'}
                    <DeliveredProcedureOutlined />
                </Button> */}
                {
                    (allTaskFinished && tasks.length > 0) ? (
                        <div className="flex center space-between" style={{ width: "100%" }}>
                            <span style={{ flexShrink: 0, paddingRight: 10, color: "lightgreen", fontSize: 18 }}><CheckOutlined /> 已完成{allNestTime > 0 ? `，耗时：${(allNestTime / 1000).toFixed(2)}秒` : ''}</span>
                            <Button type="primary" style={{ background: "orange", flex: 1 }} disabled={taskRunning} onClick={() => {
                                if(setTimeoutTemp) {
                                    clearTimeout(setTimeoutTemp);
                                }
                                navigate(`/nest/result`, {
                                    state: {
                                        data: resultDataTemp
                                    }
                                });
                            }}>查看结果</Button>

                        </div>
                    ) : (
                        <>
                            {/* <span style={{ marginRight: 10 }}><Checkbox checked={downloadDirectly} onClick={() => {
                                        if(downloadDirectly){
                                            setDownloadDirectly(false);
                                            localStorage.removeItem("SK_DOWNLOAD_RIGHT_NOW");
                                        }else {
                                            setDownloadDirectly(true);
                                            localStorage.setItem("SK_DOWNLOAD_RIGHT_NOW",  "true");

                                        }
                                    }}> 排板后直接下载DXF文件和标签 </Checkbox></span> */}
                            <Button type="primary" style={{ width: '100%' }} disabled={tasks.length === 0 || taskRunning || !ready} onClick={async () => {
                                await onStartNest2();
                                onTaskStore();
                                setTimeoutTemp = setTimeout(() => {

                                    message.success("排板已完成");
                                    navigate(`/nest/result`, {
                                        state: {
                                            data: resultDataTemp
                                        }
                                    });
                                    clearTimeout(setTimeoutTemp);
                                }, 500);
                            }}>开始排板</Button>

                        </>

                    )
                }
            </div>
        </>
    )

}