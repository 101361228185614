import { Input, Radio, Space } from "antd";
import { useEffect, useState } from "react";

export const RadioInput = (props: {
    value?: number;
    onChange?: (value: number) => void;
    options?: { label?: string, value: number }[];
    disabled?: boolean;
    suffix?: string;
}) => {

    const [value, setValue] = useState(props.value);
    const [valueInOptions, setValueInOptions] = useState(false);

    useEffect(() => {
        const option = props.options?.find(option => option.value === props.value);
        if (!option) {
            setInputValue(props.value?.toString() || "");
            setValueInOptions(false);
            setValue(-Infinity);
        } else {
            setInputValue("");
            setValueInOptions(true);
            setValue(props.value);
        }
    }, [props.value]);


    const onChange = (e: any) => {
        setValue(e.target.value);
        props.onChange?.(e.target.value);
    }

    const [inputValue, setInputValue] = useState(props.value?.toString() || "");

    return (

        <Radio.Group value={value} disabled={props.disabled}>
            <Space direction="vertical">
                {
                    props.options?.map((option, index) => {
                        return (
                            <Radio key={index} value={option.value} checked={option.value === value} onChange={onChange}>{option.label}</Radio>
                        )
                    })
                }
                {
                    (
                        <Radio key="other" value={-Infinity} checked={!valueInOptions} onChange={() => {
                            setValue(-Infinity);//不触发onChange
                        }}>

                            自定义 {(
                                <Input suffix={props.suffix} style={{ width: 80 }} disabled={props.disabled} value={inputValue} onChange={(e) => {
                                    setInputValue(e.target.value);
                                }} onFocus={() => {
                                    setValue(-Infinity);
                                }} onKeyDownCapture={(e) => {
                                    e.stopPropagation();
                                    if (e.key === "Enter") {
                                        if (inputValue === "" || isNaN(Number(inputValue))) {
                                            return;
                                        }
                                        props.onChange?.(Number(inputValue));
                                    }
                                }}
                                    onBlur={() => {
                                        if (inputValue === "" || isNaN(Number(inputValue))) {
                                            setInputValue(props.value?.toString() || "");
                                            return;
                                        }
                                        props.onChange?.(Number(inputValue));
                                    }}

                                />
                            )}
                        </Radio>
                    )
                }
            </Space>

        </Radio.Group>
    )
}