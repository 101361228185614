import { DirectX, DirectY } from "../../../../constants/enum";
import { menneiBoard } from "../01-mennei";

export const sanheyi01MenneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number,
    startY: number,
    width: number,
    height: number,
    nojiaoliankong: boolean,
    jiaolianbianju: number,
    nolashou: boolean,
    menminglashou?: boolean,
    menlashouX?: number,
    menlashouY?: number,
    menlashoubianju?: number,
    menlashoufangxiang?: "vertical" | "horizontal",
    doorSide?: "left" | "right",
): [Path[], Path[]] => {
    return menneiBoard(dx, dy, startX, startY, width, height, nojiaoliankong, jiaolianbianju, nolashou, menminglashou, menlashouX, menlashouY, menlashoubianju, menlashoufangxiang, doorSide);
}