import { message, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { json2Form } from ".";

interface Options { autoErrorTip?: boolean, withoutAuthorization?: boolean, files?: {name:string, file: File}[] }
class Request {
    
    private loginInfo: any;
    private exipreAt: number;

    getLoginInfo() {
        if(!this.loginInfo) {
            const localLoginInfo = localStorage.getItem("SK_LOGIN_INFO");
            this.loginInfo = JSON.parse(localLoginInfo);
        }
        return this.loginInfo;
    }

    getAccessToken() {
        if(!this.loginInfo) {
            const localLoginInfo = localStorage.getItem("SK_LOGIN_INFO");
            if(!localLoginInfo) {
                const navigate = useNavigate();
                return navigate("/login")
            }
            this.loginInfo = JSON.parse(localLoginInfo);
        }
        if(!this.exipreAt) {
            this.exipreAt = Number(localStorage.getItem("SK_TOKEN_EXPIRE_AT"));
        }
        if(!this.exipreAt || this.exipreAt < (Date.now() + 60 * 60 * 1000)) {
            const navigate = useNavigate();
            return navigate("/login")
        }
        return this.loginInfo?.accessToken;
    }

    reset() {
        this.loginInfo = undefined;
        this.exipreAt = undefined;
    }

    baseRequest = (method: string, url: string, params: {[key: string]: any} = {}, options?: Options) => {
        let authorization: string | undefined;
        if(!options?.withoutAuthorization) {
            
            authorization = this.getAccessToken();

        }
        const config: any = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorization
            }
        }

        if(method === 'GET') {
            if(Object.keys(params)?.length > 0) {
                url += '?' + json2Form(params);
            }
        }else {
            if(options?.files) {
                const formData = new FormData();
                options.files.forEach((file) => {
                    formData.append(file.name, file.file);
                });
                formData.append("$data", JSON.stringify(params));
                config['body'] = formData;
                delete config.headers['Content-Type'];
            }else {
                config['body'] = JSON.stringify(params);
            }
        }

        return fetch(url, config).then((res) => {
            return res.json();  
        }).then((res) => {
            
            if(res.statusCode === 401) {
                Modal.confirm({
                    title: "距离上次登录已超过7天，请重新登录",
                    okText: "重新登陆",
                    centered: true,
                    onOk: () => {
                        window.location.href = "/#/login";
                    }
                })
                return ;
            }else if(res.statusCode === 403) {
                
                Modal.confirm({
                    title: "该账号已被禁用",
                    footer: [],
                    maskClosable: false,
                    centered: true,
                    closable: false,
                    onCancel: (e) => {
                        // e.preven
                    },
                    onOk: () => {
                        window.location.href = "/#/login";
                    }
                });
            }
            if(options?.autoErrorTip !== false) {
                if(res.code !== 1) {
                    message.warning(res.msg || res.message);
                }
            }
            return res;
        });
    }



    get = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("GET", url, params, options);
    }

    post = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("POST", url, params, options);
    }
    
    put = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("PUT", url, params, options);
    }

    delete = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("DELETE", url, params, options);
    }
    
    patch = (url: string, params: {[key: string]: any} = {}, options?: Options) => {
        return this.baseRequest("PATCH", url, params, options);
    }

}

export const request = new Request();
// request.getAccessToken();
