import { DirectX, DirectY } from "../../../constants/enum";
import { qiandangwaiBoard } from "../地柜/01-qiandangwai";

export const _05qiandangwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    return qiandangwaiBoard(cornerType, dx, dy, startX, startY, width, height, corner);
}