import { DirectX, DirectY } from "../../../constants/enum";
import { _12AdineiBoard } from "./12-a-dinei"

export const _12AdingneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number
): [Path[], Path[]] => {
    return _12AdineiBoard(dx, dy, startX, startY, width, height, a);
}