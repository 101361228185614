import { Group } from "antd/es/avatar";
import { Vector2d } from "konva/lib/types";
import { useEffect, useMemo, useState } from "react"
import { Circle, Line, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { ShapeBound, m2px, px2m } from "../../../store";

let floorImageLoad = false;
const floorImage = new Image();
floorImage.src = "/assets/images/0eb30f2442a7d933a8c0c8a83a03081f72f00151.jpg";
floorImage.onload = () => {
    floorImageLoad = true;
}


export const ShapeArea = (props: {
    areaName: string;
    wallThickness: number;
    anchors: Vector2d[];
    innerContour: Vector2d[];
    outerContour: Vector2d[];
    innerArea: number;
    innerBound: ShapeBound;
    hideAreaLabel?: boolean;

}) => {

    const [innerOutlinePoints, setInnerOutlinePoints] = useState<number[]>([]);
    const [outerOutlinePoints, setOuterOutlinePoints] = useState<number[]>([]);

    const [image] = useState(floorImage);
    const [imageLoaded, setImageLoaded] = useState(floorImageLoad);

    useEffect(() => {
        if(imageLoaded) {
            return ;
        }
        const image = new Image();
        image.src = "/assets/images/0eb30f2442a7d933a8c0c8a83a03081f72f00151.jpg";
        image.onload = () => {
            setImageLoaded(true);
        }
    }, [imageLoaded]);


    useEffect(() => {
        const points: number[] = [];
        props.anchors.map((v) => {
            points.push(v.x, v.y);
        });
        const innerOutlinePoints: number[] = [];
        const outerOutlinePoints: number[] = [];
        props.innerContour.forEach((v) => {
            innerOutlinePoints.push(v.x, v.y);

        });
        props.outerContour.forEach((v) => {
            outerOutlinePoints.push(v.x, v.y);
        });
        if (innerOutlinePoints.length >= 2) {
            innerOutlinePoints.push(innerOutlinePoints[0], innerOutlinePoints[1]);
        }
        if (outerOutlinePoints.length >= 2) {
            outerOutlinePoints.push(outerOutlinePoints[0], outerOutlinePoints[1]);
        }
        setInnerOutlinePoints(innerOutlinePoints);
        setOuterOutlinePoints(outerOutlinePoints);
    }, [props.anchors, props.innerContour]);



    return (
        <Group>
            {
                useMemo(() => {
                    return (
                        <>
                            <Line
                                points={outerOutlinePoints}
                                opacity={1}
                                fill="#a6a6a6"
                                stroke={"#595959"}
                                strokeWidth={1}
                                closed
                                strokeScaleEnabled={false}
                            />
                            <Line
                                points={innerOutlinePoints}
                                opacity={1}
                                fill="#847362"
                                stroke={"#595959"}
                                strokeWidth={1}
                                closed
                                strokeScaleEnabled={false}
                            />
                            {
                                props.anchors.map((v, i) => {
                                    return (
                                        <Circle
                                            key={i}
                                            x={v.x}
                                            y={v.y}
                                            fill={"#595959"}
                                            opacity={0.6}
                                            radius={props.wallThickness / 4}
                                        />
                                    )
                                })
                            }
                        </>
                    )

                }, [innerOutlinePoints, outerOutlinePoints, props.anchors])
            }
            {
                useMemo(() => {
                    return imageLoaded && (
                        <>
                            <Line
                                points={innerOutlinePoints}
                                opacity={1}
                                stroke={"#595959"}
                                fillPatternImage={image}
                                fillPatternScale={{ x: 0.2, y: 0.2 }}
                                fillPatternOffsetY={0}
                                fillPatternOffsetX={0}
                                strokeWidth={1}
                                closed
                                strokeScaleEnabled={false}
                            />

                        </>
                    )
                }, [props.anchors, innerOutlinePoints, outerOutlinePoints, imageLoaded])

            }
            {
                useMemo(() => {
                    if (!props.innerBound || props.hideAreaLabel) {
                        return;
                    }

                    return (
                        <>

                            <Text
                                ref={r => {
                                    //调整尺寸
                                    if (r) {
                                        r.offsetX(r.width() / 2);
                                        r.offsetY(r.height() / 2 * 2.8);
                                    }
                                }}
                                x={props.innerBound.left + (props.innerBound.right - props.innerBound.left) / 2}
                                y={props.innerBound.top + (props.innerBound.bottom - props.innerBound.top) / 2}
                                fill="#000"
                                fontSize={18}
                                fontStyle="600"
                                stroke={"#fff"}
                                strokeWidth={0.4}
                                strokeScaleEnabled={false}
                                align="center"
                                verticalAlign="center"
                                text={props.areaName}
                                zIndex={99}
                            />
                            <Text
                                ref={r => {
                                    //调整尺寸
                                    if (r) {
                                        r.offsetX(r.width() / 2);
                                        r.offsetY(r.height() / 2 / 2.8);
                                    }
                                }}
                                x={props.innerBound.left + (props.innerBound.right - props.innerBound.left) / 2}
                                y={props.innerBound.top + (props.innerBound.bottom - props.innerBound.top) / 2}
                                fill="#000"
                                fontSize={20}
                                fontStyle="600"
                                stroke={"#fff"}
                                strokeWidth={0.6}
                                strokeScaleEnabled={false}
                                align="center"
                                verticalAlign="center"
                                text={(props.innerArea * px2m * px2m).toFixed(2) + 'm²'}
                            />
                        </>
                    )
                }, [props.innerBound, props.areaName, props.hideAreaLabel])
            }
        </Group>
    )
}