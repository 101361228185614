import { Circle } from "../../constants";
import { DirectX, DirectY } from "../../constants/enum";
import { pathMoveX, pathMoveY } from "../../util/graph";
import { corner2 } from "./corner2";

export const corner8 = (dx: DirectX, dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const corner2Paths = corner2(dx, dy, startX, startY, true);
    let paths = corner2Paths.filter((item) => item.type !== Circle);
    paths = paths.slice(8, paths.length);
    paths = pathMoveY(paths, DirectY.DOWN, dy * 43.49);
    if(typeof sizeRes === 'object') {
        // if(!corner) {
        //     sizeRes.width = 0;
        //     sizeRes.height = 0;
        // }else {
            sizeRes.width = 34.0000;
            sizeRes.height = 33.998293;
        // }
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}