import { PlusOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { locale } from '../../utils/locale';
import './style.css';

const cupboards = [{
    key: "digui-cebaobei",
    name: locale["地柜"],
    struct: "cebaobei",
    type: "digui",
    long: 800,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 4.96,
    mengao: 640,
    waibanjiaoType: "youhujiao",
},

// {
//     key: "sanheyi-digui-cebaobei",
//     name: locale["三合一地柜",
//     struct: "cebaobei",
//     type: "digui",
//     long: 800,
//     deep: 550,
//     height: 680,
//     spacing: 10,
//     jiaolianbianju: 100,
//     lianjiekong: true,
//     lianjiekongbianju: 40,
//     guituiX: 60,
//     guituiY: 50,
//     doorNumber: 2,
//     doorSide: "left",
//     menwaibanjiao: true,
//     nowaibanjiao: false,
//     geceng: true,
//     gecengX: 60,
//     gecengY: 340,
//     mengao: 640,
//     waibanjiaoType: "youhujiao",
// },
 {
    key: "digui-beibaoce",
    struct: "beibaoce",
    name: locale["地柜"],
    type: "digui",
    long: 800,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 3,
    mengao: 640,
    waibanjiaoType: "youhujiao",

}, {
    key: "diaogui-cebaobei",
    name: locale["吊柜"],
    struct: "cebaobei",
    type: "diaogui",
    long: 800,
    deep: 350,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    diaokongbianju: 60,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 3,
    waibanjiaoType: "youhujiao",

},
//  {
//     key: "sanheyi-diaogui-cebaobei",
//     name: locale["三合一吊柜",
//     struct: "cebaobei",
//     type: "diaogui",
//     long: 800,
//     deep: 350,
//     height: 680,
//     spacing: 10,
//     jiaolianbianju: 100,
//     lianjiekong: true,
//     lianjiekongbianju: 40,
//     diaokongbianju: 60,
//     doorNumber: 2,
//     doorSide: "left",
//     menwaibanjiao: true,
//     nowaibanjiao: false,
//     geceng: true,
//     gecengX: 60,
//     gecengY: 340,
//     waibanjiaoType: "youhujiao",

// },
{
    key: "diaogui-beibaoce",
    name: locale["吊柜"],
    type: "diaogui",
    struct: "beibaoce",
    long: 800,
    deep: 350,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    diaokongbianju: 60,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 3,
    waibanjiaoType: "youhujiao",


}, {
    key: "right-sanmengui-cebaobei",
    name: locale["A右三门柜"],
    type: "sanmengui",
    struct: "cebaobei",
    a: 491,
    long: 900,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,                                                                                                                            
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 4.96,
    menwaibanjiao: true,
    nowaibanjiao: false,
    waibanjiaoType: "youhujiao",
    doorSide: "left",

}, {
    key: "left-sanmengui-cebaobei",
    name: locale["A左三门柜"],
    struct: "cebaobei",
    type: "sanmengui",
    a: 491,
    long: 900,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 4.96,
    menwaibanjiao: true,
    nowaibanjiao: false,
    waibanjiaoType: "youhujiao",
    doorSide: "left",

}, {
    key: "left-qiezhuanjiaogui-cebaobei",
    name: locale["左切转角柜"],
    struct: "cebaobei",
    type: "sanmengui",
    long: 980,
    deep: 550,
    height: 680,
    x: 400,
    y: 300,
    doorWidth: 400,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    menwaibanjiao: true,
    nowaibanjiao: false,
    waibanjiaoType: "youhujiao",
    doorSide: "left",
    mengao: 640,

},  {
    key: "right-qiezhuanjiaogui-cebaobei",
    name: locale["右切转角柜"],
    struct: "cebaobei",
    type: "sanmengui",
    long: 980,
    deep: 550,
    height: 680,
    x: 400,
    y: 300,
    doorWidth: 400,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    menwaibanjiao: true,
    nowaibanjiao: false,
    waibanjiaoType: "youhujiao",
    doorSide: "left",
    mengao: 640,

}, {
    key: "left-zhuanjiaogui-cebaobei",
    name: locale["左转角柜"],
    struct: "cebaobei",
    type: "zhuanjiaogui",
    long: 980,
    deep: 550,
    height: 680,
    x: 400,
    y: 300,
    doorWidth: 400,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    menwaibanjiao: true,
    nowaibanjiao: false,
    doorSide: "left",
    waibanjiaoType: "youhujiao",
    mengao: 640,

}, {
    key: "right-zhuanjiaogui-cebaobei",
    name: locale["右转角柜"],
    struct: "cebaobei",
    type: "zhuanjiaogui",
    long: 980,
    deep: 550,
    height: 680,
    x: 400,
    y: 300,
    doorWidth: 400,
    doorSide: "left",
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    menwaibanjiao: true,
    nowaibanjiao: false,
    waibanjiaoType: "youhujiao",
    mengao: 640,

}, {
    key: "shuicaogui-cebaobei",
    type: "shuicaogui",
    name: locale["水槽柜"],
    struct: "cebaobei",
    long: 800,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 4.96,
    waibanjiaoType: "youhujiao",
    mengao: 640

    
}, {
    key: "lalangui-cebaobei",
    name: locale["拉篮柜"],
    struct: "cebaobei",
    type: "lalangui",
    long: 800,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: -1,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: false,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 4.96,
    a: 225,
    x: 38,
    y1: 80,
    y2: 400,
    y3: 0,
    mengao: 640,
    waibanjiaoType: "youhujiao",
    paikong: true,
    paikongX: 30,
    paikongY: 90,
    paikongWidth: 10,
    paikongHeight: 120
    
}, {
    key: "digui-cebaobei",
    name: "煤气罐柜",
    struct: "cebaobei",
    type: "digui",
    long: 800,
    deep: 550,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    guituiX: 60,
    guituiY: 50,
    doorNumber: 2,
    doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 4.96,
    mengao: 640,
    waibanjiaoType: "youhujiao",
    isMeiqiguangui: true
    
}, {
    key: "yangtaigui-cebaobei",
    name: locale["阳台柜"],
    struct: "cebaobei",
    type: "yangtaigui",
    long: 1200,
    deep: 570,
    height: 880,
    spacing: 10,
    jiaolianbianju: 100,
    doorNumber: 2,
    // doorSide: "left",
    menwaibanjiao: true,
    nowaibanjiao: false,    
    mengao: 640,
    menkuan: 366,
    dibanshen: 570,
    zuocekuan: 570,
    zuocechang: 880,
    zhongcekuan: 550,
    zhongcechang: 850,
    youcekuan: 550,
    youcechang: 880,
    waibanjiaoType: "youhujiao",
    doorSide: "left",

}, {
    key: "ii-yangtaigui-cebaobei",
    name: locale["阳台柜2"],
    struct: "cebaobei",
    type: "yangtaigui",
    waibanjiaoType: "youhujiao",
    long: 0,
    deep: 0,
    height: 0,
    diyBoards: [],
    menwaibanjiao: true,
    nowaibanjiao: false,  
    // spacing: 10,
    // jiaolianbianju: 100,
    // doorNumber: 2,
    // // doorSide: "left",
    // menwaibanjiao: true,
    // nowaibanjiao: false,    
    // mengao: 640,
    // menkuan: 366,
    // dibanshen: 570,
    // zuocekuan: 570,
    // zuocechang: 880,
    // zhongcekuan: 550,
    // zhongcechang: 850,
    // youcekuan: 550,
    // youcechang: 880,
    // waibanjiaoType: "youhujiao",
    // doorSide: "left",

}, {
    key: "diaogui-sanmengui-beibaoce",
    name: locale["吊柜三门柜"],
    type: "diaogui",
    doorSide: "left",
    struct: "beibaoce",
    long: 1200,
    deep: 350,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    diaokongbianju: 60,
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 3,
    a: 800,
    waibanjiaoType: "youhujiao",

}, {
    key: "diaogui-sanmengui-beibaoce",
    name: locale["吊柜三门柜"],
    type: "diaogui",
    struct: "cebaobei",
    doorSide: "left",
    long: 1200,
    deep: 350,
    height: 680,
    spacing: 10,
    jiaolianbianju: 100,
    lianjiekong: true,
    lianjiekongbianju: 40,
    diaokongbianju: 60,
    menwaibanjiao: true,
    nowaibanjiao: false,
    geceng: true,
    gecengX: 60,
    gecengY: 340,
    gecengkongjing: 3,
    a: 800,
    waibanjiaoType: "youhujiao",

}, {
    
    key: "hanjiegui",
    name: "焊接柜",
    struct: "cebaobei",
    type: "yangtaigui",
    waibanjiaoType: "youhujiao",
    long: 0,
    deep: 0,
    height: 0,
    diyBoards: [],
    menwaibanjiao: true,
    nowaibanjiao: false,  
}];

export const CupboardCreateModal = (props) => {
    return (
        <Dropdown trigger={["click"]} placement="bottomRight" overlay={(
            <Menu>
            {
                cupboards.filter((item) => {
                    if(props.order.struct === "cebaobei") {
                        return item.struct === props.order.struct;
                    }else if(props.order.struct === "beibaoce") {
                        const hasBeibaoceTypes = cupboards.filter((_) => _.struct === "beibaoce").map((_) => _.type);
                        if(hasBeibaoceTypes.indexOf(item.type) > -1) {
                            return item.struct === "beibaoce";
                        }else { 
                            return cupboards.findIndex((_) => _.struct !== "beibaoce") > -1; 
                        }
                    }
                    // return props.order.struct === "cebaobei" ? !item.struct : "struct" in item ? item.struct === props.order.struct : true
                }).map((board, index) => {
                    return (
                        <Menu.Item key={board.key} onClick={() => {
                            props.onSelected({...board});
                        }}>{index + 1}、{board.name}</Menu.Item>
                    )
                })
            }
            </Menu>
          )}>
            <div className="add item"/*  onClick={openCupboardSelectModal} */>
                + {locale['添加']}
            </div>
          </Dropdown>
        // <div className={"order-create-modal " + (props.visible ? '' : 'none')} onClick={(e) => {
        //     e.preventDefault();
        // }}>
        //     <div className="content" >
        //         <div className="title" style={{width: 140}}>选择橱柜类型</div>
        //         <div className="cupboard-list">
        //         {
        //             cupboards.map((board) => {
        //                 return (
        //                     <div className="cupboard-item" key={board.key} onClick={() => {
        //                         props.onSelected({...board});
        //                         props.onClose();
        //                     }}>
        //                         {board.name}
        //                     </div>
        //                 )
        //             })
        //         }
        //         </div>
        //         <div className="cancel" onClick={props.onClose}>
        //             取消
        //         </div>
        //     </div>
        // </div>
    )

}