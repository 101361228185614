import { Arc, Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { pathMirrorY, pathRotate2 } from "../../../util/graph";
import { sanheyiNeijiaoPart, sanheyiNeijiaoPart2 } from "./@parts";

export const corner1Sanheyi = (_dx: DirectX, _dy: DirectY, startX: number, startY: number, corner: boolean, sizeRes?: {width: number, height: number}): Path[] => {
    const dx = 1;
    const dy = 1;
    
    let paths: Path[] = [{
        type: Line,
        params: [
            startX, startY,
            startX + dx * 12, startY
        ]
    }, {
        type: Line,
        params: [
            startX + dx * 12, startY,
            startX + dx * 12, startY + dy * 4.367136
        ]
    }];
    const sx1 = startX + dx * 12;
    const sy1 = startY + dy * 4.367136;
    paths.push(...sanheyiNeijiaoPart(sx1, sy1, dx, dy, "none"));
    const sx2 = sx1;
    const sy2 = sy1 + dy * 26.265727;
    paths.push({
        type: Line,
        params: [
            sx2, sy2, sx2, sy2 + dy * 8.267122,
        ]
    })
    const sx3 = sx2;
    const sy3 = sy2 + dy * 8.267122;
    paths.push(...sanheyiNeijiaoPart2(sx3, sy3, dx, dy));

    let width = 32.865654;
    let height = 60.599985;
    if(_dx === 1 && _dy === -1) {
        paths = pathMirrorY(paths, startX);
        paths = pathRotate2(paths, startX, startY, 180);
    }else if(_dx === -1 && _dy === 1) {
        paths = pathMirrorY(paths, startX);

    }else if(_dx === -1 && _dy === -1) {
        paths = pathRotate2(paths, startX, startY, 180);
    }
    
    if(typeof sizeRes === 'object') {
        sizeRes.width = width;
        sizeRes.height = height;
    }
    return paths.filter((item) => corner ? true : item.type === Circle);
}