import { useEffect, useState } from "react";
import { space } from "../../store/space-store";
import { useDrawingAndEditing, useSpace } from "../../store";

export const Right1Widget = (props: {

}) => {

    const { isDrawing, isEditing } = useDrawingAndEditing();
    

    return (
        <>
            {
                (!isDrawing && !isEditing) && (
                    <Space />
                )
            }
        </>

    )
}

const Space = (props: {}) => {

    const [spaceStore, setSpaceStore] = useState<any>(space.initStore());

    const { wallDrawed, isDrawing, areaDrawed, roomName, floorHeight, floorThickness, wallThickness,
            updateRoomName, updateFloorHeight, updateFloorThickness, updateWallThickness
    } = useSpace();

    useEffect(() => {
        // console.log("store equal:", spaceStore === space.$getStore());
        if(spaceStore !== space.$getStore()) {
            space.updateStoreBatch(spaceStore);
        }
    }, [spaceStore]);


    useEffect(() => {
        if(roomName !== spaceStore.roomName || 
            floorHeight !== spaceStore.floorHeight || 
            floorThickness !== spaceStore.floorThickness || 
            wallThickness !== spaceStore.wallThickness
        ) {
            setSpaceStore({
                ...spaceStore,
                roomName,
                floorHeight,
                floorThickness,
                wallThickness
            })
        }
    }, [roomName, floorHeight, floorThickness, wallThickness]);


    const onNewStore = (newStore) => {
        for (const key in newStore) {
            if(typeof newStore['key'] === 'number' ? Math.abs(newStore[key] - spaceStore[key]) > 0.01 : newStore[key] !== spaceStore[key]) {
                // delete newStore[key];
                if(key === 'roomName') {
                    updateRoomName(newStore[key]);
                }else if(key === 'floorHeight') {
                    updateFloorHeight(newStore[key]);
                }else if(key === 'floorThickness') {
                    updateFloorThickness(newStore[key]);
                }else if(key === 'wallThickness') {
                    updateWallThickness(newStore[key]);
                }
            }
        }
        setSpaceStore(newStore);
    }

    const [options, setOptions] = useState({
        disabledKeys: []
    })

    useEffect(() => {
        console.log({
            wallDrawed,
            areaDrawed,
            isDrawing
        });
        
        const disabledKeys = areaDrawed ? ['roomName', 'wallThickness'] :  wallDrawed ? ['wallThickness'] : isDrawing ? ['roomName', 'floorHeight', 'floorThickness', 'wallThickness'] : [];
        console.log(disabledKeys);
        
        setOptions({
            disabledKeys: disabledKeys,
        })
    }, [isDrawing, areaDrawed, wallDrawed])

    return (
        <div className="fixed no-scroll-bar" style={{ width: 270, background: "#ffffff", boxShadow: '0 2px 10px 3px rgba(0, 0, 0, .1)', right: 0, top: 54 + 240 + 16, height: "calc(100vh - (54px + 240px + 16px + 10px))", overflowY: "auto", borderTopLeftRadius: 4, borderBottomLeftRadius: 4, }}>
            <div style={{ background: "#f7f7f8", height: 30, width: '100%', fontSize: 14, color: 'rgba(3,9,17,0.6)', borderBottom: '1px solid #f8f9fb', lineHeight: '30px', padding: '0 12px', }}>
                <span className="bold">{space.label}</span>
            </div>
            <div style={{ padding: '16px 12px' }}>
                {
                    space.getEditForm(
                        spaceStore,
                        onNewStore,
                        options
                    )()
                }
            </div>
        </div>
    )
}

