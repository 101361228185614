import { Circle } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { conbinePaths, pathMirrorY, pathMoveX, pathMoveY } from "../../../util/graph";
import { _03qiandangwaiBoard } from "./03-qiandangwai";
import { rectBoard } from "../general-board";

export const _04qiandangwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    a: number,
    corner: boolean
 ): [Path[], Path[]]  => {
    const [dineiBoardPaths, dineiBoardCorners] = _03qiandangwaiBoard(cornerType, dx, dy, startX, startY, width, height, a, corner);
    let paths = conbinePaths([dineiBoardPaths, dineiBoardCorners]);
    paths = pathMirrorY(paths, startX);
    paths = pathMoveX(paths, DirectX.RIGHT, width);
    return [
        paths.slice(0, dineiBoardPaths.length),
        ([] as Path[]).concat(paths.slice(dineiBoardPaths.length, paths.length)),
    ]

 }