import { DirectX, DirectY } from "../../../constants/enum";
import { _05beineiBoard } from "./05-beinei";
import { _05fengneiBoard } from "./05-fengnei";

export const _07fengneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number
 ): [Path[], Path[]]  => {
    return _05fengneiBoard(dx, dy, startX, startY, width, height);
 }