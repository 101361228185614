import { Circle, Line } from "../../../constants";
import { DirectX, DirectY } from "../../../constants/enum";
import { clearCorner, cornerMirrorX, pathMirrorY, pathMoveX, pathMoveY, pathRotate } from "../../../util/graph";
import { corner14 } from "../../coners/corner14";
import { corner2 } from "../../coners/corner2";
import { corner14WaiWuhujiao } from "../../coners/无护角/corner14-wai-wuhujiao";
import { corner2WaiWuhujiao } from "../../coners/无护角/corner2-wai-wuhujiao";
import { corner14WaiWujiao } from "../../coners/无角/corner14-wai-wujiao";
import { corner2WaiWujiao } from "../../coners/无角/corner2-wai-wujiao";
import { rectBoard } from "./../general-board";



export const _01AbeiwaiBoard = (
    cornerType: undefined | "youhujiao" | "wuhujiao" | "wujiao",
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
    corner: boolean
 ): [Path[], Path[]]  => {
     //console.log(cornerType);
     
    //左下角
    const cornerBLSize = { width: 0, height: 0 };
    let cornerBL: Path[] = [];
    let cornerBLW: number = 0;
    let cornerBLH: number = 0;
    if(cornerType === "youhujiao") {
        cornerBL = corner14(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);
    }else if(cornerType === "wujiao") {
        cornerBL = corner14WaiWujiao(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);
    }else if(cornerType === "wuhujiao") {
        cornerBL = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerBLSize);
        cornerBLW = cornerBLSize.width;
        cornerBLH = cornerBLSize.height;
        cornerBL = cornerMirrorX(cornerBL, startX, cornerBLSize);

    }

    //右下角
    const cornerBRSize = { width: 0, height: 0 };
    let cornerBR: Path[] = [];
    //  = corner14(dx, dy, startX, startY, true, cornerBRSize);
    let cornerBRW: number = cornerBRSize.width;
    let cornerBRH: number = cornerBRSize.height;
    if(cornerType === "youhujiao") {
        cornerBR = corner14(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBLSize)
        cornerBR = pathMirrorY(cornerBR, startX)
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    }else if(cornerType === "wujiao") {
        cornerBR = corner14WaiWujiao(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBLSize)
        cornerBR = pathMirrorY(cornerBR, startX)
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);
    }else if(cornerType === "wuhujiao") {
        cornerBR = corner14WaiWuhujiao(dx, dy, startX, startY, true, cornerBRSize);
        cornerBRW = cornerBRSize.width;
        cornerBRH = cornerBRSize.height;
        cornerBR = cornerMirrorX(cornerBR, startX, cornerBLSize)
        cornerBR = pathMirrorY(cornerBR, startX)
        cornerBR = pathMoveX(cornerBR, DirectX.RIGHT, width);

    }
    // if(!corner) {
    //     cornerBL = clearCorner(cornerBL);
    // }
    
    //右上角
    const cornerTRSize = {width: 0, height: 0};
    let cornerTR: Path[] = [];
    let cornerTRW: number = cornerTRSize.width;
    let cornerTRH: number = cornerTRSize.height;
    if(cornerType === "youhujiao") {
        cornerTR = corner2(-dx, dy, startX, startY,corner,  cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else if(cornerType === "wujiao") {
        cornerTR = corner2WaiWujiao(-dx, dy, startX, startY,corner,  cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);
    }else if(cornerType === "wuhujiao") {
        cornerTR = corner2WaiWuhujiao(-dx, dy, startX, startY,corner,  cornerTRSize);
        cornerTRW = cornerTRSize.width;
        cornerTRH = cornerTRSize.height;
        cornerTR = pathMoveY(cornerTR, DirectY.UP, height - cornerTRSize.height);
        cornerTR = pathMoveX(cornerTR, DirectX.RIGHT, width);

    }
    
    //右上角
    const cornerTLSize = {width: 0, height: 0};
    let cornerTL: Path[] = [];
    let cornerTLW: number = cornerTLSize.width;
    let cornerTLH: number = cornerTLSize.height;
    if(cornerType === "youhujiao") {
        cornerTL = corner2(dx, dy, startX, startY,corner,  cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wujiao") {
        cornerTL = corner2WaiWujiao(dx, dy, startX, startY,corner,  cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);
    }else if(cornerType === "wuhujiao") {
        cornerTL = corner2WaiWuhujiao(dx, dy, startX, startY,corner,  cornerTLSize);
        cornerTLW = cornerTLSize.width;
        cornerTLH = cornerTLSize.height;
        cornerTL = pathMoveY(cornerTL, DirectY.UP, height - cornerTLSize.height);

    }


    
    const boardConfig: any = {
        middleware: {
            top: false,
            left: (height > 500) && "far",
            right: (height > 500) && "far",
            bottom:(width > 500 && "far")
        }
    }
    let paths;
    if (corner) {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            cornerBLW, cornerBLH, cornerBRW, cornerBRH, cornerTRW, cornerTRH, cornerTLW, cornerTLH, boardConfig);
    } else {
        paths = rectBoard("outer", dx, dy, startX, startY, width, height,
            0, 0, 0, 0, 0, 0, 0, 0, boardConfig);
    }
    
    // const corners: Path[] = [{
    //     type: Line,
    //     params: [
    //         dx * (2 * startX + width) / 2 + dx * 29.58 / 2 + dx * 1.92, startY,
    //         dx * (2 * startX + width) / 2 + dx * 29.58 / 2 + dy * 1.92, startY + dy * 1.995,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         dx * (2 * startX + width) / 2 + dx * 29.58 / 2 + dx * 1.92, startY + dy * 1.995,
    //         dx * (2 * startX + width) / 2 + dx * 29.58 / 2, startY + dy * 1.995,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         dx * (2 * startX + width) / 2 + dx * 29.58 / 2, startY + dy * 1.995,
    //         dx * (2 * startX + width) / 2 + dx * 29.58 / 2, startY,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         dx * (2 * startX + width) / 2 - dx * 29.58 / 2 - dx * 1.92, startY,
    //         dx * (2 * startX + width) / 2 - dx * 29.58 / 2 - dx * 1.92, startY + dy * 1.995,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         dx * (2 * startX + width) / 2 - dx * 29.58 / 2 - dx * 1.92, startY + dy * 1.995,
    //         dx * (2 * startX + width) / 2 - dx * 29.58 / 2, startY + dy * 1.995,
    //     ]
    // }, {
    //     type: Line,
    //     params: [
    //         dx * (2 * startX + width) / 2 - dx * 29.58 / 2, startY + dy * 1.995,
    //         dx * (2 * startX + width) / 2 - dx * 29.58 / 2, startY
    //     ]
    // }, {
    //     type: Circle,
    //     params: [
    //         dx * (2 * startX + width) / 2, startY + dy *32.385,
    //         3.11
    //     ]
    // }, {
    //     type: Circle,
    //     params: [
    //         dx *  (2 * startX + width) / 2 - dx * 15.75, startY + dy *32.385,
    //         2.61
    //     ]
    // }, {
    //     type: Circle,
    //     params: [
    //         dx * (2 * startX + width) / 2 + dx * 15.75, startY + dy *32.385,
    //         2.61
    //     ]
    // }]
    return [
        paths,
        // corners.concat(cornerBL, cornerBR, cornerTR, cornerTL),
        corner ? ([] as Path[]).concat(cornerBL, cornerBR, cornerTR, cornerTL) : [],
    ]
}