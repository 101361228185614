import { DirectX, DirectY } from "../../../constants/enum";
import { _02gecengneiBoard } from "./02-gecengnei"

export const _12AgecengneiBoard = (
    dx: DirectX,
    dy: DirectY,
    startX: number, 
    startY: number, 
    width: number, 
    height: number,
): [Path[], Path[]] => {
    return _02gecengneiBoard(dx, dy, startX, startY, width, height);
}