import { PageHeader } from "@ant-design/pro-layout"
import { Alert, Button, Form, Input, Modal, Space, Table, message } from "antd"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { request } from "../../../utils2/request"
import { apis } from "../../../utils2/apis"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { SheetCreateModal } from "../../../components/sheet-create-modal"

export const SheetCostSettingsPage = () => {

    const [loading, setLoading] = useState(true);

    const [sheets, setSheets] = useState<any[]>([]);
    const [sizeSheets, setSizeSheets] = useState<any[]>([]);

    useEffect(() => {
        request.get(apis.sheet + `/list`)
            .then(res => {
                if (res.code === 1) {
                    const sheets: {
                        id: number;
                        materialName: string;
                        price: number;
                        width: number;
                        length: number;
                    }[] = res.data;
                    setSheets(sheets);
                }
            });
    }, []);

    useEffect(() => {
        if (sheets.length > 0) {

            const sizeSheets: {
                width: number;
                length: number;
                sheets: {
                    id: number;
                    price: number;
                    width: number;
                    length: number;
                    materialName: string;
                }[]
            }[] = [];
            sheets.forEach(sheet => {
                const sizeSheet = sizeSheets.find(sizeSheet => sizeSheet.width === sheet.width && sizeSheet.length === sheet.length);
                if (sizeSheet) {
                    sizeSheet.sheets.push(sheet);
                } else {
                    sizeSheets.push({
                        width: sheet.width,
                        length: sheet.length,
                        sheets: [sheet]
                    });
                }
            });
            setSizeSheets(sizeSheets);
        }
    }, [sheets]);

    const addRef = useRef<any>();

    const [editSheetId, setEditSheetId] = useState<number | null>(null);
    const [tempPrice, setTempPrice] = useState<string>("");
    const [editSubmitting, setEditSubmitting] = useState(false);
    const onPriceEdit = () => {
        const price = Number(tempPrice);
        if(isNaN(price) || price <= 0) {
            message.error("请输入正确的价格");
            return;
        }
        const submitPrice = price * 100;
        setEditSubmitting(true);
        request.put(apis.sheet + `/${editSheetId}`, {
            price: submitPrice
        })
            .then((res) => {
                if(res.code === 1) {
                    setSheets(sheets.map(s => {
                        if(s.id === editSheetId) {
                            return {
                                ...s,
                                price: submitPrice
                            }
                        }
                        return s;
                    }));
                    setEditSheetId(null);
                    setTempPrice("");
                }
            
            })
            .finally(() => {
                setEditSubmitting(false);
            
            })
    }

    return (
        <PageHeader
            title="板材成本设置"
            subTitle={(<Button type="link" onClick={() => {
                addRef.current.open();
            }}><PlusOutlined /> 添加尺寸</Button>)}
        >
        <Alert message="导出Excel时，根据设置的价格计算成本表" type="info" style={{marginBottom: 24}}/>
            {
                sizeSheets.map(ss => {
                    return (
                        <div className="flex " style={{ marginBottom: 24, background: "#fff", padding: 12 }}>
                            <div className="flex column center" style={{ marginRight: 24, fontSize: 10, width: `100px`, height: `calc(100px * ${ss.length / ss.width})`, background: "gray", color: "#fff" }}>
                                <div>长：{ss.length}mm</div>
                                <div>宽：{ss.width}mm</div>
                            </div>
                            <div style={{ flex: 1, flexShrink: 0 }}>

                                <Table
                                    size="small"
                                    pagination={false}
                                    columns={[
                                        {
                                            title: "#",
                                            key: "index",
                                            width: 50,
                                            render: (_, __, index) => {
                                                return index + 1;
                                            }
                                        },
                                        {
                                            title: "材质",
                                            dataIndex: "materialName",
                                            key: "materialName",
                                            width: 150,
                                        },
                                        {
                                            title: "尺寸",
                                            key: 'size',
                                            width: 300,
                                            render: (_, sheet: any) => {
                                                return `长：${sheet.length}mm x 宽：${sheet.width}mm`;
                                            }

                                        },
                                        {
                                            title: "成本（元/张）",
                                            dataIndex: "price",
                                            key: "price",
                                            render: (price, sheet) => {
                                                return editSheetId === sheet.id ? (
                                                    <span>

                                                        <Input 
                                                            autoFocus
                                                            size="small" 
                                                            style={{width: 50}} 
                                                            value={tempPrice} 
                                                            onChange={(e) => {
                                                                setTempPrice(e.target.value);
                                                            }}
                                                            onPressEnter={() => {
                                                                onPriceEdit();
                                                            }}
                                                        />

                                                        <Space style={{marginLeft: 10}}>
                                                            {
                                                                editSubmitting ? (
                                                                    <span>
                                                                        <LoadingOutlined spin  />
                                                                        保存中...
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                    <Button type="link" size="small" onClick={() => {
                                                                       onPriceEdit();
                                                                        
                                                                    }}>确认</Button>
                                                                    <Button type="text" size="small" onClick={() => {
                                                                        setEditSheetId(null);
                                                                        setTempPrice("");
                                                                    }}>取消</Button>
                                                                    
                                                                    </>
                                                                )
                                                            }
                                                        </Space>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {(price / 100)}元/张
                                                        <Button style={{marginLeft: 10}} type="link" size="small" onClick={() => {
                                                            setEditSheetId(sheet.id);
                                                            setTempPrice((price / 100).toString());
                                                        }}>编辑</Button>
                                                    </span>
                                                );
                                            }
                                        },
                                    ]}
                                    dataSource={ss.sheets}
                                />
                            </div>
                        </div>
                    )
                })
            }
            <div style={{ textAlign: 'center', padding: '50px 0', fontSize: 12, color: "gray"}}>到底了~</div>

            <SheetCreateModal
                ref={addRef}
                onSheetsAdd={(newSheets) => {
                    setSheets([...sheets, ...newSheets]);
                }}
            />
        </PageHeader>
    )
}
