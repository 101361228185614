import { AccumulativeShadows, RandomizedLight, Environment as EnvironmentImpl, OrbitControls } from "@react-three/drei"
import { Canvas } from "@react-three/fiber"
import { memo, useEffect, useMemo, useState } from "react"
import { AreaData, DrawedAreaMeta, DrawedOnWallMeta, DrawedStructMeta, DrawedWallMeta, OnWallData, StructData, WallData, px2m, useDrawed, useSpace } from "../../store";
import { Addition, Base, Geometry, Subtraction } from "@react-three/csg";
import { BoxGeometry, ExtrudeGeometry, Shape, Vector2 } from "three";
import { space } from "../../store/space-store";
import { Area3D } from "./3d-preview/3d-area";
import { WallsAndOnWalls3D } from "./3d-preview/3d-wall";
import { Struct3D } from "./3d-preview/3d-struct";

export const Preview3D = (props: {
    height: number;
    width: number;
}) => {

    return (
        <Canvas shadows camera={{ position: [-15, 10, 15], fov: 25 }}>
            <color attach="background" args={['skyblue']} />
            <axesHelper args={[10]} />
            <House />
            <Environment />
            <OrbitControls makeDefault />
        </Canvas>
    )

}


const House = () => {

    const { drawedList } = useDrawed();
    // const [ drawedWalls, setDrawedWalls] = useState<DrawedWallMeta[]>([]);
    // const [ areaWalls, setAreaWalls] = useState<DrawedAreaMeta[]>([]);
    const [ displayAreas, setDisplayAreas] = useState<AreaData[]>([]);
    const [ displayWalls, setDisplayWalls] = useState<WallData[]>([]);
    const [ displayWindows, setDisplayWindows] = useState<OnWallData[]>([]);
    const [ displayDoors, setDisplayDoors] = useState<OnWallData[]>([]);
    const [ displayStructList, setDisplayStructList] = useState<StructData[]>([]);

    const [globalOffset, setGlobalOffset] = useState({ x: 0, y: 0 });
    const [ready, setReady] = useState(false);

    useEffect(() => {
        //计算绘制的墙体、区域、窗户、门、结构
        const areas = drawedList.filter((d) => d.type === 'area') as DrawedAreaMeta[];
        let displayWalls: WallData[] = [];
        if(areas.length > 0) {
            areas.forEach((a) => {
                displayWalls.push(...a.data.walls);
            });
        }else {
            displayWalls = drawedList.filter((d) => d.type.indexOf("wall-") === 0).map((d) => d.data) as WallData[];
        }
        const displayWindows: OnWallData[] = [];
        const displayDoors: OnWallData[] = [];
        drawedList.filter((d) => d.type.indexOf("onwall") === 0).forEach((o) => {
            if(o.type.indexOf("window") > -1) {
                displayWindows.push(o.data as OnWallData);
            }else if(o.type.indexOf("door") > -1) {
                displayDoors.push(o.data as OnWallData);
            }
        });
        const structList = drawedList.filter((d) => d.type.indexOf("struct") === 0).map((a) => a.data) as StructData[];
        //计算范围，确保墙是最大范围
        
        let left = Infinity;
        let top = Infinity;
        let right = -Infinity;
        let bottom = -Infinity;
        displayWalls.forEach((w) => {
            left = Math.min(left, w.startAnchorPos.x, w.endAnchorPos.x);
            top = Math.min(top, w.startAnchorPos.y, w.endAnchorPos.y);
            right = Math.max(right, w.startAnchorPos.x, w.endAnchorPos.x);
            bottom = Math.max(bottom, w.startAnchorPos.y, w.endAnchorPos.y);
        });
        const displayAreas = areas.map((a) => a.data);
        setGlobalOffset({
            x: -(left + right) / 2,
            y: -(top + bottom) / 2
        });
        setDisplayAreas(displayAreas);
        setDisplayWalls(displayWalls);
        setDisplayWindows(displayWindows);
        setDisplayDoors(displayDoors);
        setDisplayStructList(structList);
        if(!ready) {
            setReady(true);
        }
    }, [drawedList]);

    const { floorHeight, floorThickness } = useSpace();

    return (
        <group
            visible={ready}
        >
            
            <WallsAndOnWalls3D
                        wall3DList={displayWalls.map((w) => {
                            return {
                                globalOffset,
                                ...w
                            }
                        })}
                        door3DList={displayDoors.map((d) => {
                            return {
                                globalOffset,
                                ...d
                            }
                        })}
                        window3DList={displayWindows.map((w) => {
                            return {
                                globalOffset,
                                ...w
                            }
                        })}
                    />
        {/* {
            
            useMemo(() => {
                // console.log({
                //     globalOffset,
                //     displayWalls: displayWalls.length,
                //     displayDoors: displayDoors.length,
                //     displayWindows: displayWindows.length
                
                // });
                
                const wallListProps = displayWalls.map((w) => {
                    return {
                        globalOffset,
                        ...w
                    }
                })
                const doorListProps = displayDoors.map((d) => {
                    return {
                        globalOffset,
                        ...d
                    }
                });
                const windowListProps = displayWindows.map((w) => {
                    return {
                        globalOffset,
                        ...w
                    }
                });
                return (
                    <WallsAndOnWalls3D
                        wall3DList={wallListProps}
                        door3DList={doorListProps}
                        window3DList={windowListProps}
                    />
                )
            }, [displayWalls, displayDoors, displayWindows, globalOffset, floorThickness, floorHeight])
        } */}
        {
            useMemo(() => {
                return displayAreas.map(a => (
                    <Area3D
                        globalOffset={globalOffset}
                        {...a}                    
                    />
                ))
            }, [displayAreas])
        }
        {
            useMemo(() => {
                return displayStructList.map(s => (
                    <Struct3D 
                        globalOffset={globalOffset}
                        {...s}
                    />
                ))
            }, [displayStructList])
        }
            
        </group>
    )


    const [areaGeoList, setAreaGeoList] = useState<{ innerWallGeo: ExtrudeGeometry, outerWallGeo: ExtrudeGeometry }[]>([]);
    const [structGeoList, setStructGeoList] = useState<ExtrudeGeometry[]>([]);
    const [onWallGeoList, setOnWallGeoList] = useState<BoxGeometry[]>([]);
    const [wallHeight, setWallHeight] = useState(3);
    // const [floorThickness, setFloorThickness] = useState(0.2);

    useEffect(() => {
        const areas = drawedList.filter((d) => d.type === 'area') as DrawedAreaMeta[];
        let baseOffsetLeft: number = 0;
        let baseOffsetTop: number = 0;
        setAreaGeoList(areas.map((a) => {
            const { outerContour, innerContour, outerBound, innerBound } = a.data;
            if (!baseOffsetLeft) {
                baseOffsetLeft = outerBound.left + (outerBound.right - outerBound.left) / 2;
            }
            if (!baseOffsetTop) {
                baseOffsetTop = outerBound.top + (outerBound.bottom - outerBound.top) / 2;
            }
            const outerVectors = outerContour.map((o) => new Vector2(
                (o.x - baseOffsetLeft) * px2m,
                (o.y - baseOffsetTop) * px2m
            ));
            const innerVectors = innerContour.map((o) => new Vector2(
                (o.x - baseOffsetLeft) * px2m,
                (o.y - baseOffsetTop) * px2m
            ));
            const outerWallGeo = new ExtrudeGeometry(new Shape(outerVectors), {
                bevelEnabled: false,
                depth: wallHeight
            });
            outerWallGeo.translate(0, 0, -wallHeight);
            outerWallGeo.rotateX(Math.PI / 2);
            const innerWallGeo = new ExtrudeGeometry(new Shape(innerVectors), {
                bevelEnabled: false,
                depth: wallHeight - floorThickness
            });
            innerWallGeo.translate(0, 0, -wallHeight);
            innerWallGeo.rotateX(Math.PI / 2);
            return {
                outerWallGeo,
                innerWallGeo
            }
        }));
        const structs = drawedList.filter((d) => d.type.indexOf("struct") === 0) as DrawedStructMeta[];

        setStructGeoList(structs.map((s) => {
            const { structType, pos, size } = s.data;
            const shape = new Shape();
            let depth: number = wallHeight - floorThickness;
            if (structType === 'struct-round-post') {
                shape.moveTo((pos.x - baseOffsetLeft) * px2m, (pos.y - baseOffsetTop) * px2m);
                shape.arc(0, 0, size.x / 2 * px2m, 0, Math.PI * 2);
            } else if (structType === 'struct-rect-post') {
                shape.moveTo((pos.x - size.x / 2 - baseOffsetLeft) * px2m, (pos.y - size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x - size.x / 2 - baseOffsetLeft) * px2m, (pos.y + size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x + size.x / 2 - baseOffsetLeft) * px2m, (pos.y + size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x + size.x / 2 - baseOffsetLeft) * px2m, (pos.y - size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x - size.x / 2 - baseOffsetLeft) * px2m, (pos.y - size.y / 2 - baseOffsetTop) * px2m);
            } else if (structType.indexOf("struct-beam") === 0) {
                shape.moveTo((pos.x - size.x / 2 - baseOffsetLeft) * px2m, (pos.y - size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x - size.x / 2 - baseOffsetLeft) * px2m, (pos.y + size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x + size.x / 2 - baseOffsetLeft) * px2m, (pos.y + size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x + size.x / 2 - baseOffsetLeft) * px2m, (pos.y - size.y / 2 - baseOffsetTop) * px2m);
                shape.lineTo((pos.x - size.x / 2 - baseOffsetLeft) * px2m, (pos.y - size.y / 2 - baseOffsetTop) * px2m);
            }
            const structGeo = new ExtrudeGeometry(shape, {
                bevelEnabled: false,
                depth: (size.z < 0 ? depth : size.z * px2m)
            });
            structGeo.translate(0, 0, -wallHeight);
            structGeo.rotateX(Math.PI / 2);
            return structGeo;
        }));

        const onWallList = drawedList.filter((d) => d.type.indexOf("onwall") === 0) as DrawedOnWallMeta[];
        setOnWallGeoList(onWallList.map((o) => {
            let offsetFloorHeight = 0;//离地高度
            let height = 0;
            if (o.type.indexOf("door") > -1) {
                height = 2;
            } else {
                height = 1.4;
                offsetFloorHeight = 0.85;
            }
            // if(o.type === 'onwall-single-door') {
            const boxGeometry = new BoxGeometry(o.data.onWallWidth * px2m, height, space.store.wallThickness * px2m);
            if (o.data.openDirect === 'left' || o.data.openDirect === 'right') {
                boxGeometry.rotateY(Math.PI / 2);
                boxGeometry.translate(
                    (o.data.wall.from.x - baseOffsetLeft) * px2m, 
                    floorThickness + height / 2 + offsetFloorHeight,
                    (o.data.anchor - baseOffsetTop) * px2m
                );

            }else {
                boxGeometry.translate(
                    (o.data.anchor - baseOffsetLeft) * px2m,
                    floorThickness + height / 2 + offsetFloorHeight,
                    (o.data.wall.from.y - baseOffsetTop) * px2m
                );
            }
            return boxGeometry;
        }));

    }, [drawedList, wallHeight, floorThickness]);



    return useMemo(() => (
        <group>
            <mesh receiveShadow castShadow >
                <Geometry computeVertexNormals>
                    {
                        areaGeoList.map((a) => {
                            return (
                                <>
                                    <Base geometry={a.outerWallGeo} />
                                    <Subtraction geometry={a.innerWallGeo} />
                                </>
                            )
                        })
                    }
                    {
                        structGeoList.map((s) => {
                            return (
                                <Addition geometry={s} />
                            )
                        })
                    }
                    {
                        onWallGeoList.map((o) => {
                            return (
                                <Subtraction geometry={o} />
                            )
                        })
                    }
                </Geometry>
                <meshStandardMaterial envMapIntensity={1} />
            </mesh>
            {/* window or door base */}
            {/* {
                onWallGeoList.map((o) => {
                    return (
                        <mesh>
                            <Geometry computeVertexNormals>
                                <Base geometry={o} />
                                <meshStandardMaterial color={0x000000} />
                            </Geometry>
                        </mesh>
                    )
                })
            } */}
        </group>
    ), [areaGeoList, structGeoList])
}



const Environment = memo(({ direction = [5, 5, 5] }: { direction?: [x: number, y: number, z: number] }) => (
    <>
        <directionalLight position={direction} intensity={0.8} shadow-mapSize={1024} castShadow />
        <directionalLight position={[-5, 5, 5]} intensity={0.8} shadow-mapSize={128} castShadow />
        <directionalLight position={[-5, 5, -5]} intensity={0.8} shadow-mapSize={128} castShadow />
        <directionalLight position={[0, 5, 0]} intensity={0.8} shadow-mapSize={128} castShadow />
            <AccumulativeShadows frames={100} alphaTest={0.85} opacity={1} scale={30} position={[0, 0, 0]}>
                <RandomizedLight amount={8} radius={2.5} ambient={0.5} intensity={1} position={direction} bias={0.01} />
            </AccumulativeShadows>
        {/* <EnvironmentImpl preset="city" /> */}
    </>
))
